var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-warpper console-body asktrue"},[_c('header',[_c('div',{staticClass:"left"},[_c('router-link',{staticClass:"back cur no-select",attrs:{"to":{
          name: _vm.routerName,
          params: { tab: _vm.routerName, back: '1' },
        }}},[_c('em',{staticClass:"el-icon-back"})]),_c('h1',{staticClass:"ellipsis"},[_c('span',{attrs:{"title":_vm.proData.exam_name}},[_vm._v(_vm._s(_vm.proData.exam_name))]),(_vm.proData.exam_status == 1)?_c('em',{staticClass:"iconfont iconshangxian",attrs:{"title":"点击下线"},on:{"click":function($event){return _vm.examStatusChange()}}}):_vm._e(),(_vm.proData.exam_status == 3)?_c('em',{staticClass:"iconfont iconxiaxian",attrs:{"title":"点击上线"},on:{"click":function($event){return _vm.examStatusChange()}}}):_vm._e()])],1),_c('div',{staticClass:"menuUl no-select"},[(_vm.ftype == 2)?_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsQuestionnaire' },attrs:{"to":{
          name: 'ProKsQuestionnaire',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("问卷")]):_vm._e(),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsExaminee' },attrs:{"to":{
          name: 'ProKsExaminee',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("考生")]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsData' },attrs:{"to":{
          name: 'ProKsData',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("数据")]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsScore' },attrs:{"to":{
          name: 'ProKsScore',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("成绩")]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsStatistics' },attrs:{"to":{
          name: 'ProKsStatistics',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("统计")]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProKsNotice' },attrs:{"to":{
          name: 'ProKsNotice',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype, from: _vm.from },
        }}},[_vm._v("通知")])],1),_c('div',{staticClass:"btn"},[_c('span',{attrs:{"title":"基本设置"},on:{"click":function($event){return _vm.popFn('isEdit')}}},[_c('em',{staticClass:"iconfont iconshezhi"}),_c('span',[_vm._v("编辑")])]),_c('span',{attrs:{"title":"链接设置"},on:{"click":function($event){return _vm.popFn('isShare')}}},[_c('em',{staticClass:"iconfont iconfenxiang"}),_c('span',[_vm._v("链接")])]),(_vm.proData.goods_status == 2 && _vm.proData.from_type == 2)?_c('span',{attrs:{"title":"商品设置"},on:{"click":function($event){return _vm.popFn('isShop')}}},[_c('em',{staticClass:"iconfont iconshangpin"}),_c('span',[_vm._v("商品")])]):_vm._e(),(_vm.proData.face_recognition == 2 || _vm.proData.photograph === 2)?_c('span',{attrs:{"title":"监控设置"},on:{"click":function($event){return _vm.popFn('isMonitor')}}},[_c('em',{staticClass:"iconfont iconjiankong"}),_c('span',[_vm._v("监控")])]):_vm._e(),_c('span',{attrs:{"title":"阅卷设置"},on:{"click":function($event){return _vm.popFn('isMark')}}},[_c('em',{staticClass:"iconfont icontubiao_peizhi"}),_c('span',[_vm._v("阅卷")])]),_c('span',{attrs:{"title":"外观设置"},on:{"click":function($event){return _vm.popFn('isStyle')}}},[_c('em',{staticClass:"iconfont iconwaiguan"}),_c('span',[_vm._v("外观")])]),_c('a',{attrs:{"href":_vm.proData.specify_student == 2 && !_vm.proData.exam_text
            ? `https://asktrue.co/#/exam/main/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}`
            : (_vm.proData.projectType == 2 || _vm.proData.projectType == 3) &&
              _vm.proData.real_name == 2
            ? `https://asktrue.co/#/exam/loginpg/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}/${_vm.userId}`
            : `https://asktrue.co/#/exam/login/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}/${_vm.userId}`,"target":"_blank"}},[_vm._m(0)])]),_c('router-link',{staticClass:"close el-icon-close",attrs:{"to":{
        name: _vm.routerName,
        params: { tab: _vm.routerName, back: '1' },
      }}})],1),_c('router-view'),(_vm.other.show)?_c('el-drawer',{key:_vm.other.key,attrs:{"visible":_vm.other.show,"size":_vm.other.isStyle ? '100%' : '70%',"show-close":_vm.other.isStyle ? false : true,"before-close":_vm.handleClose,"custom-class":"asktrue-drawer"},on:{"update:visible":function($event){return _vm.$set(_vm.other, "show", $event)}}},[(_vm.other.isEdit)?_c('Form',{attrs:{"isOnOff":_vm.isOnOff,"proData":_vm.proData},on:{"refresh":function($event){_vm.other.show = false;
        _vm.other.isEdit = false;
        _vm.getProjectEditFn();}}}):_vm._e(),(_vm.other.isShare)?_c('Share',{attrs:{"source":"xm","proData":_vm.proData,"examData":{
        examId: _vm.id,
        isJk: _vm.proData.face_recognition,
        type: _vm.type,
        vipId: _vm.proData.vip_id,
        goodStatus: _vm.proData.goods_status,
      }},on:{"closePop":_vm.handleClose}}):_vm._e(),(_vm.other.isMark)?_c('Mark',{ref:"mark",attrs:{"data":_vm.proData,"id":_vm.id,"type":_vm.type},on:{"closePop":_vm.handleClose}}):_vm._e(),(_vm.other.isMonitor)?_c('Monitor',{attrs:{"data":_vm.proData,"id":_vm.proData.exam_id,"type":_vm.proData.projectType,"isJk":_vm.proData.face_recognition,"isPZ":_vm.proData.photograph,"vipId":_vm.proData.vip_id,"state":_vm.proData.exam_status,"ftype":_vm.proData.from_type},on:{"closePop":_vm.handleClose}}):_vm._e(),(_vm.other.isStyle)?_c('Style',{attrs:{"id":_vm.proData.exam_id,"type":_vm.proData.projectType,"ftype":_vm.proData.from_type},on:{"close":_vm.handleClose}}):_vm._e(),(_vm.other.isShop)?_c('Shop',{attrs:{"data":_vm.proData,"projectId":_vm.proData.exam_id,"vipId":_vm.proData.vip_id,"state":_vm.proData.exam_status,"ftype":_vm.proData.from_type,"from":"pop"},on:{"closePop":_vm.handleClose}}):_vm._e()],1):_vm._e(),(_vm.other.isMustFillSet)?_c('Mustfill',{attrs:{"isMustFillSet":_vm.other.isMustFillSet,"freeFormP":_vm.freeForm,"examId":Number(_vm.proData.exam_id),"projectType":Number(_vm.proData.projectType)},on:{"closePop":_vm.handleClose}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('em',{staticClass:"iconfont iconpreview1",attrs:{"title":"预览"}}),_c('span',[_vm._v("预览")])])
}]

export { render, staticRenderFns }
<template>
  <div class="mf-warpper">
    <div class="main bgf tal">
      <div class="title">请先完成以下问题</div>
      <!--表单-->
      <el-form
        :model="form"
        ref="form"
        class="myAsktrueForm"
        v-if="form.formData && form.formData.length"
      >
        <el-form-item
          :label="
            item.type != 'explain' ? index + 1 + '、' + item.infoText1 : ''
          "
          prop="name"
          v-for="(item, index) in form.formData"
          :key="index"
        >
          <!--文本 textarea-->
          <el-input
            type="textarea"
            autosize
            v-model="form.formAs[index].answerText"
            :placeholder="item.infoText2"
            v-if="item.displayStyle == 1 && form.formAs.length"
          ></el-input>

          <!--下拉框 select-->
          <div
            class="select-wrap"
            v-if="item.displayStyle == 2 && form.formAs.length"
          >
            <el-select
              popper-class="center-el-select"
              v-for="i in Number(
                item.xlLevelSet ? item.xlLevelSet.split('#zbw#')[0] : 1
              )"
              :key="i"
              v-model="form.formAs[index].answerArr[i - 1].answer"
              :placeholder="
                item.xlLevelSet
                  ? item.xlLevelSet.split('#zbw#')[i]
                  : item.infoText2
              "
              @change="handleSelectFn($event, item, i)"
            >
              <el-option
                v-for="(opt, idx) in item.projectInfoOptions"
                :key="idx"
                :label="opt.optionText"
                :value="opt.selectId"
                v-show="
                  opt.selectId &&
                  i == opt.selectId.split('-').length &&
                  !opt.isHide
                "
              ></el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item class="mt40" v-if="form.formData && form.formData.length">
          <el-button
            type="primary"
            style="padding: 10px 60px; border-radius: 2px"
            @click="toPage()"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  newGetRequiredReq,
  newPostRequiredReq,
  selectParams,
} from "@/api/asktrue/answer/exam";
import { getData } from "@/views/staff/js/data.js";

export default {
  props: {
    id: Number,
    type: String,
    ownerId: String,
    studentId: String,
  },
  data() {
    return {
      style: {
        backgroundStyle: "", //设置的背景
      },
      form: {
        formData: [], //表单数组
        formAs: [], //表单答案
        dialogImageUrl: "",
        dialogVisible: false,
        disabled: false,
      },
    };
  },
  created() {
    //this.getFormDataFn();
  },
  methods: {
    //必填项表单答案
    getFormDataFn(projectInfos) {
      this.form.formData = projectInfos;
      this.form.formData.forEach((ele) => {
        if (ele.startSystemAddr == 1) {
          //启用系统数据
          this.getOption(ele);
        }
      });
      newGetRequiredReq(this.type).then((res) => {
        //获取答案
        if (res.code == "200") {
          this.form.formAs = res.data.result;
          if (!this.form.formAs || !this.form.formAs.length) {
            //没有答案
            this.form.formAs = [];
            this.form.formData.map((ele) => {
              let obj = {
                answerArr: [{ answer: "" }, { answer: "" }],
                answer: "",
                answerText: "",
                examId: this.id,
                infoId: ele.infoId,
                studentId: this.studentId,
                vipId: this.ownerId,
              };
              this.form.formAs.push(obj);
            });
          } else {
            this.form.formAs.map((ele) => {
              let asArr = ele.answer ? ele.answer.split("#zbw#") : [];
              if (asArr.length) {
                ele.answerArr = [];
                asArr.forEach((el) => {
                  let objItem = { answer: el };
                  ele.answerArr.push(objItem);
                });
              } else {
                ele.answerArr = [{ answer: "" }, { answer: "" }];
              }
            });
            //解决答案长度与信息项长度不一致的情况
            this.form.formData.map((ele, index) => {
              let aIndex = this.form.formAs.findIndex(
                (item) => item.infoId == ele.infoId
              );
              let xlLevelSet = ele.xlLevelSet
                ? ele.xlLevelSet.split("#zbw#")[0]
                : 1;
              if (aIndex == -1) {
                //不存在
                let obj = {
                  answerArr: [{ answer: "" }, { answer: "" }],
                  answer: "",
                  answerText: "",
                  examId: this.id,
                  infoId: ele.infoId,
                  studentId: this.studentId,
                  vipId: this.ownerId,
                };
                this.form.formAs.splice(index, 0, obj);
              } else if (
                this.form.formAs[aIndex].answerArr.length < xlLevelSet
              ) {
                let len =
                  Number(xlLevelSet) -
                  this.form.formAs[aIndex].answerArr.length;
                for (let i = 0; i < len; i++) {
                  this.form.formAs[aIndex].answerArr.push({ answer: "" });
                }
              }
            });
          }
        }
      });
    },
    //获取参数选项-学历、院校等
    getOption(oItem) {
      let ts = [
        { type: "tSchool", infoTypeId: 8 },
        { type: "tMajor", infoTypeId: 7 },
        { type: "tTrade", infoTypeId: 14 },
        { type: "tCity", infoTypeId: 9 },
        { type: "tJobKind", infoTypeId: 12 },
      ];
      let parameterType = "";
      let obj = ts.find((el) => el.infoTypeId == oItem.infoTypeId);
      if (obj && oItem.startSystemAddr == 1) {
        parameterType = obj.type;
      } else {
        return false;
      }
      oItem.projectInfoOptions = [];
      selectParams({ parameterType: parameterType }).then((res) => {
        if (res.code == "200") {
          let selectArr = res.data.result || [];
          selectArr = res.data.result || [];
          selectArr.forEach((ele) => {
            let valId =
              oItem.infoTypeId == 8 ? ele.city_id || "0000" : ele.value;
            let obj = { optionText: ele.label, selectId: valId };
            let selId = String(obj.selectId);
            obj.selectId =
              String(valId).length / 2 == 2
                ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
                : String(valId).length / 2 == 3
                ? selId.substring(0, 2) +
                  "-" +
                  selId.substring(2, 4) +
                  "-" +
                  selId.substring(4, 6)
                : selId;
            if (oItem.infoTypeId == 8) {
              //院校
              obj.selectId = obj.selectId + ele.value;
            }
            oItem.projectInfoOptions.push(obj);
          });
          if (oItem.infoTypeId == 8 || oItem.infoTypeId == 9) {
            //院校/地点
            oItem.projectInfoOptions = oItem.projectInfoOptions.concat(
              JSON.parse(
                JSON.stringify(getData("provinceVar"))
                  .replace(/value/g, "optionText")
                  .replace(/id/g, "selectId")
              )
            );
          }
          oItem.projectInfoOptions.forEach((ele) => {
            ele.optionId = ele.selectId;
          });
        }
      });
    },
    handleSelectFn(val, tItem, i) {
      let index = this.form.formAs.findIndex(
        (ele) => ele.infoId == tItem.infoId
      );
      if (index == -1) {
        return false;
      }
      //级联数据过滤
      if (tItem.xlLevelNeed == 1) {
        //级联
        if (i == 1) {
          //一级
          tItem.projectInfoOptions.forEach((item) => {
            if (
              item.selectId.split("-")[0] != val &&
              item.selectId.split("-").length == 2
            ) {
              item.isHide = true;
            } else {
              item.isHide = false;
            }
          });
          this.form.formAs[index].answerArr[1].answer = "";
          if (this.form.formAs[index].answerArr[2]) {
            this.form.formAs[index].answerArr[2].answer = "";
          }
        }
        if (i == 2) {
          //二级
          if (!this.form.formAs[index].answerArr[0].answer) {
            this.$utils.voit("请先选择" + (i - 1) + "级菜单", "error");
            this.form.formAs[index].answerArr[1].answer = "";
            return false;
          }
          tItem.projectInfoOptions.forEach((item) => {
            if (
              item.selectId.split("-")[0] + "-" + item.selectId.split("-")[1] !=
                val &&
              item.selectId.split("-").length == 3
            ) {
              item.isHide = true;
            } else if (
              item.selectId.split("-")[0] + "-" + item.selectId.split("-")[1] ==
                val &&
              item.selectId.split("-").length == 3
            ) {
              item.isHide = false;
            }
          });
          if (this.form.formAs[index].answerArr[2]) {
            this.form.formAs[index].answerArr[2].answer = "";
          }
        }
        if (
          i == 3 &&
          (!this.form.formAs[index].answerArr[0].answer ||
            !this.form.formAs[index].answerArr[1].answer)
        ) {
          //三级
          this.$utils.voit("请先选择" + (i - 1) + "级菜单", "error");
          this.form.formAs[index].answerArr[2].answer = "";
          return false;
        }
      }
      this.form.formAs[index].answerText = "";
      this.form.formAs[index].answer = "";
      this.form.formAs[index].answerArr.forEach((ele) => {
        if (ele.answer) {
          let obj = tItem.projectInfoOptions.find(
            (el) => el.selectId == ele.answer
          );
          this.form.formAs[index].answerText += obj.optionText + "-";
          this.form.formAs[index].answer += ele.answer + "#zbw#";
        }
      });
      if (this.form.formAs[index].answer) {
        this.form.formAs[index].answer = this.form.formAs[index].answer.slice(
          0,
          this.form.formAs[index].answer.length - 5
        );
      }
      if (this.form.formAs[index].answerText) {
        this.form.formAs[index].answerText = this.form.formAs[
          index
        ].answerText.slice(0, this.form.formAs[index].answerText.length - 1);
      }
    },
    //提交必填项
    toPage() {
      let isFinish = true;
      this.form.formAs.forEach((ele) => {
        if (!ele.answerText && !ele.answer) {
          isFinish = false;
        }
      });
      if (!isFinish) {
        this.$utils.voit("请填写完成所有必填项");
        return false;
      }
      newPostRequiredReq(this.type, this.form.formAs).then((res) => {
        if (res.code == "200") {
          this.$utils.voit("提交成功", "success");
          this.$emit("closeFill");
          let name = "ExamMain";
          this.$router.push({
            name: name,
            params: { id: this.id, ownerId: this.ownerId, type: this.type },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mf-warpper {
  padding: 40px 50px;
  position: relative;
  .main {
    .title {
      font-size: 22px;
      border-bottom: 1px solid #e4e4e4;
      padding-bottom: 10px;
      height: 60px;
      line-height: 50px;
      padding: 0 0px 0px 0;
      overflow: hidden;
      color: #666;
      margin-bottom: 20px;
    }

    .myAsktrueForm {
      /deep/ .el-form-item {
        margin-bottom: 20px;
        .el-form-item__label {
          display: block;
          width: 100%;
          text-align: left;
          line-height: 160%;
          margin-bottom: 2px;
        }
        .el-form-item__content {
          line-height: 32px;
          display: block;
          width: 100%;
          //公共样式
          span,
          em {
            vertical-align: middle;
          }

          .el-textarea {
            width: 80%;
          }
          .select-wrap {
            display: flex;
            width: 100%;
            .el-select {
              flex: 1;
              max-width: 80%;
            }
            .el-select:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
      /deep/ .el-divider {
        background-color: #e4e4e4;
      }
    }
  }
}
</style>

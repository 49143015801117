/*
 * @Description: crm 上用到的 product
 * @Date: 2024-01-22 15:39:27
 * @LastEditTime: 2024-01-30 17:09:19
 */
export default function (axios) {
  return {
    // /product/getOutStaffCount  客户详情统计业务外包、劳务派遣相关数据
    getOutStaffCount(params) {
      return axios.get("/product/getOutStaffCount", { params });
    },
  };
}

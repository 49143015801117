<!--匿名-答题登录页（调查、评估类项目）-->
<template>
  <div class="exam-background pr" :style="backgroundStyle">
    <!--头：logo与title-->
    <div class="top">
      <div class="logo" v-if="transData.logo.show">
        <img v-show="transData.logo.show" :src="transData.logo.img" />
      </div>
      <div class="title" :style="titleStyle">{{ titleText }}</div>
      <!--{{titleText||examData.exam_name}}-->
    </div>
    <div :class="['main-box', { mainMore: stepList.length > 1 }]">
      <div v-if="stepList.length > 1" class="step-show">
        <div
          :style="stepStyle.pass"
          :class="['back-border05 ' + (onG ? 'border-on-g' : 'border-on')]"
        ></div>
        <div
          :style="
            step == stepList[0].step
              ? stepStyle.checked
              : maxStep >= stepList[0].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[0].step && !onG },
            { 'on-g': maxStep >= stepList[0].step && onG },
            { 'active-g': step == stepList[0].step && onG },
            { active: step == stepList[0].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[0].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[0].step) + 1
            }}</i>
            <div class="name">{{ stepList[0].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[1].step ? stepStyle.pass : stepStyle.begin
          "
          v-if="stepList.length > 1"
          :class="[
            'back-border1',
            { 'border-on': maxStep >= stepList[1].step && !onG },
            { 'border-on-g': maxStep >= stepList[1].step && onG },
          ]"
        ></div>
        <div
          :style="
            step == stepList[1].step
              ? stepStyle.checked
              : maxStep >= stepList[1].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          v-if="stepList.length > 1"
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[1].step && !onG },
            ,
            { 'on-g': maxStep >= stepList[1].step && onG },
            { 'active-g': step == stepList[1].step && onG },
            { active: step == stepList[1].step },
            { active: step == stepList[1].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[1].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[0].step) + 1
            }}</i>
            <div class="name">{{ stepList[1].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[stepList.length - 1].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          :class="[
            'back-border05',
            {
              'border-on':
                maxStep >= stepList[stepList.length - 1].step && !onG,
            },
            {
              'border-on-g':
                maxStep >= stepList[stepList.length - 1].step && onG,
            },
          ]"
        ></div>
      </div>

      <div :class="['main', 'main' + step]">
        <!--考前须知-->
        <div class="plate tal" v-if="inform && step == 0">
          <div class="kqxz border-box">
            <div class="inform tal" v-html="inform"></div>
            <el-checkbox
              class="mt20"
              v-model="isInform"
              @change="changeStep(0, 1)"
              >我已阅读并同意遵守规定</el-checkbox
            >
          </div>
        </div>

        <!-- 登录信息 -->
        <div class="plate body" v-show="step == 2">
          <div class="border-box" style="width: 360px">
            <div class="f60 mb30 tal">
              注：本项目采用匿名方式，但限制了参与范围，请验证是否有参与资格!
            </div>

            <div class="login">
              <el-input
                v-model="phone"
                :placeholder="
                  examData.login_rule == '1'
                    ? '输入手机号码'
                    : examData.login_rule == '2'
                    ? '输入电子邮箱'
                    : '输入考号'
                "
                :prefix-icon="
                  examData.login_rule == '1'
                    ? 'el-icon-mobile-phone'
                    : 'el-icon-message'
                "
              ></el-input>
              <div class="pr code-div">
                <el-input
                  class="psd-input"
                  :placeholder="
                    examData.login_rule == '3' ? '输入密码' : '输入验证码'
                  "
                  v-model="code"
                  prefix-icon="el-icon-lock"
                ></el-input>
                <span
                  class="getcodebtn pa cur"
                  v-if="examData.login_rule != '3'"
                  ><em></em><span class="cur">获取验证码</span></span
                >
              </div>
              <el-button class="logBtn" :style="this.buttonStyle"
                >验证</el-button
              >
            </div>
            <div class="" style="margin-top: 25px" v-if="judgesList.length">
              <p>验证通过，请点击下面的随机许可证参与项目</p>
              <ul class="mt20">
                <li v-for="(item, index) in judgesList" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--版权-->
    <div
      class="foot"
      v-if="this.transData.copyright && this.transData.copyright.show"
    >
      {{ this.transData.copyright.font }}
    </div>

    <!--必填项弹窗-->
    <el-drawer
      :visible.sync="isMustFill"
      size="40%"
      custom-class="asktrue-drawer"
      ><MustFill
        ref="mustFill"
        :id="Number($route.params.id)"
        :type="String(type)"
        :ownerId="vipId"
        :studentId="studentId"
        v-on:closeFill="isMustFill = false"
    /></el-drawer>
  </div>
</template>

<script>
// import {v4 as uuidv4} from 'uuid';
import MustFill from "../../ExamMustFill";
export default {
  components: { MustFill },
  props: { transData: Object },
  data() {
    return {
      stepStyle: {
        checked: "",
        begin: "",
        pass: "",
      },
      phone: "", //账号
      code: "", //验证码
      isMustFill: false, //必填项开关
      studentId: "", //考生id

      judgesList: [], //随机数列表

      examData: {},
      num: 0, //秒数
      myVar: "", //定时器
      active: false, //是否可以点击

      outerStyle: this.client(),
      step: 0,
      maxStep: 0,
      stepList: [],
      isInform: false,
      onG: false,
      inform: "",
      title: "",
      vipId: "",
      backgroundStyle: "", //设置的背景
      titleStyle: "", //设置的标题样式
      titleText: "标题", //设置的标题
      buttonStyle: "",
      // uuid: uuidv4().split('-').join(''),
    };
  },
  watch: {
    transData: {
      handler() {
        this.getStyle();
      },
      deep: true,
    },
    isInform(val) {
      if (val) {
        this.step = 2;
        this.maxStep = 2;
      } else {
        this.maxStep = 0;
      }
    },
  },
  created() {
    this.getStyle();
    this.type = this.$store.state.asktrue.projectInfo
      ? this.$store.state.asktrue.projectInfo.projectType
      : "";
    this.vipId = this.$store.state.asktrue.projectInfo
      ? this.$store.state.asktrue.projectInfo.vip_id
      : "";
    this.examData = this.$store.state.asktrue.projectInfo
      ? this.$store.state.asktrue.projectInfo
      : "";
    if (!this.vipId) {
      this.$watch("$store.state.project.projectInfo", (val) => {
        this.examData = val;
        if (val.vip_id) {
          this.vipId = val.vip_id;
          this.getExamInfoFn();
          this.type = val.projectType;
        }
      });
    } else {
      this.getExamInfoFn();
    }
    // if(!this.$storage.get("loginMac")){this.$storage.set("loginMac",this.uuid)}
    // this.getExamInfoFn();
  },
  methods: {
    changeStepStyle() {
      for (let prop in this.stepStyle) {
        this.stepStyle[prop] = this.transData.step[prop];
      }
    },
    stopVideo() {
      this.$refs.webcam.stopNavigator();
    },
    getStyle() {
      if (this.transData) {
        // 背景样式
        let backgroundType = this.transData.backgroundStyle.backgroundType;
        let typeDetail = this.transData.backgroundStyle.typeDetail;
        switch (backgroundType) {
          case "pure":
            this.backgroundStyle = "background:" + typeDetail;
            break;
          case "gradient":
            this.backgroundStyle =
              "background:linear-gradient(" +
              typeDetail[0] +
              "," +
              typeDetail[1] +
              ");";
            break;
          case "img":
            this.backgroundStyle =
              "background:url(" + typeDetail + ");background-size:cover;";
            break;
        }
        if (
          this.transData.backgroundStyle.backgroundType == "pure" &&
          this.transData.backgroundStyle.typeDetail
            .split("#")[1]
            .toLowerCase() == "3399cc"
        ) {
          this.onG = true;
        } else {
          this.onG = false;
        }
        let title = this.transData.title;
        this.titleText = title.text;
        this.titleStyle =
          "font-size:" +
          title.fontSize +
          ";color:" +
          title.color +
          ";font-weight:" +
          (title.bold ? "bold;" : "normal;");
        this.buttonStyle =
          "background: " +
          this.transData.button.backgroundColor +
          ";color:" +
          this.transData.button.fontColor +
          ";";
      }
    },
    //切换步骤
    changeStep(val) {
      if (this.maxStep >= val) this.step = val;
    },
    // 获取屏幕宽度
    client() {
      if (window.innerWidth) {
        // ie9+ 最新的浏览器
        return {
          width: window.innerHeight * 0.866 + "px",
          minHeight: window.innerHeight * 0.8 + "px",
        };
      } else if (document.compatMode === "CSS1Compat") {
        // W3C
        return {
          width: document.documentElement.clientHeight * 0.867 + "px",
          minHeight: document.documentElement.clientHeight * 0.8 + "px",
        };
      }
      return {
        width: document.body.clientHeight * 0.867 + "px",
        minHeight: document.body.clientHeight * 0.8 + "px",
      };
    },
    //获取考试信息
    getExamInfoFn() {
      this.title = this.examData.exam_name;
      this.inform = this.examData.exam_text.split("\n").join("<br>");
      this.stepList = [];
      if (this.inform) {
        let str =
          this.examData.projectType == 1
            ? "考试"
            : this.examData.projectType == 2
            ? "调查"
            : this.examData.projectType == 3
            ? "评估"
            : this.examData.projectType == 4
            ? "报名"
            : "投票";
        this.stepList.push({ title: str + "须知", step: 0 });
      }
      this.stepList.push({ step: 2, title: "资格验证" });
      this.step = this.stepList[0].step;
    },
    //获取随机数
    getJudges(len) {
      this.$message.closeAll();
      let reg = new RegExp("^1[0-9]{10}$");
      if (this.examData.login_rule == "1" && !reg.test(this.phone)) {
        this.$message("请输入正确的手机号获取项目许可");
        return false;
      }
      if (this.examData.login_rule == "2" && !this.phone) {
        this.$message("请输入邮箱");
        return false;
      }
      if (this.examData.login_rule == "3" && !this.phone) {
        this.$message("请输入考号");
        return false;
      }
      if (this.examData.login_rule == "3" && !this.code) {
        this.$message("请输入密码");
        return false;
      }
      this.judgesList = [];
      for (let i = 0; i < 6; i++) {
        this.judgesList.push(this.$utils.asktrue.createCode(len));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.exam-background {
  height: 100%;
  overflow: hidden;
  background: #fff;
  position: relative;
  .topBg {
    width: 100%;
    height: 45%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    img {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
  }
  .top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .logo {
      max-height: 60px;
      border-radius: 10px;
      overflow: hidden;
      width: fit-content;
      margin-right: 10px;
      flex-shrink: 0;
      img {
        max-height: 60px;
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #555;
      line-height: 36px;
      flex-shrink: 1;
    }
  }
  .main-box {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 640px;
    height: 435px;
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 2;

    .step-show {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      margin-top: 60px;
      height: 30px;
      padding: 0 70px;
      .back-border1 {
        flex-grow: 0;
        flex-shrink: 0;
        width: 140px;
        border-top: 1px dashed #aaa;
      }
      .back-border05 {
        flex-grow: 1;
        border-top: 1px dashed #aaa;
      }
      .border-on {
        border-color: #3399cc;
      }
      .border-on-g {
        border-color: rgb(5, 117, 173);
      }
      .el-icon-arrow-right {
        color: #39c;
        padding-top: 4px;
      }
      .sep {
        width: 14px;
      }
      .top-info-item {
        flex-grow: 0;
        position: relative;
        margin-top: -10px;
        color: #aaa;
        border-color: #aaa;
        .info {
          font-size: 12px;
          padding: 0 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          max-width: 40px;
          i {
            display: block;
            border: 1px solid;
            width: 22px;
            height: 22px;
            border-radius: 15px;
            line-height: 20px;
            margin-bottom: 6px;
          }
          .name {
            display: block;
            word-break: keep-all;
          }
        }
        &.on {
          position: relative;
          border-color: #3399cc;
          color: #39c;
        }
        &.active {
          font-size: 14px;
          color: #f60;
          border-color: #f60;
        }
      }
    }

    .main {
      flex: 1;
      margin: 0px auto;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 0px 70px 0px;
      background-color: #fff;
      &.main1,
      &.main2 {
        .plate {
          flex-direction: column;
          justify-content: center;
          .border-box {
            flex: none;
          }
        }
        /deep/ .el-input {
          .el-input__inner {
            height: 45px;
            border-radius: 23px;
            background-color: rgba(255, 255, 255, 0);
            padding: 0 20px 0 50px;
          }
          .el-input__prefix {
            left: 15px;
            font-size: 18px;
            .el-input__icon {
              line-height: 45px;
            }
          }
        }
        .logBtn {
          padding: 0px;
          width: 360px;
          height: 45px;
          border-radius: 23px;
        }
      }
      .plate {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        h2 {
          font-size: 15px;
          margin-bottom: 5px;
          position: relative;
          padding-left: 10px;
          color: #444;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0px;
            margin-top: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #444;
          }
        }

        .border-box {
          flex: 1;
          position: relative;
          min-height: 120px;
          border-radius: 5px;
          span.title {
            display: inline-block;
            margin-bottom: 10px;
            width: 80px;
            margin-left: 10px;
            font-size: 16px;
            margin-left: 126px;
            color: #666;
            position: relative;
            &.hav-after::before {
              content: "";
              position: absolute;
              top: 3px;
              left: -10px;
              width: 4px;
              height: 16px;
              border-radius: 5px;
              background-color: #39c;
            }
          }
          &.kqxz {
            width: 100%;
            height: 100%;
            padding-bottom: 40px;
            position: relative;
            padding: 0px 0px 40px 0;
            .inform {
              line-height: 180%;
              width: 100%;
              height: 100%;
              overflow: hidden;
              overflow-y: auto;
              border: 1px solid #e4e4e4;
              padding: 20px;
              border-radius: 8px;
            }
          }
        }

        /deep/ .hardwareCheck {
          width: 100%;
          // .el-tabs__content{
          .testing {
            display: flex;
            justify-content: space-between;
            .left-div {
              width: 35%;
              text-align: center;
              margin-right: 5%;
              padding-top: 20px;
              img {
                width: 60px;
                height: 60px;
              }
              p {
                line-height: 180%;
                color: #888;
              }
              .start-test {
                width: 100px;
                text-align: center;
                line-height: 30px;
                border-radius: 2px;
                background-color: #f60;
                color: #fff;
                cursor: pointer;
                font-size: 12px;
              }
              .common_status {
                height: 26px;
                div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.s1 {
                    color: #06b300;
                  }
                  &.s2 {
                    color: #f60;
                  }
                  &.s3 {
                    color: #000;
                  }
                  em {
                    font-size: 26px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                  span {
                    font-size: 15px;
                    font-weight: 700;
                    vertical-align: middle;
                  }
                }
              }
            }
            .right-div {
              width: 60%;
              p {
                line-height: 180%;
              }
            }

            .test_speed {
              width: 100%;
              margin: 0px auto 20px;
              a i {
                display: inline-block;
                width: 16px;
                text-align: center;
                height: 16px;
                line-height: 15px;
                margin-left: 3px;
                font-size: 12px;
              }
            }
            .web_speed {
              width: 100%;
              border: 1px solid #e5ebf0;
              margin: 0 auto;
              border-collapse: collapse;
            }
            .web_speed td {
              width: 50%;
              height: 48px;
              text-align: center;
              border-bottom: 1px solid #e5ebf0;
            }
            .web_speed .reco {
              color: #5bbcee;
            }
          }
          // }
        }

        /deep/ .login {
          .el-input {
            width: 360px;
            display: flex;
            margin: 30px auto 0;
            // input{border:0;border-bottom:1px solid#e4e4e4;}
          }
          .code-div {
            margin-bottom: 30px;
            .el-input__inner {
              padding-right: 100px;
            }
            .getcodebtn {
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              color: #999;
              position: relative;
              padding-left: 20px;
              em {
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 16px;
                background-color: #e4e4e4;
              }
            }
          }
        }
      }
      .check-two {
        padding: 30px 40px !important;
        .qr-code {
          width: 160px;
          height: 160px;
          padding: 5px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          img {
            width: 100%;
          }
          .checked {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            background: rgba(255, 255, 255, 0.9);
            color: #06b300;
            font-size: 18px;
            i {
              color: #06b300;
              margin-top: 44px;
              display: inline-block;
              font-size: 32px;
            }
          }
          &.yes .checked {
            display: block;
          }
        }
      }
    }
    &.mainMore {
      width: 850px;
      height: 500px;
      .main {
        height: 50%;
      }
    }
  }
  .foot {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #666;
    z-index: 1;
  }

  /deep/ .doc-pop {
    .el-drawer__body {
      padding: 30px 20px 20px;
      text-align: left;
      .el-image {
        height: unset;
      }
      img {
        width: 100%;
        cursor: pointer; //max-height: 190px;
        &.el-image-viewer__img {
          width: auto;
          max-height: auto;
        }
      }
    }
  }
}
</style>

<!--
自定义下拉框，适用于筛选下拉
使用方法
<g-selectset
  v-model="search.tab"          //value值
  :options="other.tabList"      //选项
  placeholder="类型"            //提示
  class="searchTab"             //样式
/>

// 其他参数可从下面的props中的备注查看作用
-->
<template>
  <div ref="selectSet" :class="['g-selectset', { on: isOn }, placement]">
    <div
      @click="disabled ? '' : clickFn()"
      :class="['selectTitle', { isDisabled: disabled }]"
    >
      <slot>
        <span
          class="value slh"
          :class="{ no: label == '' || label == null }"
          :title="label"
          >{{ label || placeholder }}</span
        >
        <i class="el-icon-arrow-down"></i>
      </slot>
    </div>
    <div class="select-box">
      <div class="noData" v-show="options.length == 0">暂无数据，请先新增</div>
      <ul class="option scroll" v-show="options.length > 0">
        <li
          v-for="(SelItem, SelIndex) in options"
          :key="'selectSet_' + SelIndex"
          @click="selectFn(SelItem)"
          :class="{ on: value == SelItem.value }"
        >
          <span>{{ SelItem.label }}</span>
          <div class="select-func" v-if="!SelItem.const && isEdit" @click.stop>
            <i
              class="iconfont iconbianji"
              title="编辑"
              @click="
                eid = SelItem.value;
                name = SelItem.label;
                isSet = true;
              "
            ></i>
            <i
              class="iconfont iconshanchu"
              title="删除"
              @click="delFn(SelItem.value)"
            ></i>
          </div>
        </li>
      </ul>
      <div class="g-selectset-box" v-if="isEdit">
        <div
          class="add"
          v-if="!isSet"
          @click="
            eid = 0;
            name = '';
            isSet = true;
          "
        >
          +新增
        </div>
        <div class="edit" v-else>
          <input type="text" v-model="name" />
          <button @click.stop="saveFn">保存</button>
          <button
            @click.stop="
              eid = 0;
              name = '';
              isSet = false;
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-selectset",
  props: {
    value: [Number, String], //双向绑定传承
    placeholder: String, //选框提示
    options: Array, //选框选项
    width: {
      type: String,
      default: "100px", //控制选框宽度
    },
    height: {
      type: String,
      default: "38px", //控制选框高度
    },
    disabled: { type: Boolean, default: false }, //是否禁用
    isEdit: { type: Boolean, default: true }, //控制表单选项是否允许增删改
    placement: { type: String, default: "bottom" }, //弹窗出现位置：top/bottom
  },
  data() {
    return {
      isOn: false,
      label: "",
      isSet: false, //是否编辑
      eid: 0, //编辑id，0=新增；>0编辑id
      name: "", //名称
    };
  },
  watch: {
    value: {
      handler() {
        this.setValue();
      },
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    clickFn() {
      this.$emit("click");
      this.isOn = !this.isOn;
      this.eid = 0;
      this.name = "";
      this.isSet = false;
    },
    setValue() {
      if (this.value) {
        let i = this.options.findIndex(
          (e) => String(e.value) == String(this.value)
        );
        if (i > -1) {
          this.label = this.options.find((e) => {
            return String(e.value) == String(this.value);
          }).label;
        }
      } else {
        this.label = "";
      }
    },
    selectFn(item) {
      if (item.noCheck) {
        this.$emit("input", item.value);
        this.isOn = false;
      } else {
        this.label = item.value ? item.label : "";
        this.checkedId = item.value;
        this.$emit("input", item.value);
        this.isOn = false;
      }
    },
    delFn(v) {
      this.name = "";
      this.eid = 0;
      this.$emit("del", v);
      if (v == this.checkedId) {
        this.label = "";
        this.checkedId = null;
        this.$emit("input", null);
        this.isSet = false;
      }
    },
    // 保存
    saveFn() {
      if (!this.name) {
        this.$message.warning("名称不能为空");
        return false;
      }
      this.$emit("save", { id: this.eid, name: this.name });
      this.isSet = false;
    },

    bodyCloseMenus(e) {
      let self = this;
      if (this.$refs.selectSet && !this.$refs.selectSet.contains(e.target)) {
        if (self.bankSwitch == true) {
          self.bankSwitch = false;
        } else {
          this.isOn = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="less" scoped>
.noData {
  padding: 0 15px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
}
.g-selectset {
  position: relative;
  border-radius: 2px;
  .selectTitle {
    position: relative;
    padding: 0 20px 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    // font-size: 12px;
    cursor: pointer;
    .value {
      white-space: nowrap;
      &.no {
        color: #999;
      }
    }
    .el-icon-arrow-down {
      position: absolute;
      top: 50%;
      right: 9px;
      color: #c0c4cc;
      transform: translateY(-50%);
    }

    &.isDisabled {
      cursor: no-drop;
      background-color: #f5f7fa;
      color: #c0c4cc;
    }
  }
  .select-box {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    min-width: calc(100% + 2px);
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0 0;
    z-index: 1983;
    display: none;
    .option {
      max-height: 170px;
      overflow: hidden;
      overflow-y: auto;
      li {
        width: 100%;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 0 15px;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        span {
          flex: 1;
          width: 0px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .select-func {
          width: 45px;
          display: none;
          justify-content: space-between;
          align-items: center;
          margin-right: -5px;
          i {
            width: 20px;
            height: 20px;
            font-size: 14px;
            color: #999;
            overflow: hidden;
            text-align: center;
            line-height: 20px;
          }
        }
        &:hover {
          background-color: #f5f7fa;
          .select-func {
            display: flex;
          }
        }
        &.on {
          color: @theme-general;
        }
      }
    }
    .g-selectset-box {
      width: 100%;
      height: 36px;
      border-top: 1px solid #e4e4e4;
      display: flex;
      align-items: center;
      .add {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 12px;
        color: #999;
      }
      .edit {
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          flex: 1;
          width: 50%;
          height: 26px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          font-size: 12px;
          padding: 10px;
        }
        button {
          margin-left: 5px;
          width: 40px;
          height: 26px;
          overflow: hidden;
          line-height: 24px;
          text-align: center;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          background-color: #fff;
          color: #999;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  &.on {
    .selectTitle {
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #e0e0e0;
        z-index: 1984;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #fff;
        z-index: 1985;
      }
      .el-icon-arrow-down {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .select-box {
      display: block;
    }
    &.top {
      .selectTitle {
        &::after {
          bottom: unset;
          top: -10px;
          left: 50%;
          border-bottom-color: transparent;
          border-top-color: #e0e0e0;
        }
        &::before {
          bottom: unset;
          top: -11px;
          left: 50%;
          border-bottom-color: transparent;
          border-top-color: #fff;
        }
      }
      .select-box {
        top: auto;
        bottom: calc(100% + 10px);
      }
    }
  }
}
</style>

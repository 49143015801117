//生成简历html的css
export const resumecss =`
  html,
  body {
    position: relative;
    height: 100%;
    color: #333;font-size: 14px;font-family: "微软雅黑", Arial, Helvetica, sans-serif;
  }
  li{ list-style: none;}
  input.uplode{position:absolute;top:0;width:100%!important;height:100%!important;margin:0!important;left:0;opacity:0;cursor:pointer;}

  /* 复制css/resume.css内容，将hover样式删除 */
  .jl-warp {
    padding: 40px 50px;
    width: 100%;
    /*简历编辑部分 */
  }
  .jl-warp ul{padding:0;}
  .jl-warp ul li {
    text-align: left;
  }
  .jl-warp ul li .top-div {
    height: 44px;
    line-height: 44px;
    background-color: #f2f2f2;
    padding: 0 15px;
    font-size: 16px;
  }
  .jl-warp ul li .top-div span {
    font-weight: 700;
  }
  .jl-warp ul li .top-div em {
    color: #39c;
    display: none;
    cursor: pointer;
  }
  .jl-warp ul li .top-div .sel-box {
    right: 0;
    top: 43px;
    background-color: #fff;
    font-size: 12px;
    border: 1px solid #e4e4e4;
    z-index: 11;
    display: none;
  }
  .jl-warp ul li .top-div .sel-box li {
    line-height: 30px;
    padding: 0 15px;
  }
  .jl-warp ul li .content {
    padding: 10px 15px;
    line-height: 30px;
  }
  .jl-warp ul li .content .con-left {
    width: 100%;
  }
  .jl-warp ul li .content .con-line i {
    margin-left: 10px;
    color: #ccc;
  }
  .jl-warp ul li .content .con-line span {
    margin: 0 10px;
  }
  .jl-warp ul li .content .con-line span:first-child {
    margin: 0;
  }
  .jl-warp ul li .content .area-read {
    max-width: calc(100% - 70px);
  }
  .jl-warp ul li .content .area-read.eva-area {
    max-width: calc(100% - 42px);
  }
  .jl-warp ul li .content .el-textarea__inner {
    border: 0;
    padding: 5px 0;
    resize: none;
    color: #333;
    font-family: inherit;
  }
  .jl-warp ul li .evaluation .area-read {
    max-width: 100%;
  }
  .jl-warp ul li .img-box {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #e4e4e4;
    overflow: hidden;
  }
  .jl-warp ul li .img-box img {
    width: 100%;
    height: 100%;
  }
  .jl-warp ul li .con-part {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 5px;
  }
  .jl-warp ul li .con-part .con-edit {
    right: 0;
    display: none;
  }
  .jl-warp ul li .con-part em {
    color: #39c;
    cursor: pointer;
  }
  .jl-warp ul li .con-part em.del-ico {
    font-size: 17px;
  }
  .jl-warp ul li .con-part:last-child {
    border: 0;
    padding-bottom: 0;
  }
  .jl-warp .edit-box {
    background-color: #f8f8f8;
    padding: 10px 40px;
    margin: 0 -6% 20px;
  }
  .jl-warp .edit-box .jl-left {
    flex: 1;
    max-width: 45%;
  }
  .jl-warp .edit-box .jl-right {
    flex: 1;
    max-width: 45%;
  }
  .jl-warp .edit-box .flex-end .el-form-item__content {
    justify-content: flex-end;
  }
  .jl-warp .edit-box .el-form-item__error {
    padding-top: 0;
  }
  .jl-warp .edit-box .form-line {
    display: flex;
    margin: 10px auto 0;
    align-items: center;
  }
  .jl-warp .edit-box .form-line .el-form-item__content {
    width: 100%;
    margin-left: 0!important;
    display: flex;
    align-items: center;
    line-height: 30px;
  }
  .jl-warp .edit-box .form-line .form-title {
    width: 110px;
    font-weight: 700;
    color: #666;
  }
  .jl-warp .edit-box .form-line .form-title i {
    color: #ea5d5d;
    padding-right: 10px;
    margin-left: -18px;
  }
  .jl-warp .edit-box .form-line .form-div {
    width: calc(100% - 110px);
  }
  .jl-warp .edit-box .form-line .form-div .el-date-editor.el-input,
  .jl-warp .edit-box .form-line .form-div .el-autocomplete,
  .jl-warp .edit-box .form-line .form-div .el-select {
    width: 100%;
  }
  .jl-warp .edit-box .form-line .form-div .el-date-editor.el-input .el-icon-date,
  .jl-warp .edit-box .form-line .form-div .el-autocomplete .el-icon-date,
  .jl-warp .edit-box .form-line .form-div .el-select .el-icon-date {
    display: none;
  }
  .jl-warp .edit-box .form-line .form-div .el-date-editor.el-input .el-input__icon,
  .jl-warp .edit-box .form-line .form-div .el-autocomplete .el-input__icon,
  .jl-warp .edit-box .form-line .form-div .el-select .el-input__icon {
    line-height: 30px;
  }
  .jl-warp .edit-box .form-line .form-div input,
  .jl-warp .edit-box .form-line .form-div .el-input__inner {
    width: 100%;
    height: 30px;
    line-height: 28px;
    border-radius: 3px;
    padding: 0 5px;
    overflow: hidden;
  }
  .jl-warp .edit-box .form-line .form-div .el-textarea__inner {
    padding: 5px;
  }
  .jl-warp .edit-box .form-line .form-div .el-date-editor .el-range__icon {
    display: none;
  }
  .jl-warp .edit-box .form-line .form-div .el-date-editor .el-range-separator {
    width: 10%;
    line-height: 30px;
    min-width: 20px;
  }
  .jl-warp .edit-box .form-line .form-div .el-radio {
    margin-right: 20px;
  }
  .jl-warp .edit-box .form-line .form-div .el-radio .el-radio__label {
    font-size: 13px;
    padding-left: 5px;
  }
  .jl-warp .edit-box .form-line .form-div .el-radio:last-child {
    margin-right: 0;
  }
  .jl-warp .edit-box .form-line .el-button {
    border-radius: 13px;
    height: 26px;
    line-height: 26px;
    width: 80px;
    padding: 0;
    margin: 5px 0 5px 20px;
  }
  /*.jl-warp .edit-box .form-line:last-child {
    margin-bottom: 0;
  }*/
  .jl-warp .edit-box .text300 {
    width: 300px!important;
  }
  .jl-warp .triangle .el-input__suffix {
    line-height: 28px;
    margin-top: -14px;
  }
  .jl-warp .triangle .el-input__suffix .el-input__icon {
    line-height: 30px;
  }
  .jl-warp li#attach {
    margin-bottom: 20px;
  }
}`
import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// //获取登录用户信息
// export const getUserInfoReq = data => getReq(apiUrl1+"/companyMemberCtl/selectUserInfoByToken",data)
export function getUserInfoReq(params) {
  return request({
    url: "/companyMemberCtl/selectUserInfoByToken",
    method: "get",
    params,
  });
}

// //新增、编辑认证信息
// export const postRzInfoReq = data => postReq(apiUrl1+"/attestation/save",data,{ContentType:'application/json;charset=UTF-8'})
export function postRzInfoReq(data) {
  return request({
    url: "/attestation/save",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// //查询认证信息
// export const getRzInfoReq = data => getReq(apiUrl1+"/attestation/select/"+data)
// //更改申请状态
// export const editRzStatusReq = data => postReq(apiUrl1+"/attestation/updateApplyStatus",data)
export function editRzStatusReq(data) {
  return request({
    url: "/attestation/updateApplyStatus",
    method: "post",
    data,
  });
}

// //查询认证信息列表，目前只有个人用户才会出现多条数据
// export const getRzListReq = data => getReq(apiUrl1+"/attestation/selectList",data)
export function getRzListReq(params) {
  return request({
    url: "/attestation/selectList",
    method: "get",
    params,
  });
}

export default {
  parent: "BPO",
  routers: [
    {
      path: ":page",
      name: "BpoView",
      component: () => import("@/views/bpo/ProviderList"),
    },
  ],
};

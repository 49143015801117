var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.props.show)?_c('div',[(!_setup.isPC)?_c('div',{class:[
      'bottom-popup',
      _setup.isUniFrameWork && 'bottom-popup-uni',
      !_setup.isPC && 'bottom-popup-h5',
      !_setup.isPC && _setup.props.modal && 'bottom-popup-modal',
    ],on:{"click":_setup.closeBottomPopup}},[_c('div',{ref:"dialogRef",class:['bottom-popup-main', !_setup.isPC && 'bottom-popup-h5-main'],style:({
        height: _setup.props.height,
        borderTopLeftRadius: _setup.props.borderRadius,
        borderTopRightRadius: _setup.props.borderRadius,
      }),on:{"click":function($event){$event.stopPropagation();}}},[(_vm.title || _vm.showHeaderCloseButton)?_c('div',{staticClass:"header"},[(_vm.title)?_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.showHeaderCloseButton)?_c('div',{staticClass:"header-close",on:{"click":_setup.closeBottomPopup}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("关闭"))+" ")]):_vm._e()]):_vm._e(),_vm._t("default"),(_vm.showFooterSubmitButton)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-submit",on:{"click":_setup.submit}},[_vm._v(_vm._s(_vm.submitButtonContent))])]):_vm._e()],2)]):_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
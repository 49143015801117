export default {
  parent: "SrmInvoice",
  routers: [
    {
      // 全部
      path: "all",
      name: "SrmInvoiceAll",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 待确认
      path: "wait",
      name: "SrmInvoiceWait",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 已确认
      path: "cfmd",
      name: "SrmInvoiceCfmd",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 已驳回
      path: "reject",
      name: "SrmInvoiceReject",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
  ],
};

/*
 * @Description: crm contract
 * @Date: 2024-01-22 15:39:27
 * @LastEditTime: 2024-04-28 14:30:30
 */
export default function (axios) {
  return {
    // /contract/selectPage  合同列表
    getCrmContractList(data, nose) {
      nose = nose ? nose : ""
      return axios.post("/contract/selectPage" + nose, data);
    },

    // /contract/saveContract  保存合同
    saveCrmContract(data) {
      return axios.post("/contract/saveContract", data);
    },

    // /contract/getContractById 合同详情
    getCrmContractDetail(params, nose) {
      nose = nose ? nose : "";
      return axios.get("/contract/getContractById" + nose, { params });
    },

    // /contract/deleteByIds 删除合同（可批量）
    deleteCrmContractsByIds(params) {
      return axios.post("/contract/deleteByIds", {}, { params });
    },

    // /contract/selectContactPersons  合同对接人列表
    selectContactPersons(data) {
      return axios.post("/contract/selectContactPersons", data);
    },
  };
}

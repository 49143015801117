//安排通知路由
export default {
  routers: [
    {
      //登录页
      path: "/tzLogin",
      name: "TzLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/login"], resolve),
      meta: { pageType: "tz" },
    },
    {
      //首页
      path: "/tzIndex",
      name: "TzIndex",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/index"], resolve),
      meta: { pageType: "tz", auth: true },
    },
    {
      //详情页
      path: "/tzDetail",
      name: "TzDetail",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/detail"], resolve),
      meta: { pageType: "tz", auth: true },
    },

    {
      //企业账号-t通知安排-模板预览
      path: "/NoticeTemplatePrev",
      name: "NoticeTemplate",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/noticeTemplate"], resolve),
      meta: { auth: true },
    },
    {
      //企业账号-通知确认页面
      path: "/NoticeTemplate",
      name: "NoticeTemplate",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/noticeTemplate"], resolve),
    },
    {
      //企业账号-通知自定义表单预览页面
      path: "/NoticeTemplatePrevPage",
      name: "NoticeTemplatePrev",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tz/noticeTemplatePrev"], resolve),
    },
  ],
};

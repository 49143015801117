var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-item-container",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex-space background-item"},[_c('div',[_vm._v("背景色")]),_c('div',{staticClass:"flex-center",staticStyle:{"margin-right":"-4px"}},[_c('el-color-picker',{staticClass:"cube-color",attrs:{"predefine":_vm.predefineColors,"size":"mini"},on:{"change":function($event){return _vm.change('pure')}},model:{value:(_vm.pure),callback:function ($$v) {_vm.pure=$$v},expression:"pure"}})],1)]),_c('div',{class:['background-item', { 'unset-height': _vm.unsetHeight.gradient }],on:{"click":function($event){return _vm.changeType('gradient')}}},[_c('div',{staticClass:"flex-space"},[_c('div',[_vm._v("渐变色")]),_c('div',{staticClass:"flex-center"},[(_vm.backgroundType == 'gradient')?_c('div',{staticClass:"cube-color-img",style:('background:linear-gradient(' +
            _vm.gradient[0] +
            ',' +
            _vm.gradient[1] +
            ');')}):_vm._e(),_c('i',{staticClass:"el-icon-arrow-down"})])]),_c('div',{staticClass:"linner-gra flex-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-color-picker',{staticClass:"no-border",attrs:{"predefine":_vm.predefineColors,"size":"mini"},on:{"change":function($event){return _vm.change('gradient')}},model:{value:(_vm.gradient[0]),callback:function ($$v) {_vm.$set(_vm.gradient, 0, $$v)},expression:"gradient[0]"}}),_c('div',{staticClass:"color-show",style:('background:linear-gradient(to right,' +
          _vm.gradient[0] +
          ',' +
          _vm.gradient[1] +
          ');')}),_c('el-color-picker',{staticClass:"no-border",attrs:{"predefine":_vm.predefineColors,"size":"mini"},on:{"change":function($event){return _vm.change('gradient')}},model:{value:(_vm.gradient[1]),callback:function ($$v) {_vm.$set(_vm.gradient, 1, $$v)},expression:"gradient[1]"}})],1)]),_c('div',{class:['background-item', { 'unset-height': _vm.unsetHeight.img }],on:{"click":function($event){return _vm.changeType('img')}}},[_vm._m(0),_c('div',{staticClass:"upload-img-outer"},[_c('el-upload',{staticClass:"img-container tac",attrs:{"action":_vm.apiUrl + '/minIOApi/oss/putObjects?fileDirType=comp_img',"headers":_vm.token,"on-success":_vm.handleSuccess,"accept":"image/png"}},[(_vm.img)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.img}}):_c('div',{staticClass:"el-icon-plus"})]),_c('p',{staticClass:"notes"},[_vm._v(" 注：背景图片拉伸显示;若图片上传失败，请尝试把图片像素调小或者剪切范围缩小后再上传。 ")])],1)]),_c('p',{staticClass:"notes"},[_vm._v("注：背景色、渐变色、背景图三选一；")]),_c('div',{staticClass:"flex-space"},[_c('el-button',{staticClass:"mb15",staticStyle:{"width":"62px"},attrs:{"size":"mini"},on:{"click":function($event){return _vm.resetData('', 'clear')}}},[_vm._v("清空")]),_c('el-button',{staticClass:"mb15",staticStyle:{"width":"62px"},attrs:{"size":"mini"},on:{"click":function($event){return _vm.resetData('', 'reset')}}},[_vm._v("重置")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-space"},[_c('div',[_vm._v("背景图")]),_c('div',[_c('i',{staticClass:"el-icon-arrow-down"})])])
}]

export { render, staticRenderFns }
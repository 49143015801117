<template>
  <div
    :class="'container pr flex-center container-' + type"
    :style="backgroundStyle"
  >
    <div class="" :style="scaleStyle + 'width: 900px'">
      <span class="iconfont iconzuojiankuohao pa">返回</span>
      <div class="detail-container">
        <div
          class="top-img"
          :style="
            headStyle
              ? {
                  backgroundImage:
                    'url(' + headStyle ||
                    require('@/assets/image/asktrue/topBg/ast1.jpg') + ')',
                  backgroundSize: 'cover',
                }
              : ''
          "
        ></div>
        <div class="borderbottom10 title-area pr">
          <div class="pa qr-code" id="qrCode"></div>
          <div class="pa wchat-qr-code">
            <div class="weixin" style="display: inline">
              <em class="iconfont iconweixin mr5 cur"></em>
              <div class="wcode">
                <div class="img-outer" id="wCQrCode"></div>
                <p class="f14">微信扫码分享</p>
              </div>
            </div>
            <em class="iconfont iconqq ml15 mr5 cur"></em>
            <em class="iconfont iconxinlang ml15 mr5 cur pr"></em>
          </div>
          <div class="title">
            {{
              (type != "wj" && type != "wjS" && $route.query.type != "wj"
                ? detail.projectName
                : detail.papertName) || "--"
            }}
          </div>
          <div class="time">
            {{
              (type != "wj" && type != "wjS" && $route.query.type != "wj"
                ? detail.insertTime
                : detail.createTime) || "--"
            }}
            发布
          </div>
          <div class="detail">
            <span
              ><i class="f60">{{
                detail.minAmount ? "¥" + detail.minAmount : "免费"
              }}</i
              ><em class="ml5 el-icon-arrow-down"></em
            ></span>
            <span
              >样本<i>{{
                (type != "wj" && type != "wjS" && $route.query.type != "wj"
                  ? detail.studentAmount
                  : detail.studentSum) || 0
              }}</i></span
            >
            <span
              >评论<i>{{ evaList.length }}</i></span
            >
            <span>{{ detail.nickName || detail.userName || "--" }}</span>
          </div>
          <div
            class="mt15"
            v-if="type != 'wj' && type != 'wjS' && $route.query.type != 'wj'"
          >
            <el-button
              v-if="Boolean(detail.minAmount) != false"
              type="primary"
              size="mini"
              >立即购买</el-button
            >
            <el-button v-else type="primary" size="mini">立即参加</el-button>
            <el-button size="mini" class="ml20" style="width: 80px"
              >收藏</el-button
            >
          </div>
          <div
            class="mt15"
            v-if="type == 'wj' || type == 'wjS' || $route.query.type == 'wj'"
          >
            <el-button
              v-if="Boolean(detail.minAmount) == false"
              type="primary"
              size="mini"
              >立即引用</el-button
            >
            <el-button v-else type="primary" size="mini">立即购买</el-button>
            <el-button size="mini" class="ml20" style="width: 80px"
              >收藏</el-button
            >
            <el-button size="mini" class="ml20" style="width: 80px"
              >预览</el-button
            >
          </div>
        </div>
        <div :class="[{ borderbottom10: transData.commons }, 'project-detail']">
          <div class="project-and-commons-title">
            {{
              type == "wj" || type == "wjS" || $route.query.type == "wj"
                ? "问卷说明"
                : "项目说明"
            }}
          </div>
          <p v-html="transData.introHtml || detail.paperNote"></p>
        </div>
        <div class="commons-area" v-if="transData.commons">
          <div class="project-and-commons-title">用户评论</div>
          <ul class="common-list" v-if="evaList.length">
            <li v-for="(item, index) in evaList" :key="index">
              <div class="flex">
                <div class="mr15 header-img">
                  <img v-if="item.userLogo" :src="item.userLogo" /><img
                    v-else
                    src="@/assets/image/asktrue/error/face.png"
                  />
                </div>
                <div class="tal f12">
                  <div class="c9 mb5">
                    {{ item.commentTime }}<i class="ml10">发布</i>
                  </div>
                  <div class="c6">{{ item.comment }}</div>
                </div>
              </div>
              <div
                v-if="item.replyComment"
                class="mt10 tal f12 c9"
                style="padding-left: 50px; margin-bottom: -10px"
              >
                <div style="background: #f8f8f8; padding: 5px">
                  商家回复：{{ item.replyComment }}
                </div>
              </div>
            </li>
            <el-button size="mini" class="c9 mt20" @click="toPage('fComments')"
              >更多评论<i class="el-icon-d-arrow-right ml5"></i
            ></el-button>
          </ul>
          <div v-else>
            <p class="mt20 mb20 ccc f12">暂无评论，快去抢沙发！</p>
            <el-button size="mini" class="c9 mb20" @click="toPage('fComments')"
              >发布评论</el-button
            >
          </div>
        </div>
      </div>
      <div style="color: #777; padding: 20px 0 35px" class="f12">
        Asktrue 提供技术支持
      </div>
    </div>
  </div>
</template>
<script>
import { getProDetailReq, getProEvaReq } from "@/api/asktrue/project/commodity";
import {
  selectAnonPaperById,
  selectCommentByPaperId,
} from "@/api/asktrue/paper/paper";
import Bus from "@/views/staff/js/bus";
export default {
  data() {
    return {
      item: { price: 10 },
      backgroundStyle: "",
      headStyle: "",
      detail: {},
      examAmounts: [],
      evaList: [],
      htmlInfo: "",
    };
  },
  watch: {
    transData: {
      handler() {
        this.getStyle();
      },
      deep: true,
    },
    id: {
      handler() {
        this.getDetailFn();
        this.getEvaListFn();
      },
    },
  },
  created() {
    let that = this;
    this.$nextTick(() => {
      that.$utils.asktrue.creatQrFn(
        "http://toc.matcheasy.cn",
        "qrCode",
        52,
        52
      );
      that.$utils.asktrue.creatQrFn(
        "http://toc.matcheasy.cn",
        "wCQrCode",
        80,
        80
      );
    });
    this.createdGetData();
    Bus.$on(
      "getDetail",
      function () {
        this.createdGetData();
      }.bind(this)
    );
    Bus.$on(
      "explainChange",
      function (val, type) {
        this.htmlInfo = val;
        if (type) {
          this.detail.paperNote = val;
        }
      }.bind(this)
    );
  },
  deactivated() {
    //离开页面取消监听
    Bus.$off("getDetail");
    Bus.$off("explainChange");
  },
  methods: {
    createdGetData() {
      this.getStyle();
      if (this.id) {
        this.getDetailFn();
        this.getEvaListFn();
      }
    },
    getStyle() {
      let backgroundType = this.transData.backgroundStyle.backgroundType;
      let typeDetail = this.transData.backgroundStyle.typeDetail;
      let headerImg;
      if (this.transData.headerImg) {
        headerImg = this.transData.headerImg;
      } else {
        headerImg = require("@/assets/image/asktrue/topBg/ast1.jpg");
      }
      switch (backgroundType) {
        case "pure":
          this.backgroundStyle = "background:" + typeDetail;
          break;
        case "gradient":
          this.backgroundStyle =
            "background:linear-gradient(" +
            typeDetail[0] +
            "," +
            typeDetail[1] +
            ");";
          break;
        case "img":
          this.backgroundStyle = "background:url(" + typeDetail + ")";
          break;
      }
      this.headStyle =
        headerImg.indexOf("@/") > -1
          ? require("@/assets/image/asktrue/topBg/ast" +
              headerImg.match(/[0-9]{1,2}/) +
              ".jpg")
          : headerImg;
    },
    //获取详情信息
    getDetailFn() {
      if (this.type != "wj" && this.type != "wjS") {
        let params = {
          projectId: this.id,
          projectType: this.type,
        };
        getProDetailReq(params).then((res) => {
          if (res.code == "200") {
            this.detail = res.data.result;
            console.log(this.detail, "this.detail======");
            if (this.htmlInfo) {
              this.detail.goodsText = this.htmlInfo;
            }
            this.examAmounts = res.data.result.examAmounts || [];
            this.getPrice();
          }
        });
      } else {
        //问卷
        selectAnonPaperById({ paperId: this.id }).then((res) => {
          if (res.code == "200") {
            this.detail = res.data.result;
            if (this.htmlInfo) {
              this.detail.paperNote = this.htmlInfo;
            }
            this.examAmounts = res.data.result.examAmounts || [];
            this.getPrice();
          }
        });
      }
    },
    //获取套餐费用
    getPrice() {
      console.log(this.examAmounts, "ttttc");
      let arr = [];
      this.examAmounts.map((item) => {
        if (this.type != "wj" && this.type != "wjS") {
          arr.push(item.examAmount);
        } else {
          arr.push(item.paperAmount);
        }
      });
      if (arr.length > 1) {
        let max = Math.max.apply(Math, arr);
        let min = Math.min.apply(Math, arr);
        this.detail.maxAmount = max;
        this.detail.minAmount = min;
      } else {
        this.detail.maxAmount = arr[0];
        this.detail.minAmount = arr[0];
      }
    },
    //获取评论信息
    getEvaListFn() {
      if (this.type != "wj" && this.type != "wjS") {
        //项目详情页面
        let params = {
          pageNum: 1,
          pageSize: 5,
          paperId: this.id,
          projectType: this.type,
        };
        getProEvaReq(params).then((res) => {
          if (res.code == "200") {
            this.evaList = res.data.result.list;
          }
        });
      } else {
        let params = { pageNum: 1, pageSize: 5, paperId: this.id };
        selectCommentByPaperId(params).then((res) => {
          if (res.code == "200") {
            this.evaList = res.data.result.list;
          }
        });
      }
    },
  },
  props: {
    type: String, //wj-问卷,1/2/3/4/5项目的类型，wjS问卷外观设置
    transData: Object,
    scaleStyle: String,
    id: { required: true },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #fff;
  padding-top: 50px;
  min-height: 100%;
  span.iconzuojiankuohao {
    left: 48px;
    top: 40px;
    color: var(--second-theme-color);
    cursor: pointer;
    font-size: 15px;
    display: none;
    &:before {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .detail-container {
    padding: 0px;
    color: rgb(102, 102, 102);
    border-radius: 3px;
    overflow: hidden;
    width: 900px;
    margin: 0px auto;
    min-height: 297px;
    background: #fff;
    // box-shadow: #9c9c9c 0px 0px 4px 0px;
    .borderbottom10 {
      border-bottom: 10px solid #f2f2f2;
    }
    .top-img {
      width: 900px;
      background: url("~@/assets/image/asktrue/banner/banner1.jpg") center
        center no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 270px;
    }
    .title-area {
      line-height: 30px;
      text-align: center;
      padding: 50px 15px 35px;
      color: #999;
      font-size: 12px;
      .qr-code {
        left: 20px;
        top: 20px;
        height: 62px;
        padding: 4px;
        z-index: 1;
        border: 1px solid #e4e4e4;
      }
      .wchat-qr-code {
        right: 20px;
        top: 20px;
        z-index: 1;
        .iconweixin:hover {
          color: #6cc630;
        }
        .iconqq:hover {
          color: #02befd;
        }
        .iconxinlang:hover {
          color: #e6162d;
        }
        .wcode {
          display: none;
          position: absolute;
          border-radius: 3px;
          top: 25px;
          right: 80px;
          width: 160px;
          height: 160px;
          padding-top: 20px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          text-align: center;
          .img-outer {
            width: 100px;
            margin-left: 30px;
            padding: 10px;
            background: #fff;
          }
        }
        .weixin:hover .wcode {
          display: block;
        }
      }
      .title {
        font-size: 24px;
        color: rgb(102, 102, 102);
        font-weight: 400;
        padding: 0px 150px;
        line-height: 150%;
        margin-top: 0px;
      }
      .time {
        margin-top: 15px;
      }
      .detail {
        margin-top: 5px;
        span {
          display: inline-block;
          padding-left: 10px;
          margin: 0 8px;
          position: relative;
          color: #999;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0px;
            margin: -2px 0px 0px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgb(204, 204, 204);
          }
          i {
            margin: 0 5px;
            color: var(--second-theme-color);
          }
        }
      }
    }
    .project-detail {
      padding: 27px 100px 50px;
      line-height: 180%;
      text-align: left;
    }
    .commons-area {
      padding: 27px 100px 50px;
      .common-list {
        li {
          border-bottom: 1px solid #f2f2f2;
          padding: 25px 0 22px 0px;
          &:first-child {
            padding-top: 0px;
          }
          .header-img {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .project-and-commons-title {
      font-size: 18px;
      color: rgb(102, 102, 102);
      font-weight: 400;
      position: relative;
      text-align: center;
      height: 33px;
      margin-bottom: 20px;
      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 40px;
        height: 4px;
        margin-left: -20px;
        background-color: rgb(228, 228, 228);
        border-radius: 10px;
      }
    }
  }
}
.container-wj {
  background: #fff !important;
  .detail-container {
    box-shadow: unset;
    border: 0px !important;
  }
  span.iconzuojiankuohao {
    display: block;
  }
}
</style>

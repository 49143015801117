export default function (axios) {
  return {
    getStaffListReq(params) {
      return axios.get("/compStffMember/selectCompStffMember", { params });
    },
    // 海报提交申请
    matcheasyPosterReq(params) {
      return axios.post("/anon/trial/insertTrial", params);
    },
    // 海报获取验证码
    matcheasyCoderReq(userLoginCode, type) {
      return axios.get("/anon/verifyCode", {
        params: { userLoginCode, verifyType: type ? type : 2 },
      });
    },

    matcheasyCompNameRepeat(params) {
      return axios.post(
        "/anon/trial/anon/applyTrial/selectCompNameExist",
        {},
        { params }
      ); //查找公司名是否重复
    },
  };
}

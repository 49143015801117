<template>
  <div class="tempWrap no-scroll" :style="backgroundStyle">
    <div class="topBg">
      <div class="top">
        <div class="logo" v-if="transData.logo.show">
          <img v-show="transData.logo.show" :src="transData.logo.img" />
        </div>
        <div class="title" :style="titleStyle">{{ titleText }}</div>
      </div>
    </div>

    <div class="bodyBox">
      <div class="tempBody">
        <!--考前须知-->
        <div
          class="kqxz"
          v-show="step == 1"
          :style="
            'background-color:' +
            transData.loginbox.rgba +
            ';border-color:' +
            transData.loginbox.borderColor +
            ';border-width:' +
            (transData.loginbox.show ? 1 : 0) +
            'px;'
          "
        >
          <h3><span>须知</span></h3>
          <div class="content no-scroll" v-html="inform">{{ inform }}</div>
          <div class="flex-space mt15">
            <el-checkbox v-model="checked" class="flex"
              ><p class="f14">我已阅读并同意遵守规定</p></el-checkbox
            >
            <p
              v-if="checked"
              @click="prevStep(2)"
              style="line-height: 19px; color: #bbb"
              class="el-icon-right"
            ></p>
          </div>
        </div>
        <div
          v-show="step == 2"
          v-if="
            (examData.projectType == 2 || examData.projectType == 3) &&
            examData.real_name == 2
          "
        >
          <div
            class="formBox"
            :style="
              'background-color:' +
              transData.loginbox.rgba +
              ';border-color:' +
              transData.loginbox.borderColor +
              ';border-width:' +
              (transData.loginbox.show ? 1 : 0) +
              'px;'
            "
          >
            <div class="mb20 f60">
              注：本项目采用匿名方式，但限制了项目参与范围，请输入限制范围内的手机或邮箱获取项目参与许可!
            </div>
            <input
              class="text"
              :placeholder="
                examData.login_rule == '1' ? '输入手机号' : '输入电子邮箱'
              "
              :style="
                'border-color:' +
                transData.loginbox.borderColor +
                '!important;color:' +
                transData.loginbox.borderColor +
                '!important;'
              "
            />

            <div class="login-input">
              <input
                type="text"
                class="text"
                placeholder="输入验证码"
                :style="
                  'border-color:' +
                  transData.loginbox.borderColor +
                  '!important;color:' +
                  transData.loginbox.borderColor +
                  '!important;'
                "
              />
              <p
                slot="suffix"
                :style="'color:' + transData.loginbox.borderColor"
              >
                <em
                  :style="'background-color:' + transData.loginbox.borderColor"
                ></em>
                <span class="f14">获取验证码</span>
              </p>
            </div>
            <el-button
              :style="
                'border-color:' +
                transData.loginbox.borderColor +
                '!important;color:' +
                transData.loginbox.borderColor +
                '!important;'
              "
              >验证</el-button
            >
          </div>

          <div class="mt30" v-if="judgesList.length">
            <p>请点击下面的随机许可证参与项目</p>
            <ul class="mt5 flex c9">
              <li v-for="(item, index) in judgesList" :key="index" class="mr10">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="formBox"
          v-show="step == 2"
          v-else-if="examData.login_rule == '3'"
          :style="
            'background-color:' +
            transData.loginbox.rgba +
            ';border-color:' +
            transData.loginbox.borderColor +
            ';border-width:' +
            (transData.loginbox.show ? 1 : 0) +
            'px;'
          "
        >
          <input
            type="text"
            class="text"
            placeholder="输入通行证"
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
          />
          <input
            type="text"
            class="text"
            placeholder="输入密码"
            show-password
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
          />
          <el-button
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
            >登录</el-button
          >
        </div>
        <div
          class="formBox"
          v-show="step == 2"
          v-else-if="examData.login_rule == '1' || examData.login_rule == '2'"
          :style="
            'background-color:' +
            transData.loginbox.rgba +
            ';border-color:' +
            transData.loginbox.borderColor +
            ';border-width:' +
            (transData.loginbox.show ? 1 : 0) +
            'px;'
          "
        >
          <input
            type="text"
            class="text"
            :placeholder="
              examData.login_rule == '1' ? '输入手机号码' : '输入电子邮箱'
            "
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
          />
          <div class="login-input">
            <input
              type="text"
              class="text"
              placeholder="输入验证码"
              :style="
                'border-color:' +
                transData.loginbox.borderColor +
                '!important;color:' +
                transData.loginbox.borderColor +
                '!important;'
              "
            />
            <p slot="suffix" :style="'color:' + transData.loginbox.borderColor">
              <em
                :style="'background-color:' + transData.loginbox.borderColor"
              ></em>
              <span>免费获取</span>
            </p>
          </div>
          <el-button
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
            >登录</el-button
          >
        </div>
        <div
          class="formBox"
          v-show="step == 2"
          v-else-if="examData.login_rule == '4'"
          :style="
            'background-color:' +
            transData.loginbox.rgba +
            ';border-color:' +
            transData.loginbox.borderColor +
            ';border-width:' +
            (transData.loginbox.show ? 1 : 0) +
            'px;'
          "
        >
          <input
            type="text"
            class="text"
            placeholder="输入姓名"
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
          />
          <input
            type="text"
            class="text"
            placeholder="输入身份证号"
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
          />
          <el-button
            :style="
              'border-color:' +
              transData.loginbox.borderColor +
              '!important;color:' +
              transData.loginbox.borderColor +
              '!important;'
            "
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transData: Object,
  },
  watch: {
    checked(val) {
      if (val) {
        this.step = 2;
        this.activeIndex = 1;
      }
    },
    transData: {
      handler() {
        this.getStyle();
      },
      deep: true,
    },
  },
  data() {
    return {
      backgroundStyle: "", //设置的背景
      titleStyle: "", //设置的标题样式
      titleText: "", //设置的标题
      showTitle: false,
      buttonStyle: "",
      passCheck: "", //通行证
      password: "", //密码
      logoStyle: "",
      checked: false,
      step: 0,
      stepList: [],
      activeIndex: 0,
      inform: "",
      boxHeight: 0,
      examData: {},
      judgesList: [],
    };
  },
  methods: {
    //获取随机数
    getJudges(len) {
      this.judgesList = [];
      for (let i = 0; i < 6; i++) {
        this.judgesList.push(this.$utils.asktrue.createCode(len));
      }
    },
    prevStep(pre) {
      if (pre == 1) {
        this.step = this.stepList[this.activeIndex - 1].step;
        this.activeIndex--;
      } else {
        this.step = this.stepList[this.activeIndex + 1].step;
        this.activeIndex++;
      }
    },
    getStyle() {
      // 背景样式
      let backgroundType = this.transData.backgroundStyle.backgroundType;
      let typeDetail = this.transData.backgroundStyle.typeDetail;
      switch (backgroundType) {
        case "pure":
          this.backgroundStyle = "background:" + typeDetail;
          break;
        case "gradient":
          this.backgroundStyle =
            "background:linear-gradient(" +
            typeDetail[0] +
            "," +
            typeDetail[1] +
            ");";
          break;
        case "img":
          this.backgroundStyle =
            "background:url(" + typeDetail + ");background-size:cover;";
          break;
      }
      if (
        this.transData.backgroundStyle.backgroundType == "pure" &&
        this.transData.backgroundStyle.typeDetail &&
        this.transData.backgroundStyle.typeDetail.split("#")[1].toLowerCase() ==
          "3399cc"
      ) {
        this.onG = true;
      } else {
        this.onG = false;
      }
      let title = this.transData.title;
      this.titleText = title.text;
      this.titleStyle =
        "font-size:" +
        title.fontSize +
        ";color:" +
        title.color +
        ";font-weight:" +
        (title.bold ? "bold;" : "normal;");
      this.buttonStyle =
        "background: " +
        this.transData.button.backgroundColor +
        "!important;color:" +
        this.transData.button.fontColor +
        "!important;border:0px;";
      if (!this.transData.loginbox) {
        this.$set(this.transData, "loginbox", {
          show: true,
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          opacity: 50,
          rgba: "rgba(255,255,255,0.5)",
        });
      } else {
        //
      }
    },
    getProInfo() {
      this.examData = this.$store.state.asktrue.projectInfo;
      if (this.examData.exam_text) {
        this.inform = this.examData.exam_text.split("\n").join("<br>");
        this.stepList.push({ step: 1, name: "考试须知" });
      }
      this.stepList.push({ step: 2, name: "登录信息" });
      this.step = this.stepList[0].step;
    },
  },
  created() {
    this.getStyle();
    this.getProInfo();
  },
};
</script>

<style lang="less" scoped>
.tempWrap {
  height: 100%;
  overflow-y: auto;
  background: #fff;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .topBg {
    width: 100%;
    height: auto;
    min-height: 150px;
    padding: 20px 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    img {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
  }
  .bodyBox {
    width: 100%;
    height: 50%;
    flex: 1;
    margin: 10px 0 50px;
    padding: 0 30px;
    .tempBody {
      height: 100%;
      .kqxz {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        h3 {
          margin-bottom: 15px;
        }
        .content {
          flex: 1;
          overflow: hidden;
          overflow-y: auto;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          padding: 15px;
          text-align: left;
          line-height: 180%;
          position: relative;
        }
      }
      /deep/.formBox {
        margin: 0px;
        padding: 40px 30px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        .text {
          display: block;
          margin-bottom: 25px;
          width: 100%;
          height: 40px;
          line-height: 40px !important;
          border: 1px solid #fff !important;
          border-radius: 20px;
          padding: 0 20px;
          background-color: rgba(255, 255, 255, 0);
          color: #fff;
          &::-webkit-input-placeholder {
            color: var(--textColor) !important; /* WebKit browsers */
          }
          &:-moz-placeholder {
            color: var(--textColor) !important; /* Mozilla Firefox 4 to 18 */
          }
          &::-moz-placeholder {
            color: var(--textColor) !important; /* Mozilla Firefox 19+ */
          }
          &:-ms-input-placeholder {
            color: var(--textColor) !important; /* Internet Explorer 10+ */
          }
        }
        .login-input {
          position: relative;
          margin-bottom: 25px;
          .text {
            margin-bottom: 0px;
          }
          p {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            padding-left: 20px;
            em {
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
              width: 2px;
              height: 16px;
              background-color: #e4e4e4;
            }
          }
        }
        .el-button {
          width: 100%;
          display: block;
          height: 40px;
          border-radius: 20px;
          background-color: rgba(255, 255, 255, 0) !important;
          border: 1px solid #fff !important;
          color: #fff;
        }
      }
    }
  }
}
</style>

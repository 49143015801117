<template>
  <div class="title-item-container no-select" @click.stop="">
    <div class="title-item">
      <el-input
        size="mini"
        @input="change"
        v-model="text"
        class="title-text-input"
        placeholder="输入标题"
      ></el-input>
    </div>
    <div class="title-item flex-space">
      <div>字号</div>
      <div class="flex-center flex-space">
        <el-select
          size="mini"
          class="font-size-select"
          v-model="fontSize"
          @change="change"
          popper-class="center-el-select"
        >
          <i slot="prefix" class="el-icon-arrow-down"></i>
          <el-option
            v-for="item in 36"
            :key="item"
            :value="item + 'px'"
            v-show="item > 15"
            >{{ item }}px</el-option
          >
        </el-select>
      </div>
    </div>
    <div class="title-item flex-space">
      <div>颜色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change"
          v-model="color"
          class="cube-color"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
    <div class="title-item flex-space">
      <div>加粗</div>
      <div class="flex-center">
        <el-switch
          @change="change"
          v-model="bold"
          active-color="#39c"
          inactive-color="#ccc"
        ></el-switch>
      </div>
    </div>
    <div class="title-item flex-space" v-if="showShow">
      <div>显示</div>
      <div class="flex-center">
        <el-switch
          @change="change"
          v-model="show"
          active-color="#39c"
          inactive-color="#ccc"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { title: Object, showShow: { type: Boolean, default: false } },
  data() {
    return {
      text: "",
      fontSize: "",
      color: "",
      bold: false,
      show: false,
    };
  },
  watch: {
    title: {
      handler(val) {
        for (let prop in val) {
          this[prop] = this.title[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    for (let prop in this.title) {
      this[prop] = this.title[prop];
    }
  },
  methods: {
    change() {
      let obj = {
        text: this.text,
        fontSize: this.fontSize,
        color: this.color,
        bold: this.bold,
      };
      if (this.showShow) {
        obj.show = this.show;
      }
      this.$emit("dealChange", obj, "title");
    },
  },
};
</script>

<style lang="less" scoped>
.title-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .title-item {
    line-height: 50px;
    border-bottom: 1px dotted #e4e4e4;
    height: 51px;
    overflow: hidden;
    /deep/ .title-text-input {
      .el-input__inner {
        border: 0px !important;
        background: transparent;
        padding: 0px;
      }
    }
    /deep/ .font-size-select {
      // .el-input__inner
      width: 90px;
      .el-input__inner {
        padding-right: 20px;
        background: transparent;
        border: 0px !important;
        text-align: right;
      }
      .el-input__suffix {
        display: none;
      }
      .el-input__prefix {
        right: 0px;
        color: #333;
        width: 10px;
        left: unset;
      }
    }
    /deep/ .cube-color .el-color-picker__trigger {
      padding: 3px;
      border-color: transparent;
      margin-right: 20px;
      .el-color-picker__color {
        border-radius: 2px;
        border: 1px solid #e4e4e4;
        overflow: hidden;
      }
      .el-color-picker__icon {
        left: unset;
        color: #333;
        right: -37px;
        font-size: 15px;
      }
    }
  }
}
</style>

<template>
  <div class="control-warpper" id="mydiv" v-show="isSet">
    <!-- <i class="el-icon-close c9" style="" @click="$emit('dealShow', false)"></i> -->
    <!-- 登录页控制 -->
    <ul class="control-item">
      <li
        :class="{ active: activeControl == 1 }"
        @click="changeActiveControl(1)"
        v-if="transData.login.exist.indexOf('backgroundStyle') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">背景</div>
          <div class="flex-center">
            <div class="cube-container">
              <div
                v-if="login.backgroundStyle.backgroundType == 'pure'"
                :style="
                  'background:' +
                  login.backgroundStyle.typeDetail +
                  ';' +
                  (login.backgroundStyle.typeDetail == '#fff' ||
                  login.backgroundStyle.typeDetail == '#FFF' ||
                  login.backgroundStyle.typeDetail == '#ffffff' ||
                  login.backgroundStyle.typeDetail == '#FFFFFF'
                    ? 'border:1px solid #f2f2f2;'
                    : '')
                "
              ></div>
              <div
                v-else-if="login.backgroundStyle.backgroundType == 'gradient'"
                :style="
                  'background:linear-gradient(' +
                  login.backgroundStyle.typeDetail[0] +
                  ',' +
                  login.backgroundStyle.typeDetail[1] +
                  ');'
                "
              ></div>
              <img v-else :src="login.backgroundStyle.typeDetail" />
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <BackgroundLogin
          :backgroundStyle="login.backgroundStyle"
          @dealChange="dealChange"
        />
      </li>
      <li
        :class="{ active: activeControl == 2 }"
        @click="changeActiveControl(2)"
        v-if="transData.login.exist.indexOf('title') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">标题</div>
          <div class="flex-center">
            <div
              class="title-container ellipsis"
              :style="
                'padding-right: 9px;max-width: 140px;color:' + login.title.color
              "
            >
              {{ login.title.text }}
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <TitleLogin :title="login.title" @dealChange="dealChange" />
      </li>
      <li
        :class="{ active: activeControl == 6 }"
        @click="changeActiveControl(6)"
        v-if="transData.login.exist.indexOf('step') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">导航</div>
          <div class="flex-center">
            <div class="cube-container">
              <div
                :style="'background:' + login.step.pass.borderColor + ';'"
              ></div>
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <StepColor :stepData="login.step" @dealChange="dealChange" />
      </li>
      <li
        :class="{ active: activeControl == 8 }"
        @click="changeActiveControl(8)"
        v-if="transData.login.exist.indexOf('loginbox') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">登录框</div>
          <div class="flex-center">
            <div></div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <LoginBox :loginbox="login.loginbox" @dealChange="dealChange" />
      </li>
      <li
        :class="{ active: activeControl == 3 }"
        @click="changeActiveControl(3)"
        v-if="transData.login.exist.indexOf('logo') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">Logo</div>
          <div class="flex-center">
            <div class="cube-container flex-center">
              <img v-if="login.logo.img" :src="login.logo.img" />
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <LogoLogin :logo="login.logo" @dealChange="dealChange" />
      </li>
      <li
        :class="{ active: activeControl == 4 }"
        @click="changeActiveControl(4)"
        v-if="transData.login.exist.indexOf('sidePicture') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">插画</div>
          <div class="flex-center">
            <div class="cube-container">
              <img v-if="login.sidePicture.img" :src="login.sidePicture.img" />
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <SidePictureLogin
          :sidePicture="login.sidePicture"
          @dealChange="dealChange"
        />
      </li>
      <li
        :class="{ active: activeControl == 5 }"
        @click="changeActiveControl(5)"
        v-if="transData.login.exist.indexOf('button') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">按钮</div>
          <div class="flex-center">
            <div class="cube-container">
              <div
                :style="'background:' + login.button.backgroundColor + ';'"
              ></div>
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <ButtonLogin :button="login.button" @dealChange="dealChange" />
      </li>
      <li
        :class="{ active: activeControl == 7 }"
        @click="changeActiveControl(7)"
        v-if="transData.login.exist.indexOf('copyright') > -1"
      >
        <div class="flex-space">
          <div class="tipsName">版权</div>
          <div class="flex-center">
            <div class="mr5">
              {{ login.copyright && login.copyright.show ? "显示" : "隐藏" }}
            </div>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <CopyRight :copyright="login.copyright" @dealChange="dealChange" />
      </li>
    </ul>
    <div class="flex mt10" style="padding-left: 20px">
      <el-button
        size="mini"
        type="primary"
        class="mr10 cur"
        style="width: 75px"
        @click="savePageStyle"
        >保存</el-button
      >
      <el-button size="mini" style="width: 75px" @click="$emit('resetStyle')"
        >重置</el-button
      >
    </div>
  </div>
</template>

<script>
import { apiUrl1 } from "@/api/asktrue/config";
import { getShorlUrlReq } from "@/api/asktrue/common";
// import TinymceEditor from "../TinymceEditor"; //富文本编辑器
import BackgroundLogin from "../style-control/Background";
// import BackgroundExplain from "../style-control/Background";
// import BackgroundExam from "../style-control/Background";
import TitleLogin from "../style-control/Title";
import LogoLogin from "../style-control/Logo";
import SidePictureLogin from "../style-control/SidePicture";
import ButtonLogin from "../style-control/Button";
import CopyRight from "../style-control/CopyRight";
// import HeaderImgExplain from "../style-control/HeaderImg";
import StepColor from "../style-control/StepColor";
import LoginBox from "../style-control/LoginBox";

import {
  postProStyleReq,
  postProjectGoodssetSave,
  getShareInfoReq,
} from "@/api/asktrue/project/project";
export default {
  components: {
    // TinymceEditor,
    BackgroundLogin,
    // BackgroundExplain,
    // BackgroundExam,
    TitleLogin,
    LogoLogin,
    SidePictureLogin,
    ButtonLogin,
    CopyRight,
    // HeaderImgExplain,
    // LoginModule,
    StepColor,
    LoginBox,
  },
  props: {
    isSet: Boolean,
    transData: Object,
    paperActiveIndex: Number,
    id: Number,
    projectType: Number,
  },
  data() {
    return {
      examList: [],
      examStyle: {},
      login: {},
      login1: {},
      login2: {},
      login3: {},
      commodity: {},
      activeControl: -1, //右侧设置展开的index
      isCost: false, //费用
      other: {
        costLabel: "免费", //费用回显文字数据
        costType: 0, //费用类型(String)：0-免费；1、收费、2-红包*************临时数据
        onlyAmount: "", //收费-单次测试金额*************临时数据
        priceList: [], //收费-价格列表*************临时数据
        redPacket: "", //红包-金额*************临时数据
        link: "",
      },
      share: {
        title: "",
        img: "",
        explain: "",
      },
      token: {},
      apiUrl: apiUrl1,
    };
  },
  watch: {
    paperActiveIndex(index) {
      this.activeControl = index == -1 ? 0 : -1;
      //this.examStyle = index >= 0 ? this.examList[index].examStyle : this.examList[0].examStyle;
      // this.dealChange(this.examStyle.backgroundStyle, 'backgroundStyle');
    },
    transData: {
      handler() {
        this.reset();
      },
      deep: true,
    },
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    this.reset();
    this.getShareInfoFn();
    this.activeControl = this.paperActiveIndex == -1 ? 0 : -1;
    this.getLink();
  },
  methods: {
    getLink() {
      let url =
        "https://" +
        location.host +
        "/#/goodsDetail?pId=" +
        this.$route.query.id +
        "&type=" +
        this.$route.query.type;
      getShorlUrlReq({ longUrl: url }).then((res) => {
        if (res.code == "200") {
          this.other.link = res.data.dwz;
        }
      });
    },
    //获取分享数据
    getShareInfoFn() {
      let params = {
        examId: this.id,
        projectType: 1,
      };
      getShareInfoReq(params).then((res) => {
        if (res.code == "200") {
          let examInfo = this.$store.state.asktrue.projectInfo;
          if (res.data.result) {
            this.share.title =
              res.data.result.shareTitle1 || examInfo.exam_name;
            this.share.img = res.data.result.shareLogo1 || examInfo.logo_path;
            this.share.explain =
              res.data.result.shareDesc1 ||
              "考试？测评？调查？评估？Asktrue.cn帮您一站解决！";
          } else {
            this.share.title = examInfo.exam_name;
            this.share.img = examInfo.logo_path;
            this.share.explain =
              "考试？测评？调查？评估？Asktrue.cn帮您一站解决！";
          }
        }
      });
    },
    uploadImgFn(res) {
      if (res.code == "200") {
        this.share.img = res.data.result;
      }
    },
    // 复制链接
    copyLink() {
      let copyElem = document.getElementById("copyLink");
      copyElem.select();
      document.execCommand("copy");
      this.$utils.voit("复制成功！", "success");
    },
    reset() {
      console.log(this.transData, "===this.transData111");
      for (let prop in this.transData) {
        this[prop] = this.transData[prop];
      }
      //商品页费用回显
      if (this.commodity.cost) {
        this.other.costType = this.commodity.cost.costType; //费用类型(String)：0-免费；1、收费、2-红包*************临时数据
        if (this.commodity.cost.costType == 1) {
          //回显收费
          let arr = [this.commodity.cost.onlyAmount];
          this.other.priceList = this.commodity.cost.priceList; //收费-价格列表*************临时数据
          this.other.priceList.map((item) => {
            arr.push(item.examAmount);
          });

          if (arr.length > 1) {
            let max = Math.max.apply(Math, arr);
            let min = Math.min.apply(Math, arr);
            this.other.costLabel = min + " - " + max + "元";
          } else {
            this.other.costLabel = this.commodity.cost.onlyAmount + "元";
          }
          this.other.onlyAmount = this.commodity.cost.onlyAmount; //收费-单次测试金额*************临时数据
        } else if (this.commodity.cost.costType == 2) {
          //回显红包
          this.other.costLabel = this.commodity.cost.redPacket + "元（红包）";
          this.other.redPacket = this.commodity.cost.redPacket; //红包-金额*************临时数据
        } else {
          //回显免费
          this.other.costLabel = "免费";
        }
      }
    },
    //获取须知内容
    getDescVal(val) {
      this.commodity.introHtml = val;
    },

    //------------------------------------------------------------------------

    //展开费用设置
    showPrice() {
      this.other.showPrice = !this.other.showPrice; //展开
      //临时变量赋值
      this.other.costType = this.commodity.cost.costType;
      this.other.onlyAmount = this.commodity.cost.onlyAmount;
      this.other.priceList = JSON.parse(
        JSON.stringify(this.commodity.cost.priceList)
      );
      this.other.redPacket = this.commodity.cost.redPacket;
    },
    //收费-新增
    addPriceLineFn(index) {
      let obj = {
        examId: this.id,
        projectType: this.projectType,
        setName: "",
        propId: "",
        propValue: "",
        examAmount: "",
        setComment: "",
      };
      this.other.priceList.splice(index + 1, 0, obj);
    },
    //收费-删除
    delPriceLineFn(index) {
      this.other.priceList.splice(index, 1);
    },
    //费用设置-切换
    changeCostFn() {
      if (this.other.costType == 0) {
        //免费
        this.other.onlyAmount = ""; //清空收费-单次测试金额----清空的是临时数据
        this.other.priceList = []; //清空收费-列表收费金额----清空的是临时数据
        this.other.redPacket = ""; //清空红包----清空的是临时数据
      } else if (this.other.costType == 1) {
        //收费
        this.other.redPacket = ""; //清空红包----清空的是临时数据
      } else if (this.other.costType == 2) {
        //红包
        this.other.onlyAmount = ""; //清空收费-单次测试金额----清空的是临时数据
        this.other.priceList = []; //清空收费-列表收费金额----清空的是临时数据
      }
    },
    //费用设置-保存
    savePriceFn() {
      console.log(this.commodity, "commodity");
      if (this.other.costType == 0) {
        //免费
        this.commodity.cost.costType = this.other.costType;
        this.commodity.cost.onlyAmount = ""; //清空收费-单次测试金额----清空的是临时数据
        this.commodity.cost.priceList = []; //清空收费-列表收费金额----清空的是临时数据
        this.commodity.cost.redPacket = ""; //清空红包----清空的是临时数据
        this.other.costLabel = "免费";
      } else if (this.other.costType == 1) {
        //收费
        if (this.other.onlyAmount == "") {
          this.$message.warning("请输入单次收费金额");
          return false;
        }
        let isnull = false;
        let arr = [this.other.onlyAmount];
        this.other.priceList.map((item) => {
          if (
            item.setName == "" ||
            item.propId == "" ||
            item.propValue == "" ||
            item.examAmount == ""
          ) {
            isnull = true;
          }
          arr.push(item.examAmount);
        });
        if (isnull) {
          this.$message.warning("收费信息录入不完整，请检查！");
          return false;
        }
        if (arr.length > 1) {
          let max = Math.max.apply(Math, arr);
          let min = Math.min.apply(Math, arr);
          this.other.costLabel = min + " - " + max + "元";
        } else {
          this.other.costLabel = arr[0] + "元";
        }

        this.commodity.cost.costType = this.other.costType;
        this.commodity.cost.onlyAmount = this.other.onlyAmount;
        this.commodity.cost.priceList = this.other.priceList;
        this.commodity.cost.redPacket = ""; //清空红包----清空的是临时数据
      } else if (this.other.costType == 2) {
        //红包
        if (this.other.redPacket == "") {
          this.$message.warning("请输入红包金额");
          return false;
        }
        this.other.costLabel = this.other.redPacket + "元（红包）";

        this.commodity.cost.costType = this.other.costType;
        this.commodity.cost.onlyAmount = ""; //清空收费-单次测试金额----清空的是临时数据
        this.commodity.cost.priceList = []; //清空收费-列表收费金额----清空的是临时数据
        this.commodity.cost.redPacket = this.other.redPacket;
      }

      this.isCost = false;
    },

    //------------------------------------------------------------------------

    // 保存当前设置页面的数据
    savePageStyle() {
      let params = {
        examId: Number(this.id), //	integer	考试编码
        projectType: String(this.projectType), //	string	项目类型:1考试 2调查 3评估 4报名 5投票
      };
      console.log(params);
      params.spare1 = JSON.stringify(this.login); //string	用来接收存储登录页pc端的外观
      console.log(params, "ppppppppssssssss");
      postProStyleReq(params).then((res) => {
        console.log(res, "=res");
        if (res.code == "200") {
          this.$message.success("保存成功");
        }
      });
    },
    //费用保存
    saveCostFn() {
      let params = {
        projectType: this.projectType,
        examId: this.id,
        goodsPayType: this.commodity.cost.costType,
        list: [],
      };
      if (this.commodity.cost.costType == 2) {
        //红包
        params.list = [
          {
            goodsPayType: this.commodity.cost.costType,
            examAmount: this.commodity.cost.redPacket,
          },
        ];
      } else if (this.commodity.cost.costType == 1) {
        //收费
        let onlyone = {
          examId: this.id,
          projectType: this.projectType,
          setName: "基本套餐",
          examAmount: this.commodity.cost.onlyAmount,
          propId: 1,
          propValue: 1,
        };
        params.list = JSON.parse(JSON.stringify(this.commodity.cost.priceList));
        params.list.unshift(onlyone);
      }
      postProjectGoodssetSave(params).then((res) => {
        if (res.code == "200") {
          this.$message.success("保存成功");
        }
      });
    },

    // 组件返回值设置
    dealChange(data, type) {
      this.$emit("dealChange", data, type);
    },
    // 右侧展开控制
    changeActiveControl(control) {
      this.activeControl = this.activeControl == control ? -1 : control;
    },
  },
};
</script>

<style lang="less" scoped>
.control-warpper {
  width: 260px;
  height: 100%;
  padding: 0px 0 15px;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 5px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    display: block;
    cursor: pointer;
    font-size: 20px;
  }
  /deep/ .el-divider {
    background: #e4e4e4;
    margin: 10px 0;
    .el-divider__text {
      font-size: 14px;
      font-weight: normal;
      color: #999;
    }
  }
  .control-item {
    padding: 0 20px;
    line-height: 50px;
    font-size: 12px;
    &.commodity-item {
      font-size: 14px;
      .el-divider {
        margin-top: 25px;
        .el-divider__text.is-left {
          left: -20px;
        }
      }
      li {
        height: 50px;
        border: 0px;
        &.cur-normal {
          cursor: default;
          height: 50px;
          overflow: hidden;
          .flex-center {
            height: 49px;
          }
        }
        /deep/.el-input__inner {
          border: 0px !important;
          text-align: right;
          color: #666;
          font-size: 12px;
        }
        /deep/.el-textarea__inner {
          border: 0px;
          text-align: right;
          color: #555;
          font-size: 12px;
          margin-top: 10px;
          min-height: 40px;
          padding-right: 0px;
        }
        .share-upload {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border: 1px solid #e4e4e4;
          margin-top: 5px;
          /deep/.el-upload {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        /deep/.header-img-item {
          height: 28px;
          .active {
            padding-top: 3px;
          }
        }
      }
    }
    li {
      cursor: pointer;
      border-bottom: 1px dotted #e4e4e4;
      height: 51px;
      overflow: hidden;
      &.active {
        height: unset;
      }
      .cube-container {
        width: 20px;
        height: 20px;
        line-height: normal;
        border-radius: 2px;
        margin-right: 9px;
        overflow: hidden;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="less">
.popIntro .el-dialog__body {
  padding: 0 20px;
}

/*费用弹窗样式 */
.cost-set {
  background-color: #fff;
  padding: 15px;
  width: 400px;
  .el-radio {
    .el-radio__label {
      font-size: 12px;
    }
  }
  .cost-label {
    p {
      color: #ccc;
      font-size: 12px;
      margin-top: 5px;
    }
    ul {
      li {
        display: flex;
        margin-top: 10px;
        .text {
          width: 80%;
          height: 28px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          margin-right: 5px;
          &.title {
            width: 16%;
          }
          &.unit {
            width: 13%;
          }
          &.num {
            width: 10%;
          }
          &.price {
            width: 10%;
          }
          &.note {
            width: 28%;
          }
          &.el-select {
            .el-input {
              height: 100%;
            }
            .el-input__suffix {
              width: 16px;
              height: 16px;
              top: 50%;
              right: 0px;
              margin-top: -8px;
              line-height: 16px;
              .el-input__icon {
                width: 100%;
                height: 100%;
                line-height: 16px;
              }
            }
          }
          .el-input__inner {
            border: 0px !important;
            width: 100%;
            height: 100%;
            min-height: 20px !important;
            display: block;
            font-size: 12px;
            padding: 0 5px;
          }
        }
        .el-select-dropdown {
          .el-select-dropdown__item {
            font-size: 12px;
          }
        }
        em {
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          background-color: #fff;
          cursor: pointer;
          color: #aaa;
          &.el-icon-plus {
            font-size: 15px;
          }
          &.el-icon-close {
            font-size: 16px;
          }
        }
      }
    }
  }
  .price-lines {
    li {
      display: flex;
      margin-bottom: 10px;
      .el-select {
        margin-top: 2px;
      }
      .el-input {
        margin-right: 10px;
        input {
          height: 26px;
          border: 1px solid#e4e4e4;
          font-size: 12px;
          padding: 0 5px;
          width: 120px;
        }
        .el-input__suffix {
          right: 8px !important;
        }
      }
      em {
        border: 1px solid #e4e4e4;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: #fff;
        margin-top: 1px;
        padding: 0 5px;
        font-size: 20px;
        justify-content: center;
        display: flex;
      }
    }
  }
  .el-select-dropdown__item {
    margin-bottom: 0 !important;
  }
}
.tipsName {
  min-width: 65px;
  text-align: left;
}
.shareTitle .el-input__inner {
  padding: 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shareContent .el-textarea__inner {
  padding: 5px 0px;
}
</style>

import requestUser from "@/assets/plugins/asktrue/request-user"; // user 实例
import requestPaper from "@/assets/plugins/asktrue/request-paper"; // paper 实例
import qs from "qs"; // 序列化

// //模版列表
// export const getTempListReq = data => getReq(apiUrl2+"/reportTempletCtl/selectReportTempletListPage",data,{showLoading:true,loadingTarget:'.console-main'})
export function getTempListReq(params) {
  return requestPaper({
    url: "/reportTempletCtl/selectReportTempletListPage",
    method: "get",
    params,
  });
}

// //上传模版
// export const postUplodeTempReq = (data,params) => postReq(apiUrl2+"/reportTempletCtl/importTemplet"+(params?('?isAddPaper='+params.isAddPaper+(params.paperId?'&paperId='+params.paperId:'')):''),data,{ContentType:'application/json;charset=UTF-8'})
export function postUplodeTempReq(data, params) {
  return requestPaper({
    url:
      "/reportTempletCtl/importTemplet" +
      (params
        ? "?isAddPaper=" +
          params.isAddPaper +
          (params.paperId ? "&paperId=" + params.paperId : "")
        : ""),
    method: "post",
    data,
  });
}

// //删除模版
// export const delTempReq = data => postReq(apiUrl2+"/reportTempletCtl/updateReportTempletStatus",data)

// //下载模版
// export const downloadFileReq = data => exportReq(apiUrl1+'/minIOApi/downloadFile' ,data)//下载

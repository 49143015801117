//获取日期，p_count为天数
export function FunGetDateStr(p_count) {
  var dd = new Date();
  dd.setDate(dd.getDate() + p_count); //获取p_count天后的日期
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1; //获取当前月份的日期
  if (m < 10) {
    m = "0" + m;
  }
  var d = dd.getDate();
  if (d < 10) {
    d = "0" + d;
  }
  return y + "-" + m + "-" + d;
}

export function getFromTimeStamp(val, type) {
  let timestr = new Date(parseInt(val));
  console.log(timestr, val);
  let month =
    timestr.getMonth() > 8
      ? timestr.getMonth() + 1
      : "0" + (timestr.getMonth() + 1);
  let date =
    timestr.getDate() > 9 ? timestr.getDate() : "0" + timestr.getDate();
  let timeString = timestr.getFullYear() + "-" + month + "-" + date;
  if (type == "minute") {
    timeString +=
      " " +
      (timestr.getHours() > 9 ? timestr.getHours() : "0" + timestr.getHours()) +
      ":" +
      (timestr.getMinutes() > 9
        ? timestr.getMinutes()
        : "0" + timestr.getMinutes());
  }
  if (type == "second") {
    timeString +=
      " " +
      (timestr.getHours() > 9 ? timestr.getHours() : "0" + timestr.getHours()) +
      ":" +
      (timestr.getMinutes() > 9
        ? timestr.getMinutes()
        : "0" + timestr.getMinutes()) +
      ":" +
      (timestr.getSeconds() > 9
        ? timestr.getSeconds()
        : "0" + timestr.getSeconds());
  }
  return timeString;
}
export function getDay(val) {
  let timestr = val;
  let month = timestr.getMonth() + 1;
  let date = timestr.getDate();
  let timeString = [
    timestr.getFullYear(),
    month,
    date,
    timestr.toString().split(" ")[0],
  ];
  return timeString;
}

export default {
  parent: "Questionnaire",
  routers: [
    // 我参加的
    {
      path: "in",
      name: "Questionnaire_in",
      component: () => import("@/views/staff/questionnaire/InView.vue"),
    },
    // 我发布的
    {
      path: "publish",
      name: "Questionnaire_publish",
      component: () => import("@/views/staff/questionnaire/PublishView.vue"),
    },
    // 全部
    {
      path: "all",
      name: "Questionnaire_all",
      component: () => import("@/views/staff/questionnaire/IndexView.vue"),
    },
  ],
};

export default {
  parent: "Course",
  routers: [
    // 课程-我学习的
    {
      path: "study",
      name: "kc-study",
      component: () => import("@/views/train/course/indexView.vue"),
      meta: { title: "课程-我学习的" },
    },
    // 课程-我必修的
    {
      path: "required",
      name: "kc-required",
      component: () => import("@/views/train/course/indexView.vue"),
      meta: { title: "课程-我必修的" },
    },
    // 课程-我购买的
    {
      path: "buy",
      name: "kc-buy",
      component: () => import("@/views/train/course/indexView.vue"),
      meta: { title: "课程-我购买的" },
    },
    // 课程-我发布的
    {
      path: "creat",
      name: "kc-creat",
      component: () => import("@/views/train/course/indexView.vue"),
      meta: { title: "课程-我发布的" },
    },
    // 课程-全部
    {
      path: "all",
      name: "kc-all",
      component: () => import("@/views/train/course/indexView.vue"),
      meta: { title: "课程" },
    },
  ],
};

// 课程接口
export default function (axios) {
  return {
    // 课程↓↓↓↓↓↓↓*****************************************************
    // 课程-增
    kc_addApi(params) {
      return axios.post("/courseCtl/addCourse", params)
    },
    kc_delApi(params) {
      return axios.get("/courseCtl/deleteCourse", { params })
    },
    // 课程-改
    kc_editApi(params) {
      return axios.post("/courseCtl/editCourse", params)
    },
    // 课程-列表
    kc_ListApi(params) {
      return axios.post("/courseCtl/selectCourseList", params);
    },
    // 课程-详情
    kc_detailApi(params) {
      return axios.get("/courseCtl/getCourseById", { params });
    },

    // *****************************************************
    // 课件
    // *****************************************************
    // 课件-章-增、改
    kc_editChapterApi(params) {
      return axios.post("/courseware/editChapter", params);
    },
    // 课件-节（课件）-增、改
    kc_editCoursewareApi(params) {
      return axios.post("/courseware/editCourseware", params);
    },
    // 课件-章/节-删
    kc_deleteChapterApi(params) {
      return axios.get("/courseware/deleteChapter", { params });
    },
    // 课件-节-移动
    kc_moveCoursewareApi(params) {
      return axios.post("/courseware/moveCourseware", params);
    },
    // 课件-列表
    kc_chapterListApi(params) {
      return axios.post("/courseware/selectChapterList", params);
    },
    // 课件-节-详情
    kc_selectByIdApi(params) {
      return axios.get("/courseware/selectById", { params });
    },


    // *****************************************************
    // 练习
    // *****************************************************
    // 练习-问卷-新增/修改
    kcwj_editApi(params) {
      return axios.post("/coursePaper/editCoursePaper", params);
    },
    // 练习-问卷-列表
    kcwj_listApi(params) {
      return axios.get("/coursePaper/selectPaperPape", { params });
    },
    // 练习-问卷-删除
    kcwj_delApi(params) {
      return axios.get("/coursePaper/deletePaper", { params });
    },
    // 练习-问卷-切换
    kcwj_replaceApi(params) {
      return axios.post("/coursePaper/editCoursewarePaperId", {}, { params });
    },
    // 练习-试题-新增/修改
    kcwjst_editApi(params) {
      return axios.post("/coursePaperQuestion/editCoursePaperQuestion", params);
    },
    // 练习-试题-列表
    kcwjst_listApi(params) {
      return axios.get("/coursePaper/selectPaperByCoursewareId", { params });
    },
    // 练习-试题-删除
    kcwjst_delApi(params) {
      return axios.post("/coursePaperQuestion/deletePaperQuestion", {}, { params });
    },

    // 练习-答题-保存答题记录
    kcwjdt_saveApi(params) {
      return axios.post("/coursePaperQuestionResult/addQuestionResult", params)
    },
    // 练习-答题-答题记录查询
    kcwjdt_listApi(params) {
      return axios.get("/coursePaperQuestionResult/selectQuestionResult", { params })
    },
    // 练习-答题-重置答题
    kcwjdt_resetApi(params) {
      return axios.post("/coursePaperQuestionResult/resetPaperQuestion", {}, { params })
    },


    // *****************************************************
    // 学习记录
    // *****************************************************
    // 学习记录-新增
    kc_addRecordApi(params) {
      return axios.post("/studyRecord/addRecord", params);
    },
    // 学习记录-新增
    kc_editRecordApi(params) {
      return axios.post("/studyRecord/editRecord", params);
    },

    // 学习记录-列表
    kc_selectRecordListApi(params) {
      return axios.post("/studyRecord/selectRecordList", params);
    },


    // *****************************************************
    // 培训班
    // *****************************************************
    // 培训班-关联培训班
    kc_classAddApi(params) {
      return axios.post("/trainingCourse/addTrainingCourse", params);
    },
    // 培训班-列表
    kc_classListApi(params) {
      return axios.post("/trainingCourse/selectTrainingList", params);
    },
    // 培训班-退出培训班
    kc_classDelApi(params) {
      return axios.post("/trainingCourse/deleteTraining", params);
    },


    // *****************************************************
    // 评论
    // *****************************************************
    // 评论-新增/修改
    kcpl_editApi(params) {
      return axios.post("/remark/editRemark", params);
    },
    // 评论-列表
    kcpl_listApi(params) {
      return axios.post("/remark/selectRemarkList", params);
    },
    // 评论-回复
    kcpl_replyApi(params) {
      return axios.post("/remark/addRemarkReply", params);
    },
    // 评论标签-新增/修改
    kcplbq_editApi(params) {
      return axios.post("/tags/editTags", params);
    },
    // 评论标签-列表
    kcplbq_listApi(params) {
      return axios.post("/tags/selectTagsList", params);
    },
    // 评论标签-删除
    kcplbq_delApi(params) {
      return axios.get("/tags/deleteTags", { params });
    },


    // *****************************************************
    // 辅助功能
    // *****************************************************
    // 查询收藏、点赞数据
    kc_getcollectApi(params) {
      return axios.post("/courseware/selectOtherInfo", {}, { params });
    },
    // 收藏
    kc_collectApi(params) {
      return axios.post("/collect/editCollect", params);
    },
    // 点赞
    kc_likeApi(params) {
      return axios.post("/actions/editActions", params);
    },
    // 笔记-增
    kc_noteAddApi(params) {
      return axios.post("/note/editNote", params);
    },
    // 笔记-查
    kc_noteListApi(params) {
      return axios.post("/note/selectNoteList", params);
    },
    // 课程-上线/下线-执行
    kc_onlineApi(params) {
      return axios.get("/courseCtl/moveOnline", { params });
    },
    // 课程详情统计各个列表数
    kc_selectCourseCountApi(params) {
      return axios.get("/courseCtl/selectCourseCount", { params });
    },



    // *****************************************************
    // 课程类型
    // *****************************************************
    // 类型-增
    kclx_addApi(params) {
      return axios.post("/typeCtl/addType", params);
    },
    // 类型-改/删
    kclx_updateApi(params) {
      return axios.get("/typeCtl/updateType", { params });
    },
    // 类型-查
    kclx_listApi(params) {
      return axios.get("/typeCtl/selectTypeByCompId", { params });
    },


  };
}

export default function (axios) {
  return {
    // 	查询体检汇总数据
    selectPhysicalSummaryReq(data) {
      return axios.post("/physicalPlan/selectPhysicalSummary", data, {
        ContentType: "application/json",
      });
    },

    // 删除体检安排
    delPhysicalReq(data) {
      return axios.post("/physicalPlan/deletePhysicalPlan", data, {
        ContentType: "application/json",
      });
    },
    // 新增体检安排项目
    editPhysicalPlanReq(data) {
      return axios.post("/physicalPlan/editPhysicalPlan", data, {
        ContentType: "application/json",
      });
    },

    // 单个、批量以及全部导出体检安排
    exportPhysicalReq(params) {
      return axios.get("/physicalPlan/exportPhysicalPlanExcel", { params });
    },
    // 导出体检签到
    exportPhysicalSigneReq(params) {
      return axios.get("/physicalPlan/exportInterviewSigneExcel", { params });
    },
    // 导入体检安排
    uploadPhysicalMemberReq(data, params) {
      return axios.uplode(
        "/physicalPlan/importPhysicalPlan?planId=" + params.planId,
        data
      );
    },
    // 查询单个体检安排详情
    getPhysicalPlanReq(data) {
      return axios.post("/physicalPlan/selectByPrimaryId", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    // 体检安排列表分页查询
    physicalPlanList(params) {
      return axios.get("/physicalPlan/selectPage", { params });
    },

    // 体检医院
    // 删除单个
    delHospitalReq(data) {
      return axios.post("/planHospital/deletePlanHospital", data, {
        ContentType: "application/json",
      });
    },
    // 编辑和新增
    editHospitalReq(data) {
      return axios.post("/planHospital/editPlanHospital", data, {
        ContentType: "application/json",
      });
    },
    // 获取医院城市列表
    getHospitalCityListReq(data) {
      return axios.post("/planHospital/selectDistinctHospitalCity", data, {
        ContentType: "application/json",
      });
    },
    // 分页查询
    getHospitalListReq(params) {
      return axios.get("/planHospital/selectPage", { params });
    },

    // 体检人员
    // 删除体检人员
    delPhysicalMemberReq(data) {
      return axios.post("/physicalMember/deletePhysicalMember", data, {
        ContentType: "application/json",
      });
    },
    // 编辑体检人员
    editPhysicalMemberReq(data, params) {
      return axios.post(
        "/physicalMember/editPhysicalMember?jobId=" +
          params.jobId +
          "&resumeId=" +
          params.resumeId +
          "&stageId=" +
          params.stageId +
          "&needConfirm=" +
          params.needConfirm +
          "&isResume=" +
          (params.resumeId ? 1 : "") +
          "&isNewTime=" +
          params.isNewTime +
          "&isNewPlace=" +
          params.isNewPlace,
        data,
        { ContentType: "application/json" }
      );
    },
    // 编辑体检签到
    editPhysicalSignReq(data) {
      return axios.post("/physicalMember/editSigneStatus", data);
    },
    // 获取体检人员列表
    getPhysicalMemberListReq(params) {
      return axios.get("/physicalMember/selectPage", { params });
    },

    // 查询人选列表
    postPhysicalMemberListReq(data) {
      return axios.post("/physicalMember/selectPageList", data, {
        ContentType: "application/json",
      });
    },
    // 人选上方的筛选条件
    postPhysicalSelectFilterReq(data) {
      return axios.post("/physicalMember/selectFilter", data, {
        ContentType: "application/json",
      });
    },

    postPhysicalItemsReq(data) {
      return axios.post("/physicalMember/selectPhysicalSubject", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    // 新增/编辑体检项目
    editPhysicalSubjectReq(data) {
      return axios.post("/physicalMember/editPhysicalSubject", data, {
        ContentType: "application/json",
      });
    },
    // 删除体检项目
    delPhysicalSubjectReq(data) {
      return axios.post("/physicalMember/deletePhysicalSubject", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    // // 查询详细的体检安排人员的信息
    addPhysicalTimeReq(data) {
      return axios.post("/physicalPlan/addPhysicalTime", data, {
        ContentType: "application/json",
      });
    },
    //     // 审批前查询下一流程阶段
    // export const getNextStageReq = (data) =>
    // getReq(apiUrl8 + "/physicalMember/getNextStage", data);

    getTjNextStageReq(data) {
      return axios.get("/physicalMember/getNextStage", { data });
    },
    //面试安排获取职位数据
    getJobNameByPlanIdReq(data) {
      return axios.post(
        "/physicalMember/getJobNameByPlanId?planId=" + data.planId
      );
    },
    //获取筛选条件（职位，时间，医院）
    selectTimeJobPlace(data) {
      return axios.post(
        "/physicalMember/selectTimeJobPlace?planId=" + data.planId
      );
    },
    // 新增/编辑笔试/面试/体检人员
    editExamMemberReqTJ(type, data, params) {
      let url = "/physicalMember/editPhysicalMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          url += key + "=" + params[key] + "&";
        }
      }
      return axios.post(url, data);
    },
    // 面试审批
    updatePlanApprovalTjReq(data, channelId) {
      return axios.post(
        `/physicalMember/updatePlanApproval?channelId=${channelId}`,
        data,
        {
          ContentType: "application/json",
        }
      );
    },
  };
}

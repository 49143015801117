<!--
组件名称：项目编辑表单
组件说明：
本组件暂时为五类项目共同的编辑组件。
开发之初，应领导要求五类项目完全分开，但分开后调整开发难度及工作量加大，后临时合并为一个表单组件开发。
如若今后编辑表单差距过大时，请完整复制本组件到同目录下的Edit**Project.vue中再调整并引用
备注人：王超
-->
<template>
  <div class="edit-wrap no-select">
    <!--右栏-->
    <div class="sidebar">
      <div class="oc" v-if="isOnOff.isTimeTerm">
        <span>有效期</span><el-switch v-model="isTrue.isTimeTerm"></el-switch>
      </div>
      <div class="oc" v-if="isOnOff.isAB && editData.from_type == 2">
        <span>AB卷</span>
        <span
          v-if="isAbCk"
          @click="$message.warning('发布到网站的项目，不能设置AB卷')"
          ><el-switch v-model="isTrue.isAB" :disabled="isAbCk"></el-switch
        ></span>
        <span v-else
          ><el-switch v-model="isTrue.isAB" :disabled="isAbCk"></el-switch
        ></span>
      </div>
      <div class="oc" v-if="isOnOff.isCheat">
        <span>防作弊</span>
        <span
          v-if="isJk"
          @click="$message.warning('设置屏幕监测后，不能开启防作弊')"
          ><el-switch v-model="isTrue.isCheat" :disabled="isJk"></el-switch
        ></span>
        <span v-else
          ><el-switch v-model="isTrue.isCheat" :disabled="isJk"></el-switch
        ></span>
      </div>
      <div class="oc" v-if="isOnOff.isMonitor">
        <span>监控</span><el-switch v-model="isTrue.isMonitor"></el-switch>
      </div>

      <!-- 必填项字段已被领导要求屏蔽（要求的是屏蔽，暂不删除，后续使用过程中可能还有想法） -->
      <div class="oc" v-if="isOnOff.isMustFill">
        <span>必填项</span><el-switch v-model="isTrue.isMustFill"></el-switch>
      </div>
    </div>
    <!-- ------------------------------------------------------------------------------ -->
    <el-form
      label-width="85px"
      :model="ruleForm"
      :rules="formRules"
      ref="ruleForm"
      class="g-form-div"
    >
      <el-form-item label="名称：" prop="title">
        <div class="flex flex-space">
          <el-input
            v-model="ruleForm.title"
            placeholder="输入项目名称"
          ></el-input>
          <!-- <span
            @click="$message.warning('设置AB卷的项目，不能发布到网站')"
            v-if="isTrue.isAB"
          >
            <el-checkbox
              v-model="ruleForm.isOpen"
              @change="changeOpenFn()"
              class="open-label"
              :disabled="isTrue.isAB"
              v-if="editData.from_type == 2 && editData.projectType != 3"
              >发布到网站</el-checkbox
            >
          </span> -->
          <!--fromType	integer	1：购买的项目 2：自建项目；杨总要求，评估去掉“发布到网站”-->
          <!-- <span v-else>
            <el-checkbox
              v-model="ruleForm.isOpen"
              @change="changeOpenFn()"
              class="open-label"
              v-if="editData.from_type == 2 && editData.projectType != 3"
              >发布到网站</el-checkbox
            >
          </span> -->
          <!--fromType	integer	1：购买的项目 2：自建项目；杨总要求，评估去掉“发布到网站”-->
        </div>
      </el-form-item>

      <el-form-item
        label="AB卷："
        v-if="isTrue.isAB && editData.from_type == 2"
      >
        <div class="ab-content">
          <div class="paperTypeList flex flex-wrap">
            <div v-for="(ab, abi) in ruleForm.paperTypeList" :key="abi">
              <span>{{ ab }}卷</span
              ><em
                class="el-icon-close"
                title="删除"
                @click="delABFn(abi)"
              ></em>
            </div>
          </div>
          <em
            :class="[
              other.isAbSet ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
              'isAbOpt',
            ]"
            @click="other.isAbSet = !other.isAbSet"
          ></em>
        </div>
        <div class="ab-option" v-if="other.isAbSet">
          <div class="abOption tal">
            <el-checkbox-group
              v-model="ruleForm.paperTypeList"
              @change="orderABFn"
            >
              <el-checkbox
                v-for="(ab, abi) in other.abOption"
                :key="abi"
                :label="ab"
                >{{ ab }}卷</el-checkbox
              >
              <label class="addABBtn"
                ><em class="el-icon-plus" @click.stop="addABFn"></em
              ></label>
            </el-checkbox-group>

            <div class="tar">
              <el-button
                size="mini"
                style="padding: 8px 25px"
                @click="other.isAbSet = false"
                >关闭</el-button
              >
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="标签："
        :required="ruleForm.isOpen"
        v-if="editData.from_type == 2"
      >
        <div class="flex tags-box">
          <el-checkbox-group v-model="ruleForm.tags" size="mini">
            <el-checkbox-button
              v-for="(tag, index) in other.tagsOption"
              :label="tag.id"
              :key="index"
              >{{ tag.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item label="认证：">
        <div class="item-font tal cur" @click="clickVerifyFn()">
          <span class="c8">{{ other.verifyLabel }}</span
          ><em class="item-font el-icon-arrow-down fr c9"></em>
        </div>
      </el-form-item>
      <el-form-item label="" v-if="other.showVerify" class="verify-set">
        <div class="item-font tal">
          <span
            :class="[ruleForm.verify ? '' : 'on', 'verify-state cur']"
            @click="changeVerifyFn()"
            ><em class="el-icon-check"></em>不认证</span
          ><span class="fr ccc f12">任何人都可参加。</span>
        </div>
        <el-divider class="hrVerify"></el-divider>
        <div class="item-font tal">
          <span :class="[ruleForm.verify ? 'on' : '', 'verify-state']"
            ><em class="el-icon-check"></em>认证</span
          >
          <span class="ml40">
            <el-radio-group
              v-model="ruleForm.verifyType"
              class="ml40"
              @change="changeVerifyTypeFn()"
            >
              <el-radio label="3" v-if="!ruleForm.anonymous">考号</el-radio
              ><!--调查匿名存在考号，原系统调查匿名无考号  >   v-if="!ruleForm.anonymous || (ruleForm.anonymous && (editData.projectType==2 || editData.projectType==3) ) "临时改为匿名全无考号-->
              <!-- 如果短信剩余量为0 直接不让选手机认证方式 -->
              <el-radio label="1" @click.native="handleChangeVerifyTypeSms"
                >手机
                <el-tooltip
                  placement="top-start"
                  effect="light"
                  popper-class="asktrue-tooltip"
                >
                  <template slot="content">
                    <div
                      class=""
                      style="width: 250px; line-height: 1.35; padding: 5px"
                    >
                      <div>
                        短信剩余数量： <em class="f60">{{ smsCount }}</em>
                        <br /><br />
                        为保证您的项目顺利进行，请确保您的剩余短信数量充足。
                      </div>
                      <div style="margin-top: 15px">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="goChargeAccount"
                          >去充值</el-button
                        >
                      </div>
                    </div>
                  </template>
                  <el-button type="text" style="padding: 0">
                    <em class="f60">{{ smsCount }}</em>
                    <i
                      class="iconfont iconbangzhuwendang1"
                      style="margin-left: 3px"
                    ></i>
                  </el-button>
                </el-tooltip>
              </el-radio>

              <!-- 如果邮件剩余量为0 直接不让选邮箱认证方式 -->
              <el-radio label="2" @click.native="handleChangeVerifyTypeMail"
                >邮箱
                <el-tooltip
                  placement="top-start"
                  effect="light"
                  popper-class="asktrue-tooltip"
                >
                  <template slot="content">
                    <div
                      class=""
                      style="width: 250px; line-height: 1.35; padding: 5px"
                    >
                      <div>
                        邮件剩余数量： <em class="f60">{{ mailCount }}</em
                        ><br /><br />
                        为保证您的项目顺利进行，请确保您的剩余邮件数量充足
                      </div>
                      <div style="margin-top: 15px">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="goChargeAccount"
                          >去充值</el-button
                        >
                      </div>
                    </div>
                  </template>
                  <el-button type="text" style="padding: 0">
                    <em class="f60">{{ mailCount }}</em>
                    <i
                      class="iconfont iconbangzhuwendang1"
                      style="margin-left: 3px"
                    ></i>
                  </el-button>
                </el-tooltip>
              </el-radio>

              <!-- 如果人脸识别次数为0 直接不让选人脸识别认证方式 -->
              <el-radio
                label="4"
                v-if="!ruleForm.anonymous"
                @click.native="handleChangeVerifyTypeFaceRecogn"
                >人脸识别
                <el-tooltip
                  placement="top-start"
                  effect="light"
                  popper-class="asktrue-tooltip"
                >
                  <template slot="content">
                    <div
                      class=""
                      style="width: 250px; line-height: 1.35; padding: 5px"
                    >
                      <div>
                        人脸识别剩余次数：
                        <em class="f60">{{ faceRecognCount }}</em
                        ><br /><br />
                        为保证您的项目顺利进行，请确保您的剩余人脸识别次数充足
                      </div>
                      <div style="margin-top: 15px">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="goChargeAccount"
                          >去充值</el-button
                        >
                      </div>
                    </div>
                  </template>
                  <el-button type="text" style="padding: 0">
                    <em class="f60">{{ faceRecognCount }}</em>
                    <i
                      class="iconfont iconbangzhuwendang1"
                      style="margin-left: 3px"
                    ></i>
                  </el-button>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </span>
          <span class="fr ccc f12" v-if="editData.projectType == 1"
            >认证通过后才可参加。</span
          >
          <span class="anonymous fr c6 f12" v-else>
            <span>匿名</span
            ><em class="iconfont iconbangzhuwendang1 ccc" title=""></em
            ><el-switch
              v-model="ruleForm.anonymous"
              @change="changeAnonymous"
            ></el-switch>
          </span>
        </div>
      </el-form-item>

      <el-form-item
        label="封面："
        :required="ruleForm.isOpen"
        v-if="editData.from_type == 2"
      >
        <div class="flex cover flex-space">
          <img
            v-if="ruleForm.coverUrl"
            :src="ruleForm.coverUrl"
            @click="other.showCover = !other.showCover"
          />
          <img
            v-if="!ruleForm.coverUrl"
            src="@/assets/image/asktrue/error/ico_kaoshi.png"
            @click="other.showCover = !other.showCover"
          />
          <span class="item-font f12 ccc">注：点击图片更换封面。</span>
        </div>
        <div v-if="other.showCover">
          <ul class="cover">
            <li
              v-for="(item, index) in other.coverOption"
              :key="index"
              :class="selCoverId == index ? 'on' : ''"
              @click="chooseImgFn(item.path, index)"
            >
              <img v-if="item.path" :src="item.path" />
              <img
                v-if="!item.path"
                src="@/assets/image/asktrue/error/ico_kaoshi.png"
              />
            </li>
            <li
              class="pr border yhj2"
              @click="chooseCustomImgFn()"
              v-if="other.coverOption2[0]"
            >
              <em
                class="pa el-icon-close cur"
                @click.stop="delImgFn(0)"
                title="删除自定义封面"
              ></em>
              <img :src="other.coverOption2[0]" />
              <input
                type="file"
                class="uplode"
                accept="image/*"
                :value="other.nullAutoCover"
                ref="uploadFile"
                @change="uplodeImg"
              />
            </li>
            <li class="pr border yhj2" title="上传自定义封面" v-else>
              <span class="el-icon-plus"></span>
              <input
                type="file"
                class="uplode"
                accept="image/*"
                :value="other.nullAutoCover"
                ref="uploadFile"
                @change="uplodeImg"
              />
            </li>
          </ul>
        </div>
        <ImgCropper
          v-if="other.cropImageDialog"
          :options="{ imgUrl: ruleForm.coverUrl, fixedNumber: [5, 4] }"
          v-on:childEvent="getImgUrl"
        />
      </el-form-item>

      <el-form-item label="须知：" class="content" :key="tinymceFlag">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2 }"
          placeholder="请输入考前须知"
          v-model="ruleForm.desc"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="有效期："
        :required="true"
        v-if="this.isTrue.isTimeTerm"
      >
        <div class="flex">
          <div style="width: 330px">
            <DateRangePicker
              ref="DateRangePicker"
              from="xm"
              :start="ruleForm.examStartTime"
              :end="ruleForm.examEndTime"
              placeholder="设置有效期"
              @rangeChange="changeRange"
              v-if="this.isTrue.isTimeTerm && other.isTime"
              :key="new Date().getTime()"
            />
          </div>
        </div>
      </el-form-item>

      <el-form-item label="查看：">
        <div class="flex mt5">
          <el-checkbox v-model="ruleForm.isLookResult">查看成绩</el-checkbox>
          <el-checkbox v-model="ruleForm.isLookReport">查看报告</el-checkbox>
          <el-checkbox v-model="ruleForm.isLookAnswer">查看答案</el-checkbox>
        </div>
      </el-form-item>

      <!-- 项目类型是考试(projectType 为 1)的时候 显示切屏 -->
      <el-form-item label="切屏：" v-if="editData.projectType === '1'">
        <div class="flex mt5">
          <el-checkbox
            v-model="ruleForm.cutScreen"
            :true-label="2"
            :false-label="1"
            >开启</el-checkbox
          >
          <div class="flex" v-if="ruleForm.cutScreen == 2">
            ，切屏<el-input
              v-model="ruleForm.cutNum"
              placeholder="不超过15"
              size="mini"
              class="mini-input"
            ></el-input
            >次后，强制交卷。
          </div>
        </div>
      </el-form-item>

      <!-- <el-form-item label="监控：" v-if="isTrue.isMonitor">
          <div class="flex mt5" >
            <el-radio-group v-model="other.monitor" class="item-font" @change="monitorFn">
              <div class="jkfs">
                <div class="jk">
                  <el-radio :label="1">拍照</el-radio>
                  <div class="fz" v-if="other.monitor==1"><span class="fl">每</span><input type="text" class="text" v-model="ruleForm.photoRate" /><span>分钟拍一次</span></div>
                </div>
                <div class="jk">
                  <el-radio :label="2">实时视频</el-radio>
                  <div class="fz" v-if="other.monitor==2"><el-checkbox v-model="other.videotape">录像</el-checkbox><span>（此为收费功能）</span></div>
                </div>
              </div>
            </el-radio-group>
          </div>
        </el-form-item> -->

      <el-form-item label="监控：" :required="true" v-if="isTrue.isMonitor">
        <div class="item-font tal cur" @click="clickMonitorFn()">
          <span class="c8" v-html="other.MonitorLabel"></span>
          <em class="item-font el-icon-arrow-down fr c9"></em>
        </div>
      </el-form-item>
      <el-form-item
        label=""
        v-if="isTrue.isMonitor && other.showMonitor"
        class="monitor-set"
      >
        <el-radio-group
          v-model="other.monitor"
          class="item-font"
          @change="monitorFn"
        >
          <div class="jkfs">
            <div class="jk flex">
              <el-radio :label="1">拍照</el-radio>
              <div class="fz">
                <span class="fl">每</span
                ><input
                  type="text"
                  class="text"
                  v-model="ruleForm.photoRate"
                  @click="
                    other.monitor = 1;
                    other.angle1 = false;
                    other.angle2 = false;
                    other.angle3 = false;
                    clickChildFn();
                  "
                  @input="clickChildFn()"
                /><span>分钟拍一次</span>
              </div>
            </div>
            <!-- <el-divider class="hrVerify"></el-divider> -->

            <div class="jk">
              <div class="">
                <el-radio :label="2">实时音视频</el-radio>
                <div
                  class="fz"
                  style="display: inline-block; height: 40px; line-height: 40px"
                >
                  <span style="padding-right: 5px"
                    >剩余时长:
                    <em class="f60">{{
                      monitorRemainingAmount | formatMinute
                    }}</em>
                    分钟</span
                  >
                  <el-button
                    v-if="monitorRemainingAmount <= 0"
                    type="primary"
                    style="padding: 5px 6px"
                    size="mini"
                    @click="goChargeAccount"
                    >去充值</el-button
                  >
                  <el-tooltip v-else placement="top-start" effect="light">
                    <template slot="content">
                      <div
                        class=""
                        style="width: 250px; line-height: 1.35; padding: 5px"
                      >
                        <div>
                          <!-- 我们的实时音视频采用<em class="b">预付费模式</em>， -->
                          为保证您的项目顺利进行，请确保您的剩余时长充足。
                        </div>
                        <div style="margin-top: 15px">
                          <el-button
                            type="primary"
                            size="mini"
                            @click="goChargeAccount"
                            >去充值</el-button
                          >
                          <el-button size="mini" @click="goEvaluate"
                            >预估用量</el-button
                          >
                        </div>
                      </div>
                    </template>
                    <el-button type="text" style="padding: 0"
                      ><i class="iconfont iconbangzhuwendang1"></i
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
              <div class="fzbox">
                <div class="fz">
                  <el-checkbox
                    class="angle"
                    v-model="other.angle1"
                    @change="
                      other.angle1
                        ? (other.monitor = 2)
                        : (other.videotape1 = false);
                      clickChildFn();
                    "
                    >第1视角</el-checkbox
                  ><el-checkbox
                    v-model="other.videotape1"
                    :disabled="!other.angle1"
                    @change="clickChildFn()"
                    >录像</el-checkbox
                  >
                </div>
                <div class="fz">
                  <el-checkbox
                    class="angle"
                    v-model="other.angle2"
                    @change="
                      other.angle2
                        ? (other.monitor = 2)
                        : (other.videotape2 = false);
                      clickChildFn();
                    "
                    >第2视角</el-checkbox
                  ><el-checkbox
                    v-model="other.videotape2"
                    :disabled="!other.angle2"
                    @change="clickChildFn()"
                    >录像</el-checkbox
                  >
                </div>
                <div class="fz" @click="jyCheatFn">
                  <el-checkbox
                    class="angle"
                    v-model="other.angle3"
                    @change="
                      other.angle3
                        ? (other.monitor = 2)
                        : (other.videotape3 = false);
                      clickChildFn();
                    "
                    :disabled="isTrue.isCheat"
                    >屏幕监测</el-checkbox
                  ><el-checkbox
                    v-model="other.videotape3"
                    :disabled="!other.angle3"
                    @change="clickChildFn()"
                    >录像</el-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </el-radio-group>
        <div class="monitorBtn">
          <el-button size="small" @click="monitorOk">确定</el-button>
        </div>
      </el-form-item>

      <!-- 必填项字段已被领导要求屏蔽（要求的是屏蔽，暂不删除，后续使用过程中可能还有想法） -->
      <el-form-item label="必填项：" v-if="isTrue.isMustFill">
        <div
          class="flex flex-space item-font cur"
          @click="other.isMustFillSet = !other.isMustFillSet"
        >
          <span class="c8">{{
            ruleForm.freeForm.length > 0 ? "已设置" : "请设置"
          }}</span>
          <em class="el-icon-arrow-right item-font c9"></em>
        </div>
      </el-form-item>

      <el-form-item label="提交：" v-if="editData">
        <div class="flex flex-space">
          <el-input
            v-model="ruleForm.numSubmit"
            placeholder="输入试题数量"
            style="width: 50%"
          ></el-input>
          <span class="ml40 f12 ccc"
            >注：输入10，即为每10题提交一次答题数据</span
          >
        </div>
      </el-form-item>

      <!-- 云掌门打通asktrue的心理测评 活动报名 问卷调查 项目有一个查看范围 -->
      <!-- from: matcheasy 默认是''云掌门 staffcloud 也是云掌门 -->
      <!-- 项目类型:1考试 2调查 3评估 4报名 5投票 -->
      <el-form-item
        label="查看范围："
        v-if="
          ['staffcloud', ''].indexOf(from) > -1 &&
          [1, 2, 4].indexOf(proData.projectType * 1) > -1
        "
      >
        <div class="formRow">
          <StaffSelector
            placeholder="全体员工"
            title="选择员工"
            :tabs="StaffDialog.causListTabs"
            :checked="StaffDialog.causListChecked"
            @ensure="(e) => chooseBack(e, 'causList')"
            class="mySelect"
          />
        </div>
      </el-form-item>

      <div class="btn-div big tal" v-if="from1 != 'addframe'">
        <el-button type="primary" plain @click="submitForm()">保存</el-button>
      </div>
    </el-form>

    <!----------------------------------弹窗↓------------------------------------------------------------------------------>

    <!-- 必填项，暂时屏蔽，不删除 -->
    <Mustfill
      ref="mustfillFile"
      :isMustFillSet="other.isMustFillSet"
      :freeFormP="ruleForm.freeForm"
      :examId="Number(editData.exam_id)"
      :projectType="Number(editData.projectType)"
      v-on:closePop="closePop"
    />

    <!--AB卷状态改变弹窗-->
    <!-- <el-dialog :visible.sync="isAbChange" width="30%" top="15%" custom-class="confirm-sun-dialog" :append-to-body="false" :modal="false" :fullscreen="false">
      <div slot="title" style="line-height:24px;font-size:18px"><i class="el-icon-warning mr5" style="color:#E6A23C;font-size:24px;"></i><span>清空确认</span></div>
      <div class="tal">项目AB卷设置更改后，问卷、考生、成绩等原有项目数据将被清空，是否继续保存？</div>
      <div slot="footer">
        <el-button size="small" type="primary" @click="submitForm()">是</el-button>
        <el-button size="small" @click="isAbChange = false;">否</el-button>
      </div>
    </el-dialog> -->

    <!--认证弹窗-->
    <el-dialog
      :visible.sync="isConfirm"
      width="30%"
      top="15%"
      custom-class="asktrue-dialog"
      :append-to-body="false"
      :modal="false"
      :fullscreen="false"
    >
      <div slot="title" style="line-height: 24px; font-size: 18px">
        <i
          class="el-icon-warning mr5"
          style="color: #e6a23c; font-size: 24px"
        ></i
        ><span>认证确认</span>
      </div>
      <div class="tal">认证后才能发布，现在去认证?</div>
      <div slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="
            isRenZheng = true;
            isConfirm = false;
          "
          >确定</el-button
        >
        <el-button size="small" @click="isConfirm = false">取消</el-button>
      </div>
    </el-dialog>
    <el-drawer
      :visible.sync="isRenZheng"
      size="65%"
      :append-to-body="true"
      custom-class="asktrue-drawer"
    >
      <CompanyCertification
        class="ceritfication"
        from="xq"
        :status="applyStatus"
        @close="isRenZheng = false"
        v-if="isRenZheng"
      />
    </el-drawer>
  </div>
</template>

<script>
import ImgCropper from "./ImgCropper"; //图片剪裁弹窗
import Mustfill from "./Mustfill"; //引入自定义表单（必填项），暂时屏蔽，不删除
import StaffSelector from "@/components/StaffSelector.vue"; //引入公共选员工弹窗
import {
  postProjectReq,
  // getMustfillReq,
  addAdminInvigilatorReq,
} from "@/api/asktrue/project/project"; //引入接口
import { newGetQuesInfoReq } from "@/api/asktrue/paper/paper";
import { selectProjectLabelListPage } from "@/api/asktrue/project/commodity";
import CompanyCertification from "./certification/Company";
import { selectParams } from "@/api/asktrue/project/commodity";
import { getData } from "@/views/staff/js/data.js";
import DateRangePicker from "./DateRangePicker";
import { getCountReq } from "@/api/asktrue/user/set";

export default {
  name: "EditProject",
  components: {
    ImgCropper,
    Mustfill, //暂时屏蔽，不删除
    CompanyCertification,
    DateRangePicker,
    StaffSelector,
  },
  props: {
    from1: String,
    isOnOff: Object,
    proData: Object, //编辑的信息
    // isTrue: {
    //   type: Object,
    //   default: () => ({
    //     isTrue: {
    //       isTimeTerm: true, //有效期开关
    //       isAB: false, //AB卷开关
    //       isCheat: false, //防作弊开关
    //       isMonitor: false, //监控开关
    //       isMustFill: false, //必填项开关
    //     },
    //   }),
    // }, //有效期等右侧开关
  },
  data() {
    return {
      editData: {},
      isTrue: {
        isTimeTerm: true, //有效期开关
        isAB: false, //AB卷开关
        isCheat: false, //防作弊开关
        isMonitor: false, //监控开关
        isMustFill: false, //必填项开关
      },
      tinymceFlag: 1, //区分编辑器
      //表单数据--前后端数据交互部分
      ruleForm: {
        isOpen: false, //发布到网站
        title: "", //项目名称
        paperTypeList: ["A", "B"], //AB卷选中值
        tags: [], //标签-选中值

        verify: false, //是否认证：false不认证、true认证
        verifyType: "0", //认证方式：0-verify=false时的值；1-通行证；2-手机；3-邮箱；4-人脸识别
        anonymous: false, //匿名，false实名、true匿名

        coverUrl: "", //封面图片url
        desc: "", //须知
        examStartTime: "", //有效期1
        examEndTime: "", //有效期2
        isLookResult: false, //允许查看成绩
        isLookReport: false, //允许查看报告
        isLookAnswer: false, //允许查看答案
        faceRecognition: 1, //开启视频 1不开启 2 开启
        photograph: 1, //开启拍照 1不开启 2 开启
        photoRate: "5", //拍照频率（分钟）
        playerSet: [0, 0, 0, 0, 0, 0],
        freeForm: [], //已设置自定义表单项
        numSubmit: "", //自动提交试题数量
        cutScreen: 1, // 1 不开始切屏提醒 2 开始切屏提醒
        cutNum: 3, // 接口传回来再改
      },
      //表单校验
      formRules: {
        title: [{ required: true, message: "输入名称", trigger: "blur" }],
      },
      other: {
        //其他辅助参数
        isAbSet: false, //是否展开AB卷设置窗口
        abOption: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ], //AB卷选项，不固定值
        tagsOption: [], //标签选项
        showVerify: false, //显示认证展开框，默认隐藏
        verifyLabel: "不认证", //认证回显文字数据
        verifyType: [
          { id: "0", label: "" },
          { id: "3", label: "考号" },
          { id: "1", label: "手机" },
          { id: "2", label: "邮箱" },
          { id: "4", label: "人脸识别" },
        ], //认证回显文字数据

        showCover: false, //显示封面展开框
        coverOption: [], //封面默认图片选项
        coverOption2: [], //封面自定义图片
        cropImageDialog: false, //剪裁框
        nullAutoCover: "", //默认空图片路径

        MonitorLabel: "请选择",
        showMonitor: false,
        angle1: false,
        angle2: false,
        angle3: false,
        videotape1: false, //开启录像
        videotape2: false, //开启录像
        videotape3: false, //开启录像

        monitor: 0, //监控方式

        videotape: false, //开启录像

        isMustFillSet: false, //必填项设置弹窗展开状态
        isTime: false,
      },
      selCoverId: -1,
      applyStatus: 1, //认证状态

      isConfirm: false,
      isRenZheng: false,

      //isAbChange:false,//ab卷更改弹窗
      monitorRemainingAmount: 0, // 剩余音视频时长

      smsCount: 0, // 剩余短信数目
      mailCount: 0, // 剩余邮件数目
      faceRecognCount: 0, // 剩余人脸识别数目

      from: "",
      // 云掌门 查看范围 用到了
      // 选择员工弹窗数据，各个参数在组件中有详细解释；
      StaffDialog: {
        causListTabs: [
          { name: "a", label: "按部门", fos: 1 },
          { name: "b", label: "", fos: 1 }, //供应商、客户；label=""时，系统自动计算label值，目前仅支持name=b
        ],
        causListChecked: [], //应用范围回显
        canListTargetDataScopeList: [{ scopeType: 1, scopeMemberId: null }],
      },
    };
  },
  created() {
    this.getProjectEditFn();
    this.getcoverOptionFn(); //获取固定封面
    this.getCountFn();

    // 如果是空 是来自云掌门的各个项目 如果是matcheasy 则是来自好招的测评
    this.from = this.$route.query.from || "staffcloud";
    if (window.location.href.indexOf("/recruit") != -1) {
      this.from = "matcheasy";
    }
    if (["staffcloud", ""].indexOf(this.from) > -1) {
      // 考试项目
      if (this.proData.projectType == 1) {
        this.selectExamScope();
      } else if (this.proData.projectType == 2) {
        this.selectExamScopeReq();
      } else if (this.proData.projectType == 4) {
        this.selectEnrollScope();
      }
    }
  },
  activated() {
    this.tinymceFlag++;
    this.monitorFn();
    this.getCountFn();
  },
  //监听
  watch: {
    editData: {
      handler(n) {
        if (n && n.exam_id) {
          //console.log(this.editData,'editData==');
          this.getTagsFn();
          this.getFormDataFn();
          this.getABFn();
          this.other.showPrice = false;
        }
      },
      deep: true,
    },
    isTrue: {
      handler() {
        if (!this.isTrue.isTimeTerm) {
          this.ruleForm.timeTerm = [new Date().getTime(), new Date().getTime()];
        }
        if (!this.isTrue.isMonitor) {
          this.other.monitor = 0;
          this.ruleForm.faceRecognition = 1; //监控-视频，1不开启，2开启
          this.ruleForm.photograph = 1; //监控-拍照，1不开启，2开启
          this.other.angle1 = false;
          this.other.angle2 = false;
          this.other.angle3 = false;
          this.other.videotape1 = false;
          this.other.videotape2 = false;
          this.other.videotape3 = false;
          this.other.MonitorLabel = "未设置";
          this.$emit("setJkFn", false);
        } else {
          //this.getFormDataFn();
          // if(this.isTrue.isCheat){
          //   this.other.angle3=false;
          //   this.other.videotape3=false;
          //   this.$emit('setJkFn',false)
          // }else{
          //   this.other.angle3=Number(this.editData.player_set.substring(2,3))==0?false:true;
          //   if(this.other.angle3){
          //     this.$emit('setJkFn',true)
          //   }
          // }
        }
      },
      deep: true,
    },
    "ruleForm.verify": {
      handler(v) {
        this.$emit("isSpecify", v);
      },
      deep: true,
    },
  },
  methods: {
    getProjectEditFn() {
      this.editData = this.proData;
      this.isTrue.isTimeTerm =
        this.editData.exam_end_time || this.editData.exam_start_time
          ? true
          : false;
      this.isTrue.isAB = this.editData.abc == 1 ? true : false;
      this.isTrue.isCheat = this.editData.prevent_chead == 1 ? false : true;
      this.isTrue.isMonitor =
        this.editData.face_recognition == 1 && this.editData.photograph == 1
          ? false
          : true;
      this.isTrue.isMustFill = this.editData.student_write == 1 ? true : false; //开启是1-true，关闭是2-false
      this.isAbCk = this.editData.goods_status == 2 ? true : false;
      this.isJk =
        Number(this.editData.player_set?.substring(2, 3)) == 0 ? false : true; //true-允许开启，false-禁止开启
      //console.log(this.isJk,'==this.editData')
    },
    // 获取音视频剩余时长
    async getCountFn() {
      getCountReq().then((res) => {
        if (res.code == "200") {
          if (res.data.result) {
            this.monitorRemainingAmount =
              res.data.result.videoTime - res.data.result.userVideoTime; // 音视频剩余时长
            this.smsCount = res.data.result.smsCount; // 短信剩余数量
            this.mailCount = res.data.result.mailCount; // 邮件剩余数量
            this.faceRecognCount = res.data.result.faceRecognCount; // 人脸识别剩余次数
          } else {
            this.monitorRemainingAmount = 0;
            this.smsClunt = 0;
            this.mailCount = 0;
            this.faceRecognCount = 0;
          }
        } else {
          this.$message.warning("网络错误！");
        }
      });
    },
    jyCheatFn() {
      if (this.isTrue.isCheat) {
        this.$message.warning("设置防作弊后，不能开启屏幕监测");
      }
    },
    //获取AB卷已选项
    getABFn() {
      this.other.abOption = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      if (
        this.editData.paperTypeList != "" &&
        this.editData.paperTypeList != null
      ) {
        this.ruleForm.paperTypeList = this.editData.paperTypeList.split(",");
      } else {
        this.ruleForm.paperTypeList = ["A", "B"];
      }
      let l1 = [];
      let l2 = [];
      this.ruleForm.paperTypeList.forEach((el) => {
        el.length == 1 ? l1.push(el) : l2.push(el);
      });
      this.ruleForm.paperTypeList = l1.sort().concat(l2.sort());
      if (l2.length) {
        let maxL2 = l2[l2.length - 1];
        let arr = maxL2.split("");
        let n = this.other.abOption.findIndex((el) => {
          return el === arr[0];
        });
        let m = this.other.abOption.findIndex((el) => {
          return el === arr[1];
        });
        let num = (n + 1) * 26 + m;
        for (var i = 26; i <= num; i++) {
          this.addABFn();
        }
      }
    },
    //已选卷标排序
    orderABFn() {
      let l1 = [];
      let l2 = [];
      this.ruleForm.paperTypeList.forEach((el) => {
        el.length == 1 ? l1.push(el) : l2.push(el);
      });
      this.ruleForm.paperTypeList = l1.sort().concat(l2.sort());
    },
    //AB卷删除已选项
    delABFn(index) {
      this.ruleForm.paperTypeList.splice(index, 1);
    },
    //新增卷标
    addABFn() {
      //this.other.abOption
      let ab = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let l = this.other.abOption.length;
      let sw = parseInt(l / 26); //十位，取整
      if (sw > 26) {
        this.$message.warning("目前项目卷标暂时只能支持到ZZ");
        return;
      }
      let gw = l % 26; //个位，取余
      let swzf = ab[sw - 1];
      let gwzf = ab[gw];
      this.other.abOption.push(swzf + "" + gwzf);
    },
    //标签数据
    getTagsFn() {
      selectProjectLabelListPage({ examType: this.editData.projectType }).then(
        (res) => {
          if (res.code == 200) {
            this.other.tagsOption = res.data.result;
          }
        }
      );
    },
    //----------------------------------------------------------------------
    //发布到网站
    changeOpenFn() {
      if (this.ruleForm.isOpen) {
        this.$emit("setAbFn", true);
      } else {
        this.$emit("setAbFn", false);
      }
      this.$store.dispatch("asktrue/getUserInfo").then((res) => {
        this.applyStatus = res.applyStatus == "已认证" ? 1 : 2;
        if (this.applyStatus == 1) {
          //已认证
          this.other.showVerify = false;
          this.ruleForm.verify = true; //认证
          this.ruleForm.verifyType = 3; //考号
          this.ruleForm.anonymous = false; //实名
          let type =
            this.other.verifyType[
              this.other.verifyType.findIndex(
                (item) => item.id == this.ruleForm.verifyType
              )
            ].label;
          let rn = "";
          if (this.editData.projectType != 1) {
            rn = this.ruleForm.anonymous ? "匿名-" : "实名-";
          }
          this.other.verifyLabel = "认证 / " + rn + type; //“认证”行显示文字
        } else {
          this.ruleForm.isOpen = false;
          this.isConfirm = true;
          // this.$confirm('认证后才能发布，现在去认证?', '认证确认', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.isRenZheng = true;
          // }).catch(() => {});
        }
      });
    },
    //----------------------------------------------------------------------
    //认证展开框
    clickVerifyFn() {
      if (this.ruleForm.isOpen) {
        if (this.editData.from_type == 2) {
          this.$message("提示：发布到网站的项目，认证方式不可更改");
        } else {
          this.$message("提示：购买的项目，认证方式不可更改");
        }
      } else {
        this.other.showVerify = !this.other.showVerify;
      }
    },
    //点击不认证
    changeVerifyFn() {
      this.ruleForm.verify = false; //不认证
      this.ruleForm.verifyType = 0; //清楚认证方式，默认为0，实际开发时再根据情况调整0或空
      this.other.showVerify = false; //关闭弹窗
      this.other.verifyLabel = "不认证"; //“认证”行显示文字
      this.ruleForm.anonymous = false;
    },
    //点击认证方式
    changeVerifyTypeFn() {
      this.ruleForm.verify = true; //认证
      this.other.showVerify = false; //关闭弹窗
      let type =
        this.other.verifyType[
          this.other.verifyType.findIndex(
            (item) => item.id == this.ruleForm.verifyType
          )
        ].label;
      let rn = "";
      if (this.editData.projectType != 1) {
        rn = this.ruleForm.anonymous ? "匿名-" : "实名-";
      }
      this.other.verifyLabel = "认证 / " + rn + type; //“认证”行显示文字
    },
    //匿名
    changeAnonymous() {
      if (
        this.ruleForm.anonymous &&
        (this.ruleForm.verifyType == "3" ||
          this.ruleForm.verifyType == "4" ||
          this.ruleForm.verifyType == "0")
      ) {
        this.ruleForm.verifyType = "1";
      }
      this.changeVerifyTypeFn();
      this.other.showVerify = true; //开启弹窗
    },

    //----------------------------------------------------------------------

    //------------------------------------------------------------------------
    //封面默认图片
    getcoverOptionFn() {
      this.other.coverOption = [
        // {path:"https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/1.jpg"},
        // {path:"https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/2.jpg"},
        {
          path: "https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/3.jpg",
        },
        {
          path: "https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/4.jpg",
        },
        {
          path: "https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/5.jpg",
        },
        // {path:"https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/6.jpg"},
        {
          path: "https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/7.jpg",
        },
        {
          path: "https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/8.jpg",
        },
        // {path:"https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/9.jpg"},
        // {path:"https://asktrue.oss-cn-beijing.aliyuncs.com/front/project/10.jpg"}
      ];
    },
    //选择系统提供的封面
    chooseImgFn(item, index) {
      this.selCoverId = index;
      this.ruleForm.coverUrl = item;
    },
    //选择自定义封面
    chooseCustomImgFn() {
      this.selCoverId = -1;
      this.ruleForm.coverUrl = this.other.coverOption2[0];
    },
    //上传封面
    uplodeImg(e) {
      this.ruleForm.coverUrl = window.URL.createObjectURL(e.target.files[0]);
      this.other.cropImageDialog = true;
      this.other.nullAutoCover = "";
      this.$refs.uploadFile.value = null;
    },
    //移除图片
    delImgFn(index) {
      if (this.ruleForm.coverUrl == this.other.coverOption2[index]) {
        this.ruleForm.coverUrl = "";
      }
      this.other.coverOption2.splice(index, 1);
    },
    //获取封面
    getImgUrl(e) {
      this.ruleForm.coverUrl = e;
      this.selCoverId = 0;
      if (e) {
        this.other.coverOption2 = [];
        this.other.coverOption2.unshift(this.ruleForm.coverUrl);
        this.selCoverId = -1;
      }
      this.other.cropImageDialog = false;
    },
    //------------------------------------------------------------------------
    //必填项接口
    getMustfillFn() {
      let params = {
        examId: this.editData.exam_id,
        paperId: -1,
        paperTypeId: 0,
        projectType: this.editData.projectType,
      };
      newGetQuesInfoReq(params).then((res) => {
        if (res.code == "200") {
          this.ruleForm.freeForm = [];

          res.data.result.map((item) => {
            let value = "";
            let level = 1;
            if (
              item.infoTypeId != 1 &&
              item.infoTypeId != 4 &&
              (item.infoTypeId != 5) & (item.infoTypeId != 116)
            ) {
              //下拉
              value = [{ answer: "" }, { answer: "" }];
              level = item.xlLevelSet
                ? Number(item.xlLevelSet.split("#zbw#")[0])
                : 1;
            }
            let formObj = {
              examId: item.examId,
              infoId: item.infoId,
              title: item.infoText1,
              placeholder:
                level > 1 && item.xlLevelSet
                  ? item.xlLevelSet.split("#zbw#")[1]
                  : item.infoText2,
              placeholder2:
                level > 1 && item.xlLevelSet
                  ? item.xlLevelSet.split("#zbw#")[2]
                  : "",
              type: item.displayStyle == 1 ? "textarea" : "select",
              infoTypeId: item.infoTypeId,
              option: [],
              status: item.status,
              isPrev: true,
              xlLevelNeed: item.xlLevelNeed,
              isSys: item.startSystemAddr == 1 ? true : false,
              value,
              level,
            };
            //改变选项数组
            if (item.displayStyle == 2) {
              //下拉
              if (item.startSystemAddr == 1) {
                //启用系统数据
                let ts = [
                  { type: "tSchool", infoTypeId: 8 },
                  { type: "tMajor", infoTypeId: 7 },
                  { type: "tTrade", infoTypeId: 14 },
                  { type: "tCity", infoTypeId: 9 },
                  { type: "tJobKind", infoTypeId: 12 },
                ];
                let parameterType = ts.find(
                  (ele) => ele.infoTypeId == item.infoTypeId
                ).type;
                this.getOption(parameterType).then((data) => {
                  formObj.option = data;
                  //改变自定义表单数组
                  this.ruleForm.freeForm.push(formObj);
                });
              } else {
                //文本
                item.list.map((oitem) => {
                  formObj.option.push({
                    infoId: oitem.infoId,
                    label: oitem.optionText,
                    status: item.status == 2 ? 2 : oitem.status || 1,
                    id: oitem.selectId,
                  });
                });
                this.ruleForm.freeForm.push(formObj);
              }
            } else {
              //文本
              item.list.map((oitem) => {
                formObj.option.push({
                  infoId: oitem.infoId,
                  label: oitem.optionText,
                  status: item.status == 2 ? 2 : oitem.status || 1,
                  id: oitem.selectId,
                });
              });
              this.ruleForm.freeForm.push(formObj);
            }
          });
        }
      });
    },
    //获取参数选项-学历、院校等
    async getOption(item) {
      let tempOption = [];
      if (!item) {
        return;
      }
      await selectParams({ parameterType: item }).then((res) => {
        if (res.code == "200") {
          let selectArr = res.data.result || [];
          selectArr.forEach((ele) => {
            let valId = item == "tSchool" ? ele.city_id || "0000" : ele.value;
            let obj = {
              label: ele.label,
              id: valId,
              state: false,
              isShow: true,
              status: 1,
            };
            let selId = String(obj.id);
            if (selId.length < 2) {
              return false;
            }
            obj.id =
              String(valId).length / 2 == 2
                ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
                : String(valId).length / 2 == 3
                ? selId.substring(0, 2) +
                  "-" +
                  selId.substring(2, 4) +
                  "-" +
                  selId.substring(4, 6)
                : selId;
            tempOption.push(obj);
          });
          if (item == "tSchool" || item == "tCity") {
            //院校/地点
            tempOption = tempOption.concat(
              JSON.parse(
                JSON.stringify(getData("provinceVar")).replace(
                  /value/g,
                  "label"
                )
              )
            );
            tempOption.forEach((ele) => {
              ele.state = false;
              ele.status = 1;
            });
          }
        }
      });
      return tempOption;
    },

    clickMonitorFn() {
      this.other.showMonitor = !this.other.showMonitor;
    },
    clickChildFn() {
      if (this.isTrue.isMonitor) {
        if (this.other.monitor == 1) {
          //拍照
          this.ruleForm.faceRecognition = 1; //监控-视频，1不开启，2开启
          this.ruleForm.photograph = 2; //监控-拍照，1不开启，2开启
          this.other.MonitorLabel =
            "拍照 / 每" + this.ruleForm.photoRate + "分钟拍一次";
        } else if (this.other.monitor == 2) {
          this.ruleForm.faceRecognition = 2; //监控-视频，1不开启，2开启
          this.ruleForm.photograph = 1; //监控-拍照，1不开启，2开启
          let label = "实时音视频 / ";
          if (this.other.angle1) {
            label =
              label +
              "第1视角" +
              (this.other.videotape1 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if (this.other.angle1 && this.other.angle2) {
            label = label + " _ ";
          }
          if (this.other.angle2) {
            label =
              label +
              "第2视角" +
              (this.other.videotape2 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if ((this.other.angle1 || this.other.angle2) && this.other.angle3) {
            label = label + " _ ";
          }
          if (this.other.angle3) {
            label =
              label +
              "屏幕监测" +
              (this.other.videotape3 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
            this.$emit("setJkFn", true);
          } else {
            this.$emit("setJkFn", false);
          }
          this.other.MonitorLabel = label;
        }
      } else {
        this.other.MonitorLabel = "未设置";
      }
    },
    //监控
    monitorFn() {
      if (this.isTrue.isMonitor) {
        if (this.other.monitor == 1) {
          //拍照
          this.ruleForm.faceRecognition = 1; //监控-视频，1不开启，2开启
          this.ruleForm.photograph = 2; //监控-拍照，1不开启，2开启
          this.other.angle1 = false;
          this.other.angle2 = false;
          this.other.angle3 = false;
          this.other.MonitorLabel =
            "拍照 / 每" + this.ruleForm.photoRate + "分钟拍一次";
        } else if (this.other.monitor == 2) {
          //视频
          this.ruleForm.faceRecognition = 2; //监控-视频，1不开启，2开启
          this.ruleForm.photograph = 1; //监控-拍照，1不开启，2开启
          this.other.angle1 =
            Number(this.editData.player_set.substring(0, 1)) == 0
              ? false
              : true;
          this.other.angle2 =
            Number(this.editData.player_set.substring(1, 2)) == 0
              ? false
              : true;
          this.other.angle3 =
            Number(this.editData.player_set.substring(2, 3)) == 0
              ? false
              : true;
          this.other.videotape1 =
            Number(this.editData.player_set.substring(0, 1)) == 2
              ? true
              : false;
          this.other.videotape2 =
            Number(this.editData.player_set.substring(1, 2)) == 2
              ? true
              : false;
          this.other.videotape3 =
            Number(this.editData.player_set.substring(2, 3)) == 2
              ? true
              : false;
          if (!this.other.angle1 && !this.other.angle2 && !this.other.angle3) {
            this.other.angle1 = true;
          }
          if (this.isTrue.isCheat) {
            this.other.angle3 = false;
            this.other.videotape3 = false;
          }
          let label = "实时音视频 / ";
          if (this.other.angle1) {
            label =
              label +
              "第1视角" +
              (this.other.videotape1 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if (this.other.angle1 && this.other.angle2) {
            label = label + " _ ";
          }
          if (this.other.angle2) {
            label =
              label +
              "第2视角" +
              (this.other.videotape2 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if ((this.other.angle1 || this.other.angle2) && this.other.angle3) {
            label = label + " _ ";
          }
          if (this.other.angle3) {
            label =
              label +
              "屏幕监测" +
              (this.other.videotape3 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
            this.$emit("setJkFn", true);
          } else {
            this.$emit("setJkFn", false);
          }
          this.other.MonitorLabel = label;
        }
      } else {
        //监控关闭
        this.other.showMonitor = false;
        this.ruleForm.faceRecognition = 1; //监控视频，1不开启，2开启
        this.ruleForm.photograph = 1; //监控拍照，1不开启，2开启
        this.other.angle1 = false;
        this.other.angle2 = false;
        this.other.angle3 = false;
      }
    },
    checkMonitorRemainingAmount() {
      console.log("111");
    },
    async monitorOk() {
      if (this.other.monitor == 2) {
        // await this.getCountFn()
        if (this.monitorRemainingAmount <= 0) {
          this.$message.warning(
            "音视频时长不足，请在栏目“设置-音视频”充值后再处理"
          );
          return false;
        }
        if (this.other.angle1 || this.other.angle2 || this.other.angle3) {
          this.other.showMonitor = false;
        } else {
          this.$message.warning("请设置视频监控方式");
          return;
        }
      } else if (this.other.monitor == 1) {
        var numReg = /^[0-9]*$/;
        var numRe = new RegExp(numReg);
        if (
          this.ruleForm.photoRate != "" &&
          this.ruleForm.photoRate > 0 &&
          numRe.test(this.ruleForm.photoRate)
        ) {
          this.other.showMonitor = false;
        } else {
          this.$message.warning("请设置拍照频率");
        }
      } else {
        this.$message.warning("请选择监控方式");
      }
    },
    //获取自定义表单数据
    getFormDataFn() {
      if (this.editData) {
        this.ruleForm.title = this.editData.exam_name;
        this.ruleForm.isOpen = this.editData.goods_status == 2 ? true : false;
        this.ruleForm.verify =
          this.editData.specify_student == 1 ? true : false;
        this.ruleForm.verifyType = this.editData.login_rule;
        this.ruleForm.anonymous = this.editData.real_name == 1 ? false : true;
        this.ruleForm.cutScreen = this.editData.cut_screen;
        this.ruleForm.cutNum = this.editData.cut_num || 3;
        if (this.ruleForm.verify) {
          this.changeVerifyTypeFn();
        } else {
          this.changeVerifyFn();
        }

        this.ruleForm.coverUrl = this.editData.logo_path;
        if (this.editData.logo_path) {
          this.other.coverOption2 = [this.editData.logo_path];
          this.selCoverId = -1;
        }
        this.ruleForm.desc = this.editData.exam_text;
        var thisd = new Date();
        this.ruleForm.examStartTime =
          this.editData.exam_start_time ||
          new Date(+thisd + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 19)
            .replace("T", " ");
        this.ruleForm.examEndTime =
          this.editData.exam_end_time ||
          new Date(+thisd.setMonth(thisd.getMonth() + 1) + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 19)
            .replace("T", " ");
        this.other.isTime = true;
        this.ruleForm.isLookResult =
          this.editData.allow_view_score == 1 ? true : false;
        this.ruleForm.isLookReport =
          this.editData.allow_view_report == 1 ? true : false;

        if (this.editData.channel_ids) {
          this.ruleForm.tags = this.editData.channel_ids.split(",");
        } else {
          this.ruleForm.tags = [];
        }
        this.other.monitor = 0;
        this.other.MonitorLabel = "请选择";

        this.other.angle1 = false;
        this.other.angle2 = false;
        this.other.angle3 = false;
        this.other.videotape1 = false;
        this.other.videotape2 = false;
        this.other.videotape3 = false;
        if (Number(this.editData.photograph) == 2) {
          this.other.monitor = 1;
          this.ruleForm.photograph = 2; //监控-视频，1不开启，2开启
          this.other.MonitorLabel =
            "拍照 / 每" + this.ruleForm.photoRate + "分钟拍一次";
        } //监控回显-拍照
        if (Number(this.editData.face_recognition) == 2) {
          this.other.monitor = 2;
          this.ruleForm.faceRecognition = 2; //监控-视频，1不开启，2开启
          this.other.angle1 =
            Number(this.editData.player_set.substring(0, 1)) == 0
              ? false
              : true;
          this.other.angle2 =
            Number(this.editData.player_set.substring(1, 2)) == 0
              ? false
              : true;
          this.other.angle3 =
            Number(this.editData.player_set.substring(2, 3)) == 0
              ? false
              : true;
          this.other.videotape1 =
            Number(this.editData.player_set.substring(0, 1)) == 2
              ? true
              : false;
          this.other.videotape2 =
            Number(this.editData.player_set.substring(1, 2)) == 2
              ? true
              : false;
          this.other.videotape3 =
            Number(this.editData.player_set.substring(2, 3)) == 2
              ? true
              : false;
          if (this.isTrue.isCheat) {
            this.other.angle3 = false;
            this.other.videotape3 = false;
          }
          let label = "实时音视频 / ";
          if (this.other.angle1) {
            label =
              label +
              "第1视角" +
              (this.other.videotape1 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if (this.other.angle1 && this.other.angle2) {
            label = label + " _ ";
          }
          if (this.other.angle2) {
            label =
              label +
              "第2视角" +
              (this.other.videotape2 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          if ((this.other.angle1 || this.other.angle2) && this.other.angle3) {
            label = label + " _ ";
          }
          if (this.other.angle3) {
            label =
              label +
              "屏幕监测" +
              (this.other.videotape3 ? "（录像）" : "") +
              "<em class='el-icon-check c39c'></em>";
          }
          this.other.MonitorLabel = label;
        } //监控回显-视频
        this.ruleForm.photoRate = this.editData.photo_rate || 5;
        this.ruleForm.numSubmit = this.editData.num_submit;

        this.getMustfillFn(); //获取必填项数据
      }
    },

    //保存
    async submitForm(editFlag) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.isOpen) {
            if (!this.ruleForm.tags || this.ruleForm.tags.length == 0) {
              this.$message.error("请选择标签");
              return;
            }
            if (this.ruleForm.coverUrl == "") {
              this.$message.error("请选择或上传封面");
              return;
            }
          }
          console.log(this.other.monitor, "==是否存在监控");
          if (
            this.isTrue.isMonitor &&
            this.other.monitor != 1 &&
            this.other.monitor != 2
          ) {
            this.$message.error("请选择监控方式");
            return;
          }

          let n1 = "0";
          let n2 = "0";
          let n3 = "0";
          if (this.ruleForm.faceRecognition == 2) {
            n1 = !this.other.angle1 ? "0" : this.other.videotape1 ? "2" : "1";
            n2 = !this.other.angle2 ? "0" : this.other.videotape2 ? "2" : "1";
            n3 = !this.other.angle3 ? "0" : this.other.videotape3 ? "2" : "1";

            // await this.getCountFn()
            if (this.monitorRemainingAmount <= 0) {
              this.$message.warning(
                "音视频时长不足，请在栏目“设置-音视频”充值后再处理"
              );
              return false;
            }

            if (n1 == "0" && n2 == "0" && n3 == "0") {
              this.$message.warning("请设置视频监控方式");
              return;
            } else {
              this.other.showMonitor = false;
            }
          }
          let params = {
            examId: this.editData.exam_id,
            projectType: this.editData.projectType,

            examName: this.ruleForm.title, //项目名称

            examText: this.ruleForm.desc, //须知
            cutScreen: Number(this.ruleForm.cutScreen), // 切屏提醒 1不开启 2 开启
            cutNum: Number(this.ruleForm.cutNum), // 切屏次数
            allowViewScore: this.ruleForm.isLookResult ? 1 : 2, //允许考生看成绩 1允许
            allowViewReport: this.ruleForm.isLookReport ? 1 : 2, //允许考生看报告 1允许
            numSubmit: this.ruleForm.numSubmit, //自动提交试题数量

            examStatus: this.editData.exam_status, //项目状态 1上线 3下线
            faceRecognition: this.ruleForm.faceRecognition, //开启视频 1不开启 2 开启
            photograph: this.ruleForm.photograph, //开启拍照 1不开启 2 开启
            photoRate: this.ruleForm.photoRate, //拍照频率，单位分钟

            playerSet: "" + n1 + n2 + n3 + 0 + 0 + 0,
            fromType: this.editData.from_type, //1：购买的项目 2：自建项目
            preventChead: this.isTrue.isCheat ? 2 : 1, //是否防作弊 1不防作弊 2 防作敝
            studentWrite: this.isTrue.isMustFill ? 1 : 2, //必填项，开启是1-true，关闭是2-false
            abc: this.isTrue.isAB ? 1 : 2, //是否区分试卷类型 1:是 2否
            paperTypeList: this.isTrue.isAB
              ? String(this.ruleForm.paperTypeList)
              : null, //AB卷列表，多个使用英文,分割，abc为1时有值
            // 有些项目原来没有 memberId 更新的时候需要传上
            memberId:
              this.proData.memberId ||
              this.$store.state.asktrue.userInfo.userId,
          };

          if (this.isTrue.isTimeTerm) {
            if (!this.ruleForm.examStartTime || !this.ruleForm.examEndTime) {
              this.$message.error("请设置有效期");
              return;
            }
            params.examStartTime = this.ruleForm.examStartTime; //有效期开始时间，
            params.examEndTime = this.ruleForm.examEndTime; //有效期结束时间，
          } else {
            params.examStartTime = ""; //有效期开始时间，
            params.examEndTime = ""; //有效期结束时间，
          }

          if (this.editData.from_type == 2) {
            //自建项目
            params.goodsStatus = this.ruleForm.isOpen ? 2 : 1; //是否发布到网站，售卖状态 1:未上架 2己上架 9下架(暂时无用，勾选复选框只是改变1、2)
            params.abc = this.isTrue.isAB == true ? 1 : 2; //是否区分试卷类型 1:是 2否
            params.paperTypeList = String(this.ruleForm.paperTypeList); //AB卷列表，多个使用英文,分割，abc为1时有值
            params.channelIds = this.ruleForm.tags.join(","); //标签-选中值
            params.specifyStudent = this.ruleForm.verify ? 1 : 2; //是否认证，考试形式 1 认证 2 不认证
            params.loginRule = this.ruleForm.verifyType; //认证方式：0-specifyStudent=false时的值；1-手机；2-邮箱；3-考号；4-人脸识别
            params.realName = this.ruleForm.anonymous ? 2 : 1; //是否匿名 实名1 匿名2
            params.logoPath = this.ruleForm.coverUrl; //封面图片url
          }
          //初次提交不传editFlag，故走不到以下两个if判断条件，读取接口后，后台校验数据，如需清空数据，接口返回对应的editFlag值
          //editFlag=='1'代表只改变了ab卷，提示是否继续操作【ab卷改变时所有项目类型，删：问卷、考生、成绩、数据】
          //editFlag=='2'代表只改变了评估的认证状态，提示是否继续操作【认证状态改变只限评估项目，删：评价关系、成绩、数据】
          //editFlag=='3'代表既改变了ab卷又改变了评估的认证状态，先提示ab卷改变，“是”后再提示认证状态改变，是否继续操作
          //校验ab卷发生改变且影响到现有数据后，重新提交给后台后新增增加的参数
          if (editFlag && (editFlag == "1" || editFlag == "3")) {
            params.editFlag = "1";
          }
          if (editFlag && (editFlag == "2" || editFlag == "3")) {
            params.editFlag1 = "1";
          }

          postProjectReq(params).then((res) => {
            if (res.code == "200") {
              // 来自云掌门 考试项目[心理测评]
              if (
                ["staffcloud", ""].indexOf(this.from) > -1 &&
                this.editData.projectType == 1
              ) {
                this.handleSaveExamScope(
                  this.editData.exam_id,
                  this.editData.projectType
                );
              } else if (
                ["staffcloud", ""].indexOf(this.from) > -1 &&
                this.editData.projectType == 2
              ) {
                this.handleSaveExamScope(
                  this.editData.exam_id,
                  this.editData.projectType
                );
              } else if (
                ["staffcloud", ""].indexOf(this.from) > -1 &&
                this.editData.projectType == 4
              ) {
                this.handleSaveExamScope(
                  this.editData.exam_id,
                  this.editData.projectType
                );
              }

              let tips = "";
              if (this.editData.projectType == 1) {
                tips = "考生";
              } else if (this.editData.projectType == 2) {
                tips = "样本";
              } else if (this.editData.projectType == 3) {
                tips = "评价关系";
              } else if (this.editData.projectType == 4) {
                tips = "报名";
              } else if (this.editData.projectType == 5) {
                tips = "候选";
              }
              if (res.data.result == "1") {
                //ab卷发生改变且影响到问卷、考生等数据后，重新提交给后台后返回
                this.$confirm(
                  "项目AB卷设置变更后，问卷、" +
                    tips +
                    "、成绩、数据等原有项目数据将被清空，是否继续保存？",
                  "提示",
                  {
                    confirmButtonText: " 是 ",
                    cancelButtonText: " 否 ",
                    customClass: "confirm",
                  }
                )
                  .then(() => {
                    this.submitForm("1");
                  })
                  .catch(() => {
                    //不操作
                  });
              } else if (res.data.result == "2") {
                //认证状态发生改变，影响数据
                this.$confirm(
                  "项目认证状态设置变更后，" +
                    tips +
                    "、成绩、数据等原有项目数据将被清空，是否继续保存？",
                  "提示",
                  {
                    confirmButtonText: " 是 ",
                    cancelButtonText: " 否 ",
                    customClass: "confirm",
                  }
                )
                  .then(() => {
                    this.submitForm("2");
                  })
                  .catch(() => {
                    //不操作
                  });
              } else if (res.data.result == "3") {
                //ab卷和认证状态都发生改变，影响数据
                this.$confirm(
                  "项目AB卷设置变更后，问卷、" +
                    tips +
                    "、成绩、数据等原有项目数据将被清空，是否继续保存？",
                  "提示",
                  {
                    confirmButtonText: " 是 ",
                    cancelButtonText: " 否 ",
                    customClass: "confirm",
                  }
                )
                  .then(() => {
                    this.$confirm(
                      "项目认证状态设置变更后，" +
                        tips +
                        "、成绩、数据等原有项目数据将被清空，是否继续保存？",
                      "提示",
                      {
                        confirmButtonText: " 是 ",
                        cancelButtonText: " 否 ",
                        customClass: "confirm",
                      }
                    )
                      .then(() => {
                        this.submitForm("3");
                      })
                      .catch(() => {
                        //不操作
                      });
                  })
                  .catch(() => {
                    //不操作
                  });
              } else {
                //正常提交返回
                this.$message.success("提交成功");
                if (this.from1 == "addframe") {
                  console.log(this.proData, res.data, "==dd");
                  let query = {
                    id: res.data.examId,
                    type: this.proData.projectType,
                    ftype: 2,
                  };
                  this.$emit("saveNext", query);
                } else {
                  this.addAdminInvigilatorFn();

                  this.$emit("getProjectEditFn");
                  this.$emit("getProjectFn", 1);
                  if (this.editData.goods_status == 1 && this.ruleForm.isOpen) {
                    const confirmText = [
                      "是否现在就去设置商品信息？",
                      "或者也可以从左侧“设置”下的“商品”栏目进入。",
                    ];
                    const newDatas = [];
                    const h = this.$createElement;
                    for (const i in confirmText) {
                      newDatas.push(h("p", null, confirmText[i]));
                    }
                    this.$confirm(h("div", null, newDatas), "提示", {
                      confirmButtonText: "现在去设置",
                      cancelButtonText: "稍后设置",
                      customClass: "confirm",
                    })
                      .then(() => {
                        let shops = "";
                        if (this.editData.projectType == 1) {
                          shops = "ProKsCommodity";
                        }
                        if (this.editData.projectType == 2) {
                          shops = "ProDcCommodity";
                        }
                        if (this.editData.projectType == 3) {
                          shops = "ProPgCommodity";
                        }
                        if (this.editData.projectType == 4) {
                          shops = "ProBmCommodity";
                        }
                        if (this.editData.projectType == 5) {
                          shops = "ProTpCommodity";
                        }
                        this.$router.push({
                          name: shops,
                          query: {
                            id: this.editData.exam_id,
                            isJk: this.editData.face_recognition,
                            type: this.editData.projectType,
                            vipId: this.editData.vip_id,
                          },
                        });
                      })
                      .catch(() => {});
                  }
                }
                this.$emit("refresh");
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    addAdminInvigilatorFn() {
      if (this.isTrue.isMonitor) {
        addAdminInvigilatorReq({
          examId: this.editData.exam_id,
          projectType: this.editData.projectType,
        }).then((res) => {
          console.log(res, "==-addAdminInvigilatorReq()");
          if (res.code == "200") {
            //
          }
        });
      }
    },
    //关闭
    closePop() {
      this.other.isMustFillSet = false;
    },

    //选时间
    changeRange(start, end) {
      console.log(start, end);
      this.ruleForm.examStartTime = new Date(+start + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
      this.ruleForm.examEndTime = new Date(+end + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
    },

    goEvaluate() {
      let routeUrl = this.$router.resolve({
        path: "/Com/Setting/monitorEvaluate/monitor",
      });
      window.open(routeUrl.href, "_blank");
    },

    goCharge() {
      let routeUrl = this.$router.resolve({
        path: "/Com/Setting/monitor/monitor",
      });
      window.open(routeUrl.href, "_blank");
    },

    // 查看范围 选员工弹窗返回
    chooseBack(v, type, index) {
      console.log(v, index, "==vv");
      if (type == "causList") {
        //应用范围
        this.StaffDialog.causListChecked = v;
        this.StaffDialog.canListTargetDataScopeList.splice(0);
        if (v && v.length) {
          v.forEach((e) => {
            this.StaffDialog.canListTargetDataScopeList.push({
              parentId: e.urlId.split("_")[0],
              scopeMemberId: e.id,
              scopeMemberNames: e.name,
              scopeType: e.treeType,
            });
          });
        } else {
          this.StaffDialog.canListTargetDataScopeList = [
            { scopeType: 1, scopeMemberId: null },
          ];
        }
      }
    },

    // 查看范围
    selectExamScope() {
      let examId = this.proData.exam_id;
      this.$api.selectExamScope({ examId }).then((res) => {
        if (res.code === "200") {
          let canListTargetDataScopeList = res.record;
          // 如果没有设置查看范围，默认是全部
          if (
            !canListTargetDataScopeList ||
            canListTargetDataScopeList.length == 0
          ) {
            canListTargetDataScopeList = [
              { scopeType: 1, scopeMemberId: null },
            ];
          }
          this.$set(
            this.StaffDialog,
            "canListTargetDataScopeList",
            canListTargetDataScopeList
          );
          let causListChecked = [];
          res.record &&
            res.record.forEach((e) => {
              causListChecked.push({
                compId: e.parentId,
                id: e.scopeMemberId,
                name: e.scopeMemberNames,
                examId: e.examId,
                treeType: e.scopeType,
              });
            });
          this.$set(this.StaffDialog, "causListChecked", causListChecked);
        }
      });
    },
    // 查看范围
    selectExamScopeReq() {
      let examId = this.proData.exam_id;
      this.$api.selectExamScopeReq({ examId }).then((res) => {
        if (res.code === "200") {
          let canListTargetDataScopeList = res.record;
          // 如果没有设置查看范围，默认是全部
          if (
            !canListTargetDataScopeList ||
            canListTargetDataScopeList.length == 0
          ) {
            canListTargetDataScopeList = [
              { scopeType: 1, scopeMemberId: null },
            ];
          }
          this.$set(
            this.StaffDialog,
            "canListTargetDataScopeList",
            canListTargetDataScopeList
          );
          let causListChecked = [];
          res.record &&
            res.record.forEach((e) => {
              causListChecked.push({
                compId: e.parentId,
                id: e.scopeMemberId,
                name: e.scopeMemberNames,
                examId: e.examId,
                treeType: e.scopeType,
              });
            });
          this.$set(this.StaffDialog, "causListChecked", causListChecked);
        }
      });
    },
    // 查看范围
    selectEnrollScope() {
      let examId = this.proData.exam_id;
      this.$api.selectEnrollScope({ examId }).then((res) => {
        if (res.code === "200") {
          let canListTargetDataScopeList = res.record;
          // 如果没有设置查看范围，默认是全部
          if (
            !canListTargetDataScopeList ||
            canListTargetDataScopeList.length == 0
          ) {
            canListTargetDataScopeList = [
              { scopeType: 1, scopeMemberId: null },
            ];
          }
          this.$set(
            this.StaffDialog,
            "canListTargetDataScopeList",
            canListTargetDataScopeList
          );
          let causListChecked = [];
          res.record &&
            res.record.forEach((e) => {
              causListChecked.push({
                compId: e.parentId,
                id: e.scopeMemberId,
                name: e.scopeMemberNames,
                examId: e.examId,
                treeType: e.scopeType,
              });
            });
          this.$set(this.StaffDialog, "causListChecked", causListChecked);
        }
      });
    },
    // 保存项目的查看范围
    handleSaveExamScope(examId, projectType) {
      let scopeList = JSON.parse(
        JSON.stringify(this.StaffDialog.canListTargetDataScopeList)
      );
      scopeList.forEach((item) => {
        item.examId = examId;
      });
      if (projectType == 1) {
        this.$api.saveExamScope(scopeList).then((res) => {
          if (res.code === "200") {
            console.log("保存项目查看范围成功");
          }
        });
      } else if (projectType == 2) {
        this.$api.saveExamScopeReq(scopeList).then((res) => {
          if (res.code === "200") {
            console.log("保存项目查看范围成功");
          }
        });
      } else if (projectType == 4) {
        this.$api.saveEnrollScope(scopeList).then((res) => {
          if (res.code === "200") {
            console.log("保存项目查看范围成功");
          }
        });
      }
    },

    goChargeAccount() {
      // let routeUrl = this.$router.resolve({
      //   path: "https://asktrue.co/#/Com/Setting/Account/Account",
      // });
      window.open("https://asktrue.co/#/Com/Setting/Account/Account", "_blank");
    },
    handleChangeVerifyTypeMail(event) {
      if (this.mailCount < 1) {
        this.$message.info("没有充足的邮件数量，请充值。");
        event.preventDefault();
      }
    },
    handleChangeVerifyTypeSms(event) {
      if (this.smsCount < 1) {
        this.$message.info("没有充足的短信数量，请充值。");
        event.preventDefault();
      }
    },
    handleChangeVerifyTypeFaceRecogn(event) {
      if (this.faceRecognCount < 1) {
        this.$message.info("没有充足的人脸识别次数，请充值。");
        event.preventDefault();
      }
    },
  },
  filters: {
    formatMinute: function (value) {
      // 以千为单位，只保留一位小数
      if (value) {
        // return `${(value / 1000).toFixed(2)} 千`
        return value;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-wrap {
  padding: 50px 60px 50px 50px;
  .sidebar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
    .oc {
      display: flex;
      align-items: center;
      gap: 10px;
      /deep/.el-switch {
        height: 18px;
        .el-switch__core {
          height: inherit;
          &::after {
            width: 15px;
            height: 15px;
          }
        }
        &.is-checked {
          .el-switch__core::after {
            margin-left: -16px;
          }
        }
      }
    }
  }
  /deep/ .g-form-div {
    .el-input {
      height: 40px;
    }
    .el-input__inner,
    .el-textarea__inner {
      border: 0 !important;
    }
    .el-form-item__label {
      font-weight: 700;
    }
    .el-form-item__content {
      line-height: 30px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #f2f2f2;
      .item-font {
        line-height: 40px;
      }
      .open-label {
        .el-checkbox__inner {
          width: 12px;
          height: 12px;
        }
        .el-checkbox__inner::after {
          height: 5px;
          left: 3px;
        }
        .el-checkbox__label {
          font-size: 12px;
          padding-left: 5px;
        }
      }
      .cover {
        img {
          width: 50px;
          height: 40px;
          border: 1px solid #e4e4e4;
          cursor: pointer;
          border-radius: 2px;
        }
      }
      ul.cover {
        display: flex;
        flex-wrap: wrap;
        background-color: #f8f8f8;
        padding: 7px 15px;
        margin-top: 10px;
        li {
          width: 15%;
          height: 0px;
          padding-top: 12.5%;
          position: relative;
          margin: 8px 2% 8px 0;
          border: 0px solid #e4e4e4;
          background-color: #fff;
          &:last-child {
            margin-right: 0px;
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            object-fit: cover;
          }
          span.el-icon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -12px 0 0 -12px;
          }
          em {
            right: -10px;
            top: -8px;
            border: 1px solid#e4e4e4;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50%;
            line-height: 20px;
            color: #ccc;
            z-index: 1000;
            display: none;
          }
          .el-icon-plus {
            font-size: 24px;
            color: #ccc;
            margin-top: 23px;
            cursor: pointer;
          }
        }
        li.on {
          // border: 1px solid#39c;
          // box-shadow: 2px 2px 4px rgba(51, 153, 204, 0.5);
          border: 1px solid var(--second-theme-color);
          box-shadow: 2px 2px 4px rgba(var(--second-theme-color-rgb), 0.5);
        }
        li:hover em {
          display: block;
        }
      }
      .el-form-item__error {
        top: unset;
        bottom: 5px;
      }
      ul.label {
        display: flex;
        flex-wrap: wrap;
        li {
          border: 1px solid #e4e4e4;
          width: 10%;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 26px;
          line-height: 24px;
          text-align: center;
          cursor: pointer;
          margin: 5px 1.25% 5px 0;
          font-size: 12px;
          overflow: hidden;
        }
        li:nth-child(9n) {
          margin-right: 0px;
        }
        li.on {
          border-color: var(--second-theme-color); // #39c
          color: var(--second-theme-color);
          background-color: #f8f8f8;
        }
      }

      .tags-box {
        .el-checkbox-group {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .el-checkbox-button {
            width: 10%;
            height: 26px;
            overflow: hidden;
            margin: 5px 1.25% 5px 0;
            border: 1px solid #dcdfe6;
            border-radius: 2px;
            &:nth-child(9n) {
              margin-right: 0px;
            }
            .el-checkbox-button__inner {
              width: 100%;
              height: 100%;
              border: 0px;
              border-radius: 0px;
              padding: 0px;
              line-height: 24px;
              display: block;
              color: #777;
            }
            &.is-checked {
              border-color: var(--second-theme-color); // #39c
              .el-checkbox-button__inner {
                background-color: #f8f8f8;
                color: var(--second-theme-color); // #39c
              }
            }
          }
        }
      }

      .el-date-editor {
        width: 100%;
        .el-range-input {
          width: 115px;
          text-align: left;
        }
        .el-range-separator {
          width: 8%;
          line-height: 40px;
          color: #ccc;
          padding-right: 10px;
        }
        .el-range__icon {
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }

      .ab-content {
        position: relative;
        line-height: 40px;
        .paperTypeList {
          margin-right: 100px;
          div {
            margin-right: 20px;
            em {
              margin-left: 5px;
              color: #999;
              cursor: pointer;
            }
          }
        }
        .isAbOpt {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: right;
          color: #999;
          cursor: pointer;
        }
      }
      .ab-option {
        background-color: #f2f2f2;
        padding: 5px 15px 10px 15px;
        border-radius: 2px;
        .abOption {
          .el-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          .el-checkbox {
            display: unset;
            width: 8%;
            margin-right: 2%;
            .el-checkbox__input {
              float: left;
              margin-top: 7px;
              margin-right: 5px;
            }
            .el-checkbox__label {
              padding-left: 5px;
            }
          }
          .addABBtn {
            display: flex;
            align-items: center;
            width: 8%;
            em {
              width: 100%;
              height: 24px;
              line-height: 22px;
              border: 1px solid #e4e4e4;
              background-color: #fff;
              color: #999;
              font-size: 16px;
              overflow: hidden;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }

      .mustFillSet {
        text-align: left;
        padding: 5px 15px 10px 15px;
        background-color: #f2f2f2;
        border-radius: 2px;
      }
    }

    .content {
      .el-textarea__inner {
        resize: none;
        min-height: 40px !important;
        padding-top: 9px;
      }
    }
    /*认证设置弹窗样式*/
    .verify-set {
      margin-top: -10px;
      .el-form-item__content {
        background-color: #f8f8f8;
        padding: 10px 20px;
        .hrVerify {
          margin: 10px 0;
        }
      }
      .anonymous {
        margin-top: -1px;
        & > span {
          vertical-align: middle;
        }
        & > em {
          font-size: 20px;
          vertical-align: middle;
          margin: 0 12px 0px 3px;
        }
        & > .el-switch {
          vertical-align: middle;
          height: 18px;
          line-height: 18px;
          .el-switch__core {
            height: 18px;
            border-radius: 9px;
            &:after {
              width: 14px;
              height: 14px;
            }
          }
          &.is-checked {
            .el-switch__core::after {
              margin-left: -15px;
            }
          }
        }
      }
      .verify-state {
        padding-left: 20px;
        color: #888;
        position: relative;
        em {
          position: absolute;
          left: 0px;
          top: 0px;
          line-height: 20px;
          font-size: 16px;
          display: none;
        }
        &.on {
          color: #39c;
          em {
            display: block;
          }
        }
      }
    }

    .monitor-set {
      margin-top: -10px;
      .el-form-item__content {
        background-color: #f8f8f8;
        padding: 10px 20px 17px 20px;
        .hrVerify {
          margin: 10px 0;
        }
      }
      .el-radio-group.item-font {
        width: 100%;
        display: block;
        .jkfs {
          display: block;
          text-align: left;
          width: 100%;
          .jk {
            .el-radio {
              line-height: 26px;
              vertical-align: middle;
              .el-radio__input {
                line-height: normal;
              }
            }

            .fz {
              vertical-align: middle;
              height: 26px;
              line-height: 26px;
              overflow: hidden;
              font-size: 14px;
              input.text {
                height: 26px;
                width: 50px;
                float: left;
                text-align: center;
                margin-left: 5px;
                margin-right: 5px;
              }
              .el-checkbox__label {
                padding-left: 5px;
              }
            }
            .fzbox {
              border: 1px solid #e4e4e4;
              padding: 10px 20px;
              .fz {
                margin-top: 5px;
                &:first-child {
                  margin-top: 0px;
                }
                .angle {
                  width: 80px;
                }
                .notes {
                  color: #ccc;
                }
              }
            }
          }
        }
      }
      .monitorBtn {
        text-align: left;
        margin-top: 15px;
        .el-button {
          padding: 0px;
          width: 65px;
          height: 26px;
          background-color: #fff;
        }
      }
    }

    .noborder {
      .el-form-item__content {
        border-bottom: 0;
      }
    }
    .btn-div.big {
      margin-left: 85px;
      .el-button {
        width: 160px;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
      }
    }
    .btn-div.small {
      .el-button {
        width: 60px;
        height: 24px;
        line-height: 22px;
        font-size: 12px;
      }
    }
  }
}

/deep/.ceritfication.cert-wrap {
  padding: 0px;
  .detail-box {
    border-radius: 0px;
    width: 100%;
    padding: 60px;
  }
}

/deep/ .mini-input {
  width: 70px;
  border: 1px solid #e4e4e4;
  margin: 0 5px;
  .el-input__inner {
    text-align: center;
  }
}

// 云掌门
.formRow {
  padding-top: 5px;
}
/deep/ .staffSelector .ss-select-box {
  border: none;
  // padding-left: 0;
}

.el-button.el-tooltip {
  min-width: 0;
  & > span {
    display: inline-block;
  }
}
</style>


<style lang="less">
.asktrue-tooltip {
  .el-button {
    min-width: 0;
    width: auto;
    height: auto;
    padding: 5px 8px;
  }
}
</style>
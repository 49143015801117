<template>
  <div class="cert-wrap">
    <div class="detail-box">
      <el-form
        label-width="80px"
        :model="approvalInfo"
        ref="approvalInfo"
        class="myAsktrueForm"
      >
        <el-form-item label="状态：" prop="value" v-if="cerStatus == 2">
          <el-input
            :value="
              applyStatus == 2
                ? '审核中'
                : applyStatus == 3
                ? '审核未通过'
                : applyStatus == 4
                ? '已撤回'
                : '审核通过'
            "
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="时间：" prop="value">
          <el-input v-model="approvalInfo.applyTime" readonly></el-input>
        </el-form-item>
        <el-form-item label="说明：" required>
          <el-input
            v-model="approvalInfo.applyExplain"
            placeholder="输入说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称：" required>
          <el-input
            v-model="approvalInfo.compName"
            placeholder="输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="属性：" prop="trade"
          ><!--trade|compType|compSize-->
          <el-cascader
            v-model="approvalInfo.trade"
            :options="tradeOption"
            :props="{ expandTrigger: 'hover', value: 'id', label: 'value' }"
            placeholder="行业"
          >
          </el-cascader>
          <el-divider direction="vertical"></el-divider>
          <el-select
            v-model="approvalInfo.compType"
            placeholder="性质"
            popper-class="center-el-select"
          >
            <el-option
              v-for="item in compTypeArr"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-divider direction="vertical"></el-divider>
          <el-select
            v-model="approvalInfo.compSize"
            placeholder="规模"
            popper-class="center-el-select"
          >
            <el-option
              v-for="item in compSizeArr"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址：" prop="city">
          <el-select
            v-model="approvalInfo.provinceId"
            placeholder="省市"
            @change="clickProvinceFn()"
            popper-class="center-el-select"
          >
            <el-option
              v-for="item in provinceOption"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-divider direction="vertical"></el-divider>

          <el-select
            v-model="approvalInfo.cityId"
            placeholder="城市"
            @change="clickCityFn()"
            popper-class="center-el-select"
          >
            <el-option
              v-for="item in cityOption"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>

          <el-divider direction="vertical"></el-divider>

          <el-select
            v-model="approvalInfo.districtId"
            placeholder="区县"
            @change="clickDistrictFn()"
            popper-class="center-el-select"
          >
            <el-option
              v-for="item in districtOption"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="address">
          <el-input
            v-model="approvalInfo.compAddr"
            placeholder="详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="资质">
          <el-upload
            :action="apiUrl1 + '/minIOApi/oss/putObjects?fileDirType=comp_img'"
            :before-upload="beforeUpload"
            :on-success="busiLicenceAddrUploaded"
            :headers="token"
            list-type="picture-card"
            :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview"
            :file-list="approvalInfo.busiLicenceAddr"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible"
            ><img width="100%" :src="dialogImageUrl" alt=""
          /></el-dialog>
        </el-form-item>
        <el-form-item label="联系人：" prop="recruitPerson" required>
          <el-input
            v-model="approvalInfo.recruitPerson"
            placeholder="输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话：" required>
          <el-input
            v-model="approvalInfo.phoneNo"
            placeholder="输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item class="noborder mt40" v-if="cerStatus == 1">
          <el-button
            type="primary"
            @click="submitForm('approvalInfo')"
            class="btnsave"
            style="width: 160px; height: 40px"
            >提交</el-button
          >
          <el-button
            type="info"
            class="btnnull ml30"
            style="width: 160px; height: 40px"
            @click="closePage()"
            >关闭</el-button
          >
        </el-form-item>
        <el-form-item label="审批：" v-if="cerStatus == 2">
          <ul class="appro">
            <li v-for="(item, index) in applyList" :key="index">
              <em class="iconfont iconduihao"></em>
              <div class="flex flex-space content">
                <div class="left flex column-center">
                  <img src="@/assets/image/asktrue/error/face.png" />
                  <div class="ml10">
                    <p>{{ item.userName }}</p>
                    <p>职位名称</p>
                  </div>
                </div>
                <div class="center">
                  {{
                    item.applyStatus == 1
                      ? "已提交"
                      : item.applyStatus == 2
                      ? "审核通过"
                      : item.applyStatus == 3
                      ? "审核未通过"
                      : "已撤回"
                  }}
                </div>
                <div class="right">
                  <span>--</span>
                  <span class="ml20 c9">{{ item.createTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </el-form-item>
        <div class="btn-div mt30 clearfix" v-if="cerStatus == 2">
          <el-button
            type="primary"
            class="fl"
            @click="editRzStatusFn(4)"
            v-if="applyStatus != 4"
            >撤回</el-button
          >
          <el-button type="primary" plain class="fl ml30" @click="cerStatus = 1"
            >修改</el-button
          >
          <!-- <el-button plain class="ml20 fr" @click="closePage()">关闭</el-button> -->
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getData } from "@/views/staff/js/data.js";
import {
  postRzInfoReq,
  getRzListReq,
  editRzStatusReq,
} from "@/api/asktrue/user/user";
import { apiUrl1 } from "@/api/asktrue/config";
import { getCompanyInfo } from "@/api/asktrue/user/cUser";

export default {
  name: "companyView",
  data() {
    return {
      tradeOption: getData("tradeList"), //行业选项
      compTypeArr: getData("compTypeVar"), //企业性质数组
      compSizeArr: getData("compSizeVar"), //规模数组
      provinceOption: getData("provinceVar"), //省-选项
      districtOption: [], //区-选项
      cityOption: [], //市-选项
      approvalInfo: {
        applyTime: this.$utils.asktrue.MillisecondToDate(new Date()),
        applyExplain: "",
        compName: "",
        phoneNo: "",
        recruitPerson: "",
        frontCard: "",
        trade: [], //行业
        compFieldId: "", //所属领域id，逗号分割
        compType: null, //企业性质值
        compSize: "", //规模值
        provinceId: "", //省-值
        cityId: "", //市-值
        districtId: "", //区-值
        compAddr: "", //具体地址
        busiLicenceAddr: [],
        picArr: [],
      },
      dialogImageUrl: "", //资质图片
      dialogVisible: false,
      cerStatus: 1, //认证状态
      applyStatus: 1, //审核状态
      applyList: [], //审核记录

      token: {},
      apiUrl1: apiUrl1,
      getted: false,
      userInfo: {},
    };
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    this.getRzListFn();
  },
  activated() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    this.getRzListFn();
  },
  methods: {
    // 获取详情
    async getInfo() {
      getCompanyInfo().then((res) => {
        if (res.code == "200") {
          this.userInfo = res.data.result;
          // if(this.userInfo.busiLicenceAddr == '' || this.userInfo.busiLicenceAddr == null || this.userInfo.busiLicenceAddr == '[]') {
          //   this.userInfo.busiLicenceAddr = [];
          // } else {
          //   let strarr = this.userInfo.busiLicenceAddr.match(/(http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g);
          //   let arr=[];
          //   strarr.forEach(ele => {
          //     arr.push({url:ele});
          //   })
          //   this.userInfo.busiLicenceAddr = arr;
          // }
          // this.getAddrOption();
          // this.getChoosedfiled()
        }
      });
    },
    //获取认证列表
    async getRzListFn() {
      await getCompanyInfo().then((res) => {
        if (res.code == "200") {
          this.userInfo = res.data.result;
        }
      });
      await getRzListReq().then((res) => {
        if (res.code == "200") {
          if (res.data.result[0]) {
            this.approvalInfo = res.data.result[0];
            this.cerStatus = 2;
          } else {
            this.approvalInfo = this.userInfo;
          }
          this.applyStatus = this.userInfo.applyStatus;
          this.applyList = this.userInfo.applyList || [];
          this.approvalInfo.applyTime = this.userInfo.updateTime;
          this.approvalInfo.trade = this.userInfo.compFieldId?.split(",");
          this.approvalInfo.trade = this.approvalInfo.trade?.map(Number);
          this.approvalInfo.compType = Number(this.approvalInfo.compType) || "";
          this.approvalInfo.compSize = Number(this.approvalInfo.compSize) || "";
          this.approvalInfo.cityId = Number(this.approvalInfo.cityId) || "";
          this.approvalInfo.districtId =
            Number(this.approvalInfo.districtId) || "";
          this.clickProvinceFn();
          this.clickCityFn();
          this.approvalInfo.busiLicenceAddr = this.userInfo.businessLicensePic
            ? JSON.parse(this.userInfo.businessLicensePic)
            : [];
        }
      });
    },
    //选择省
    clickProvinceFn() {
      this.approvalInfo.city = ""; //清空市
      this.approvalInfo.district = ""; //清空区
      this.districtOption = []; //清空区-选项

      let cityData = getData("cityList"); //查询全部市
      this.cityOption = cityData.filter(
        (item) => item.pid == this.approvalInfo.provinceId
      );
    },
    //选择市
    clickCityFn() {
      if (this.approvalInfo.cityId) {
        this.approvalInfo.district = ""; //清空区
        let districtData = getData("districtList"); //查询全部辖区
        this.districtOption = districtData.filter(
          (item) => item.pid == this.approvalInfo.cityId
        ); //重新赋值区

        let cityVal =
          this.cityOption[
            this.cityOption.findIndex(
              (item) => item.id == this.approvalInfo.cityId
            )
          ].value;
        this.approvalInfo.address = cityVal;
      }
    },
    //选择区
    clickDistrictFn() {
      let cityVal =
        this.cityOption[
          this.cityOption.findIndex(
            (item) => item.id == this.approvalInfo.cityId
          )
        ].value;
      let districtVal =
        this.districtOption[
          this.districtOption.findIndex(
            (item) => item.id == this.approvalInfo.districtId
          )
        ].value;
      this.approvalInfo.address = cityVal + districtVal;
    },
    // 上传前操作
    beforeUpload(file) {
      let isType =
        file.type === "image/jpeg" ||
        file.type === "image/x-png" ||
        file.type === "image/pjpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("请上传png、jpg或jpeg格式图片!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isType && isLt2M;
    },
    //资质图片-删除
    handleRemove(file) {
      let i = this.approvalInfo.busiLicenceAddr.findIndex(
        (ele) => ele.url == file.url
      );
      this.approvalInfo.busiLicenceAddr.splice(i, 1);
    },
    //资质图片-放大预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    busiLicenceAddrUploaded(res, file) {
      if (res.code == "200") {
        this.approvalInfo.busiLicenceAddr.push({
          name: file.name,
          url: res.data.result,
        });
      }
    },
    //表单保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approvalInfo.compFieldId = this.approvalInfo.trade.toString();
          this.approvalInfo.businessLicensePic = JSON.stringify(
            this.approvalInfo.busiLicenceAddr
          );
          this.approvalInfo.picArr = ""; //JSON.stringify(this.approvalInfo.picArr)
          this.approvalInfo.trade = JSON.stringify(this.approvalInfo.trade);
          // this.approvalInfo.applyTime = this.approvalInfo.applyTime
          this.approvalInfo.compType
            ? (this.approvalInfo.compType = Number(this.approvalInfo.compType))
            : (this.approvalInfo.compType = null);
          this.approvalInfo.compSize
            ? (this.approvalInfo.compSize = Number(this.approvalInfo.compSize))
            : (this.approvalInfo.compSize = null);
          this.approvalInfo.provinceId
            ? (this.approvalInfo.provinceId = Number(
                this.approvalInfo.provinceId
              ))
            : (this.approvalInfo.provinceId = null);
          // let a = false;
          // if(a){
          postRzInfoReq(this.approvalInfo).then((res) => {
            if (res.code == "200") {
              this.getRzListFn();
              this.cerStatus = 2;
              if (this.applyStatus == 4) {
                this.editRzStatusFn(2);
              } else {
                this.$message.success("提交成功");
              }
              if (this.from == "xq") {
                this.$emit("close");
              }
            }
          });
          // }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //更改认证状态
    editRzStatusFn(applyStatus) {
      let params = {
        applyStatus: applyStatus,
        attestationId: this.approvalInfo.attestationId,
      };
      if (applyStatus == 2) {
        params.applyDesc = "修改认证";
      } else if (applyStatus == 4) {
        params.applyDesc = "撤回认证";
      }
      editRzStatusReq(params).then((res) => {
        if (res.code == "200") {
          this.$message.success("操作成功");
          this.getRzListFn();
        }
      });
    },
    //关闭
    closePage() {
      if (this.from != "xq") {
        this.$router.go(-1);
      } else {
        this.$emit("close");
      }
    },
  },
  props: {
    from: String,
    status: Number,
  },
};
</script>

<style lang="less" scoped>
.cert-wrap {
  background-color: #ebedf1;
  padding: 50px 0;
  .detail-box {
    padding: 30px 40px;
    width: 70%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
  }
  /deep/ .el-form.myAsktrueForm {
    .el-form-item {
      margin-bottom: 12px;
      .el-form-item__content {
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 12px;
        text-align: left;
        input,
        select {
          font-size: 14px;
        }
        .el-select {
          width: 180px;
        }
        .el-cascader {
          width: 180px;
          line-height: 32px;
        }
        .el-textarea .el-textarea__inner {
          padding: 5px 5px !important;
          min-height: 32px !important;
          display: block !important;
          border: 0px !important;
          resize: none;
        }
        .el-input__icon {
          line-height: 32px;
        }
        .el-form-item__error {
          margin-left: 5px;
          border: 0px solid #e4e4e4;
          position: absolute;
          top: 27px;
          left: 0px;
        }
        .el-upload--picture-card {
          background-color: #fbfdff;
          border: 1px dashed #e4e4e4;
          border-radius: 2px;
          margin: 8px 8px 8px 0;
        }
        .el-upload-list--picture-card .el-upload-list__item {
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          margin: 8px 8px 8px 0;
        }
        .el-upload-list--picture-card .el-upload-list__item > div {
          width: 100%;
          height: 100%;
        }
        .el-upload-list--picture-card .el-upload-list__item-thumbnail {
          object-fit: contain;
        }
        .el-input__inner {
          border: 0px;
        }
      }
      .el-divider--vertical {
        margin: 0 20px;
      }
      .el-color-picker {
        height: 30px;
      }
      .el-color-picker__trigger {
        width: 30px;
        height: 30px;
      }
      ul.card {
        li {
          text-align: center;
          line-height: 24px;
          color: #666;
          img {
            border: 1px solid#e4e4e4;
            width: 110px;
          }
        }
      }
      ul.appro:before {
        content: "";
        border-left: 1px solid#e4e4e4;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
      ul.appro {
        position: relative;
        li {
          display: flex;
          line-height: 24px;
          margin-bottom: 10px;
          position: relative;
          .content {
            margin-left: 25px;
            width: 100%;
            background-color: #f5f5f5;
            font-size: 12px;
            padding: 5px 10px;
            align-items: center;
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }
          em {
            border-radius: 50%;
            width: 18px;
            height: 18px;
            line-height: 18px;
            background: #fff;
            z-index: 1;
            position: absolute;
            left: -8px;
            top: 12px;
            text-align: center;
          }
          em.iconduihao {
            border: 1px solid#41c352;
            color: #41c352;
            font-size: 12px;
          }
          em.iconcuo {
            border: 1px solid#fd6374;
            color: #fd6374;
            font-size: 12px;
          }
        }
        li:last-child {
          margin-bottom: 0;
        }
      }
    }
    .noborder.el-form-item {
      .el-form-item__content {
        border-bottom: 0px solid #e4e4e4;
      }
    }
  }
}
</style>

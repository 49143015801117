import LayoutKs from "@/views/staff/exam/detail/MainView.vue";
export default {
  parent: "AsktrueProject",
  routers: [
    // 考试测评
    {
      path: "exam",
      component: LayoutKs,
      name: "examExam",
      redirect: "/project/exam/evaluate",
    },
    {
      //问卷-详情
      path: "/Project/QuesNairDetail",
      name: "cQuesNairDetail",
      component: (resolve) =>
        require([
          "@/views/staff/components/asktrue/questionnaire/quesnairDetail",
        ], resolve),
      meta: {
        auth: true,
      },
    },
  ],
};

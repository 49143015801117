export default function (axios) {
  return {
    // //客户
    khSelectPageReq(params) {
      return axios.post("/headhunter/recruiter/selectPage", params);
    },

    khSelectInvitedC(data) {
      return axios.post("/headhunter/recruiter/selectInvitedC", data, {
        ContentType: "application/json",
      });
    }, //邀请分页查询
    khSelectApprovedC(data) {
      return axios.post("/headhunter/recruiter/selectApprovedC", data, {
        ContentType: "application/json",
      });
    }, //审批分页查询
    khSaveVirtualCustomerReq(params) {
      return axios.post("/headhunter/recruiter/saveVirtualCustomer", params);
    },
    KhResumCooperationReq(params) {
      return axios.post(
        "/headhunter/recruiter/resumCooperation?compId=" + params.compId,
        params
      );
    },
    KhSelectCompNameReq(data) {
      return axios.post("/headhunter/recruiter/selectCompName", data, {
        ContentType: "application/json",
      });
    }, //模糊查询会员公司名称
    selectJobPageByCompReq(data) {
      return axios.post("/headhunter/recruiter/selectJobPageByComp", data, {
        ContentType: "application/json",
      });
    }, //猎头新增职位
    getCustomerReq(params) {
      return axios.post(
        "/headhunter/recruiter/getCustomer?compId=" + params.compId
      );
    },
    deleteCustomerReq(params) {
      return axios.post(
        "/headhunter/recruiter/deleteCustomer?customerId=" + params.customerId
      );
    },
    // // 客户公司统计 /headhunter/recruiter/getCount
    getRecruiterCountReq() {
      return axios.get("/headhunter/recruiter/getCount");
    },

    selectEntrustResumeForPersonReq(data) {
      return axios.post("/entrustCtl/selectEntrustResumeForPerson", data, {
        ContentType: "application/json",
      });
    }, //猎头->委托页面列表数据
    selectPHeadhunterCtlReq(data) {
      return axios.post("/pHeadhunterCtl/selectPage", data, {
        ContentType: "application/json",
      });
    }, //猎头->关注页面列表数据
    cancelAttentionReq(data) {
      return axios.post("/pHeadhunterCtl/cancelAttention", data);
    }, //猎头->关注页面取消关注
    HRselectPageAllReq(data) {
      return axios.post("/headhunter/recruiter/selectPageAll", data, {
        ContentType: "application/json",
      });
    }, //猎头->搜索页面列表数据
    activatInvitationReq(data) {
      return axios.post("/headhunter/recruiter/activatInvitation", data, {
        ContentType: "application/json",
      });
    }, //猎头->激活邀请
    hhSelectAllConditionReq(params) {
      return axios.get("/recruiter/headhunter/selectAllCondition", { params });
    }, //猎头/客户搜索栏目筛选条件
    selectCompHhConditionReq(params) {
      return axios.get("/headhunter/recruiter/selectCompHhCondition", {
        params,
      });
    },
    //职位、客户详情>设置
    selectDeptAndStaffReq(params) {
      return axios.get("/headhunter/member/selectDeptAndStaff", { params });
    }, //部门员工查询
    selectMemberReq(data) {
      return axios.post("/headhunter/member/selectMember", data, {
        ContentType: "application/json",
      });
    }, //部门员工查询
    addMemberReq(data) {
      return axios.post("/headhunter/member/addMember", data, {
        ContentType: "application/json",
      });
    }, //新增成员
    removeMemberReq(data) {
      return axios.post("/headhunter/member/removeMember", data, {
        ContentType: "application/json",
      });
    }, //移除成员
    updateMemberReq(data) {
      return axios.post("/headhunter/member/updateMember", data, {
        ContentType: "application/json",
      });
    }, //修改管理员状态
    removeCusMemberReq(data) {
      return axios.post("/headhunter/member/removeCusMember", data, {
        ContentType: "application/json",
      });
    }, //移除客户管理员

    //新增记录
    addMemoReq(data) {
      return axios.post("/headhunter/memo/addMemo", data, {
        ContentType: "application/json",
      });
    },
    addMemoReq(data) {
      return axios.post("/headhunter/memo/addMemo", data, {
        ContentType: "application/json",
      });
    },

    //查询备忘记录
    selectMemoReq(data) {
      return axios.post("/headhunter/memo/selectMemo", data, {
        ContentType: "application/json",
      });
    },
    selectMemoReq(data) {
      return axios.post("/headhunter/memo/selectMemo", data, {
        ContentType: "application/json",
      });
    },

    //猎头详情
    getHhCompReq(data) {
      return axios.post(
        "/recruiter/headhunter/getHhComp?compId=" + data.compId,
        data,
        { ContentType: "application/json" }
      );
    },
    getHhCompReq(data) {
      return axios.post(
        "/recruiter/headhunter/getHhComp?compId=" + data.compId,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    getInAuditCountByCusReq(data) {
      return axios.post(
        "/headhunter/recruiter/getInAuditCountByCus?customerId=" +
          data.customerId,
        data,
        { ContentType: "application/json" }
      );
    }, //根据客户统计内审职位数量

    saveCustomerReq(data) {
      let url ="/headhunter/recruiter/saveCustomer";
      return axios.post(url,data,{ContentType: "application/json",});
    },

  };
}

import { Message } from "element-ui";
import crypto from "crypto";
import QRCode from "qrcodejs2";
export default {
  apiUrl: "//api.matcheasy.cn",
  //计算年龄/日期
  getAgeFn(str, type) {
    if (!str) {
      return 0;
    }
    let value = "";
    if (type == 1) {
      //计算年龄
      //出生时间 毫秒
      let birthDayTime = new Date(str).getTime();
      //当前时间 毫秒
      let nowTime = new Date().getTime();
      //一年毫秒数(365 * 86400000 = 31536000000)
      value = Math.ceil((nowTime - birthDayTime) / 31536000000);
    } else {
      //获取日期
      let myDate = new Date(str);
      let year = myDate.getFullYear(); //获取当前年
      let mon =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //获取当前月
      value = year + "-" + mon;
    }

    return value;
  },
  //简历附件上传
  // resumeFileUplodeReq(data, type) {
  // let url =
  //   "/file/upload" + (type == 1 ? "Cover" : "") + "?fileDirType=resume_file";
  // return uplodeReq(apiUrl + url, data);
  // },
  //弹窗提示
  voit(val, type) {
    Message.closeAll();
    Message({
      message: val || "功能开发中，敬请期待",
      type: type ? type : "warning",
    });
  },
  //时间戳转换
  MillisecondToDate(value, format, connect) {
    if (!value) {
      return false;
    }
    let date;
    if (typeof value == "string" && value.constructor == String) {
      date = new Date(value.replace(/-/g, "/"));
    } else {
      date = new Date(value);
    }
    let y = date.getFullYear(),
      m = date.getMonth() + 1,
      d = date.getDate(),
      h = date.getHours(),
      i = date.getMinutes(),
      s = date.getSeconds();
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    if (h < 10) {
      h = "0" + h;
    }
    if (i < 10) {
      i = "0" + i;
    }
    if (s < 10) {
      s = "0" + s;
    }
    let t = value ? y + "-" + m + "-" + d + " " + h + ":" + i : "--";
    if (format) {
      t = value ? y + "-" + m + "-" + d : "--";
    }
    if (connect && connect.length) {
      t = value ? y + connect[0] + m + connect[1] + d + connect[2] : "--";
    }
    return t;
  },
  //md5加密
  setMd(word) {
    console.log(crypto, "crypto");
    var md5 = crypto.createHash("md5");

    md5.update(word); //需要加密的密码
    return md5.digest("hex");
  },
  //数组去重,arr为数组，name为不可重复参数名
  unique(arr, name) {
    // 如果新数组的当前元素的索引值 == 该元素在原始数组中的第一个索引，则返回当前元素
    return arr.filter(function (x, index) {
      let arrids = [];
      arr.forEach((item) => {
        arrids.push(item[name]);
      });
      return arrids.indexOf(x[name]) === index;
    });
  },
  //get导出  原方法 download
  matchDownload(url, name, type, method) {
    console.log(url, name, type, "params=====");
    var xhr = new XMLHttpRequest();
    xhr.open(method ? method : "GET", url, true);
    //xhr.setRequestHeader("Content-Type","application/json");
    xhr.responseType = "blob"; // 返回类型blob
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function (res) {
      // 请求完成
      console.log(this.status, "this.status");
      if (this.status === 200) {
        // 返回200
        var blob = this.response;
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        console.log(res, blob, href, "导出");
        // if(blob.type=="application/json" || blob.size==0){
        if (blob.size == 0) {
          Message({
            message: "导出失败",
            type: "error",
          });
          return;
        }
        if (blob.type == "application/json") {
          blob = new File([JSON.stringify(blob)], ".json", {
            type: "application/json",
            lastModified: Date.now(),
          });
        }
        // }
        console.log(type, "type=");
        var date = new Date(); //实例一个时间对象；
        var y = date.getFullYear(); //获取系统的年；
        var M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1; //获取系统月份，由于月份是从0开始计算，所以要加1
        var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取系统日
        var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取系统时间
        var mm =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
        var ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
        name = name + "_" + y + M + d + h + mm + ss;

        //判断是否是IE浏览器，是的话返回true
        if (window.navigator.msSaveBlob) {
          try {
            window.navigator.msSaveBlob(blob, name + type);
          } catch (e) {
            console.log(e, "eee");
          }
        } else {
          // 谷歌浏览器 创建a标签 添加download属性下载
          var downloadElement = document.createElement("a");
          downloadElement.href = href;
          downloadElement.target = "_blank";
          downloadElement.download = name + type; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      } else {
        if (this.status == 404) {
          Message({ message: "导出失败,文件未找到", type: "error" });
        } else {
          Message({ message: "导出失败", type: "error" });
        }
        return;
      }
    };
    // 发送ajax请求
    xhr.send();
  },
  //post导出文件,params是post请求需要的参数，url是请求url地址
  postExcelFile(params, url) {
    var form = document.createElement("form");
    form.style.display = "none";
    form.action = url;
    form.method = "post";
    document.body.appendChild(form);

    for (var key in params) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = params[key];
      form.appendChild(input);
    }

    form.submit();
    form.remove();
  },
  // post导出文件
  postdownload(params, url, token) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.setRequestHeader("access_token", token); // 将 token 拼接到 access_token 请求头中
    // 将参数对象转换为 JSON 字符串
    var jsonData = JSON.stringify(params);
    xhr.responseType = "blob"; // 返回类型blob

    xhr.onload = function (res) {
      if (this.status === 200) {
        // 请求成功的处理逻辑
        // console.log(xhr.responseText);
        var blob = this.response;
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        if (blob.size == 0) {
          Message({
            message: "导出失败",
            type: "error",
          });
          return;
        }
        window.open(href);
        console.log(res, blob, href, "导出");
      } else {
        if (this.status == 404) {
          Message({ message: "导出失败,文件未找到", type: "error" });
        } else {
          Message({ message: "导出失败", type: "error" });
        }
        return;
      }
    };

    xhr.send(jsonData);
  },
  //生成二维码
  creatQrFn(url, id, size) {
    new QRCode(id, {
      width: size, // 设置宽度，单位像素
      height: size, // 设置高度，单位像素
      text: "" + url, // 设置二维码内容或跳转地址
    });
  },
  //下载生成的二维码
  downLoadQrFn(id) {
    let myCanvas = document.getElementById(id).getElementsByTagName("canvas");
    let a = document.createElement("a");
    a.href = myCanvas[0].toDataURL("image/png");
    a.download = "二维码";
    a.click();
  },
  matchGetDay(val) {
    let timestr = val;
    let month = timestr.getMonth() + 1;
    let date = timestr.getDate();
    let timeString = [
      timestr.getFullYear(),
      month,
      date,
      timestr.toString().split(" ")[0],
    ];
    return timeString;
  },
  //将base64转换为file类型
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  //文本域内容在div中显示时处理换行
  textToDiv(content) {
    return content ? content.replace(/\n/g, "<br>") : "";
  },
  //设置附件选择格式范围
  fileType(type) {
    if (type == 1) {
      //图片
      //return 'image/jpeg,image/png,image/gif';
      return ".jpg,.png,.gif";
    } else if (type == 2) {
      //文档
      //return 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/ppt, application/pdf';
      return ".doc,docx,.xls,.xlsx,.pdf,.ppt,.pptx";
    } else if (type == 3) {
      //压缩包
      return ".zip,.rar,.7z";
    } else if (type == 4) {
      //视频
      //return 'video/*';
      return ".ogm,.wmv,.avi,.m4v,.mpg,.mpeg,.mp4";
    } else if (type == 5) {
      //音频
      //return 'audio/*';
      return ".mp3,.wma,.m4a,.ogg,.wav";
    } else {
      return "*";
    }
  },
  //通知：附件字符串处理，返回对应数据
  temInfo(item, type) {
    let info = "";
    let arr = [];
    let l = 0;
    if (type == "name") {
      //返回附件名称
      arr = item.split("/");
      l = arr.length;
      info = arr[l - 1];
    } else if (type == "format") {
      //返回格式
      arr = item.split(".");
      l = arr.length;
      info = arr[l - 1];
    } else if (type == "err") {
      //返回格式对应的error图片
      arr = item.split(".");
      l = arr.length;
      switch (arr[l - 1].toLowerCase()) {
        case "docx":
        case "doc":
          info = require("@/views/recruit/assets/public/office/word.png");
          break;
        case "xlsx":
        case "xls":
          info = require("@/views/recruit/assets/public/office/excel.png");
          break;
        case "jpg":
        case "jpeg":
        case "gif":
        case "png":
          info = require("@/views/recruit/assets/public/office/jpg.png");
          break;
        case "pdf":
          info = require("@/views/recruit/assets/public/office/pdf.png");
          break;
        case "pptx":
        case "ppt":
          info = require("@/views/recruit/assets/public/office/ppt.png");
          break;
        case "txt":
          info = require("@/views/recruit/assets/public/office/txt.png");
          break;
        case "rar":
        case "zip":
        case "7z":
        case "arj":
          info = require("@/views/recruit/assets/public/office/rar.png");
          break;
        case "avi":
        case "mov":
        case "qt":
        case "asf":
        case "rm":
        case "navi":
        case "mp4":
          info = require("@/views/recruit/assets/public/office/video.png");
          break;
        case "mp3":
        case "mpeg":
        case "wma":
        case "ra":
        case "rmx":
          info = require("@/views/recruit/assets/public/office/music.png");
          break;
        case "html":
        case "htm":
        case "java":
        case "jsp":
        case "php":
        case "css":
        case "js":
        case "asp":
        case "vue":
        case "xml":
          info = require("@/views/recruit/assets/public/office/code.png");
          break;
        default:
          info = require("@/views/recruit/assets/public/office/no.png");
          break;
      }
    } else if (type == "img") {
      //返回正常图片
      arr = item.split(".");
      l = arr.length;
      if (
        arr[l - 1].toLowerCase() == "jpg" ||
        arr[l - 1].toLowerCase() == "jpeg" ||
        arr[l - 1].toLowerCase() == "gif" ||
        arr[l - 1].toLowerCase() == "png"
      ) {
        info = item;
      } else {
        info = ""; //后续开发文档转图片
      }
    }
    return info;
  },
};

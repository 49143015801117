<!--
注解：
必填项表单组件
请千万注意：本组件仅用于项目编辑中的必填项，而非通知里面的自定义表单。
-->
<template>
  <div class="FreeForm no-select">
    <!--表单-->
    <el-form :model="form" ref="form" class="form">
      <div
        :class="[item.type, 'form-line']"
        v-for="(item, index) in formData.filter((el) => el.status == 1)"
        :key="index"
      >
        <!--公共-->
        <div class="icoFun" v-if="item.isPrev">
          <em
            class="iconfont iconupon"
            title="上移"
            @click="upTr(index)"
            v-if="index > 0"
          ></em>
          <em
            class="iconfont iconxiayi"
            title="下移"
            @click="downTr(index)"
            v-if="index < formData.length - 1"
          ></em>
          <em
            class="iconfont iconbianji"
            title="编辑"
            @click="getEditFn(item)"
          ></em>
          <em
            class="iconfont iconshanchu"
            title="删除"
            @click="item.status = 2"
          ></em>
        </div>

        <!--文本 textarea--------------------------------------------------------------------->
        <div class="form-box" v-if="item.type == 'textarea'">
          <!--预览-->
          <div class="prev" v-if="item.isPrev">
            <el-form-item :label="item.title || '标题名称'">
              <!-- <el-input
                type="textarea"
                :autosize="{ minRows: item.line }"
                readonly
                v-model="form.value"
                :placeholder="item.placeholder"
              ></el-input> -->
              <!--此处的自定义表单禁止控制宽度 :style="'width:'+item.width+';'"-->
              <el-input
                readonly
                v-model="form.value"
                :placeholder="item.placeholder"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </div>
          <!--编辑-->
          <div class="edit" v-else>
            <el-form-item>
              <div class="clearfix">
                <el-input
                  type="textarea"
                  v-model="form.tempTitle"
                  placeholder="输入标题"
                  class="fl"
                ></el-input>
              </div>
              <div class="clearfix mt10">
                <el-input
                  type="textarea"
                  class="small fl"
                  placeholder="填写提示"
                  v-model="form.tempPlaceholder"
                ></el-input>
              </div>
              <div class="mt10 clearfix">
                <el-button
                  size="mini"
                  type="primary"
                  class="mr15"
                  @click="getSaveFn(item)"
                  >保存</el-button
                >
                <el-button size="mini" @click="getHideFn(item)">关闭</el-button>
              </div>
            </el-form-item>
          </div>
        </div>

        <!--下拉框 select--------------------------------------------------------------------->
        <div class="form-box" v-if="item.type == 'select'">
          <!--预览-->
          <div class="prev" v-if="item.isPrev">
            <el-form-item :label="item.title || '标题名称'">
              <div class="select-wrap">
                <el-select
                  v-model="item.value[i - 1].answer"
                  :placeholder="i == 1 ? item.placeholder : item.placeholder2"
                  v-for="i in item.level || 1"
                  :key="i"
                  style="width: 220px"
                  popper-class="center-el-select"
                >
                  <el-option
                    v-for="(opt, ind) in item.option"
                    :key="ind"
                    :label="opt.label"
                    :value="opt.label"
                    v-show="
                      (!opt.id || String(opt.id).split('-').length == i) &&
                      opt.status == 1
                    "
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <!--编辑-->
          <div class="edit" v-else>
            <el-form-item>
              <div class="clearfix">
                <el-input
                  type="textarea"
                  v-model="form.tempTitle"
                  placeholder="输入标题"
                  class="fl"
                ></el-input>
              </div>
              <div class="clearfix mt10">
                <el-checkbox
                  v-model="form.isSys"
                  @change="(e) => isSysFn(e, item)"
                  v-show="
                    item.infoTypeId == 7 ||
                    item.infoTypeId == 8 ||
                    item.infoTypeId == 9 ||
                    item.infoTypeId == 12 ||
                    item.infoTypeId == 14
                  "
                  >启用系统数据</el-checkbox
                >
              </div>
              <div class="clearfix mt10 select-wrap">
                <!-- <el-input type="textarea" :autosize="{minRows:2}" v-model="form.tempOption" placeholder="输入选项信息，多个选项之间用“回车换行”进行分隔" class="fl" ></el-input> -->
                <div class="selectSet" v-for="i in item.level || 1" :key="i">
                  <div class="ss-tit" v-if="i == 1">
                    <el-input
                      v-model="form.tempPlaceholder"
                      placeholder="输入下拉提示"
                    ></el-input>
                    <em
                      class="el-icon-circle-plus"
                      @click="optAddFn(i, item)"
                      v-show="!form.isSys"
                    ></em>
                  </div>
                  <div class="ss-tit" v-if="i == 2">
                    <el-input
                      v-model="form.tempPlaceholder2"
                      placeholder="输入下拉提示"
                    ></el-input>
                    <em
                      class="el-icon-circle-plus"
                      @click="optAddFn(i, item)"
                      v-show="!form.isSys"
                    ></em>
                  </div>
                  <ul class="ss-opt">
                    <li
                      v-for="(o, e) in form.tempOption.filter(
                        (el) => el.status == 1
                      )"
                      :key="e"
                      :class="[o.state ? 'on' : '', 'pr']"
                      v-show="
                        !o.id ||
                        (String(o.id).split('-').length == i && !o.notShow)
                      "
                      @click="selOption(i, o)"
                    >
                      <el-input
                        v-model="o.label"
                        placeholder="输入菜单项"
                        :readonly="form.isSys"
                      ></el-input>
                      <em
                        class="el-icon-error"
                        @click="o.status = 2"
                        v-show="!form.isSys"
                      ></em>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mt10 clearfix">
                <el-button
                  size="mini"
                  type="primary"
                  class="mr15"
                  @click="getSaveFn(item)"
                  >保存</el-button
                >
                <el-button size="mini" @click="getHideFn(item)">关闭</el-button>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { selectParams } from "@/api/asktrue/project/commodity";
import { getData } from "@/views/staff/js/data.js";
export default {
  data() {
    return {
      form: {
        tempTitle: "", //临时标题
        tempPlaceholder: "", //临时提示
        tempPlaceholder2: "",
        isSys: true, //启用系统数据
        tempOption: [], //临时选项,必须为数组
      },
    };
  },
  created() {},
  watch: {},
  methods: {
    //回传表单数据
    getFormDataFn() {
      this.$emit("backFormData", this.formData);
      this.$emit("backTitle", this.title);
    },

    //弹窗传参
    closePopover(item) {
      this.hzState = Number(item.stateHz) == 0 ? false : true;
      this.$emit("changePop", item.id);
    },
    //新增对象
    getAddFn(t) {
      for (var i = 0; i < this.formData.length; i++) {
        //每次只能编辑一个
        this.getHideFn(this.formData[i]);
        if (!this.formData[i].title) {
          this.formData[i].status = 2;
        }
      }
      let obj = {
        isPrev: false,
        type: t,
        title: "",
        placeholder: "",
        option: [],
        status: 1,
      };
      if (t == "select") {
        obj = {
          isPrev: false,
          type: t,
          title: "",
          placeholder: "",
          value: [{ answer: "" }],
          option: [
            { label: "", status: 1, id: 1 },
            { label: "", status: 1, id: 2 },
          ],
          status: 1,
        };
        this.getEditFn(obj);
      }
      this.formData.push(obj);
      this.$emit("isAddBtn", false);
      this.$emit("toBottom");
    },
    //上移
    upTr(index) {
      if (index === 0) {
        return;
      }
      this.swapItems(this.formData, index, index - 1);
    },
    //下移
    downTr(index) {
      if (index === this.formData.length - 1) {
        return;
      }
      this.swapItems(this.formData, index, index + 1);
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    //编辑对象
    getEditFn(item) {
      for (var i = 0; i < this.formData.length; i++) {
        //每次只能编辑一个
        this.formData[i].isPrev = true;
      }
      this.form.tempTitle = item.title; //临时标题
      this.form.tempPlaceholder = item.placeholder; //临时提示
      this.form.tempPlaceholder2 = item.placeholder2; //临时提示
      this.form.isSys = item.isSys;
      if (item.option != undefined) {
        this.form.tempOption = JSON.parse(JSON.stringify(item.option)); //临时选项
      }
      item.isPrev = false;
    },
    //启用系统数据
    isSysFn(isSys, item) {
      this.form.tempOption = [];
      if (isSys) {
        let ts = [
          { type: "tSchool", infoTypeId: 8 },
          { type: "tMajor", infoTypeId: 7 },
          { type: "tTrade", infoTypeId: 14 },
          { type: "tCity", infoTypeId: 9 },
          { type: "tJobKind", infoTypeId: 12 },
        ];
        let parameterType = ts.find(
          (ele) => ele.infoTypeId == item.infoTypeId
        ).type;
        if (parameterType == "tCity") {
          let proArr = JSON.parse(
            JSON.stringify(getData("provinceVar")).replace(/value/g, "label")
          );
          let cityArr = JSON.parse(
            JSON.stringify(getData("cityList")).replace(/value/g, "label")
          );
          this.form.tempOption = proArr.concat(cityArr);
          this.form.tempOption.forEach((ele) => {
            let selId = String(ele.id);
            ele.id =
              selId.length / 2 == 2
                ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
                : selId.length / 2 == 3
                ? selId.substring(0, 2) +
                  "-" +
                  selId.substring(2, 4) +
                  "-" +
                  selId.substring(4, 6)
                : selId;
            ele.status = 1;
          });
        } else {
          this.getOption(parameterType);
        }
      } else {
        this.form.tempOption = [
          { id: 1, status: 1, label: "" },
          { id: 2, status: 1, label: "" },
        ];
      }
    },
    //获取参数选项-学历、院校等
    getOption(item) {
      if (!item) {
        return false;
      }
      selectParams({ parameterType: item }).then((res) => {
        if (res.code == "200") {
          let selectArr = res.data.result || [];
          this.form.tempOption = [];
          selectArr.forEach((ele) => {
            let valId = item == "tSchool" ? ele.city_id || "0000" : ele.value;
            let obj = {
              label: ele.label,
              id: valId,
              state: false,
              isShow: true,
              status: 1,
            };
            let selId = String(obj.id);
            if (selId.length < 2) {
              return false;
            }
            obj.id =
              String(valId).length / 2 == 2
                ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
                : String(valId).length / 2 == 3
                ? selId.substring(0, 2) +
                  "-" +
                  selId.substring(2, 4) +
                  "-" +
                  selId.substring(4, 6)
                : selId;
            if (item == "tSchool") {
              //院校
              obj.id = obj.id + ele.value;
            }
            this.form.tempOption.push(obj);
          });
          if (item == "tSchool") {
            //院校
            this.form.tempOption = this.form.tempOption.concat(
              JSON.parse(
                JSON.stringify(getData("provinceVar")).replace(
                  /value/g,
                  "label"
                )
              )
            );
            this.form.tempOption.forEach((ele) => {
              ele.state = false;
              ele.status = 1;
            });
          }
          this.$forceUpdate(); //强制更新数据
        }
      });
    },
    //新增选项
    optAddFn(i, item) {
      let sIndex = i - 1;
      //获取当前id最大值
      let arr = [];
      let maxId = 0;
      if (this.form.tempOption.length) {
        this.form.tempOption.map((item, idx) => {
          if (!item.id) {
            item.id = idx;
          }
          item.id = String(item.id);
          if (item.id.split("-").length > 1) {
            arr.push(Number(item.id.split("-")[sIndex]));
          } else {
            arr.push(Number(item.id));
          }
        });
        maxId = Math.max.apply(Math, arr);
      }
      //插入id+1,防止id重复
      let obj = { label: "", id: String(maxId + 1), status: 1, isShow: true };
      if (sIndex > 0 && item.level > 1) {
        //2||3级/级联
        let pIndex = this.form.tempOption.findIndex((item) => item.state); //上级
        if (pIndex > -1) {
          let pid = this.form.tempOption[pIndex].id;
          obj.id = pid + "-" + obj.id;
        } else {
          this.$utils.voit("请先选择上级选项", "error");
          return false;
        }
      } else if (sIndex > 0 && (!item.level || item.level < 2)) {
        //2||3级/非级联
        obj.id = sIndex + "-" + obj.id;
      }
      this.form.tempOption.push(obj);
      this.$forceUpdate(); //强制更新数据
      //console.log(this.form.tempOption,'this.form.tempOption')
    },
    //选择菜单项
    selOption(sIndex, item) {
      this.form.tempOption.forEach((i) => (i.state = false));
      if (sIndex < 3) {
        //切换子级数据
        this.form.tempOption.forEach((j) => {
          if (sIndex == 1 && String(j.id).split("-").length > 1) {
            j.notShow = !(String(j.id).split("-")[0] == item.id);
          } else if (sIndex == 2 && String(j.id).split("-").length > 2) {
            j.notShow = !(
              String(j.id).split("-")[0] + "-" + String(j.id).split("-")[1] ==
              item.id
            );
          }
        });
        //console.log(this.form.tempOption,'this.form.tempOption')
      }
      item.state = true;
      this.$forceUpdate(); //强制更新数据
    },
    //保存
    getSaveFn(item) {
      item.title = this.form.tempTitle; //赋值标题
      item.placeholder = this.form.tempPlaceholder; //赋值提示
      item.placeholder2 = this.form.tempPlaceholder2; //赋值提示
      item.isSys = this.form.isSys;
      //item.level=this.form.level
      item.option = JSON.parse(JSON.stringify(this.form.tempOption)); //临时选项
      this.getHideFn(item);
    },
    //关闭
    getHideFn(item) {
      this.form.tempTitle = ""; //临时标题
      this.form.tempPlaceholder = ""; //临时提示
      this.form.tempPlaceholder2 = ""; //临时提示
      this.form.isSys = true;
      (this.form.tempOption = []), //临时选项
        (item.isPrev = true);
      if (!item.title) {
        item.status = 2;
      }
    },
  },
  props: {
    isAddBtn: Boolean, //表单控件是否显示
    formData: Array, //自定义表单数组
    freeType: String, //新增自定义表单的类型
  },
};
</script>

<style lang="less" scoped>
.FreeForm {
  text-align: left;
  .button {
    margin-bottom: 20px;
    .el-button {
      border-radius: 2px;
      padding: 8px 22px;
    }
  }

  /deep/ .el-form.form {
    .form-line {
      position: relative;
      background-color: #fff;
      .icoFun {
        position: absolute;
        top: 18px;
        right: 20px;
        z-index: 99;
        background-color: #f6f6f6;
        display: none;
        em {
          margin-left: 10px;
          cursor: pointer;
        }
      }
      &:first-child {
        .iconupon {
          display: none;
        }
      }
      &:last-child {
        .iconxiayi {
          display: none;
        }
      }
      &:hover {
        background-color: #f6f6f6;
        .icoFun {
          display: block;
        }
      }

      .form-box {
        border: 1px solid #ececec;
        margin-bottom: 20px;
        padding: 15px 20px 20px;
        border-radius: 2px;
        .el-form-item {
          margin: 0px;
          .el-form-item__label {
            display: block;
            float: unset;
            width: 100%;
            padding-right: 40%;
            text-align: left;
            line-height: 160%;
            margin-bottom: 2px;
          }
          .el-form-item__content {
            line-height: 32px;
            span,
            em {
              vertical-align: middle;
            }
            .el-textarea {
              .el-textarea__inner {
                padding: 4px 5px;
                min-height: 32px;
                line-height: 160%;
                display: block;
                resize: none;
              }
            }
            .el-input__icon {
              line-height: 32px;
            }
            .el-input {
              line-height: 0px;
              .el-input__inner {
                height: 32px;
              }
            }
          }

          .el-button {
            float: left;
            height: 32px;
            width: 80px;
            padding: 0px;
            border-radius: 2px;
            font-size: 14px;
          }
        }
        .select-wrap {
          display: flex;
          .selectSet {
            flex: 1;
          }
          .selectSet:not(:first-child) {
            margin-left: 10px;
          }
          .el-select:not(:first-child) {
            margin-left: 10px;
          }
        }
        //预览
        //.prev{

        //}
        //编辑
        .edit {
          padding: 5px 0px 0px;
          .el-form-item {
            .el-form-item__content {
              //.el-textarea{width:68%;}
              .selectSet {
                border: 1px solid #e4e4e4;
                border-radius: 2px;
                overflow: hidden;
                background-color: #fff;
                .ss-tit {
                  position: relative;
                  height: 32px;
                  padding-right: 40px;
                  border-bottom: 1px solid #e4e4e4;
                  .el-input {
                    display: block;
                    float: left;
                    height: 100%;
                    .el-input__inner {
                      border: 0px !important;
                      border-radius: 0px;
                      height: 100%;
                    }
                  }
                  em.el-icon-circle-plus {
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    margin-top: -10px;
                    font-size: 20px;
                    color: #ccc;
                    cursor: pointer;
                    &:hover {
                      color: #999;
                    }
                  }
                }
                .ss-opt {
                  max-height: 200px;
                  overflow: hidden;
                  overflow-y: auto;
                  li {
                    height: 32px;
                    position: relative;
                    padding-right: 40px;
                    .el-input {
                      display: block;
                      float: left;
                      height: 100%;
                      .el-input__inner {
                        border: 0px !important;
                        border-radius: 0px;
                        height: 100%;
                      }
                    }
                    em.el-icon-error {
                      position: absolute;
                      right: 5px;
                      top: 50%;
                      margin-top: -10px;
                      font-size: 20px;
                      color: #ccc;
                      cursor: pointer;
                      display: none;
                      &:hover {
                        color: #999;
                      }
                    }
                    &:hover {
                      background-color: #f2f2f2;
                      .el-input {
                        .el-input__inner {
                          background-color: #f2f2f2;
                        }
                      }
                      em.el-icon-error {
                        display: block;
                      }
                    }
                    &.on,
                    &.on .el-input__inner {
                      background-color: #39c !important;
                      color: #fff;
                    }
                    &.on {
                      input {
                        background-color: #39c;
                        color: #fff;
                        &::-webkit-input-placeholder {
                          color: #fff !important;
                        }
                        &::-ms-input-placeholder {
                          color: #fff !important;
                        }
                        &:-moz-placeholder {
                          color: #fff !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .textarea {
    .content {
      border: 1px solid #e4e4e4;
      margin-top: 5px;
      background: #fff;
      padding: 5px;
      cursor: text;
      border-radius: 2px;
    }
    /deep/ .el-input__inner {
      border: 1px solid #e4e4e4 !important;
    }
    /deep/ .el-input__suffix {
      top: 0;
      margin-top: 0;
    }
  }
}
</style>

<template>
  <div class="monitor">
    <div
      class="datatab"
      v-if="proData.face_recognition == 2 || proData.photograph == 2"
    >
      <span
        :class="{ on: other.isDataTab == 1 }"
        @click="
          other.isDataTab = 1;
          getData();
        "
        >监控设置</span
      >
      <span
        :class="{ on: other.isDataTab == 2 }"
        @click="
          other.isDataTab = 2;
          getData();
        "
        >监控数据</span
      >
    </div>

    <div class="body" v-if="other.isDataTab == 1">
      <PopShare
        v-on:closePop="isShare = false"
        source="xm"
        :proData="proData"
        :examData="{
          examId: id,
          type: type,
          vipId: vipId,
          player_set: proData.player_set,
        }"
      />
    </div>
    <div class="body" v-if="other.isDataTab == 2">
      <div
        class="data-no"
        v-if="!other.key2 && (!monitorData.list || !monitorData.list.length)"
      >
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#iconmeiyoushuju"></use>
        </svg>
        <p>暂无数据</p>
      </div>
      <div v-else>
        <!--筛选框显示区域-->
        <div class="top-box" style="padding-top: 40px">
          <!-- 项目选择 下拉框 -->
          <div class="select-box">
            <el-select
              v-model="value"
              default-first-option
              v-if="left.testList.length > 1"
              placeholder="请选择问卷"
              @change="selectPaper()"
              popper-class="center-el-select"
            >
              <el-option
                v-for="item in left.testList"
                :key="item.paperId"
                :label="item.paperName"
                :value="item.paperId"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 头部搜索框 -->
          <div
            class="search-box tac clearfix"
            style="padding: 20px 0px 0px 0px"
          >
            <div style="width: 50%; margin-left: 50px">
              <input
                v-model="search.key2"
                type="text"
                autocomplete="off"
                class="search-key fl"
                style="width: 100%"
                placeholder="输入姓名或考号"
                @keyup.enter="getMonitorListFn('reset')"
              />
              <button
                class="search-btn mini fl iconfont iconsousuo"
                @click="getMonitorListFn('reset')"
              ></button>
            </div>
          </div>
        </div>

        <!-- 视频列表 -->
        <div class="monitor-list" style="padding: 20px">
          <ul>
            <li
              v-for="(item, index) in monitorData.list"
              :key="index"
              @click="getInvigilateByIdFn(item)"
            >
              <div class="video">
                <!--拍照监控-->
                <el-image
                  :src="item.photoUrl"
                  v-if="proData.photograph == 2"
                ></el-image>
                <!--视频监控-->
                <div class="pr vct-player">
                  <VTcPlayer
                    v-if="proData.face_recognition == 2"
                    :options="getonmessage(item)"
                    :elmId="'p' + item.videoId"
                    :key="item.videoId"
                  />
                  <div class="pa masking"></div>
                </div>
              </div>
              <div class="title">
                <!--姓名_考号-->
                <span v-if="item.studentName && item.examStuNo">{{
                  item.studentName + "_" + item.examStuNo
                }}</span>
                <div v-else>
                  <span v-elseif="item.studentName">{{
                    item.studentName
                  }}</span>
                  <span v-elseif="item.examStuNo">{{ item.examStuNo }}</span>
                </div>
                <span class="f12 ccc">{{ item.updateTime }}</span>
              </div>
            </li>
          </ul>
          <!-- 监控分页 -->
          <div class="pr clearfix">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="monitorData.currentPage"
              :total="monitorData.totalSize"
              :page-size="monitorData.pageSize"
              :page-sizes="monitorData.pageList"
              class="fl mt20 tac"
              @current-change="handleMonitorCurrentChange"
              @size-change="handleMonitorSizeChange"
            >
            </el-pagination>
          </div>
        </div>
        <!--无数据显示-->
        <div class="data-no" v-if="!monitorData.list.length">
          <em class="iconfont iconshujuweikong"></em>
          <p>暂无数据</p>
        </div>
      </div>
      <!-- 监控数据,功能开发中，敬请耐心等待... -->
    </div>

    <el-drawer
      title="监控详情列表"
      size="50%"
      :visible.sync="other.isMonitorDetail"
      direction="rtl"
      custom-class="monitorDetail-drawer"
      append-to-body
    >
      <div style="padding: 45px" v-if="monitorDetail && monitorDetail.list">
        <div class="top">
          <h3>{{ monitorDetail.studentName }}</h3>
          <!-- <span class="display-pop">
            <el-popover popper-class="display-pop" placement="bottom" width="300" trigger="click" :append-to-body="false" v-if="RecordList.length" >
              <el-table 
              class="pop-table" 
              :data="RecordList" 
              v-if="RecordList.length"
              height="220"
              :row-style="{height: 0 +'px'}"
              :cell-style="{padding: '2px'}"
              >
                <el-table-column width="150" property="createTime" label="作弊时间"></el-table-column>
                <el-table-column width="100" property="recordKindName" label="作弊行为"></el-table-column>
              </el-table>
              <em slot="reference"  class="iconfont iconzuobi cur ml15 f18" title="作弊行为" @click="getRecoreList()"></em>
            </el-popover>
          </span> -->

          <span>{{ monitorDetail.timeBucket }}</span>
        </div>
        <ul class="list" v-if="proData.photograph == 2">
          <!--拍照监控-->
          <li
            v-for="(item, index) in monitorDetail.list"
            :key="index"
            @click="
              other.isMonitorDetail = true;
              other.monitorDetail = index;
            "
          >
            <div class="video">
              <!-- 拍照 -->
              <el-image
                :src="item.photoUrl"
                :preview-src-list="monitorDetail.photoList"
                :z-index="20000"
              ></el-image>
            </div>
            <div class="title">
              <span class="f12 ccc">{{ item.updateTime }}</span>
              <!-- <a title="下载" @click="downloadNetFn(item)">下载</a> -->
              <a
                :href="item.photoUrl.replace('http://', 'https://')"
                download=""
                target="_blank"
                >下载</a
              >
            </div>
          </li>
        </ul>
        <div v-if="proData.face_recognition == 2">
          <!--视频监控-->
          <div
            class="video-wrap"
            v-for="(aItem, aIndex) in monitorDetail.list"
            :key="aIndex"
          >
            <div class="tal h-title">
              <!-- <span>{{aIndex.length>9?'第一视角':aIndex.length==9?'第二视角':'第三视角'}}</span> -->
              <span>{{
                aIndex == "pcCamera"
                  ? "第一视角"
                  : aIndex == "secondCamera"
                  ? "第二视角"
                  : "第三视角"
              }}</span>
              <em class="ml10 mr10 ccc">/</em
              ><el-checkbox v-model="checkedObj[aIndex]">全选</el-checkbox>
            </div>
            <ul class="list">
              <li
                v-for="(item, index) in aItem"
                :key="index"
                @click="
                  other.isMonitorDetail = true;
                  other.monitorDetail = index;
                "
              >
                <div class="video">
                  <VTcPlayer
                    v-if="proData.face_recognition == 2"
                    ref="tcPlayer"
                    :options="getonmessage(item)"
                    :elmId="item.videoId"
                    @load="onLoad"
                    @play="onPlay"
                    @pause="onPause"
                    :key="item.videoId"
                  />
                </div>
                <div class="title">
                  <span class="f12 ccc">{{ item.updateTime }}</span>
                  <a title="下载" @click="downloadNetFn(item)">下载</a>
                  <!-- <a :href="item.videoUrl.replace('http://','https://')" download="" target="_blank">下载</a> -->
                </div>
              </li>
            </ul>
          </div>
          <div class="zuobi video-wrap">
            <div class="tal h-title"><span>监考记录</span></div>
            <el-table
              :data="RecordList"
              v-if="RecordList.length"
              class="pop-table"
              border
            >
              <el-table-column
                width="150"
                property="createTime"
                label="作弊时间"
              ></el-table-column>
              <el-table-column
                property="recordKindName"
                label="作弊行为"
              ></el-table-column>
            </el-table>
          </div>
        </div>
        <div
          class="pop-Batch"
          v-if="JSON.stringify(checkedObj).indexOf(':true') > -1"
        >
          <button class="btnnull">
            <a @click="downloadNetFn()">批量下载</a>
          </button>
        </div>
      </div>
      <!-- 监控数据
      监控记录 -->
      <!--无数据显示-->
      <div class="data-no" v-else>
        <em class="iconfont iconshujuweikong"></em>
        <p>暂无数据</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import PopShare from "@/views/staff/components/asktrue/PopMonitor"; //引入分享弹窗
import { VTcPlayer } from "v-tcplayer";
import {
  getInvigilateReq,
  getInvigilateByIdReq,
  getProPaperReq,
  selectMonitorRecordReq,
} from "@/api/asktrue/project/project";
export default {
  name: "ksMonitor",
  components: {
    PopShare,
    VTcPlayer,
  },
  props: {
    data: Object,
    id: Number,
    type: Number,
    isJk: Boolean,
    isPZ: Boolean,
    vipId: Number,
    title: String,
    state: Number,
    ftype: String,
  },
  data() {
    return {
      proType: 1, //项目类型
      proData: {}, //项目详情数据
      value: "",

      search: {
        key: "",
        tags: "",
        ab: "",
        key2: "", //视频关键字
      },
      RecordList: [], //作弊回显
      left: {
        testList: [], //左栏问卷列表
        testAbList: [], //区分AB卷的问卷列表
      },
      list: {
        sortType: 0, //排序
        order: [
          { field: "exam_stu_no", sc: "desc" },
          { field: "start_time", sc: "desc" },
          { field: "end_time", sc: "desc" },
        ],
        orderby: "", //排序字符串参数，接口使用
        pageList: [10, 20, 30, 50, 100, 200], //每页显示条数数组
        pageSize: 20, //每页显示条数
        currentPage: 1, //当前页码
        totalSize: 0,
        checkedAll: false, //全选
        checkedGroup: [], //列表选中状态
        listLoading: true,
        listData: [], //答题数据列表
        isShowBatch: false, //显示底部操作按钮
      },
      monitorData: {
        pageList: [10, 20, 30, 50, 100, 200], //每页显示条数数组
        pageSize: 20, //每页显示条数
        currentPage: 1, //当前页码
        totalSize: 0,
        list: [], //监控数据列表
      },
      monitorDetail: {
        list: [],
        photoList: [],
      },
      checkedObj: {}, //选中
      other: {
        key: "",
        key2: "",
        paperName: "",
        paperTypeId: "0", //当前选中问卷的卷标
        tid: 0, //当前选中问卷的id
        paperType: 1, //试卷类型1静态2动态
        isMarkingPapers: false, //阅卷开关
        isDataTab: 1, //答题数据与监控数据切换，1答题数据；2监控数据
        isMonitorDetail: false, //监控详情列表弹窗
        monitorDetail: 0, //监控数据index值
        MarkingPapersData: {}, //阅卷数据
        player: "",
        isDwonTips: false, //下载等待弹窗开关
        isDwonTips2: false, //下载等待弹窗开关
      },
    };
  },
  activated() {
    this.clearDataFn(); //加载清空数据
  },
  mounted() {
    this.getProjectEditFn();
    this.getData();
  },
  methods: {
    // 试卷选择
    selectPaper() {
      // this.value = this.left.testList.paperId
      console.log(this.value);
      this.other.tid = this.value;
      this.getData();
    },
    // 视频加载
    onLoad() {},
    // 视频点击播放
    onPlay() {},
    // 视频点击暂停
    onPause() {},
    //左栏问卷列表
    getTestListFn() {
      let params = {
        examId: this.id,
        projectType: this.type,
      };
      console.log(params);
      getProPaperReq(params).then((res) => {
        if (res.code == "200") {
          this.left.testList = res.data.result;
          console.log(this.left.testList, "this.left.testList");
          this.left.testAbList = [];
          if (res.data.result.length > 0) {
            this.value = res.data.result[0].paperId;
            if (this.proData.abc == 1) {
              //开启AB卷
              let abList = this.proData.paperTypeList.split(",");
              let l1 = [];
              let l2 = [];
              abList.forEach((el) => {
                el.length == 1 ? l1.push(el) : l2.push(el);
              });
              abList = l1.sort().concat(l2.sort());

              abList.map((el) => {
                let lis = res.data.result.filter((e) => e.paperTypeId == el);
                if (lis.length > 0) {
                  // this.left.testList=this.left.testList.concat(lis);
                  this.left.testAbList.push({
                    abLabel: el + "卷", //AB卷名称
                    list: lis,
                  });
                }
              });
              this.other.tid = this.left.testAbList[0].list[0].paperId;
              this.other.paperName = this.left.testAbList[0].list[0].paperName;
              this.other.paperTypeId =
                this.left.testAbList[0].list[0].paperTypeId;

              this.other.paperType =
                this.left.testAbList[0].list[0].pageCreateType;
              console.log(this.other, "other");
              this.getData();
            } else {
              //未开启AB卷
              // this.left.testList=this.left.testList.concat(res.data.result);
              this.left.testAbList = [
                {
                  abLabel: "", //空名称
                  list: res.data.result,
                },
              ];
              if (res.data.result && res.data.result.length > 0) {
                this.other.tid = res.data.result[0].paperId;
                this.other.paperTypeId = res.data.result[0].paperTypeId;
                this.other.paperType = res.data.result[0].pageCreateType;
                this.getData();
              }
            }
          } else {
            this.noShow = true;
            this.showLoading = false;
            this.list.listLoading = false;
            this.other.tid = 0;
            this.other.paperTypeId = "0";
          }
        }
      });
    },
    getData() {
      // this.list.currentPage=1;
      this.search.tags = "";
      if (this.other.isDataTab == 2) {
        this.getMonitorListFn();
        // this.$message.warning();
      }
    },
    getRecoreList() {
      let obj = {
        examId: this.id,
        projectType: this.type,
        studentId: this.other.monitorDetail,
      };
      selectMonitorRecordReq(obj).then((res) => {
        console.log(res, "rrr");
        if (res.code == "200") {
          this.RecordList = res.data.result;
        }
      });
    },
    //加载页面情况数据
    clearDataFn() {
      (this.search = {
        key: "",
        tags: "",
        ab: "",
        key2: "", //视频关键字
      }),
        (this.other.isDataTab = 1);
      this.left.testList = [];
      this.left.testAbList = [];
      this.list.listData = [];
      this.noShow = false; //不显示（初始暂无数据和列表均不能显示）
      this.noShow2 = false; //不显示（初始暂无数据和列表均不能显示）
      this.list.totalSize = 0;
      this.list.currentPage = 1;
      this.list.checkedAll = false; //全选
      this.list.checkedGroup = []; //列表选中状态
      this.list.isShowBatch = false; //显示底部操作按钮
    },
    //获取项目详情数据
    getProjectEditFn() {
      this.proData = this.$store.getters.projectInfo;
      console.log(this.proData, "this.proDatathis.proData");
      this.getTestListFn();
    },
    //获取监控数据列表
    getMonitorListFn() {
      let params = {
        projectType: this.type, //项目类型:1考试,2调查,3评估,4报名,5投票
        examId: this.id, //项目ID
        pageNum: this.monitorData.currentPage, //查询页数
        pageSize: this.monitorData.pageSize, //每页条数
        paperId: this.other.tid, //试卷ID
        paperTypeId: this.other.paperTypeId, //卷标
        searchText: this.search.key2, //文本搜索条件
      };
      if (this.proData.face_recognition == 2) {
        //开启视频 1不开启 2 开启
        params.investigationType = 2; //查询的监控类型：照片1；视频2
      }
      if (this.proData.photograph == 2) {
        //开启拍照 1不开启 2 开启
        params.investigationType = 1; //查询的监控类型：照片1；视频2
      }

      getInvigilateReq(params).then((res) => {
        if (res.code == "200") {
          this.monitorData.list = res.data.result.list;
          this.monitorData.totalSize = res.data.result.total;
          console.log(this.monitorData.list, "llll");
          this.noShow2 = true;
          this.showLoading = false;
          this.list.listLoading = false;
          this.other.key2 = this.search.key2;
        }
      });
    },
    //获取监控详情
    getInvigilateByIdFn(item) {
      console.log(item);
      this.other.monitorDetail = item.studentId;
      this.monitorDetail.studentName = item.studentName;
      let params = {
        projectType: this.type, //项目类型:1考试,2调查,3评估,4报名,5投票
        examId: this.id, //项目ID
        paperId: this.other.tid, //试卷ID
        searchText: this.search.key, //文本搜索条件
        studentId: this.other.monitorDetail,
        //playerType:'one'
      };
      if (this.proData.face_recognition == 2) {
        //开启视频 1不开启 2 开启
        params.investigationType = 2; //查询的监控类型：照片1；视频2
      }
      if (this.proData.photograph == 2) {
        //开启拍照 1不开启 2 开启
        params.investigationType = 1; //查询的监控类型：照片1；视频2
      }
      getInvigilateByIdReq(params).then((res) => {
        if (res.code == "200") {
          this.monitorDetail.list = res.data.result;
          if (this.proData.photograph == 2) {
            //如果是拍照监控，则生成预览图片列表
            this.monitorDetail.photoList = [];
            res.data.result.map((el) => {
              this.monitorDetail.photoList.push(el.photoUrl);
            });
          } else {
            if (
              this.monitorDetail.list.filter((e) => e.fileFormat == "mp4")
                .length
            ) {
              this.monitorDetail.list = this.monitorDetail.list.filter(
                (e) => e.fileFormat == "mp4"
              );
            }
            // let roomObj={}
            // this.monitorDetail.list.map(e=>{
            //   let streamParam=this.$commons.UrlSearch(e.streamParam)
            //   if(!roomObj[streamParam.groupid]){roomObj[streamParam.groupid]=[]}
            //   roomObj[streamParam.groupid].push(e)
            // })
            // 三个视角在一个房间了，判断方法有变
            let roomObj = {};
            this.monitorDetail.list.map((e) => {
              let streamParam = this.$commons.UrlSearch(e.streamParam);
              console.log(streamParam);
              // https://cloud.tencent.com/document/product/647/76497
              let userId = window.atob(
                streamParam.userid.replace("-", "/").replace(".", "=")
              );
              console.log(userId);
              let type = userId.split("@")[1].split("_")[0];
              if (!roomObj[type]) {
                roomObj[type] = [];
              }
              roomObj[type].push(e);
            });

            this.monitorDetail.list = roomObj;
          }
          if (res.data.result.length) {
            this.monitorDetail.timeBucket =
              res.data.result[0].updateTime +
              "-" +
              res.data.result[res.data.result.length - 1].updateTime;
          } else {
            this.monitorDetail.list = null;
          }
          this.other.isMonitorDetail = true;
        }
      });
      this.getRecoreList();
    },
    // 视频地址存放
    getonmessage(item) {
      let obj = {
        //hlsUrl: 'https://lib.baomitu.com/hls.js/0.8.9/hls.min.js',
        width: "100%",
        height: "100%",
        autoplay: false, // 自动播放（腾讯云播放地址测试可以自动播放）
        m3u8: item.videoUrl.replace("http://", "https://"),
      };
      return obj;
    },
    //批量下载
    downloadNetFn(item) {
      if (item) {
        let url = item.videoUrl.replace("http://", "https://");
        this.$utils.asktrue.download(
          url,
          item.studentName + "_" + item.examStuNo,
          ".mp4"
        );
      } else {
        for (let key in this.checkedObj) {
          if (this.checkedObj[key]) {
            this.monitorDetail.list[key].map((e) => {
              let url = e.videoUrl.replace("http://", "https://");
              this.$utils.asktrue.download(
                url,
                e.studentName + "_" + e.examStuNo,
                ".mp4"
              );
            });
          }
        }
      }
    },

    /* 监控分页 */
    //翻页，每页条数发生改变时触发
    handleMonitorSizeChange(val) {
      this.monitorData.currentPage = 1;
      this.monitorData.pageSize = val;
      this.getMonitorListFn();
      this.handleMonitorCurrentChange(this.monitorData.currentPage);
    },
    //翻页，当前页码发生改变时触发
    handleMonitorCurrentChange(e) {
      this.monitorData.currentPage = e;
      this.monitorData.checkedGroup = []; //清空已选，成绩id
      this.monitorData.isCheckAll = false; //取消全选复选项选中状态
      this.getMonitorListFn();
    },
  },
};
</script>

<style lang="less" scoped>
.monitor {
  position: relative;
  height: 100%;
  .datatab {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    height: 30px;
    line-height: 30px;
    transform: translateX(-50%);
    border: 0px solid #e4e4e4;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 0 3px rgba(125, 125, 125, 0.3);
    span {
      width: 80px;
      font-size: 12px;
      cursor: pointer;
      &.on {
        background-color: #39c;
        color: #fff;
      }
    }
  }
}
.display-pop {
  margin-left: -260px;
}
.edit-warpper {
  padding: 0px;
  height: 100%;
  .sidebar {
    width: 180px;
    height: 100%;
    float: right;
    background-color: #fff;
    border-left: 1px solid #e4e4e4;
    padding: 0 25px;
    .oc {
      line-height: 18px;
      padding: 22px 0;
      border-bottom: 1px solid #e4e4e4;
      font-size: 12px;
      text-align: left;
      /deep/ .el-switch {
        float: right;
        .el-switch__core {
          width: 36px;
          height: 18px;
          &:after {
            width: 14px;
            height: 14px;
          }
        }
        &.is-checked {
          .el-switch__core::after {
            margin-left: -15px;
          }
        }
      }
    }
  }
  .main {
    height: 100%;
    padding: 50px 55px 30px;
    overflow: hidden;
    overflow-y: auto;
    .body {
      width: 100%;
      border-radius: 5px;
      text-align: left;
    }
  }
}

.monitor-list {
  ul {
    border-radius: 2px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 18%;
      height: 0px;
      padding-top: 13%;
      margin: 2.5% 2.5% 2.5% 0;
      background-color: #000;
      position: relative;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      .video {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        .el-image {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .vct-player,
        .vtp-player {
          height: 100%;
        }
        .masking {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
      .title {
        position: absolute;
        left: 0px;
        bottom: -30px;
        width: 100%;
        line-height: 30px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        span {
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.monitorDetail-drawer {
  .top {
    line-height: 36px;
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 18px;
      font-weight: 400;
    }
    span {
      font-size: 12px;
      color: #ccc;
    }
  }
  .list {
    border-radius: 2px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 30%;
      height: 0px;
      padding-top: 22%;
      margin: 2.5% 5% 5% 0;
      background-color: #000;
      position: relative;
      &:nth-child(3n) {
        margin-right: 0px;
      }
      /deep/ .video {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .el-image {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .vtp-player {
          height: 100%;
        }
        .vcp-controls-panel {
          font-size: 12px;
        }
      }
      .title {
        position: absolute;
        left: 0px;
        bottom: -30px;
        width: 100%;
        line-height: 30px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        a {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
  .video-wrap {
    .h-title {
      margin-top: 20px;
      color: #666;
      /deep/ .el-checkbox__label {
        color: #666;
        font-weight: normal;
        padding-left: 5px;
      }
    }
    .list {
      margin-top: 0;
    }
    &.zuobi {
      margin-top: 10px;
      .pop-table {
        font-size: 12px;
        margin-top: 10px;
        height: auto !important;
      }
    }
  }
  .pop-Batch {
    left: 66%;
  }
}

.top-box {
  display: flex;

  .select-box {
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 38px;

    /deep/ input {
      width: 180px;
      height: 38px;
      font-size: 12px;
      padding: 0 10px;
      border-radius: 2px;
    }
  }
  .search-box {
    padding: 30px 40px;
    display: contents;
    .leading-out {
      position: relative;
      input {
        width: 90px;
        height: 38px;
        line-height: 38px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
        background-color: #fff;
        font-size: 12px;
      }
      ul {
        position: absolute;
        top: 37px;
        left: 0px;
        border: 1px solid #e4e4e4;
        width: 100%;
        z-index: 2;
        background-color: #fff;
        display: none;
        li {
          height: 38px;
          line-height: 38px;
          text-align: center;
          font-size: 12px;
          color: #666;
          cursor: pointer;
        }
        li + li {
          border-top: 1px dotted #e4e4e4;
        }
      }
      &:hover {
        ul {
          display: block;
        }
      }
    }
  }
}

/deep/ .video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .el-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .vtp-player {
    height: 100%;
  }
  .vcp-controls-panel {
    font-size: 12px;
  }
}
.title {
  position: absolute;
  left: 0px;
  bottom: -30px;
  width: 100%;
  line-height: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  a {
    font-size: 12px;
    color: #999;
  }
}
</style>

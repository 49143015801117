<!--
自定义下拉框，适用于筛选下拉
使用方法
<gSelect
  v-model="search.tab"          //value值
  :list="other.tabList"      //选项
  placeholder="类型"            //提示
  class="searchTab"             //样式
  @confirm="searchTab"          //返回事件
  @show="getOptFn"              //点击加载显示下拉框时触发
/>

import gSelect from "@/components/private/g-select.vue"; //引入
components: {gSelect}
-->
<template>
  <div ref="select" class="g-select no-select">
    <div
      :class="['title', sty, { on: show }, { isDisabled: disabled }]"
      @click="disabled ? '' : clickFn()"
    >
      <i :class="['prefixIcon', prefixIcon]" v-if="prefixIcon"></i>
      <!-- 前缀插槽，特殊情况用到 -->
      <slot name="prefix" />
      <span :class="['text', { on: label }]" :title="label">{{
        label || placeholder
      }}</span>
      <i :class="['suffixIcon', suffixIcon]"></i>
    </div>
    <div class="option-box" v-show="show">
      <div class="search" v-if="isSearch && list?.length > 6">
        <el-input
          type="text"
          autocomplete="off"
          v-model="key"
          class="keywords"
          placeholder="关键词筛选"
          @input="searchFn"
        >
          <em slot="append" class="iconfont iconsousuo" @click="searchFn"></em>
        </el-input>
      </div>
      <!-- 不分组选项 -->
      <div class="optBox scroll" v-if="!group">
        <ul class="option">
          <li
            v-for="(SelItem, SelIndex) in opt"
            :key="'selectCustom_' + SelIndex"
            @click="selectFn(SelItem)"
          >
            {{ SelItem.label }}
          </li>
        </ul>
      </div>
      <div class="group scroll" v-else>
        <ul
          class="option scroll"
          v-for="(SelItem, SelIndex) in opt"
          :key="'selectCustom_' + SelIndex"
        >
          <div class="label" v-if="SelItem.label">{{ SelItem.label }}</div>
          <li
            v-for="(SelE, SelI) in SelItem.opt"
            :key="'selectOpt_' + SelI"
            @click="selectFn(SelE)"
          >
            {{ SelE.label }}
          </li>
        </ul>
      </div>
      <div class="addBox" @click="addFn" v-if="isAdd">
        <em class="iconfont iconjia"></em>
        <span>新增</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-select",
  props: {
    id: Number,
    value: [String, Number],
    placeholder: String,
    list: Array, //格式[{label:'名称',value:'值'}]
    sty: { type: String, default: "font" }, //样式选项：font文字样式；select下拉框样式
    prefixIcon: String, //前缀图标,默认不显示
    suffixIcon: { type: String, default: "el-icon-arrow-down" }, //后缀图标,默认下拉箭头

    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "38px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      // 是否分组
      type: Boolean,
      default: false,
    },
    isSearch: { type: Boolean, default: false },
    defaultText: String, //值：不限、全部等，未选择时默认的空选项，同时作为判断条件是否存在此类空选项
    isAdd: { type: Boolean, default: false }, //是否存在新增功能
    addType: { type: String, default: "out" }, //新增方式，out外部新增(已开发)，in内部新增（暂未开发）
  },
  data() {
    return {
      show: false,
      label: "",
      opt: [],
      key: "",
    };
  },
  created() {
    this.setValue();
  },
  methods: {
    setListFn() {
      this.opt = [];
      if (this.list?.length && typeof this.list[0] == "object") {
        this.opt = JSON.parse(JSON.stringify(this.list));
        if (this.group) {
          this.opt.forEach((e) => {
            if (e?.opt && e?.opt.length) {
              if (typeof e.opt[0] == "string") {
                e.opt = e.opt.map((el) => {
                  return { label: el, value: el };
                });
              }
            }
          });
          console.log(this.opt, "==this.opt");
        }
      } else {
        this.list?.forEach((e) => {
          this.opt.push({ label: e, value: e });
        });
      }
      if (this.defaultText) {
        if (this.group) {
          let opt = {
            label: "",
            opt: [{ value: null, label: this.defaultText }],
          };
          this.opt.unshift(opt);
        } else {
          this.opt.unshift({ label: this.defaultText, value: null });
        }
      }
    },
    setValue() {
      this.setListFn();
      if (this.value) {
        let i = this.opt.findIndex((e) => e.value == this.value);
        if (i > -1) {
          this.label = this.opt.find((e) => {
            return e.value == this.value;
          }).label;
        }
      } else {
        this.label = "";
      }
    },
    selectFn(item) {
      this.label = item.value != null ? item.label : "";
      this.$emit("input", item.value);
      this.$emit("text", this.label);
      this.show = false;
      this.key = "";
      this.setListFn();
      this.$emit("confirm");
    },
    clickFn() {
      this.$emit("show");
      this.show = !this.show;
    },
    clear() {
      this.label = "";
      this.$emit("input", "");
    },

    searchFn() {
      this.opt = this.list.filter((e) => e.label.indexOf(this.key) > -1);
      this.$emit("text", this.label);
      if (this.defaultText) {
        if (this.group) {
          let opt = {
            label: null,
            opt: [{ label: this.defaultText, value: null }],
          };
          this.opt.unshift(opt);
        } else {
          this.opt.unshift({ label: this.defaultText, value: null });
        }
      }
    },

    bodyCloseMenus(e) {
      let self = this;
      if (this.$refs.select && !this.$refs.select.contains(e.target)) {
        if (self.bankSwitch == true) {
          self.bankSwitch = false;
        } else {
          this.show = false;
        }
      }
    },

    // 新增方法
    addFn() {
      if (this.addType == "out") {
        this.$emit("add");
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="less" scoped>
.g-select {
  position: relative;
  border-radius: 2px;
  .title {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.select {
      padding: 0 10px;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      background-color: #fff;
    }
    &.isDisabled {
      cursor: no-drop;
      background-color: #f5f7fa;
    }
    .text {
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.on {
        color: #606266;
      }
    }
    .prefixIcon {
      margin-right: 5px;
      color: inherit;
    }
    .suffixIcon {
      position: relative;
      color: #c0c4cc;
      margin-left: 3px;
      // transform: translateY(-50%);
    }
    &.on {
      .suffixIcon {
        transform: rotate(180deg);
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #e0e0e0;
        z-index: 1984;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #fff;
        z-index: 1985;
      }
    }
  }
  .option-box {
    position: absolute;
    top: calc(100% + 10px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0;
    z-index: 1983;
    .search {
      padding: 5px 10px;
      /deep/.keywords {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        min-width: 180px;
        width: 100%;
        height: 28px !important;
        border: 1px solid #e4e4e4 !important;
        border-radius: 2px;
        font-size: 12px;
        .el-input__inner {
          flex: 1;
          border-width: 0px !important;
        }
        .el-input-group__append {
          position: unset !important;
          border-width: 0px !important;
          height: 100% !important;
          padding: 0 15px !important;
          background-color: #fff !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          .iconsousuo {
            font-size: 12px;
          }
        }
      }
    }
    .group {
      min-width: 150px;
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
      .option {
        .label {
          display: flex;
          align-items: center;
          height: 34px;
          color: #ddd;
          padding: 0 15px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #e4e4e4;
        }
      }
    }
    .optBox {
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
    }

    .option {
      li {
        height: 34px;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 12px;
        padding: 0 15px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }
        &.on {
          color: #39c;
        }
      }
    }
  }
}
.addBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 40px;
  font-size: 12px;
  border-top: 1px solid #e4e4e4;
  padding-top: 5px;
  color: #39c;
  cursor: pointer;
  .iconfont {
    font-size: 12px;
    color: #39c;
  }
}
</style>

export default {
  parent: "Diary",
  routers: [
    // 我的日志
    {
      path: "mydiary",
      name: "MyDiary",
      component: () => import("@/views/oa/diary/DiaryList.vue"),
    },
    // 评阅日志
    {
      path: "reminddiary",
      name: "RemindDiary",
      component: () => import("@/views/oa/diary/DiaryList.vue"),
    },
    // @我的日志
    {
      path: "reviewlog",
      name: "ReviewLog",
      component: () => import("@/views/oa/diary/DiaryList.vue"),
    },
  ],
};

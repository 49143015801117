<!--图片增强弹框组件-->
<template>
  <!--图片裁剪-->
  <div class="personal">
    <!-- <el-upload
      class="self"
      drag
      action="test"
      :auto-upload="false"
      :on-change="onChange"
      :show-file-list="false"
    >
      <div class="_uploadImg">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text"><em>支持拖拽、点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </div>
      <img
        v-if="imageUrl"
        :src="imageUrl"
        style="width: 136px; height: 136px"
        class="avatar"
      />
    </el-upload> -->
    <!-- 上传图像前调整图像的尺寸 -->
    <el-dialog
      title="图片剪裁"
      :visible.sync="cropImageDialog"
      class="cropImageForm"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      custom-class="asktrue-dialog"
    >
      <div class="img-crop">
        <!-- 1.截图区域 -->
        <div class="imgCrop-content">
          <div class="cropper-content">
            <vueCropper
              height="362px"
              ref="cropper"
              :img="options.imgUrl"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixedBox="option.fixedBox"
              :fixedNumber="options.fixedNumber"
              :fixed="option.fixed"
              :centerBox="option.centerBox"
              :maxImgSize="option.maxImgSize"
              @realTime="realTime"
              @imgLoad="imgLoad"
            ></vueCropper>
          </div>
          <div class="tac">
            <div
              :style="
                'width:160px;height:' +
                (160 / options.fixedNumber[0]) * options.fixedNumber[1] +
                'px;overflow:hidden;'
              "
            >
              <div
                class="preview-content"
                :style="{
                  transform: 'scale(' + 160 / previews.w + ')',
                  transformOrigin: 'left top',
                }"
                v-html="previews.html"
              >
                {{ previews.html }}
                <!-- previews.w>160?:'' -->
                <!-- <div class="show-preview" :style="previews.div">
                  <div :style="previews.div" class="preview">
                    <img :src="previews.url" :style="previews.img" />
                  </div>
                </div> -->
              </div>
            </div>
            <p class="desc mt5">预览效果</p>
          </div>
        </div>
        <!-- 2.操作按钮区域 -->
        <div class="btn-content flex-center">
          <!-- <input
            class="btn"
            type="button"
            value="+"
            title="放大"
            @click="changeScale(1)"
          />
          <input
            class="btn"
            type="button"
            style
            value="-"
            title="缩小"
            @click="changeScale(-1)"
          />
          <input
            class="btn"
            type="button"
            value="↺"
            title="左旋转"
            @click="rotateLeft"
          /> -->
          <div
            class="el-icon-plus btn"
            style="line-height: 32px"
            @click="changeScale(1)"
          ></div>
          <div
            class="el-icon-minus btn"
            style="line-height: 32px"
            @click="changeScale(-1)"
          ></div>
          <div
            class="el-icon-refresh-left btn"
            style="line-height: 32px"
            @click="rotateLeft"
          ></div>
          <div
            class="el-icon-refresh-right btn"
            style="line-height: 32px"
            @click="rotateRight"
          ></div>
          <div
            class="btn"
            style="line-height: 32px; font-size: 17px"
            @click="changeLayer"
          >
            <p>[]</p>
          </div>
          <!-- <input
            class="btn"
            type="button"
            value="↻"
            title="右旋转"
            @click="rotateRight"
          /> -->
          <!-- <input
            class="btn"
            type="button"
            value="[]"
            title="框选"
            @click="changeLayer"
          /> -->
          <input
            v-if="false"
            class="btn"
            type="button"
            value="↓"
            title="下载"
            @click="down('blob')"
          />
        </div>
      </div>
      <div class="btn-footer">
        <el-button @click="sendParamToParent" size="small">关闭</el-button>
        <el-button type="primary" @click="submitPhoto" size="small"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper/src";
//import 'vue-cropper/dist/cropper.css'
import { ossUploadFileReq } from "@/api/asktrue/common";

export default {
  name: "dialogCropperComp",
  components: {
    VueCropper,
  },
  props: {
    options: Object, //参数
  },
  data() {
    return {
      imageUrl: "",
      cropImageDialog: true,
      //剪切图片上传
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: true, //输出原图比例截图 props名full
        outputType: "png", // 裁剪生成图片的格式
        canMove: true,
        original: false, // 上传图片按照原始比例渲染
        canMoveBox: true, // 截图框能否拖动
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 160, // 默认生成截图框宽度
        autoCropHeight: 200,
        fixedBox: true, // 固定截图框大小 不允许改变

        maxImgSize: 2000, //限制图片最大宽度和高度
        info: true, // 裁剪框的大小信息
        canScale: false, // 图片是否允许滚轮缩放
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [4, 5], // 截图框的宽高比例
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
    };
  },
  watch: {
    imgFile: {
      handler(newImgFile) {
        this.option.img = newImgFile;
        this.imageUrl = newImgFile;
      },
      immediate: true,
    },
    fixedBox: {
      handler(newValue) {
        this.option.fixedBox = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    onChange(file) {
      //blob类型
      // this.imageUrl = URL.createObjectURL(file.raw);
      //转base64
      let fr = new FileReader();
      fr.readAsDataURL(file.raw);
      fr.onload = (e) => {
        this.imageUrl = e.target.result;
        //值发生改变须向父组件传值
        this.sendParamToParent();
        //this.cropImageDialog = true;
        this.option.img = e.target.result;
      };
    },

    sendParamToParent() {
      // 调用父组件的方法并传递参数
      this.$emit("childEvent", this.imageUrl);
    },
    // 上传图像
    submitPhoto() {
      // 获取截图的base64 数据
      this.$refs.cropper.getCropData((data) => {
        //this.imageUrl = data;
        // 转换为File对象
        let file = this.$utils.dataURLtoFile(
          data,
          Date.parse(new Date()) + ".png"
        );
        let formData = new FormData();
        formData.append("file", file);
        //上传至服务器
        ossUploadFileReq(formData).then((res) => {
          if (res.code == "200") {
            this.imageUrl = res.data.result; //res.data[Object.keys(res.data)[0]];
            this.sendParamToParent();
          }
        });
      });
      // 获取截图的blob数据
      // this.$refs.cropper.getCropBlob((data) => {
      //   // do something
      //   console.log(data)
      // })
    },
    /*图片操作栏开始*/
    //放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    changeLayer() {
      this.option.autoCrop = !this.option.autoCrop;
      if (!this.option.autoCrop) {
        // this.$refs.cropper.startCrop() 开始截图
        // this.$refs.cropper.stopCrop() 停止截图
        this.$refs.cropper.clearCrop(); //清除截图
        // this.$refs.cropper.changeScale() 修改图片大小 正数为变大 负数变小
        // this.$refs.cropper.getImgAxis() 获取图片基于容器的坐标点
        // this.$refs.cropper.getCropAxis() 获取截图框基于容器的坐标点
        // this.$refs.cropper.goAutoCrop 自动生成截图框函数
        // this.$refs.cropper.rotateRight() 向右边旋转90度
        // this.$refs.cropper.rotateLeft() 向左边旋转90度
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //下载图片
    down(type) {
      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click();
        });
      }
    },
    imgLoad(msg) {
      console.log("imgLoad");
      console.log(msg);
    },
    /*图片操作栏结束*/

    // 压缩图片
    compress(img) {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        //initSize = img.src.length,
        width = img.width,
        height = img.height;
      canvas.width = width;
      canvas.height = height;
      // 铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //ctx.drawImage(img, 0, 0, width, height)
      //进行压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.8);
      //console.log("压缩后的图片大小：" + ndata.length)
      return ndata;
    },
    // base64转成bolb对象
    dataURItoBlob(base64Data) {
      let byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      else byteString = unescape(base64Data.split(",")[1]);
      let mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>
<style lang="less">
/deep/.personal {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  border-radius: 3px;
  padding-bottom: 20px;
  .content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 10px;
    // overflow-y: scroll;
    overflow: hidden;
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    //  1.标题及图像说明
    .content-desc {
      margin: 0px 10px;
      .title {
        font-size: 16px;
        border-left: 5px solid #2d8cf0;
        padding-left: 10px;
        margin-bottom: 16px;
      }
      .desc {
        margin-left: 18px;
      }
    }
    // 2.图像区域
    .content-image {
      display: flex;
      justify-content: rows;
      margin: 20px 30px;
      li {
        width: 180px;
        height: 180px;
        margin-right: 20px;
        position: relative;
        .delete-img {
          display: none;
        }
        &:hover {
          .delete-img {
            display: block;
            position: absolute;
            width: 180px;
            height: 40px;
            line-height: 40px;
            left: 0px;
            top: 140px;
            background: rgba(59, 60, 61, 0.5);
            // box-sizing: content-box;
            z-index: 999;
            cursor: pointer;
            text-align: right;
            i {
              margin: 8px 10px 0 0;
              display: block;
              font-size: 24px;
              color: white;
            }
          }
        }
      }
      img {
        width: 180px;
        height: 180px;
        border-radius: 4px;
      }
      .upload-img:hover {
        border: 1px dashed #92cccc;
      }
    }
  }
}
</style>
<style lang="less">
// 裁剪图像对话框
.cropImageForm {
  .img-crop {
    // 1.截图区域
    .imgCrop-content {
      margin: 0 20px;
      display: flex;
      display: -webkit-flex;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      .cropper-content {
        width: 350px;
        height: 350px;
        margin-right: 40px;
      }
      .preview-content {
        .show-preview {
          // border: 1px solid #ccc;
          box-sizing: border-box;
          width: 160px;
          overflow: hidden;
          flex: 1;
          -webkit-flex: 1;
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          -webkit-justify-content: center;
          .preview {
            overflow: hidden;
            /*border-radius: 50%;*/
            /*border: 1px solid #cccccc;*/
            background: #fff;
            margin-left: 0px;
            img {
              width: 100%;
            }
          }
        }
        .desc {
          margin-top: 10px;
          text-align: center;
        }
        .el-button {
          margin: 10px 40px;
        }
      }
    }
    // 2.操作按钮区域
    .btn-content {
      width: 350px;
      margin: 10px 20px;
      text-align: center;
      // #uploads {
      //   position: absolute;
      //   clip: rect(0 0 0 0);
      // }
      .btn {
        height: 32px;
        width: 32px;
        font-size: 20px;
        margin: 3px 5px;
        background-color: #fff;
        border: 1px solid #999;
        border-radius: 4px;
      }
    }
  }
  .btn-footer {
    width: 100%;
    text-align: right;
    padding-right: 20px;
    margin-top: -20px;
  }
}
</style>

import data from "./base/data.json";

// 使用方式：
// import {getData} from "@/js/data.js";
//     let a = getData("compSizeVar");
//     console.log(a);
// 传参：
//企业规模 compSizeVar
//企业性质 compTypeVar
//学校list schoolList
//学校类型 schoolTypeVar
//学校性质 schoolLevelVar
//学校级别 schoolGradeVar
//学位 degreeVar
//政治面貌 politicalVar
//教育水平 eduLevelVar
//培养方向 cultivationMethodVar
//薪资（年） salaryYearVar
//薪资（月） salaryMonthVar
//年龄 ageVar
//工作经验 experienceVar
//省 provinceVar
//市 cityList
//区 districtList
//圈 areaList
//地铁线 subwayLineVar
//地铁站 subwayStationVar
//行业list tradeList
//职业list jobKindList
//专业list professionList
//学校 schoolVar
//专业 professionVar
//省市 cityVar
//省市区 districtVar
//省市圈 areaVar
//行业 tradeVar
//职业 jobKindVar
//星座 xingzuoVar
//属相 shuxiangVar
//兴趣 interestVar
//职位诱惑 jobSpecialVar
//项目状态 projectStatusVar
//场地预定状态 placeOrderStatusVar
//简历状态 opeStatusVar
//工作状态 workStatusVar
//发票内容 invoiceVar
//发票类型 invoiceTypeVar
//模版内容 templateVar
//设施 facilitiesVar
//招聘系统>猎头>候选人>状态（移动端使用) zhuangtaiVar
export function getData(varType) {
  return data[varType].rows;
}

// 获取省份下的城市
export function getProCities(proId) {
  let cityList = data.cityList.rows;
  let newList = cityList.filter(
    (i) => String(i.id).substring(0, 2) == String(proId)
  );
  return newList;
}
// 获取省份下的区县
export function getProDist(proId) {
  let districtList = data.districtList.rows;
  let newList = districtList.filter(
    (i) => String(i.id).substring(0, 2) == String(proId)
  );
  return newList;
}
// 获取城市下的区县
export function getCityDist(cityId) {
  let districtList = data.districtList.rows;
  let newList = districtList.filter(
    (i) => String(i.id).substring(0, 4) == String(cityId)
  );
  return newList;
}

// 获取城市的区，eg：传入3301，返回北京划分的所有区
export function cityDistrict(cityId) {
  let rowsl = data.districtVar.rows.length;
  while (rowsl--) {
    let elem = data.districtVar.rows[rowsl];
    if (elem.id == cityId.toString().substr(0, 2)) {
      let childl = elem.children.length;
      let child = elem.children;
      while (childl--) {
        if (child[childl].id == cityId) {
          return child[childl].children;
        }
      }
    }
  }
}
// 获取城市的商务区，eg：传入3301，返回北京划分的所有商务区
export function cityArea(cityId) {
  let rowsl = data.areaVar.rows.length;
  while (rowsl--) {
    let elem = data.areaVar.rows[rowsl];
    if (elem.id == cityId.toString().substr(0, 2)) {
      let childl = elem.children.length;
      let child = elem.children;
      while (childl--) {
        if (child[childl].id == cityId) {
          return child[childl].children;
        }
      }
    }
  }
}
// 获取城市的地铁线，eg：传入3301，返回北京划分的所有地铁线
export function citySubway(cityId) {
  let list = [];
  let rowsl = data.subwayLineVar.rows.length;
  while (rowsl--) {
    let elem = data.subwayLineVar.rows[rowsl];
    if (elem.pid == cityId) {
      list.push({ value: elem.value, n: elem.pid, id: elem.pid });
    }
  }
  return list.reverse();
}

/*
 * @Description: 绩效 进程管理
 * @Date: 2024-01-25 14:26:02
 * @LastEditTime: 2024-01-25 14:26:24
 */

export default {
  parent: "ProcessManagement",
  routers: [
    {
      path: ":type",
      name: "ProcessManagementList",
      component: () =>
        import("@/views/performance/ProcessManagement/ProcessList.vue"),
    },
  ],
};

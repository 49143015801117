import Vue from "vue";

let req;
// 自动导入全局公共组件
// req = require.context(".", true, /\.vue$/);
// req = require.context("./", true, /^.\/g-.*\.vue$/);
req = require.context("./", true, /^.*\/g-.*\.vue$/);
req.keys().forEach((path) => {
  let component = req(path)?.default;
  if (component && component.name) {
    Vue.component(component.name, component);
  }
});

<template>
  <ul v-if="pc" class="temp-ul" @click.stop="">
    <li :class="{ on: template == 1 }" @click="checked(1)">
      <div class="module-box">
        <Login1
          class="pcLogin"
          :transData="
            template == 1 && autoStyle ? loginData.login : loginData.login1
          "
          v-if="normal"
        />
        <ExamLoginNick1
          class="pcLogin"
          :transData="
            template == 1 && autoStyle ? loginData.login : loginData.login1
          "
          v-else
        />
      </div>
      <div class="module-tit">模板一</div>
    </li>
    <li :class="{ on: template == 2 }" @click="checked(2)">
      <div class="module-box">
        <Login2
          class="pcLogin"
          :transData="
            template == 2 && autoStyle ? loginData.login : loginData.login2
          "
          v-if="normal"
        />
        <ExamLoginNick2
          class="pcLogin"
          :transData="
            template == 2 && autoStyle ? loginData.login : loginData.login2
          "
          v-else
        />
      </div>
      <div class="module-tit">模板二</div>
    </li>
    <li :class="{ on: template == 3 }" @click="checked(3)">
      <div class="module-box">
        <Login3
          class="pcLogin"
          :transData="
            template == 3 && autoStyle ? loginData.login : loginData.login3
          "
          v-if="normal"
        />
        <ExamLoginNick3
          class="pcLogin"
          :transData="
            template == 3 && autoStyle ? loginData.login : loginData.login3
          "
          v-else
        />
      </div>
      <div class="module-tit">模板三</div>
    </li>
  </ul>

  <ul v-else class="temp-ul mobile" @click.stop="">
    <li :class="{ on: template == 1 }" @click="checked(1)">
      <div class="module-box">
        <LoginMobile1
          class="mLogin"
          :transData="
            template == 1 && autoStyle ? loginData.login : loginData.login1
          "
        />
      </div>
      <div class="module-tit">模板一</div>
    </li>
    <li :class="{ on: template == 2 }" @click="checked(2)">
      <div class="module-box">
        <LoginMobile2
          class="mLogin"
          :transData="
            template == 2 && autoStyle ? loginData.login : loginData.login2
          "
        />
      </div>
      <div class="module-tit">模板二</div>
    </li>
    <li :class="{ on: template == 3 }" @click="checked(3)">
      <div class="module-box">
        <LoginMobile3
          class="mLogin"
          :transData="
            template == 3 && autoStyle ? loginData.login : loginData.login3
          "
        />
      </div>
      <div class="module-tit">模板三</div>
    </li>
  </ul>
</template>

<script>
import Login1 from "./pc/login1";
import Login2 from "./pc/login2";
import Login3 from "./pc/login3";
import ExamLoginNick1 from "./pc/loginPg1";
import ExamLoginNick2 from "./pc/loginPg2";
import ExamLoginNick3 from "./pc/loginPg3";
import LoginMobile1 from "./mobile/login1";
import LoginMobile2 from "./mobile/login2";
import LoginMobile3 from "./mobile/login3";
export default {
  components: {
    Login1,
    Login2,
    Login3,
    ExamLoginNick1,
    ExamLoginNick2,
    ExamLoginNick3,
    LoginMobile1,
    LoginMobile2,
    LoginMobile3,
  },
  props: ["loginData", "pc", "normal", "autoStyle"],
  data() {
    return {
      template: 0,
    };
  },
  watch: {
    "loginData.login.template": {
      handler() {
        for (let prop in this.loginData.login.template) {
          this[prop] = this.loginData.login.template[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    for (let prop in this.loginData.login.template) {
      this[prop] = this.loginData.login.template[prop];
    }
  },
  methods: {
    checked(v) {
      this.template = v;
      this.$emit(
        "dealChange",
        {
          template: this.template,
        },
        "template"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.temp-ul {
  li {
    margin-bottom: 20px;
    height: auto;
    &:hover {
      background-color: #fff;
    }
    .module-box {
      width: 76px;
      height: 49px;
      overflow: hidden;
      border: 1px solid #e4e4e4;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .pcLogin {
        width: 1100px;
        transform: scale(0.0682);
        height: 700px;
        transform-origin: left top;
      }
    }
    .module-tit {
      font-size: 12px;
      margin-top: 10px;
      text-align: center;
    }

    &.on {
      color: var(--second-theme-color);
      .module-box {
        border-color: var(--second-theme-color);
      }
    }
  }
  &.mobile {
    li {
      .module-box {
        width: 49px;
        height: 76px;
        overflow: hidden;
        border: 1px solid #e4e4e4;
        margin: 0 auto;
        cursor: pointer;
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .mLogin {
          width: 360px;
          transform: scale(0.13);
          height: 590px;
          transform-origin: left top;
        }
      }
      &.on {
        color: var(--second-theme-color);
        .module-box {
          border-color: var(--second-theme-color);
        }
      }
    }
  }
}
</style>

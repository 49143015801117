var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exam-background pr",style:(_vm.backgroundStyle)},[_c('div',{staticClass:"top"},[(_vm.transData.logo.show)?_c('div',{staticClass:"logo"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.transData.logo.show),expression:"transData.logo.show"}],attrs:{"src":_vm.transData.logo.img}})]):_vm._e(),_c('div',{staticClass:"title",style:(_vm.titleStyle)},[_vm._v(_vm._s(_vm.titleText))])]),_c('div',{class:['main-box', { mainMore: _vm.stepList.length > 1 }],style:('background-color:' +
      _vm.transData.loginbox.rgba +
      ';border-color:' +
      _vm.transData.loginbox.borderColor +
      ';border-width:' +
      (_vm.transData.loginbox.show ? 1 : 0) +
      'px;')},[(_vm.stepList.length > 1)?_c('div',{staticClass:"step-show"},[_c('div',{class:['back-border05 ' + (_vm.onG ? 'border-on-g' : 'border-on')],style:(_vm.stepStyle.pass)}),_c('div',{class:[
          'top-info-item',
          { on: _vm.maxStep >= _vm.stepList[0].step && !_vm.onG },
          { 'on-g': _vm.maxStep >= _vm.stepList[0].step && _vm.onG },
          { 'active-g': _vm.step == _vm.stepList[0].step && _vm.onG },
          { active: _vm.step == _vm.stepList[0].step },
        ],style:(_vm.step == _vm.stepList[0].step
            ? _vm.stepStyle.checked
            : _vm.maxStep >= _vm.stepList[0].step
            ? _vm.stepStyle.pass
            : _vm.stepStyle.begin)},[_c('div',{staticClass:"info",on:{"click":function($event){return _vm.changeStep(_vm.stepList[0].step)}}},[_c('i',[_vm._v(_vm._s(_vm.stepList.findIndex((ele) => ele.step == _vm.stepList[0].step) + 1))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.stepList[0].title))])])]),(_vm.stepList.length > 1)?_c('div',{class:[
          'back-border1',
          { 'border-on': _vm.maxStep >= _vm.stepList[1].step && !_vm.onG },
          { 'border-on-g': _vm.maxStep >= _vm.stepList[1].step && _vm.onG },
        ],style:(_vm.maxStep >= _vm.stepList[1].step ? _vm.stepStyle.pass : _vm.stepStyle.begin)}):_vm._e(),(_vm.stepList.length > 1)?_c('div',{class:[
          'top-info-item',
          { on: _vm.maxStep >= _vm.stepList[1].step && !_vm.onG },
          ,
          { 'on-g': _vm.maxStep >= _vm.stepList[1].step && _vm.onG },
          { 'active-g': _vm.step == _vm.stepList[1].step && _vm.onG },
          { active: _vm.step == _vm.stepList[1].step },
          { active: _vm.step == _vm.stepList[1].step },
        ],style:(_vm.step == _vm.stepList[1].step
            ? _vm.stepStyle.checked
            : _vm.maxStep >= _vm.stepList[1].step
            ? _vm.stepStyle.pass
            : _vm.stepStyle.begin)},[_c('div',{staticClass:"info",on:{"click":function($event){return _vm.changeStep(_vm.stepList[1].step)}}},[_c('i',[_vm._v(_vm._s(_vm.stepList.findIndex((ele) => ele.step == _vm.stepList[0].step) + 1))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.stepList[1].title))])])]):_vm._e(),_c('div',{class:[
          'back-border05',
          {
            'border-on':
              _vm.maxStep >= _vm.stepList[_vm.stepList.length - 1].step && !_vm.onG,
          },
          {
            'border-on-g':
              _vm.maxStep >= _vm.stepList[_vm.stepList.length - 1].step && _vm.onG,
          },
        ],style:(_vm.maxStep >= _vm.stepList[_vm.stepList.length - 1].step
            ? _vm.stepStyle.pass
            : _vm.stepStyle.begin)})]):_vm._e(),_c('div',{class:['main', 'main' + _vm.step]},[(_vm.inform && _vm.step == 0)?_c('div',{staticClass:"plate tal"},[_c('div',{staticClass:"kqxz border-box"},[_c('div',{staticClass:"inform tal",domProps:{"innerHTML":_vm._s(_vm.inform)}}),_c('el-checkbox',{staticClass:"mt20",on:{"change":function($event){return _vm.changeStep(0, 1)}},model:{value:(_vm.isInform),callback:function ($$v) {_vm.isInform=$$v},expression:"isInform"}},[_vm._v("我已阅读并同意遵守规定")])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],staticClass:"plate body"},[_c('div',{staticClass:"border-box",staticStyle:{"width":"360px"}},[_c('div',{staticClass:"f60 mb30 tal"},[_vm._v(" 注：本项目采用匿名方式，但限制了参与范围，请验证是否有参与资格! ")]),_c('div',{staticClass:"login clearfix"},[_c('div',{staticClass:"login-input",class:_vm.examData.login_rule == '1'
                  ? 'el-icon-mobile-phone'
                  : 'el-icon-message',style:('color:' + _vm.transData.loginbox.borderColor + ';')},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"text",style:('border-color:' +
                  _vm.transData.loginbox.borderColor +
                  '!important;color:' +
                  _vm.transData.loginbox.borderColor +
                  '!important;'),attrs:{"type":"text","placeholder":_vm.examData.login_rule == '1'
                    ? '输入手机号码'
                    : _vm.examData.login_rule == '2'
                    ? '输入电子邮箱'
                    : '输入考号'},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})]),_c('div',{staticClass:"login-input code-div el-icon-lock",style:('color:' + _vm.transData.loginbox.borderColor)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"text",style:('border-color:' +
                  _vm.transData.loginbox.borderColor +
                  '!important;color:' +
                  _vm.transData.loginbox.borderColor +
                  '!important;'),attrs:{"type":"text","placeholder":_vm.examData.login_rule == '3' ? '输入密码' : '输入验证码'},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}}),(_vm.examData.login_rule != '3')?_c('span',{staticClass:"getcodebtn pa",style:('color:' + _vm.transData.loginbox.borderColor)},[_c('em',{style:('background-color:' + _vm.transData.loginbox.borderColor + ';')}),_c('span',{staticClass:"cur"},[_vm._v("获取验证码")])]):_vm._e()]),_c('el-button',{staticClass:"logBtn",style:('border-color:' +
                _vm.transData.loginbox.borderColor +
                '!important;color:' +
                _vm.transData.loginbox.borderColor +
                '!important;')},[_vm._v("验证")])],1),(_vm.judgesList.length)?_c('div',{staticStyle:{"margin-top":"25px"}},[_c('p',[_vm._v("验证通过，请点击下面的随机许可证参与项目")]),_c('ul',{staticClass:"mt20"},_vm._l((_vm.judgesList),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()])])])]),(this.transData.copyright && this.transData.copyright.show)?_c('div',{staticClass:"foot"},[_vm._v(" "+_vm._s(this.transData.copyright.font)+" ")]):_vm._e(),_c('el-drawer',{attrs:{"visible":_vm.isMustFill,"size":"40%","custom-class":"asktrue-drawer"},on:{"update:visible":function($event){_vm.isMustFill=$event}}},[_c('MustFill',{ref:"mustFill",attrs:{"id":Number(_vm.$route.params.id),"type":String(_vm.type),"ownerId":_vm.vipId,"studentId":_vm.studentId},on:{"closeFill":function($event){_vm.isMustFill = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
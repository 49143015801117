const utf8 = { ContentType: "application/json;charset=UTF-8" };
export default function (axios) {
  return {
    // 已投递
    getSelectDeliveryPageReq(data) {
      return axios.post("/cowManCtl/selectDeliveryPage", data, utf8);
    },
    // 不合适
    getSelectInappropriatePageReq(data) {
      return axios.post("/cowManCtl/selectInappropriatePage", data, utf8);
    },
    // 审批中
    getSelectResumeApproveListReq(data) {
      return axios.post("/cowManCtl/selectResumeApproveList", data, utf8);
    },
    // 已录用/已发offer/已入职
    getSelectResumeEmployListReq(data) {
      return axios.post("/cowManCtl/selectResumeEmployList", data, utf8);
    },
    // 新招呼/沟通中
    getQueryNewHelloResumePageReq(data) {
      return axios.post("/cowManCtl/queryNewHelloResumePage", data, utf8);
    },
    // 已入库
    getQueryResumeLibPageReq(data) {
      return axios.post("/cowManCtl/queryResumeLibPage", data, utf8);
    },
    // RPO牛人统计栏（不用传参）
    getCowManCountReq(data) {
      return axios.post("/cowManCtl/getCowManCount", data, utf8);
    },
  };
}

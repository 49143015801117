// 工资
export default {
  parent: "Wage",
  routers: [
    {
      path: "file/:sign?",
      name: "WageFile",
      component: () => import("@/views/salary/wage/FileShunt.vue"),
    },
    {
      path: "list/:sign?",
      name: "WageList",
      component: () => import("@/views/salary/wage/ListShunt.vue"),
    },
    {
      path: "set/:sign?",
      name: "WageSet",
      component: () => import("@/views/salary/wage/SetShunt.vue"),
      meta: {
        nomy: true,
      },
    },
    {
      path: "bill/:sign?",
      name: "WageBill",
      component: () => import("@/views/salary/wage/BillShunt.vue"),
      meta: {
        nomy: true,
      },
    },
  ],
};

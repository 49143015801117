// 社保
export default {
  parent: "Social",
  routers: [
    {
      path: "ssa/:sign?",
      name: "SsArchives",
      component: () => import("@/views/salary/ss/SsArchivesShunt.vue"),
    },
    {
      path: "ssr/:sign?",
      name: "SsRecord",
      component: () => import("@/views/salary/ss/SsRecordShunt.vue"),
    },
    {
      path: "sss/:sign?",
      name: "SsSet",
      component: () => import("@/views/salary/ss/SsSetShunt.vue"),
      meta: {
        nomy: true,
      },
    },
  ],
};

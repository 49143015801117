export default {
  parent: "CrmOrder",
  routers: [
    {
      path: ":page",
      name: "CRMOrderList",
      component: () => import("@/views/crm/order/OrderList.vue"),
    },
    // {
    //   // 全部
    //   path: "all",
    //   name: "CrmOrderAll",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已确认
    //   path: "confrimList",
    //   name: "CrmOrderConfrimList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 待确认
    //   path: "toList",
    //   name: "CrmOrderToList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已驳回
    //   path: "opposeList",
    //   name: "CrmOrderOpposeList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已废除
    //   path: "abrogateList",
    //   name: "CrmOrderAbrogateList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
  ],
};

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'message-input-toolbar',
    !_setup.isPC && 'message-input-toolbar-h5',
    _setup.isUniFrameWork && 'message-input-toolbar-uni',
  ]},[_c('div',{class:[
      'message-input-toolbar-list',
      !_setup.isPC && 'message-input-toolbar-h5-list',
      _setup.isUniFrameWork && 'message-input-toolbar-uni-list',
    ]},[(!_setup.isUniFrameWork)?_c(_setup.EmojiPicker,{on:{"insertEmoji":_setup.insertEmoji,"dialogShowInH5":_setup.dialogShowInH5,"dialogCloseInH5":_setup.dialogCloseInH5}}):_vm._e(),(_setup.isUniFrameWork)?_c(_setup.ImageUpload,{attrs:{"imageSourceType":"camera"}}):_vm._e(),_c(_setup.ImageUpload,{attrs:{"imageSourceType":"album"}}),(!_setup.isUniFrameWork)?_c(_setup.FileUpload):_vm._e(),_c(_setup.VideoUpload,{attrs:{"videoSourceType":"album"}}),(_setup.isUniFrameWork)?_c(_setup.VideoUpload,{attrs:{"videoSourceType":"camera"}}):_vm._e(),_c(_setup.Words),(_setup.extensionListShowInStart[0])?_vm._l((_setup.extensionListShowInStart),function(extension,index){return _c(_setup.ToolbarItemContainer,{key:index,ref:extension.data.name + 'Ref',refInFor:true,staticStyle:{"display":"none"},attrs:{"iconFile":_setup.genExtensionIcon(extension),"title":_setup.genExtensionText(extension),"iconWidth":_setup.isUniFrameWork ? '25px' : '20px',"iconHeight":_setup.isUniFrameWork ? '25px' : '20px',"needDialog":false},on:{"onIconClick":function($event){return _setup.onExtensionClick(extension)}}})}):_vm._e()],2),(_setup.extensionListShowInEnd[0] && _setup.isPC)?_c('div',{class:['message-input-toolbar-list-end']},_vm._l((_setup.extensionListShowInEnd),function(extension,index){return _c(_setup.ToolbarItemContainer,{key:index,attrs:{"iconFile":_setup.genExtensionIcon(extension),"title":_setup.genExtensionText(extension),"iconWidth":_setup.isUniFrameWork ? '25px' : '20px',"iconHeight":_setup.isUniFrameWork ? '25px' : '20px',"needDialog":false},on:{"onIconClick":function($event){return _setup.onExtensionClick(extension)}}})}),1):_vm._e(),_c(_setup.UserSelector,{ref:"userSelectorRef",attrs:{"type":_setup.selectorShowType,"currentConversation":_setup.currentConversation,"isGroup":_setup.isGroup},on:{"submit":_setup.onUserSelectorSubmit,"cancel":_setup.onUserSelectorCancel}}),(_setup.isH5)?_c('div',{ref:"h5Dialog",class:['message-input-toolbar-h5-dialog']}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
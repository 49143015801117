//招聘者---猎头
export default function (axios) {
  return {
    //猎头-猎头分页查询
    hhSelectPageReq(data) {
      return axios.post("/recruiter/headhunter/selectPage", data, {
        ContentType: "application/json",
      });
    },

    hhSelectPageAllReq(data) {
      return axios.post("/recruiter/headhunter/selectPageAll", data, {
        ContentType: "application/json",
      });
    }, //猎头-搜索分页查询

    //猎头-邀请
    selectInvitedHhReq(data) {
      return axios.post("/recruiter/headhunter/selectInvitedHh", data, {
        ContentType: "application/json",
      });
    },

    //猎头-审批
    selectApprovedHhReq(data) {
      return axios.post("/recruiter/headhunter/selectApprovedHh", data, {
        ContentType: "application/json",
      });
    },

    //猎头-	新增猎头公司
    hhInsertHCompanyReq(data) {
      return axios.post("/recruiter/headhunter/insertHCompany", data, {
        ContentType: "application/json",
      });
    },

    //猎头-审批
    hhApproveReq(data) {
      return axios.post("/recruiter/headhunter/approve", data, {
        ContentType: "application/json",
      });
    },

    //猎头-审批
    hhSelectInviteApproveReq(data) {
      return axios.post(
        "/recruiter/headhunter/selectInviteApprove?flag=" +
        data.flag +
        "&reqFlag=" +
        data.reqFlag +
        "&compId=" +
        data.compId +
        "&userId=" +
        data.userId,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    //猎头-发送邀请（批量）
    hhSendInvitationReq(data) {
      return axios.post("/recruiter/headhunter/sendInvitation", data, {
        ContentType: "application/json",
      });
    },

    //猎头-撤销申请（批量）
    hhCancelInvitationReq(data) {
      return axios.post(
        "/recruiter/headhunter/cancelInvitation?compIds=" +
        data.compIds +
        "&reqFlag=" +
        data.reqFlag,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    //猎头-审批
    hhsendReminderReq(data) {
      return axios.post("/recruiter/headhunter/sendReminder", data, {
        ContentType: "application/json",
      });
    },

    //猎头-终止合作
    hhCancelCooperationReq(params) {
      return axios.post("/recruiter/headhunter/cancelCooperation", params, {
        ContentType: "application/json",
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    hhEntrustPositionReq(data) {
      return axios.post(
        "/recruiter/headhunter/entrustPosition?compIds=" +
        data.compIds +
        "&jobIds=" +
        data.jobIds,
        data,
        { ContentType: "application/json" }
      );
    }, //猎头-委托职位

    // 猎头公司统计 /recruiter/headhunter/getCount
    getHeadhunterCountReq(params) {
      return axios.get("/recruiter/headhunter/getCount", { params });
    },

    hhSelectResumePageReq(data) {
      return axios.post("/recruiter/headhunter/selectResumePage", data, {
        ContentType: "application/json",
      });
    }, //猎头-查寻猎头推荐的简历(推荐/初筛)

    //猎头-查寻猎头推荐的简历(推荐/初筛)（新）
    newPrimarySelectPage(data) {
      return axios.post("/newPrimaryScreeningCtl/selectPage", data, {
        ContentType: "application/json",
      });
    },

    hhSelectProcResumePageReq(data) {
      return axios.post("/recruiter/headhunter/selectProcResumePage", data, {
        ContentType: "application/json",
      });
    }, //猎头-查寻猎头推荐进度中的简历

    //猎头-详情-录用
    hhSelectResumeEmployListReq(data) {
      return axios.post("/resumeProcessCtl/selectResumeEmployList", data, {
        ContentType: "application/json",
      });
    },

    //猎头-取消委托
    hhCancelEntrustReq(data) {
      return axios.post(
        "/recruiter/headhunter/cancelEntrust?compId=" +
        data.compId +
        "&jobIds=" +
        data.jobIds +
        "&flag=" +
        data.flag +
        "&entrustStatus=" +
        data.entrustStatus,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    hhSelectAllConditionReq(params) {
      return axios.get("/recruiter/headhunter/selectAllCondition", { params });
    }, //猎头/客户搜索栏目筛选条件

    //猎头/客户搜索栏目筛选条件
    hhSelectHhCompConditionReq(params) {
      return axios.get("/recruiter/headhunter/selectHhCompCondition", {
        params,
      });
    },
  };
}

/*
 * @Description: 统计分析
 * @Date: 2024-03-13 10:26:02
 * @LastEditTime: 2024-03-13 13:44:29
 */

export default {
  parent: "ResultAnalysis",
  routers: [
    {
      // 目标统计
      path: "mb",
      name: "TjfxMb",
      component: () => import("@/views/performance/resultanalysis/mbView.vue"),
    },
    {
      // 指标统计
      path: "zb",
      name: "TjfxZb",
      component: () => import("@/views/performance/resultanalysis/zbView.vue"),
    },
    {
      // 绩效评价统计
      path: "pj",
      name: "TjfxPj",
      component: () => import("@/views/performance/resultanalysis/pjView.vue"),
    },
    {
      // 结果统计
      path: "jg",
      name: "TjfxJg",
      component: () => import("@/views/performance/resultanalysis/jgView.vue"),
    },
  ],
};

export default {
  parent: "Class",
  routers: [
    // 培训班-我参加的
    {
      path: "join",
      name: "pxb-join",
      component: () => import("@/views/train/class/indexView.vue"),
      meta: { title: "培训班-我参加的" },
    },
    // 培训班-我必学的
    {
      path: "required",
      name: "pxb-required",
      component: () => import("@/views/train/class/indexView.vue"),
      meta: { title: "培训班-我必学的" },
    },
    // 培训班-我报名的
    {
      path: "apply",
      name: "pxb-apply",
      component: () => import("@/views/train/class/indexView.vue"),
      meta: { title: "培训班-我报名的" },
    },
    // 培训班-我发布的
    {
      path: "creat",
      name: "pxb-creat",
      component: () => import("@/views/train/class/indexView.vue"),
      meta: { title: "培训班-我发布的" },
    },
    // 培训班-全部
    {
      path: "all",
      name: "pxb-all",
      component: () => import("@/views/train/class/indexView.vue"),
      meta: { title: "培训班-全部" },
    },
  ],
};

/*
 * @Description: RPO
 * @Date: 2023-09-14 17:07:17
 * @LastEditTime: 2024-05-06 15:56:10
 */
export default {
  parent: "RPO",
  routers: [
    // 职位 Job
    {
      path: "lbjob",
      name: "LbJob",
      component: () => import("@/views/rpo/headhunter/hJob/JobIndex.vue"),
    },
    // 候选人 Candidate
    {
      path: "lbcandidate",
      name: "LbCandidate",
      component: () => import("@/views/rpo/headhunter/hResume/index.vue"),
    },
    // 消息
    {
      path: "lbmessage",
      name: "LbMessage",
      component: () => import("@/views/rpo/message/IndexView.vue"),
    },
    // 寻访
    {
      path: "lbfind",
      name: "LbFind",
      component: () => import("@/views/rpo/LbFind/IndexView.vue"),
    },
    // LIST
    {
      path: "lblist",
      name: "LbLIST",
      component: () => import("@/views/rpo/LbLIST/IndexView.vue"),
    },
    // 客户
    {
      path: "lbcustomer",
      name: "LbCustomer",
      component: () => import("@/views/rpo/LbCustomer/IndexView.vue"),
    },
    // 工具
    {
      path: "lbtoolbox",
      name: "LbToolbox",
      component: () => import("@/views/rpo/LbToolbox/IndexView.vue"),
    },
    // 笔试
    {
      path: "LbhWritten",
      name: "LbhWritten",
      component: () =>
        import("@/views/recruit/arrange/bs/writtenExamArrange.vue"),
      meta: {
        title: "笔试",
        prev: {
          name: "LbToolbox",
          title: "工具",
        },
      },
    },
    // 面试
    {
      path: "LbhInterview",
      name: "LbhInterview",
      component: () =>
        import("@/views/recruit/arrange/ms/InterviewArrange.vue"),
      meta: {
        title: "面试",
        prev: {
          name: "LbToolbox",
          title: "工具",
        },
      },
    },
    // 体检 physicalExamArrange
    {
      path: "LbhPhysical",
      name: "LbhPhysical",
      component: () =>
        import("@/views/recruit/arrange/tj/PhysicalExamArrange.vue"),
      meta: {
        title: "体检",
        prev: {
          name: "LbToolbox",
          title: "工具",
        },
      },
    },
    // 通知 noticeArrange
    {
      path: "LbhNotice",
      name: "LbhNotice",
      component: () => import("@/views/recruit/arrange/tz/NoticeArrange.vue"),
      meta: {
        title: "通知",
        prev: {
          name: "LbToolbox",
          title: "工具",
        },
      },
    },
    // 测评
    {
      path: "LbMatcheasyExam",
      name: "LbMatcheasyExam",
      component: () => import("@/views/staff/exam/IndexViewMatcheasy.vue"),
      meta: {
        title: "测评",
        prev: {
          name: "LbToolbox",
          title: "工具",
        },
      },
    },
    // 渠道
    {
      path: "lbchannelmenu",
      name: "LbChannelMenu",
      component: () => import("@/views/rpo/LbChannelMenu/IndexView.vue"),
    },
    {
      //渠道-职位（首页）
      path: "lbhchannel",
      name: "LbhChaJob",
      component: (resolve) =>
        require(["@/views/rpo/hChannel/index.vue"], resolve),
      meta: {
        auth: true,
        title: "好招",
        prev: {
          name: "LbChannelMenu",
          title: "渠道",
        },
      },
    },
    // 人才库 Talent
    {
      path: "lbtalent",
      name: "LbTalent",
      component: () => import("@/views/rpo/LbTalent/IndexView.vue"),
    },
    // 商城
    {
      path: "lbhzmall",
      name: "LbHzMall",
      component: () => import("@/views/recruit/mall/IndexView.vue"),
    },
    // 统计
    {
      path: "lbresumestatistical",
      name: "LbResumeStatistical",
      component: () => import("@/views/rpo/LbResumeStatistical/IndexView.vue"),
    },
  ],
};

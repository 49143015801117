/*
 * @Description: 360评估等项目相关的页面 主要是详情页
 * @Date: 2023-11-17 16:22:39
 * @LastEditTime: 2023-11-17 17:54:28
 */
import LayoutPg from "@/views/staff/360/detail/main.vue";
import LayoutDc from "@/views/staff/questionnaire/detail/MainView.vue";
import LayoutBm from "@/views/staff/activity/detail/MainView.vue";
import LayoutKs from "@/views/staff/exam/detail/MainView.vue";

export default {
  parent: "AsktrueProject",
  routers: [
    {
      path: "assessment",
      component: LayoutPg,
      name: "AsktrueProjectAssessment",
      redirect: "/project/assessment/evaluate",
    },
    // 问卷调查
    {
      path: "investigation",
      component: LayoutDc,
      name: "investigationQuestionnaire",
      redirect: "/project/investigation/evaluate",
    },
    // 活动吧
    {
      path: "signup",
      component: LayoutBm,
      name: "signupActivity",
      redirect: "/project/signup/evaluate",
    },
    // 考试测评
    {
      path: "exam",
      component: LayoutKs,
      name: "examExam",
      redirect: "/project/exam/evaluate",
    },
  ],
};

export default function (axios) {
  return {
    uploadDiary(params) {
      //日志上传附件
      return axios.upload("/file/uploadCover?fileDirType=diary_file", params);
    },
    uploadNotice(params) {
      //公告上传附件
      return axios.upload("/file/uploadCover?fileDirType=notice_file", params);
    },
  };
}

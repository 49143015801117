export default function (axios) {
  return {
    selectNotice(params) {
      //查询公告列表
      return axios.get("/cNotice/selectPage", { params });
    },
    getNoticeType(params) {
      //查询公告栏目列表
      return axios.post(
        "/cNoticeCol/selectPage?pageNum=" +
          params.pageNum +
          "&" +
          "pageSize=" +
          params.pageSize
      );
    },
    editColumn(params) {
      //编辑公告栏目
      return axios.post("/cNoticeCol/editNoticeCol", params);
    },
    isUseNotice(params) {
      //判断是否被公告用了 用了就不能删除了
      return axios.post(
        "/cNoticeCol/isUseNotice?noticeColId=" + params.noticeColId
      );
    },
    delColumn(params) {
      //批量删除日志栏目 删除之前需要判断是否被公告用过 需调用isUseNotice接口
      return axios.post(
        "/cNoticeCol/deleteNoticeCol?noticeColIds=" + params.noticeColIds
      );
    },
    moveColumn(params) {
      //向上移动或者向下移动公告栏目
      return axios.post("/cNoticeCol/moveNoticeCol", params);
    },
    editNotice(params) {
      //编辑公告
      return axios.post("/cNotice/editNotice", params);
    },
    addNoticeReadRecord(params) {
      //新增访问记录 查询公告详情前需要调用此接口
      return axios.post("/cNotice/addReadRecord?noticeId=" + params.noticeId);
    },
    getNoticeDetail(params) {
      //根据主键查询一条公告的详细信息
      return axios.get(
        "/cNotice/selectByPrimaryKey?noticeId=" + params.noticeId
      );
    },
    delNotice(params) {
      //批量删除公告
      return axios.post("/cNotice/deleteNotice?noticeIds=" + params.noticeIds);
    },
    saveVoteResult(params) {
      //投票结果进行保存
      return axios.post("cNotice/saveVoteResult", params);
    },
    editPublic(params) {
      //将草稿箱变为公告内容
      return axios.post("cNotice/editPublic?noticeId=" + params.noticeId);
    },
    // uploadNotice(params) {
    //   //公告上传附件
    //   return axios.upload("/file/uploadCover?fileDirType=notice_file", params);
    // },
  };
}

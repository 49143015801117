<!--
自定义输入框+下拉框，适用于即可输入也可下拉选择
使用方法
<g-inputSelect
  v-model="search.tab"          //value值
  :list="other.tabList"         //选项
  placeholder="类型"            //提示
  class="searchTab"             //样式
  @text="backLabel"             //返回输入框文字时间
  @confirm="searchTab"          //返回事件
  @show="getOptFn"              //点击加载显示下拉框时触发
/>

import gInputSelect from "@/components/private/g-inputSelect.vue"; //引入
components: {gInputSelect}
-->
<template>
  <div ref="select" class="g-inputSelect no-select">
    <div
      :class="['title', sty, { on: show }, { isDisabled: disabled }]"
      @click="disabled ? '' : clickFn()"
    >
      <el-input
        type="text"
        autocomplete="off"
        v-model="label"
        class="keywords"
        :placeholder="placeholder"
        @input="searchFn"
      >
        <em slot="append" class="el-icon-arrow-up" v-if="opt.length"></em>
      </el-input>
    </div>
    <div class="option-box" v-show="show && opt.length">
      <!-- 不分组选项 -->
      <div class="optBox scroll" v-if="!group">
        <ul class="option">
          <li
            v-for="(SelItem, SelIndex) in opt"
            :key="'selectCustom_' + SelIndex"
            @click="selectFn(SelItem)"
          >
            {{ SelItem.label }}
          </li>
        </ul>
      </div>
      <div class="group scroll" v-else>
        <ul
          class="option scroll"
          v-for="(SelItem, SelIndex) in opt"
          :key="'selectCustom_' + SelIndex"
        >
          <div class="label" v-if="SelItem.label">{{ SelItem.label }}</div>
          <li
            v-for="(SelE, SelI) in SelItem.opt"
            :key="'selectOpt_' + SelI"
            @click="selectFn(SelE)"
          >
            {{ SelE.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-inputSelect",
  props: {
    id: Number,
    value: [String, Number],
    text: String,
    placeholder: String,
    list: Array, //格式[{label:'名称',value:'值'}]
    sty: { type: String, default: "font" }, //样式选项：font文字样式；select下拉框样式
    prefixIcon: String, //前缀图标,默认不显示
    suffixIcon: { type: String, default: "el-icon-arrow-down" }, //后缀图标,默认下拉箭头

    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "38px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      // 是否分组
      type: Boolean,
      default: false,
    },
    isSearch: { type: Boolean, default: false },
    defaultText: String, //值：不限、全部等，未选择时默认的空选项，同时作为判断条件是否存在此类空选项
  },
  data() {
    return {
      show: false,
      label: this.text,
      opt: [],
      key: "",
    };
  },
  created() {
    this.setValue();
  },
  methods: {
    setListFn() {
      this.opt = [];
      if (this.list?.length && typeof this.list[0] == "object") {
        this.opt = JSON.parse(JSON.stringify(this.list));
        if (this.group) {
          this.opt.forEach((e) => {
            if (e?.opt && e?.opt.length) {
              if (typeof e.opt[0] == "string") {
                e.opt = e.opt.map((el) => {
                  return { label: el, value: el };
                });
              }
            }
          });
          console.log(this.opt, "==this.opt");
        }
      } else {
        this.list?.forEach((e) => {
          this.opt.push({ label: e, value: e });
        });
      }
      if (this.defaultText) {
        if (this.group) {
          let opt = {
            label: "",
            opt: [{ value: null, label: this.defaultText }],
          };
          this.opt.unshift(opt);
        } else {
          this.opt.unshift({ label: this.defaultText, value: null });
        }
      }
    },
    setValue() {
      this.setListFn();
      if (this.value) {
        let i = this.opt.findIndex((e) => e.value == this.value);
        if (i > -1) {
          this.label = this.opt.find((e) => {
            return e.value == this.value;
          }).label;
        }
      } else {
        this.label = "";
      }
    },
    selectFn(item) {
      this.label = item.value != null ? item.label : "";
      this.$emit("input", item.value);
      this.$emit("text", this.label);
      this.show = false;
      this.key = "";
      this.setListFn();
      this.$emit("confirm");
    },
    clickFn() {
      this.$emit("show");
      // if (this.opt.length) {
      this.show = !this.show;
      // }
    },
    clear() {
      this.label = "";
      this.$emit("input", "");
    },

    searchFn() {
      this.opt = this.list.filter((e) => e.label.indexOf(this.label) > -1);
      this.$emit("input", "");
      this.$emit("text", this.label);
      if (this.defaultText) {
        if (this.group) {
          let opt = {
            label: null,
            opt: [{ label: this.defaultText, value: null }],
          };
          this.opt.unshift(opt);
        } else {
          this.opt.unshift({ label: this.defaultText, value: null });
        }
      }
    },

    bodyCloseMenus(e) {
      let self = this;
      if (this.$refs.select && !this.$refs.select.contains(e.target)) {
        if (self.bankSwitch == true) {
          self.bankSwitch = false;
        } else {
          this.show = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="less" scoped>
.g-inputSelect {
  position: relative;
  border-radius: 2px;
  .title {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.select {
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      background-color: #fff;
      /deep/.keywords {
        display: block;
        width: 100%;
        height: 100%;
        .el-input__inner {
          display: block;
          width: 100%;
          height: 100%;
          border: 0px !important;
        }
        .el-input-group__append {
          top: 0px !important;
          right: 0px !important;
        }
        .el-icon-arrow-up {
          color: #c0c4cc;
          font-size: 14px;
          transition: transform 0.3s;
          transform: rotateZ(180deg);
          cursor: pointer;
        }
      }
      &.on {
        .el-icon-arrow-up {
          transform: rotateZ(0deg);
        }
      }
    }
    &.isDisabled {
      cursor: no-drop;
      background-color: #f5f7fa;
    }
    .text {
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.on {
        color: #606266;
      }
    }
    .prefixIcon {
      margin-right: 5px;
      color: inherit;
    }
    .suffixIcon {
      position: relative;
      color: #c0c4cc;
      margin-left: 3px;
      // transform: translateY(-50%);
    }
    &.on {
      .suffixIcon {
        transform: rotate(180deg);
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #e0e0e0;
        z-index: 1984;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border: 6px solid transparent;
        border-bottom-color: #fff;
        z-index: 1985;
      }
    }
  }
  .option-box {
    position: absolute;
    top: calc(100% + 10px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0;
    z-index: 1983;
    .group {
      min-width: 150px;
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
      .option {
        .label {
          display: flex;
          align-items: center;
          height: 34px;
          color: #ddd;
          padding: 0 15px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #e4e4e4;
        }
      }
    }
    .optBox {
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
    }

    .option {
      li {
        height: 34px;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 12px;
        padding: 0 15px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }
        &.on {
          color: #39c;
        }
      }
    }
  }
}
</style>

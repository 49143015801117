<template>
  <div class="header-img-item-container" @click.stop="">
    <ul class="header-img-item-ul">
      <li
        class="header-img-item pr"
        v-for="(pItem, pIndex) in headerImgList"
        :key="pIndex"
        @click="change(pIndex)"
      >
        <img
          :src="
            pItem.indexOf('@/') > -1
              ? require('@/assets/image/asktrue/topBg/ast' +
                  (pIndex + 1) +
                  '.jpg')
              : pItem
          "
        />
        <div v-if="activeIndex == pIndex" class="active">
          <i class="el-icon-check"></i>
        </div>
      </li>
      <li class="header-img-item pr">
        <!-- <el-upload :action="apiUrl+'/minIOApi/putObjects?fileDirType=comp_img'" :headers="token" :on-success="handleSuccess">
          <i class="el-icon-plus" style="line-height: 20px;"></i>
        </el-upload> -->
        <i class="el-icon-plus" style="line-height: 20px"></i>
        <input
          type="file"
          class="uplode"
          ref="uploadFile"
          accept="image/*"
          @change="uplodeImg"
        />
      </li>
    </ul>
    <p class="notes f12">注：建议高度270px，宽度900px。</p>
    <ImgCropper
      v-if="cropImageDialog"
      :options="{ imgUrl: upload, fixedNumber: [10, 3] }"
      v-on:childEvent="handleSuccess"
    />
  </div>
</template>

<script>
import { apiUrl1 } from "@/api/asktrue/config";
import ImgCropper from "../ImgCropper"; //图片剪裁弹窗
export default {
  props: ["headerImg"],
  components: { ImgCropper },
  data() {
    return {
      activeIndex: 0,
      headerImgList: [
        "@/assets/image/asktrue/topBg/ast1.jpg",
        "@/assets/image/asktrue/topBg/ast2.jpg",
        "@/assets/image/asktrue/topBg/ast3.jpg",
        "@/assets/image/asktrue/topBg/ast4.jpg",
        "@/assets/image/asktrue/topBg/ast5.jpg",
        "@/assets/image/asktrue/topBg/ast6.jpg",
        "@/assets/image/asktrue/topBg/ast7.jpg",
        "@/assets/image/asktrue/topBg/ast8.jpg",
        "@/assets/image/asktrue/topBg/ast9.jpg",
        "@/assets/image/asktrue/topBg/ast10.jpg",
        "@/assets/image/asktrue/topBg/ast11.jpg",
      ],
      token: {},
      apiUrl: apiUrl1,
      cropImageDialog: false,
      upload: "",
    };
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    setTimeout(() => {
      if (this.headerImg) {
        if (this.headerImgList.indexOf(this.headerImg) == -1) {
          this.activeIndex = this.headerImgList.length;
          this.headerImgList.push(this.headerImg);
        } else {
          this.activeIndex = this.headerImgList.indexOf(this.headerImg);
        }
      } else {
        this.headerImg = "@/assets/image/asktrue/topBg/ast1.jpg";
        this.activeIndex = 0;
      }
    }, 200);
  },
  methods: {
    //上传封面
    uplodeImg(e) {
      this.upload = window.URL.createObjectURL(e.target.files[0]);
      this.cropImageDialog = true;
      this.$refs.uploadFile.value = null;
    },
    handleSuccess(file) {
      // if(res.code == '200') {
      this.cropImageDialog = false;
      if (file) {
        this.headerImgList[11] = file;
        this.change(11);
      }
      // }
    },
    change(index) {
      this.activeIndex = index;
      this.$emit("dealChange", this.headerImgList[index], "headerImg");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list {
  display: none;
}
.header-img-item-container {
  background: #f2f2f2;
  padding: 15px;
  .notes {
    line-height: 20px;
    text-align: left;
    padding: 10px 0;
    color: #ccc;
  }
  &:hover .notes {
    color: #f60;
  }
  .header-img-item-ul {
    display: flex;
    flex-wrap: wrap;
    .header-img-item {
      width: 47%;
      height: 22px;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      margin: 8px auto;
      margin-left: 0px;
      line-height: normal;
      background-color: #fff;
      &:nth-child(2n-1) {
        margin-right: 6%;
      }
      .active {
        background: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        i {
          background: #fff;
          border-radius: 10px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: #39c;
          font-size: 18px;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}
</style>

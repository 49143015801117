export default function (axios) {
  return {
    // 获取公司信息
    // export const getCompanyInfo = (data) => getReq(apiUrl+'/compMemberCtl/selectCompMemberById',data);

    // //个人-求职-投递
    // export const getPostRelListReq = data => getReq(apiUrl+"/resumePostRelCtl/selectResumeInfoPage",data)//投递分页查询
    // export const getBonusRelListReq = data => getReq(apiUrl+"/resumePostRelCtl/selectBonusJobInfoPage",data)//内推职位分页查询
    // export const getMatchRelListReq = data => getReq(apiUrl+"/resumePostRelCtl/selectMatchJob",data)//匹配职位分页查询

    // //删除投递接口调整
    // export const updateResumePostRelStatusReq = data => postReq(apiUrl+"/resumePostRelCtl/updateResumePostRelStatus?channelId="+data.channelId+"&status="+data.status,data,{ContentType:'application/json;charset=UTF-8'})

    // export function delRelListReq(typeUrl,data) {//删除内推、投递职位记录
    //   let url='/resumePostRelCtl/update'+typeUrl
    //   return postReq(apiUrl+url+"?relId="+data.relId+"&"+data.status,data,{ContentType:'application/json;charset=UTF-8'})
    // }
    // //个人-求职-投递end

    // //个人-内推-推荐分页查询
    // export const getBonusResumeListReq = data => getReq(apiUrl+"/bonuseResumeCtl/selectBonusResumeInfoPage",data)
    // //个人-内推-奖金分页查询
    // //export const getRewardResumeListReq = data => getReq(apiUrl+"/rewardOrder/selectRewardOrderPage",data)
    // export const getSelectBonusAwardReq = data => getReq(apiUrl+"/bonuseResumeCtl/selectBonusAward",data)

    // //绑定个人邮箱
    // export const confirmMobileCode = data => postReq(apiUrl+"/userMember/confirmMobileCode",data)
    // //绑定个人邮箱
    // export const bindEmail = data => postReq(apiUrl+"/userMember/bindEmail",data)
    // //修改个人密码
    // export const updatePassWord = data => postReq(apiUrl+"/userMember/updatePassWord",data)

    // //查询是否有关联账户
    // export const checkRelativeAccount = data => getReq(apiUrl+"/userMember/updatePasswordBefore",data)
    checkRelativeAccount(params) {
      return axios.get("/userMember/updatePasswordBefore", { params });
    },
    // //个人会员查询
    // export const getPerInfoReq = data => getReq(apiUrl+"/userMember/selectById",data)
    // //个人会员修改
    // export const updateUserMember = data => postReq(apiUrl+"/userMember/updateUserMember",data,{ContentType:'application/json;charset=UTF-8'})

    // //账户列表查询
    // export const selectPersonAccountPage = data => getReq(apiUrl+"/orderCtl/selectPersonAccountPage",data,{ContentType:'application/json;charset=UTF-8'})
    // //账户消费信息查询
    // // export const selectPersonOrderInfo = data => getReq(apiUrl+"/iPayAccount/selectAccount",data,{ContentType:'application/json;charset=UTF-8'})

    // //个人订单查询
    // export const selectPersonOrderPage = data => getReq(apiUrl+"/orderCtl/selectPersonOrderPage",data,{ContentType:'application/json;charset=UTF-8'})

    // //个人发票查询
    // export const selectReceiptInfoPerson= data => getReq(apiUrl+"/cReceiptInfoCtl/selectReceiptInfoPerson",data)
    // //新增发票信息查询
    // export const insertReceiptInfoPerson   = data => postReq(apiUrl+"	/cReceiptInfoCtl/insertReceiptInfoPerson",data,{ContentType:'application/json;charset=UTF-8'})
    // //修改发票信息
    // export const updateReceiptInfoPerson= data => postReq(apiUrl+"/cReceiptInfoCtl/updateReceiptInfoPerson",data,{ContentType:'application/json;charset=UTF-8'})
    // //删除发票信息、设为默认
    // export const updateStatusPerson = data => postReq(apiUrl+"/cReceiptInfoCtl/updateStatusPerson",data)
    // //删除邮寄地址
    // export const updateEmailStatus = data => postReq(apiUrl+"/cReceiptMailInfoCtl/updateEmailStatus",data)
    // //删除个人邮寄地址
    // export const updateMailStatusPerson= data => postReq(apiUrl+"/cReceiptMailInfoCtl/updateMailStatusPerson",data)
    // //修改邮寄地址保存
    // export const updateEmailInfoPerson  = data => postReq(apiUrl+"/cReceiptMailInfoCtl/updateEmailInfoPerson",data,{ContentType:'application/json;charset=UTF-8'})
    // //保存发票新增邮寄地址
    // export const insertMailInfoPerson = data => postReq(apiUrl+"/cReceiptMailInfoCtl/insertMailInfoPerson",data,{ContentType:'application/json;charset=UTF-8'})
    // //邮寄地址查询
    // export const selectMailInfoPerson= data => getReq(apiUrl+"/cReceiptMailInfoCtl/selectMailInfoPerson",data)
  };
}

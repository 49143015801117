// 两列拖动改变两列宽度
export function dragTwoColDiv(contentId, leftBoxId, resizeId) {
  let resize = document.getElementById(resizeId);
  let leftBox = document.getElementById(leftBoxId);
  // let rightBox = document.getElementById(rightBoxId);
  let box = document.getElementById(contentId);
  resize.onmousedown = function (e) {
    let startX = e.clientX;
    resize.left = resize.offsetLeft;
    document.onmousemove = function (e) {
      let endX = e.clientX;
      let moveLen = resize.left + (endX - startX);
      let maxT = box.clientWidth - resize.offsetWidth;
      if (moveLen < 300) moveLen = 300;
      if (moveLen > maxT - 300) moveLen = maxT - 300;
      resize.style.left = moveLen;
      // leftBox.style.width = moveLen + 'px';
      leftBox.style.width = box.clientWidth - moveLen - 5 + "px";
      // rightBox.style.width = (box.clientWidth - moveLen - 5) + 'px';
    };
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
      resize.releaseCapture && resize.releaseCapture();
    };
    resize.setCapture && resize.setCapture();
    return false;
  };
}

// 上下拖动改变上下两个模块的高度
export function dragTwoRowDiv(contentId, topBoxId, resizeId) {
  let resize = document.getElementById(resizeId);
  let topBox = document.getElementById(topBoxId);
  let box = document.getElementById(contentId);
  resize.onmousedown = function (e) {
    let startY = e.clientY;
    resize.top = resize.offsetTop;
    document.onmousemove = function (e) {
      let endY = e.clientY;
      let moveLen = resize.top + (endY - startY) - 60;
      let maxT = box.clientHeight - resize.offsetHeight;
      if (moveLen < 200) moveLen = 200;
      if (moveLen > maxT - 200) moveLen = maxT - 200;
      resize.style.top = moveLen;
      topBox.style.height = moveLen + "px";
    };
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
      resize.releaseCapture && resize.releaseCapture();
    };
    resize.setCapture && resize.setCapture();
    return false;
  };
}

/*
 * @Description: paper.js
 * @Date: 2023-11-20 10:09:47
 * @LastEditTime: 2024-01-09 09:57:01
 */
import requestProject from "@/assets/plugins/asktrue/request-project"; // project 实例
import requestPaper from "@/assets/plugins/asktrue/request-paper"; // paper 实例
import requestUser from "@/assets/plugins/asktrue/request-user"; // user 实例
import qs from "qs"; // 序列化

// //问卷新增和编辑
// export const editQuesNair = (data) =>
//   postReq(apiUrl2 + "/paperCtl/editPaper", data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
export function editQuesNair(data) {
  return requestPaper({
    url: "/paperCtl/editPaper",
    method: "post",
    data,
  });
}

// //问卷列表
// export const getQuesList = (data) =>
//   postReq(apiUrl2 + "/paperCtl/selectPaperCount", data, {
//     showLoading: true,
//     loadingTarget: ".console-main",
//   });
export function getQuesList(data) {
  return requestPaper({
    url: "/paperCtl/selectPaperCount",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷订单列表
// export const getQuesOrderList = (data) =>
//   getReq(apiUrl2 + "/paperOrderCtl/selectPaperOrderListPage", data, {
//     showLoading: true,
//     loadingTarget: ".console-main",
//   });
// // 管理员问卷标签
// export const getPaperLabelListPage = (data) =>
//   getReq(apiUrl1 + "/anon/selectPaperLabelListPage", data);
export function getPaperLabelListPage(params) {
  return requestUser({
    url: "/anon/selectPaperLabelListPage",
    method: "get",
    params,
  });
}

// // 问卷信息
// export const selectPaperByPaperId = (data) =>
//   getReq(apiUrl2 + "/paperCtl/selectPaperByPaperId", data);
export function selectPaperByPaperId(params) {
  return requestPaper({
    url: "/paperCtl/selectPaperByPaperId",
    method: "get",
    params,
  });
}

// export const selectPaperByPaperIdNose = (data) =>
//   getReq(apiUrl2 + "/paperCtl/selectPaperByPaperIdNose", data);
export function selectPaperByPaperIdNose(params) {
  return requestPaper({
    url: "/paperCtl/selectPaperByPaperIdNose",
    method: "get",
    params,
  });
}

// // 删除问卷
// export const changePaperStatusByPaperId = (data) =>
//   postReq(apiUrl2 + "/paperCtl/deletePaperByPaperId", data);
export function changePaperStatusByPaperId(data) {
  return requestPaper({
    url: "/paperCtl/deletePaperByPaperId",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 查询存在问卷的项目
// export const selectProHasPaperReq = (data) =>
//   postReq(apiUrl3 + "/project/selectProjectExam", data);
export function selectProHasPaperReq(params) {
  return requestProject({
    url: "/project/selectProjectExam",
    method: "post",
    params,
  });
}
// // 获取问卷商城列表
// export const selectPaperPage = (data) =>
//   getReq(apiUrl2 + "/anon/paperCtl/selectPaperPage", data);
export function selectPaperPage(params) {
  return requestPaper({
    url: "/anon/paperCtl/selectPaperPage",
    method: "get",
    params,
  });
}

// // 获取问卷详情
// export const selectAnonPaperById = (data) =>
//   getReq(apiUrl2 + "/anon/paperCtl/selectPapertById", data);
export function selectAnonPaperById(params) {
  return requestPaper({
    url: "/anon/paperCtl/selectPapertById",
    method: "get",
    params,
  });
}

// // 商城问卷详情
// export const selectPapertById = (data) =>
//   getReq(apiUrl2 + "/paperCtl/selectPapertById", data);
// // 收藏问卷
// export const collectPaper = (data) =>
//   postReq(apiUrl2 + "/paperCtl/collectProject", data);
export function collectPaper(data) {
  return requestPaper({
    url: "/paperCtl/collectProject",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 获取问卷评论
// export const selectCommentByPaperId = (data) =>
//   getReq(apiUrl2 + "/paperCtl/selectCommentByPaperId", data);
export function selectCommentByPaperId(params) {
  return requestPaper({
    url: "/paperCtl/selectCommentByPaperId",
    method: "get",
    params,
  });
}

// // 新增评论
// export const insertCommentToBuyer = (data) =>
//   postReq(apiUrl2 + "/paperCtl/insertCommentToBuyer", data);
export function insertCommentToBuyer(data) {
  return requestPaper({
    url: "/paperCtl/insertCommentToBuyer",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const insertCommentReply = (data) =>
//   postReq(apiUrl2 + "/paperCtl/updateCommentToReply", data);
export function insertCommentReply(data) {
  return requestPaper({
    url: "/paperCtl/updateCommentToReply",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷-信息项
// export const postQuesInfoReq = (data) =>
//   uplodeReq(apiUrl2 + "/paperInfo/editPaperInfo", data); //新增编辑
// export const getQuesInfoReq = (data) =>
//   postReq(apiUrl2 + "/paperInfo/selectByInfoId", data); //查询详情

// export const delQuesInfoReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/deletePaperInfo", data); //批量删除问卷信息项
export function delQuesInfoReq(data) {
  return requestPaper({
    url: "/paperQuestion/deletePaperInfo",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

export function newPostQuesInfoReq(data) {
  return requestProject({
    url: `/projectExamPaperInfo/saveInfo?examId=${data.examId}&paperId=${data.paperId}&paperTypeId=${data.paperTypeId}&projectType=${data.projectType}`,
    method: "post",
    data: data.examInfoList,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

export function newGetQuesInfoReq(params) {
  return requestProject({
    url: "/projectExamPaperInfo/selectInfo",
    method: "get",
    params,
  });
}

// export const newDelQuesInfoReq = (data) =>
//   delReq(apiUrl3 + "/projectExamPaperInfo/deteleInfo", data); //关闭必填项/删除一个必填项-新
export function newDelQuesInfoReq(params) {
  return requestProject({
    url: "/projectExamPaperInfo/deteleInfo",
    method: "delete",
    params,
  });
}

// export const newSelectOptionReq = (data) =>
//   getReq(apiUrl3 + "/projectExamPaperInfo/selectOption", data); //必填项下拉项查询-新

// //问卷-试题
// // export const editPaperQuesDtReq = data => postReq(apiUrl2+"/paperQuestion/editPaperQuestionForDongtai",data, 'application/json')//新增或者编辑动态问卷试题（作废）
// export const editPaperQuesDtReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/editPaperBasicRandom", data); //新增或者编辑动态问卷试题
export function editPaperQuesDtReq(data) {
  return requestPaper({
    url: "/paperQuestion/editPaperBasicRandom",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const editPaperQuesReq = (data) =>
//   uplodeReq(apiUrl2 + "/paperQuestion/editPaperQuestionForStatic", data); //新增或者编辑静态问卷试题
export function editPaperQuesReq(data) {
  return requestPaper({
    url: "/paperQuestion/editPaperQuestionForStatic",
    method: "post",
    data,
  });
}

// export const getQuesNumQuq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/selectCountByRule", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //根据动态规则查询试题数量
export function getQuesNumQuq(data) {
  return requestPaper({
    url: "/paperQuestion/selectCountByRule",
    method: "post",
    data,
  });
}

// export const getDynamicQuesList = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/selectDongtaiPaperQuestion", data); //根据动态规则查询试题数量
export function getDynamicQuesList(data) {
  return requestPaper({
    url: "/paperQuestion/selectDongtaiPaperQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const insertGuide = (data) =>
//   postReq(apiUrl2 + "/paperCtl/insertGuide", data); //插入指导语
export function insertGuide(data) {
  return requestPaper({
    url: "/paperCtl/insertGuide",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getPaperQuesListReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/selectStaticPaperQuestion", data, {
//     showLoading: data.showLoading !== undefined ? data.showLoading : true,
//     loadingTarget: data.loadClass ? data.loadClass : ".paper-wrap",
//   }); //静态问卷试题列表

export function getPaperQuesListReq(data) {
  return requestPaper({
    url: "/paperQuestion/selectStaticPaperQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getPaperQuesListReqNose = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/selectStaticPaperQuestionNose", data, {
//     showLoading: data.showLoading !== undefined ? data.showLoading : true,
//     loadingTarget: data.loadClass ? data.loadClass : ".paper-wrap",
//   }); //静态问卷试题列表

// export const updatePaperQuesListReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/updatePaperQuestionSort", data); //静态问卷试题功能栏进行排序
export function updatePaperQuesListReq(data) {
  return requestPaper({
    url: "/paperQuestion/updatePaperQuestionSort",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //批量删除问卷试题以及题组
// export const delPaperQuesReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/deletePaperQuestion", data);
export function delPaperQuesReq(data) {
  return requestPaper({
    url: "/paperQuestion/deletePaperQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //批量保存问卷试题样式
// export const editPaperStyleReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/editStyle", data);
export function editPaperStyleReq(data) {
  return requestPaper({
    url: "/paperQuestion/editStyle",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //通过问卷id以及矩阵试题样式id查询矩阵样式的详细信息
// export const getMatrixQuesDataReq = (data) =>
//   postReq(
//     apiUrl2 + "/paperQuestion/selectQuestionByPaperQuestionGroupId",
//     data
//   );
export function getMatrixQuesDataReq(data) {
  return requestPaper({
    url: "/paperQuestion/selectQuestionByPaperQuestionGroupId",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //批量修改矩阵样式试题
// export const editMatrixQuesReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/editJuzhenStyle", data);
export function editMatrixQuesReq(data) {
  return requestPaper({
    url: "/paperQuestion/editJuzhenStyle",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 试题 & 问卷 引用信息
// export const selectQuoteReq = (data) =>
//   postReq(
//     apiUrl2 + `/paperQuestion/selectQuestionQuote/${data.flag}/${data.targetId}`
//   );
export function selectQuoteReq(data) {
  return requestPaper({
    url: `/paperQuestion/selectQuestionQuote/${data.flag}/${data.targetId}`,
    method: "post",
    data,
  });
}

// //移动
// export const movePaperExplainReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/movePaperExplain", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //上移或者下移问卷说明
export function movePaperExplainReq(data) {
  return requestPaper({
    url: "/paperQuestion/movePaperExplain",
    method: "post",
    data,
  });
}

// export const movePaperInfoReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/movePaperInfo", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //静态试卷信息项上移下移
export function movePaperInfoReq(data) {
  return requestPaper({
    url: "/paperQuestion/movePaperInfo",
    method: "post",
    data,
  });
}

// export const newMovePaperInfoReq = (data) =>
//   putReq(
//     apiUrl3 +
//       "/projectExamPaperInfo/updateOrder?examId=" +
//       data.examId +
//       "&infoId1=" +
//       data.infoId1 +
//       "&infoId2=" +
//       data.infoId2 +
//       "&paperId=" +
//       data.paperId +
//       "&paperTypeId=" +
//       data.paperTypeId +
//       "&projectType=" +
//       data.projectType
//   ); //必填项上下移-新
export function newMovePaperInfoReq(data) {
  return requestProject({
    url: `/projectExamPaperInfo/updateOrder?examId=${data.examId}&infoId1=${data.infoId1}&paperId2=${data.infoId2}&paperId=${data.paperId}&paperTypeId=${data.paperTypeId}&projectType=${data.projectType}`,
    method: "put",
  });
}

// export const movePaperQuesReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/movePaperQuestion", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //上移或者下移问卷试题，或者题组
export function movePaperQuesReq(data) {
  return requestPaper({
    url: "/paperQuestion/movePaperQuestion",
    method: "post",
    data,
  });
}

// export const insertPaperQuestionReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/insertPaperQuestion", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //拖拽排序问卷试题，或者题组
export function insertPaperQuestionReq(data) {
  return requestPaper({
    url: "/paperQuestion/insertPaperQuestion",
    method: "post",
    data,
  });
}

// export const dragPaperExplainReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/dragPaperExplain", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //拖拽排序问卷说明
export function dragPaperExplainReq(data) {
  return requestPaper({
    url: "/paperQuestion/dragPaperExplain",
    method: "post",
    data,
  });
}

// //答案设置
// export const getPaperScoreReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionScore/selectByPaperIdNose", data); //查询问卷下面的试题答案设置
export function getPaperScoreReq(data) {
  return requestPaper({
    url: "/paperQuestionScore/selectByPaperIdNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const selectByQuestionIdsReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionScore/selectByQuestionIds", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //阅卷-查询动态问卷下面的试题答案设置
export function selectByQuestionIdsReq(data) {
  return requestPaper({
    url: "/paperQuestionScore/selectByQuestionIds",
    method: "post",
    data,
  });
}

// export const editPaperScoreReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionScore/editPaperQuestionScore", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //编辑答案设置
export function editPaperScoreReq(data) {
  return requestPaper({
    url: "/paperQuestionScore/editPaperQuestionScore",
    method: "post",
    data,
  });
}

// export const editDtPaperScoreReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionScore/editDongtaiScore", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //动态问卷设置试题分数
export function editDtPaperScoreReq(data) {
  return requestPaper({
    url: "/paperQuestionScore/editDongtaiScore",
    method: "post",
    data,
  });
}

// export const getDtPaperScoreReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionScore/selectQuestionScore", data); //查询动态问卷试题答案设置
export function getDtPaperScoreReq(data) {
  return requestPaper({
    url: "/paperQuestionScore/selectQuestionScore",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷-说明
// //export const delQuesExpReq = data => postReq(apiUrl2+"/paperQuestionExplain/deleteById",data)//删除问卷说明

// export const delQuesExpReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestion/deletePaperExplain", data); //批量删除问卷说明
export function delQuesExpReq(data) {
  return requestPaper({
    url: "/paperQuestion/deletePaperExplain",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const editQuesExpReq = (data) =>
//   postReq(apiUrl2 + "/paperQuestionExplain/editPaperQuestionExplain", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //新增或者编辑问卷说明
export function editQuesExpReq(data) {
  return requestPaper({
    url: "/paperQuestionExplain/editPaperQuestionExplain",
    method: "post",
    data,
  });
}

// export const selectPaperCountReq = (data) =>
//   postReq(
//     apiUrl2 +
//       "/paperQuestionExplain/selectPaperCount?pageNum=" +
//       data.pageNum +
//       "&pageSize=" +
//       data.pageSize +
//       "&searchText=" +
//       data.searchText
//   ); // 查询当前公司下的所有问卷说明;
export function selectPaperCountReq(data) {
  return requestPaper({
    url:
      "/paperQuestionExplain/selectPaperCount?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&searchText=" +
      data.searchText,
    method: "post",
  });
}

// // 问卷计分
// export const editPaperScoringInfo = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/editPaperScoringInfo", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //新增或者编辑计分项
export function editPaperScoringInfo(data) {
  return requestPaper({
    url: "/paperScoringCtl/editPaperScoringInfo",
    method: "post",
    data,
  });
}

// export const editPaperScoringType = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/editPaperScoringType", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //新增或者编辑计分信息项
export function editPaperScoringType(data) {
  return requestPaper({
    url: "/paperScoringCtl/editPaperScoringType",
    method: "post",
    data,
  });
}

// export const updatePaperScoringSetStatus = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/updatePaperScoringSetStatus", data); //删除计分信息项（支持批量）
export function updatePaperScoringSetStatus(data) {
  return requestPaper({
    url: "/paperScoringCtl/updatePaperScoringSetStatus",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const selectPaperInfoComboBox = (data) =>
//   getReq(apiUrl2 + "/paperInfo/selectPaperInfoComboBox", data); //查询添加计分项时所设置的问卷信息项
export function selectPaperInfoComboBox(params) {
  return requestPaper({
    url: "/paperInfo/selectPaperInfoComboBox",
    method: "get",
    params,
  });
}

// export const updatePaperScoringType = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/updatePaperScoringType", data); //删除标准设置的信息项
export function updatePaperScoringType(data) {
  return requestPaper({
    url: "/paperScoringCtl/updatePaperScoringType",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaulSum = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaulSum", data); //默认总分的计分设置
export function defaulSum(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaulSum",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaulType = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaulType", data); //默认题型的计分设置
export function defaulType(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaulType",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaultSubject = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaultSubject", data); //默认科目的计分设置
export function defaultSubject(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaultSubject",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaulSumG = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaulSum4", data); //默认总分的计分设置
export function defaulSumG(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaulSum4",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaulTypeG = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaulType4", data); //默认题型的计分设置
export function defaulTypeG(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaulType4",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const defaultSubjectG = (data) =>
//   postReq(apiUrl2 + "/paperScoringCtl/defaultSubject4", data); //默认科目的计分设置
export function defaultSubjectG(data) {
  return requestPaper({
    url: "/paperScoringCtl/defaultSubject4",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getPaperScoringList = (data) =>
//   getReq(apiUrl2 + "/paperScoringCtl/selectPaperScoringList", data); //查询计分项列表
export function getPaperScoringList(params) {
  return requestPaper({
    url: "/paperScoringCtl/selectPaperScoringList",
    method: "get",
    params,
  });
}

// export const getPaperIdList = (data) =>
//   getReq(apiUrl2 + "/paperScoringCtl/selectQuesIdAndSort", data); //查询计分项列表
export function getPaperIdList(params) {
  return requestPaper({
    url: "/paperScoringCtl/selectQuesIdAndSort",
    method: "get",
    params,
  });
}

// // 问卷外观
// export const selectPaperStyleSetByPaperId = (data) =>
//   postReq(apiUrl2 + "/paperStyleSetCtl/selectPaperStyleSetByPaperId", data); //查询问卷外观
export function selectPaperStyleSetByPaperId(data) {
  return requestPaper({
    url: "/paperStyleSetCtl/selectPaperStyleSetByPaperId",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const selectPaperStyleSetByPaperIdNose = (data) =>
//   postReq(apiUrl2 + "/paperStyleSetCtl/selectPaperStyleSetByPaperIdNose", data); //查询问卷外观
export function selectPaperStyleSetByPaperIdNose(data) {
  return requestPaper({
    url: "/paperStyleSetCtl/selectPaperStyleSetByPaperIdNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const editPaperStyleSet = (data) =>
//   postReq(apiUrl2 + "/paperStyleSetCtl/editPaperStyleSet", data, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //保存微卷外观
export function editPaperStyleSet(data) {
  return requestPaper({
    url: "/paperStyleSetCtl/editPaperStyleSet",
    method: "post",
    data,
  });
}

// //问卷预览试题
// export const getPreviewPaperQuesReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/previewPaper", data, {
//     showLoading: data.showLoading != undefined ? data.showLoading : true,
//     loadingTarget: data.loadClass ? data.loadClass : ".paper-wrap",
//   });
export function getPreviewPaperQuesReq(data) {
  return requestPaper({
    url: "/paperCtl/previewPaper",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getPreviewPaperQuesReqNose = (data) =>
//   postReq(apiUrl2 + "/paperCtl/previewPaperNose", data, {
//     showLoading: data.showLoading != undefined ? data.showLoading : true,
//     loadingTarget: data.loadClass ? data.loadClass : ".paper-wrap",
//   });
export function getPreviewPaperQuesReqNose(data) {
  return requestPaper({
    url: "/paperCtl/previewPaperNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷报告
// export const getPaperReportReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/selectReportByPaperId", data); //查询问卷下面的报告
export function getPaperReportReq(data) {
  return requestPaper({
    url: "/paperCtl/selectReportByPaperId",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const addRepotForPaperReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/insertRepotForPaper", data); //批量添加报告到问卷
export function addRepotForPaperReq(data) {
  return requestPaper({
    url: "/paperCtl/insertRepotForPaper",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const delPaperReportReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/deletePaperReport", data); //删除问卷下面的报告
export function delPaperReportReq(data) {
  return requestPaper({
    url: "/paperCtl/deletePaperReport",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //分享
// export const getShareInfoReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/selectProjectWxShareNose", data); //查询
export function getShareInfoReq(data) {
  return requestPaper({
    url: "/paperCtl/selectProjectWxShareNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const saveShareInfoReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/editWxShare", data); //保存
export function saveShareInfoReq(data) {
  return requestPaper({
    url: "/paperCtl/editWxShare",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷试题分页数设置
// export const savePaperPageReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/editPaperPageCount", data); //保存
export function savePaperPageReq(data) {
  return requestPaper({
    url: "/paperCtl/editPaperPageCount",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 问卷模板
// export const selectReportTempByPaperId = (data) =>
//   getReq(apiUrl3 + "/project/paper/selectReportTempByPaperId", data);
export function selectReportTempByPaperId(params) {
  return requestProject({
    url: "/project/paper/selectReportTempByPaperId",
    method: "get",
    params,
  });
}

// // 问卷套餐
// export const selectPaperComboList = (data) =>
//   getReq(apiUrl3 + "/project/paper/selectPaperComboList", data); //查询
export function selectPaperComboList(params) {
  return requestProject({
    url: "/project/paper/selectPaperComboList",
    method: "get",
    params,
  });
}

// export const updatePaperComboStatus = (data) =>
//   getReq(apiUrl3 + "/project/paper/updatePaperComboStatus", data); //删除
export function updatePaperComboStatus(params) {
  return requestProject({
    url: "/project/paper/updatePaperComboStatus",
    method: "get",
    params,
  });
}

// //问卷试题必答
// export const editMustQuestionReq = (data) =>
//   postReq(apiUrl2 + "/paperCtl/editMustQuestion", data); //删除
export function editMustQuestionReq(data) {
  return requestPaper({
    url: "/paperCtl/editMustQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //问卷是否有图片
// export const paperHasImg = (data) =>
//   getReq(apiUrl2 + "/paperCtl/isPaperPic", data);
export function paperHasImg(params) {
  return requestPaper({
    url: "/paperCtl/isPaperPic",
    method: "get",
    params,
  });
}

// //查询问卷有无答题记录
// export const paperHasAsReq = (data) =>
//   getReq(apiUrl3 + "/project/score/selectHasAnswerByPaperIdNose", data);
export function paperHasAsReq(params) {
  return requestProject({
    url: "/project/score/selectHasAnswerByPaperIdNose",
    method: "get",
    params,
  });
}

// //阅卷设置
// export const selectPaperQuestion = (data) =>
//   postReq(apiUrl2 + "/paperMarking/selectPaperQuestion", data); //分题型获取未分part的试题
export function selectPaperQuestion(data) {
  return requestPaper({
    url: "/paperMarking/selectPaperQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const selectPaperPartQuestion = (data) =>
//   postReq(apiUrl2 + "/paperMarking/selectPaperPartQuestion", data); //分题型获取未分part的试题
export function selectPaperPartQuestion(data) {
  return requestPaper({
    url: "/paperMarking/selectPaperPartQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const addPaperPart = (data) =>
//   postReq(apiUrl2 + "/paperMarking/addPaperPart", data); //新增part
export function addPaperPart(data) {
  return requestPaper({
    url: "/paperMarking/addPaperPart",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const removePaperPart = (data) =>
//   postReq(apiUrl2 + "/paperMarking/removePaperPart", data); //删除part
export function removePaperPart(data) {
  return requestPaper({
    url: "/paperMarking/removePaperPart",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const addPaperPartQuestion = (data) =>
//   postReq(
//     apiUrl2 +
//       "/paperMarking/addPaperPartQuestion?paperId=" +
//       data.paperId +
//       "&partId=" +
//       data.partId +
//       "",
//     data.questionList,
//     { ContentType: "application/json;charset=UTF-8" }
//   ); //新增part下试题
export function addPaperPartQuestion(data) {
  return requestPaper({
    url:
      "/paperMarking/addPaperPartQuestion?paperId=" +
      data.paperId +
      "&partId=" +
      data.partId +
      "",
    method: "post",
    data: data.questionList,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const removePaperPartQuestion = (data) =>
//   postReq(apiUrl2 + "/paperMarking/removePaperPartQuestion", data); //删除part下试题
export function removePaperPartQuestion(data) {
  return requestPaper({
    url: "/paperMarking/removePaperPartQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const updatePartQuestionSort = (data) =>
//   postReq(apiUrl2 + "/paperMarking/updatePartQuestionSort", data.list, {
//     ContentType: "application/json;charset=UTF-8",
//   }); //问卷重新排序后同步更改part中的题目号
export function updatePartQuestionSort(data) {
  return requestPaper({
    url: "/paperMarking/updatePartQuestionSort",
    method: "post",
    data: data.list,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

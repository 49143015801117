<!-- 视频播放组件
  内包含：视频播放、章节信息、随堂笔记、收藏、分享、评论
  除视频播放外，其他信息均可控制是否显示
-->
<template>
  <div class="g-play" :style="'height:' + h + 'px'">
    <div class="play-main">
      <!-- 当前播放章节名称及学习进度 -->
      <!-- <div class="play-head">
          <g-font text="人力资源管理" class="name" />
          <div class="info">
            <span>正在学习：</span>
            <i>2课时</i>
            <span>/</span>
            <span>6课时</span>
            <g-icon
              name="icongengduo"
              size="20px"
              title="查看章节目录"
              @click.native="moduFn('chapter')"
            />
          </div>
        </div> -->
      <!-- 视频播放器 -->
      <div class="play-video">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :options="playerOptions"
          @timeupdate="onTimeUpdate"
          @play="onPlayerPlay"
          v-if="play.isLoad"
        ></video-player>
        <div class="video-empty" v-else>
          <img :src="pic" v-if="pic" />
        </div>
      </div>
      <!-- 播放器底部功能 -->
      <div class="play-foot" v-if="!isCreate && this.from != 'classDetail'">
        <div class="item">
          <!-- iconxing1 -->
          <g-icon
            :name="collect?.collectType == 1 ? 'iconxing1' : 'iconxing0'"
            :color="collect?.collectType == 1 ? '#ff9f00' : ''"
            text="收藏"
            gap="5px"
            @click.native="collectFn"
          />
          <g-icon
            name="iconout"
            text="分享"
            size="13px"
            gap="5px"
            @click.native="shareFn"
          />
          <g-comment from="course" :id="courseId" @save="commentFn" />
        </div>
        <div class="item">
          <g-icon
            name="iconzan"
            :text="collect?.likeCount || 0"
            gap="5px"
            title="赞"
            @click.native="likeFn(1)"
          />
          <g-icon
            name="iconzan"
            :text="collect?.hateCount || 0"
            gap="5px"
            title="踩"
            class="fan"
            @click.native="likeFn(2)"
          />
        </div>
        <div class="item">
          <!-- <g-icon
              name="iconfankui"
              text="课堂笔记"
              size="14px"
              gap="5px"
              @click.native="moduFn('note')"
            /> -->
        </div>
      </div>
    </div>

    <!-- 右侧信息 -->
    <div class="play-info" v-if="modu.show">
      <g-icon
        name="iconcuo"
        size="20"
        color="#999"
        class="close"
        @click="modu.show = false"
      />
      <g-tab v-model="modu.value" :tabs="tabs" />
      <!-- 章节模块 -->
      <div class="module chapter no-scroll" v-show="modu.value == 'chapter'">
        <!-- 章节列表 -->
        <g-chapter
          ref="chapterList"
          v-model="id"
          :courseId="courseId"
          from="play"
          v-if="courseId"
        />
        <!-- @click="playFn" -->
      </div>
      <!-- 笔记模块 -->
      <div class="module note" v-show="modu.value == 'note'">
        <div ref="note" class="list no-scroll">
          <div class="item" v-for="(item, index) in modu.noteList" :key="index">
            <div class="time">
              <em></em><span>{{ item.createTime }}</span>
            </div>
            <div class="value" v-html="$utils.toHtml(item.noteContent)"></div>
          </div>
        </div>
        <div class="noteForm">
          <div class="form" v-if="note.show">
            <el-input
              v-model="note.text"
              placeholder="请输入内容"
              type="textarea"
              :rows="4"
            >
            </el-input>
            <div class="noteBtn">
              <el-button type="primary" @click="writeNoteFn">提交</el-button>
              <el-button @click="note.show = false">取消</el-button>
            </div>
          </div>
          <g-icon
            name="iconadd"
            class="btn"
            size="14px"
            @click.native="note.show = true"
            title="写笔记"
            v-else
          />
        </div>
      </div>
      <!-- 笔记模块 -->
      <div class="module msg no-scroll" v-show="modu.value == 'msg'">
        评论模块
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "g-play",
  props: {
    h: { type: Number, default: 500 }, //高度
    isCreate: { type: Boolean, default: false }, //是否创建者
    isLecturer: { type: Boolean, default: false }, //是否讲师
    courseId: [Number, String], //课程id
    coursewareId: [Number, String], //课件id,双向绑定值
    pic: String, //封面图片
    from: String,
  },
  data() {
    return {
      id: this.coursewareId || null,
      detail: {},
      collect: {},

      play: {
        isLoad: false,
        duration: 0, //总时长
        currentTime: 0, //已播放时长
        recordId: null,
        isRecordIng: false, //是否正在执行
        i: 0,
      },
      modu: {
        show: true, //是否显示
        value: "chapter", //当前显示哪个模块
        noteList: [], //课堂笔记列表数据
      },
      note: {
        show: false, //写课堂笔记开关
        text: "", //课堂笔记表单输入
      },
    };
  },
  computed: {
    ...mapGetters("user", ["user", "userInfo"]),
    playerOptions() {
      let obj = {};
      if (this.play.isLoad) {
        obj = {
          //视频url设置,直播流为例
          sources: [
            {
              src: this.detail.coursewareUrl || "", //视频文件地址
              // type: "video/mp4", //视频类型，这里可以不写，如果写一定要写对，否则会无法播放
            },
          ],
          // 其他设置项
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //提示信息
          autoplay: true, //是否自动播放
          controls: true, //是否显示控制栏
          poster: this.pic, //视频封面
          height: this.isCreate ? this.h - 20 : this.h - 70, //高度
          playbackRates: [0.5, 1, 1.5, 2, 2.5, 3], //视频播放速度
          language: "zh-CN", //设置播放器语言
          // fluid: true,
        };
      }
      return obj;
    },
    tabs() {
      let arr = [{ label: "目录", value: "chapter" }];
      // isCreate：是否自建、from：来自哪个页面功能（classDetail：培训班详情页的课程）
      if (!this.isCreate && this.id && this.from != "classDetail") {
        arr.push({ label: "课堂笔记", value: "note" });
      }
      return arr;
    },
  },
  watch: {
    coursewareId() {
      this.id = this.coursewareId;
    },
    id() {
      console.log(this.$route);
      if (this.from != "classDetail") {
        let newURL =
          "/#" + this.$route.path + "?id=" + this.courseId + "&pid=" + this.id;
        window.history.pushState({}, "", newURL);
      }
      this.$emit("input", this.id);
      this.getDetailFn();
    },
    "modu.value": {
      handler(v) {
        if (v == "note") {
          this.getNoteListFn();
          this.noteToBottom();
        }
      },
      deep: true,
    },
  },
  created() {
    // window.addEventListener("visibilitychange", this.saveTimeFn());
    this.getDetailFn();
  },
  methods: {
    // 获取课件详情数据
    getDetailFn() {
      if (this.id) {
        let ps = { coursewareId: this.id };
        this.$api.kc_selectByIdApi(ps).then((res) => {
          if (res.code == "200") {
            this.$set(this, "detail", res.data.result);
            this.play.isLoad = true;
            console.log("课件详情数据", this.detail);
            this.getCollectFn();
          }
        });
      }
    },
    // 获取收藏数据
    getCollectFn() {
      let ps = { coursewareId: this.id };
      this.$api.kc_getcollectApi(ps).then((res) => {
        if (res.code == "200") {
          this.collect = res.data;
        }
      });
    },
    // 收藏
    collectFn() {
      let ps = {
        collectId: this.collect?.collectId || null, //	收藏主键 新增不传/修改传
        collectType: this.collect?.collectType == 1 ? 2 : 1, //	1 收藏 2 取消收藏
        courseId: this.courseId, //	课程id 新增传
        coursewareId: this.id, //	课件id 新增传
      };
      this.$api.kc_collectApi(ps).then((res) => {
        if (res.code == "200") {
          this.getCollectFn();
        }
      });
    },
    // 赞踩
    likeFn(i) {
      let ps = {
        courseId: this.courseId, //	integer	课程id 新增传
        coursewareId: this.id, //	integer	课件id 新增传
        likeId: this.collect?.likeId || null, //	integer	收藏主键 新增不传/修改传
        actionType: i, //	integer	1 喜欢 2 不喜欢 3取消喜欢 4取消不喜欢
      };
      this.$api.kc_likeApi(ps).then((res) => {
        if (res.code == "200") {
          this.getCollectFn();
        }
      });
    },
    // 分享
    shareFn() {
      this.$message("分享");
    },
    // 评论
    commentFn(e) {
      let ps = {
        commentScore: e.score, //	string	评论分数
        commentText: e.comment, //	string	评论内容
        commentType: e.level, //	integer	1:好评；2:中评；3:差评
        courseId: this.courseId, //	integer	课程编码 新增传
        tagRelList: e.tagRelList, //评论标签
      };
      this.$api.kcpl_editApi(ps).then((res) => {
        if (res.code == "200") {
          this.$message.success("评论成功");
          this.$emit("comment");
        }
      });

      // this.$refs.comment.getListFn();
    },

    getNoteListFn() {
      let ps = {
        pageNum: 1,
        pageSize: 10000000,
        coursewareId: this.id,
      };
      this.$api.kc_noteListApi(ps).then((res) => {
        if (res.code == "200") {
          this.modu.noteList = res.data.result.list;
          this.noteToBottom();
        }
      });
    },
    writeNoteFn() {
      let ps = {
        courseId: this.courseId, //	integer	课程id 新增传
        coursewareId: this.id, //	integer	课件id 新增传
        noteContent: this.note.text, //	string	笔记内容
      };
      this.$api.kc_noteAddApi(ps).then((res) => {
        if (res.code == "200") {
          this.note.show = false;
          this.note.text = "";
          this.getNoteListFn();
        }
      });
    },
    noteToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.note;
        container.scrollTop = container.scrollHeight;
      });
    },

    onPlayerPlay(player) {
      // 当播放器播放时，跳转到指定进度
      let num =
        this.detail.playTime == this.detail.totalTime
          ? 0
          : this.detail.playTime;
      player.currentTime(num || 0);
    },
    // 实时记录播放进度
    onTimeUpdate(event) {
      const player = this.$refs.videoPlayer.player;
      // 获取当前播放时间
      this.play.currentTime = player.currentTime();
      // 获取视频总时长
      this.play.duration = player.duration();
      // 计算播放进度，可以根据需要进行处理
      // const progress = (currentTime / duration) * 100;
      // console.log(`当前播放进度: ${progress.toFixed(2)}%`);
      // 在此处添加进度条处理逻辑
      let date = new Date();
      let ss = date.getSeconds();
      if (
        (ss % 20 === 0 || this.play.currentTime == this.play.duration) &&
        !this.isCreate &&
        !this.isLecturer &&
        this.user.userType != 2 &&
        this.from != "classDetail"
      ) {
        if (!this.play.isRecordIng) {
          this.play.isRecordIng = true;
          let that = this;
          setTimeout(function () {
            console.log(that.play.recordId, "==that.play.recordId");
            if (that.play.recordId) {
              that.saveTimeFn();
            } else {
              that.addTimeFn();
            }
          }, 1000); // 定时时间
        }
      }
    },
    // 先新增一条记录，再保存播放进度
    addTimeFn() {
      let ps = {
        courseId: this.courseId,
        courseId: this.courseId,
        coursewareId: this.id,
        playTime: this.play.currentTime, //	integer	播放时长 单位秒
        totalTime: this.play.duration, //	integer	总时长 单位秒
      };
      this.$api.kc_addRecordApi(ps).then((res) => {
        if (res.code == "200") {
          this.play.recordId = res.data.recordId;
          this.play.isRecordIng = false;
        }
      });
    },
    // 执行保存播放进度
    saveTimeFn() {
      localStorage.setItem("第一次=", "111111=" + this.play.recordId);
      if (this.play.recordId) {
        localStorage.setItem("第二次=", "2222222");
        let ps = {
          recordId: this.play.recordId,
          coursewareId: this.id, //	integer	课件id 新增必传
          playTime: this.play.currentTime, //	integer	播放时长 单位秒
          totalTime: this.play.duration, //	integer	总时长 单位秒
        };
        this.$api.kc_editRecordApi(ps).then((res) => {
          if (res.code == "200") {
            this.play.isRecordIng = false;
          }
        });
      }
    },
  },
  // mounted() {
  //   window.addEventListener("beforeunload", this.saveTimeFn());
  // },

  // beforeDestroy() {
  //   window.removeEventListener("visibilitychange", this.saveTimeFn());
  // },
};
</script>

<style lang="less" scoped>
.g-play {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  background-color: #1b2128;
  border-radius: 2px;
  overflow: hidden;
}
.play-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 0px;
  height: 100%;
  background-color: #000;
  .play-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: -5px;
    height: 50px;
    // background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    .name {
      max-width: 50%;
      font-weight: 700;
    }
    .info {
      flex: 1;
      width: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: #999;
      font-size: 12px;
      i {
        color: #39c;
      }
      .g-icon {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
  .play-video {
    flex: 1;
    height: 0px;
    width: 100%;
    overflow: hidden;
    padding: 10px 10px;
    .video-player {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /deep/.vjs-error .vjs-error-display:before {
        display: none;
      }
    }
    .video-empty {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: covewr;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .play-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: -5px;
    height: 50px;
    background-color: rgba(27, 33, 40, 0.5);
    color: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    .item {
      display: flex;
      align-items: center;
      gap: 20px;
      .g-icon {
        line-height: 1;
        cursor: pointer;
      }
      /deep/.fan {
        .iconfont {
          transform: rotateX(180deg);
        }
      }
    }
  }
}
.play-info {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  // background-color: #fff;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 10px);
    height: 24px;
    background: linear-gradient(
      0deg,
      #1b2128 11.61%,
      rgba(27, 33, 40, 0) 99.59%
    );
    pointer-events: none;
  }
  .close {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }
  /deep/.g-tab {
    color: #a1a9b2;
    padding: 5px 30px 0;
    font-size: 16px;
    border-bottom: 1px solid #14171a;
    span.on {
      color: #c0c5cc !important;
    }
  }
  .module {
    flex: 1;
    width: 100%;
    height: 0;
    padding: 20px 30px 20px 30px;
    overflow: hidden;
    overflow-y: auto;
    color: #999;

    &.note {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .list {
        margin: -10px;
        padding: 10px;
        flex: 1;
        height: 0px;
        overflow: hidden;
        overflow-y: auto;
        .item {
          // border: 1px solid #111;
          border-radius: 2px;
          padding: 10px 0px;
          line-height: 180%;
          font-size: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid #111;
          }
          .time {
            position: relative;
            em {
              position: absolute;
              top: 9px;
              left: -10px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #999;
            }
            span {
              color: #667280;
            }
          }
          .value {
            color: #a1a9b2;
          }
        }
      }
      .noteForm {
        height: 0px;
        position: relative;
        margin: 0 -10px;
        .form {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 2;
          background-color: #1b2128;

          /deep/.el-textarea {
            font-size: 12px;
          }
          .noteBtn {
            margin-top: 10px;
            .el-button {
              width: auto;
              height: 26px;
              padding: 0 20px;
              font-size: 12px;
            }
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          line-height: 1;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          color: #fff;
          position: absolute;
          right: -10px;
          bottom: -5px;
          z-index: 1;
          cursor: pointer;
          &:hover {
            background-color: rgba(51, 153, 204, 1);
          }
        }
      }
    }
  }
}

/deep/.vjs-custom-skin > .video-js {
  height: 100%;
}
</style>

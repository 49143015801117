// //猎头系统--职位
// import {
//   getReq,return axios.post,
//   //delReq
// } from '@/api/apiReq';
// import { apiUrl } from "@/api/config"
const utf8 = {
  ContentType: "application/json;charset=UTF-8",
};
export default function (axios) {
  return {
    JobSelectByCompanyIdReq(params) {
      return axios.get(
        "/headhunter/recruiter/selectByCompanyId",
        { params },
        {
          ContentType: "application/json",
        }
      );
    }, //客户分页查询
    JobInsertReq(data) {
      return axios.post("/headhunter/job/insert", data, {
        ContentType: "application/json",
      });
    }, //猎头新增职位
    JobupdateReq(data) {
      return axios.post("/headhunter/job/updateJob", data, {
        ContentType: "application/json",
      });
    }, //猎头新增职位
    JobselectPageReq(data) {
      return axios.post("/headhunter/job/selectPage", data, {
        ContentType: "application/json",
      });
    }, //猎头职位列表查询
    JobselectPageReq(data) {
      return axios.post("/headhunter/job/selectPage", data, utf8);
    },
    JobUpdateStatusReq(data) {
      return axios.get(
        "/headhunter/job/updateStatus?jobIds=" +
          data.jobIds +
          "&status=" +
          data.status
      );
    }, //猎头职位列表查询
    JobSelectJobPeopleListReq(data) {
      return axios.get(
        "/headhunter/job/selectJobPeopleList?jobId=" + data.jobId
      );
    }, //查询猎头职位下的寻访数据
    JobSaveJobPeopleDetailReq(data) {
      return axios.post("/headhunter/job/saveJobPeopleDetail", data, {
        ContentType: "application/json",
      });
    }, //保存职位寻访数据
    JobSelectParamByListTypeReq(data) {
      return axios.post("/headhunter/job/selectParamByListType", data, {
        ContentType: "application/json",
      });
    }, //
    deleteOrCloseJobReq(data) {
      return axios.get(
        "/headhunter/job/deleteOrCloseJob?jobIds=" +
          data.jobIds +
          "&status=" +
          data.status
      );
    }, //查询猎头职位下的寻访数据
    selectChannelJobPageReq(data) {
      return axios.post("/headhunter/job/selectChannelJobPage", data, {
        ContentType: "application/json",
      });
    }, //猎头职位列表查询
    insertChannelJobReq(data) {
      return axios.post("/headhunter/job/insertChannelJob", data, {
        ContentType: "application/json",
      });
    }, //猎头职位列表查询
    getInsideStatusReq(data) {
      return axios.get("/recruiter/job/getInsideStatus/" + data.jobId);
    }, //查询猎头职位下的寻访数据
  };
}

export default {
  parent: "AtExam",
  routers: [
    // 我参加的
    {
      path: "in",
      name: "AtExam_in",
      component: () => import("@/views/staff/exam/InView.vue"),
    },
    // 我发布的
    {
      path: "publish",
      name: "AtExam_publish",
      component: () => import("@/views/staff/exam/PublishView.vue"),
    },
    // 全部
    {
      path: "all",
      name: "AtExam_all",
      component: () => import("@/views/staff/exam/IndexView.vue"),
    },
  ],
};

//猎头系统--职位
export default function (axios) {
  return {
    getSelectPageReq(params) {
      return axios.get("/findCtl/selectPage", { params });
    }, //分页查询寻访记录

    editFindReq(data) {
      return axios.post("/findCtl/editFind", data, {
        ContentType: "application/json",
      });
    }, //新增或者编辑寻访记录
    delFindReq(params) {
      return axios.post(
        "/findCtl/deleteByPrimaryKey?findPeopleId=" + params.findPeopleId,
        params
      );
    }, //根据id删除自己创建的寻访记录
    sendNoticeReq(data) {
      return axios.post(
        "/findCtl/sendNotice?content=" +
          data.content +
          "&type=" +
          data.type +
          "&subject=" +
          data.subject,
        data.memberList
      );
    }, //给寻访人员发送邮件或者短信

    selectFindDetailReq(data) {
      return axios.post(
        "/findCtl/selectByPrimaryKey?findPeopleId=" +
          data.findPeopleId +
          "&status=" +
          data.status,
        data,
        { ContentType: "application/json" }
      );
    }, //根据id查询寻访沟通内容详情业务
    selectHeadhunterReq(params) {
      return axios.post(
        "/findCtl/selectHeadhunter?jobId=" + params.jobId,
        params
      );
    }, //查询猎头
    selectPlaceTradeReq(params) {
      return axios.post("/findCtl/selectPlaceTrade", params);
    }, //查询寻访人员的行业以及地点

    selectCompJobReq(data) {
      return axios.post("/findCtl/selectCompJob", data, {
        ContentType: "application/json",
      });
    }, //查询合作的公司及其下面的职位

    editPeopleJobReq(data) {
      return axios.post("/findCtl/editPeopleJob", data, {
        ContentType: "application/json",
      });
    }, //寻访人员加到职位
    moveFindReq(params) {
      return axios.post(
        "/findCtl/removePeoFromJob?jobId=" +
          params.jobId +
          "&peopleId=" +
          params.peopleId
      );
    }, //寻访人员移出职位
    addResumeCommentReq(data) {
      return axios.post(
        "/findCtl/addResumeComment?channelId=" +
          data.channelId +
          "&resumeId=" +
          data.resumeId +
          "&findPeopleId=" +
          data.findPeopleId,
        { ContentType: "application/json" }
      );
    }, //寻访生成简历同步点评数据

    findToResumeReq(data) {
      return axios.post("/findCtl/checkResume", data, {
        ContentType: "application/json",
      });
    }, //寻访生成简历时校验简历库
    selectHeadhunterCount(params) {
      return axios.get("/findCtl/selectHeadhunterCount", { params });
    }, // 猎头-统计 统计各种数量
  };
}

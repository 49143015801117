<!-- 暂无数据公共样式
  可直接引用，无需设置任何参数  
  1、<g-null v-if="totalSize == 0" />

  亦可根据不同的引用环境设置class和tips，如：
  <g-null class="nullData" tips="暂无数据，请更换筛选条件再试下" v-if="totalSize == 0" />

  使用插槽：
  <g-null><span>暂无数据，<i>去添加</i></span></g-null>
 -->
<template>
  <div class="g-null no-select">
    <img src="~img/error/empty.png" v-if="img" class="img" />
    <svg class="icon" aria-hidden="true" v-else>
      <use xlink:href="#iconmeiyoushuju"></use>
    </svg>
    <p>
      <slot>
        <span>{{ tips }}</span>
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "g-null",
  props: {
    img: { type: Boolean, default: false },
    tips: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
// 暂无数据样式1
.g-null {
  padding: 50px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    height: 160px;
  }

  .icon {
    width: 160px;
    height: 110px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }

  p {
    font-size: 14px;
    color: #8797a1;
    margin-top: 20px;
  }
}
</style>

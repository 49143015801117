/*
 * @Description: 360评估等项目相关的页面 主要是详情页
 * @Date: 2023-11-17 16:22:39
 * @LastEditTime: 2023-11-17 17:53:11
 */

export default {
  parent: "AsktrueProjectAssessment",
  routers: [
    {
      path: "questionnaire",
      name: "ProPgQuestionnaire",
      component: () => import("@/views/staff/360/detail/questionnaire.vue"),
    },
    {
      path: "evaluate",
      name: "ProPgEvaluate",
      component: () => import("@/views/staff/360/detail/evaluate.vue"),
    },
    {
      path: "data",
      name: "ProPgData",
      component: () => import("@/views/staff/360/detail/data.vue"),
    },
    {
      path: "score",
      name: "ProPgScore",
      component: () => import("@/views/staff/360/detail/score.vue"),
    },
    {
      path: "statistics",
      name: "ProPgStatistics",
      component: () => import("@/views/staff/360/detail/statistics.vue"),
    },
    {
      path: "notice",
      name: "ProPgNotice",
      component: () => import("@/views/staff/360/detail/notice.vue"),
    },
  ],
};

<template>
  <div class="tinymce-editor mt5">
    <editor
      v-model="descValue"
      :id="tinymceId"
      :init="init"
      :placeholder="
        dialogType == 1
          ? '输入试题内容'
          : dialogType == 2
          ? '输入选项内容'
          : dialogType == 3
          ? '输入答案解析'
          : dialogType == 4
          ? '输入产品说明'
          : '输入说明'
      "
    />
  </div>
</template>
<script>
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";

//主题
import "tinymce/themes/silver";
// import "tinymce/icons/default/icons";
// 编辑器插件plugins
// 更多插件参考文档：http://tinymce.ax-z.cn/
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/autoresize"; // 高度自适应
import "tinymce/plugins/fullscreen"; // 全屏
import { ossUploadFileReq } from "@/api/asktrue/common";
export default {
  components: {
    editor: Editor,
  },
  props: {
    baseUrl: {
      type: String,
      default: "https://asktrue.co",
    },
    editValue: String, //编辑内容
    dialogType: Number, //区分编辑内容
    tinyId: String, //编辑器id
  },
  data() {
    return {
      token: this.$store.state.asktrue.access_token,
      tinymceId: this.tinyId || "tinymceId",
      descValue: this.editValue, //内容
      init: {
        language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`, //语言包
        language: "zh_CN",
        skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
        height: 130,
        min_height:
          this.dialogType == 1 || this.dialogType == 2 || this.dialogType == 3
            ? 360
            : 130,
        max_height:
          this.dialogType == 1 || this.dialogType == 2 || this.dialogType == 3
            ? 360
            : 10000,
        menubar: false,
        statusbar: false, // 隐藏编辑器底部的状态栏
        plugins: ["lists image", "table autoresize fullscreen"],
        content_style:
          "p {font-size:14px;color:#666666} .mce-content-body[data-mce-placeholder]{font-size:14px;color:#999999}", //设置默认字号
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        toolbar:
          "undo redo | fontselect fontsizeselect | bold italic forecolor backcolor | \
          alignleft aligncenter alignright alignjustify | \
          image removeformat fullscreen",
        images_upload_url: "",
        // images_upload_handler: (blobInfo, success) => {//图片上传
        //   console.log(blobInfo.blob(),'===aaaablobInfo')
        //   const img = 'data:image/jpeg;base64,' + blobInfo.base64()
        //   success(img)
        // },
        images_upload_handler: function (blobInfo, success) {
          let file = blobInfo.blob(); //转化为易于理解的file对象
          let formData = new FormData();
          formData.append("file", file);
          //上传至服务器
          ossUploadFileReq(formData).then((res) => {
            if (res.code == "200") {
              let imageUrl = res.data.result; //res.data[Object.keys(res.data)[0]];
              success(imageUrl);
            }
          });
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      tinymce.init({ selector: this.tinymceId });
    });
  },
  beforeDestroy() {
    tinymce.remove({ selector: this.tinymceId });
  },
  methods: {
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.descValue = "";
    },
  },
  watch: {
    descValue(newValue) {
      this.$emit("changeVal", newValue);
    },
  },
};
</script>
<style lang="less" scope>
.tinymce-editor {
  .tox-tinymce {
    border-color: #e4e4e4;
  }
  .tox-toolbar__primary {
    background: unset !important;
    border-bottom: 1px solid#e4e4e4;
  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid #e4e4e4;
  }
  .tox .tox-tbtn svg {
    fill: #999 !important;
  }
  .tox .tox-tbtn {
    color: #999;
  }
}
/deep/ .mce-content-body[data-mce-placeholder] {
  font-size: 12px !important;
  color: #999 !important;
}
</style>
<style lang="less">
.tox-tinymce-aux,
.tox-dialog {
  z-index: 2300 !important;
}
</style>
<style></style>

<template>
  <div class="button-item-container" @click.stop="">
    <div class="button-item flex-space">
      <div>背景色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change"
          v-model="backgroundColor"
          class="cube-color"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
    <div class="button-item flex-space">
      <div>字体色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change"
          v-model="fontColor"
          class="cube-color"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["button"],
  data() {
    return {
      backgroundColor: "",
      fontColor: "",
    };
  },
  watch: {
    button: {
      handler(val) {
        for (let prop in val) {
          this[prop] = this.button[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    for (let prop in this.button) {
      this[prop] = this.button[prop];
    }
  },
  methods: {
    change() {
      this.$emit(
        "dealChange",
        {
          backgroundColor: this.backgroundColor,
          fontColor: this.fontColor,
        },
        "button"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.button-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .button-item {
    line-height: 50px;
    border-bottom: 1px dotted #e4e4e4;
    height: 51px;
    overflow: hidden;
    /deep/ .cube-color .el-color-picker__trigger {
      padding: 3px;
      border-color: transparent;
      margin-right: 20px;
      .el-color-picker__color {
        border-radius: 2px;
        border: 1px solid #e4e4e4;
        overflow: hidden;
      }
      .el-color-picker__icon {
        left: unset;
        color: #333;
        right: -37px;
        font-size: 15px;
      }
    }
  }
}
</style>

<!-- 评估/报名样式此文件相同， 其余相同 -->
<template>
  <div class="edit-warpper console-body">
    <div class="back" @click="$emit('closePop')">
      <em class="iconfont iconshangyi1"></em><span>返回</span>
    </div>
    <div class="close" @click="$emit('closePop')">
      <em class="iconfont iconshanchu"></em>
    </div>
    <!--左栏-->
    <div class="menu-warpper no-scroll no-select" v-show="isTemp">
      <div class="temp">
        <LoginModule
          :loginData="pcData"
          :autoStyle="autoStyle"
          :pc="pc"
          @dealChange="dealChange"
        />
      </div>
    </div>

    <!--中间正文-->
    <div class="main no-scroll flex-space">
      <!--白色区域-->
      <div :class="['body no-scroll', { mobile: !pc }]">
        <div class="change-top-tab">
          <div class="tab left">
            <div
              :class="['item', { active: pc }]"
              @click="
                pc = true;
                edit = false;
              "
            >
              PC
            </div>
            <div
              :class="['item', { active: !pc }]"
              @click="
                pc = false;
                edit = false;
              "
            >
              手机
            </div>
          </div>
          <div class="tab right">
            <div class="item" @click="isTemp = !isTemp">模板</div>
            <div class="item" @click="isSet = !isSet">设置</div>
          </div>
        </div>
        <div class="login-view" v-if="pc">
          <ExamLogin1
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 1"
            :transData="pcData.login"
          />
          <ExamLogin2
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 2"
            :transData="pcData.login"
          />
          <ExamLogin3
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 3"
            :transData="pcData.login"
          />
        </div>
        <div class="mobile-style" v-if="!pc">
          <MExamLogin1
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 1"
            :transData="pcData.login"
          />
          <MExamLogin2
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 2"
            :transData="pcData.login"
          />
          <MExamLogin3
            ref="examLogin"
            v-if="pcData.login.template && pcData.login.template.template == 3"
            :transData="pcData.login"
          />
        </div>
      </div>
      <PcControl
        :isSet="isSet"
        @dealChange="dealChange"
        @dealShow="edit = false"
        :transData="pcData"
        :paperActiveIndex="Number(paperActiveIndex)"
        :id="id"
        :projectType="projectType"
        @resetStyle="resetStyle"
      />
    </div>
  </div>
</template>

<script>
import { getPcStyle } from "@/views/staff/js/styleOptions";
import PcControl from "@/views/staff/components/asktrue/style-right-control/PcControl"; //右侧外观样式控制
import ExamLogin1 from "@/views/staff/components/asktrue/style-control/pc/login1";
import ExamLogin2 from "@/views/staff/components/asktrue/style-control/pc/login2";
import ExamLogin3 from "@/views/staff/components/asktrue/style-control/pc/login3";
import MExamLogin1 from "@/views/staff/components/asktrue/style-control/mobile/login1";
import MExamLogin2 from "@/views/staff/components/asktrue/style-control/mobile/login2";
import MExamLogin3 from "@/views/staff/components/asktrue/style-control/mobile/login3";
import {
  getProjectEdit,
  postProStyleSetReq,
  getProPaperReq,
} from "@/api/asktrue/project/project";
import LoginModule from "@/views/staff/components/asktrue/style-control/LoginModule";

export default {
  components: {
    PcControl,
    LoginModule,
    ExamLogin1,
    ExamLogin2,
    ExamLogin3,
    MExamLogin1,
    MExamLogin2,
    MExamLogin3,
  },
  props: {},
  data() {
    return {
      edit: false, //展示样式编辑
      pc: true, //pc样式还是移动样式
      isTemp: false, //模板开关
      isSet: false,
      paperActiveIndex: "0", //考试页面设置时当前问卷的index
      pageActiveType: "0",
      pageActiveTypeId: "0",
      papeSet: {},
      id: 0,
      projectType: 4,
      autoStyle: false, //是否自定义样式？false：否，使用当前模板默认值；true：是，使用自定义样式值
      pcData: {
        login: {
          //登录页样式控制对象
          exist: [
            "template",
            "backgroundStyle",
            "step",
            "title",
            "logo",
            "sidePicture",
            "button",
            "loginbox",
            "copyright",
          ], //当前模板存在哪些控制项
          template: {
            template: 0,
          },
          backgroundStyle: {
            //背景样式控制
            backgroundType: "pure", //背景样式类型
            typeDetail: "#fff", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
          },
          step: {
            begin: { borderColor: "#aaa", color: "#aaa" },
            pass: { borderColor: "#39c", color: "#39c" },
            checked: { borderColor: "#f60", color: "#f60" },
          },
          title: {
            //标题
            text: "",
            fontSize: "26px",
            color: "#555",
            bold: false,
          },
          logo: {
            show: false, //是否展示logo
            img: "", //图片路径
          },
          sidePicture: {
            //插画
            img: "",
          },
          button: {
            //按钮样式
            backgroundColor: "#39c", //背景色
            fontColor: "#fff", //字体颜色
          },
        },
        login1: getPcStyle().login1,
        login2: getPcStyle().login2,
        login3: getPcStyle().login3,
      },
      testList: [],
      pageTypeList: [],
      scaleStyle: "",
    };
  },
  watch: {
    $route() {
      if (this.$route.fullPath.indexOf("/Style") > -1) {
        this.$nextTick(() => {
          if (this.$refs.examLogin) {
            this.$refs.examLogin.stopVideo();
          }
        });
        this.$refs.examLogin.getReset();
        this.getData();
        this.getProjectEditFn();
      } else {
        this.$nextTick(() => {
          if (this.$refs.examLogin) {
            this.$refs.examLogin.stopVideo();
          }
        });
      }
    },
  },
  created() {
    this.getData();
    this.getProjectEditFn();
  },
  mounted() {
    let div = document.body;
    let showWidth =
      (div.style.width ||
        div.clientWidth ||
        div.offsetWidth ||
        div.scrollWidth) - 540;
    if (showWidth < 900) {
      this.scaleStyle =
        "transform-origin:center top;transform:scale(" + showWidth / 900 + ");";
    }
  },
  methods: {
    //获取详情数据
    getProjectEditFn() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.projectType,
      };
      getProjectEdit(params).then((res) => {
        if (res.code == "200") {
          let $ = res.data.result;
          this.editData = $;
          this.$store.dispatch("asktrue/setProInfo", this.editData);

          this.pageTypeList = [];
          this.testList = [];
          if ($.specify_student == 1) {
            //认证才有登录页
            this.pageTypeList.push({ active: 3, label: "登录页" });
            this.testList.push({
              paperId: -1,
              pageCreateType: -1,
              paperTypeId: -1,
            });
          }

          this.getTestListFn();
          this.getStyleSetFn();
        }
      });
    },
    // 初始化页面或监听参数变化
    getData() {
      this.id = Number(this.$route.query.id);
      this.edit = false;
    },
    //获取外观样式参数值
    async getStyleSetFn(reset) {
      let params = {
        examId: String(this.$route.query.id), //	项目id	query	string
        projectType: String(this.projectType), //项目类型:1考试 2调查 3评估 4报名 5投票
      };
      await postProStyleSetReq(params).then((res) => {
        if (res.code == "200" && res.data.result) {
          if (res.data.result.spare1) {
            this.autoStyle = true;
            this.pcData.login = JSON.parse(res.data.result.spare1);
            if (
              !this.pcData.login.template ||
              !this.pcData.login.template.template
            ) {
              this.pcData.login = getPcStyle().login1;
            }
            this.pcData["login" + this.pcData.login.template.template] =
              JSON.parse(res.data.result.spare1);
            if (!this.pcData.login.exist) {
              this.$set(
                this.pcData.login,
                "exist",
                getPcStyle()["login" + this.pcData.login.template.template]
                  .exist
              );
              this.$set(
                this.pcData["login" + this.pcData.login.template.template],
                "exist",
                getPcStyle()["login" + this.pcData.login.template.template]
                  .exist
              );
            }
            if (!this.pcData.login.step) {
              let step = {
                begin: { borderColor: "#aaa", color: "#aaa" },
                pass: { borderColor: "#39c", color: "#39c" },
                checked: { borderColor: "#f60", color: "#f60" },
              };
              this.$set(this.pcData.login, "step", step);
            }

            if (!this.pcData.login.copyright) {
              this.$set(this.pcData.login, "copyright", {
                show: true,
                font: "Asktrue 提供技术支持",
              });
            }
          } else {
            this.autoStyle = false;
            this.pcData.login = getPcStyle().login1;
            this.pcData.login.title.text = this.editData.exam_name;
          } //PC登录页

          if (reset) {
            this.edit = true;
          }
          this.$nextTick(() => {
            this.$refs.examLogin.changeStepStyle();
          });
        } else {
          this.autoStyle = false;
          this.pcData.login = getPcStyle().login1;
          this.pcData.login1 = getPcStyle().login1;
          this.pcData.login2 = getPcStyle().login2;
          this.pcData.login3 = getPcStyle().login3;
          this.pcData.login.title.text = this.editData.exam_name;
        }
      });
      this.pcData.login1.title.text = this.editData.exam_name;
      this.pcData.login2.title.text = this.editData.exam_name;
      this.pcData.login3.title.text = this.editData.exam_name;
    },

    //问卷查询
    getTestListFn() {
      let params = {
        examId: Number(this.id),
        projectType: this.projectType,
      };
      getProPaperReq(params).then((res) => {
        if (res.code == "200" && res.data.result.length) {
          this.testList = this.testList.concat(res.data.result);
          this.paperActiveIndex = this.testList[0].paperId + "";
          this.pageActiveType = this.testList[0].pageCreateType + "";
          this.pageActiveTypeId = this.testList[0].paperTypeId + "";
          this.pageTypeList.push({ active: 4, label: "答题页" });
        }
      });
    },

    // 试题样式保存
    dealExamListChange(data) {
      this.pcData.examStyle = data;
    },
    // 组件返回值设置
    dealChange(data, type) {
      if (type == "template") {
        this.pcData.login = this.pcData["login" + data.template];
        this.pcData.login.title.text = this.editData.exam_name;
        console.log(this.pcData.login, "==this.pcData.login11");
      } else {
        this.pcData.login[type] = data;
        if (type == "step") {
          this.$refs.examLogin.changeStepStyle();
        }
      }
    },
    //重置样式
    resetStyle() {
      this.edit = false;
      this.getStyleSetFn(true);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-warpper {
  height: auto;
  max-height: calc(100% - 80px);
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 5;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  .temp {
    padding-top: 20px;
  }
}

.edit-warpper {
  padding: 0px;
  height: 100%;
  position: relative;
  .back {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 9;
    color: #fff;
    cursor: pointer;
    em {
      position: relative;
      color: inherit;
      transform: rotate(-90deg);
      font-size: 19px;
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 9;
    color: #fff;
    cursor: pointer;
    em {
      font-size: 19px;
      color: inherit;
    }
  }
  .main {
    height: 100%;
    padding: 0px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    .body {
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .login-view {
        flex-grow: 1;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
      }
      &.mobile {
        padding-bottom: 50px;
        .mobile-style {
          background: #fff;
          height: calc(100% - 60px);
          width: 360px;
          margin: 0 auto;
          margin-top: 60px;
          box-shadow: 0 0 8px rgba(24, 61, 74, 0.5);
          border-radius: 3px;
          .exam-paper-change {
            position: absolute;
            margin-left: -75px;
            left: 0px;
            top: 0px;
            z-index: 100;
          }
        }
      }
      .change-top-tab {
        top: 0px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 400px;
        z-index: 100;
        overflow: hidden;
        display: flex;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          font-size: 12px;
          border-left: 1px solid #e4e4e4;
          border-right: 1px solid #e4e4e4;
          border-bottom: 1px solid #e4e4e4;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          .item {
            width: 60px;
            line-height: 25px;
            text-align: center;
          }
        }
        .active {
          background: var(--second-theme-color);
          color: #fff;
        }
      }

      .edit {
        top: 65px;
        position: fixed;
        left: calc(50% + 102px);
        line-height: 25px;
        width: 30px;
        height: 26px;
        font-size: 12px;
        border-radius: 0 0 2px 2px;
        border: 1px solid #e4e4e4;
        border-top: 0px;
        background: #fff;
        z-index: 1;
        &.active {
          background: var(--second-theme-color);
          color: #fff;
          border-color: var(--second-theme-color);
        }
      }
      .temp {
        position: fixed;
        top: 90px;
        left: 90px;
        width: 75px;
      }
      .exam-paper-change {
        position: absolute;
        bottom: 30px;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
        z-index: 100;
        display: flex;
        padding-right: 205px;
        li {
          width: 40px;
          height: 40px;
          line-height: 38px;
          text-align: center;
          margin-left: 15px;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          overflow: hidden;
          background-color: #fff;
          cursor: pointer;
          &.on {
            background-color: var(--second-theme-color);
            color: #fff;
            border: 1px solid var(--second-theme-color);
          }
        }
      }
    }
  }
}
</style>

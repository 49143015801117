// 人事助手/花名册
export default {
  parent: "StaffManage",
  routers: [
    {
      path: "assi-my/:sign?",
      name: "Assi-my",
      component: () => import("@/views/staff/AssistantMy.vue"),
    },
    // 花名册
    {
      path: "roster/:sign?",
      name: "Roster",
      component: () => import("@/views/staff/StaffShunt.vue"),
    },
    {
      path: "assi-in/:sign?",
      name: "Assi-in",
      component: () => import("@/views/staff/AssistantInShunt.vue"),
      meta: {
        nomy: true,
      },
    },
    {
      path: "assi-to/:sign?",
      name: "Assi-to",
      component: () => import("@/views/staff/AssistantToShunt.vue"),
      meta: {
        nomy: true,
      },
    },
    {
      path: "assi-out/:sign?",
      name: "Assi-out",
      component: () => import("@/views/staff/AssistantOutShunt.vue"),
      meta: {
        nomy: true,
      },
    },
    {
      path: "assi-change/:sign?",
      name: "Assi-change",
      component: () => import("@/views/staff/AssistantChangeShunt.vue"),
      meta: {
        nomy: true,
      },
    },
  ],
};

import store from "@/store";
import Vue from "vue";
import html2canvas from "html2canvas";

// 使用方法：this.$utils.方法名()
export default {
  fileToPng: "?ci-process=doc-preview&dstType=png", // 腾讯云缩略图后缀参数
  fileToHtml: "?ci-process=doc-preview&dstType=html&htmltitle=", // 腾讯云预览后缀参数
  //缩略图前缀"https://ow365.cn?i=14719&info=1&ssl=1&furl="
  fileBaseUrl: "https://ow365.cn?i=31584&ssl=1&info=1&furl=",
  //预览前缀"https://ow365.cn/?i=14719&ssl=1&furl="
  fileBaseView: "https://ow365.cn/?i=31584&ssl=1&furl=",
  apiBaseUrl: "https://staffmarket.cn/user/user", // 该参数目前主要用于附件上传使用
  apiBaseUrlSalary: "https://staffmarket.cn/salary", // 该参数目前主要用于附件上传使用
  apiBaseUrlStaff: "https://staffmarket.cn/staff", // 该参数目前主要用于附件上传使用
  apiBaseUrlCrm: "https://staffmarket.cn/crm", // 该参数目前主要用于附件上传使用
  apiBaseUrlMatcheasy: "http://api.matcheasy.cn", // 好招
  apiBaseUrlBs: "https://enroll.matcheasy.cn/", // 好招-笔试-apiUrl6
  apiBaseUrlMs: "https://enroll.matcheasy.cn/", // 好招-面试-apiUrl7
  apiBaseUrlTj: "https://enroll.matcheasy.cn/", // 好招-体检-apiUrl8
  apiBaseUrlAsktrue: "https://project.asktrue.cn", //阿仕图-考试-apiUrl10

  // 附件迁移服务器后删除
  getFileViewUrl(url, title) {
    let str = this.getFormat(url, 1);
    title = title
      ? this.safeTextToBase64(title)
      : this.safeTextToBase64("预览");
    let fileViewUrl = url + (str == "img" ? "" : this.fileToHtml + title);
    return fileViewUrl;
  },
  // URL 安全的 BASE64 编码,目前该方法只应用于文件预览是，传值给腾讯云文件url
  safeTextToBase64(str) {
    let Base64 = require("js-base64").Base64;
    str = Base64.encode(str); //Base64.encode(item.attachName); //代码文本， 需Base64处理
    str = str.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "");
    return str;
  },

  //宽度自适应,value为选中文字
  autoWidth(value) {
    if (value == "" || value == 0) {
      return "85px";
    } else {
      return String(value).length * 12 + 15 + "px";
    }
  },
  // 根据参数时间+一个月
  // 参数时间ymd：格式必须：YYYY-MM-DD
  // 一个月并非+30天，而是下个月-1天
  addOneMonth(ymd) {
    let beginDate = new Date(ymd);
    let by = beginDate.getFullYear();
    let bm = beginDate.getMonth() + 1;
    let bd = beginDate.getDate();
    let ey = by,
      em = bm,
      ed = null;
    let len = new Date(by, bm, 0).getDate(); //一个月的天数
    if (bd == 1) {
      ed = len;
    } else {
      if (em == 12) {
        ey = by + 1;
        em = 1;
      } else {
        em = em + 1;
      }
      let elen = new Date(ey, em, 0).getDate(); //一个月的天数
      ed = (bd == len ? elen : bd) - 1;
    }
    em = (em < 10 ? "0" : "") + em;
    ed = (ed < 10 ? "0" : "") + ed;
    return ey + "-" + em + "-" + ed;
  },

  // 时间戳转格式，返回格式（可自定义）：YYYY-MM-DD hh:mm:ss
  toTime(str, type) {
    let date = new Date(str);
    let YY = date.getFullYear();
    let MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    let DD = (date.getDate() < 10 ? "0" : "") + date.getDate();
    let hh = (date.getHours() < 10 ? "0" : "") + date.getHours();
    let mm = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    let ss = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
    return type
      .replace("YYYY", YY)
      .replace("MM", MM)
      .replace("DD", DD)
      .replace("hh", hh)
      .replace("mm", mm)
      .replace("ss", ss);
  },

  // 日期 显示 周几
  weekStr: {
    0: "周日",
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
  },
  toWeek(str) {
    console.log(str);
    let date = new Date(str);
    let weekIndex = date.getDay(); // Sunday - Saturday : 0 - 6
    return this.weekStr[weekIndex];
  },
  // 判断浏览器类型
  getBrowserType() {
    // 获取浏览器 userAgent
    var ua = navigator.userAgent;
    // 是否为 Opera
    var isOpera = ua.indexOf("Opera") > -1;
    // 返回结果
    if (isOpera) {
      return "Opera";
    }
    // 是否为 IE
    var isIE =
      ua.indexOf("compatible") > -1 && ua.indexOf("MSIE") > -1 && !isOpera;
    var isIE11 = ua.indexOf("Trident") > -1 && ua.indexOf("rv:11.0") > -1;
    // 返回结果
    if (isIE11) {
      return "IE11";
    } else if (isIE) {
      // 检测是否匹配
      var re = new RegExp("MSIE (\\d+\\.\\d+);");
      re.test(ua);
      // 获取版本
      var ver = parseFloat(RegExp["$1"]);
      // 返回结果
      if (ver == 7) {
        return "IE7";
      } else if (ver == 8) {
        return "IE8";
      } else if (ver == 9) {
        return "IE9";
      } else if (ver == 10) {
        return "IE10";
      } else {
        return "IE";
      }
    }

    // 是否为 Edge
    var isEdge = ua.indexOf("Edge") > -1;
    // 返回结果
    if (isEdge) {
      return "Edge";
    }

    // 是否为 Firefox
    var isFirefox = ua.indexOf("Firefox") > -1;
    // 返回结果
    if (isFirefox) {
      return "Firefox";
    }

    // 是否为 Safari
    var isSafari = ua.indexOf("Safari") > -1 && ua.indexOf("Chrome") == -1;
    // 返回结果
    if (isSafari) {
      return "Safari";
    }

    // 是否为 Chrome
    var isChrome =
      ua.indexOf("Chrome") > -1 &&
      ua.indexOf("Safari") > -1 &&
      ua.indexOf("Edge") == -1;
    // 返回结果
    if (isChrome) {
      return "Chrome";
    }

    // 是否为 UC
    var isUC = ua.indexOf("UBrowser") > -1;
    // 返回结果
    if (isUC) {
      return "UC";
    }

    // 是否为 QQ
    var isQQ = ua.indexOf("QQBrowser") > -1;
    // 返回结果
    if (isQQ) {
      return "QQ";
    }
    // 都不是
    return "";
  },

  // 文件下载
  download(url) {
    let a = document.createElement("a");
    a.href = url;
    a.download = true;
    a.click();
  },
  // 文件下载，支持txt/xml等格式的下载，可改名，暂时存在跨域问题
  downFn(url, name) {
    let uu = url.replace(/\+/g, "%2B"); //encodeURI(url)
    fetch(uu).then((res) => {
      res.blob().then((myBlob) => {
        const href = URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        a.href = href;
        if (name) {
          a.download = name; // 下载文件重命名
        } else {
          a.download = url.split("/").slice(-1)[0];
        }
        a.click();
        a.remove();
      });
    });
  },
  downloadFile(url, name, type, method) {
    url = url.replace(/\+/g, "%2B"); //encodeURI(url)
    var xhr = new XMLHttpRequest();
    xhr.open(method ? method : "GET", url, true);
    //xhr.setRequestHeader("Content-Type","application/json");
    xhr.responseType = "blob"; // 返回类型blob
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function (res) {
      // 请求完成
      if (this.status === 200) {
        // 返回200
        var blob = this.response;
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        console.log(res, blob, href, "导出");
        if (blob.type == "application/json" || blob.size == 0) {
          // Message({
          //   message: "导出失败",
          //   type: "error",
          // });
          return;
        }
        var date = new Date(); //实例一个时间对象；
        var y = date.getFullYear(); //获取系统的年；
        var M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1; //获取系统月份，由于月份是从0开始计算，所以要加1
        var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取系统日
        var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取系统时间
        var mm =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
        var ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
        name = name + "_" + y + M + d + h + mm + ss;

        //判断是否是IE浏览器，是的话返回true
        if (window.navigator.msSaveBlob) {
          try {
            window.navigator.msSaveBlob(blob, name + type);
          } catch (e) {
            console.log(e, "eee");
          }
        } else {
          // 谷歌浏览器 创建a标签 添加download属性下载
          var downloadElement = document.createElement("a");
          downloadElement.href = href;
          downloadElement.target = "_blank";
          downloadElement.download = name + type; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      } else {
        if (this.status == 404) {
          // Message({ message: "导出失败,文件未找到", type: "error" });
        } else {
          // Message({ message: "导出失败", type: "error" });
        }
        return;
      }
    };
    // 发送ajax请求
    xhr.send();
  },
  // get 文件流文件导出方法，后端返回无code时可以使用
  exportFile(url, name, type) {
    fetch(url, { headers: { access_token: store.state.user.access_token } })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw "下载失败";
        }
      })
      .then((blob) => {
        var href = window.URL.createObjectURL(blob);
        var downloadElement = document.createElement("a");
        downloadElement.href = href;
        downloadElement.target = "_blank";
        downloadElement.download = name + type;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.$message.error(err);
      });
  },

  // 将数据转为树状结构
  getTreeData(data, options, callback) {
    let props = {
      id: "id",
      parentId: "parentId",
      children: "children",
    };
    Object.assign(props, options);
    let temp = {};
    let result = [];
    for (let item of data) {
      temp[item[props.id]] = item;
      if (callback) callback(item);
    }
    for (let item of data) {
      let parentId = item[props.parentId];
      let parent = temp[parentId];
      if (!parent) {
        result.push(item);
      } else {
        (parent[props.children] || (parent[props.children] = [])).push(item);
      }
    }
    return result;
  },
  // 密码安全级别检测
  checkPwd(password) {
    let result = 0;
    let arr = new Array(0, 0, 0, 0);
    for (let i = 0; i < password.length; i++) {
      let asciiNumber = password.substr(i, 1).charCodeAt();
      if (asciiNumber >= 48 && asciiNumber <= 57) {
        arr[0] = 1; //digital
      } else if (asciiNumber >= 97 && asciiNumber <= 122) {
        arr[1] = 1; //lowercase
      } else if (asciiNumber >= 65 && asciiNumber <= 90) {
        arr[2] = 1; //uppercase
      } else {
        arr[3] = 1; //specialcase
      }
    }
    for (let i = 0; i < arr.length; i++) {
      result += arr[i];
    }
    switch (result - 1) {
      case 2:
        return "中";
      case 3:
        return "高";
      default:
        return "低";
    }
  },
  // 密码安全级别检测
  analyzePasswordSecurityLevel(password) {
    let securityLevelFlag = 0;
    if (password.length < 6) {
      return 0;
    } else {
      var securityLevelFlagArray = new Array(0, 0, 0, 0);
      for (var i = 0; i < password.length; i++) {
        var asciiNumber = password.substr(i, 1).charCodeAt();
        if (asciiNumber >= 48 && asciiNumber <= 57) {
          securityLevelFlagArray[0] = 1; //digital
        } else if (asciiNumber >= 97 && asciiNumber <= 122) {
          securityLevelFlagArray[1] = 1; //lowercase
        } else if (asciiNumber >= 65 && asciiNumber <= 90) {
          securityLevelFlagArray[2] = 1; //uppercase
        } else {
          securityLevelFlagArray[3] = 1; //specialcase
        }
      }

      for (let i = 0; i < securityLevelFlagArray.length; i++) {
        if (securityLevelFlagArray[i] == 1) {
          securityLevelFlag++;
        }
      }
      return securityLevelFlag - 1;
    }
  },
  // 遍历树状结构的数据
  eachTree(arr, callback, option = {}) {
    option.layer = option.layer || 1;
    for (let item of arr) {
      let args = { ...option };
      let children = item[option.children || "children"];
      if (children) {
        args.isLeaves = false;
        let res = callback(item, args);
        args.layer += 1;
        this.eachTree(children, callback, Object.assign({}, args, res));
      } else {
        args.isLeaves = true;
        callback(item, args);
      }
    }
  },
  // 根据文件字符串获取格式或图标,
  // str：带有格式扩展名的文件路径或文件名；
  // backType：返回类型：不传-返回格式名；1-返回格式对应的文件名（如xls文件返回excel）；2-返回格式对应的小图标路径；3-返回格式对应的大图片路径；
  getFormat(str, backType) {
    let format = "";
    if (str) {
      format = str.substring(str.lastIndexOf(".") + 1).toLowerCase();
    }
    if (backType) {
      let data = [
        { format: "excel", type: ["xls", "xlsx"] },
        { format: "word", type: ["doc", "docx"] },
        { format: "img", type: ["jpg", "jpeg", "png", "gif"] },
        { format: "music", type: ["mp3", "mp4"] },
        { format: "pdf", type: ["pdf"] },
        { format: "txt", type: ["txt"] },
        { format: "ppt", type: ["ppt"] },
      ];
      let img = "no";
      if (format) {
        data.forEach((el) => {
          if (el.type.findIndex((e) => e == format) > -1) {
            img = el.format;
          }
        });
      }
      if (backType == 1) {
        return img; //返回格式名称
      } else if (backType == 2) {
        return require("@/assets/image/office/ico_" + img + ".png"); //返回格式小图标
      } else if (backType == 3) {
        return require("@/assets/image/office/" + img + ".png"); //返回格式大图片
      }
    } else {
      return format; //返回格式名如docx、xml、png等，不带.
    }
  },
  //文本框插入内容函数封装，分为获取光标位置与设置光标位置
  // 获取光标位置
  getCursorPosFn(el, type) {
    //type为1获取光标开始位置，type为2获取结束位置
    let pos = 0;
    if (el.selectionStart || el.selectionStart === 0) {
      if (type == 1) {
        pos = el.selectionStart; // 获取选区开始位置
      } else {
        pos = el.selectionEnd; // 获取选区结束位置
      }
    }
    return pos;
  },
  // 设置光标位置
  setCaretPosFn(el, pos) {
    if (el.setSelectionRange) {
      el.focus();
      el.setSelectionRange(pos, pos);
    } else if (el.createTextRange) {
      const range = el.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  },

  // 统一处理三级目录
  getTabs(self, my, teamfulltime, teamOutsourced) {
    let userSign = store.state.user.userSign;
    // let staffType = store.state.user.staffType;
    let obj = {};
    // 企业显示团队
    if (userSign == "company") {
      // arr.push(teamfulltime);
      if (teamfulltime) {
        obj.hrm = teamfulltime;
      }
      if (teamOutsourced) {
        obj.hro = teamOutsourced;
      }
    } else {
      // 管理员显示团队
      if (self.isAdminObj["internal"]) {
        if (teamfulltime) {
          obj.hrm = teamfulltime;
        }
      }
      if (self.isAdminObj["outsourced"]) {
        if (teamOutsourced) {
          obj.hro = teamOutsourced;
        }
      }

      if (my) {
        obj.my = my;
      }
    }
    return obj;
  },

  // 生成头像
  async getAvatar(name, color) {
    if (!name || typeof name !== "string") throw "头像生成失败，名字错误";
    let str = name;
    if (name.length > 2) {
      // 去掉字符串的前name.length - 2 个字符
      str = name.substring(name.length - 2);
    }
    let colors = [
      "#ff9e48",
      "#fa9dc6",
      "#ff8a73",
      "#9e9dfb",
      "#fc5d7d",
      "#63d19f",
      "#ca9dfa",
      "#62b5d1",
      "#d6be5c",
      "#87cefa",
    ];
    let index = Math.floor(Math.random() * colors.length);
    let div = document.createElement("div");
    div.style.width = "98px";
    div.style.height = "98px";
    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.fontSize = "28px";
    if (!color) {
      div.style.backgroundColor = colors[index];
    } else {
      div.style.backgroundColor = color;
    }
    div.style.color = "#fff";
    div.style.justifyContent = "center";
    div.innerText = str;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, {
      width: 98,
      height: 98,
      scale: 1,
      dpi: 300,
    });
    let dataURL = canvas.toDataURL("image/jpg");
    document.body.removeChild(div);
    let file = this.dataURLtoFile(dataURL, Date.now().toString(16) + ".jpg");
    return Vue.prototype.$axios
      .upload(
        "/ossApi/putObjects",
        { file },
        { params: { fileDirType: "comp_img" } }
      )
      .then((res) => res.data[file.name]);
  },
  dataURLtoFile(dataUrl, fileName) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  },

  // [3, 4, 13 ,14, 15, 17, 20, 22] => [[3,4],[13,14,15],[17],[20],[22]]
  // 3, 4, 13 ,14, 15, 17, 20, 22 => [[3,4],[13,14,15],[17],[20],[22]]
  sortArrayByContinuous(arr) {
    if (!Array.isArray(arr)) {
      arr = arr.split(",");
    }
    if (!arr.length) {
      return arr;
    } else {
      var array = [];
      var temp = [+arr[0]];
      for (var i = 1, l = arr.length; i < l; i++) {
        if (+arr[i] === +arr[i - 1] + 1) {
          temp.push(+arr[i]);
        } else {
          array.push(temp);
          temp = [+arr[i]];
        }
      }
      array.push(temp);
      return array;
    }
  },

  //数组内数据相加--解决精度缺失
  actionOperationResult(vals) {
    let max = Math.max(
      ...vals.map((item) => item.toString().split(".")[1]?.length || 0)
    );
    const p = Math.pow(10, max);
    let result = 0;
    for (let item of vals) {
      result += Math.round(item * p);
    }
    return result / p;
  },

  //时间戳转换
  MillisecondToDate(value) {
    if (!value) {
      return false;
    }
    let date;
    if (typeof value == "string" && value.constructor == String) {
      date = new Date(value.replace(/-/g, "/"));
    } else {
      date = new Date(value);
    }
    let y = date.getFullYear(),
      m = date.getMonth() + 1,
      d = date.getDate(),
      h = date.getHours(),
      i = date.getMinutes(),
      s = date.getSeconds();
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    if (h < 10) {
      h = "0" + h;
    }
    if (i < 10) {
      i = "0" + i;
    }
    if (s < 10) {
      s = "0" + s;
    }
    let t = value ? y + "-" + m + "-" + d + " " + h + ":" + i : "--";
    return t;
  },
};

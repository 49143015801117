import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// //用户注册
// export const postRegReq = (data) => postReq(apiUrl1 + "/anon/register", data);

// //获取验证码
// export const getVerifCodeReq = (data) =>
//   getReq(apiUrl1 + "/anon/verifyCode", data);

// //用户登录
// export const postLoginReq = (data) =>
//   postReq(apiUrl1 + "/anon/generateToken", data);

// //退出登录
// export const getOutLoginReq = (data) => getReq(apiUrl1 + "/invalidToken", data);

// //忘记密码
// export const resetPsdReq = (data) =>
//   postReq(apiUrl1 + "/anon/resetPassword", data);

// //获取切换用户列表
// export const getUserListReq = (data) =>
//   getReq(apiUrl1 + "/changeUserList", data);
// export const changeUserReq = (data) => getReq(apiUrl1 + "/changeUser", data);

// //登录后修改密码（企业）
// export const postPsdComReq = (data) =>
//   postReq(apiUrl1 + "/cUser/updatePassWord", data);
// //登录后修改密码（员工）
// export const postPsdChildReq = (data) =>
//   postReq(apiUrl1 + "/cChildUser/updatePassWord", data);

// //登录后修改密码（个人）
// export const postPsdPerReq = (data) =>
//   postReq(apiUrl1 + "/iUser/updatePassWord", data);

// //三方登录
// export function getLoginToken(type, data) {
//   let url =
//     type == "qq"
//       ? "/anon/qqLogin"
//       : type == "wx"
//       ? "/anon/weChatLogin"
//       : type == "alipay"
//       ? "/anon/alipayLogin"
//       : "/anon/sinaWeiboLogin";
//   return postReq(apiUrl1 + url, data);
// }

export function getLoginToken(type, data) {
  let url =
    type == "qq"
      ? "/anon/qqLogin"
      : type == "wx"
      ? "/anon/weChatLogin"
      : type == "alipay"
      ? "/anon/alipayLogin"
      : "/anon/sinaWeiboLogin";
  return request({
    url: url,
    method: "post",
    data,
  });
}

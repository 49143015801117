export default {
  parent: "Task",
  routers: [
    // 我的执行的
    {
      path: "execute",
      name: "Execute",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
    // 我的参与的
    {
      path: "participation",
      name: "Participation",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
    // 我的负责的
    {
      path: "responsibility",
      name: "Responsibility",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
    // 我的创建的
    {
      path: "create",
      name: "Create",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
    // 我的分配的
    {
      path: "allocation",
      name: "Allocation",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
    // 我的审核的
    {
      path: "examine",
      name: "Examine",
      component: () => import("@/views/oa/task/TaskList.vue"),
    },
  ],
};

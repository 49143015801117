//猎头系统--List
export default function (axios) {
  return {
    listSaveReq(params) {
      return axios.post("/headhunter/listManage/save", params )
    },
    listSelectPageReq(params) {
      return axios.post("/headhunter/listManage/selectPage", params)
    },
    listCollectionReq(params) {
      return axios.get("/headhunter/listManage/listCollection?id=" + params.id)
    },
    cancelCollectionReq(params) {
      return axios.get("/headhunter/listManage/cancelCollection?id=" + params.id)
    },
    listDeleteByIdsReq(params) {
      return axios.get("/headhunter/listManage/deleteByIds?ids=" + params.ids)
    },
    selectParamByListTypeReq(params) {
      return axios.get("/headhunter/listManage/selectParamByListType?type=" + params.type)
    },
    browseAndDownloadIncreaseReq(params) {
      return axios.get("/headhunter/listManage/browseAndDownloadIncrease" + { params })
    },
    selectDetailByListIdReq(params) {
      return axios.get("/headhunter/listManage/selectDetailByListId?id=" + params.id)
    },
  }
}













<template>
  <div class="logo-item-container" @click.stop="">
    <div class="logo-item">
      <div class="flex-space">
        <div>显示</div>
        <div>
          <el-switch
            v-model="show"
            @change="change"
            active-color="#39c"
            inactive-color="#ccc"
          ></el-switch>
        </div>
      </div>
      <div class="upload-img-outer">
        <el-upload
          class="img-container flex-center"
          :action="apiUrl + '/minIOApi/oss/putObjects?fileDirType=comp_img'"
          :headers="token"
          :on-success="uplodeImgFn"
          accept="image/*"
          :show-file-list="false"
        >
          <img v-if="img" :src="img" />
          <div v-else class="el-icon-plus"></div
        ></el-upload>
      </div>
    </div>
    <div class="flex-space">
      <el-button
        size="mini"
        style="width: 62px"
        class="mt10"
        @click="reset('clear')"
        >清空</el-button
      >
      <el-button size="mini" style="width: 62px" class="mt10" @click="reset()"
        >重置</el-button
      >
    </div>
    <p class="notes">注：请上传高度在120px左右的正方形</p>
  </div>
</template>

<script>
import { apiUrl1 } from "@/api/asktrue/config";
export default {
  props: ["logo"],
  data() {
    return {
      token: "",
      show: false,
      img: "",
      apiUrl: apiUrl1,
    };
  },
  watch: {
    logo: {
      handler() {
        for (let prop in this.logo) {
          this[prop] = this.logo[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    for (let prop in this.logo) {
      this[prop] = this.logo[prop];
    }
  },
  methods: {
    reset(type) {
      type == "clear" ? (this.img = "") : (this.img = "");
      this.change();
    },
    //上传
    uplodeImgFn(res) {
      if (res.code == "200") {
        this.img = res.data.result;
        this.change();
      }
    },
    // logo数据变化
    change() {
      this.$emit(
        "dealChange",
        {
          show: this.show,
          img: this.img,
        },
        "logo"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.logo-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .notes {
    line-height: 20px;
    text-align: left;
    padding: 10px 0;
    color: #ccc;
  }
  &:hover .notes {
    color: #f60;
  }
  .logo-item {
    height: unset;
    border-bottom: 0px;
    overflow: hidden;
    .upload-img-outer {
      background: #fff;
      /deep/ .img-container {
        border: 1px solid #e4e4e4;
        line-height: 139px;
        width: 100%;
        height: 139px;
        .el-upload {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
        }
        .el-icon-plus {
          font-size: 24px;
          width: 100%;
          color: #ccc;
        }
      }
    }
    .cube-color-img {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      margin-right: 9px;
      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>

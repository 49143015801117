<template>
  <div class="icon-font">
    <div
      v-if="!right"
      class="iconfont"
      :class="[name]"
      @click="$emit('click', $event)"
    ></div>
    <div class="label">
      <slot></slot>
    </div>
    <div
      v-if="right"
      class="iconfont"
      :class="[name]"
      @click="$emit('click', $event)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "icon-font",
  props: {
    name: String,
    right: Boolean,
  },
};
</script>

<style lang="less" scoped>
.icon-font {
  .iconfont {
    color: inherit;
    font-size: inherit;
  }
}
</style>

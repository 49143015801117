<!--
 * @Description: 详情页面
 * @Date: 2023-11-17 15:19:08
 * @LastEditTime: 2023-11-22 13:33:02
-->
<template>
  <div class="main-warpper console-body asktrue">
    <header>
      <!-- 左侧 -->
      <div class="left">
        <router-link :to="{ name: '360' }" class="back cur">
          <em class="el-icon-arrow-left"></em>
        </router-link>
        <h1 class="ellipsis">
          <span :title="proData.exam_name">{{ proData.exam_name }}</span>
          <em
            class="iconfont iconshangxian"
            @click="examStatusChange()"
            title="点击下线"
            v-if="proData.exam_status == 1"
          ></em>
          <em
            class="iconfont iconxiaxian"
            @click="examStatusChange()"
            title="点击上线"
            v-if="proData.exam_status == 3"
          ></em>
        </h1>
      </div>

      <!-- 中间的菜单 -->
      <div class="menuUl no-select">
        <router-link
          v-if="ftype == 2"
          :class="{ on: activedRouteName == 'ProPgQuestionnaire' }"
          :to="{
            name: 'ProPgQuestionnaire',
            query: { id: id, type: type, ftype: ftype },
          }"
        >
          <span>问卷</span>
        </router-link>

        <router-link
          :class="{ on: activedRouteName == 'ProPgEvaluate' }"
          :to="{
            name: 'ProPgEvaluate',
            query: { id: id, type: type, ftype: ftype },
          }"
        >
          <span>评价</span>
        </router-link>

        <router-link
          :to="{
            name: 'ProPgData',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProPgData' }"
        >
          <span>数据</span>
        </router-link>
        <router-link
          :to="{
            name: 'ProPgScore',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProPgScore' }"
        >
          <span>成绩</span>
        </router-link>
        <router-link
          :class="{ on: activedRouteName == 'ProPgStatistics' }"
          :to="{
            name: 'ProPgStatistics',
            query: { id: id, type: type, ftype: ftype },
          }"
        >
          <span>统计</span>
        </router-link>
        <router-link
          :class="{ on: activedRouteName == 'ProPgNotice' }"
          :to="{
            name: 'ProPgNotice',
            query: { id: id, type: type, ftype: ftype },
          }"
        >
          <span>通知</span>
        </router-link>
      </div>

      <!-- 右侧的操作区域-->
      <div class="btn-area">
        <!-- 评估中不需要必填项--暂时屏蔽
          <span @click="getMustfillFn()" title="信息项设置"
          ><em class="iconfont iconmobanguanli"></em><span>信息项</span></span
        > -->
        <span @click="showPopup('isEdit')" title="基本设置">
          <em class="iconfont iconshezhi"></em>
          <span>编辑</span>
        </span>
        <span @click="showPopup('isShare')" title="链接设置">
          <em class="iconfont iconfenxiang"></em>
          <span>链接</span>
        </span>
        <!-- <span @click="showPopup('isMark')" title="阅卷设置">
          <em class="iconfont iconbs"></em><span>阅卷</span>
        </span> -->
        <span @click="showPopup('isStyle')" title="外观设置">
          <em class="iconfont iconwaiguan"></em>
          <span>外观</span>
        </span>
        <a
          class="btn"
          :href="
            proData.specify_student == 2 && !proData.exam_text
              ? `https://asktrue.co/#/exam/main/${proData.exam_id}/${proData.projectType}/${proData.vip_id}`
              : (proData.projectType == 2 || proData.projectType == 3) &&
                proData.real_name == 2
              ? `https://asktrue.co/#/exam/loginpg/${proData.exam_id}/${proData.projectType}/${proData.vip_id}/${userId}`
              : `https://asktrue.co/#/exam/login/${proData.exam_id}/${proData.projectType}/${proData.vip_id}/${userId}`
          "
          target="_blank"
        >
          <span>
            <em class="iconfont iconpreview1" title="预览"></em>
            <span>预览</span>
          </span>
        </a>
      </div>

      <router-link
        title="关闭"
        :to="{
          name: '360',
        }"
        class="close el-icon-close"
      ></router-link>
    </header>
    <router-view></router-view>
    <el-drawer
      :visible.sync="popup.show"
      :size="popup.size"
      :before-close="handleClose"
      :show-close="popup.isStyle ? false : true"
      :key="popup.key"
      custom-class="asktrue-drawer"
    >
      <EditForm
        v-if="popup.isEdit"
        :isOnOff="isOnOff"
        :proData="proData"
        @refresh="
          handleClose('isEdit');
          getProjectEditFn();
        "
      />
      <SharePopup
        v-if="popup.isShare"
        source="xm"
        :proData="proData"
        :examData="{
          examId: id,
          isJk: proData.face_recognition,
          type: type,
          vipId: proData.vip_id,
          goodStatus: proData.goods_status,
        }"
        @closePop="handleClose('isShare')"
      />
      <StylePopup
        v-if="popup.isStyle"
        :id="proData.exam_id"
        :type="proData.projectType"
        :ftype="proData.from_type"
        @close="handleClose('isStyle')"
      />
    </el-drawer>
  </div>
</template>

<script>
import Bus from "@/views/staff/js/bus"; //总线事件
import EditForm from "../../components/asktrue/EditProject"; //引入项目编辑表单
import SharePopup from "../../components/asktrue/PopShare"; //引入分享弹窗
import StylePopup from "./style"; //引入外观弹窗
import { getProjectEdit, delProjectReq } from "@/api/asktrue/project/project";

export default {
  components: {
    EditForm,
    SharePopup,
    StylePopup,
  },
  data() {
    return {
      id: 0,
      type: 0, //项目类型:1考试 2调查 3评估 4报名 5投票
      ftype: 0, //项目来源，2是自建，1是购买（不显示问卷）
      proData: {}, //详情数据
      // freeForm: [], //已设置自定义表单项   评估中不需要必填项--暂时屏蔽
      popup: {
        size: "70%", // 默认 70%
        show: false, //弹窗开关
        isEdit: false, //编辑开关
        isShare: false, // 分享开关
        isMark: false, // 阅卷设置
        isStyle: false, // 外观设置
        // isMustFillSet: false, //必填项设置弹窗展开状态   评估中不需要必填项--暂时屏蔽
        key: "",
      },
      isOnOff: {
        isTimeTerm: true, //有效期开关
        isAB: true, //AB卷开关
        isCheat: false, //防作弊开关
        isMonitor: false, //监控开关
        isMustFill: true, //必填项开关
      },
    };
  },
  activated() {
    console.log("activated");
  },
  created() {
    Bus.$on(
      "getData",
      function () {
        this.getProjectEditFn();
      }.bind(this)
    );

    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.ftype = this.$route.query.ftype;
    this.proData = {};
    this.getProjectEditFn();
  },
  computed: {
    userId() {
      return this.$store.state.asktrue.userInfo.userId;
    },
    activedRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    //获取详情数据
    getProjectEditFn() {
      let params = {
        examId: this.id,
        projectType: this.type,
      };
      getProjectEdit(params).then((res) => {
        if (res.code == "200") {
          this.proData = res.data.result;
          this.$store.dispatch("asktrue/setProInfo", this.proData);
          this.$emit("getList");
        }
      });
    },

    //上线下线
    examStatusChange() {
      let list = [
        {
          examId: this.proData.exam_id,
          projectType: this.proData.projectType,
        },
      ];
      let params = {
        opeType: this.proData.exam_status == 1 ? 2 : 1, //1-上线,2-下线
        list: list,
      };
      delProjectReq(params).then((res) => {
        if (res.code == "200") {
          let msg = params.opeType == 1 ? "上线" : "下线";
          this.$message.success(msg + "成功");
          this.getProjectEditFn();
        }
      });
    },

    // 显示弹窗
    showPopup(which) {
      Object.keys(this.popup).forEach((prop) => {
        if (typeof this.popup[prop] === "boolean") {
          this.popup[prop] = false;
        }
      });
      if (which === "isStyle") {
        this.popup.size = "100%";
      } else {
        this.popup.size = "70%";
      }
      this.popup.key = which;
      this.popup.show = true;
      this.popup[which] = true;
    },

    // 关闭弹窗
    handleClose(which) {
      this.popup.show = false;
      if (typeof which === "string") {
        this.popup[which] = false;
      }
    },
    // 评估中不需要必填项--暂时屏蔽
    // //必填项接口
    // getMustfillFn() {
    //   this.popup.isMustFillSet = true;
    //   // 调用新的接口
    //   let data = {
    //     examId: this.proData.exam_id,
    //     paperId: -1,
    //     paperTypeId: 0,
    //     projectType: this.proData.projectType,
    //   };
    //   console.log(data);
    //   newGetQuesInfoReq(data).then((res) => {
    //     if (res.code == "200") {
    //       this.freeForm = [];

    //       res.data.result.map((item) => {
    //         let value = "";
    //         let level = 1;
    //         if (
    //           item.infoTypeId != 1 &&
    //           item.infoTypeId != 4 &&
    //           (item.infoTypeId != 5) & (item.infoTypeId != 116)
    //         ) {
    //           //下拉
    //           value = [{ answer: "" }, { answer: "" }];
    //           level = item.xlLevelSet
    //             ? Number(item.xlLevelSet.split("#zbw#")[0])
    //             : 1;
    //         }
    //         let formObj = {
    //           examId: item.examId,
    //           infoId: item.infoId,
    //           title: item.infoText1,
    //           placeholder:
    //             level > 1 && item.xlLevelSet
    //               ? item.xlLevelSet.split("#zbw#")[1]
    //               : item.infoText2,
    //           placeholder2:
    //             level > 1 && item.xlLevelSet
    //               ? item.xlLevelSet.split("#zbw#")[2]
    //               : "",
    //           type: item.displayStyle == 1 ? "textarea" : "select",
    //           infoTypeId: item.infoTypeId,
    //           option: [],
    //           status: item.status,
    //           isPrev: true,
    //           xlLevelNeed: item.xlLevelNeed,
    //           isSys: item.startSystemAddr == 1 ? true : false,
    //           value,
    //           level,
    //         };
    //         //改变选项数组
    //         if (item.displayStyle == 2) {
    //           //下拉
    //           if (item.startSystemAddr == 1) {
    //             //启用系统数据
    //             let ts = [
    //               { type: "tSchool", infoTypeId: 8 },
    //               { type: "tMajor", infoTypeId: 7 },
    //               { type: "tTrade", infoTypeId: 14 },
    //               { type: "tCity", infoTypeId: 9 },
    //               { type: "tJobKind", infoTypeId: 12 },
    //             ];
    //             let parameterType = ts.find(
    //               (ele) => ele.infoTypeId == item.infoTypeId
    //             ).type;
    //             this.getOption(parameterType).then((data) => {
    //               formObj.option = data;
    //               //改变自定义表单数组
    //               this.freeForm.push(formObj);
    //             });
    //           } else {
    //             //文本
    //             item.list.map((oitem) => {
    //               formObj.option.push({
    //                 infoId: oitem.infoId,
    //                 label: oitem.optionText,
    //                 status: item.status == 2 ? 2 : oitem.status || 1,
    //                 id: oitem.selectId,
    //               });
    //             });
    //             this.freeForm.push(formObj);
    //           }
    //         } else {
    //           //文本
    //           item.list.map((oitem) => {
    //             formObj.option.push({
    //               infoId: oitem.infoId,
    //               label: oitem.optionText,
    //               status: item.status == 2 ? 2 : oitem.status || 1,
    //               id: oitem.selectId,
    //             });
    //           });
    //           this.freeForm.push(formObj);
    //         }
    //       });
    //     }
    //   });
    // },
    // //获取参数选项-学历、院校等
    // async getOption(item) {
    //   let tempOption = [];
    //   if (!item) {
    //     return;
    //   }
    //   await selectParams({ parameterType: item }).then((res) => {
    //     if (res.code == "200") {
    //       let selectArr = res.data.result || [];
    //       selectArr.forEach((ele) => {
    //         let valId = item == "tSchool" ? ele.city_id || "0000" : ele.value;
    //         let obj = {
    //           label: ele.label,
    //           id: valId,
    //           state: false,
    //           isShow: true,
    //           status: 1,
    //         };
    //         let selId = String(obj.id);
    //         if (selId.length < 2) {
    //           return false;
    //         }
    //         obj.id =
    //           String(valId).length / 2 == 2
    //             ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
    //             : String(valId).length / 2 == 3
    //             ? selId.substring(0, 2) +
    //               "-" +
    //               selId.substring(2, 4) +
    //               "-" +
    //               selId.substring(4, 6)
    //             : selId;
    //         tempOption.push(obj);
    //       });
    //       if (item == "tSchool" || item == "tCity") {
    //         //院校/地点
    //         tempOption = tempOption.concat(
    //           JSON.parse(
    //             JSON.stringify(getData("provinceVar")).replace(
    //               /value/g,
    //               "label"
    //             )
    //           )
    //         );
    //         tempOption.forEach((ele) => {
    //           ele.state = false;
    //           ele.status = 1;
    //         });
    //       }
    //     }
    //   });
    //   return tempOption;
    // },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/public.css";
.main-warpper {
  display: flex;
  flex-direction: column;
  padding: 70px 0 0 0;
  position: relative;
  height: 100vh;
  overflow: hidden;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 70px;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    // box-shadow: 0 0 3px rgba(125, 125, 125, 0.3);
    z-index: 10;
    padding: 0 20px 0 20px;

    .left {
      display: flex;
      align-items: center;
      min-width: 300px;
      // max-width: 30%;
      width: 30%;

      /*返回*/
      .back {
        margin-right: 20px;

        em {
          font-size: 20px;
          color: var(--second-theme-color);
          font-weight: 700;
        }

        span {
          font-size: 12px;
          vertical-align: middle;
        }
      }

      h1 {
        max-width: 300px;
        font-size: 22px;
        font-weight: 400;
        position: relative;
        padding-right: 30px;

        em {
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          font-size: 20px;
          cursor: pointer;

          &.iconshangxian {
            color: var(--second-theme-color);
          }
        }
      }

      .state {
        i {
          margin: 0 15px;
          color: #ccc;
        }

        span {
          font-size: 14px;
          color: var(--second-theme-color);
        }
      }
    }

    .menuUl {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      height: 100%;
      width: 0;
      padding: 0;
      background-color: #fff;

      a {
        color: #333;
        padding: 0 10px;
        font-size: 15px;
        position: relative;

        &.on {
          font-weight: 700;
          color: var(--second-theme-color);
          border-color: var(--second-theme-color);

          &::after {
            content: "";
            position: absolute;
            bottom: -26px;
            left: 50%;
            transform: translateX(-50%);
            width: 30%;
            height: 2px;
            background: var(--second-theme-color);
          }
        }
      }
    }

    .btn-area {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 30%;
      justify-content: flex-end;

      span {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 12px;
        color: #999;

        span {
          display: none;
        }

        em {
          font-size: 14px;
          color: inherit;
          cursor: pointer;
        }
      }
    }

    .close {
      font-size: 24px;
      color: #999;
      margin-left: 20px;
    }
  }
}
</style>

export default {
  routers: [
    // 首页
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/home/HomeView.vue"),
    },
    // 产品-阿仕图-考试
    {
      path: "/project/asktrue/ks",
      name: "project_asktrue1",
      component: () => import("@/views/home/ProjectAsktrue1.vue"),
    },
    // 产品-阿仕图-调查
    {
      path: "/project/asktrue/dc",
      name: "project_asktrue2",
      component: () => import("@/views/home/ProjectAsktrue2.vue"),
    },
    // 产品-阿仕图-评估
    {
      path: "/project/asktrue/pg",
      name: "project_asktrue3",
      component: () => import("@/views/home/ProjectAsktrue3.vue"),
    },
    // 产品-阿仕图-报名
    {
      path: "/project/asktrue/bm",
      name: "project_asktrue4",
      component: () => import("@/views/home/ProjectAsktrue4.vue"),
    },
    // 产品-阿仕图-投票
    {
      path: "/project/asktrue/tp",
      name: "project_asktrue5",
      component: () => import("@/views/home/ProjectAsktrue5.vue"),
    },

    // 产品-好招
    {
      path: "/project/matcheasy",
      name: "project_matcheasy",
      component: () => import("@/views/home/ProjectMatcheasy.vue"),
    },
    // 产品-猎头
    {
      path: "/project/hunteasy",
      name: "project_hunteasy",
      component: () => import("@/views/home/ProjectHunteasy.vue"),
    },
    // 产品-hrm
    {
      path: "/project/hrm",
      name: "project_hrm",
      component: () => import("@/views/home/ProjectHrm.vue"),
    },
    // 产品-hro
    {
      path: "/project/hro",
      name: "project_hro",
      component: () => import("@/views/home/ProjectHro.vue"),
    },
    // 关于我们
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/home/AboutView.vue"),
    },
    // 渠道合作
    {
      path: "/cooperation",
      name: "cooperation",
      component: () =>
        import(
          /* webpackChunkName: "cooperation" */ "@/views/home/CooperAtion.vue"
        ),
    },
    // 申请试用
    {
      path: "/try",
      name: "try",
      component: () =>
        import(/* webpackChunkName: "try" */ "@/views/home/TryView.vue"),
    },
    // 快速注册
    {
      path: "/logon",
      name: "logonView",
      component: () => import("@/views/bpo/logonView"),
    },
    // 邀请
    {
      path: "/invite",
      name: "emailInvite",
      component: () => import("@/views/bpo/emailInvite"),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/home/LoginView.vue"),
    },
    {
      path: "/oa",
      name: "OA",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/recruit",
      name: "Recruit",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/rpo",
      name: "RPO",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/staff",
      name: "Staff",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/salary",
      name: "Salary",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/performance",
      name: "Performance",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/other",
      name: "Other",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/manage",
      name: "Manage",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/Project",
      name: "AsktrueProject",
      component: () => import("@/views/project/IndexView.vue"),
    },
    {
      path: "/crm",
      name: "crm",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    {
      path: "/srm",
      name: "srm",
      component: () => import("@/views/manage/ManageView.vue"),
    },

    // 培训
    {
      path: "/train",
      name: "train",
      component: () => import("@/views/manage/ManageView.vue"),
    },

    // --------------独立页面-----------------------
    // 工资条
    {
      path: "/payslip",
      name: "Payslip",
      component: () => import("@/views/only/PaySlip.vue"),
    },
    // 员工信息完善页面
    {
      path: "/staff-info",
      name: "Staff-info",
      component: () => import("@/views/only/StaffInfo.vue"),
    },
    // 员工信息完善页面
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/only/Register.vue"),
    },

    // 好招--安排--报名↓
    {
      //报名--登录
      path: "/enter/login",
      name: "enterLogin",
      component: () => import("@/views/recruit/arrange/bm/loginView.vue"),
    },
    {
      //报名--考试框架页
      path: "/enter/main",
      name: "enterMain",
      component: () => import("@/views/recruit/arrange/bm/mainView.vue"),
    },
    {
      //交易记录详情独立页（邮件短信的超链接访问）
      path: "/transaction",
      name: "transaction",
      component: () => import("@/views/only/TransactionView.vue"),
    },
  ],
};

export default {
  parent: "CrmContract",
  routers: [
    {
      path: ":page",
      name: "CRMContractList",
      component: () => import("@/views/crm/contract/ContractList.vue"),
    },
    // // 合同列表未执行
    // {
    //   path: "no",
    //   name: "CRMContractList_no",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // // 执行中
    // {
    //   path: "in",
    //   name: "CRMContractList_in",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // // 已完成
    // {
    //   path: "done",
    //   name: "CRMContractList_done",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // {
    //   path: "invalid",
    //   name: "CRMContractList_invalid",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // {
    //   path: "all",
    //   name: "CRMContractList_all",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
  ],
};

<!-- 
使用说明：
v-model  双向绑定
placeholder  提示文字
@confirm  点击搜索按钮或者按回车触发事件
 -->
<template>
  <el-input
    class="search-input"
    :value="value"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
    @keyup.enter.native="$emit('confirm')"
  >
    <icon-font
      class="btn-search"
      slot="append"
      name="iconsousuo"
      @click.native="$emit('confirm')"
    />
  </el-input>
</template>

<script>
export default {
  name: "search-input",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.search-input {
  height: 34px;
  width: 200px;
  font-size: 12px;
  position: relative;
  /deep/.el-input__inner {
    padding-right: 40px;
  }
  /deep/.el-input-group__append {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    border-color: #e4e4e4;
    border-radius: 0 2px 2px 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-search {
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 13px;
      color: #bbb;
      cursor: pointer;
    }
  }
}
</style>

import { render, staticRenderFns } from "./message-face.vue?vue&type=template&id=bdccb2b4&scoped=true"
import script from "./message-face.vue?vue&type=script&lang=ts&setup=true"
export * from "./message-face.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./message-face.vue?vue&type=style&index=0&id=bdccb2b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdccb2b4",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./message-timestamp.vue?vue&type=template&id=8c01a29e&scoped=true"
import script from "./message-timestamp.vue?vue&type=script&setup=true&lang=ts"
export * from "./message-timestamp.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./message-timestamp.vue?vue&type=style&index=0&id=8c01a29e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c01a29e",
  null
  
)

export default component.exports
export default {
  parent: "SrmOrder",
  routers: [
    {
      path: ":page",
      name: "SRMOrderList",
      component: () => import("@/views/crm/order/OrderList.vue"),
    },
    // {
    //   // 全部
    //   path: "all",
    //   name: "SrmOrderAll",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已确认
    //   path: "confrimList",
    //   name: "SrmOrderConfrimList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 待确认
    //   path: "toList",
    //   name: "SrmOrderToList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已驳回
    //   path: "opposeList",
    //   name: "SrmOrderOpposeList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
    // {
    //   // 已废除
    //   path: "abrogateList",
    //   name: "SrmOrderAbrogateList",
    //   component: () => import("@/views/crm/order/OrderList.vue"),
    // },
  ],
};

/*
 * @Description: 合同接口 sign 可能是 hrm 、hro
 * @Date: 2023-01-12 08:49:03
 * @LastEditTime: 2023-09-01 13:58:47
 */

export default function (axios) {
  return {
    // 获取合同列表
    ContractList(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/getStaffContractPage`,
        data
      );
    },
    //新增
    addContract(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/insertStaffContract`,
        data
      );
    },
    //删除
    delContract(params, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/delStaffContract?ids=` + params
      );
    },
    //合同回显
    getContract(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/getStaffContract`,
        data
      );
    },
    //修改
    updateContract(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/updateStaffContract`,
        data
      );
    },

    // 查询单个员工所有合同
    getStaffContractList(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/getStaffContractList`,
        data
      );
    },

    // getOverviewData  /internal/staffContractCtl/selectStaffContractSummary  查询合同汇总数据
    selectStaffContractSummary(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/staffContractCtl/selectStaffContractSummary`,
        data
      );
    },
  };
}

export default {
  parent: "Address",
  routers: [
    {
      path: "addressBook",
      name: "AddressBook",
      component: () => import("@/views/address/addressBook"),
      meta: {
        title: "通讯录",
      },
    },
  ],
};

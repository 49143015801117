<!--
  // 附件组件传参
  prop: {
    fileDirType: "comp_img",//图片上传路径参数
    limit: 10,//最大上传个数
    buttonName: "点击上传",//控件上传按钮/文本文字
    styleType: "button", //控件样式类型：button按钮样式（默认）；font文本样式；single头像模式
    style: "width:auto;height:34px;", //控件样式
    field: {
      // 附件返回字段
      id: "attachId",
      name: "attachName",
      url: "attachUrl",
    },
  },
-->
<template>
  <div class="uploadFile">
    <el-upload
      ref="upload"
      class="upload-btn"
      :action="
        (noToken.isNose
          ? $utils.apiBaseUrl + actionNose
          : $utils.apiBaseUrl + action) + prop.fileDirType
      "
      :headers="{ access_token: access_token }"
      multiple
      :limit="prop.limit"
      :show-file-list="false"
      :on-success="handleSuccess"
      :file-list="uploadFileList"
    >
      <el-button
        :style="prop.style"
        v-if="prop.styleType == 'button'"
        ref="uploadBtn"
      >
        <em class="iconfont iconannex"></em><span>{{ prop.buttonName }}</span>
      </el-button>
      <span
        class="font"
        :style="prop.style"
        v-if="prop.styleType == 'font'"
        ref="uploadBtn"
      >
        <em class="iconfont iconannex"></em><span>{{ prop.buttonName }}</span>
      </span>
      <div
        class="singleBox"
        :style="prop.style"
        v-if="prop.styleType == 'single'"
      >
        <img v-if="singleUrl" :src="singleUrl" class="single" />
        <i class="el-icon-plus" v-else></i>
      </div>
    </el-upload>
    <ul
      class="uploadFileList"
      v-show="
        uploadFileList && uploadFileList?.length && prop.styleType != 'single'
      "
    >
      <li v-for="(ff, fi) in uploadFileList" :key="'file_' + fi">
        <el-image :src="ff.ico" class="defaultImg"></el-image>
        <span class="fileName slh">{{ ff.name }}</span>
        <a @click="viewFile(ff)">预览</a>
        <el-divider direction="vertical"></el-divider>
        <a @click="removeFile(fi)">删除</a>
        <el-checkbox
          v-model="ff.checked"
          @change="uploadFileListChange"
          v-if="isChoose && from == 'AppDetail'"
          >{{ chooseName }}</el-checkbox
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "g-uploadfile",
  props: {
    value: [Array, String], //双向绑定参数，当styleType=='single'是为String类型，图片路径；其他是数组格式
    prop: {
      type: Object,
      default: () => {
        return {
          fileDirType: "comp_img",
          limit: 10,
          buttonName: "点击上传",
          styleType: "button", //控件样式类型：button按钮样式（默认）；font文本样式；single头像模式
          style: "width:auto;height:34px;padding:0 10px;", //控件样式
          field: {
            name: "attachName",
            url: "attachUrl",
          },
        };
      },
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    noToken: {
      type: Object,
      default: () => ({
        isNose: false,
        userId: null,
        vipId: null,
      }),
    },

    // 以下传参是审批中用到的特殊处理
    from: String, //AppDetail来自审批意见
    isChoose: { type: Boolean, default: false }, //是否存在选择功能
    chooseName: String, //存在选择功能时才起作用，显示选项名称
  },
  computed: {
    ...mapGetters("user", ["access_token"]),
  },
  data() {
    return {
      action: "/ossApi/putObjects?fileDirType=",
      actionNose:
        "/ossApi/putObjectsNose?userId=" +
        this.noToken.userId +
        "&vipId=" +
        this.noToken.vipId +
        "&fileDirType=",
      uploadFileList: [],
      checked: [],
      singleUrl: null, //头像模式图片路径
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.prop.styleType == "single" && this.value) {
          this.singleUrl = this.value;
        } else if (this.prop.styleType != "single" && this.value?.length) {
          this.uploadFileList = [];
          this.value.forEach((ele, eli) => {
            let j = this.checked.findIndex((x) => x == eli);
            this.uploadFileList.push({
              name: ele[this.prop.field.name],
              url: ele[this.prop.field.url],
              ico: this.$utils.getFormat(ele[this.prop.field.url], 2),
              checked: j > -1 ? true : false,
            });
          });
        }
      },
      immediate: true,
    },
    // uploadFileList: {
    //   handler() {
    //     this.emitList();
    //   },
    //   deep: true,
    // },
  },
  methods: {
    //附件上传成功
    handleSuccess(res, file) {
      console.log(res, file, "????");
      if (res.code == "200") {
        if (this.prop.styleType == "single") {
          this.$set(this, "uploadFileList", []);
          this.$set(
            this,
            "singleUrl",
            res.data[file.name] // + this.$utils.fileToPng
          );
        } else {
          this.uploadFileList.push({
            name: file.name,
            url: res.data[file.name],
            ico: this.$utils.getFormat(res.data[file.name], 2),
            checked: false,
          });
        }
        this.emitList();
      }
    },
    viewFile(ff) {
      let str = this.$utils.getFormat(ff.url, 1);
      let title = this.$utils.safeTextToBase64(ff.name);
      str = str == "img" ? "" : this.$utils.fileToHtml + title;
      window.open(ff.url + str);
    },
    removeFile(index) {
      this.uploadFileList.splice(index, 1);
      this.emitList();
    },
    uploadFileListChange() {
      let arr = [];
      this.checked = [];
      this.uploadFileList.forEach((e, i) => {
        if (e.checked) {
          arr.push(e);
          this.checked.push(i);
        }
      });
      this.$emit("evidence", arr);
      this.emitList();
    },
    // 返回数据
    emitList() {
      if (this.prop.styleType == "single") {
        this.$emit("input", this.singleUrl);
      } else {
        let arr = [];
        this.uploadFileList.forEach((ele, index) => {
          arr.push({
            [this.prop.field.id]: index + 1,
            [this.prop.field.name]: ele.name,
            [this.prop.field.url]: ele.url,
            [this.prop.field.checked]: ele.checked || false,
          });
        });
        this.$emit("input", arr);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.uploadFile {
  /deep/.el-upload {
    display: block;
    .iconannex {
      margin-right: 5px;
      font-size: unset;
    }
    .el-button {
      width: auto;
      height: 34px;
      padding: 0 12px;
      background-color: #fff;
      color: #606266;
      border-color: #e4e4e4;
    }
    .font {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .singleBox {
      width: 60px;
      height: 60px;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-icon-plus {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: 16px;
        color: #999;
      }
    }
  }
  .uploadFileList {
    margin-top: 10px;
    li {
      font-size: inherit;
      color: #999;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 8px;
      }
      .defaultImg {
        width: 16px;
        height: 16px;
        overflow: hidden;
        margin-right: 8px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .fileName {
        margin-right: 30px;
        word-break: break-all;
      }
      a {
        color: @color;
        font-size: 12px;
        flex-shrink: 0;
      }
      .el-divider {
        margin: 0 12px;
      }
      .el-checkbox {
        margin-left: 30px;
        font-size: 12px;
        align-items: center;
        .el-checkbox__label {
          line-height: 1;
        }
      }
    }
  }
}
</style>

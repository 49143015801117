<template>
  <div class="part-wapper">
    <div class="yj_set_box">
      <div class="set_box">
        <div class="l lr">
          <div class="lrTit"><span>全部试题</span></div>
          <div class="allBox no-scroll">
            <div
              class="tx"
              v-for="(item, index) in other.paperNumList"
              :key="'tx_' + index"
              v-show="item.st.filter((e) => e.ed == false).length > 0"
            >
              <h3>{{ item.tx }}</h3>
              <div class="st">
                <i
                  v-for="(ele, eli) in item.st.filter((e) => !e.ed)"
                  :key="'st_' + index + '_' + eli"
                  :class="[
                    { ed: ele.ed },
                    { on: other.stOn.findIndex((e) => e == ele.id) > -1 },
                  ]"
                  @click="stOnFn(ele)"
                  v-show="!ele.ed"
                  >{{
                    other.stList.find((ee) => {
                      return ee.id == ele.id;
                    }).sort
                  }}</i
                >
              </div>
            </div>
          </div>
        </div>
        <div class="r lr">
          <div class="lrTit">
            <span>阅卷模块</span
            ><i
              class="iconfont iconjia"
              title="新增模块"
              @click="addPartFn()"
            ></i>
          </div>
          <div class="partbox no-scroll">
            <div
              class="part"
              v-for="(item, index) in other.partList"
              :key="'part_' + index"
            >
              <div class="partTit">
                <span>Part{{ index + 1 }}</span>
                <i
                  class="iconfont iconcuo"
                  @click="delPartFn(item.partId)"
                  :title="'删除Part' + (index + 1)"
                ></i>
              </div>
              <i
                class="icoToOut iconfont iconzuojiankuohao"
                @click="removePartFn(item.partId)"
                title="移除选中"
                v-if="
                  other.part.findIndex((e) => e == index) > -1 &&
                  other.stOut.length > 0
                "
              ></i>
              <i
                class="icoToOut iconfont iconyoujiankuohao"
                @click="toPartFn(index)"
                :title="'添到Part' + (index + 1)"
                v-if="other.stOn.length > 0"
              ></i>
              <div class="st">
                <i
                  v-for="(ele, eli) in item.children"
                  :key="'th_' + index + '_' + eli"
                  :class="{ on: other.stOut.findIndex((e) => e == ele) > -1 }"
                  @click="stOutFn(item.children, ele, index)"
                  >{{
                    other.stList.find((e) => {
                      return e.id == ele;
                    }).sort
                  }}</i
                >
              </div>
            </div>
            <div class="part" v-if="other.partList.length == 0"></div>
          </div>
        </div>
      </div>
      <div class="func">
        <el-button type="primary" @click="savePartFn()">保存</el-button>
        <el-button @click="other.isPartPop = false">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaperQuesListReq,
  selectPaperQuestion,
} from "@/api/asktrue/paper/paper";
import {
  selectPaperPartQuestionPro,
  addPaperPartPro,
  removePaperPartPro,
  addPaperPartQuestionPro,
  removePaperPartQuestionPro,
} from "@/api/asktrue/project/project";
export default {
  props: {
    part: Object,
  },
  data() {
    return {
      other: {
        isPartPop: false,
        stList: [],
        paperNumList: [],
        stOn: [], //选择试题过渡使用，左侧追加时使用
        stOut: [], //选择试题过渡使用，右侧移除时使用
        partList: [],
        partlistData: [],
        part: [], //哪些part存在已选项
        partData: [],
      },
    };
  },
  created() {
    this.getPaperQuesList();
  },
  methods: {
    // 获取问卷试题id及序号
    async getPaperQuesList() {
      await getPaperQuesListReq({
        pageNum: 1,
        pageSize: 999999,
        paperId: this.part.paperId,
      }).then((res) => {
        if (res.code == "200") {
          this.other.stList = [];
          res.data.result.list.forEach((ele) => {
            if (ele.p_q == 1) {
              //题组
              ele.questionInfoList.forEach((el, index) => {
                this.other.stList.push({
                  sort: Number(ele.sort) + index,
                  id: el.questionId,
                });
              });
            } else {
              //试题
              this.other.stList.push({
                sort: Number(ele.sort),
                id: ele.questionId,
              });
            }
          });
          this.selectPaperQuestion();
        }
      });
    },
    // 获取问卷题型及题型下试题
    async selectPaperQuestion() {
      await selectPaperQuestion({ paperId: this.part.paperId }).then((res) => {
        if (res.code == "200") {
          this.other.paperNumList = [];
          let pnl = res.data.result;
          let aI = [];
          let aV = [];
          for (let i in pnl) {
            aI.push(i); //key
            aV.push(pnl[i]); //值
            let aT = [];
            pnl[i].forEach((ele) => {
              aT.push({ id: ele.questionId, ed: false });
            });
            this.other.paperNumList.push({ tx: i, st: aT });
          }
          this.getPartFn();
        }
      });
    },
    // 获取part
    async getPartFn(msg) {
      let params = JSON.parse(JSON.stringify(this.part));
      delete params.show;
      await selectPaperPartQuestionPro(params).then((res) => {
        if (res.code == "200") {
          this.other.partList = [];
          this.other.partlistData = res.data.result;
          let pl = res.data.result;
          let aI = [];
          for (let i in pl) {
            aI.push(i); //key
            let aT = [];
            if (pl[i].length == 1 && !pl[i][0].questionId) {
              aT = [];
            } else {
              pl[i].forEach((ele) => {
                aT.push(ele.questionId);
              });
            }
            this.other.partList.push({ partId: i, children: aT });
          }
          this.setPartDataFn();
          if (msg) {
            this.$message.success(msg);
          }
          this.other.isPartPop = true;
        }
      });
    },
    // 新增Part
    addPartFn() {
      let params = JSON.parse(JSON.stringify(this.part));
      delete params.show;
      addPaperPartPro(params).then((res) => {
        if (res.code == "200") {
          this.getPartFn("新增成功");
        }
      });
    },
    // 删除Part
    delPartFn(id) {
      let params = {
        partId: id,
        examId: this.part.examId,
        projectType: this.part.projectType,
        paperTypeId: this.part.paperTypeId,
      };
      removePaperPartPro(params).then((res) => {
        if (res.code == "200") {
          this.getPartFn("删除成功");
        }
      });
    },
    // 左侧选择试题
    stOnFn(ele) {
      this.other.stOut = [];
      let i = this.other.stOn.findIndex((e) => e == ele.id);
      if (i == -1) {
        //没有选中，追加选中
        this.other.stOn.push(ele.id);
      } else {
        //有选中，取消选中
        this.other.stOn.splice(i, 1);
      }
    },
    // 往part中追加试题
    toPartFn(index) {
      // 以下被屏蔽代码不要删除，本代码是在仅前端往part中追加试题时使用，在不独立操作接口，而是最终统一保存时使用
      // let child=this.other.partList[index].children.concat(this.other.stOn);
      // let arr=this.other.stList.filter(ele=>child.indexOf(ele.id)!=-1)
      // arr.sort((a,b)=>{return a.sort-b.sort})
      // console.log(arr,'==arr');
      // this.other.partList[index].children=[];
      // arr.forEach(el=>{
      //   this.other.partList[index].children.push(el.id);
      // })
      let child = this.other.partList[index].children.concat(this.other.stOn);
      let arr = this.other.stList.filter((ele) => child.indexOf(ele.id) != -1);
      let questionList = [];
      arr.forEach((ele) => {
        questionList.push({
          examId: this.part.examId,
          paperTypeId: this.part.paperTypeId,
          paperId: this.part.paperId,
          partId: this.other.partList[index].partId,
          questionId: ele.id,
          sort: ele.sort,
        });
      });
      let params = {
        paperId: this.part.paperId,
        partId: this.other.partList[index].partId,
        questionList: questionList,
        examId: this.part.examId,
        projectType: this.part.projectType,
        paperTypeId: this.part.paperTypeId,
      };
      addPaperPartQuestionPro(params).then((res) => {
        if (res.code == "200") {
          this.getPartFn("添加成功");
        }
      });
    },
    // 右侧选择试题
    stOutFn(item, ele, index) {
      this.other.stOn = [];
      let i = this.other.stOut.findIndex((e) => e == ele);
      if (i == -1) {
        //没有选中，追加选中
        this.other.stOut.push(ele);
        if (this.other.part.findIndex((e) => e == index) == -1) {
          this.other.part.push(index);
        }
      } else {
        //有选中，取消选中
        this.other.stOut.splice(i, 1);
        let l = this.other.stOut.filter(function (n) {
          return item.indexOf(n) != -1;
        }).length;
        if (l == 0) {
          this.other.part.splice(
            this.other.part.findIndex((e) => e == index),
            1
          );
        }
      }
    },
    // 从part中移除试题
    removePartFn(partId) {
      // 以下暂不删除，属于静态操作
      // let stOut=JSON.parse(JSON.stringify(this.other.stOut))
      // stOut.forEach(el=>{
      //   let $=this.other.partList[index].children
      //   let i=$.indexOf(el)
      //   if(i>-1){
      //     $.splice(i,1)
      //     this.other.stOut.splice(this.other.stOut.findIndex(e=>e==el),1)
      //   }
      // })
      // this.other.part.splice(this.other.part.findIndex(e=>e==index),1);
      // 以下属于动态操作
      console.log(this.other.partlistData[partId]);
      let ids = [];
      let qids = [];
      this.other.partlistData[partId].forEach((ele) => {
        if (this.other.stOut.findIndex((el) => el == ele.questionId) > -1) {
          ids.push(ele.id);
          qids.push(ele.questionId);
        }
      });
      removePaperPartQuestionPro({ id: ids.join(",") }).then((res) => {
        if (res.code == "200") {
          qids.forEach((el) => {
            this.other.stOut.splice(
              this.other.stOut.findIndex((e) => e == el),
              1
            );
          });
          this.getPartFn("移除成功");
        }
      });
    },
    // 设置当前所有已试题
    setPartDataFn() {
      this.other.stOn = [];
      this.other.partData = [];
      this.other.partList.forEach((ele) => {
        this.other.partData = this.other.partData.concat(ele.children);
      });
      this.other.paperNumList.map((ele) => {
        ele.st.map((el) => {
          el.on = false;
          el.ed =
            this.other.partData.findIndex((e) => e == el.id) == -1
              ? false
              : true;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-drawer.partPop {
  box-shadow: -2px 4px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  .el-drawer__header {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .el-drawer__body {
    overflow: hidden;
    .part-wapper {
      height: 100%;
    }
    .yj_set_box {
      padding: 20px 30px 20px;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      .set_box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .lr {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .lrTit {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 20px;
            margin-bottom: 10px;
            i {
              cursor: pointer;
              margin-left: 15px;
            }
          }
          &.l {
            width: 330px;
          }
          &.r {
            width: 310px;
            flex: 1;
            align-items: flex-end;
            .lrTit {
              width: 330px;
            }
          }
          .allBox {
            border: 1px solid #e4e4e4;
            flex: 1;
            overflow: hidden;
            overflow-y: auto;
            padding: 5px 10px 10px;
            .tx {
              margin: 0 0 10px;
              padding-bottom: 0%;
              border-bottom: 0px dashed #e4e4e4;
              &:last-child {
                border-bottom: 0px;
              }
              h3 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                font-weight: 400;
                font-size: 14px;
                border-bottom: 1px dashed #fff;
              }
              .st {
                display: flex;
                flex-wrap: wrap;
                // margin: 0 -2.5%;
                gap: 3.25%;
                i {
                  width: 11.5%;
                  height: 24px;
                  line-height: 22px;
                  border: 1px solid #e4e4e4;
                  padding: 0 0px;
                  // margin: 0px 2.5% 0px 2.5%;
                  text-align: center;
                  font-size: 12px;
                  border-radius: 2px;
                  cursor: pointer;
                  &:nth-child(n + 8) {
                    margin-top: 10px;
                  }
                  &.ed {
                    display: none;
                  }
                  &.on {
                    background-color: var(--second-theme-color);
                    border-color: var(--second-theme-color);
                    color: #fff;
                    cursor: default;
                  }
                }
              }
            }
          }
          .partbox {
            flex: 1;
            overflow: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 200px;
            align-items: flex-end;
            .part {
              border: 1px solid #e4e4e4;
              border-radius: 2px;
              flex: 1;
              position: relative;
              width: 330px;
              .partTit {
                width: 100%;
                height: 32px;
                border-bottom: 1px solid #eee;
                background-color: #fafafa;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px 0 20px;
                span {
                  font-size: 14px;
                }
                i {
                  font-size: 14px;
                  cursor: pointer;
                }
              }
              .icoToOut {
                position: absolute;
                top: 50%;
                left: -45px;
                font-size: 20px;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                background-color: #f8f8f8;
                color: #999;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                  background-color: var(--second-theme-color);
                  color: #fff;
                }
              }
              .partNum {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 24px;
                height: 24px;
                z-index: 10;
                border-top-left-radius: 2px;
                overflow: hidden;
                &::before {
                  content: "";
                  width: 0px;
                  height: 0px;
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  z-index: 1;
                  border-top: 12px solid var(--second-theme-color);
                  border-right: 12px solid transparent;
                  border-bottom: 12px solid transparent;
                  border-left: 12px solid var(--second-theme-color);
                }
                i {
                  position: absolute;
                  z-index: 2;
                  color: #fff;
                  left: 28%;
                  top: 28%;
                  font-size: 12px;
                  transform: translate(-50%, -50%);
                }
              }

              .st {
                // display: flex;
                // flex-wrap: wrap;
                // min-height: 40px;
                // justify-content: flex-start;
                // align-items: self-start;
                // align-content: flex-start;
                // flex: 1;
                margin: 10px 0px;
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                gap: 3.25%;
                i {
                  width: 11.5%;
                  height: 24px;
                  line-height: 22px;
                  border: 1px solid #e4e4e4;
                  padding: 0 0px;
                  margin: 0px 2.5% 0px 2.5%;
                  text-align: center;
                  font-size: 12px;
                  border-radius: 2px;
                  background-color: #fff;
                  cursor: pointer;
                  &:nth-child(n + 6) {
                    margin-top: 10px;
                  }
                  &.on {
                    background-color: var(--second-theme-color);
                    border-color: var(--second-theme-color);
                    color: #fff;
                    cursor: default;
                  }
                }
              }
              &:not(:first-child) {
                margin-top: 5%;
              }
            }
          }
        }
      }
      .func {
        position: absolute;
        bottom: 0px;
        height: 80px;
        display: flex;
        align-items: center;
        display: none;
        .el-button {
          padding: 8px 30px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>

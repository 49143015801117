// 打卡
export default function (axios) {
  return {
    // 打卡——
    // 查询打卡列表
    // 日报 (团队、个人)
    selectAttendance(params) {
      return axios.post("/cPunchCard/selectPage", params);
    },
    // 月报列表
    selectAttendanceYb(params) {
      return axios.get("/cPunchCard/selectMonthList", { params });
    },
    // 查看详情
    attendanceDetial(params) {
      return axios.get("/cPunchCard/selectDetail", { params });
    },
    // 校准补卡 缺卡
    updatePunchCard(params) {
      if (params.patchCardTime) {
        return axios.post("/cPunchCard/updatePunchCard", params);
      } else {
        return axios.get("/cPunchCard/deletePunchCard", { params });
      }
    },
    // 判断是否可以打卡
    isPunchCard() {
      return axios.get("/cPunchCard/selectIsOrNotPunchCard");
    },
    // 新增打卡
    addPunchCard(params) {
      return axios.post("/cPunchCard/addPunchCard", params);
    },
    // 导出 日报
    exportPunchCard(params) {
      return axios.post("/cPunchCard/export", params);
    },
    // 导出 月报
    exportPunchCardMonth(params) {
      return axios.post("/cPunchCard/exportMonthReport", params);
    },
    // 个人/成员月报数据汇总
    personalDataSummary(params) {
      return axios.post("/cPunchCardSummary/selectMyMonthSummary ", params);
    },
    // 查询团队打卡月报数据汇总
    teamDataSummary(params) {
      return axios.post("/cPunchCardSummary/selectTeamMonthSummary", params);
    },

    //********************************************************************* */

    // 假勤——列表（日报、月报、年报）
    // 统计数据
    jq_List(params) {
      return axios.get("/vacation/getVacationData", { params });
    },

    // 假勤——个人列表（月报、年报）
    jq_ListUser(params) {
      return axios.get("/vacation/getVacationDataByUser", { params });
    },

    // 假勤——设置-查（列表）
    jq_setList() {
      return axios.get("/vacation/getVacationSet");
    },
    // 假勤——设置——保存-请假qj、出差cc、外勤wq、加班jb
    jq_setSave(params, type) {
      let url = "";
      if (type == "qj") {
        url = "/vacation/leaveType/saveLeaveType";
      } else if (type == "cc") {
        url = "/vacation/businessTripType/saveBusinessTripType";
      } else if (type == "wq") {
        url = "/vacation/outworkType/saveOutworkType";
      } else if (type == "jb") {
        url = "/vacation/workOvertimeType/saveWorkOvertimeType";
      }
      return axios.post(url, params);
    },
    // 假勤——设置——删除-请假qj、出差cc、外勤wq、加班jb
    jq_setDel(params, type) {
      let url = "";
      if (type == "qj") {
        url = "/vacation/leaveType/deleteLeaveTypeById";
      } else if (type == "cc") {
        url = "/vacation/businessTripType/deleteBusinessTripTypeById";
      } else if (type == "wq") {
        url = "/vacation/outworkType/deleteOutworkTypeById";
      } else if (type == "jb") {
        url = "/vacation/workOvertimeType/deleteWorkOvertimeTypeById";
      }
      return axios.post(url, {}, { params });
    },
    // 假勤——设置——查详情-请假qj、出差cc、外勤wq、加班jb
    jq_setDetail(params, type) {
      let url = "";
      if (type == "qj") {
        url = "/vacation/leaveType/getLeaveTypeById?leaveTypeId=";
      } else if (type == "cc") {
        url =
          "/vacation/businessTripType/getBusinessTripTypeById?businessTripId=";
      } else if (type == "wq") {
        url = "/vacation/outworkType/getOutworkTypeById?outworkTypeId=";
      } else if (type == "jb") {
        url =
          "/vacation/workOvertimeType/getWorkOvertimeTypeById?workOvertimeId=";
      }
      return axios.get(url + params.id);
    },
    // // 获取假勤统计数据个人
    // jq_getVacationTongji(params) {
    //   return axios.get("/vacation/getVacationDataByUser");
    // },

    // 假勤——查-设置-节假日设置
    jq_setHolidaySave(params) {
      return axios.post("/vacation/holidaySet/saveHolidaySet", params); //保存节假日设置（可批量）
    },
    jq_setHolidayClean(params) {
      return axios.post("/vacation/holidaySet/cleanHolidaySet", params); //清除节假日设置（可批量）
    },
    jq_setHolidayGet(params) {
      return axios.get("/vacation/holidaySet/getHolidaySet", { params }); //获取节假日设置数据
    },

    // 假勤——查-设置-同步节假日
    jq_xxx(params) {
      return axios.get(
        "https://timor.tech/api/holiday/year/" + params.year + "/"
      );
    },

    // 假勤——获取计算时长
    jq_calDuration(params) {
      return axios.get("/vacation/calDuration", { params }); //计算假勤时长
    },
    // 审批——获取计算普通时长
    jq_calDurationCommon(params) {
      return axios.get("/vacation/calDurationCommon", { params }); //计算普通时长
    },

    // 假勤——获取假勤统计数据
    jq_getCount(params) {
      return axios.get("/vacation/getCount", { params }); //计算假勤时长
    },
    // 假勤——假勤汇总（个人）
    jq_getCountByUser(params) {
      return axios.get("/vacation/getCountByUser", { params }); //计算假勤时长
    },

    //********************************************************************* */

    // 统计——查（列表）

    // /statistics/selectStatisticsDataDay   查询统计数据日报
    getStatisticsDataDay(data, params) {
      return axios.post("/statistics/selectStatisticsDataDay", data, {
        params,
      });
    },

    // /statistics/selectStatisticsDataMonth   查询统计数据月报
    getStatisticsDataMonth(data, params) {
      return axios.post("/statistics/selectStatisticsDataMonth", data, {
        params,
      });
    },

    // /statistics/selectDayTopSummary  查询统计数据日报汇总（上半部分）
    getStatisticsOverviewDay(data, params) {
      return axios.post("/statistics/selectDayTopSummary", data, {
        params,
      });
    },

    // /statistics/selectMonthTopSummary  查询统计数据月报汇总（上半部分）
    getStatisticsOverviewMonth(data, params) {
      return axios.post("/statistics/selectMonthTopSummary", data, {
        params,
      });
    },

    // /statistics/exportStatisticsDay  统计导出日报
    exportStatisticsDay(data) {
      return axios.post("/statistics/exportStatisticsDay", data);
    },

    // /statistics/exportStatisticsMonth   统计导出月报
    exportStatisticsMonth(data) {
      return axios.post("/statistics/exportStatisticsMonth", data);
    },

    //********************************************************************* */

    // // 查询统计数据月报ddj
    // jq_dataMonth(data) {
    //   return axios.post("/statistics/selectStatisticsDataMonth", data);
    // },

    // getStatisticsDataMonth(data, params) {
    //   return axios.post("/statistics/selectStatisticsDataMonth", data, {
    //     params,
    //   });
    // },

    // 规则——查（列表）

    // 新增打卡规则
    saveAttendanceRule(data) {
      return axios.post("/clockRuleCtl/saveRule", data);
    },
    // 打卡规则列表
    getAttendanceRuleList(data) {
      return axios.post("/clockRuleCtl/getClockRuleList", data);
    },
    // /clockRuleCtl/delRule 删除打卡规则
    deleteAttendanceRuleById(params) {
      return axios.post("/clockRuleCtl/delRule?ruleIds=" + params.ruleIds);
    },
    // /clockRuleCtl/updateRule  修改打卡规则
    updateAttendanceRule(data) {
      return axios.post("/clockRuleCtl/updateRule", data);
    },
    // /clockRuleCtl/selectClockUser 查询除了白名单的打卡人员
    getClockUserListWithOutWhite(params) {
      return axios.post(
        "/clockRuleCtl/selectClockUser?ruleId=" + params.ruleId
      );
    },
    // /clockRuleCtl/selectAllClockUser  查询所有打卡人员 里面的人也包含白名单的人
    getClockUserList(params) {
      return axios.post(
        "/clockRuleCtl/selectAllClockUser?ruleId=" + params.ruleId
      );
    },

    // /clockRuleCtl/getClassesSet  查询每个月的排班设置
    getClockClassesSetByMonth(data) {
      return axios.post("/clockRuleCtl/getClassesSet", data);
    },

    // /clockRuleCtl/importClassesSet  导入每月的排班设置
    importClassesSetByMonth(data) {
      // let formData = new FormData();
      // for (let k of Object.keys(data)) {
      //   let val = data[k];
      //   if (val instanceof Array) {
      //     for (let item of val) {
      //       formData.append(k, item);
      //     }
      //   } else {
      //     formData.append(k, val);
      //   }
      // }
      // data 已转为 formData
      let formData = data;
      return axios.post("/clockRuleCtl/importClassesSet", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    // /clockRuleCtl/exportClassesSet  导出排班设置
    exportClassesSet(params) {
      return axios.post("/clockRuleCtl/exportClassesSet", null, {
        params,
      });
    },

    // 根据key查询在小程序中设置的打卡方式设置
    getSetStatus(params) {
      return axios.get("/clockRuleCtl/selectDataByKeyNose", { params });
    },
  };
}

<!-- 
  员工选择器，引用案例中只展示了部分参数，全部参数请从props中查看

  引用案例，对应参数可参看下面的props
  <StaffSelector
    placeholder="选择抄送人员"  //表单提示
    title="选择员工"    //弹窗标题
    :tabs="[{ name: "a", label: "按部门", fos: 1 }]"   //弹窗页签选项
    sign="all"   //引用范围
    :optType="1",//1、单选；2多选，默认：2多选
    :checked="[]"   //回显数据
    @ensure="chooseBack"    //回调方法
    class="copyTo"    //样式
  />

  checked回显案例：
  [
    {name:'济南职伴',treeType:1,urlId:'1234567'},
    {name:'张三',treeType:3,urlId:'1234567_207_152445'},
  ]
  对象参数解释：
  name:名称，用于回显到文本框；
  treeType:类型，用于确定选项数据类型值；
  urlId:数据完整id路径下划线分割层级id值
-->
<template>
  <div class="staffSelector">
    <!-- 选择框 -->
    <div
      @click.capture="disabled ? '' : showFn()"
      :class="['ss-select-box', { isDisabled: disabled }, { test: a }]"
      ref="xuanzekuang"
    >
      <slot>
        <div class="ss-text-box">
          <em :class="['prefixIcon', prefixIcon]" v-if="prefixIcon"></em>
          <div :class="['text slh', { c: !text }]">
            {{ isShowCheckedLabel ? text || placeholder : placeholder }}
          </div>
          <em class="el-icon-arrow-down"></em>
        </div>
      </slot>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="show"
      :title="title"
      append-to-body
      @close="show = false"
      custom-class="tree-dialog"
    >
      <div class="content-box">
        <el-tabs type="card" v-model="currentTab">
          <el-tab-pane
            v-for="(item, i) in newTabs"
            :key="i"
            :name="item.name"
            :label="item.label"
          />
        </el-tabs>
        <g-searchinput
          v-model="searchText"
          placeholder="关键词搜索"
          @confirm="searchFn"
        />
        <!-- default-expanded-keys:默认展开的节点的 key 的数组
             default-checked-keys：默认勾选的节点的 key 的数组
            filter-node-method：对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
          check：当复选框被点击的时候触发
        node-key：每个树节点用来作为唯一标识的属性，整棵树应该是唯一的 -->
        <div
          v-loading="loading"
          v-if="treeData[currentTab] && treeData[currentTab].length"
          class="loading-box"
        >
          <el-tree
            ref="tree"
            :filter-node-method="filterTree"
            :default-expanded-keys="defaultExpandedKeys"
            :default-checked-keys="defaultCheckedKeys"
            :data="treeData[currentTab]"
            @check="checkFn"
            node-key="urlId"
            show-checkbox
            :props="{ label: 'name', id: 'urlId' }"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <em
                :class="[
                  'iconfont',
                  treeType.find((e) => {
                    return e.treeType == data.treeType;
                  }).iconfont,
                ]"
              ></em>
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
        <g-null
          :tips="
            currentTab == 'c'
              ? '暂无合作' + (systemSign == 1 ? '供应商' : '客户')
              : '暂无数据'
          "
          class="no"
          v-else
        />
        <!-- :default-checked-keys="selected.filter((e) => e.name == currentTab)" -->
      </div>
      <div class="selected-box">
        <div class="selected-title-box">
          <div class="selected-title">已选（{{ selected.length }}）</div>
          <div class="btn-clear" @click="clearFn">清空</div>
        </div>
        <div class="selected-content-box">
          <div class="selected-item" v-for="(item, i) in selected" :key="i">
            <div class="item-content">
              <em
                :class="[
                  'iconfont',
                  treeType.find((e) => {
                    return e.treeType == item.treeType;
                  }).iconfont,
                ]"
              ></em>
              <span>{{ item.urlName || item.name }}</span>
            </div>
            <div class="btn-cancel" @click="cancelFn(item.urlId, item.tabName)">
              ×
            </div>
          </div>
          <div class="selectedTips" v-if="selected.length">
            <span
              v-for="(tItem, tIndex) in treeType"
              :key="'tt_' + tIndex"
              v-show="
                selected.filter((e) => e.treeType == tItem.treeType).length
              "
              >{{
                selected.filter((e) => e.treeType == tItem.treeType).length
              }}个{{ tItem.label }}</span
            >
          </div>
          <div class="selectedTips" v-else>
            <span>还未做出选择</span>
          </div>
        </div>
      </div>
      <div class="footer" slot="footer">
        <el-button type="primary" @click="ensureFn">确定</el-button>
        <el-button @click="show = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "g-selector",

  props: {
    placeholder: { type: String, default: "请选择" }, //提示
    title: { type: String, default: "选择员工" }, //弹窗标题
    optType: { type: Number, default: 2 }, //选择类型：1单选；2多选
    disabled: { type: Boolean, default: false }, //是否禁用

    tabs: {
      type: Array, //页签
      default: () => [
        { name: "a", label: "按部门", fos: 1 },
        { name: "b", label: "", fos: 1 }, //供应商、客户；label=""时，系统自动计算label值，目前仅支持name=b
        // { name: "c", label: "部门", fos: 1 },
        // { name: "d", label: "按职务", fos: 1 },
        // { name: "e", label: "职位", fos: 1 },
        // { name: "f", label: "标签", fos: 1 },
      ],
    },
    sign: String, //显示哪些公司，""（全部）/hrm（人事管理，本公司）/hro（人事外包，合作公司）
    checked: { type: Array, default: () => [] }, //回显
    bpoId: String, //供应商/客户ID
    isShowCheckedLabel: { type: Boolean, default: true }, //选择员工时，是否在表单中回显选择的员工名称，true显示选择员工名称，false显示placeholder的值
    prefixIcon: String, //前缀图标
  },

  data() {
    return {
      a: false,
      text: "", //选择回显文本
      show: false, //弹窗是否显示
      newTabs: [],
      currentTab: "a", //当前页签名称
      searchText: "", //筛选关键词
      treeType: [
        { label: "公司", treeType: 1, iconfont: "iconfolder" },
        { label: "部门", treeType: 2, iconfont: "iconfolder" },
        { label: "人员", treeType: 3, iconfont: "iconface" },
        { label: "职务", treeType: 4, iconfont: "icongwb" },
        { label: "职位", treeType: 6, iconfont: "icongwb" },
        { label: "标签", treeType: 5, iconfont: "icontag" },
      ],

      defaultExpandedKeys: [], //默认展开
      defaultCheckedKeys: [], //默认选中
      treeData: {}, //所有树状结构
      isLoad: false,

      radio: [],
      selected: [], //选中
      checked2: [], //临时选中（仅用于二次展开弹窗时回显上次选中数据用）
      loading: true,
    };
  },

  created() {
    this.a = false;
    this.setText(this.checked);
  },

  watch: {
    checked() {
      this.setText(this.checked);
    },
    currentTab() {
      this.restoreSelect();
    },
    searchText() {
      this.searchFn();
    },
  },

  computed: {
    ...mapGetters("user", ["compId", "systemSign"]),
  },

  methods: {
    //改变文本框颜色
    changeColor() {
      this.a = true;
    },
    // 还原颜色
    backColor() {
      console.log("触发了backColor");
      this.a = false;
    },
    // 文本回显
    setText(data) {
      this.$nextTick(() => {
        if (data) {
          let arr = data.map((e) => {
            return e.name;
          });
          this.text = arr.join("，");
        } else {
          this.$set(this, "text", "");
        }
      });
    },

    // 点击显示时：1、设置tabs选项卡 > 2、查询选项数据 > 3、设置回显 > 4、显示弹窗（3、4嵌套在2中）
    showFn() {
      // --------------------------------------------------------------------1、设置tabs选项卡
      this.isLoad = false;
      this.newTabs = JSON.parse(JSON.stringify(this.tabs));
      if (this.sign == "hrm") {
        let i = this.newTabs.findIndex((e) => e.name == "b");
        if (i > -1) {
          this.newTabs.splice(i, 1);
        }
      } else if (this.sign == "hro") {
        let i = this.newTabs.findIndex((e) => e.name == "a");
        if (i > -1) {
          this.newTabs.splice(i, 1);
        }
      }
      let j = this.newTabs.findIndex((e) => e.name == "b");
      if (j > -1 && !this.newTabs[j].label) {
        if (this.systemSign == "1") {
          this.$set(this.newTabs[j], "label", "按供应商");
        } else if (this.systemSign == "2") {
          this.$set(this.newTabs[j], "label", "按客户");
        } else {
          this.newTabs.splice(j, 1);
          // this.$set(this.newTabs[j], "label", "合作伙伴");
        }
      }
      // --------------------------------------------------------------------2、查询选项数据
      this.selected = [];
      this.newTabs.forEach((ele, index) => {
        let params = {};
        if (ele.name == "a") {
          params.compFlag = 1;
        } else if (ele.name == "b") {
          params.compFlag = 2;
        } else {
          params.dynamicId = this.compId;
          params.compId = this.compId;
        }
        this.$api.selectorReq(params, ele.name).then((res) => {
          if (res.code == "200") {
            let result = ele.name == "e" ? res.record : res.data.result;
            let treeType = null;
            if (ele.name == "a" || ele.name == "b") {
              result = result.map((el) => {
                el.compId = el.id;
                el.name = el.compNickName || el.compName;
                return el;
              });
              if (this.bpoId) {
                result = result.filter((e) => e.compId == this.bpoId);
              }
              // 单选
              if (this.optType == 1) {
                this.disDep(result);
              }
            }

            // c只要部门
            if (ele.name == "c") {
              treeType = 2;
            }
            // d数据处理-职务
            if (ele.name == "d") {
              treeType = 4;
              result = result.filter((e) => e && e.trim());
              result = result.map((e) => {
                let obj = {
                  compId: e,
                  name: e,
                  id: e,
                };
                return obj;
              });
            }
            // e数据处理-职位
            if (ele.name == "e") {
              treeType = 6;
            }

            this.setTreeDataLocation(result, treeType); //生成选项路径
            this.$set(this.treeData, ele.name, result);
            this.setSelected(result); // 设置选中

            if (index == this.newTabs.length - 1) {
              this.currentTab = this.newTabs[0].name;
              this.show = true;
            }
            this.loading = false;
            this.restoreSelect();
          }
        });
      });
    },

    // 设置选中
    setSelected(data, compId) {
      let checked = this.checked.length
        ? this.checked
        : this.checked2.length
        ? this.checked2
        : [];
      if (checked.length) {
        data.forEach((ele) => {
          this.$set(ele, "tabName", this.currentTab);
          compId = ele.compId == ele.urlId ? ele.compId : compId;
          let i = checked.findIndex(
            (e) => e.treeType == ele.treeType && e.urlId == ele.urlId
          );
          if (i > -1) {
            let obj = {};
            for (let key of Object.keys(ele)) {
              if (ele[key]) {
                obj[key] = ele[key];
              }
            }
            this.selected.push(obj);
          }
          if (ele.children && ele.children.length) {
            this.setSelected(ele.children, compId);
          }
        });
      }
    },

    // 页签是a部门员工且单选时处理所有部门不可选只能选员工
    disDep(data) {
      data.forEach((e) => {
        if (e.treeType != 3) {
          this.$set(e, "disabled", true);
        }
        if (e.children && e.children.length) {
          this.disDep(e.children);
        }
      });
    },
    setTreeDataLocation(data, treeType, parentName, parentId, compId) {
      data.forEach((e) => {
        let urlName = (parentName ? parentName + "_" : "") + e.name;
        let urlId = (parentId ? parentId + "_" : "") + e.id;
        if (!e.compId) {
          this.$set(e, "compId", compId || urlId);
        }
        this.$set(e, "urlName", urlName);
        this.$set(e, "urlId", urlId);
        if (treeType) {
          this.$set(e, "treeType", treeType);
        }
        if (e.children && e.children.length) {
          this.setTreeDataLocation(
            e.children,
            treeType,
            e.urlName,
            urlId,
            compId || urlId
          );
        }
      });
    },
    // 搜索
    searchFn() {
      this.$refs.tree.filter(this.searchText);
    },
    // 过滤节点的方法
    filterTree(val, data) {
      return data.name.indexOf(val) > -1;
    },

    // 树状复选框点击
    checkFn(node, data) {
      // console.log(node, "node");
      // console.log(data, "data");
      let fos = this.newTabs.find((e) => {
        return e.name == this.currentTab;
      }).fos; // 获取返回父子状态

      if (this.optType == 1) {
        // 单选
        this.selected = [node];
        this.restoreSelect();
      } else {
        // 多选
        // let compId = node.urlId.split("_")[0];
        this.selected = this.selected.filter(
          (e) => e.tabName != this.currentTab
        );
        if (fos == 1) {
          // 要父级
          // 判断是否全选
          data.checkedNodes.forEach((ele) => {
            let i = this.selected.findIndex(
              (e) => ele.urlName.indexOf(e.urlName) == 0
            );
            if (i == -1) {
              // 父级未选
              this.$set(ele, "tabName", this.currentTab);
              this.selected.push(ele);
            }
          });
        } else {
          // 要子级
          // return false;
          let sel = data.checkedNodes.filter((item) => !item.children);
          sel.forEach((e) => {
            this.$set(e, "tabName", this.currentTab);
          });
          this.selected = this.selected.concat(sel);
        }
      }
    },
    getChildFn(arrs) {
      let i = arrs.findIndex((e) => !e.children);
      let item = {};
      if (i > -1) {
        item = arrs[i];
        this.radio = [item];
      } else {
        let isOk = true;
        arrs.forEach((e) => {
          if (e.children && isOk) {
            this.getChildFn(e.children);
            isOk = false;
          }
        });
      }
    },

    cancelFn(id, tab) {
      let i = this.selected.findIndex((e) => e.urlId == id && e.tabName == tab);
      this.selected.splice(i, 1);
      this.restoreSelect();
    },

    // 清空选中
    clearFn() {
      this.selected = [];
      this.restoreSelect();
    },

    // 切换选项卡时恢复选中状态
    restoreSelect() {
      this.$nextTick(() => {
        if (this.$refs.tree) {
          let data = this.selected || [];
          this.$refs.tree.setCheckedNodes(data);
          let urlId = this.treeData[this.currentTab][0].urlId;
          // this.$refs.tree.defaultExpandedKeys = [urlId];
          this.defaultExpandedKeys = [urlId];
        }
      });
    },

    // 确定按钮事件
    ensureFn() {
      console.log(this.selected, "返回已选");
      this.setText(this.selected); //选项文本回显
      this.$emit("ensure", this.selected);
      this.checked2 = this.selected;
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.staffSelector {
  user-select: none;
  // 选择框
  .ss-select-box {
    width: 100%;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    padding: 0 10px;
    cursor: pointer;

    .test {
      border: 1px solid #21a2f1 !important;
    }
    &.isDisabled {
      cursor: no-drop;
      background-color: #f5f7fa;
    }
    .ss-text-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .prefixIcon {
        font-size: 12px;
      }
      .text {
        width: 0px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &.c {
          color: #999;
        }
      }
      .el-icon-arrow-down {
        color: #c0c4cc;
        font-size: 14px;
      }
    }
  }
}

.searchBox .staffSelector .ss-select-box .ss-text-box .el-icon-arrow-down {
  font-size: 12px;
}

/deep/.tree-dialog {
  width: 620px;

  .el-dialog__header {
    position: static;
    padding: 15px 20px 5px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 600;
    }
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
  }

  .el-dialog__body {
    height: 380px;
    overflow: hidden;
    padding: 10px 20px 0;
    display: flex;

    .content-box {
      width: 280px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      .loading-box {
        width: 100%;
        height: 289px;
      }

      .el-tabs {
        width: 100%;
        margin-top: -1px;
        .el-tabs__header {
          border: none;
          user-select: none;
          margin: 0;
          .el-tabs__item {
            line-height: 30px;
          }
        }
        .el-tabs__content {
          display: none;
        }
      }
      .el-tabs__nav {
        width: 100%;
        display: flex;
        background-color: #f5f5f5;
        border-radius: 0;
        border: none;
        .el-tabs__item {
          width: 24%;
          height: 100%;
          padding: 0 !important;
          font-size: 12px;
          text-align: center;
          border: 1px solid @border;
          border: none;
          border-top: 1px solid @border;
          border-bottom: 1px solid @border;
        }
        .is-active {
          border-top-color: @theme-general;
          border-bottom-color: white;
          background-color: white;
          border-left: 1px solid @border;
          border-right: 1px solid @border;
        }
        .is-active:first-child {
          border-left-color: white;
        }
        &::after {
          content: "";
          width: 0;
          flex-grow: 1;
          display: block;
          border-top: 1px solid @border;
          border-bottom: 1px solid @border;
        }
      }

      .search-input {
        width: calc(100% - 30px);
        height: 28px;
        padding: 0;
        margin: 10px 0;
        flex-grow: unset;
      }

      .el-tree {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        overflow: auto;
        label.el-checkbox {
          margin-right: 4px;
        }
        .custom-tree-node {
          font-size: 12px;
          em {
            font-size: 13px;
            margin-right: 6px;
            color: #ccc;
          }
        }
      }
      .no {
        padding: 50px 0px 0px;
        .icon {
          width: 100px;
          height: 65px;
        }
        p {
          font-size: 12px;
        }
      }
    }

    .selected-box {
      width: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      border: 1px solid #e4e4e4;
      border-radius: 2px;

      .selected-title-box {
        width: 100%;
        height: 32px;
        padding: 0 10px;
        background-color: #f5f5f5;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e4e4e4;
        margin-top: -1px;
      }

      .btn-clear {
        cursor: pointer;
      }

      .selected-content-box {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        height: 0px;

        .selected-item {
          height: 30px;
          line-height: 30px;
          padding-left: 12px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          .btn-cancel {
            display: none;
            color: #999;
            padding: 0 10px;
            cursor: pointer;
          }
          &:hover {
            background-color: #f0f0f0;
            .btn-cancel {
              display: block;
            }
          }
          .item-content {
            font-size: 12px;
            em {
              font-size: 13px;
              margin-right: 6px;
              color: #ccc;
            }
          }
        }
        .selectedTips {
          padding: 20px 0;
          text-align: center;
          color: #aaa;
          font-size: 12px;
          span {
            margin: 0 3px;
          }
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 15px 20px 20px;
    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        width: 65px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px;
        line-height: 13px;
        margin: 0 0 0 15px;
      }
    }
  }
}
</style>

// 通用接口
export default function (axios) {
  return {
    // 按部门选员工
    selectorReq(params, tab) {
      let url = "";
      if (tab == "a" || tab == "b") {
        url = "/cCompDepartment/selectCompany";
      } else if (tab == "c") {
        url = "/cCompDepartment/selectDeptByCompId";
      } else if (tab == "d") {
        url = "/compStaffMember/selectPostByCompId";
      } else if (tab == "e") {
        url = "/internal/staffArchiveCtl/findStaffPositionNose";
        // } else if (tab == "c") {
        //   if (type == "all") {
        //     url = "/cCompDepartment/selectCompany";
        //   } else if (type == "our") {
        //     url = "/cCompDepartment/selectCompanyByCompId";
        //   } else if (type == "other") {
        //     url = "/cCompDepartment/selectCompanyByCompId";
        //   }
      }
      return axios.get(url, { params });
    },
  };
}

export default {
  parent: "SrmClient",
  routers: [
    {
      // 待跟进
      path: "wait",
      name: "SrmClientWait",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 跟进中
      path: "ing",
      name: "SrmClientIng",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 商谈中
      path: "deep",
      name: "SrmClientDeep",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 已签约
      path: "success",
      name: "SrmClientSuccess",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 不合作
      path: "loss",
      name: "SrmClientLoss",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 邀请
      path: "invite",
      name: "SrmClientInvite",
      component: () => import("@/views/crm/client/clientInvite.vue"),
    },

    {
      // 搜索
      path: "sea",
      name: "SrmClientSea",
      component: () => import("@/views/crm/client/clientSea.vue"),
    },
  ],
};

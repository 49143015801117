export default {
  parent: "Job",
  routers: [
    {
      path: ":page",
      name: "JobList",
      component: () => import("@/views/recruit/job/ListView.vue"),
    },
  ],
};

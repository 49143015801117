export default {
  parent: "Attendance",
  routers: [
    // {
    //   path: "punchcard",
    //   name: "PunchCard",
    //   component: () => import("@/views/oa/attendance/PunchCard.vue"),
    // },
    // 打卡
    {
      path: "record/:sign?",
      name: "AttendanceList",
      component: () => import("@/views/oa/attendance/AttendanceList.vue"),
    },
    // 假勤
    {
      path: "vacation/:sign?",
      name: "VacationPage",
      component: () => import("@/views/oa/attendance/vacation/index.vue"),
    },
    // 统计
    {
      path: "statistics/:sign?",
      name: "StatisticsPage",
      component: () => import("@/views/oa/attendance/statistics/index.vue"),
    },
    // 规则
    {
      path: "setting/:sign?",
      name: "AttendanceSet",
      component: () => import("@/views/oa/attendance/AttendanceSet.vue"),
      meta: {
        nomy: true,
      },
    },
  ],
};

export default function (axios) {
  return {
    // 文档——增
    doc_add(params) {
      return axios.post("/cDocumentRecord/addDocument", params);
    },
    // 文档——查(分页)
    doc_select(params) {
      return axios.get("/cDocumentRecord/selectPage", { params });
    },
    // --------------------------------------------------------------------------------

    //删除安排
    delExamReq(params) {
      // let url = type == 1 ? '/examPlan/deleteExamPlan' : type == 2 ? apiUrl7 + '/interviewPlan/deleteInterviewPlan' : type == 3 ? apiUrl8 + '/physicalPlan/deletePhysicalPlan' : ''
      return axios.post("/examPlan/deleteExamPlan", params);
    },
    // 新增笔试安排项目
    editExamReq(params) {
      return axios.post("/examPlan/editExamPlan", params);
    },

    //更新签到设置
    examSigneSetReq(params) {
      // let url = type == '1' ? '/examPlan/editSigneSet' : type == '2' ? apiUrl7 + '/interviewPlan/editSigneSet' : type == '3' ? apiUrl8 + '/physicalPlan/editSigneSet' : ''
      return axios.post("/examPlan/editSigneSet", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    // 单个、批量以及全部导出笔试安排
    exportExamPlanReq(params) {
      return axios.get("/examPlan/exportExamPlanExcel", { params });
    },
    // 导出笔试签到
    exportExamSigneReq(params) {
      return axios.get("/examPlan/exportExamSigneExcel", { params });
    },
    // 导入笔试安排
    // uploadExamPlanReq = (data, params) => uplodeReq("/examPlan/importExamPlan?planId=" + params.planId,{params});},
    // 查询单个笔试安排详情
    selectByPrimaryKeyReq(params) {
      return axios.post("/examPlan/selectByPrimaryKey", params);
    },

    // 笔试安排列表分页查询
    examPlanList(params) {
      return axios.get("/examPlan/selectPage", { params });
    },

    // 考号相关
    // 新增考号规则
    editPlanStnoReq(params) {
      return axios.post(
        "/stnoRlue/editPlanStno?planId=" +
          (params.planId || "") +
          "&isPsd=" +
          params.isPsd,
        params
      );
    },
    // 新增笔试人员的时候 根据规则生成考号
    createExamNumReq(params) {
      return axios.post("/stnoRlue/createExamNum", { params });
    },
    // 删除考号规则
    delStnoReq(params) {
      return axios.delete("/stnoRlue/deleteByPrimaryKey", { params });
    },
    // 查询考号规则列表
    stnoListReq(params) {
      return axios.get("/stnoRlue/selectPage", { params });
    },
    // 通过id查询详情
    selectByStnoIdReq(params) {
      return axios.post("/stnoRlue/selectByStnoId", { params });
    },

    // 考生相关
    // 分页查询笔试人员
    examMemberListReq(params) {
      return axios.get("/examMember/selectPage", { params });
    },
    // 分页查询笔试人员  新
    examMemberListReqNew(params) {
      return axios.post("/examMember/selectPageNew ", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // 笔试统计
    examMemberCount(params) {
      return axios.get("/examMember/deCount", { params });
    },

    // 获取笔试的时间场地筛选
    examMemberPlaceAndDate(params) {
      return axios.get("/examMember/getFilterData", { params });
    },

    // editExamMemberReq1(type, data, params) {
    //   let url =
    //     type == "written"
    //       ? apiUrl6 + "/examMember/editExamMember"
    //       : type == "interview"
    //         ? apiUrl7 + "/interviewMember/editInterviewMember"
    //         : type == "physical"
    //           ? apiUrl8 + "/physicalMember/editPhysicalMember"
    //           : "";
    //   if (params) {
    //     url = url + "?";
    //     for (let key in params) {
    //       url += key + "=" + params[key] + "&";
    //     }
    //   }
    //   return postReq(url, data, { ContentType: "application/json" });
    // },
    // 新增/编辑笔试/面试/体检人员
    editExamMemberReqBs(type, data, params) {
      let url = "/examMember/editExamMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          url += key + "=" + params[key] + "&";
        }
      }
      return axios.post(url, data);
    },
    // 新增/编辑笔试/面试/体检人员
    editExamMemberReq(type, data, params) {
      let url = "/examMember/editExamMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          url += key + "=" + params[key] + "&";
        }
      }
      return axios.post(url, data);
    },
    // 简历内新增/编辑笔试人员
    editResumeExamMemberReq(data, params) {
      let url = "/examMember/editResumeExamMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          if (key) {
            url += key + "=" + params[key] + "&";
          }
        }
      }
      return axios.post(url, data);
    },
    // 删除笔试人员
    delExamMemberReq(params) {
      return axios.post("/examMember/delete", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    //关闭笔试or面试or体检项目
    editPlanStatusReq(params) {
      return axios.post("/examPlan/editPlanStatus", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    // 查询详细的笔试安排人员的信息
    // getExamArrangeInfoReq(params) {
    //   return axios.post("/examPlan/selectExamArrangeInfo", { params });
    // },
    getExamArrangeInfoBsReq(params) {
      return axios.post("/examPlan/selectExamArrangeInfo", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // 查询详细的面试安排人员的信息
    getExamArrangeInfoMsReq(params) {
      return axios.post("/examPlan/selectInterviewArrangeInfo", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // 查询详细的体检安排人员的信息
    getExamArrangeInfoTjReq(params) {
      return axios.post("/examPlan/selectPhysicalArrangeInfo", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //签到相关
    //登录
    confirmSignReq(params) {
      return axios.post("/examMember/confirmTelNose", { params });
    },
    //查询人员签到信息
    confirmSignNoseReq(params) {
      return axios.post("/examMember/confirmSignNose", { params });
    },
    // 更新人员签到状态
    editExamSignStatusReq(params) {
      return axios.post("/examMember/confirmSigneStatusNose", { params });
    },

    //自动生成一个考号和密码
    createStnoReq(params) {
      return axios.post("/examMember/createStno", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    //笔试安排获取职位数据--停用
    getJobNameByPlanIdReq(params) {
      return axios.post(
        "/examMember/getJobNameByPlanId?planId=" + params.planId
      );
    },

    addExamTimeReq(params) {
      return axios.post("/examPlan/addExamTime", params);
    },

    //笔试安排获取阿仕图同步考试数据
    // getSyncExamReq(params) {
    //   console.log(params, "getSyncExamReq--");
    //   // return axios.get(apiUrl10 + "/projectBase/getExamNamNose", { params });
    // },
    //笔试安排-长链接转短网址(好招用)
    // getShorlUrlReq(params) {
    //   console.log(params, "getShorlUrlReq--");
    //   // return axios.post(apiUrl10 + "/dwz/getShorlUrlNose", { params });
    // },
    //笔试安排-查询同步考试下-考生成绩
    //getSyncScoreDetailNoseReq(params){return axios.get(apiUrl10+"/project/score/selectScoreDetailNose",data)
    getSyncPaperListNoseReq(params) {
      console.log(params, "getSyncPaperListNoseReq--");
      // return axios.get(apiUrl10 + "/project/score/selectPaperListNose", {
      //   params,
      // });
    },
    // 删除考试地点
    // export const delPlaceReq = (data) =>
    //   delReq(apiUrl6 + "/examPlace/delete", data);

    // 笔试统计
    delPlaceReq(params) {
      return axios.delete("/examPlace/delete", { params });
    },
    //笔试安排-时间场地职位查询
    getJobTimePlaceByPlanIdReq(params) {
      return axios.post("/examMember/selectTimeJobPlace", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    //新增考试以及分数
    getInserList(params) {
      return axios.post("/examCtl/inserList", params);
    },
    //查询考试以及分数
    getStudentInfo(params) {
      return axios.post("/examCtl/selecList", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //删除考试
    delExamScore(params) {
      return axios.post("/examCtl/delExam", params);
    },
    //根据计划id查询考试名称以及id
    getExamMap(params) {
      return axios.post("/examCtl/selectExamMap", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //查询单个考试以及维度
    getExam(params) {
      return axios.post("/examCtl/selectExam", params);
    },
    //新增分数
    addScore(params) {
      return axios.post("/examCtl/insertScore", params);
    },
    //导出成绩
    ExportExam(params) {
      return axios.post("/examMember/exportRecordNose", params);
    },
    //导入成绩
    // uploadExamReq = (data, params) => uplodeReq("/examCtl/importExamScore?access_token=" + params.token + '&planId=' + params.planId,{params});},//批量导入通知内容 其实导入的就是信息项的值
    //考生开启视频监控获取sig，各种形式项目登录通用
    getGenSigReq(params) {
      console.log(params, "getGenSigReq--");
      // return axios.post(apiUrl11 + "/player/genSig", { params });
    },
    //监考员开启视频监控获取sig
    getSigNoseReq(params) {
      console.log(params, "getSigNoseReq--");
      // return axios.post(apiUrl11 + "/player/genSigNose", { params });
    },
    //根据域名获取参数(wx_appid_kf为微信分享所需参数，缺省时，使用前端现用值为默认值)
    getParamNoseReq(params) {
      console.log(params, "getParamNoseReq--");
      // return axios.post(apiUrl11 + "/player/getParamNose", { params });
    },
    //导出报告
    exportProjectReportReq(params) {
      console.log(params, "exportProjectReportReq--");
      // return axios.get(apiUrl12 + "/fileMake/exportProjectReportNose", {
      //   params,
      // });
    }, //导出报告--获取code代码,不需要token
    //获取报告
    getProjectReportReq(params) {
      console.log(params, "getProjectReportReq--");
      // return axios.get(apiUrl12 + "/fileMake/getExportUrlNose", { params });
    }, //导出报告--获取code代码--获取code代码,不需要token
    // 文件操作
    perviewFileReq(params) {
      console.log(params, "perviewFileReq--");
      // return axios.post(apiUrl12 + "/minIOApi/previewNose", { params });
    }, //预览
    getMemberOneNoseReq(params) {
      return axios.post("/examMember/getMemberOneNose", params);
    }, //获取录用的报道地点
    //判断这个人是否已经安排到该笔试安排下面
    selectMemberNumReq(params) {
      return axios.post("/examMember/selectMemberNum", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    // //导出安排通知
    // exportExamInformReq(data) {
    //   return axios.post(apiUrl6 + "/examMember/exportExamInformMember", data);
    // },

    // //批量导入通知内容 其实导入的就是信息项的值
    // export const importArrangeItemValReq = (data, params) =>
    // uplodeReq(
    //   apiUrl6 +
    //     "/examMember/importArrangeItemValue?planId=" +
    //     params.planId +
    //     "&formId=" +
    //     params.formId +
    //     "&informId=" +
    //     params.informId +
    //     "&type=" +
    //     params.type,
    //   data
    // );
    postNoticeMemberReq(data) {
      return axios.post("/examMember/selectArrangeNoticeMember", data);
    },
    postNoticeMemberUpdateBSReq(data) {
      return axios.post("/examMember/editArrangeNotice", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // 删除考试地点
    // export const delInterviewPlaceReq = (data) =>
    // delReq(apiUrl7 + "/interviewPlace/delete", data);

    //删除时间、地点的时候判断是否有人员
    //   export function selIfExistMemberReq(type, data) {
    //   let url =
    //     type == "examTimes" || type == "timeSetArr"
    //       ? "/examPlace/selectIfExistMemberByTime"
    //       : "/examPlace/selectIfExistMember";
    //   return postReq(apiUrl6 + url, data);
    // }

    //删除时间、地点的时候判断是否有人员
    selIfExistMemberReq(type, data) {
      let url =
        type == "examTimes" || type == "timeSetArr"
          ? "/examPlace/selectIfExistMemberByTime"
          : "/examPlace/selectIfExistMember";
      return axios.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //   export const changePlaceReq = (data) =>
    // postReq(apiUrl6 + "/examPlace/editExamPlace", data, {
    //   ContentType: "application/json",
    // });
    changePlaceReq(data) {
      return axios.post("/examPlace/editExamPlace", data);
    },
    // 删除所有场地里面的场地的时候 无法传planid的时候使用该方法
    deleteBatchPlaceReq(data) {
      return axios.post("/examPlace/deleteBatchPlace", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // 审批前查询下一流程阶段
    // getNextStageReq(data) {
    //   return axios.get("/examMember/getNextStage", { data });
    // },
    getBsNextStageReq(data) {
      return axios.get("/examMember/getNextStage", { data });
    },
    // 获取考试地点列表
    getBsPlaceListReq(data) {
      return axios.get("/examPlace/selectPage", { params: data });
    },
    // 笔试审批
    updatePlanApprovalBsReq(data, channelId) {
      return axios.post(
        `/examMember/updatePlanApproval?channelId=${channelId}`,
        data,
        {
          ContentType: "application/json",
        }
      );
    },
  };
}

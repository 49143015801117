import request from "@/assets/plugins/asktrue/request-paper"; // paper 实例
import qs from "qs"; // 序列化

// //试题科目列表
// export const postQuesSubReq = data => postReq(apiUrl2+"/questionSubject/selectAll",data)
export function postQuesSubReq(data) {
  return request({
    url: "/questionSubject/selectAll",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //新增/编辑试题科目
// export const editQuesSubReq = data => postReq(apiUrl2+"/questionSubject/editQuestionSubject",data,{ContentType:'application/json;charset=UTF-8'})
// //移动
// export const moveQuesSubReq = data => postReq(apiUrl2+"/questionSubject/movelSubjects",data);
// //删除
// export const delQuesSubReq = data => postReq(apiUrl2+"/questionSubject/deleteByQuestionId",data);

// //试题标签列表
// export const postQuesTagsReq = data => postReq(apiUrl2+"/questionLabel/selectAll",data)
export function postQuesTagsReq(data) {
  return request({
    url: "/questionLabel/selectAll",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //新增/编辑试题标签
// export const editQuesTagReq = data => postReq(apiUrl2+"/questionLabel/editQuestionLabel",data,{ContentType:'application/json;charset=UTF-8'})
// //移动
// export const moveQuesTagReq = data => postReq(apiUrl2+"/questionLabel/movelLabels",data);
// //删除
// export const delQuesTagReq = data => postReq(apiUrl2+"/questionLabel/deleteByQuestionId",data);

// //试题难度列表
// export const getDiffListReq = data => postReq(apiUrl2+"/questionDiffcult/selectAll",data)
export function getDiffListReq(data) {
  return request({
    url: "/questionDiffcult/selectAll",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //新增或者编辑试题难度
// export const postDiffReq = data => postReq(apiUrl2+"/questionDiffcult/editQuestionDifficult",data,{ContentType:'application/json;charset=UTF-8'})
// //批量删除试题难度
// export const delDiffReq = data => postReq(apiUrl2+"/questionDiffcult/deleteQuestionDifficults",data)
// //移动试题难度
// export const moveDiffReq = data => postReq(apiUrl2+"/questionDiffcult/moveDifficult",data)

// //试题列表
// export const getQuesListReq = data => getReq(apiUrl2+"/questionInfo/selectCount",data,{showLoading:true,loadingTarget:data.loadClass?data.loadClass:'.console-main'})
export function getQuesListReq(params) {
  return request({
    url: "/questionInfo/selectCount",
    method: "get",
    params,
  });
}

// //删除或者批量删除试题
// export const delQuesReq = data => postReq(apiUrl2+"/questionInfo/deleteQuestion",data,{ContentType:'application/json;charset=UTF-8'})
export function delQuesReq(data) {
  return request({
    url: "/questionInfo/deleteQuestion",
    method: "post",
    data,
  });
}

// //新增或者编辑试题
// export const editQuesReq = data => uplodeReq(apiUrl2+"/questionInfo/editQuestion",data,{ContentType:'application/json;charset=UTF-8'})
export function editQuesReq(data) {
  return request({
    url: "/questionInfo/editQuestion",
    method: "post",
    data,
  });
}

// export function getQuesDataReq(data) {
//   let url=data.paperId?'/paperQuestion/selectPaperQuestionById'://问卷试题详细信息
//           '/questionInfo/selectQuestionById'//试题详细信息
//   return getReq(apiUrl2+url ,data,{ContentType:'application/json;charset=UTF-8'})
// }
export function getQuesDataReq(params) {
  let url = params.paperId
    ? "/paperQuestion/selectPaperQuestionById" //问卷试题详细信息
    : "/questionInfo/selectQuestionById"; //试题详细信息
  return request({
    url: url,
    method: "get",
    params,
  });
}

// //新增或者编辑题组
// export const editQuesGroupReq = data => postReq(apiUrl2+"/questionGroup/editCommonGroupQuestion",data,{ContentType:'application/json;charset=UTF-8'})
export function editQuesGroupReq(data) {
  return request({
    url: "/questionGroup/editCommonGroupQuestion",
    method: "post",
    data,
  });
}

// //新增或者编辑矩阵题组
// export const editMatrixGroupReq = data => postReq(apiUrl2+"/questionGroup/editMatrixGroupQuestion",data,{ContentType:'application/json;charset=UTF-8'})

// //将试题添加到问卷
// export const addQuestionForPaperReq = data => postReq(apiUrl2+"/questionInfo/addQuestionForPaper?paperId="+data.paperId,data.questionInfoList,{ContentType:'application/json;charset=UTF-8'})
export function addQuestionForPaperReq(data) {
  return request({
    url: "/questionInfo/addQuestionForPaper?paperId=" + data.paperId,
    method: "post",
    data: data.questionInfoList,
  });
}

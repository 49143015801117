<template>
  <div class="body">
    <div class="share-box flex pr" style="padding: 75px 50px 40px 50px">
      <h2>阅卷链接</h2>
      <!--分享链接-->
      <div class="url">
        <div class="clearfix">
          <input type="text" readonly v-model="url" class="text" />
          <button
            class="btnnull"
            v-clipboard:copy="url"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            复制
          </button>
          <a :href="url" target="_blank"
            ><button class="btnnull">打开</button></a
          >
        </div>
        <div class="clearfix mt25">
          <button class="btnout" @click="$utils.asktrue.downLoadQrFn('qrCode')">
            下载二维码
          </button>
          <button class="btnout" @click="$utils.voit()">二维码海报</button>
        </div>
      </div>
      <!--分享渠道-->
      <div class="orient">
        <em class="iconfont iconweixin" @click="postShareFn('wx')"></em>
        <em
          class="iconfont iconqq"
          title="分享到QQ好友"
          @click="postShareFn('qq')"
        ></em>
      </div>
      <!--二维码-->
      <div class="ewm">
        <div class="tac">
          <p style="margin-top: -8px">微信扫码</p>
          <div ref="qrCode" id="qrCode" class="qr-code"></div>
        </div>
      </div>
    </div>
    <!-- 阅卷模块 -->
    <div class="share-box yjsz mt30" style="padding: 30px 50px 40px 50px">
      <div class="title">
        <h2>阅卷模块</h2>
      </div>
      <div class="partList">
        <div
          class="partAbList"
          v-for="(item, index) in other.testAbList"
          :key="index"
        >
          <h3 class="ab" v-if="item.abLabel">
            {{ item.abLabel }}
          </h3>
          <ul v-if="other.testList.length" class="paper-list">
            <li
              v-for="(ele, eli) in item.list"
              :key="'plist_' + index + '_' + eli"
            >
              <div class="paperTit slh" :title="ele.paperName">
                {{ ele.paperName || "--" }}
              </div>
              <div class="partList" v-if="ele.partData">
                <div
                  class="part"
                  v-for="(el, ei) in Object.keys(ele.partData)"
                  :key="ei"
                >
                  <div class="h3">part{{ ei + 1 }}</div>
                  <div class="number">
                    <i v-for="(e, i) in ele.partData[el]" :key="i">{{
                      e.sort
                    }}</i>
                  </div>
                </div>
              </div>
              <div class="partList" v-else>
                <div class="part">
                  <span class="ccc">尚未设置part模块</span>
                </div>
              </div>
              <div class="partFunc" @click="partShowFn(item, ele)">设置</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 阅卷分配 -->
    <div class="share-box yjsz pr mt30" style="padding: 30px 50px 40px 50px">
      <div class="title">
        <h2>阅卷分配</h2>
        <span class="btn cur" @click="addFn()"
          ><i class="el-icon-circle-plus-outline"></i><span>新增</span></span
        >
      </div>

      <div class="set">
        <div class="ms radioBox">
          <span class="tit">模式：</span>
          <el-radio-group v-model="set.type" @change="setTypeFn">
            <el-radio :label="1">单阅</el-radio>
            <el-radio :label="2">多阅</el-radio>
          </el-radio-group>
          <span class="" v-if="set.type == 2"
            >评阅次数：<input
              id="setNum"
              type="text"
              class="text"
              v-model="set.markingCount"
              @input="isNumFn(1)"
              @blur="editMarkingConfigFn()"
          /></span>
          <span class="ml20" v-if="set.type == 2"
            >仲裁分差：<input
              id="setScore"
              type="text"
              class="text"
              v-model="set.scoreGap"
              @input="isNumFn(2)"
              @blur="editMarkingConfigFn()"
          /></span>
        </div>
        <div class="xs radioBox">
          <span class="tit">显示：</span>
          <el-radio-group
            v-model="set.studentShowType"
            @change="editMarkingConfigFn()"
          >
            <el-radio label="1">姓名</el-radio>
            <el-radio label="2">考号</el-radio>
            <el-radio label="3">匿名</el-radio>
          </el-radio-group>
          <span class="" v-if="set.studentShowType == '3'"
            ><input
              type="text"
              class="text text2"
              v-model="set.anonymousLength"
              placeholder="编号位数"
              title="编号位数"
          /></span>
        </div>
      </div>
      <div class="list">
        <ul>
          <li class="b">
            <div>
              <el-checkbox
                v-model="list.checkedAll"
                @change="checkAllFn()"
              ></el-checkbox>
            </div>
            <div>姓名</div>
            <div>手机</div>
            <div>邮箱</div>
            <div>账号</div>
            <div>密码</div>
            <div>角色</div>
            <div>任务分配</div>
          </li>
          <el-checkbox-group v-model="list.checkList">
            <li v-for="(item, index) in list.data" :key="'list_' + index">
              <div>
                <el-checkbox
                  :label="item.teacherId"
                  @change="checkListFn()"
                  :disabled="item.role == '0' ? true : false"
                ></el-checkbox>
              </div>
              <div>
                <el-input
                  v-model="item.name"
                  placeholder="输入姓名"
                  @change="editFn(item)"
                ></el-input>
              </div>
              <!-- :disabled="item.role=='0'?true:false"-->
              <div>
                <el-input
                  v-model="item.telephone"
                  placeholder="输入手机"
                  @change="editFn(item)"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="item.email"
                  placeholder="输入邮箱"
                  @change="editFn(item)"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="item.userLoginCode"
                  placeholder="输入账号"
                  @change="editFn(item)"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="item.password"
                  placeholder="输入密码"
                  @change="editFn(item)"
                ></el-input>
              </div>
              <div>
                <el-select
                  v-model="item.role"
                  placeholder="请选择"
                  @change="editFn(item)"
                  popper-class="pop-select center-el-select"
                  :disabled="item.role == '0' ? true : false"
                >
                  <el-option
                    v-for="el in other.type"
                    :key="'type_' + el.value"
                    :label="el.label"
                    :value="el.value"
                    :disabled="other.isHasAdmin && el.value == '0'"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <el-popover
                  placement="bottom-end"
                  trigger="click"
                  v-model="item.popover"
                  :class="[
                    item.role == '0' ? 'disabled' : '',
                    'popover-select',
                  ]"
                  popper-class="pop-testBox no-scroll"
                  @show="selectPaperTeacherPartFn(item)"
                  :disabled="item.role == '0' ? true : false"
                >
                  <div slot="reference" class="paperTit">
                    <span v-if="item.paperTit" :title="item.paperTit">{{
                      item.paperTit
                    }}</span>
                    <span v-else>全部</span>
                    <i class="el-icon-arrow-down" v-if="item.role != '0'"></i>
                  </div>
                  <div class="testBox">
                    <div class="level1 small-scroll" v-if="other.level == 1">
                      <!--问卷列表-->
                      <el-checkbox-group v-model="item.checked">
                        <div
                          v-for="(ele, index) in other.testAbList"
                          :key="index"
                          v-show="ele.list && ele.list.length"
                        >
                          <h3 class="ab" v-if="ele.abLabel">
                            {{ ele.abLabel }}
                          </h3>
                          <ul v-if="other.testList.length" class="paper-list">
                            <li
                              class="bgf pr"
                              v-for="(el, ei) in ele.list"
                              :key="'plist_' + index + '_' + ei"
                              :class="{
                                o_n:
                                  item.checked && item.checked.length
                                    ? item.checked.filter(
                                        (e) =>
                                          e.indexOf(
                                            el.paperTypeId +
                                              '_' +
                                              el.paperId +
                                              '_'
                                          ) != -1
                                      ).length > 0 &&
                                      item.checked.filter(
                                        (e) =>
                                          e.indexOf(
                                            el.paperTypeId +
                                              '_' +
                                              el.paperId +
                                              '_'
                                          ) != -1
                                      ).length < el.part.length
                                    : false,
                              }"
                            >
                              <div class="paperTit">
                                <el-checkbox
                                  :label="el.paperTypeId + '_' + el.paperId"
                                  @change="
                                    (e, els, items) =>
                                      checkedPaperFn(e, el, item)
                                  "
                                  >{{ el.paperName || "--" }}</el-checkbox
                                >
                                <!-- <span :title="el.paperName" class="ellipsis">{{el.paperName || '--'}}</span> -->
                              </div>
                              <div
                                class="examNum"
                                v-if="el.part && el.part.length"
                              >
                                <div
                                  class="examPart"
                                  v-for="(p, pi) in el.part"
                                  :key="'part_' + index + '_' + ei + '_' + pi"
                                  :class="{
                                    on:
                                      item.checked && item.checked.length
                                        ? item.checked.findIndex(
                                            (a) =>
                                              a ==
                                              el.paperTypeId +
                                                '_' +
                                                el.paperId +
                                                '_' +
                                                p.partId
                                          ) > -1
                                        : false,
                                  }"
                                >
                                  <!-- <span :class="{on:other.showPart=='Part_'+index+'_'+ei+'_'+p}">Part{{p}}</span> -->
                                  <el-checkbox
                                    :label="
                                      el.paperTypeId +
                                      '_' +
                                      el.paperId +
                                      '_' +
                                      p.partId
                                    "
                                    @change="
                                      (e, els, pp, items) =>
                                        checkedPartFn(e, el, p.partId, item)
                                    "
                                    >Part{{ pi + 1 }}</el-checkbox
                                  >
                                  <i class="iconfont iconyoujiankuohao"></i>
                                  <ul class="paper-list">
                                    <li
                                      class="bgf pr"
                                      v-for="(t, ti) in p.questionList"
                                      :key="'t_' + ti"
                                      :title="'第' + t.sort + '题'"
                                    >
                                      <span class="f12">{{ t.sort }}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <!-- <div v-for="(el,index2) in other.testAbList" :key="index2" v-show="el.list && el.list.length">
                              <el-divider v-if="el.abLabel">{{el.abLabel}}</el-divider>
                              <div v-if="other.testList.length" class="paper-list">
                                <div class="bgf pr" v-for="(e,ei) in el.list" :key="ei" :class="{on:other.tid==el.paperId && other.paperTypeId==el.paperTypeId}">
                                  <span class="paperName f12" :title="e.paperName"><el-checkbox :label="e.paperTypeId+'_'+e.paperId">{{e.paperName || '--'}}</el-checkbox></span>
                                </div>
                              </div>
                            </div> -->
                      </el-checkbox-group>
                    </div>
                    <div class="popver-option-btn">
                      <el-button type="primary" @click="checkFn(item)"
                        >确定</el-button
                      >
                    </div>
                  </div>
                </el-popover>
              </div>
            </li>
          </el-checkbox-group>
        </ul>
      </div>
    </div>

    <div class="pop-Batch" v-if="list.checkList.length">
      <input type="button" class="btnnull" value="发通知" @click="sendFn" />
      <input type="button" class="btnnull" value="删除" @click="delFn" />
    </div>

    <el-drawer
      :visible.sync="part.show"
      :modal="false"
      size="780px"
      custom-class="partPop"
      v-if="part.show"
    >
      <Part :part="part" />
    </el-drawer>
  </div>
</template>

<script>
import Part from "@/views/staff/components/asktrue/Part.vue";
import { getShorlUrlReq } from "@/api/asktrue/common";
import {
  getProPaperReq,
  getTeacherSelectPageReq,
  postEditTeacherReq,
  postDeleteTeacherReq,
  addPaperTeacherPartReq,
  selectPaperPartQuestionReq,
  selectPaperTeacherPartReq,
  getMarkingConfigReq,
  editMarkingConfigReq,
} from "@/api/asktrue/project/project";
export default {
  name: "markItem",
  components: { Part },
  props: {
    data: Object,
    id: [Number, String],
    type: [Number, String],
  },
  data() {
    return {
      url: "", //分享路径
      proData: {},
      set: {
        examId: null, //	string	项目编码
        projectType: null, //	string	项目类型
        type: 1, //阅卷模式：1单阅；2多阅；
        markingCount: 1, //	integer	需阅卷的次数（多阅）
        scoreGap: 0, //	number	仲裁分差：双阅差值超过多少分，需仲裁介入；
        studentShowType: "1", //	string	考试显示类型1：姓名 2：考号 3：匿名
        anonymousLength: 7, //	integer	匿名编号长度（考生显示类型为匿名时必填）
      },
      list: {
        data: [],
        checkedAll: false,
        checkList: [],
      },
      part: {
        show: false,
        examId: 0,
        paperTypeId: 0,
        projectType: 0,
        paperId: 0,
      },
      other: {
        isEdit: false,
        type: [
          { value: "0", label: "管理员" },
          { value: "1", label: "仲裁" },
          { value: "2", label: "阅卷" },
        ], //角色类型：0管理员；1仲裁；2阅卷
        isHasAdmin: false, //所有阅卷老师中是否已存在“管理员”
        teacherNum: null,

        testList: [], //左栏问卷列表
        testAbList: [], //区分AB卷的问卷列表
        level: 1,
        part: {}, //所有问卷全部part
        showPart: "",

        isSetType: false, //从单阅改为多阅时，此值改为true，意思是并未保存数据，此时可改回单阅，若此值为false时，多阅改单阅则需要校验是否允许改为单阅了
      },
    };
  },
  created() {
    this.getProjectEditFn();
  },
  methods: {
    // 获取项目详情
    getProjectEditFn() {
      this.proData = this.data;
      this.set.examId = this.id;
      this.set.projectType = this.type;
      // this.getListFn();
      // this.getTestListFn();
      // this.getUrlFn();
      this.getMarkingConfigFn(); //获取阅卷设置
      this.selectPaperPartQuestionFn();
    },

    // 获取url路径
    getUrlFn() {
      this.url =
        window.location.href.split("/#/")[0] +
        "/#/loginData?id=" +
        this.id +
        "&type=" +
        this.type +
        "";
      console.log(this.url, window.location.href, "==urllll");
      this.$utils.creatQrFn(this.url, "qrCode", 66); //生成二维码
      if (
        window.location.host.indexOf("127.0.0.1") == -1 &&
        window.location.host.indexOf("localhost") == -1
      ) {
        //生成短链接
        getShorlUrlReq({ longUrl: this.url }).then((res) => {
          if (res.code == "200") {
            this.url = res.data.dwz;
          }
        });
      }
    },
    //复制链接
    onCopy() {
      this.$message.success("内容已复制到剪切板！");
    },
    onError() {
      this.$message.error("抱歉，复制失败！");
    },

    // 查询接口，校验是否允许改回单阅；
    selSetTypeFn() {
      this.$message.error("已存在阅卷数据，无法改为单阅");
      this.set.type = 2;
    },

    // 获取阅卷设置
    getMarkingConfigFn() {
      getMarkingConfigReq({ examId: this.id, projectType: this.type }).then(
        (res) => {
          if (res.code == "200") {
            console.log(res, "111");
            let $ = res.data.result;
            if ($) {
              this.set.type = $.markingCount == 1 || !$.markingCount ? 1 : 2; //阅卷模式：1单阅；2多阅；
              this.set.markingCount = $.markingCount; //	integer	需阅卷的次数（多阅）
              this.set.scoreGap = $.scoreGap; //	number	仲裁分差：双阅差值超过多少分，需仲裁介入；
              this.set.studentShowType = $.studentShowType; //	string	考试显示类型1：姓名 2：考号 3：匿名
              this.set.anonymousLength = $.anonymousLength; //	integer	匿名编号长度（考生显示类型为匿名时必填）
            } else {
              this.set.type = 1; //阅卷模式：1单阅；2多阅；
              this.set.markingCount = 1; //	integer	需阅卷的次数（多阅）
              this.set.scoreGap = 0; //	number	仲裁分差：双阅差值超过多少分，需仲裁介入；
              this.set.studentShowType = "1"; //	string	考试显示类型1：姓名 2：考号 3：匿名
              this.set.anonymousLength = 7; //	integer	匿名编号长度（考生显示类型为匿名时必填）
            }
          }
        }
      );
    },
    setTypeFn() {
      // if(this.set.type==2){
      this.set.markingCount = this.set.type;
      // }
      console.log(this.set.type, "===this.set.type");
      this.editMarkingConfigFn();
    },
    // 校验仲裁分差
    isNumFn(e) {
      let obj = null;
      if (e == 1) {
        obj = this.set.markingCount;
      } else if (e == 2) {
        obj = this.set.scoreGap;
      }
      if (obj) {
        if (isNaN(obj)) {
          obj = obj.replace(/[^\d]/g, "");
        } else {
          if (obj.length > 1 && obj.substr(1, 1) != ".") {
            obj = Number(obj);
          }
        }
        if (e == 1) {
          if (obj < 2) {
            obj = 2;
          }
        }
      }
      if (e == 1) {
        this.set.markingCount = obj;
      } else if (e == 2) {
        this.set.scoreGap = Number(obj);
      }
      if (obj) {
        this.editMarkingConfigFn();
      }
    },
    // 阅卷设置
    editMarkingConfigFn(t) {
      if (
        this.set.type == 2 &&
        (!this.set.scoreGap || this.set.scoreGap == 0)
      ) {
        this.$message.warning("请设置仲裁分差");
        return false;
      }
      if (
        this.set.studentShowType == "3" &&
        (!this.set.anonymousLength || this.set.anonymousLength == 0)
      ) {
        this.set.anonymousLength = 7;
      }
      console.log(this.set, "this.set");
      editMarkingConfigReq(this.set).then((res) => {
        if (res.code == "200") {
          if (t != 0) {
            this.$message.success("设置成功");
          }
          this.other.isSetType = false;
          this.getMarkingConfigFn();
        } else {
          this.getMarkingConfigFn();
        }
      });
    },

    // 查——阅卷老师
    getListFn() {
      let params = {
        examId: this.id, //	项目id
        examType: this.type, //  项目类型
        pageNum: 1, //	查询页数
        pageSize: 10, //每页条数
      };
      getTeacherSelectPageReq(params).then((res) => {
        if (res.code == "200") {
          this.list.data = res.data.result;
          this.other.isHasAdmin = false;
          console.log(this.list.data, "==this.list.data");
          if (this.list.data.length) {
            this.list.data.map((el) => {
              if (el.role == "0") {
                this.other.isHasAdmin = true;
              }
              this.$set(el, "checked", []);
              this.$set(el, "popover", false);
              this.getPartFp(el);
            });
          }
          if (
            this.list.data.length == 0 ||
            this.list.data.findIndex((ele) => ele.role == 0) == -1
          ) {
            this.editMarkingConfigFn(0);
            this.addFn("admin");
          }
        }
      });
    },
    // 增——阅卷老师
    addFn(admin) {
      let cc = this.$utils.asktrue.createCode(6);
      let params = {
        examId: this.id, //string	项目id
        examType: this.type, //string	项目类型:1考试,2调查,3评估,4报名,5投票
        name: admin || cc, //string	姓名
        telephone: null, //integer	手机号
        email: null, //string	邮箱
        userLoginCode: admin || cc, //string	账号
        password: cc, //string	密码
        role: admin ? "0" : "2", //string	角色,0：管理员、1：仲裁、2：阅卷
        paperId: null, //string	问卷关系
        status: 1, //integer	状态 1 有效 2 无效
        //teacherId:null, //integer	老师id，编辑时用到
        teacherType: 1,
      };
      postEditTeacherReq(params).then((res) => {
        if (res.code == "200") {
          if (!admin) {
            this.$message.success("新增成功");
          }
          this.getListFn();
        }
      });
    },
    // 改——阅卷老师
    editFn(item) {
      postEditTeacherReq(item).then((res) => {
        if (res.code == "200") {
          this.$message.success("修改成功");
          this.getListFn();
        }
      });
    },
    delFn() {
      let teacherIds = this.list.checkList.join(",");
      let adminId = this.list.data.find((ele) => ele.role == 0).teacherId;
      if (teacherIds.indexOf(adminId) == -1) {
        postDeleteTeacherReq({ teacherIds: teacherIds }).then((res) => {
          if (res.code == "200") {
            this.$message.success("删除成功");
            this.getListFn();
            this.list.checkedAll = false;
          }
        });
      } else {
        this.$message.warning(
          "您选中的老师中存在“管理员”角色，“管理员”不可删除"
        );
        return false;
      }
      // this.list.data.splice(index,1)
    },

    // 全选
    checkAllFn() {
      this.list.checkList = [];
      if (this.list.checkedAll) {
        this.list.data.forEach((el) => {
          if (el.role != 0) {
            this.list.checkList.push(el.teacherId);
          }
        });
      }
    },
    //列表选中
    checkListFn() {
      if (
        this.list.checkList.length ==
        this.list.data.filter((e) => e.role != 0).length
      ) {
        this.list.checkedAll = true;
      } else {
        this.list.checkedAll = false;
      }
    },
    sendFn(item) {
      console.log(item);
      this.$message.success("功能开发中...");
    },

    // 查询问卷part及part下试题序号
    selectPaperPartQuestionFn() {
      selectPaperPartQuestionReq({
        examId: this.id,
        projectType: this.type,
      }).then((res) => {
        if (res.code == "200") {
          this.other.part = res.data.result;
          this.getTestListFn();
        }
      });
    },
    // 获取问卷老师已分配part
    selectPaperTeacherPartFn(item) {
      selectPaperTeacherPartReq({ teacherId: item.teacherId }).then((res) => {
        if (res.code == "200") {
          item.checked = [];
          // let l=this.other.part[ele.paperId].length
          res.data.result.forEach((ele) => {
            if (ele.partId == 0) {
              item.checked.push(ele.paperTypeId + "_" + ele.paperId);
            } else {
              item.checked.push(
                ele.paperTypeId + "_" + ele.paperId + "_" + ele.partId
              );
            }
          });
          // for(let i in this.other.part){
          //   item.checked.filter(el=>el.indexOf(''))
          // }
        }
      });
    },
    //问卷列表
    getTestListFn() {
      getProPaperReq({ examId: this.id, projectType: this.type }).then(
        (res) => {
          if (res.code == "200") {
            this.other.testList = [];
            this.other.testAbList = [];
            this.isFinished = true;
            if (res.data.result.length > 0) {
              let paperId = 0;
              if (this.proData.abc == 1) {
                //开启AB卷
                let abList = this.proData.paperTypeList.split(",");
                let l1 = [];
                let l2 = [];
                abList.forEach((el) => {
                  el.length == 1 ? l1.push(el) : l2.push(el);
                });
                abList = l1.sort().concat(l2.sort());

                abList.map((el) => {
                  let lis = res.data.result.filter((e) => e.paperTypeId == el);
                  if (lis.length > 0) {
                    lis.map((ee) => {
                      this.$set(ee, "part", this.other.part[ee.paperId]);
                    });
                    this.other.testList = this.other.testList.concat(lis);
                  }
                  this.other.testAbList.push({
                    abLabel: el + "卷", //AB卷名称
                    ab: el, //AB卷卷标名称
                    list: lis,
                  });
                  if (paperId == 0 && lis && lis.length > 0) {
                    paperId = lis[0].paperId;
                    // paperTypeId=lis[0].paperTypeId;
                  }
                });
              } else {
                //未开启AB卷
                this.other.testList = res.data.result;
                this.other.testList.map((ee) => {
                  this.$set(ee, "part", this.other.part[ee.paperId]);
                });
                this.other.testAbList = [
                  {
                    abLabel: "", //空名称
                    ab: 0, //AB卷卷标名称
                    list: this.other.testList,
                  },
                ];
                if (
                  paperId == 0 &&
                  res.data.result &&
                  res.data.result.length > 0
                ) {
                  paperId = res.data.result[0].paperId;
                  // paperTypeId=res.data.result[0].paperTypeId;
                }
                console.log(this.other.testAbList, "==a2222222222222222222222");
              }
            }
            this.getListFn();
          }
        }
      );
    },
    partShowFn(ele, el) {
      console.log(ele, el, "=aa");
      this.part.examId = this.id;
      this.part.paperTypeId = ele.ab;
      this.part.paperId = el.paperId;
      this.part.projectType = this.type;
      this.part.show = true;
    },
    // 选中问卷
    checkedPaperFn(e, els, items) {
      if (e && els.part) {
        for (let i = 0; i < els.part.length; i++) {
          let ids =
            els.paperTypeId + "_" + els.paperId + "_" + els.part[i].partId;
          if (items.checked.indexOf(ids) == -1) {
            items.checked.push(ids);
          }
        }
      } else {
        let ids = els.paperTypeId + "_" + els.paperId + "_";
        for (let i = items.checked.length - 1; i >= 0; i--) {
          if (items.checked[i].indexOf(ids) > -1) {
            items.checked.splice(i, 1);
          }
        }
      }
    },
    // Part选择
    checkedPartFn(e, els, pp, items) {
      let ids = els.paperTypeId + "_" + els.paperId;
      let l = items.checked.filter((el) => el.indexOf(ids + "_") != -1).length;
      if (l < els.part.length) {
        //5是当前设定的静态数据，后期改动动态
        let i = items.checked.findIndex((a) => a == ids);
        if (i > -1) {
          items.checked.splice(i, 1);
        }
      } else {
        items.checked.push(ids);
      }
    },

    checkFn(item) {
      let partList = [];
      item.checked.forEach((ele) => {
        let els = ele.split("_");
        // if(els.length==3){
        partList.push({
          examId: this.id, //string	项目id
          projectType: this.type, //项目类型
          paperId: els[1], //string 问卷id
          paperTypeId: els[0], //	string 试卷类型编码
          partId: els.length == 3 ? els[2] : 0, //	string part id
          teacherId: item.teacherId, //	string 阅卷老师编码
        });
        // }
      });
      addPaperTeacherPartReq({
        teacherId: item.teacherId,
        partList: partList,
      }).then((res) => {
        if (res.code == "200") {
          this.getListFn();
        }
      });
      item.popover = false;
    },
    // 获取阅卷分配显示问卷名称
    getPartFp(item) {
      let paperTit = [];
      this.other.testAbList.forEach((elem) => {
        elem.list.forEach((ele) => {
          let tit = ele.paperName;
          if (ele.paperTypeId != "0") {
            tit = ele.paperTypeId + "_" + tit;
          }
          let part = [];
          let isChed = false;
          if (!ele.part || ele.part.length == 0) {
            if (
              item.partList &&
              item.partList.findIndex(
                (e) =>
                  e.paperTypeId == ele.paperTypeId &&
                  e.paperId == ele.paperId &&
                  e.partId == 0
              ) > -1
            ) {
              isChed = true;
            }
          } else {
            ele.part.forEach((el, index) => {
              console.log(item.partList, "==item.partList");
              let a =
                item.partList &&
                item.partList.findIndex(
                  (e) =>
                    e.paperTypeId == ele.paperTypeId &&
                    e.paperId == ele.paperId &&
                    e.partId == 0
                ) > -1;
              let b =
                item.partList &&
                item.partList.findIndex(
                  (e) =>
                    e.paperTypeId == ele.paperTypeId &&
                    e.paperId == ele.paperId &&
                    e.partId == el.partId
                ) > -1;
              console.log(a, b, a || b);
              if (a || b) {
                part.push("Part" + (index + 1));
                isChed = true;
              }
            });
          }
          if (isChed) {
            paperTit.push(
              tit + (part.length > 0 ? "(" + part.join(",") + ")" : "")
            );
          }
        });
      });
      this.$nextTick(() => {
        this.$set(item, "paperTit", paperTit.join(" | "));
        this.$forceUpdate();
      });
    },
  },

  beforeRouteLeave(to, form, next) {
    this.set.type = 1; //阅卷模式：1单阅；2多阅；
    this.set.markingCount = 1; //	integer	需阅卷的次数（多阅）
    this.set.scoreGap = 0; //	number	仲裁分差：双阅差值超过多少分，需仲裁介入；
    this.set.studentShowType = "1"; //	string	考试显示类型1：姓名 2：考号 3：匿名
    this.set.anonymousLength = 7; //	integer	匿名编号长度（考生显示类型为匿名时必填）
    next();
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .share-box {
    border-radius: 3px;
    padding: 30px;
    background-color: #fff;
    &.flex {
      justify-content: space-between;
    }
    & > h2 {
      position: absolute;
      top: 30px;
      font-size: 18px;
      color: #666;
      font-weight: 400;
      line-height: 24px;
    }
    &.yjsz {
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 18px;
          color: #666;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .set {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 20px 0 10px;
        height: 30px;
        .radioBox {
          display: flex;
          align-items: center;
          margin-right: 50px;
          .tit {
            margin-right: 10px;
            color: #333;
          }
          /deep/.addTeacher {
            height: 30px;
            width: 150px;
            text-align: center;
            margin-right: 50px;
            .el-input__inner {
              height: 100%;
              text-align: center;
              padding-right: 20px;
            }
            .el-input__suffix {
              right: 0px;
              .el-input__icon {
                line-height: 30px;
              }
            }
          }
          & > div {
            display: flex;
            align-items: center;
            .el-radio {
              display: flex;
              align-items: center;
              line-height: 0px;
              margin-right: 20px;
            }
          }
          & > span {
            display: flex;
            align-items: center;
            line-height: 0px;
            .text {
              height: 24px;
              width: 50px;
              text-align: center;
              &.text2 {
                width: 66px;
              }
            }
          }
        }

        .btn {
          font-size: 14px;
          color: #333;
          font-weight: 400;
          color: #666;
          border-radius: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          i {
            font-size: 16px;
            margin-right: 3px;
          }
        }
      }
    }
    .url {
      width: 62%;
      .text {
        float: left;
        width: 65%;
        height: 34px;
        line-height: 34px;
      }
      .btnnull {
        float: left;
        max-width: 70px;
        width: 15%;
        height: 34px;
        border: 1px solid var(--second-theme-color);
        color: var(--second-theme-color);
        margin-left: 2%;
      }
      .btnout {
        float: left;
        width: auto;
        height: 34px;
        margin-right: 2%;
        padding: 0 15px;
        background-color: #fff;
      }
    }
    .orient {
      width: 22%;
      text-align: left;
      padding-left: 10px;
      em {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        margin-right: 12px;
        cursor: pointer;
      }
      em.iconweixin {
        background-color: #00d700;
      }
      em.iconxinlang {
        background-color: #e6162d;
      }
      em.iconqq {
        background-color: #0075c2;
      }
    }
    .ewm {
      width: 16%;
      max-width: 100px;
      text-align: right;
      border-left: 1px dashed #e4e4e4;
      div {
        width: 76px;
        float: right;
        p {
          display: block;
          margin: 0px 0px 5px 0;
        }
        .qr-code {
          width: 76px;
          height: 76px;
          padding: 5px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
        }
      }
    }
    .list {
      width: 100%; //border-top:1px solid #e4e4e4;padding-top:20px;
      ul {
        border: 1px solid #ebeef5;
        li {
          border-bottom: 1px solid #ebeef5;
          height: 46px;
          &:not(:first-child):hover {
            background-color: #f5f7fa;
          }
          &:last-child {
            border-bottom: 0px;
          }
          & > div {
            float: left;
            height: 100%;
            border-right: 1px solid #ebeef5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #909399;
            &:nth-child(1) {
              width: 40px;
              /deep/.el-checkbox {
                .el-checkbox__label {
                  display: none;
                }
              }
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 12%;
            }
            &:nth-child(4) {
              width: 15%;
            }
            &:nth-child(5) {
              width: 12%;
            }
            &:nth-child(6) {
              width: 12%;
            }
            &:nth-child(7) {
              width: 12%;
            }
            &:last-child {
              border-right: 0px;
              width: calc(27% - 40px);
              /deep/.popover-select {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-popover__reference-wrapper {
                  width: 50px;
                  height: 100%;
                  flex: 1;
                  display: flex;
                  .paperTit {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 20px;
                    width: 50px;
                    cursor: pointer;
                    span {
                      margin-right: 10px;
                      flex: 1;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }
                &.disabled {
                  .el-popover__reference-wrapper {
                    .paperTit {
                      cursor: not-allowed;
                    }
                  }
                }
                .el-popover__reference {
                  display: flex;
                  width: 100%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  i {
                    position: absolute;
                    right: 12px;
                    font-size: 14px;
                    color: #c0c4cc;
                  }
                }
              }
            }
            /deep/.el-input {
              .el-input__inner {
                border: 0px !important;
                padding: 0 10px;
                text-align: center;
                font-size: 12px;
                background-color: rgba(250, 250, 250, 0);
                color: #909399;
              }
            }
            /deep/.el-select {
              .el-input.is-disabled {
                .el-input__suffix {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.pop-select {
  .el-select-dropdown__item {
    font-size: 12px;
  }
}
.popover-option {
  padding: 20px;
  .el-checkbox {
    margin: 5px auto;
  }
}
</style>

<style lang="less">
.pop-testBox {
  .testBox {
    .level1 {
      padding: 10px 20px 0px;
      overflow: hidden;
      overflow-y: auto;
      max-height: 360px;
      margin-bottom: -1px;
      /deep/ .el-divider {
        margin: 25px 20px 5px 20px;
        width: auto;
        .el-divider__text {
          padding: 0 5px;
          font-size: 12px;
        }
      }
      .el-checkbox-group {
        & > div {
          margin-top: 20px;
          h3 {
            margin-bottom: 0px;
            margin-top: 15px;
            font-size: 14px;
          }
          &:first-child {
            margin-top: 0px;
            h3 {
              margin-top: 10px;
            }
          }
          .paper-list {
            text-align: center;
            padding: 0px 0 0;
            max-width: 500px;
            & > li {
              width: 100%;
              height: auto;
              padding: 10px 0;
              margin: 0px;
              text-align: left;
              border-bottom: 1px solid #e4e4e4;
              .paperTit {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span {
                  cursor: pointer;
                  color: #666;
                  font-weight: 400;
                  font-size: 14px;
                  // &.on{color:var(--second-theme-color);}
                  &:hover {
                    color: var(--second-theme-color);
                  }
                }
              }
              .examNum {
                padding-top: 10px;
                .examPart {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: 8px;
                  .el-checkbox {
                    margin-top: 0px;
                  }
                  & > span {
                    cursor: pointer;
                    color: #666;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    &:hover,
                    &.on {
                      color: var(--second-theme-color);
                    }
                  }
                  & > i {
                    margin: 0 5px;
                    height: 20px;
                    line-height: 18px;
                  }
                  ul {
                    text-align: center;
                    padding: 0px 0 0px;
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 550px;
                    align-items: center;
                    li {
                      margin-right: 15px;
                      color: #666;
                      border: 1px solid #e4e4e4;
                      padding: 0px 0px;
                      width: 36px;
                      height: 20px;
                      line-height: 18px;
                      border-radius: 2px;
                      overflow: hidden;
                      text-align: center;
                    }
                  }
                  &.on {
                    & > i {
                      color: var(--second-theme-color);
                    }
                    li {
                      color: var(--second-theme-color);
                      border-color: var(--second-theme-color);
                    }
                  }
                }
              }
              &.o_n {
                .paperTit {
                  .el-checkbox {
                    .el-checkbox__inner {
                      background-color: var(--second-theme-color);
                      border-color: var(--second-theme-color);
                      &::after {
                        width: 6px;
                        height: 2px;
                        left: 3px;
                        top: 5.5px;
                        background-color: #f2f2f2;
                        border: 0px;
                        transform: none;
                      }
                    }
                  }
                }
              }
              .el-checkbox__inner,
              .el-checkbox__inner::after {
                -webkit-transition: none !important;
                -moz-transition: none !important;
                -ms-transition: none !important;
                -o-transition: none !important;
                transition: none !important;
              }
            }
          }
        }
      }
    }
    .popver-option-btn {
      padding: 15px 20px;
      border-top: 1px solid #e4e4e4;
      position: relative;
      background-color: #fff;
      .el-button {
        padding: 7px 30px;
      }
    }
  }
}

.partList {
  .partAbList {
    h3 {
      text-align: left;
      margin-top: 15px;
    }
    .paper-list {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        position: relative;
        &:first-child {
          border-top: 1px solid #e4e4e4;
        }
        .paperTit {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 200px;
          font-size: 12px;
          padding: 15px 15px;
          border-right: 1px solid #e4e4e4;
        }
        .partList {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0px;
          .part {
            padding: 0 15px;
            &:not(:last-child) {
              border-right: 1px solid #e4e4e4;
            }
            .h3 {
              height: 16px;
              line-height: 6px;
              font-size: 12px;
              text-align: left;
            }
            .number {
              display: flex;
              align-items: center;
              gap: 5px;
              margin-top: 5px;
              i {
                min-width: 30px;
                height: 20px;
                line-height: 18px;
                border: 1px solid #e4e4e4;
                border-radius: 2px;
                padding: 0px 3px;
                font-size: 12px;
              }
            }
          }
        }
        .partFunc {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          cursor: pointer;
          font-size: 12px;
          display: none;
        }
        &:hover {
          .partFunc {
            display: block;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="login-item-container" @click.stop="">
    <div class="login-item flex-space">
      <div>边框</div>
      <div class="flex-center">
        <el-switch
          @change="change"
          v-model="show"
          active-color="#39c"
          inactive-color="#ccc"
        ></el-switch>
      </div>
    </div>
    <div class="login-item flex-space">
      <div>边框色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change"
          v-model="borderColor"
          class="cube-color"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
    <div class="login-item flex-space">
      <div>背景色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change"
          v-model="backgroundColor"
          class="cube-color"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
    <div class="login-item flex-space">
      <div>透明度</div>
      <el-slider class="slider" v-model="opacity" @change="change"></el-slider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["loginbox"],
  data() {
    return {
      show: true,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      opacity: 50,
      rgba: "",
    };
  },
  watch: {
    loginbox: {
      handler(val) {
        for (let prop in val) {
          this[prop] = this.loginbox[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    for (let prop in this.loginbox) {
      this[prop] = this.loginbox[prop];
    }
  },
  methods: {
    change() {
      this.hexToRgb();
      this.$emit(
        "dealChange",
        {
          show: this.show,
          borderColor: this.borderColor,
          backgroundColor: this.backgroundColor,
          opacity: this.opacity,
          rgba: this.hexToRgb(),
        },
        "loginbox"
      );
    },
    hexToRgb() {
      console.log(
        this.backgroundColor,
        this.opacity,
        "=======this.backgroundColor111"
      );
      let v = this.backgroundColor.replace("#", "");
      let r = parseInt(v.substring(0, 2), 16);
      let g = parseInt(v.substring(2, 4), 16);
      let b = parseInt(v.substring(4, 6), 16);
      return "rgba(" + r + "," + g + "," + b + "," + this.opacity / 100 + ")";
    },
  },
};
</script>

<style lang="less" scoped>
.login-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .login-item {
    line-height: 50px;
    border-bottom: 1px dotted #e4e4e4;
    height: 51px;
    overflow: hidden;
    align-items: center;
    /deep/ .cube-color .el-color-picker__trigger {
      padding: 3px;
      border-color: transparent;
      margin-right: 20px;
      .el-color-picker__color {
        border-radius: 2px;
        border: 1px solid #e4e4e4;
        overflow: hidden;
      }
      .el-color-picker__icon {
        left: unset;
        color: #333;
        right: -37px;
        font-size: 15px;
      }
    }
    .slider {
      width: 60%;
      height: 36px;
      margin-right: 8px;
    }
  }
}
</style>

export default {
  parent: "Doc",
  routers: [
    {
      path: ":doc",
      name: "DocList",
      component: () => import("@/views/oa/doc/DocList.vue"),
    },
  ],
};

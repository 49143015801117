// 工资接口
export default function (axios) {
  return {
    // ----------------工资核算------------------------------------------------------
    // 工资核算——查（通过时间以及员工统计账套使用人数）
    wage_selectSalarySetByStaff(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryAccounting/selectSalarySetByStaff`;
      return axios.post(url, {}, { params });
    },
    // 工资核算——下载（下载导入变量模板，其中参数都必填）
    wage_downloadSalaryTemp(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/downloadSalaryTemp`;
      return axios.post(url, params);
    },
    // 工资核算——开始核算
    wage_calculateSalary(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryAccounting/calculateSalary`;
      return axios.post(url, {}, { params });
    },
    // 工资记录——保存工资记录（工资核算结果保存、发起审批）
    wage_saveSalaryRecord(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/saveSalaryRecord`;
      if (params.order) {
        url = url + "?approverId=" + params.approverId + "&order=" + params.order
      }
      return axios.post(url, params);
    },




    // ----------------工资档案------------------------------------------------------
    // 工资档案——增、改
    wage_editFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/editSalaryArchives`;
      return axios.post(url, params);
    },
    // 工资档案——删(列表删)
    wage_delFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/deleteSalaryArchivesById`;
      return axios.post(url, {}, { params });
    },
    // 工资档案——删(详情删)
    wage_delDetailFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/deleteSalaryArchivesDetailIdsById`;
      return axios.post(url, {}, { params });
    },

    // 工资档案——删除工资档案记录
    wage_delRecord(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/delRecord`;
      return axios.post(url, {}, { params });
    },
    // 工资档案——减员
    wage_delStaff(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/delStaffArchiveTime`;
      return axios.post(url, {}, { params });
    },
    // 工资档案——查(档案列表)
    wage_selectFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/selectPage`;
      return axios.get(url, { params });
    },
    // 工资档案——查(档案详情)
    wage_selectFileDetail(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/selectBySalaryArchivesId`;
      return axios.post(url, {}, { params });
    },

    // 工资档案——查(是否已生成记录)————查看档案是否在记录中使用(1代表有在使用，0代表没有)
    wage_getDetailRecordId(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/getDetailRecordId`;
      return axios.post(url, {}, { params });
    },

    // 工资档案统计
    wage_getCountFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/getCount`;
      return axios.get(url, { params });
    },

    // ----------------工资记录------------------------------------------------------
    // 工资记录——查（列表-企业）
    wage_selectList(params, type, nose) {
      nose = nose ? nose : ""
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/selectList${nose}`;
      return axios.post(url, params);
    },
    // 工资记录——查（列表-个人）
    wage_selectMyList(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/selectSalaryInfoByUser`;
      return axios.post(url, params);
    },
    // 工资记录-删
    wage_delList(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/deleteSalaryRecord`;
      return axios.post(url, {}, { params });
    },
    // 工资记录-查（档案）
    wage_mySelFile(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryArchivesCtl/selectSalaryArchivesDetailByRecordId`;
      return axios.post(url, {}, { params });
    },

    // 工资记录-导出
    wage_exportSalaryRecords(params, type, nose) {
      nose = nose ? nose : ""
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/exportSalaryRecords${nose}`;
      return axios.post(url, params);
    },

    // 工资记录-发工资条
    wage_sendPayslip(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/sendPayslip`;
      return axios.post(url, params);
    },

    // 个人不登录查看工资条
    wage_selectSalaryInfoByIdNose(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/selectSalaryInfoByIdNose`;
      return axios.post(url, {}, { params });
    },

    // 工资记录统计
    wage_getCountList(params, type, nose) {
      nose = nose ? nose : ""
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salaryRecordCtl/getCount${nose}`;
      return axios.get(url, { params });
    },

    // ----------------工资账套------------------------------------------------------
    // 工资账套——增、改
    wage_saveSalarySet(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/saveSalarySet`;
      return axios.post(url, params);
    },
    // 工资账套——删
    wage_deleteSalarySet(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/deleteSalarySet`;
      return axios.post(url, {}, { params });
    },
    // 工资账套——查(账套列表)，type区分hrm、hro
    wage_selectSalarySet(params, type, nose) {
      nose = nose ? nose : ""
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/selectSalarySet${nose}`;
      return axios.get(url, { params });
    },
    // 工资账套——查(账套详情)
    wage_selectSalarySetById(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/selectSalarySetById`;

      return axios.get(url, { params });
    },
    // 工资账套——查(系统变量)
    wage_selectSystemParam(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/selectSystemParam`;
      return axios.get(url, { params });
    },
    // 工资账套——查(固定款项)
    wage_selectFixParam(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/selectFixParam`;
      return axios.get(url, { params });
    },

    // 工资账套——增（函数）
    wage_editFx(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySetFunction/addSalarySetFunction`;
      return axios.post(url, {}, { params });
    },
    // 工资账套——删（函数）
    wage_delFx(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySetFunction/deleteSalarySetFunction`;
      return axios.get(url, {
        params,
      });
    },
    // 工资账套——查（函数）
    wage_ListFx(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySetFunction/selectSalarySetFunctionList`;
      return axios.get(url, {
        params,
      });
    },

    // 导出常量
    wage_exportSalaryConstants(params, type) {
      let prefix = type === "hro" ? axios.urlHro : axios.urlHrm;
      let url = `/${prefix}/salarySet/exportSalaryConstants`;
      return axios.post(url, {}, { params });
    },

    // ----------------------------------------------------------------------

    // 按部门选员工（按时间过滤数据）-社保
    selectTimeDepReq(params, type, sign) {
      let prefix = sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (type == "sb") {
        return axios.get(
          prefix + "/socialInsuranceHeSuan/selectCompanyByCompId",
          {
            params,
          }
        );
      } else if (type == "gjj") {
        return axios.get(prefix + "/gjjRecordCtl/selectCompanyByCompId", {
          params,
        });
      } else if (type == "gz") {
        return axios.get(prefix + "/salaryAccounting/selectCompanyByCompId", {
          params,
        });
      }
    },
  };
}

export default function (axios) {
  return {
    // //获取项目列表
    //项目分页查询
    selectProjectPageReq(data) {
      return axios.post("/project/selectProjectPage", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //项目修改/新增
    updateProjectReq(data) {
      return axios.post("/project/updateProject", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //项目修改/新增
    updateStatusReq(data) {
      return axios.post(
        "/project/updateStatus?projectId=" +
          data.projectId +
          "&status=" +
          data.status,
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //详情页职位列表
    getDetailJobListReq(data) {
      return axios.post("/project/selectJobCountByProjectId", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //移除职位
    removeJobIdReq(params) {
      return axios.get("/project/removeJobId", { params });
    },
    //猎头项目详情阶段查询
    selectStageCountByProjectReq(params) {
      return axios.get("/newResume/selectStageCountByProject", { params });
    },
    //项目分页查询参数
    selectProjectPageParamReq(data) {
      return axios.post("/project/selectProjectPageParam", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //项目查询
    selectProjectReq(data) {
      return axios.get("/project/selectById/" + data.projectId, {
        params: data,
      });
    },
  };
}

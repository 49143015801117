export default function (axios) {
  return {
    //人才库分页查询
    getPoolResumeListReq(params) {
      return axios.get("/resumepool/selectResumePoolPage", { params });
    },
    //分类查询(层级)
    getCompDirTreeReq(params) {
      return axios.get("/resumepool/selectCompDirTree", { params });
    },
    //分类新增修改
    updateCompDirReq(params) {
      return axios.get("/resumepool/updateCompDir", { params });
    },
    //分类删除
    deleteCompDirReq(data) {
      return axios.delete("/resumepool/deleteCompDir", data);
    },
    //简历移动
    moveDirReq(params) {
      return axios.get("/resumepool/moveDir", { params });
    },
    //人才库简历推荐到职位（招聘系统）
    saveResumeChannelReq(data) {
      return axios.post("/resumepool/saveResumeChannel", data, {
        ContentType: "application/json",
      });
    },
  };
}

import { render, staticRenderFns } from "./PracticePop.vue?vue&type=template&id=50faf516&scoped=true"
import script from "./PracticePop.vue?vue&type=script&lang=js"
export * from "./PracticePop.vue?vue&type=script&lang=js"
import style0 from "./PracticePop.vue?vue&type=style&index=0&id=50faf516&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50faf516",
  null
  
)

export default component.exports
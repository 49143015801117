<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created(){
    //加载系统参数
    this.$store.dispatch("exam/getDictNoseFn",this.$api);
  }
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
:root {
  --base-theme-color: @theme-general;
  --second-theme-color: @theme-general;
  --second-theme-color-rgb: @theme-general-rgb;
}
</style>

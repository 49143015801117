// 公积金
export default {
  parent: "HousingFund",
  routers: [
    {
      path: "pfr/:sign?",
      name: "PfArchives",
      component: () => import("@/views/salary/pf/PfArchivesShunt.vue"),
    },
    {
      path: "pfl/:sign?",
      name: "PfRecord",
      component: () => import("@/views/salary/pf/PfRecordShunt.vue"),
    },
    {
      path: "pfs/:sign?",
      name: "PfSet",
      component: () => import("@/views/salary/pf/PfSetShunt.vue"),
      meta: {
        nomy: true,
      },
    },
  ],
};

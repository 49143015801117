var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-bubble"},[_c('div',{staticClass:"message-bubble-main-content",class:[_setup.message.flow === 'in' ? '' : 'reverse']},[_c(_setup.Avatar,{attrs:{"useSkeletonAnimation":"","url":_setup.message.avatar || '',"nick":_setup.message.nick,"size":"30px"}}),_c('main',{staticClass:"message-body"},[(_setup.message.flow === 'in' && _setup.message.conversationType === 'GROUP')?_c('div',{staticClass:"message-body-nickName"},[_vm._v(" "+_vm._s(_setup.props.content.showName)+" ")]):_vm._e(),_c('div',{class:['message-body-main', _setup.message.flow === 'out' && 'message-body-main-reverse']},[_c('div',{class:[
            'blink',
            'message-body-content',
            _setup.message.flow === 'out' ? 'content-out' : 'content-in',
            _setup.message.hasRiskContent && 'content-has-risk',
            _setup.isNoPadding ? 'content-noPadding' : '',
            _setup.isNoPadding && _setup.isBlink ? 'blink-shadow' : '',
            !_setup.isNoPadding && _setup.isBlink ? 'blink-content' : '',
          ]},[_c('div',{staticClass:"content-main"},[(
                (_setup.message.type === _setup.TYPES.MSG_IMAGE || _setup.message.type === _setup.TYPES.MSG_VIDEO) &&
                _setup.message.hasRiskContent
              )?_c('img',{class:['message-risk-replace', !_setup.isPC && 'message-risk-replace-h5'],attrs:{"src":_setup.riskImageReplaceUrl}}):[_vm._t("messageElement"),_vm._t("TUIEmojiPlugin")]],2),(_setup.message.hasRiskContent)?_c('div',{staticClass:"content-has-risk-tips"},[_vm._v(" "+_vm._s(_setup.riskContentText)+" ")]):_vm._e()]),(_setup.message.status === 'fail' || _setup.message.hasRiskContent)?_c('div',{staticClass:"message-label fail",on:{"click":function($event){return _setup.resendMessage()}}},[_vm._v(" ! ")]):_vm._e(),(_setup.message.status === 'unSend' && _setup.needLoadingIconMessageType.includes(_setup.message.type))?_c(_setup.Icon,{staticClass:"message-label loadingCircle",attrs:{"file":_setup.loadingIcon,"width":'15px',"height":'15px'}}):_vm._e(),_c(_setup.ReadStatus,{staticClass:"message-label align-self-bottom",attrs:{"message":_setup.shallowCopyMessage(_setup.message)},on:{"openReadUserPanel":_setup.openReadUserPanel}})],1)])],1),_c('div',{staticClass:"message-bubble-extra-content",class:_setup.message.flow === 'in' || 'reverse'},[_c(_setup.MessageQuote,{attrs:{"message":_setup.message},on:{"blinkMessage":_setup.blinkMessage,"scrollTo":_setup.scrollTo}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
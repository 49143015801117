export default {
  parent: "crm",
  routers: [
    {
      // 客户
      path: "crmclient",
      name: "CrmClient",
      component: () => import("@/views/crm/client/clientView.vue"),
    },
    {
      // 订单
      path: "crmorder",
      name: "CrmOrder",
      component: () => import("@/views/crm/order/orderView.vue"),
    },
    {
      // 合同
      path: "crmcontract",
      name: "CrmContract",
      component: () => import("@/views/crm/contract/contractView.vue"),
    },
    {
      // 发票
      path: "crminvoice",
      name: "CrmInvoice",
      component: () => import("@/views/crm/invoice/indexView.vue"),
    },
    {
      // 回款
      path: "crmpayment",
      name: "CrmPayment",
      component: () => import("@/views/crm/payment/paymentView.vue"),
    },
    {
      // 商品
      path: "crmproduct",
      name: "CrmProduct",
      component: () => import("@/views/crm/product/indexView.vue"),
    },
  ],
};

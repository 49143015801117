export default {
  parent: "SrmContract",
  routers: [
    {
      path: ":page",
      name: "SRMContractList",
      component: () => import("@/views/crm/contract/ContractList.vue"),
    },
    // // 合同列表未执行
    // {
    //   path: "no",
    //   name: "SrmContractList_no",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // // 执行中
    // {
    //   path: "in",
    //   name: "SrmContractList_in",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // // 已完成
    // {
    //   path: "done",
    //   name: "SrmContractList_done",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // {
    //   path: "invalid",
    //   name: "SrmContractList_invalid",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
    // {
    //   path: "all",
    //   name: "SrmContractList_all",
    //   component: () => import("@/views/crm/contract/ContractList.vue"),
    // },
  ],
};

// 个税接口
export default function (axios) {
  return {
    // 查询薪酬-个税
    getTaxRecordList(params, sign) {
      return axios.get(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/quryTax`,
        {
          params,
        }
      );
    },

    // /internal/tax/findCountAndSum   个税统计接口 （按年份）
    getTaxOverview(data, sign) {
      return axios.post(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/findCountAndSum`,
        data
      );
    },

    // 保存 专项附加扣除信息
    addZxfjkc(data, params, sign) {
      return axios.post(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/special/sal/addTax`,
        data,
        {
          params,
        }
      );
    },

    // 我的个税
    quryPersonTax(params, sign) {
      return axios.get(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/quryMyTax`,
        {
          params,
        }
      );
    },

    // 查询员工的个税记录
    getPersonTaxByUserId(params, sign) {
      return axios.post(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/editTax`,
        {},
        {
          params,
        }
      );
    },

    // 保存个税
    saveUserTax(data, sign) {
      return axios.post(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/save`,
        data
      );
    },

    // /tax/delTaxRecord 删除个税记录   /internal  /outsourced
    delTaxRecord(params, sign) {
      return axios.get(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/tax/delTaxRecord`,
        {
          params,
        }
      );
    },
  };
}

<template>
  <div class="copyright-item-container" @click.stop="">
    <div class="copyright-item flex-space">
      <div>显示</div>
      <div class="flex-center">
        <el-switch
          @change="change"
          v-model="show"
          active-color="#39c"
          inactive-color="#ccc"
        >
        </el-switch>
      </div>
    </div>
    <div class="copyright-item">
      <el-input
        size="mini"
        @input="change"
        v-model="font"
        class="title-text-input"
        placeholder="输入版权"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: ["copyright"],
  data() {
    return {
      show: true,
      font: "Asktrue 提供技术支持",
    };
  },
  watch: {
    copyright: {
      handler(val) {
        for (let prop in val) {
          this[prop] = this.copyright[prop];
        }
      },
      deep: true,
    },
  },
  created() {
    for (let prop in this.copyright) {
      this[prop] = this.copyright[prop];
    }
  },
  methods: {
    change() {
      this.$emit(
        "dealChange",
        {
          show: this.show,
          font: this.font,
        },
        "copyright"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.copyright-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .copyright-item {
    line-height: 50px;
    border-bottom: 1px dotted #e4e4e4;
    height: 51px;
    overflow: hidden;
    /deep/ .cube-color .el-color-picker__trigger {
      padding: 3px;
      border-color: transparent;
      margin-right: 20px;
      .el-color-picker__color {
        border-radius: 2px;
        border: 1px solid #e4e4e4;
        overflow: hidden;
      }
      .el-color-picker__icon {
        left: unset;
        color: #333;
        right: -37px;
        font-size: 15px;
      }
    }
  }
}
</style>

/*
 * @Description: project
 * @Date: 2023-11-17 09:38:44
 * @LastEditTime: 2024-01-12 11:55:48
 */
import requestProject from "@/assets/plugins/asktrue/request-project"; // project 实例
import requestPaper from "@/assets/plugins/asktrue/request-paper"; // project 实例
import requestScore from "@/assets/plugins/asktrue/request-score"; // score 实例
import requestUser from "@/assets/plugins/asktrue/request-user"; // user 实例
import requestAnswer from "@/assets/plugins/asktrue/request-answer"; // answer 实例
import requestAnswerdata from "@/assets/plugins/asktrue/request-answerdata"; // answerdata 实例

import qs from "qs"; // 序列化

//项目
export function getProjectList(params) {
  return requestProject({
    url: "/projectBase/selectPage",
    method: "get",
    params,
  });
}

// /projectBase/selectPageNose   项目信息查询(无需token验证)
export function getProjectListNose(params) {
  return requestProject({
    url: "/projectBase/selectPageNose",
    method: "get",
    params,
  });
}

// 好招获取测评统计信息
export function getProjectInfo(data) {
  return requestProject({
    url: "/projectCountInfoCtl/getProjectInfo",
    method: "post",
    data,
  });
}

export function postProjectReq(data) {
  return requestProject({
    url: "/projectBase/updateProject",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// export const getProjectEdit = data => getReq(apiUrl3+"/projectBase/selectProject",data)//详情查询
export function getProjectEdit(params) {
  return requestProject({
    url: "/projectBase/selectProject",
    method: "get",
    params,
  });
}

// export const selectProjectNose = data => getReq(apiUrl3+"/projectBase/selectProjectNose",data)//详情查询（无token）
export function selectProjectNose(params) {
  return requestProject({
    url: "/projectBase/selectProjectNose",
    method: "get",
    params,
  });
} //详情查询（无token）

// export const delProjectReq = data => postReq(apiUrl3+"/projectBase/updateProjectBatch?opeType="+data.opeType,data.list,{ContentType:'application/json;charset=UTF-8'})//批量删除
export function delProjectReq(data) {
  return requestProject({
    url: "/projectBase/updateProjectBatch?opeType=" + data.opeType,
    method: "post",
    data: data.list,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// // 项目标签查询
// export const getProjectLabelListPage = data => getReq(apiUrl1+"/paramImportCtl/selectProjectLabelListPage",data)//详情查询

// //项目详情>编辑：费用
// export const postProjectGoodssetSave = data => postReq(apiUrl3+"/project/goodsset/save?examId="+data.examId+'&projectType='+data.projectType+'&goodsPayType='+data.goodsPayType,data.list, {ContentType:'application/json;charset=UTF-8'})//费用保存
export function postProjectGoodssetSave(data) {
  return requestProject({
    url: `/project/goodsset/save?examId=${data.examId}&projectType=${data.projectType}&goodsPayType=${data.goodsPayType}`,
    method: "post",
    data: data.list,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// export const getProjectGoodsset = data => getReq(apiUrl3+"/project/goodsset/select",data, {ContentType:'application/json;charset=UTF-8'})//费用回显查询
export function getProjectGoodsset(params) {
  return requestProject({
    url: `/project/goodsset/select`,
    method: "get",
    params,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// //必填项
// export const delMustfillReq = data => delReq(apiUrl3+"/projectexaminfo/deteleInfo",data)//关闭必填项/删除一个必填项
// export const saveMustfillReq = data => postReq(apiUrl3+"/projectexaminfo/saveInfo?examId="+data.examId+'&projectType='+data.projectType,data.examInfoList,{ContentType:'application/json;charset=UTF-8'})//必填项保存
// export const getMustfillReq = data => getReq(apiUrl3+"/projectexaminfo/selectInfo",data)//必填项查询/查询一个必填项
// export const getMustfillSelectReq = data => getReq(apiUrl3+"/projectexaminfo/selectOption",data)//必填项下拉项查询

// //分享
// export const getShareInfoReq = data => postReq(apiUrl3+"/project/selectProjectWxShare",data)//查询
export function getShareInfoReq(data) {
  return requestProject({
    url: "/project/selectProjectWxShare",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const saveShareInfoReq = data => postReq(apiUrl3+"/project/editWxShare",data)//保存
export function saveShareInfoReq(data) {
  return requestProject({
    url: "/project/editWxShare",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //项目-订单
// export const getProOrderList = data => getReq(apiUrl3+"/projectOrderCtl/selectProjectOrderListPage",data,{showLoading:true,loadingTarget:'.console-main'})//列表

// //项目-问卷
// export const getProPaperReq = data => getReq(apiUrl3+"/project/paper/selectProjectPaperNose",data)//问卷列表
export function getProPaperReq(params) {
  return requestProject({
    url: "/project/paper/selectProjectPaperNose",
    method: "get",
    params,
  });
}

// export const updateProjectPaper = data => postReq(apiUrl3+"/project/paper/updateProjectPaper",data, {ContentType:'application/json;charset=UTF-8'})//引入问卷
export function updateProjectPaper(data) {
  return requestProject({
    url: "/project/paper/updateProjectPaper",
    method: "post",
    data,
  });
}

// export const uplodeReportReq = (data,params) => postReq(apiUrl3+"/project/paper/uploadReport?examId="+params.examId+"&projectType="+params.projectType+"&requireNeed="+params.requireNeed,data, {ContentType:'application/json;charset=UTF-8'})//上传模版
export function uplodeReportReq(data, params) {
  return requestProject({
    url:
      "/project/paper/uploadReport?examId=" +
      params.examId +
      "&projectType=" +
      params.projectType +
      "&requireNeed=" +
      params.requireNeed,
    method: "post",
    data,
  });
}

// export const getProTempReq = data => getReq(apiUrl3+"/project/paper/selectProjectReport",data)//模版列表
export function getProTempReq(params) {
  return requestProject({
    url: "/project/paper/selectProjectReport",
    method: "get",
    params,
  });
}

// export const delProTempReq = data => delReq(apiUrl3+"/project/paper/deleteProjectReport",data)//删除模版
export function delProTempReq(params) {
  return requestProject({
    url: "/project/paper/deleteProjectReport",
    method: "delete",
    params,
  });
}

// //项目-考生
// export const getProStuReq = data => getReq(apiUrl3+"/project/student/selectPage",data)//列表
export function getProStuReq(params) {
  return requestProject({
    url: "/project/student/selectPage",
    method: "get",
    params,
  });
}
// export const getProStuNoseReq = (data) =>
//   getReq(apiUrl3 + "/project/student/selectPageNose", data); //列表  无token
export function getProStuNoseReq(params) {
  return requestProject({
    url: "/project/student/selectPageNose",
    method: "get",
    params,
  });
}
// export const updateProjectStudent = data => postReq(apiUrl3+"/project/student/updateProjectStudent",data, {ContentType:'application/json;charset=UTF-8'})//新增或修改
export function updateProjectStudent(data) {
  return requestProject({
    url: "/project/student/updateProjectStudent",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const deleteProjectStudent = data => delReq(apiUrl3+"/project/student/deleteProjectStudent",data)//删除（批量删除）
export function deleteProjectStudent(params) {
  return requestProject({
    url: "/project/student/deleteProjectStudent",
    method: "delete",
    params,
  });
}
// export const selectNumRule = data => getReq(apiUrl3+"/project/student/selectNumRule",data)//考号规则查询
export function selectNumRule(params) {
  return requestProject({
    url: "/project/student/selectNumRule",
    method: "get",
    params,
  });
}
// export const editAllStudentPsd = data => postReq(apiUrl3+"/project/student/editStudentPassword",data)//考号规则查询
export function editAllStudentPsd(data) {
  return requestProject({
    url: "/project/student/editStudentPassword",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const updateNumRule = data => postReq(apiUrl3+"/project/student/updateNumRule",data,{ContentType:'application/json;charset=UTF-8'})//考号规则新增和修改
export function updateNumRule(data) {
  return requestProject({
    url: "/project/student/updateNumRule",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const updateJudgesReq = data => postReq(apiUrl3+"/project/student/updateProjectWorkmate",data,{ContentType:'application/json;charset=UTF-8'})//评估,投票项目评委维护
// export const postUpdateHideLogin = data => postReq(apiUrl3+"/projectBase/updateHideLogin",data,{ContentType:'application/json;charset=UTF-8'})//匿名项目设置范围（报名，调查，投票）-保存
export function postUpdateHideLogin(data) {
  return requestProject({
    url: "/projectBase/updateHideLogin",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const getselectAnswerListReq = data => getReq(apiUrl3+"/project/score/selectAnswerList",data)//考生查看答题情况（需配合项目问卷接口组合）
export function getselectAnswerListReq(params) {
  return requestProject({
    url: "/project/score/selectAnswerList",
    method: "get",
    params,
  });
}
// export const postResetStuRecordReq = data => postReq(apiUrl3+"/project/score/resetStuRecord",data)//重置考试记录
export function postResetStuRecordReq(data) {
  return requestProject({
    url: "/project/score/resetStuRecord",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const postProlongStuRecordReq = data => postReq(apiUrl3+"/project/score/prolongStuRecord",data)//延长考生考试时间
export function postProlongStuRecordReq(data) {
  return requestProject({
    url: "/project/score/prolongStuRecord",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const postSendMsgReq = data => postReq(apiUrl3+"/project/student/sendMsg?projectType="+data.projectType+"&examId="+data.examId+"&sendType="+data.sendType+"&title="+data.title+"&content="+data.content,data.userInfoList,{ContentType:'application/json;charset=UTF-8'})//发通知
export function postSendMsgReq(data) {
  return requestProject({
    url: `/project/student/sendMsg?projectType=${data.projectType}&examId=${data.examId}&sendType=${data.sendType}&title=${data.title}&content=${data.content}`,
    method: "post",
    data: data.userInfoList,
  });
}

// export const addStudentToRedisNoseReq = data => postReq(apiUrl4+"/exam/addStudentToRedisNose",data)// 更新考生缓存
export function addStudentToRedisNoseReq(data) {
  return requestAnswer({
    url: `/exam/addStudentToRedisNose`,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const selectFilterForStudentReq = data => postReq(apiUrl3+"/project/student/selectFilterForStudent?projectType="+data.projectType+"&examId="+data.examId)// 考生筛选条件的查询
export function selectFilterForStudentReq(data) {
  return requestProject({
    url: `/project/student/selectFilterForStudent?projectType=${data.projectType}&examId=${data.examId}`,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //项目-考生-考场
// export const postEditPlaceReq = data => postReq(apiUrl3+"/project/student/editPlace?examId="+data.examId+"&projectType="+data.projectType+"",data.placeList, {ContentType:'application/json;charset=UTF-8'})//新增/编辑考场
export function postEditPlaceReq(data) {
  return requestProject({
    url: `/project/student/editPlace?examId=${data.examId}&projectType=${data.projectType}`,
    method: "post",
    data: data.placeList,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const postDeletePlaceReq = data => postReq(apiUrl3+"/project/student/deletePlace",data)//删除考场
export function postDeletePlaceReq(data) {
  return requestProject({
    url: "/project/student/deletePlace",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const postSelectPlaceListReq = data => postReq(apiUrl3+"/project/student/selectPlaceList",data)//获取考场列表
export function postSelectPlaceListReq(data) {
  return requestProject({
    url: "/project/student/selectPlaceList",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const postSelectPlaceListNoseReq = (data) => postReq(apiUrl3 + "/project/student/selectPlaceListNose", data); //获取考场列表
export function postSelectPlaceListNoseReq(data) {
  return requestProject({
    url: "/project/student/selectPlaceListNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
} //获取考场列表
// //监考
// export const addAdminInvigilatorReq = data => postReq(apiUrl3+"/anon/projectInvigilate/addAdminInvigilator",data)
export function addAdminInvigilatorReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/addAdminInvigilator",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getProStuInReq = data => getReq(apiUrl6+"/anon/projectInvigilate/selectPage",data)//项目监考考生视频分页查询
// export const getProStuStatusReq = data => getReq(apiUrl6+"/anon/projectInvigilate/selectStudentPlayerStatus",data)//查询考生视频掉线和离线状态
// //生成监考人员账密并平均分配考生
// export const addInvigilatorReq = data => postReq(apiUrl3+"/anon/projectInvigilate/addInvigilator",data)
export function addInvigilatorReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/addInvigilator",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //监考人员账号、密码列表
// export const selectInvigilatorReq = data => postReq(apiUrl3+"/anon/projectInvigilate/selectInvigilator",data)
export function selectInvigilatorReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/selectInvigilator",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //获取考生总数量及已分配数量
// export const getStudentCountReq = data => postReq(apiUrl3+"/anon/projectInvigilate/getStudentCount",data)
export function getStudentCountReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/getStudentCount",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //获取考生的考场
// export const selectExamRoomReq = data => postReq(apiUrl3+"/anon/projectInvigilate/selectExamRoom",data)
// //修改监考人员账号密码
// export const updateInvigilatorReq = data => postReq(apiUrl3+"/anon/projectInvigilate/updateInvigilator",data, {ContentType:'application/json;charset=UTF-8'})
export function updateInvigilatorReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/updateInvigilator",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// //监考人员登录
// export const invigilatorLoginReq = data => postReq(apiUrl3+"/anon/projectInvigilate/invigilatorLogin",data, {ContentType:'application/json;charset=UTF-8'})
// //监考人员登出
// export const invigilatorLogoutReq = data => postReq(apiUrl3+"/anon/projectInvigilate/invigilatorLogout",data, {ContentType:'application/json;charset=UTF-8'})
// //添加监控记录
// export const addMonitorRecordReq = data => postReq(apiUrl3+"/anon/projectInvigilate/addMonitorRecord",data, {ContentType:'application/json;charset=UTF-8'})
// //获取考生的监控记录
// export const selectMonitorRecordReq = data => postReq(apiUrl3+"/anon/projectInvigilate/selectMonitorRecord",data)
export function selectMonitorRecordReq(data) {
  return requestProject({
    url: "/anon/projectInvigilate/selectMonitorRecord",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //常用监控记录列表
// export const selectMonitorRecordKindReq = data => postReq(apiUrl3+"/anon/projectInvigilate/selectMonitorRecordKind",data)

// //小程序码
// export const postXcxEwmReq = data => postReq(apiUrl3+"/smallRoutine/code",data)
export function postXcxEwmReq(data) {
  return requestProject({
    url: "/smallRoutine/code",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// //小程序外链
// export const postXcxLinkReq = data => postReq(apiUrl3+"/smallRoutine/getScheme",data, {ContentType:'application/json;charset=UTF-8'})

// //项目-评价
// export const getProRoleListReq = data => getReq(apiUrl3+"/project/appraise/rel/selectRole",data)//角色列表
export function getProRoleListReq(params) {
  return requestProject({
    url: "/project/appraise/rel/selectRole",
    method: "get",
    params,
  });
}

// export const postProRoleUpdateReq = data => postReq(apiUrl3+"/project/appraise/rel/updateRole",data, {ContentType:'application/json;charset=UTF-8'})//角色编辑
export function postProRoleUpdateReq(data) {
  return requestProject({
    url: "/project/appraise/rel/updateRole",
    method: "post",
    data,
  });
}

// export const postProRoleDelReq = data => postReq(apiUrl3+"/project/appraise/rel/deleteRole",data, {ContentType:'application/json;charset=UTF-8'})//角色删除
export function postProRoleDelReq(data) {
  return requestProject({
    url: "/project/appraise/rel/deleteRole",
    method: "post",
    data,
  });
}

// export const getProJudgesListReq = data => getReq(apiUrl3+"/project/appraise/rel/selectWorkmate",data)//评委列表
export function getProJudgesListReq(params) {
  return requestProject({
    url: "/project/appraise/rel/selectWorkmate",
    method: "get",
    params,
  });
}

// export const postProJudgesUpdateReq = data => postReq(apiUrl3+"/project/appraise/rel/updateProjectWorkmate",data, {ContentType:'application/json;charset=UTF-8'})//评委编辑
export function postProJudgesUpdateReq(data) {
  return requestProject({
    url: "/project/appraise/rel/updateProjectWorkmate",
    method: "post",
    data,
  });
}

// export const delProJudgesDelReq = data => delReq(apiUrl3+"/project/appraise/rel/deleteProjectWorkmate",data)//评委删除
export function delProJudgesDelReq(params) {
  return requestProject({
    url: "/project/appraise/rel/deleteProjectWorkmate",
    method: "delete",
    params,
  });
}

// export const getProTypeListReq = data => getReq(apiUrl3+"/project/appraise/rel/selectJudgeType",data)//评委类别列表
export function getProTypeListReq(params) {
  return requestProject({
    url: "/project/appraise/rel/selectJudgeType",
    method: "get",
    params,
  });
}

// export const postProTypeUpdateReq = data => postReq(apiUrl3+"/project/appraise/rel/updateProjectJudgeType",data, {ContentType:'application/json;charset=UTF-8'})//评委类别编辑
export function postProTypeUpdateReq(data) {
  return requestProject({
    url: "/project/appraise/rel/updateProjectJudgeType",
    method: "post",
    data,
  });
}

// export const delProTypeDelReq = data => delReq(apiUrl3+"/project/appraise/rel/deleteProjectJudgeType",data)//评委类别删除
export function delProTypeDelReq(params) {
  return requestProject({
    url: "/project/appraise/rel/deleteProjectJudgeType",
    method: "delete",
    params,
  });
}

// export const getProObjListReq = data => getReq(apiUrl3+"/project/appraise/rel/selectObjPage",data)//评估对象列表
export function getProObjListReq(params) {
  return requestProject({
    url: "/project/appraise/rel/selectObjPage",
    method: "get",
    params,
  });
}

// export const postProObjReq = data => postReq(apiUrl3+"/project/appraise/rel/addObj",data, {ContentType:'application/json;charset=UTF-8'})//评估对象新增编辑
export function postProObjReq(data) {
  return requestProject({
    url: "/project/appraise/rel/addObj",
    method: "post",
    data,
  });
}

// export const delProObjDelReq = data => delReq(apiUrl3+"/project/appraise/rel/delObj",data)//评估对象删除
export function delProObjDelReq(params) {
  return requestProject({
    url: "/project/appraise/rel/delObj",
    method: "delete",
    params,
  });
}

// export const selectWorkmateObjectStatusReq = data => getReq(apiUrl3+"/project/appraise/rel/selectWorkmateObjectStatus",data)//查询单个评委评价被评者内容
export function selectWorkmateObjectStatusReq(params) {
  return requestProject({
    url: "/project/appraise/rel/selectWorkmateObjectStatus",
    method: "get",
    params,
  });
}

// export const resetStuRecordReq = data => postReq(apiUrl3+"/project/appraise/rel/resetStuRecord",data)//评委重置记录
export function resetStuRecordReq(data) {
  return requestProject({
    url: "/project/appraise/rel/resetStuRecord",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const postProObjDepReq = data => postReq(apiUrl3+"/project/appraise/rel/selectDeptByCompId",data, {ContentType:'application/json;charset=UTF-8'})//评价部门查询
export function postProObjDepReq(data) {
  return requestProject({
    url: "/project/appraise/rel/selectDeptByCompId",
    method: "post",
    data,
  });
}

// export const postProObjDepAddReq = data => postReq(apiUrl3+"/project/appraise/rel/addDept",data, {ContentType:'application/json;charset=UTF-8'})//评价部门新增
export function postProObjDepAddReq(data) {
  return requestProject({
    url: "/project/appraise/rel/addDept",
    method: "post",
    data,
  });
}

// export const postProObjDepEditReq = data => postReq(apiUrl3+"/project/appraise/rel/updateDept",data, {ContentType:'application/json;charset=UTF-8'})//评价部门编辑/删除
export function postProObjDepEditReq(data) {
  return requestProject({
    url: "/project/appraise/rel/updateDept",
    method: "post",
    data,
  });
}

// //项目外观
// export const postProStyleReq = data => postReq(apiUrl3+"/projectStyleSet/editProjectStyle",data, {ContentType:'application/json;charset=UTF-8'})//外观新增编辑
export function postProStyleReq(data) {
  return requestProject({
    url: "/projectStyleSet/editProjectStyle",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// export const postProStyleSetReq = data => postReq(apiUrl3+"/projectStyleSet/selectProjectStyleSetNose",data)//外观查询
export function postProStyleSetReq(data) {
  return requestProject({
    url: "/projectStyleSet/selectProjectStyleSetNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //项目-数据-非评估
// export const getProDataReq = data => getReq(apiUrl3+"/project/data/selectPage",data)//列表
//列表
export function getProDataReq(params) {
  return requestProject({
    url: "/project/data/selectPage",
    method: "get",
    params,
  });
}

// export const getProPaperAnswerReq = data => getReq(apiUrl3+"/project/data/selectPaperAnswerData",data)//阅卷
export function getProPaperAnswerReq(params) {
  return requestProject({
    url: "/project/data/selectPaperAnswerData",
    method: "get",
    params,
  });
}

// export const postMarkingReq = data => postReq(apiUrl3+"/project/data/Marking",data, {ContentType:'application/json;charset=UTF-8'})//提交阅卷数据
export function postMarkingReq(data) {
  return requestProject({
    url: "/project/data/Marking",
    method: "post",
    data,
  });
}

// export const getDtPaperQuesListReq = data => postReq(apiUrl3+"/project/data/paper/previewPaper",data)//阅卷时查询动态问卷试题列表
export function getDtPaperQuesListReq(data) {
  return requestProject({
    url: "/project/data/paper/previewPaper",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getInvigilateReq = data => getReq(apiUrl3+"/project/data/selectInvigilatePage",data)//监控数据
export function getInvigilateReq(params) {
  return requestProject({
    url: "/project/data/selectInvigilatePage",
    method: "get",
    params,
  });
}

// export const getInvigilateByIdReq = data => getReq(apiUrl3+"/project/data/selectInvigilateByStudentId",data)//查询单个考生所有监控数据(结果集已按照时间升序排列）
export function getInvigilateByIdReq(params) {
  return requestProject({
    url: "/project/data/selectInvigilateByStudentId",
    method: "get",
    params,
  });
}
// export const getInvigilateByIdNoseReq = (data) => getReq(apiUrl3 + "/project/data/selectInvigilateByStudentIdNose", data); //查询单个考生所有监控数据(结果集已按照时间升序排列）
export function getInvigilateByIdNoseReq(params) {
  return requestProject({
    url: "/project/data/selectInvigilateByStudentIdNose",
    method: "get",
    params,
  });
}
// export const downloadNetReq = data => getReq(apiUrl3+"/project/data/downloadNet.do",data)//监控视频下载

// //项目-数据-评估
// export const getPgProDataReq = data => getReq(apiUrl3+"/project/appraise/data/selectDataPage",data)//列表
export function getPgProDataReq(params) {
  return requestProject({
    url: "/project/appraise/data/selectDataPage",
    method: "get",
    params,
  });
}

// export const getPgPaperAnswerReq = data => getReq(apiUrl3+"/project/appraise/data/selectPaperAnswerData",data)//阅卷
export function getPgPaperAnswerReq(params) {
  return requestProject({
    url: "/project/appraise/data/selectPaperAnswerData",
    method: "get",
    params,
  });
}

// export const getExportDataReq = data => getReq(apiUrl3+"/project/data/exportData",data)//导出答题数据
export function getExportDataReq(params) {
  return requestProject({
    url: "/project/data/exportData",
    method: "get",
    params,
  });
}

// export const getProjectDatatUrlNoseReq = data => getReq(apiUrl3+"/project/data/getProjectDatatUrlNose",data)//获取导出报告的url(不需要token)
export function getProjectDatatUrlNoseReq(params) {
  return requestProject({
    url: "/project/data/getProjectDatatUrlNose",
    method: "get",
    params,
  });
}
//导出答题数据（全部）
export function exportDataAllNose(params) {
  return requestProject({
    url: "/project/data/exportDataAllNose",
    method: "get",
    params,
  });
}
// //项目-成绩-非评估
// export const getProScoreReq = data => getReq(apiUrl3+"/project/score/selectPage",data)//列表
export function getProScoreReq(params) {
  return requestProject({
    url: "/project/score/selectPage",
    method: "get",
    params,
  });
}
// export const getProScoreNoseReq = (data) => getReq(apiUrl3 + "/project/score/selectPageNose", data); //列表
export function getProScoreNoseReq(params) {
  return requestProject({
    url: "/project/score/selectPageNose",
    method: "get",
    params,
  });
} //列表
// export const delProScoreDelReq = data => delReq(apiUrl3+"/project/score/deleteProjectStudentScore",data)//批量删除
export function delProScoreDelReq(params) {
  return requestProject({
    url: "/project/score/deleteProjectStudentScore",
    method: "delete",
    params,
  });
}
// export const updateReportReq = data => postReq(apiUrl5+"/anon/score/updateReport",data)//更新报告
export function updateReportReq(data) {
  return requestScore({
    url: "/anon/score/updateReport",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const updateScoreReq = data => postReq(apiUrl5+"/anon/score/updateScore",data)//更新成绩（返回result为任务id，查询任务进度使用）
export function updateScoreReq(data) {
  return requestScore({
    url: "/anon/score/updateScore",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const postUpdateScoreTaskReq = data => postReq(apiUrl5+"/anon/score/selectTask",data)//查询任务进度（更新成绩、更新报告的进度）
export function postUpdateScoreTaskReq(data) {
  return requestScore({
    url: "/anon/score/selectTask",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const getProScorePaperListReq = data => getReq(apiUrl3+"/project/score/selectPaperList",data)//详情页-问卷列表
export function getProScorePaperListReq(params) {
  return requestProject({
    url: "/project/score/selectPaperList",
    method: "get",
    params,
  });
}

// export const getProScorePaperListNoseReq = data => getReq(apiUrl3+"/project/score/selectPaperListNose",data)//详情页-问卷列表
export function getProScorePaperListNoseReq(params) {
  return requestProject({
    url: "/project/score/selectPaperListNose",
    method: "get",
    params,
  });
}

// export const getScoreDetailReq = data => getReq(apiUrl3+"/project/score/selectScoreDetail",data)//详情
export function getScoreDetailReq(params) {
  return requestProject({
    url: "/project/score/selectScoreDetail",
    method: "get",
    params,
  });
}

// export const getScoreDetailNoseReq = data => getReq(apiUrl3+"/project/score/selectScoreDetailNose",data)//详情
export function getScoreDetailNoseReq(params) {
  return requestProject({
    url: "/project/score/selectScoreDetailNose",
    method: "get",
    params,
  });
}

// export const postImportDataReq = data => postReq(apiUrl3+"/project/data/importData",data)//详情

// //项目-成绩-评估
// export const getPgProScoreReq = data => getReq(apiUrl3+"/project/appraise/score/selectAppraiseScorePage",data)//评估成绩分页查询
export function getPgProScoreReq(params) {
  return requestProject({
    url: "/project/appraise/score/selectAppraiseScorePage",
    method: "get",
    params,
  });
}

// export const getPgProScorePaperListReq = data => getReq(apiUrl3+"/project/appraise/score/selectPaperList",data)//详情页-问卷列表
export function getPgProScorePaperListReq(params) {
  return requestProject({
    url: "/project/appraise/score/selectPaperList",
    method: "get",
    params,
  });
}

// export const getPgScoreDetailReq = data => getReq(apiUrl3+"/project/appraise/score/selectAppraiseScoreDetail",data)//详情
export function getPgScoreDetailReq(params) {
  return requestProject({
    url: "/project/appraise/score/selectAppraiseScoreDetail",
    method: "get",
    params,
  });
}

// export const delRecordReq = data => delReq(apiUrl3+"/project/appraise/rel/delRecord",data)//删除评估成绩
export function delRecordReq(params) {
  return requestProject({
    url: "/project/appraise/rel/delRecord",
    method: "delete",
    params,
  });
}

// export const deleteAppraiseInfoReq = data => postReq(apiUrl3+"/project/appraise/rel/deleteAppraiseInfo",data)//评估项目清空评价关系
export function deleteAppraiseInfoReq(data) {
  return requestProject({
    url: "/project/appraise/rel/deleteAppraiseInfo",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const selectDeptNameByCompIdReq = data => postReq(apiUrl3+"/project/appraise/rel/selectDeptNameByCompId",data, {ContentType:'application/json;charset=UTF-8'})//部门名称查询（包含级联名称，做展示时使用）
export function selectDeptNameByCompIdReq(data) {
  return requestProject({
    url: "/project/appraise/rel/selectDeptNameByCompId",
    method: "post",
    data,
  });
}

// // 同步考生成绩到数据库
// export const addStudentScore = data => postReq(apiUrl7+"/anon/exam/addStudentScore",data, {showLoading: false})//部门名称查询（包含级联名称，做展示时使用）
export function addStudentScore(data) {
  return requestAnswerdata({
    url: "/anon/exam/addStudentScore",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //项目-统计
// export const selectChartsList = data => postReq(apiUrl3+"/project/student/selectChartsList",data)//项目-统计-查询图表
export function selectChartsList(data) {
  return requestProject({
    url: "/project/student/selectChartsList",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const exportGeneralReportReq = data => getReq(apiUrl1+"/fileMake/exportGeneralReport",data)//查询项目数量
export function exportGeneralReportReq(params) {
  return requestUser({
    url: "/fileMake/exportGeneralReport",
    method: "get",
    params,
  });
}

// // 总览
// export const selectMyProjectCount = data => getReq(apiUrl3+"/overViewCtl/selectMyProjectCount",data)//查询项目数量

// //发送邮件---------搞错，下行不是发送邮件，待真正发送邮件接口有了再说
// //export const sendEmailReq = data => postReq(apiUrl3+"/mail/sendMail",data,{ContentType:'application/json;charset=UTF-8'})//发送邮件

// //项目-通知
// export const postNoticeUpdateReq = data => postReq(apiUrl3+"/inform/updateInform?isChange="+data.isChange+"",data, {ContentType:'application/json;charset=UTF-8'})//新增或编辑通知
export function postNoticeUpdateReq(data) {
  return requestProject({
    url: "/inform/updateInform?isChange=" + data.isChange + "",
    method: "post",
    data,
  });
}

// export const getNoticeListReq = data => getReq(apiUrl3+"/inform/selectPage",data)//查询通知--列表
export function getNoticeListReq(params) {
  return requestProject({
    url: "/inform/selectPage",
    method: "get",
    params,
  });
}

// export const getNoticeListNoseReq = (data) => getReq(apiUrl3 + "/inform/selectPageNose", data); //查询通知--列表 无token
export function getNoticeListNoseReq(params) {
  return requestProject({
    url: "/inform/selectPageNose",
    method: "get",
    params,
  });
}
// export const getNoticeInfoReq = data => getReq(apiUrl3+"/inform/selectInfrom",data)//查询通知--详情
export function getNoticeInfoReq(params) {
  return requestProject({
    url: "/inform/selectInfrom",
    method: "get",
    params,
  });
}

// export const delNoticeListReq = data => delReq(apiUrl3+"/inform/deleteInform",data)//删除通知
export function delNoticeListReq(params) {
  return requestProject({
    url: "/inform/deleteInform",
    method: "delete",
    params,
  });
}

// export const postNoticeMemberUpdateReq = data => postReq(apiUrl3+"/inform/member/updateInformMember",data, {ContentType:'application/json;charset=UTF-8'})//人员管理--新增、编辑
export function postNoticeMemberUpdateReq(data) {
  return requestProject({
    url: "/inform/member/updateInformMember",
    method: "post",
    data,
  });
}

// export const postPgSyncAppraise = data => postReq(apiUrl3+"/inform/syncAppraise?projectType="+data.projectType+"&examId="+data.examId+"&informId="+data.informId+"&formId="+data.formId,data.url,{ContentType:'application/json;charset=UTF-8'})//评估类项目同步数据
export function postPgSyncAppraise(data) {
  return requestProject({
    url:
      "/inform/syncAppraise?projectType=" +
      data.projectType +
      "&examId=" +
      data.examId +
      "&informId=" +
      data.informId +
      "&formId=" +
      data.formId,
    method: "post",
    data: data.url,
  });
}

// export const postSyncAppraise = data => postReq(apiUrl3+"/project/student/tongBuKaoSheng?projectType="+data.projectType+"&examId="+data.examId+"&informId="+data.informId+"&formId="+data.formId,data.url,{ContentType:'application/json;charset=UTF-8'})//非评估类项目同步数据
export function postSyncAppraise(data) {
  return requestProject({
    url: `/project/student/tongBuKaoSheng?projectType=${data.projectType}&examId=${data.examId}&informId=${data.informId}&formId=${data.formId}`,
    method: "post",
    data: data.url,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const getNoticeMemberListReq = data => getReq(apiUrl3+"/inform/member/selectPage",data)//查询人员--列表
export function getNoticeMemberListReq(params) {
  return requestProject({
    url: "/inform/member/selectPage",
    method: "get",
    params,
  });
}

// export const getNoticeMemberReq = data => getReq(apiUrl3+"/inform/member/selectInformMember",data)//查询人员--详情
export function getNoticeMemberReq(params) {
  return requestProject({
    url: "/inform/member/selectInformMember",
    method: "get",
    params,
  });
}

// export const DelNoticeMemberReq = data => delReq(apiUrl3+"/inform/member/deleteInformMember",data)//删除人员
export function DelNoticeMemberReq(params) {
  return requestProject({
    url: "/inform/member/deleteInformMember",
    method: "delete",
    params,
  });
}

// export const postNoticeMemberSendMsgReq = (data) => {
//   let url = apiUrl3+"/inform/sendMsg?informId="+data.informId+"&needConfirm="+data.needConfirm+"&sendType="+data.sendType+"&noticeTitle="+data.noticeTitle+"&informContent="+data.informContent+"&projectType=" + data.projectType;
//   return postReq(url,data.memberList, {ContentType:'application/json;charset=UTF-8'})
// }//发通知
export function postNoticeMemberSendMsgReq(data) {
  return requestProject({
    url:
      "/inform/sendMsg?informId=" +
      data.informId +
      "&needConfirm=" +
      data.needConfirm +
      "&sendType=" +
      data.sendType +
      "&noticeTitle=" +
      data.noticeTitle +
      "&informContent=" +
      data.informContent +
      "&projectType=" +
      data.projectType,
    method: "post",
    data: data.memberList,
  });
}

// export const getNoticeMemberListNoticeInfoReq = data => getReq(apiUrl3+"/inform/member/selectMemberNoticeInfo",data)//人员通知记录查询--列表
export function getNoticeMemberListNoticeInfoReq(params) {
  return requestProject({
    url: "/inform/member/selectMemberNoticeInfo",
    method: "get",
    params,
  });
}

// export const getNoticeMemberListConfirmInfoReq = data => getReq(apiUrl3+"/inform/member/selectMemberConfirmInfo",data)//人员确认记录查询--列表
export function getNoticeMemberListConfirmInfoReq(params) {
  return requestProject({
    url: "/inform/member/selectMemberConfirmInfo",
    method: "get",
    params,
  });
}
// export const getselectStudentNoticeInfoReq = (data) =>
//   getReq(apiUrl3 + "/inform/member/selectStudentNoticeInfo", data); //人员确认记录查询--列表
// export const getselectStudentNoticeInfoNoseReq = (data) =>
//   getReq(apiUrl3 + "/inform/member/selectStudentNoticeInfoNose", data); //人员确认记录查询--列表
export function getselectStudentNoticeInfoNoseReq(params) {
  return requestProject({
    url: "/inform/member/selectStudentNoticeInfoNose",
    method: "get",
    params,
  });
} //人员确认记录查询--列表

// //项目-通知-表单部分
// export const getNoticeFFDetailReq = data => getReq(apiUrl3+"/inform/form/item/selectFormItem",data)//通知表单项目查询
export function getNoticeFFDetailReq(params) {
  return requestProject({
    url: "/inform/form/item/selectFormItem",
    method: "get",
    params,
  });
}

// export const getNoticeFFSaveReq = data => postReq(apiUrl3+"/inform/form/updateFormDef",data, {ContentType:'application/json;charset=UTF-8'})//通知表单维护=新增或编辑通知表单-保存
// export const getFormTempReq = data => getReq(apiUrl3+"/inform/form/selectFormDefTemplate",data)//选择模板列表/单个模板详情
export function getFormTempReq(params) {
  return requestProject({
    url: "/inform/form/selectFormDefTemplate",
    method: "get",
    params,
  });
}

// export const postFormTempReq = data => postReq(apiUrl3+"/inform/form/updateFormDefTemplate",data, {ContentType:'application/json;charset=UTF-8'})//新增/编辑模板
export function postFormTempReq(data) {
  return requestProject({
    url: "/inform/form/updateFormDefTemplate",
    method: "post",
    data,
  });
}

// export const delFormTempReq = data => delReq(apiUrl3+"/inform/form/deleteFormDefTemplate",data)//删除模板
export function delFormTempReq(params) {
  return requestProject({
    url: "/inform/form/deleteFormDefTemplate",
    method: "delete",
    params,
  });
}

// //项目-通知-订单确认页面
// export const getTempFormReq = data => getReq(apiUrl3+"/inform/form/item/selectFormItemNose",data)//查询模板表单--订单确认页面
export function getTempFormReq(params) {
  return requestProject({
    url: "/inform/form/item/selectFormItemNose",
    method: "get",
    params,
  });
}
// export const getTempFormValReq = data => getReq(apiUrl3+"/inform/member/selectInformMemberNose",data)//查询模板表单值
export function getTempFormValReq(params) {
  return requestProject({
    url: "/inform/member/selectInformMemberNose",
    method: "get",
    params,
  });
}
// export const postTempFormValReq = data => postReq(apiUrl3+"/inform/member/updateInformMemberNose",data, {ContentType:'application/json;charset=UTF-8'})//保存模板表单值

// //问卷-收藏夹
// export const getCollectPaperListReq = data => getReq(apiUrl3+"/paper/selectCollPage",data,{showLoading:true,loadingTarget:'.console-main'})//列表
// export const delCollectPaperReq = data => delReq(apiUrl3+"/paper/delCollPaper",data)//取消收藏

// //项目-收藏夹
// export const getCollectProListReq = data => getReq(apiUrl3+"/project/selectCollPage",data,{showLoading:data.notshow?false:true,loadingTarget:'.console-main'})//列表
// export const delCollectProReq = data => delReq(apiUrl3+"/project/delCollProject",data)//取消收藏

// // 项目套餐
export function selectProjectComboList(params) {
  return requestProject({
    url: "/project/paper/selectProjectComboList",
    method: "get",
    params,
  });
}

export function updateProjectComboStatus(params) {
  return requestProject({
    url: "/project/paper/updateProjectComboStatus",
    method: "get",
    params,
  });
}

// // 个人成绩生成
// export const perAddScore = data => postReq(apiUrl5+"/anon/score/preAddScore",data, {showLoading:false})
export function perAddScore(data) {
  return requestScore({
    url: "/anon/score/preAddScore",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //项目成绩-导出报告
// export const exportProjectReportReq = data => getReq(apiUrl1+"/fileMake/exportProjectReport",data)//导出报告--获取code代码
export function exportProjectReportReq(params) {
  return requestUser({
    url: "/fileMake/exportProjectReport",
    method: "get",
    params,
  });
}

// export const getProjectReportReq = data => getReq(apiUrl1+"/fileMake/getExportUrl",data)//导出报告--获取code代码
export function getProjectReportReq(params) {
  return requestUser({
    url: "/fileMake/getExportUrl",
    method: "get",
    params,
  });
}

// export const exportProjectReportNoseReq = data => getReq(apiUrl1+"/fileMake/exportProjectReportNose",data)//导出报告--获取code代码,不需要token
export function exportProjectReportNoseReq(params) {
  return requestUser({
    url: "/fileMake/exportProjectReportNose",
    method: "get",
    params,
  });
}

// export const getProjectReportNoseReq = data => getReq(apiUrl1+"/fileMake/getExportUrlNose",data)//导出报告--获取code代码--获取code代码,不需要token
export function getProjectReportNoseReq(params) {
  return requestUser({
    url: "/fileMake/getExportUrlNose",
    method: "get",
    params,
  });
}

// //阅卷系统-新-------------------------------↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓--------------------------------------------------------

// export const editMarkingConfigReq = data => postReq(apiUrl3+"/cTeacher/editMarkingConfig",data, {ContentType:'application/json;charset=UTF-8'})//项目阅卷设置
export function editMarkingConfigReq(data) {
  return requestProject({
    url: `/cTeacher/editMarkingConfig`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const getMarkingConfigReq = data => postReq(apiUrl3+"/cTeacher/getMarkingConfig?examId="+data.examId+"&projectType="+data.projectType,data, {ContentType:'application/json;charset=UTF-8'})//项目阅卷设置
export function getMarkingConfigReq(data) {
  return requestProject({
    url: `/cTeacher/getMarkingConfig?examId=${data.examId}&projectType=${data.projectType}`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const getTeacherSelectPageReq = data => getReq(apiUrl3+"/cTeacher/selectPageNew",data)//阅卷老师查询（新）
export function getTeacherSelectPageReq(params) {
  return requestProject({
    url: "/cTeacher/selectPageNew",
    method: "get",
    params,
  });
}
// export const postEditTeacherReq = data => postReq(apiUrl3+"/cTeacher/editTeacher",data, {ContentType:'application/json;charset=UTF-8'})//新增或者编辑阅卷老师的接口
export function postEditTeacherReq(data) {
  return requestProject({
    url: "/cTeacher/editTeacher",
    method: "post",
    data,
  });
}
// export const postDeleteTeacherReq = data => postReq(apiUrl3+"/cTeacher/deleteTeacher?teacherIds="+data.teacherIds+"",data)//批量删除阅卷老师
export function postDeleteTeacherReq(data) {
  return requestProject({
    url: `/cTeacher/deleteTeacher?teacherIds=${data.teacherIds}`,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const postCheckTeacherNoseReq = data => postReq(apiUrl3+"/cTeacher/checkTeacherNose",data)//验证阅卷老师的登录接口

// export const addPaperTeacherPartReq = data => postReq(apiUrl3+"/cTeacher/addProjPaperTeacherPart?teacherId="+data.teacherId,data.partList, {ContentType:'application/json;charset=UTF-8'})//新增阅卷老师part（新）
export function addPaperTeacherPartReq(data) {
  return requestProject({
    url: `/cTeacher/addProjPaperTeacherPart?teacherId=${data.teacherId}`,
    method: "post",
    data: data.partList,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}
// export const selectPaperPartQuestionReq = data => postReq(apiUrl3+"/cTeacher/selectProjPaperPartQuestionNose",data)//获取问卷part试题（新）
export function selectPaperPartQuestionReq(data) {
  return requestProject({
    url: "/cTeacher/selectProjPaperPartQuestionNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const selectPaperTeacherPartReq = data => postReq(apiUrl3+"/cTeacher/selectProjPaperTeacherPartNose?teacherId="+data.teacherId,data)//获取问卷老师已分配part（新）
export function selectPaperTeacherPartReq(data) {
  return requestProject({
    url: `/cTeacher/selectProjPaperTeacherPartNose?teacherId=${data.teacherId}`,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
// export const selectProjPartQuestionByTeacherReq = data => postReq(apiUrl3+"/cTeacher/selectProjPartQuestionByTeacher",data, {ContentType:'application/json;charset=UTF-8'})//根据登录的阅卷老师获取问卷part试题下拉（新）

// export const markingReq = data => postReq(apiUrl3+"/anon/project/markingData/Marking",data, {ContentType:'application/json;charset=UTF-8'})//提交阅卷数据
// export const markingDataListReq = data => getReq(apiUrl3+"/anon/project/markingData/selectPage",data)//项目数据分页查询
// export const selectCloseStudentReq = data => getReq(apiUrl3+"/anon/project/markingData/selectCloseStudent",data)//根据当前考生id获取上一个和下一个考生的id
// export const selectPaperAnswerDataReq = data => getReq(apiUrl3+"/anon/project/markingData/selectPaperAnswerData",data)//项目数据阅卷
// export const UpdateMarkingStatusReq = data => postReq(apiUrl3+"/anon/project/markingData/UpdateMarkingStatus",data)//更新考生part阅卷状态
// export const selectMarkingRecordReq = data => getReq(apiUrl3+"/anon/project/markingData/selectMarkingRecord",data)//查询考生试题评分记录

// //阅卷系统-新-------------------------------↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑--------------------------------------------------------

// // Com/Setting/monitor/monitor
// export const getProjectRTCList = data => getReq(apiUrl3+"/projectRTC/selectProjectPage",data) //查询项目音视频数据
// export const getProjectRTC = data => getReq(apiUrl3+"/projectRTC/selectStudentPage",data) //查询单个项目所有考生音视频数据

// //阅卷模块
// export const selectPaperPartQuestionPro = data => postReq(apiUrl2+"/paperMarking/selectProjPaperPartQuestion",data)//分题型获取未分part的试题
export function selectPaperPartQuestionPro(data) {
  return requestPaper({
    url: "/paperMarking/selectProjPaperPartQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const addPaperPartPro = data => postReq(apiUrl2+"/paperMarking/addProjPaperPart",data)//新增part
export function addPaperPartPro(data) {
  return requestPaper({
    url: "/paperMarking/addProjPaperPart",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const removePaperPartPro = data => postReq(apiUrl2+"/paperMarking/removeProjPaperPart",data)//删除part
export function removePaperPartPro(data) {
  return requestPaper({
    url: "/paperMarking/removeProjPaperPart",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const addPaperPartQuestionPro = data => postReq(apiUrl2+"/paperMarking/addProjPaperPartQuestion?paperId="+data.paperId+"&partId="+data.partId+"&examId="+data.examId+"&projectType="+data.projectType+"&paperTypeId="+data.paperTypeId+"",data.questionList, {ContentType:'application/json;charset=UTF-8'})//新增part下试题
export function addPaperPartQuestionPro(data) {
  return requestPaper({
    url:
      "/paperMarking/addProjPaperPartQuestion?paperId=" +
      data.paperId +
      "&partId=" +
      data.partId +
      "&examId=" +
      data.examId +
      "&projectType=" +
      data.projectType +
      "&paperTypeId=" +
      data.paperTypeId +
      "",
    method: "post",
    data: data.questionList,
  });
}

// export const removePaperPartQuestionPro = data => postReq(apiUrl2+"/paperMarking/removeProjPaperPartQuestion",data)//删除part下试题
export function removePaperPartQuestionPro(data) {
  return requestPaper({
    url: "/paperMarking/removeProjPaperPartQuestion",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const updatePartQuestionSortPro = data => postReq(apiUrl2+"/paperMarking/updateProjPartQuestionSort",data.list, {ContentType:'application/json;charset=UTF-8'})//问卷重新排序后同步更改part中的题目号

export function createNumNoseReq(data) {
  return requestProject({
    url: "/project/student/createNumNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
export function updateProjectStudentBatchReq(params) {
  return requestProject({
    url: "/project/student/updateProjectStudentBatch",
    method: "post",
    data: params,
  });
}
//

/*
 * @Description: 系统消息查询
 * @Date: 2024-04-22 10:16:48
 * @LastEditTime: 2024-05-06 17:48:45
 */

export default function (axios) {
  return {
//  /cNewsRemind/deleteNewsRemindById 删除系统消息
matcheastDeleteNewsRemindById(data){
  return axios.post(
    "/cNewsRemind/deleteNewsRemindById?remindId=" + data.remindId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},


// /cNewsRemind/selectNewsRemind 查询消息列表
matcheastSelectNewsRemind(params){
  return axios.get("/cNewsRemind/selectNewsRemind", { params })
},


// /cNewsRemind/selectNewsRemindByType 查询指定系统消息列表
matcheastSelectNewsRemindByType(params){
  return axios.get("/cNewsRemind/selectNewsRemindByType", { params })
},

// /cNewsRemind/selectNoReadNewsCount  查询所有的未读消息数量
matcheastSelectNoReadNewsCount(params){
  return axios.get("/cNewsRemind/selectNoReadNewsCount", { params })
},

// /cNewsRemind/updateIsReadNews  对系统消息进行已读
matcheastUpdateIsReadNews(data){
  return axios.post(
    "/cNewsRemind/updateIsReadNews?remindFirstType=" + data.remindFirstType + "&remindSecondType=" + data.remindSecondType,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

  }}

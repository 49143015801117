/*
 * @Description: 活动吧相关的页面 主要是详情页
 * @Date: 2023-11-17 16:22:39
 * @LastEditTime: 2023-11-17 17:53:11
 */

export default {
  parent: "signupActivity",
  routers: [
    {
      //报名--问卷
      path: "/Project/Detail/Signup/Questionnaire",
      name: "ProBmQuestionnaire",
      component: () =>
        import("@/views/staff/activity/detail/questionnaireView.vue"),
    },
    {
      //报名--报名（不认证）
      path: "/Project/Detail/Signup/Signup",
      name: "ProBmSignup",
      component: () => import("@/views/staff/activity/detail/signupView.vue"),
    },
    {
      //报名--报名（认证）
      path: "/Project/Detail/Signup/Signup2",
      name: "ProBmSignup2",
      component: () => import("@/views/staff/activity/detail/signup2View.vue"),
    },
    {
      //报名--成绩
      path: "/Project/Detail/Signup/Score",
      name: "ProBmScore",
      component: () => import("@/views/staff/activity/detail/scoreView.vue"),
    },
    {
      //报名--数据
      path: "/Project/Detail/Signup/Data",
      name: "ProBmData",
      component: () => import("@/views/staff/activity/detail/dataView.vue"),
    },
    {
      //报名--统计
      path: "/Project/Detail/Signup/Statistics",
      name: "ProBmStatistics",
      component: () =>
        import("@/views/staff/activity/detail/statisticsView.vue"),
    },
    {
      //报名--通知
      path: "/Project/Detail/Signup/Notice",
      name: "ProBmNotice",
      component: () => import("@/views/staff/activity/detail/noticeView.vue"),
    },
  ],
};

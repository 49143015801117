<template>
  <div>
    <el-popover
      trigger="click"
      v-model="showTimePicker"
      width="450"
      popper-class="asktrue-popover"
    >
      <div
        slot="reference"
        v-if="from == 'xm'"
        class="date-style-xm fl date-picker-self-chooser c6 no-select cur pr"
        :title="startDate + ' 至 ' + endDate"
      >
        <div class="nullDate c9" v-if="!minDate && !maxDate">
          {{ placeholder }}
        </div>
        <div class="dateTime tac" v-if="minDate">{{ startDate }}</div>
        <div class="zhi ml5 mr5" v-if="minDate || maxDate">至</div>
        <div class="dateTime tac" v-if="maxDate">{{ endDate }}</div>
        <i
          class="el-icon-circle-close pa c9"
          @click.stop="clearTime"
          style="right: 5px; top: 12px"
          v-if="startDate && endDate"
        ></i>
      </div>

      <div
        slot="reference"
        v-else
        class="fl date-picker-self-chooser f12 c6 no-select cur pr bgf"
        :class="{ ml10: marginl }"
      >
        <i class="c9 el-icon-date pa" style="top: 12px; left: 5px"></i>
        <div style="width: 120px" class="tac" v-if="minDate">
          {{ startDate }}
        </div>
        <div style="width: 90px" class="tac c9" v-else>起始日期</div>
        <div class="ml5 mr5">至</div>
        <div style="width: 120px" class="tac" v-if="maxDate">{{ endDate }}</div>
        <div style="width: 90px" class="tac c9" v-else>结束日期</div>
        <i
          class="el-icon-circle-close pa c9"
          @click.stop="clearTime"
          style="right: 5px; top: 12px"
          v-if="startDate && endDate"
        ></i>
      </div>

      <div class="date-input">
        <input type="text" placeholder="开始日期" readonly v-model="minYmd" />
        <el-time-picker
          v-model="minTime"
          placeholder="开始时间"
          @change="DateFn()"
        ></el-time-picker>
        <span class="el-icon-arrow-right"></span>
        <input type="text" placeholder="结束日期" readonly v-model="maxYmd" />
        <el-time-picker
          v-model="maxTime"
          placeholder="开始时间"
          @change="DateFn()"
        ></el-time-picker>
      </div>

      <div class="flex range-picker">
        <div class="pickerItem" style="padding-right: 16px">
          <div class="picker-title">
            <div class="f12">
              <span
                @click="rows.barStatus.yearPrev ? changeYear('rows', true) : ''"
                :class="
                  rows.barStatus.yearPrev ? 'c3 cur hov' : 'c9 not-allowed'
                "
                class="el-icon-d-arrow-left"
              ></span>
              <span
                @click="
                  rows.barStatus.monthPrev ? changeMonth('rows', true) : ''
                "
                :class="
                  rows.barStatus.monthPrev ? 'c3 cur hov' : 'c9 not-allowed'
                "
                class="el-icon-arrow-left"
              ></span>
            </div>
            <div>{{ rows.time[0] }}年{{ rows.time[1] }}月</div>
            <div class="f12">
              <span
                @click="changeMonth('rows', false)"
                class="c3 cur hov el-icon-arrow-right"
              ></span>
              <span
                @click="changeYear('rows', false)"
                class="c3 cur hov el-icon-d-arrow-right"
              ></span>
            </div>
            <!-- <span @click="rows.barStatus.monthNext?changeMonth('rows', false):''" :class="rows.barStatus.monthNext? 'c3 cur hov' :'c9 not-allowed'" class="el-icon-arrow-right"></span>
            <span @click="rows.barStatus.yearNext?changeYear('rows', false):''" :class="rows.barStatus.yearNext? 'c3 cur hov' :'c9 not-allowed'" class="el-icon-d-arrow-right"></span></div> -->
          </div>
          <table cellspacing="0" cellpadding="0" class="el-date-table">
            <tbody>
              <tr style="line-height: 30px">
                <th v-for="(week, key) in weeks" :key="key">
                  {{ dayName[week] }}
                </th>
              </tr>
              <tr
                class="el-date-table__row"
                v-for="(row, key) in rows.table"
                :key="key"
              >
                <td
                  v-for="(cell, key) in row"
                  :class="getMinCellClasses(rows.time, cell)"
                  :key="key"
                >
                  <div
                    @click="
                      cell.class == 'action'
                        ? checkMinCell(
                            rows.time[0],
                            rows.time[1],
                            cell.text,
                            cell.class
                          )
                        : ''
                    "
                    :class="{ c9: cell.class != 'action', c39c: cell.c39c }"
                    class="flex-center"
                  >
                    <span>{{ cell.text }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pickerItem borderl" style="padding-left: 16px">
          <div class="picker-title">
            <div class="f12">
              <span
                @click="changeYear('rows2', true)"
                class="c3 cur hov el-icon-d-arrow-left"
              ></span>
              <span
                @click="changeMonth('rows2', true)"
                class="c3 cur hov el-icon-arrow-left"
              ></span>
              <!-- <span @click="rows2.barStatus.yearPrev?changeYear('rows2', true):''" :class="rows2.barStatus.yearPrev? 'c3 cur hov' :'c9 not-allowed'" class="el-icon-d-arrow-left"></span>
            <span @click="rows2.barStatus.monthPrev?changeMonth('rows2', true):''" :class="rows2.barStatus.monthPrev? 'c3 cur hov' :'c9 not-allowed'" class="el-icon-arrow-left"></span> -->
            </div>
            <div>{{ rows2.time[0] }}年{{ rows2.time[1] }}月</div>
            <div class="f12">
              <span
                @click="
                  rows2.barStatus.monthNext ? changeMonth('rows2', false) : ''
                "
                :class="
                  rows2.barStatus.monthNext ? 'c3 cur hov' : 'c9 not-allowed'
                "
                class="el-icon-arrow-right"
              ></span>
              <span
                @click="
                  rows2.barStatus.yearNext ? changeYear('rows2', false) : ''
                "
                :class="
                  rows2.barStatus.yearNext ? 'c3 cur hov' : 'c9 not-allowed'
                "
                class="el-icon-d-arrow-right"
              ></span>
            </div>
          </div>
          <table cellspacing="0" cellpadding="0" class="el-date-table">
            <tbody>
              <tr style="line-height: 30px">
                <th v-for="(week, key) in weeks" :key="key">
                  {{ dayName[week] }}
                </th>
              </tr>
              <tr
                class="el-date-table__row"
                v-for="(row, key) in rows2.table"
                :key="key"
              >
                <td
                  v-for="(cell, key) in row"
                  :class="getMaxCellClasses(rows2.time, cell)"
                  :key="key"
                >
                  <div
                    @click="
                      cell.class == 'action'
                        ? checkMaxCell(rows2.time[0], rows2.time[1], cell.text)
                        : ''
                    "
                    :class="{ c9: cell.class != 'action', c39c: cell.c39c }"
                    class="flex-center"
                  >
                    <span>{{ cell.text }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="date-btn">
        <el-button
          @click="
            closePopFn();
            defaultFn();
          "
          >关闭</el-button
        >
        <el-button @click="clearPopFn">清空</el-button>
        <el-button type="primary" @click="timePickerFn">确定</el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { getDay } from "../../js/dateRangePicker";
export default {
  data() {
    return {
      showTimePicker: false,
      maxDate: "",
      minDate: "",
      maxTime: "",
      minTime: "",
      weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayName: {
        Sun: "日",
        Mon: "一",
        Tue: "二",
        Wed: "三",
        Thu: "四",
        Fri: "五",
        Sat: "六",
      },
      rows: {},
      rows2: {},
      currentDay: getDay(new Date()),
    };
  },
  props: {
    from: String,
    placeholder: String,
    start: String,
    end: String,
    marginl: { default: true },
  },
  computed: {
    startDate() {
      return this.minDate
        ? new Date(+this.minDate + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 16)
            .replace("T", " ")
        : "";
    },
    endDate() {
      return this.maxDate
        ? new Date(+this.maxDate + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 16)
            .replace("T", " ")
        : "";
    },
    minYmd() {
      return this.minDate
        ? new Date(+this.minDate + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 10)
            .replace("T", " ")
        : "";
    },
    maxYmd() {
      return this.maxDate
        ? new Date(+this.maxDate + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 10)
            .replace("T", " ")
        : "";
    },
  },
  created() {
    this.firstGet();
    if (this.start) {
      this.minDate = new Date(this.start.replace(/-/g, "/"));
      this.minTime = new Date(this.start.replace(/-/g, "/"));
      //this.currentDay= getDay(this.minDate);
    }
    if (this.end) {
      this.maxDate = new Date(this.end.replace(/-/g, "/"));
      this.maxTime = new Date(this.end.replace(/-/g, "/"));
    }
  },
  activated() {},
  methods: {
    clearTime() {
      this.minDate = "";
      this.maxDate = "";
      this.$emit("rangeChange");
    },
    checkMinCell(year, month, day) {
      this.minTime = this.minTime || new Date();
      let hh = this.minTime.getHours(); //获取系统时，
      let mm = this.minTime.getMinutes(); //分
      let ss = this.minTime.getSeconds(); //秒
      let newDate = new Date(
        year + "/" + month + "/" + day + " " + hh + ":" + mm + ":" + ss
      );
      this.minDate = newDate;
    },
    checkMaxCell(year, month, day) {
      this.maxTime = this.maxTime || new Date();
      let hh = this.maxTime.getHours(); //获取系统时，
      let mm = this.maxTime.getMinutes(); //分
      let ss = this.maxTime.getSeconds(); //秒
      let newDate = new Date(
        year + "/" + month + "/" + day + " " + hh + ":" + mm + ":" + ss
      );
      this.maxDate = newDate;
    },
    // 切换年数
    changeYear(type, prev) {
      let time;
      if (prev) {
        time = new Date(this[type].time[0] - 1, this[type].time[1], 0);
      } else {
        time = new Date(this[type].time[0] + 1, this[type].time[1], 0);
      }
      this[type].time = getDay(time);
      this[type].table = this.getRows(this[type].time);
      this.getNextPrevStatus();
    },
    // 切换月份
    changeMonth(type, prev) {
      let time;
      if (prev) {
        time = new Date(this[type].time[0], this[type].time[1] - 1, 0);
      } else {
        time = new Date(this[type].time[0], this[type].time[1] + 1, 0);
      }
      this[type].time = getDay(time);
      this[type].table = this.getRows(this[type].time);
      this.getNextPrevStatus();
    },
    // 获取是否可以切换上年下年上月下月的状态
    getNextPrevStatus() {
      // 年数相差一年以上
      if (
        this.rows.time[0] + 1 < this.rows2.time[0] ||
        (this.rows.time[0] < this.rows2.time[0] &&
          this.rows.time[1] < this.rows2.time[1])
      ) {
        this.rows.barStatus.yearNext = true;
        this.rows2.barStatus.yearPrev = true;
        this.rows.barStatus.monthNext = true;
        this.rows2.barStatus.monthPrev = true;
      } else {
        this.rows.barStatus.yearNext = false;
        this.rows2.barStatus.yearPrev = false;
        if (
          this.rows.time[1] + 1 < this.rows2.time[1] ||
          this.rows.time[0] < this.rows2.time[0]
        ) {
          this.rows.barStatus.monthNext = true;
          this.rows2.barStatus.monthPrev = true;
        } else {
          this.rows.barStatus.monthNext = false;
          this.rows2.barStatus.monthPrev = false;
        }
      }
      this.$forceUpdate();
    },
    timePickerFn() {
      if (this.startDate || this.endDate) {
        if (this.minDate > this.maxDate) {
          this.$message.error("开始时间必须小于结束时间！");
          return;
        } else {
          this.$emit("rangeChange", this.minDate, this.maxDate);
          this.closePopFn();
        }
      } else {
        this.$message.error("起止时间不能为空！");
      }
    },
    //关闭弹窗
    closePopFn() {
      this.showTimePicker = false;
    },
    //初始默认时间
    defaultFn() {
      if (this.start) {
        this.minDate = new Date(this.start.replace(/-/g, "/"));
      }
      if (this.end) {
        this.maxDate = new Date(this.end.replace(/-/g, "/"));
      }
    },
    //清空弹窗
    clearPopFn() {
      this.minDate = "";
      this.maxDate = "";
    },

    // 第一次获取日期表
    firstGet() {
      let leftDay = this.start
        ? getDay(new Date(this.start.replace(/-/g, "/")))
        : this.currentDay;
      let rightDay = this.end
        ? getDay(new Date(this.end.replace(/-/g, "/")))
        : getDay(new Date(this.currentDay[0], this.currentDay[1] + 1, 0));

      this.rows.table = this.getRows(leftDay);
      this.rows.time = leftDay;
      this.rows.barStatus = {
        monthPrev: true,
        yearPrev: true,
        monthNext: false,
        yearNext: false,
      };
      //let arr = getDay(new Date(this.currentDay[0],this.currentDay[1]+1, 0), true);
      this.rows2.table = this.getRows(rightDay);
      this.rows2.time = rightDay;
      this.rows2.barStatus = {
        monthPrev: false,
        yearPrev: false,
        monthNext: true,
        yearNext: true,
      };
    },
    // thisMonth: 哪个月的日历表
    // monthOn: 此日历表是否是当前日期所属月分
    getRows(thisMonth) {
      let monthOn =
        thisMonth[0] == this.currentDay[0] &&
        thisMonth[1] == this.currentDay[1]; //是否是当前日期月份

      let arr;
      if (thisMonth[1] == 1) {
        arr = getDay(new Date(thisMonth[0] - 1, 12, 0)); //获取上个月的数据【年，月，月份中最大日】
      } else {
        arr = getDay(new Date(thisMonth[0], thisMonth[1] - 1, 0)); //获取上个月的数据【年，月，月份中最大日】
      }

      let firstDay = arr[2] - this.weeks.indexOf(arr[3]);
      let lastMonthEnd = arr[2];
      let monthEnd = getDay(new Date(thisMonth[0], thisMonth[1], 0))[2];
      let on = "pre";
      let l = 6;
      let arr1 = [];
      let c39c;
      while (l--) {
        let j = 7;
        let a = [];
        while (j--) {
          a.push({ text: firstDay, class: on, c39c });
          c39c = false;
          if (on == "pre" && firstDay <= lastMonthEnd) {
            if (firstDay == lastMonthEnd) {
              firstDay = 0;
              on = "action";
            }
          }
          if (on == "action" && firstDay <= monthEnd) {
            if (firstDay + 1 == this.currentDay[2] && monthOn) {
              c39c = true;
            }
            if (firstDay == monthEnd) {
              firstDay = 0;
              on = "after";
            }
          }
          firstDay++;
        }

        arr1.push(a);
      }
      return arr1;
    },
    getMinCellClasses(time, day) {
      let styleClass = "";
      let timeStr = time[0] + "/" + time[1] + "/" + day.text;
      time = new Date(timeStr);
      let minDateStr = "";
      if (this.minDate) {
        minDateStr = getDay(this.minDate);
        minDateStr.splice(3, 1);
        minDateStr = minDateStr.join("/");
      }
      if (day.class == "action") {
        if (timeStr == minDateStr) {
          styleClass = "picked-start";
        }
      }
      return styleClass;
    },
    getMaxCellClasses(time, day) {
      let styleClass = "";
      let timeStr = time[0] + "/" + time[1] + "/" + day.text;
      time = new Date(timeStr);
      let maxDateStr = "";
      if (this.maxDate) {
        maxDateStr = getDay(this.maxDate);
        maxDateStr.splice(3, 1);
        maxDateStr = maxDateStr.join("/");
      }
      if (day.class == "action") {
        if (timeStr == maxDateStr) {
          styleClass = "picked-end";
        }
      }
      return styleClass;
    },

    DateFn() {
      let a = new Date(+this.minTime + 8 * 3600 * 1000)
        .toJSON()
        .substr(11, 8)
        .replace("T", " ");
      this.minDate = new Date(this.minYmd + " " + String(a));
      let b = new Date(+this.maxTime + 8 * 3600 * 1000)
        .toJSON()
        .substr(11, 8)
        .replace("T", " ");
      this.maxDate = new Date(this.maxYmd + " " + String(b));
    },
  },
};
</script>

<style lang="less" scoped>
.cross div {
  background: #f2f6fc;
}
.picked-start div {
  border-radius: 20px 0 0 20px; //background: #F2F6FC;
  span {
    background: #39c;
    color: #fff;
    border-radius: 20px;
  }
}
.picked div span {
  background: #39c;
  color: #fff;
  border-radius: 20px;
}
.picked-end div {
  border-radius: 0 20px 20px 0; //background: #F2F6FC;
  span {
    background: #39c;
    color: #fff;
    border-radius: 20px;
  }
}

.date-btn {
  border-top: 1px solid #e4e4e4;
  text-align: right;
  padding: 8px;
  .el-button {
    width: 60px;
    height: 26px;
    padding: 0px;
    border-radius: 2px;
    font-size: 12px;
  }
}

.el-date-table td span {
  top: 50%;
  transform: translate(-50%, -50%);
}

//-------------不同来源显示不同样式------------
//------会员套餐--
.date-style-xm {
  line-height: 38px;
  width: 100%;
  padding: 0 30px 0 0;
  border: 0px;
  .el-icon-date {
    color: #c0c4cc;
  }
  .nullDate {
    flex: 1;
    text-align: left;
  }
  .dateTime {
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .zhi {
    flex: 1;
    text-align: center;
    color: #c0c4cc;
  }
}
</style>

export default function (axios) {
  return {
    //获取登录用户信息
    getUserInfoReq(params) {
      return axios.get("/compMemberCtl/selectUserInfoByToken", { params });
    },
    //获取系统参数通用方法(单级)
    getDictNoseReq(params) {
      return axios.get("/parameter/getDictNose", { params });
    },
    //获取签名
    getUserSigReq(params) {
      return axios.get("/userSig/getUserSig", { params });
    },
  };
}

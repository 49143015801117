export default function (axios) {
  return {
    queryIndicator(params) {
      return axios.post("/performance/statistics/selectIndex", params)
    },
    queryTarget(params) {
      return axios.post("/performance/statistics/selectPerfTarget", params)
    },
    queryEvaluate(params) {
      return axios.post("/performance/statistics/selectEvaluate", params)
    },
    queryResult(params) {
      return axios.post("/performance/statistics/selectResult", params)
    },
  }
}
<template>
  <div class="form-wrap no-select">
    <el-drawer
      title="必填项弹窗"
      size="40%"
      :visible.sync="isMustFillSet"
      :append-to-body="true"
      direction="rtl"
      custom-class="mustFillSet asktrue-drawer"
      :before-close="closePop"
    >
      <div class="top">
        <h3>设置必填项</h3>
        <el-popover
          placement="bottom"
          width="300"
          v-model="other.isFreeButton"
          popper-class="freeButton-pop3 asktrue-popover"
        >
          <span slot="reference" class="cur"
            ><em class="iconfont iconjia f14 mr5"></em><span>新增</span></span
          >
          <div class="freeButton">
            <div class="const-btn-box">
              <el-checkbox-group
                v-model="other.freeFormDataChecked"
                @change="changeFreeFormFn"
              >
                <el-checkbox-button
                  v-for="(cfd, index) in other.freeFormData"
                  :label="cfd.infoTypeId"
                  :key="index"
                  @click.native="other.clickID = cfd.infoTypeId"
                  >{{ cfd.title1 }}</el-checkbox-button
                >
                <el-button
                  size="mini"
                  @click="other.isFreeBtnBox = !other.isFreeBtnBox"
                  >+自定义</el-button
                >
              </el-checkbox-group>
            </div>
            <el-divider v-if="other.isFreeBtnBox"></el-divider>
            <div class="free-btn-box" v-if="other.isFreeBtnBox">
              <el-button
                size="mini"
                @click="
                  other.isFreeButton = false;
                  $refs.freeFormData.getAddFn('textarea');
                "
                >文本</el-button
              >
              <el-button
                size="mini"
                @click="
                  other.isFreeButton = false;
                  $refs.freeFormData.getAddFn('select');
                "
                >下拉</el-button
              >
            </div>
          </div>
        </el-popover>
      </div>
      <div>
        <FreeForm
          ref="freeFormData"
          :formData="freeForm"
          :freeFormDel="freeFormDel"
          :examId="examId"
          :projectType="projectType"
          v-on:backFormData="BackFormDataFn"
          v-on:toBottom="toBottom"
        />
      </div>
      <div class="noData" v-if="freeForm.length == 0 && freeForm.length == 0">
        尚未设置必填项，请点击右上角“新增”进行创建
      </div>
      <div class="fn">
        <el-button type="primary" @click="saveMustfill">确定</el-button>
        <el-button @click="closePop">关闭</el-button>
        <div ref="FreeBottom">&nbsp;</div>
        <div class="tips f60 f12 mt20">
          提示：
          <p>1、必填项表单为独立保存，点击“确定”即时生效；</p>
          <p>2、已考试的项目，删除必填项或表单选项将影响数据的正常显示。</p>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import FreeForm from "./FreeForm"; //引入常量表单
import { newPostQuesInfoReq } from "@/api/asktrue/paper/paper"; //引入接口

export default {
  components: {
    FreeForm, //常量表单
  },
  props: {
    examId: Number,
    projectType: Number,
    isMustFillSet: Boolean, //必填项弹窗开关
    freeFormP: Array, //常量表单-父组件
  },
  data() {
    return {
      freeForm: [], //已选固定表单项
      freeFormDel: [], //要删除的表单项
      other: {
        isFreeButton: false,
        isFreeBtnBox: false, //自定义表单开关
        freeFormDataChecked: [], //已选择的固定表单项的infoTypeId组合数组
        clickID: 0,
        //固定表单项↓
        freeFormData: [
          {
            infoTypeId: 1,
            isPrev: true,
            type: "textarea",
            title: "您的姓名？",
            title1: "姓名",
            placeholder: "输入姓名",
            value: "",
            option: [],
            status: 1,
          },
          {
            infoTypeId: 2,
            isPrev: true,
            type: "select",
            title: "您的性别？",
            title1: "性别",
            placeholder: "选择性别",
            value: [{ answer: "" }],
            option: [
              { label: "男", status: 1, selectId: "1", id: 1 },
              { label: "女", status: 1, selectId: "2", id: 2 },
            ],
            status: 1,
          },
          // {
          //   infoTypeId: 3,
          //   isPrev: true,
          //   type: "textarea",
          //   title: "身份证",
          //   placeholder: "输入身份证号",
          //   value: "",
          //   option: [],
          //   status: 1,
          // },
          {
            infoTypeId: 4,
            isPrev: true,
            type: "textarea",
            title: "您的手机？",
            title1: "手机",
            placeholder: "输入手机",
            value: "",
            option: [],
            status: 1,
          },
          {
            infoTypeId: 5,
            isPrev: true,
            type: "textarea",
            title: "您的邮箱？",
            title1: "邮箱",
            placeholder: "输入邮箱",
            value: "",
            option: [],
            status: 1,
          },
          {
            infoTypeId: 6,
            isPrev: true,
            type: "select",
            title: "您的学历？",
            title1: "学历",
            placeholder: "选择学历",
            value: [{ answer: "" }],
            option: [
              { label: "初中及以下", status: 1, id: 1 },
              { label: "高中/中专/技校", status: 1, id: 2 },
              { label: "大学专科", status: 1, id: 3 },
              { label: "大学本科", status: 1, id: 4 },
              { label: "硕士研究生", status: 1, id: 5 },
              { label: "博士研究生", status: 1, id: 6 },
            ],
            status: 1,
          },
          {
            infoTypeId: 7,
            isPrev: true,
            type: "textarea",
            title: "您的专业？",
            title1: "专业",
            placeholder: "输入专业",
            // placeholder2: "选择专业",
            isSys: false,
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "经济学类", status: 1, id: 1 },
              { label: "统计学类", status: 1, id: 2 },
              { label: "经济学", status: 1, id: "1-1" },
              { label: "统计学", status: 1, id: "2-1" },
            ],
            status: 1,
            level: 2,
          },
          {
            infoTypeId: 8,
            isPrev: true,
            type: "textarea",
            title: "您的院校？",
            title1: "院校",
            placeholder: "输入院校",
            // placeholder2: "选择院校",
            isSys: false,
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "北京", status: 1, id: 1 },
              { label: "山东", status: 1, id: 2 },
              { label: "北京大学", status: 1, id: "1-1" },
              { label: "山东大学", status: 1, id: "2-1" },
            ],
            status: 1,
            level: 2,
          },
          {
            infoTypeId: 13,
            isPrev: true,
            type: "textarea",
            title: "您的年龄？",
            title1: "年龄",
            placeholder: "输入年龄",
            // value: [{ answer: "" }],
            option: [
              { label: "18岁以下", status: 1, id: 1 },
              { label: "18-25岁", status: 1, id: 2 },
              { label: "26-30岁", status: 1, id: 3 },
              { label: "31-40岁", status: 1, id: 4 },
              { label: "41-50岁", status: 1, id: 5 },
              { label: "51-60岁", status: 1, id: 6 },
              { label: "60岁以上", status: 1, id: 7 },
            ],
            status: 1,
          },
          {
            infoTypeId: 16,
            isPrev: true,
            type: "select",
            title: "您的职业？",
            title1: "职业",
            placeholder: "选择职业",
            value: [{ answer: "" }],
            option: [
              { label: "在校学生", status: 1, id: 1 },
              { label: "政府/机关干部/公务员", status: 1, id: 2 },
              { label: "企业管理者", status: 1, id: 3 },
              { label: "专业人员", status: 1, id: 4 },
              { label: "普通工人", status: 1, id: 5 },
              { label: "商业服务者", status: 1, id: 6 },
              { label: "个体经营者/承包商", status: 1, id: 7 },
              { label: "自由职业者", status: 1, id: 8 },
              { label: "农林牧渔劳动者", status: 1, id: 9 },
              { label: "退休", status: 1, id: 10 },
              { label: "其他", status: 1, id: 11 },
            ],
            status: 1,
          },
          {
            infoTypeId: 14,
            isPrev: true,
            type: "textarea",
            title: "所在行业？",
            title1: "行业",
            placeholder: "输入行业",
            placeholder2: "选择行业",
            isSys: false,
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "销售", status: 1, id: 1 },
              { label: "销售", status: 1, id: "1-1" },
            ],
            status: 1,
            level: 2,
          },
          {
            infoTypeId: 9,
            isPrev: true,
            type: "textarea",
            title: "工作地点？",
            title1: "地点",
            placeholder: "输入地点",
            placeholder2: "选择城市",
            isSys: false,
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "山东", status: 1, id: 1 },
              { label: "济南", status: 1, id: "1-1" },
            ],
            status: 1,
            level: 2,
          },
          {
            infoTypeId: 10,
            isPrev: true,
            type: "textarea",
            title: "目前单位？",
            title1: "单位",
            placeholder: "输入单位",
            value: [{ answer: "" }, { answer: "" }],
            option: [{ label: "北京阿仕图", status: 1, id: 1 }],
            status: 1,
          },
          {
            infoTypeId: 11,
            isPrev: true,
            type: "textarea",
            title: "所在部门？",
            title1: "部门",
            placeholder: "输入部门",
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "销售部", status: 1, id: 1 },
              { label: "技术部", status: 1, id: 2 },
            ],
            status: 1,
          },
          {
            infoTypeId: 12,
            isPrev: true,
            type: "textarea",
            title: "担任职务？",
            title1: "职务",
            placeholder: "输入职务",
            placeholder2: "选择职务",
            level: 2,
            isSys: false,
            value: [{ answer: "" }, { answer: "" }],
            option: [
              { label: "人力资源", status: 1, id: 1 },
              { label: "市场/产品", status: 1, id: 2 },
              { label: "招聘总监", status: 1, id: "1-1" },
            ],
            status: 1,
          },
          {
            infoTypeId: 15,
            isPrev: true,
            type: "textarea",
            title: "您的工龄？",
            title1: "工龄",
            placeholder: "输入工龄",
            value: [{ answer: "" }],
            option: [
              { label: "1年以下", status: 1, id: 1 },
              { label: "1-3年", status: 1, id: 2 },
              { label: "3-5年", status: 1, id: 3 },
              { label: "5-8年", status: 1, id: 4 },
              { label: "8-10年", status: 1, id: 5 },
              { label: "10年以上", status: 1, id: 6 },
            ],
            status: 1,
          },
        ],
      },
    };
  },
  created() {
    //this.getFormDataFn()
  },
  activated() {},
  //监听
  watch: {
    freeFormP() {
      this.getFormDataFn();
    },
  },
  methods: {
    //初始加载获取常量表单和自定义表单的默认数据
    getFormDataFn() {
      console.log(this.freeFormP);
      this.freeForm = JSON.parse(JSON.stringify(this.freeFormP));
      this.other.freeFormDataChecked = [];
      this.freeForm.map((item) => {
        if (item != null && item != "") {
          this.other.freeFormDataChecked.push(item.infoTypeId);
        }
      });
    },
    //关闭
    closePop() {
      this.$emit("closePop");
    },
    //自定义表单返回值
    BackFormDataFn(fd) {
      this.freeForm = fd;
    },

    //临时测试查看常量选项
    changeFreeFormFn() {
      let j = this.other.freeFormDataChecked.findIndex(
        (item) => item == this.other.clickID
      );
      if (j == -1) {
        //删除
        let k = this.freeForm.findIndex(
          (el) => el.infoTypeId == this.other.clickID
        );
        this.freeForm[k].status = 2;
      } else {
        //增加
        this.other.freeFormDataChecked.map((item) => {
          this.freeForm;
          let i = this.freeForm.findIndex((el) => el.infoTypeId == item);
          if (i == -1) {
            this.freeForm.push(
              this.other.freeFormData.find((e) => {
                return e.infoTypeId == item;
              })
            );
          } else {
            this.freeForm[i].status = 1;
          }
        });
      }

      this.other.isFreeButton = false;
      this.toBottom();
      console.log(this.freeForm, "???????");
    },
    //临时保存，待会删掉
    saveMustfill2() {
      console.log(this.freeForm, this.freeFormDel, "this.freeForm=");
    },
    //“确定”保存自定义项
    saveMustfill() {
      let params = {
        examId: this.examId,
        projectType: this.projectType,
        paperId: -1,
        paperTypeId: 0,
        examInfoList: [],
      };
      this.freeForm.map((item, index) => {
        if (!item.title) {
          item.status = 2;
        }
        let plist = [];
        if (item.option && item.option.length) {
          item.option
            .filter((ele) => ele.status == 1)
            .map((oitem) => {
              console.log(oitem, "oitem");
              plist.push({
                addText: 2,
                infoId: Number(oitem.infoId),
                optionText: oitem.label,
                projectType: this.projectType,
                status: oitem.status || 1,
                selectId: oitem.id,
              });
            });
        }

        if (item.isSys) {
          plist = [];
        } //启用系统数据

        params.examInfoList.push({
          examId: Number(item.examId), //	项目编码

          projectType: this.projectType, //	项目类型:1考试 2调查 3评估 4报名 5投票
          infoId: Number(item.infoId),
          infoText1: item.title, //标题---信息项题干1
          infoText2: item.placeholder, //提示语---信息项题干2
          xlLevelNeed: item.level > 1 ? 1 : 2,
          displayStyle: item.type == "textarea" ? 1 : 2, //	1：文本项 ；2：下拉项
          infoTypeId: item.infoTypeId, //
          list: plist, //下拉项选项集合
          required: 0, //	是否必填 1必填
          status: item.status || 1, //	状态 1:有效 2:无效（物理删除标识） 3:己引用失效（逻辑删除标识）
          sort: index + 1, //	显示顺序
          xlLevelSet:
            (item.level || 1) +
            "#zbw#" +
            item.placeholder +
            (item.placeholder2 ? "#zbw#" + item.placeholder2 : ""), //	下拉级别
          startSystemAddr: item.isSys ? 1 : 2,
        });
      });
      newPostQuesInfoReq(params).then((res) => {
        if (res.code == "200") {
          this.$message.success("必填项保存成功");
          this.closePop();
        }
      });
    },

    //跳转到最底部
    toBottom() {
      this.$refs.FreeBottom.scrollIntoView();
      //this.$refs.FreeBottom.scrollTop=100000
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .mustFillSet {
  padding: 30px 50px;
  .top {
    height: 40px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .fn {
    margin-top: 20px;
    text-align: left;
    .el-button {
      width: 80px;
      height: 30px;
      padding: 0px;
      border-radius: 2px;
    }
  }
  .noData {
    border: 1px solid #ececec;
    border-radius: 2px;
    padding: 20px;
    color: #999;
    text-align: left;
  }
}

.freeButton {
  margin: 10px 15px;
  /deep/ .const-btn-box {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      line-height: 0px;
      label {
        width: 22%;
        height: 26px;
        border-radius: 2px;
        padding: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
        &:not(:nth-child(4n)) {
          margin-right: 4%;
        }
        .el-checkbox-button__inner {
          padding: 0px;
          width: 100%;
          height: 100%;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          font-size: 12px;
          line-height: 24px;
          box-shadow: 0 0 !important;
        }
        &.is-checked .el-checkbox-button__inner {
          border: 1px solid #39c;
        }
      }
      .el-button {
        width: 22%;
        min-width: 5px;
        height: 26px;
        border-radius: 2px;
        padding: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .el-divider {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .free-btn-box {
    padding-bottom: 5px;
    .el-button {
      width: 22%;
      height: 26px;
      border-radius: 2px;
      padding: 0px;
    }
    .el-button + .el-button {
      margin-left: 3%;
    }
  }
}
</style>
<style>
.el-popover.freeButton-pop3 {
  left: auto !important;
  right: 50px;
  border-radius: 2px;
}
.el-popover.freeButton-pop3[x-placement^="bottom"] .popper__arrow {
  left: auto !important;
  right: 5px;
}
</style>

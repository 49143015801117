/*
 * @Description: 考试测评等项目相关的页面 主要是详情页
 * @Date: 2023-11-17 16:22:39
 * @LastEditTime: 2023-11-17 17:53:11
 */
export default {
  parent: "examExam",
  routers: [
    {
      //考试--问卷
      path: "/Project/Detail/Exam/Questionnaire",
      name: "ProKsQuestionnaire",
      component: () =>
        import("@/views/staff/exam/detail/questionnaireView.vue"),
    },
    {
      //考试--考生（考试）
      path: "/Project/Detail/Exam/Examinee",
      name: "ProKsExaminee",
      component: () => import("@/views/staff/exam/detail/examineeView.vue"),
    },
    {
      //考试--成绩
      path: "/Project/Detail/Exam/Score",
      name: "ProKsScore",
      component: () => import("@/views/staff/exam/detail/scoreView.vue"),
    },
    {
      //考试--数据
      path: "/Project/Detail/Exam/Data",
      name: "ProKsData",
      component: () => import("@/views/staff/exam/detail/dataView.vue"),
    },
    {
      //考试--统计
      path: "/Project/Detail/Exam/Statistics",
      name: "ProKsStatistics",
      component: () => import("@/views/staff/exam/detail/statisticsView.vue"),
    },
    {
      //考试--通知
      path: "/Project/Detail/Exam/Notice",
      name: "ProKsNotice",
      component: () => import("@/views/staff/exam/detail/noticeView.vue"),
    },
  ],
};

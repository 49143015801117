// //安排面试路由
export default {
  routers: [
    // 登录页
    {
      path: "/msLogin",
      name: "MsLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/login"], resolve),
      meta: { pageType: "ms" },
    },
    //首页
    {
      path: "/msIndex",
      name: "MsIndex",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/index"], resolve),
      meta: { pageType: "ms", auth: true },
    },
    //详情页
    {
      path: "/msDetail",
      name: "MsDetail",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/detail"], resolve),
      meta: { pageType: "ms", auth: true },
    },
    // 企业账号- 面试 - 视频面试评委界面
    {
      path: "/msJudge",
      name: "msJudge",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/judge"], resolve),
      meta: { pageType: "ms" },
    },
    {
      //企业账号-面试-视频面试登录界面——评委登录
      path: "/judgeLogin",
      name: "judgeLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/judgeLogin"], resolve),
      meta: { pageType: "ms", auth: true },
    },
    {
      //企业账号-面试-视频面试评委界面
      path: "/msJudge/record/:vipId/:planId/:memberId",
      name: "msRecord",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/record"], resolve),
      meta: { pageType: "ms" },
    },
    {
      //企业账号-面试-视频面试界面-评委版-整合版（含现场面试、视频面试、人机交互）
      path: "/judgeVideo",
      name: "judgeVideo",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/judgeVideo"], resolve),
      meta: { pageType: "ms" },
    },
    {
      //企业账号-面试-视频面试登录界面——考生登录
      path: "/stuLogin",
      name: "stuLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/stuLogin"], resolve),
      meta: { pageType: "ms" },
    },

    {
      //企业账号-面试-视频面试界面-考生版
      path: "/StuVideo",
      name: "StuVideo",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/stuVideo"], resolve),
      meta: { pageType: "ms" },
    },

    {
      //企业账号-面试-视频面试
      path: "/ms/room/:vipId/:planId/:roomId",
      name: "IntervieWRoom",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/interviewRoom"], resolve),
      meta: {
        pageType: "ms",
        roomAuth: true,
        noKeepAlive: true,
      },
    },

    {
      //企业账号-面试-视频面试登录界面——登录
      path: "/ms/room/:vipId/:planId/:memberId/login",
      name: "IntervieWRoomLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/ms/interviewRoomLogin"], resolve),
      meta: {
        pageType: "ms",
        noKeepAlive: true,
      },
    },
  ],
};
// const arrangeMs = [
//   {//登录页
//     path: "/msLogin",
//     name: "MsLogin",
//     component: resolve => require(['"@/views/recruit/arrange/ms/login'], resolve),
//     meta: {pageType: 'ms'}
//   },
//   {//首页
//     path: "/msIndex",
//     name: "MsIndex",
//     component: resolve => require(['"@/views/recruit/arrange/ms/index'], resolve),
//     meta: {pageType: 'ms',auth:true}
//   },
//   {//详情页
//     path: "/msDetail",
//     name: "MsDetail",
//     component: resolve => require(['"@/views/recruit/arrange/ms/detail'], resolve),
//     meta: {pageType: 'ms',auth:true}
//   },
//   { //企业账号-面试-视频面试评委界面
//     path: "/msJudge",
//     name: "msJudge",
//     component: resolve => require(['"@/views/recruit/arrange/ms/judge'], resolve),
//     meta: {pageType: 'ms'}
//   },
//   { //企业账号-面试-视频面试评委界面
//     path: "/msJudge/record/:vipId/:planId/:memberId",
//     name: "msRecord",
//     component: resolve => require(['"@/views/recruit/arrange/ms/record'], resolve),
//     meta: {pageType: 'ms'}
//   },
//   { //企业账号-面试-视频面试登录界面——评委登录
//     path: "/judgeLogin",
//     name: "judgeLogin",
//     component: resolve => require(['"@/views/recruit/arrange/ms/judgeLogin'], resolve),
//     meta: {pageType: 'ms',auth:true}
//   },
//   { //企业账号-面试-视频面试界面-评委版-整合版（含现场面试、视频面试、人机交互）
//     path: "/judgeVideo",
//     name: "judgeVideo",
//     component: resolve => require(['"@/views/recruit/arrange/ms/judgeVideo'], resolve),
//     meta: {pageType: 'ms'}
//   },
//   { //企业账号-面试-视频面试登录界面——考生登录
//     path: "/stuLogin",
//     name: "stuLogin",
//     component: resolve => require(['"@/views/recruit/arrange/ms/stuLogin'], resolve),
//     meta: {pageType: 'ms'}
//   },

//   { //企业账号-面试-视频面试界面-考生版
//     path: "/StuVideo",
//     name: "StuVideo",
//     component: resolve => require(['"@/views/recruit/arrange/ms/stuVideo'], resolve),
//     meta: {pageType: 'ms'}
//   },

//   { //企业账号-面试-视频面试
//     path: "/ms/room/:vipId/:planId/:roomId",
//     name: "IntervieWRoom",
//     component: resolve => require(['"@/views/recruit/arrange/ms/interviewRoom'], resolve),
//     meta: {
//       pageType: 'ms',
//       roomAuth: true,
//       noKeepAlive: true,
//     }
//   },

//   { //企业账号-面试-视频面试登录界面——登录
//     path: "/ms/room/:vipId/:planId/:memberId/login",
//     name: "IntervieWRoomLogin",
//     component: resolve => require(['"@/views/recruit/arrange/ms/interviewRoomLogin'], resolve),
//     meta: {
//       pageType: 'ms',
//       noKeepAlive: true
//     }
//   },
// ]
// export default arrangeMs

export default function (axios) {
  return {
    // //首页各种展示的数量查询
    // export const getIndexNumReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectIndexNum", data);
    // //首页下面的企业列表展示
    // export const getIndexCompListReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectIndexCompanyList", data);
    // //首页下面的企业性质展示
    // export const getIndexCompFieldReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectIndexCompanyFieldList", data);
    // //首页需要的职类，已按照职位数量降序排列
    // export const getIndexJobKindReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectJobKind", data);
    // //职位列表分页查询
    // export function getPorListReq(typeUrl, data) {
    //   return postReq(apiUrl + typeUrl, data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // }
    // //职位详情查询
    // export const getPortDetailReq = (jobId) =>
    //   getReq(apiUrl + "/anon/portal/select/" + jobId);
    getPortDetailReq(jobId) {
      return axios.get("/anon/portal/select/" + jobId,);
    },

    // //个人收藏/取消收藏职位
    // export function updateUserCollJobReq(type, data) {
    //   let url =
    //     type == 1 ? "/matching/addUserCollJob" : "/matching/updateUserCollJob";
    //   return getReq(apiUrl + url, data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // }
    // //推荐职位
    // export const addResumeBonusJobReq = (data) =>
    //   postReq(apiUrl + "/recommendedResumeCtl/addResumeBonusJob", data, {
    //     ContentType: "application/json",
    //   });
    // //个人关注(收藏)企业
    // export const addUserCollCompReq = (data) =>
    //   getReq(apiUrl + "/matching/addUserCollComp", data);
    // //个人取消关注(收藏)企业
    // export const updateUserCollCompReq = (data) =>
    //   getReq(apiUrl + "/matching/updateUserCollComp", data);
    // //查询个人关注的企业
    // export const selectAttentionCompReq = (data) =>
    //   getReq(apiUrl + "/matching/selectAttentionComp", data);
    // //企业详情查询
    // export const getCompDetailReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectCompanyByCompId", data);
    // //企业分页查询
    // export const getCompListReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectCompanyPage", data);
    // //查询职位热门关键字，返回值keyword以按照出现次数从多到少排序
    // export const getSearchHotKeyReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectHotKeyWords", data);
    // //企业官网职位筛选条件下拉选项
    // export const getSelectWebsiteParamByCompanyIdReq = (data) =>
    //   getReq(apiUrl + "/anon/portal/selectWebsiteParamByCompanyId", data);
    // //查询猎头公司员工
    // export const getSelectHeaderStaffMemberPageNoseReq = (data) =>
    //   getReq(apiUrl + "/compStffMember/selectHeaderStaffMemberPageNose", data);
    // //查询猎头公司员工详情
    // export const postSelectHeaderStaffNoseReq = (data) =>
    //   postReq(apiUrl + "/recruiter/headhunter/selectHeaderStaffNose", data);
    // //查询猎头员工职位
    // export const postGetHeaderJobsReq = (data) =>
    //   postReq(apiUrl + "/recruiter/job/getHeaderJobsNose", data, {
    //     ContentType: "application/json",
    //   });
    // //委托简历
    // export const addEntrustResumeReq = (data) =>
    //   postReq(apiUrl + "/entrustCtl/addEntrustResume", data, {
    //     ContentType: "application/json",
    //   });
    // //关注猎头
    // export const attentionHeadhunterReq = (data) =>
    //   postReq(apiUrl + "/pHeadhunterCtl/attention", data);
    // export const getSelectJobKindCountNoseReq = (data) =>
    //   postReq(apiUrl + "/anon/portal/selectJobKindCountNose", data); //查询职类数量
    // 查询猎头成员列表
    getSelectStaffMemberPageReq(params) {
      return axios.get("/compStffMember/selectStaffMemberPage", { params });
    },
  };
}

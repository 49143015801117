// 培训班接口
export default function (axios) {
  return {
    // 培训班-增/改
    pxb_addApi(params) {
      return axios.post("/cultivate/trainingClass/editTrainingClass", params)
    },
    // 培训班-删除
    pxb_delApi(params) {
      return axios.get("/cultivate/trainingClass/deleteTrainClass", { params })
    },
    // 培训班-查列表
    pxb_ListApi(params) {
      return axios.post("/cultivate/trainingClass/selectPage", params);
    },
    // 培训班-查详情
    pxb_detailApi(params) {
      return axios.get("/cultivate/trainingClass/selectById", { params });
    },
    // 培训班-上下线
    pxb_onlineApi(params) {
      return axios.post("/cultivate/trainingClass/updateOnline", {}, { params });
    },
    // 培训班-详情栏目名称数据统计
    pxb_selectClassSummaryApi(params) {
      return axios.get("/cultivate/trainingClass/selectClassSummary", { params });
    },




    // *****************************************************
    // 培训班-课程
    // *****************************************************
    // 课程-新增
    pxbkc_addApi(params) {
      return axios.post("/cultivate/classCourse/addClassCourse", params)
    },
    // 课程-删除
    pxbkc_delApi(params) {
      return axios.get("/cultivate/classCourse/deleteClassCourse", { params })
    },
    // 课程-列表
    pxbkc_listApi(params) {
      return axios.post("/cultivate/classCourse/selectPage", params)
    },
    // 课程-设置必修、选修
    pxbkc_updateTypeApi(params) {
      return axios.post("/cultivate/classCourse/updateCourseType", {}, { params })
    },
    // 课程-列表-筛选条件“讲师”列表
    pxbkc_lecturerListApi(params) {
      return axios.get("/cultivate/classCourse/selectLecturer", { params })
    },


    // *****************************************************
    // 培训班-学员
    // *****************************************************
    // 学员-增/改
    pxbxy_addApi(params) {
      let add = "/cultivate/classTrainee/addClassTrainee"
      let edit = "/cultivate/classTrainee/updateClassTrainee"
      url = params?.traineeId ? edit : add
      return axios.post(url, params)
    },
    // 学员-删
    pxbxy_delApi(params) {
      return axios.get("/cultivate/classTrainee/deleteTrainee", { params })
    },
    // 学员-查-列表
    pxbxy_listApi(params) {
      return axios.post("/cultivate/classTrainee/selectPage", params)
    },

    // *****************************************************
    // 培训班-讲师
    // *****************************************************
    // 讲师-增/改
    pxbjs_addApi(params) {
      let add = "/cultivate/classLecture/addLecturer"
      let edit = "/cultivate/classLecture/update"
      url = params?.traineeId ? edit : add
      return axios.post(url, params)
    },
    // 讲师-删
    pxbjs_delApi(params) {
      return axios.get("/cultivate/classLecture/deleteClassLecturer", params)
    },
    // 讲师-查-列表
    pxbjs_listApi(params) {
      return axios.post("/cultivate/classLecture/selectPage", params)
    },
    // 讲师-查-详情
    pxbjs_detailApi(params) {
      return axios.get("/cultivate/classLecture/selectById", { params })
    },

    // *****************************************************
    // 评论
    // *****************************************************
    // 评论-新增/修改
    pxbpl_editApi(params) {
      return axios.post("/cultivate/classComment/editClassComment", params);
    },
    // 评论-列表
    pxbpl_listApi(params) {
      return axios.post("/cultivate/classComment/selectPage", params);
    },
    // 评论-回复
    pxbpl_replyApi(params) {
      return axios.post("/cultivate/classComment/editCommentReply", params);
    },
    // 评论标签-新增/修改
    pxbplbq_editApi(params) {
      return axios.post("/classTags/editTags", params);
    },
    // 评论标签-列表
    pxbplbq_listApi(params) {
      return axios.post("/classTags/selectTagsList", params);
    },
    // 评论标签-删除
    pxbplbq_delApi(params) {
      return axios.get("/classTags/deleteTags", { params });
    },

    // *****************************************************
    // 测验
    // *****************************************************
    // 测验-新增/修改
    pxbcy_editApi(params) {
      return axios.post("/cultivate/classComment/addExamForClass", params);
    },
    // 测验-列表
    pxbcy_listApi(params) {
      return axios.get("/cultivate/classComment/selectExamPage", { params });
    },
    // 测验-删除
    pxbcy_delApi(params) {
      return axios.post("/cultivate/classComment/deleteExamForClass", {}, { params });
    },
    // 测验-查询允许添加的测验列表
    pxbcy_examApi(params) {
      return axios.get("/cultivate/classComment/selectExamForClass", { params });
    },

    // *****************************************************
    // 培训班类型
    // *****************************************************
    // 类型-增
    pxblx_addApi(params) {
      return axios.post("/classTypeCtl/addType", params);
    },
    // 类型-改/删
    pxblx_updateApi(params) {
      return axios.get("/classTypeCtl/updateType", { params });
    },
    // 类型-查
    pxblx_listApi(params) {
      return axios.get("/classTypeCtl/selectTypeByCompId", { params });
    },

  };
}

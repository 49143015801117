export default function (axios) {
  return {
    // 获取考试地点列表
    getPlaceListReq(data) {
      return axios.get("/examPlace/selectPage", { params: data });
    },
    // 获取考场城市列表
    getPlaceCityListReq(data) {
      return axios.post("/examPlace/selectCity", data);
    },
    // 编辑考试地点
    // changePlaceReq(data) {
    //   return axios.post("/examPlace/editExamPlace", data);
    // },
    // 编辑考点考场信息
    changePlaceDotReq(data) {
      return axios.post("/examPlace/editAddress ", data);
    },
    // 删除考试地点
    // delPlaceReq(data) {
    //   return axios.delete("/examPlace/delete", data);
    // },
    // 获取考试地点列表
    // getInterviewPlaceListReq(params, data) {
    //   return axios.post(
    //     "/interviewPlace/selectPage?pageNum=" +
    //     params.pageNum +
    //     "&pageSize=" +
    //     params.pageSize,
    //     data
    //   );
    // },
    // // 编辑考试地点
    // changeInterviewPlaceReq(data) {
    //   return axios.post("/interviewPlace/editInterviewPlace", data);
    // },
    // 编辑考点考场信息
    changeInterviewPlaceDotReq(data) {
      return axios.post("/interviewPlace/editAddress", data);
    },
    // 删除考试地点
    // delInterviewPlaceReq(data) {
    //   return axios.delete("/interviewPlace/delete", data);
    // },
    //删除时间、地点的时候判断是否有人员
    // selIfExistMemberReq(type, data) {
    //   let url =
    //     type == "examTimes" || type == "timeSetArr"
    //       ? "/examPlace/selectIfExistMemberByTime"
    //       : "/examPlace/selectIfExistMember";
    //   return axios.post(url, data, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   });
    // },
  };
}

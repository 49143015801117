export default function (axios) {
  return {
    //通知

    //新增或编辑通知
    postNoticeUpdateReq(data) {
      return axios.post(
        "/inform/updateInform?isChange=" +
          data.isChange +
          "&planId=" +
          data.planId +
          "&type=" +
          data.type +
          "&projectUrl=" +
          data.projectUrl,
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //查询通知--列表
    getNoticeListReq(params) {
      return axios.get("/inform/selectPage", { params });
    },
    //查询通知--详情
    getNoticeInfoReq(params) {
      return axios.get("/inform/selectInfrom", { params });
    },
    //查询通知状态信息
    getNoticeStatusInfoReq(data) {
      return axios.post("/inform/selectPageByInformId", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    //删除通知
    delNoticeListReq(params) {
      return axios.delete("/inform/deleteInform", { params });
    },
    //评估类项目同步数据
    postPgSyncAppraise(data) {
      return axios.post(
        "/inform/syncAppraise?projectType=" +
          data.projectType +
          "&examId=" +
          data.examId +
          "&informId=" +
          data.informId +
          "",
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //非评估类项目同步数据
    postSyncAppraise(data) {
      return axios.post(
        "/project/student/tongBuKaoSheng?projectType=" +
          data.projectType +
          "&examId=" +
          data.examId +
          "&informId=" +
          data.informId +
          "",
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //查询人员--列表
    getNoticeMemberListReq(params) {
      return axios.get("/inform/member/selectPage", { params });
    },
    // //查询人员--详情
    // getNoticeMemberReq(planId, data) {
    //   let url = planId
    //     ? "/examMember/selectArrangeNoticeMember"
    //     : "/inform/member/selectInfromMmeber";
    //   if (planId) {
    //     return axios.post(apiUrl6 + url, data);
    //   } else {
    //     return axios.get(apiUrl9 + url, data);
    //   }
    // },
    getNoticeMemberReq(params) {
      return axios.get("/inform/member/selectInfromMmeber", { params });
    },
    // // 人员管理--新增、编辑
    // postNoticeMemberUpdateReq(planId, data) {
    //   let url = planId
    //     ? apiUrl6 + "/examMember/editArrangeNotice"
    //     : apiUrl9 + "/inform/member/updateInformMember";
    //   return axios.post(url, data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // },
    postNoticeMemberUpdateTZReq(data) {
      return axios.post("/inform/member/updateInformMember", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //删除人员
    DelNoticeMemberReq(params) {
      return axios.delete("/inform/member/deleteInformMember", { params });
    },
    //发通知
    postNoticeMemberSendMsgReq(data) {
      return axios.post(
        "/inform/sendMsg?informId=" +
          data.informId +
          "&needConfirm=" +
          data.needConfirm +
          "&sendType=" +
          data.sendType +
          "&noticeTitle=" +
          data.noticeTitle +
          "&informContent=" +
          data.informContent +
          "&isArrange=" +
          data.isArrange,
        data.memberList,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //人员通知记录查询--列表
    getNoticeMemberListNoticeInfoReq(params) {
      return axios.get("/inform/member/selectMemberNoticeInfo", { params });
    },
    //人员确认记录查询--列表
    getNoticeMemberListConfirmInfoReq(params) {
      return axios.get("/inform/member/selectMemberConfirmInfo", { params });
    },

    //通知-表单部分
    //通知表单项目查询
    getNoticeFFDetailReq(params) {
      return axios.get("/inform/form/item/selectFormItem", { params });
    },
    //通知表单维护=新增或编辑通知表单-保存
    getNoticeFFSaveReq(data) {
      return axios.post("/inform/form/updateFormDef", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //选择模板列表/单个模板详情
    getFormTempReq(data) {
      return axios.get("/inform/form/selectFormDefTemplate", { params: data });
    },
    //新增/编辑模板
    postFormTempReq(data) {
      return axios.post("/inform/form/updateFormDefTemplate", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //删除模板
    delFormTempReq(params) {
      return axios.delete("/inform/form/deleteFormDefTemplate", { params });
    },

    //项目-通知-订单确认页面(无需token)
    //查询模板表单--订单确认页面
    getTempFormReq(params) {
      return axios.get("/inform/form/item/selectFormItemNose", { params });
    },
    //查询模板表单值
    getTempFormValReq(params) {
      return axios.get("/inform/member/selectInfromMmeberNose", { params });
    },
    //保存模板表单值
    postTempFormValReq(data) {
      return axios.post("/inform/member/updateInformMemberNose", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //判断是否有确认页面
    selectIfExistReq(data) {
      return axios.post("/inform/selectIfExist", data);
    },

    // 通知模板
    // /inform/form/updateFormDefTemplate 表单模板维护
    updateFormDefTemplate(data) {
      return axios.post(
        "/inform/form/updateFormDefTemplate", // url
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },

    // /inform/form/selectFormDefTemplate   企业表单模板查询
    getFormDefTemplate(params) {
      return axios.get(
        "/inform/form/selectFormDefTemplate", // url
        { params }
      );
    },

    // /inform/form/deleteFormDefTemplate  表单模板删除
    deleteFormDefTemplate(params) {
      return axios.delete(
        "/inform/form/deleteFormDefTemplate", // url
        { params }
      );
    },
    // 查询通知的统计
    getSelectNoticeSummary(params) {
      return axios.get(
        "/inform/selectNoticeSummary", // url
        { params }
      );
    },
  };
}

//安排体检路由
export default {
  routers: [
    {
      //登录页
      path: "/tjLogin",
      name: "TjLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tj/login"], resolve),
      meta: { pageType: "tj" },
    },
    {
      //首页
      path: "/tjIndex",
      name: "TjIndex",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tj/index"], resolve),
      meta: { pageType: "tj", auth: true },
    },
    {
      //详情页
      path: "/tjDetail",
      name: "TjDetail",
      component: (resolve) =>
        require(["@/views/recruit/arrange/tj/detail"], resolve),
      meta: { pageType: "tj", auth: true },
    },
  ],
};

export default function (axios) {
  return {
    //简历进程分页查询
    getProgessResumeListReq(data) {
      return axios.post("/resumeProcessCtl/selectPage", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //简历录用分页查询
    getEmployResumeListReq(data) {
      return axios.post("/resumeProcessCtl/selectResumeEmployList", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //简历初筛分页查询
    getFiltraResumeListReq(data) {
      return axios.post("/newPrimaryScreeningCtl/selectPage", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //类型详情中的简历阶段数值统计
    selectStageCountByTypeReq(params) {
      return axios.get("/newResume/selectStageCountByType", { params });
    },

    //	阶段筛选数据查询
    selectCompStagesReq(params) {
      return axios.get("/approval/selectCompStages", { params });
    },

    //流程阶段回显
    selectProcStageListReq(params) {
      return axios.get("/approval/selectProcStageList", { params });
    },

    //简历操作历史记录查询
    selectResumeRecordReq(params) {
      return axios.get("/newResume/selectResumeRecord", { params });
    },

    //简历详情-简历下审批和面试中的简历详情查询
    getApproResumeDetailReq(params) {
      return axios.get("/newResume/selectResumeDetail", { params });
    },

    //接受推荐职位
    acceptPushJobReq(params) {
      return axios.get("/approval/acceptPushJob", { params });
    },

    //通过评价id查询详细的评价维度
    getInterviewScoreReq(data) {
      return axios.post("/interviewPlan/selectScoreByScoreId", data);
    },

    //进行面试评价
    updateInterviewInfoReq(data, params) {
      let url = "/interviewPlan/insertResumeInterview";
      if (params) {
        url = url + "?";
        for (let key in params) {
          url += key + "=" + params[key] + "&";
        }
      }
      return axios.post(url, data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //人才库简历id获取(根据人才id)
    getIdByPIdReq(data) {
      return axios.post("/resumepool/getIdByPId", data);
    },

    //人才库删除单个简历(猎头系统)
    deleteResumeLibReq(data) {
      return axios.post("/resumepool/deleteResumeLib", data);
    },

    //简历库查看限制
    checkResumeCountReq(data) {
      return axios.post("/resumepool/checkResumeCount", data);
    },

    //企业官网简历投递前校验简历自定义字段
    getTemplateIdByJobIdReq(params) {
      return axios.get("/newResume/getTemplateIdByJobId", { params });
    },

    //类型详情中的简历阶段数值统计
    selectStageCountReq(data) {
      return axios.post("/resumeProcessCtl/selectStageCount", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //简历模板详情
    getResumeTemplateReq(params) {
      return axios.get("/resumeTemplateCtl/getResumeTemplate", { params });
    },

    //企业官网简历自定义字段及数据
    getResumeFieldContentReq(params) {
      return axios.get("/newResume/getResumeFieldContent", { params });
    },

    //查询标签
    selectLabelReq(params) {
      return axios.get("/operate/selectLabel", { params });
    },

    //简历标签新增/修改
    updateLabelReq(params) {
      return axios.get("/operate/updateLabel", { params });
    },

    //简历设置标签
    updateResumeLabelReq(params) {
      return axios.get("/newResume/updateResumeLabel", { params });
    },

    //简历点评
    addResumeCommentReq(params) {
      return axios.get("/newResume/addResumeComment", { params });
    },

    //简历库简历点评
    addResumeCommentPoolReq(params) {
      return axios.get("/newResume/addResumeCommentPool", { params });
    },

    //更改录用简历状态(是否发送offer,是否入职)
    updateEmployResumeReq(data) {
      return axios.post("/newResume/updateEmployResume", data);
    },

    //获取offer模版
    getOfferTemplateListReq(data) {
      return axios.post("/newResume/getOfferTemplateList", data);
    },

    //维护offer模版(新增,修改,删除)
    updateOfferTemplateReq(data) {
      return axios.post("/newResume/updateOfferTemplate", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //获取录用的报道地点
    selectAddrListReq(data) {
      return axios.post("/newResume/selectList", data);
    },

    //新增录用的报道地点
    insertAddressReq(data) {
      return axios.post("/newResume/insertAddress", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //修改录用的报道地点
    editAddressReq(data) {
      return axios.post("/newResume/editAddress", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //删除录用的报道地点
    delAddressReq(data) {
      return axios.post("/newResume/delAddress/", data);
    },

    //阶段审批
    updateProcStageApprovalReq(data) {
      return axios.post("/newResume/updateProcStageApproval", data);
    },

    //阶段筛选数据查询
    selectResumeStageDataReq(params) {
      return axios.get("/approval/selectResumeStageData", { params });
    },
    // 简历模板查询
    selectResumeTemplatePageReq(params) {
      return axios.get("/resumeTemplateCtl/selectResumeTemplatePage", {
        params,
      });
    },
  };
}

// 公积金相关接口
export default function (axios) {
  return {
    // 公积金核算
    calcPf(ids, time, sign) {
      // let url = "/gjjRecordCtl/calculateGjj";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, { userIds: ids, calTime: time });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjRecordCtl/calculateGjj`,
        { userIds: ids, calTime: time }
      );
    },
    // 获取公积金档案列表
    getPfArchives(data, sign) {
      // let url = "/gjjArchivesCtl/getGjjArchivesPage";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, data);
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/getGjjArchivesPage`,
        data
      );
    },
    // 获取公积金档案详情
    pfArchivesInfo(employId, sign) {
      // let url = "/gjjArchivesCtl/getGjjArchives";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { id: employId } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/getGjjArchives`,
        {},
        { params: { id: employId } }
      );
    },
    // 员工查看自己的档案
    getPersonalPfArchives(data, sign) {
      // let url = "/gjjArchivesCtl/getGjjArchivesByUser";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, data);
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/getGjjArchivesByUser`,
        data
      );
    },
    // 新增或修改公积金档案
    addPfArchives(params) {
      let url = params.sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.id) {
        url += "/gjjArchivesCtl/updateGjjArchives";
      } else {
        url += "/gjjArchivesCtl/insertGjjArchives";
      }
      return axios.post(url, params);
    },
    // 公积金减员
    reducePfRecord(endTime, headerId, sign) {
      // let url = "/gjjArchivesCtl/setGjjArchiveEndTime";
      // if (sign === "hro") url = axios.urlHro + url;
      // endTime += "-01";
      // return axios.post(url, {}, { params: { endTime, headerId } });
      endTime += "-01";
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/setGjjArchiveEndTime`,
        {},
        { params: { endTime, headerId } }
      );
    },

    // 删除外面的公积金档案
    delectPfRecordOut(headerId, sign) {
      // let url = "/gjjArchivesCtl/delGjjArchivesOut";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { headerId } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/delGjjArchivesOut`,
        {},
        { params: { headerId } }
      );
    },
    // 删除查看页面的公积金档案
    delectPfRecordInner(headerId, id, sign) {
      // let url = "/gjjArchivesCtl/delGjjArchivesInner";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { headerId, id } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/delGjjArchivesInner`,
        {},
        { params: { headerId, id } }
      );
    },

    //获取公积金记录列表
    getPfRecord(params) {
      // let url = "/gjjRecordCtl/getGjjRecordPage";
      // if (params.sign === "hro") url = axios.urlHro + url;
      // url = "/" + (params.sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      // return axios.post(url, params);
      return axios.post(
        `/${
          params.sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjRecordCtl/getGjjRecordPage`,
        params
      );
    },
    // 获取公积金记录统计
    PfRecordCount(params, sign) {
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjRecordCtl/selectGjjRecordCount`,
        { params }
      );
    },
    // 保存公积金记录
    savePfRecord(info, sign) {
      // let url = "/gjjRecordCtl/insertGjjRecord";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, info);
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjRecordCtl/insertGjjRecord`,
        info
      );
    },
    // 判断公积金档案是否存在记录
    hasPfRecord(archiveIds, flag, sign) {
      // let url = "/gjjArchivesCtl/getRecordId";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { archiveIds, flag } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/getRecordId`,
        {},
        { params: { archiveIds, flag } }
      );
    },
    // 清空公积金档案记录
    clearPfRecord(archiveIds, flag, sign) {
      // let url = "/gjjArchivesCtl/delRecord";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { archiveIds, flag } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjArchivesCtl/delRecord`,
        {},
        { params: { archiveIds, flag } }
      );
    },
    // 导出公积金记录
    exportRecord(sign) {
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjRecordCtl/exportGjjRecord`
      );
    },

    //获取公积金设置
    getPfSetList(sign) {
      // let url = "/gjjSetCtl/getGjjSetPage";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, { pageNum: 1, pageSize: 999999 });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjSetCtl/getGjjSetPage`,
        { pageNum: 1, pageSize: 999999 }
      );
    },
    //新增/编辑公积金设置
    addPfSet(params) {
      let url = params.sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.id) {
        url += "/gjjSetCtl/updateGjjSet";
      } else {
        url += "/gjjSetCtl/insertGjjSet";
      }
      return axios.post(url, params);
    },
    // 判断公积金设置是否有对应的档案
    hasPfArchives(setIds, sign) {
      // let url = "/gjjSetCtl/getArchiveId";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { setIds } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjSetCtl/getArchiveId`,
        {},
        { params: { setIds } }
      );
    },
    // 删除公积金档案和记录
    clearPfArchives(setIds, sign) {
      // let url = "/gjjSetCtl/updateStatusBySetId";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { setIds } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/gjjSetCtl/updateStatusBySetId`,
        {},
        { params: { setIds } }
      );
    },
    //删除公积金设置
    deletePfSet(ids, sign) {
      // let url = "/gjjSetCtl/delGjjSet";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { ids } });
      return axios.post(
        `/${sign === "hro" ? axios.urlHro : axios.urlHrm}/gjjSetCtl/delGjjSet`,
        {},
        { params: { ids } }
      );
    },
  };
}

export default {
  parent: "Notice",
  routers: [
    // 公告
    {
      path: "notice",
      name: "NoticePage",
      component: () => import("@/views/oa/notice/NoticeList.vue"),
    },
    // 我发布的
    {
      path: "my",
      name: "NoticeMy",
      component: () => import("@/views/oa/notice/NoticeList.vue"),
    },
    // 草稿箱
    {
      path: "draft",
      name: "NoticeDraft",
      component: () => import("@/views/oa/notice/NoticeList.vue"),
    },
    // 设置
    {
      path: "set",
      name: "NoticeSet",
      component: () => import("@/views/oa/notice/NoticeSet.vue"),
    },
  ],
};

export default function (axios) {
  return {
    // //用户注册
    // export const postRegReq = data => postReq(apiUrl+"/anon/register",data)
    // //获取验证码
    // export const getVerifCodeReq = data => getReq(apiUrl+"/anon/verifyCode",data)
    // //用户登录
    // export const postLoginReq = data => postReq(apiUrl+"/anon/generateToken",data)
    // //退出登录
    // export const getOutLoginReq = data => getReq(apiUrl+"/invalidToken",data)
    // //忘记密码
    // export const resetPsdReq = data => postReq(apiUrl+"/anon/resetPassword",data)
    // //获取切换用户列表
    // export const getUserListReq = data => getReq(apiUrl+"/changeUserList",data)
    // //切换用户
    // export const changeUserReq = data => getReq(apiUrl+"/anon/changeUser",data)
    // //获取切换后用户的token
    // export const getChangedUserReq = data => getReq(apiUrl+"/anon/changeUser",data)
    // //登录后修改密码（企业）
    // export const postPsdComReq = data => postReq(apiUrl+"/compMemberCtl/updatePassWord",data)//登录后修改密码（企业）
    // //登录后修改密码（员工）
    // export const postPsdChildReq = data => postReq(apiUrl+"/compStffMember/updatePassWord",data)
    // //登录后修改密码（个人）
    // export const postPsdPerReq = data => postReq(apiUrl+"/userMember/updatePassWord",data)
    // //三方登录
    // export function getLoginToken(type,data) {
    //   let url=type=='qq'?'/anon/qqLogin':type=='wx'?'/anon/weChatLogin':type=='alipay'?'/anon/alipayLogin':'/anon/sinaWeiboLogin'
    //   return postReq(apiUrl+url ,data)
    // }
  };
}

<template>
  <div class="edit-warpper console-body">
    <span class="back" @click="toDetailFn" v-if="from != 'pop'">
      <em class="el-icon-back"></em>
      <span>返回</span>
    </span>
    <!--中间正文-->
    <div class="main no-scroll">
      <!--白色区域-->
      <div :class="['body no-scroll', { mobile: !pc }]">
        <div class="change-top-tab">
          <div class="tab left">
            <div :class="['item', { active: pc }]" @click="pc = true">PC</div>
            <div :class="['item', { active: !pc }]" @click="pc = false">
              手机
            </div>
          </div>

          <div class="tab right">
            <div
              class="item"
              @click="
                edit = !edit;
                share = false;
              "
            >
              设置
            </div>
          </div>
        </div>
        <!-- <div :class="['iconfont iconfenxiang pa share', {active: share}]" @click="share = !share;edit=false;" title="分享设置"></div> -->
        <CommodityDetail
          ref="CommodityDetail"
          :transData="pcData.commodity"
          :id="id"
          type="1"
          v-if="pc"
        />
        <div class="mobile-style pr">
          <MCommodityDetail
            ref="MCommodityDetail"
            :transData="pcData.commodity"
            :projectId="id"
            type="1"
            v-if="!pc"
          />
        </div>
      </div>
      <!--右栏pc端-->
      <div v-if="edit">
        <PcControl
          @dealChange="dealChange"
          @dealShow="edit = false"
          :transData="pcData"
          :paperActiveIndex="Number(paperActiveIndex)"
          :leftIndex="5"
          :id="id"
          :projectType="projectType"
          from="shopPop"
          @resetStyle="resetStyle"
        />
        <!-- <MobileControl @dealChange="dealChange" @dealShow="edit=false" @dealExamListChange="dealExamListChange" :transData="mobile" :paperActiveIndex="Number(paperActiveIndex)" :leftIndex="5" :id="id" :projectType="projectType" @resetStyle="resetStyle"/> -->
      </div>
      <el-drawer
        title="分享"
        :visible.sync="share"
        :modal="false"
        custom-class="popShare"
        size="70%"
      >
        <Share @dealShow="share = false" />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Share from "@/views/staff/components/asktrue/PopShopShare";
import PcControl from "@/views/staff/components/asktrue/style-right-control/PcControl2";
import CommodityDetail from "@/views/staff/components/asktrue/style-control/exam/questionnairDetailStyle";
import MCommodityDetail from "@/views/staff/components/asktrue/style-control/exam/mobile/questionnairDetailStyle";
import {
  getProjectEdit,
  postProStyleSetReq,
  getProPaperReq,
  getProjectGoodsset,
} from "@/api/asktrue/project/project";
export default {
  name: "commodityView",
  components: {
    Share,
    PcControl,
    CommodityDetail,
    MCommodityDetail,
  },
  props: {
    data: Object,
    projectId: [String, Number],
    vipId: [String, Number],
    state: Number,
    ftype: Number,
    from: String,
  },
  computed: {
    pcOrMobile() {
      return this.pc ? "pcData" : "mobile";
    },
  },
  data() {
    return {
      share: false, //分享设置
      edit: false, //展示样式编辑
      pc: true, //pc样式还是移动样式
      paperActiveIndex: "0", //考试页面设置时当前问卷的index
      id: 0,
      projectType: 1,
      leftIndex: 3, //左侧控制index，外观为3-5，
      pcData: {
        commodity: {
          //商品页样式控制对象
          backgroundStyle: {
            //背景样式控制
            backgroundType: "pure", //背景样式类型
            typeDetail: "#fff", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
          },
          headerImg: "'@/assets/image/asktrue/topBg/ast1.jpg", //页眉
          commons: true, //是否允许评论
          introHtml: "", //完整说明
          intro: "", //纯文字说明，仅显示使用
          cost: {
            //费用
            costType: 0, //费用类型：0-免费；1、收费、2-红包
            onlyAmount: "", //收费-单次测试金额
            priceList: [], //收费-价格列表
            redPacket: "", //红包-金额
          },
        },
        login: {},
      },
      mobile: {
        commodity: {
          //商品页样式控制对象
          headerImg: "'@/assets/image/asktrue/topBg/ast1.jpg", //页眉
          commons: true, //是否允许评论
          introHtml: "", //完整说明
          intro: "", //纯文字说明，仅显示使用
          cost: {
            //费用
            costType: 0, //费用类型：0-免费；1、收费、2-红包
            onlyAmount: "", //收费-单次测试金额
            priceList: [], //收费-价格列表
            redPacket: "", //红包-金额
          },
        },
      },
      testList: [],
      pageTypeList: [],
    };
  },

  watch: {
    $route() {
      if (this.$route.fullPath.indexOf("/Style") > -1) {
        this.getData();
        this.getStyleSetFn();
        this.getProjectEditFn();
      }
    },
    // pc(){
    //   this.$nextTick(()=>{
    //     Bus.$emit('explainChange',this.pcData.commodity.introHtml);
    //   })
    // }
  },

  created() {
    this.reset();
    this.getData();
    this.getStyleSetFn();
    this.getProjectEditFn();
  },
  activated() {
    this.reset();
    this.getData();
    this.getStyleSetFn();
    this.getProjectEditFn();
  },
  methods: {
    reset() {
      this.pc = true;
      this.isEdit = false;
      this.pcData = {
        commodity: {
          //商品页样式控制对象
          backgroundStyle: {
            //背景样式控制
            backgroundType: "pure", //背景样式类型
            typeDetail: "#fff", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
          },
          headerImg: "'@/assets/image/asktrue/topBg/ast1.jpg", //页眉
          commons: true, //是否允许评论
          introHtml: "", //完整说明
          intro: "", //纯文字说明，仅显示使用
          cost: {
            //费用
            costType: 0, //费用类型：0-免费；1、收费、2-红包
            onlyAmount: "", //收费-单次测试金额
            priceList: [], //收费-价格列表
            redPacket: "", //红包-金额
          },
        },
      };
    },
    //获取详情数据
    getProjectEditFn() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.projectType,
      };
      getProjectEdit(params).then((res) => {
        if (res.code == "200") {
          let $ = res.data.result;
          this.editData = $;
          this.$store.dispatch("asktrue/setProInfo", this.editData);
          this.$emit("getList");
        }
      });
    },
    // 初始化页面或监听参数变化
    getData() {
      if (this.from == "pop") {
        this.id = Number(this.projectId);
      } else {
        this.id = Number(this.$route.query.id);
      }
      this.edit = false;
    },
    //获取外观样式参数值
    getStyleSetFn(reset) {
      let params = {
        examId: String(this.$route.query.id), //	项目id	query	string
        projectType: String(this.projectType), //项目类型:1考试 2调查 3评估 4报名 5投票
      };
      postProStyleSetReq(params).then((res) => {
        if (res.code == "200" && res.data.result) {
          if (res.data.result.spare5) {
            this.pcData.commodity = JSON.parse(res.data.result.spare5);
          } //PC商品详情页
          if (res.data.result.spare6) {
            this.mobile.commodity = JSON.parse(res.data.result.spare6);
          } //M商品详情页
          if (reset) {
            this.edit = true;
          }
          if (this.pc) {
            this.$refs.CommodityDetail.getDetailFn();
          } else {
            this.$refs.MCommodityDetail.getDetailFn();
          }
          this.getPrice();
        }
      });
    },

    //费用回显接口
    getPrice() {
      let params = {
        examId: String(this.$route.query.id), //	项目id	query	string
        projectType: String(this.projectType), //项目类型:1考试 2调查 3评估 4报名 5投票
      };
      getProjectGoodsset(params).then((res) => {
        console.log(res, "???res");
        if (res.code == "200") {
          if (!this.pcData.commodity.cost) {
            this.pcData.commodity.cost = {
              //费用
              costType: 0, //费用类型：0-免费；1、收费、2-红包
              onlyAmount: "", //收费-单次测试金额
              priceList: [], //收费-价格列表
              redPacket: "", //红包-金额
            };
          }
          if (this.pcData.commodity.cost.costType == 1) {
            //回显收费
            this.pcData.commodity.cost.priceList = [];
            this.mobile.commodity.cost.priceList = [];
            let arr = [];
            res.data.result.map((item, index) => {
              arr.push(item.examAmount);
              if (index == 0) {
                this.pcData.commodity.cost.onlyAmount = item.examAmount;
                this.mobile.commodity.cost.onlyAmount = item.examAmount;
              } else {
                this.pcData.commodity.cost.priceList.push(item);
                this.mobile.commodity.cost.priceList.push(item);
              }
            });
          } else if (this.pcData.commodity.cost.costType == 2) {
            //回显红包
            this.pcData.commodity.cost.redPacket =
              res.data.result[0].examAmount;
            this.mobile.commodity.cost.redPacket =
              res.data.result[0].examAmount;
          }

          console.log(this.pcData.commodity.cost, "this.pcData.commodity.cost");
        }
      });
    },

    //问卷查询
    getTestListFn() {
      let params = {
        examId: Number(this.id),
        projectType: this.projectType,
      };
      getProPaperReq(params).then((res) => {
        if (res.code == "200" && res.data.result.length) {
          this.testList = res.data.result;
          console.log(this.testList, "tttttt");
          this.paperActiveIndex = this.testList[0].paperId + "";
          this.pageTypeList.push({ active: 4, label: "答题页" });
        }
      });
    },
    // 试题样式保存
    dealExamListChange(data) {
      this[this.pcOrMobile].examStyle = data;
    },
    // 组件返回值设置
    dealChange(data, type) {
      this.pcData.commodity[type] = data;
      // this[this.pcOrMobile].commodity[type] = data;
    },
    //重置样式
    resetStyle() {
      this.edit = false;
      this.getStyleSetFn(true);
      this.getProjectEditFn();
      //this.$refs.pcstyle.reset();
    },

    toDetailFn() {
      console.log(this.id, this.projectType, this.$route.query.ftype);
      let names = [
        "",
        "ProKsExaminee",
        "ProDcData",
        "ProPgEvaluate",
        "ProBmData",
        "ProTpData",
      ];
      // let name="ProKsExaminee"
      // ProDcData
      // ProPgEvaluate
      // ProBmData
      // ProTpData
      let ftype = this.$route.query.ftype;
      this.$router.push({
        name: names[this.projectType],
        query: { id: this.id, type: this.projectType, ftype: ftype },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.menu-warpper {
  height: 100%;
}
.edit-warpper {
  padding: 0px;
  height: 100%;
  position: relative;
  .back {
    position: absolute;
    top: 24px;
    left: 20px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    em {
      font-size: 20px;
      font-weight: 700;
      color: var(--second-theme-color);
    }
    span {
      color: #999;
    }
  }
  .main {
    height: 100%;
    padding: 0px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    .body {
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
      &.mobile {
        padding-bottom: 50px;
        .mobile-style {
          background: #fff;
          min-height: 82%;
          width: 360px;
          margin: 0 auto;
          margin-top: 8%;
          box-shadow: 0 0 8px rgba(24, 61, 74, 0.5);
          border-radius: 3px;
          .exam-paper-change {
            position: absolute;
            margin-left: -75px;
            left: 0px;
            top: 0px;
          }
        }
      }
      .change-top-tab {
        top: 0px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 400px;
        z-index: 100;
        overflow: hidden;
        display: flex;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          font-size: 12px;
          border-left: 1px solid #e4e4e4;
          border-right: 1px solid #e4e4e4;
          border-bottom: 1px solid #e4e4e4;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          .item {
            width: 60px;
            line-height: 25px;
          }
        }
        .active {
          background: var(--second-theme-color);
          color: #fff;
        }
      }
      .edit {
        top: 65px;
        position: fixed;
        left: calc(50% + 182px);
        line-height: 25px;
        width: 30px;
        height: 26px;
        font-size: 13px;
        border-radius: 0 0 2px 2px;
        border: 1px solid #e4e4e4;
        border-top: 0px;
        background: #fff;
        z-index: 1;
        cursor: pointer;
        &.active {
          background: var(--second-theme-color);
          color: #fff;
          border-color: var(--second-theme-color);
        }
      }
      .share {
        top: 65px;
        position: fixed;
        left: calc(50% + 211px);
        line-height: 25px;
        width: 30px;
        height: 26px;
        font-size: 14px;
        border-radius: 0 0 2px 2px;
        border: 1px solid #e4e4e4;
        border-top: 0px;
        background: #fff;
        z-index: 1;
        cursor: pointer;
        &.active {
          background: var(--second-theme-color);
          color: #fff;
          border-color: var(--second-theme-color);
        }
      }
      .exam-paper-change {
        position: fixed;
        top: 130px;
        left: 250px;
        width: 40px;
        li {
          height: 40px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 15px;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          overflow: hidden;
          background-color: #fff;
          cursor: pointer;
          &.on {
            background-color: var(--second-theme-color);
            color: #fff;
            border: 1px solid var(--second-theme-color);
          }
        }
      }
    }
  }
}

/deep/ .popShare {
  top: 65px;
  height: calc(100% - 65px); //box-shadow:0 10px 10px 0px rgba(0,0,0,0.2);
  .el-drawer__header {
    top: 81px;
  }
}
</style>

/*
 * @Description: 绩效 目标  target https://perf.staffcloud.cn/swagger-ui.html#/
 * @Date: 2024-02-02 09:25:02
 * @LastEditTime: 2024-04-12 13:56:23
 */
export default function (axios) {
  return {
    // /targetCtl/saveTarget  新增目标
    savePerfTarget(data) {
      return axios.post("/targetCtl/saveTarget", data);
    },

    // /targetCtl/getTopUserId   获取直属上级id
    getPerTopUserId(params) {
      return axios.post("/targetCtl/getTopUserId", {}, { params });
    },

    // /targetCtl/selectPage  目标列表
    getPerfTargetList(data) {
      return axios.post("/targetCtl/selectPage", data);
    },

    // /targetCtl/getTargetInfo/{targetId}  目标详情
    getPerfTargetInfo(targetId) {
      return axios.post(`/targetCtl/getTargetInfo/${targetId}`);
    },

    // /targetCtl/ackTarget  确认目标
    ackPerfTargetFeedback(data) {
      return axios.post("/targetCtl/ackTarget", data);
    },

    // /targetCtl/delTarget/{targetId}  删除目标
    deletePerfTargetById(targetId) {
      return axios.post(`/targetCtl/delTarget/${targetId}`);
    },

    // /targetCtl/updateTarget  修改目标
    updatePerfTarget(data) {
      return axios.post("/targetCtl/updateTarget", data);
    },


    // /targetCtl/editPointSystem  修改与删除评分区间
    editPointSystem(data) {
      return axios.post("/targetCtl/editPointSystem", data);
    },

    // /targetCtl/insertPointSystem 新增评分区间
    insertPointSystem(data) {
      return axios.post("/targetCtl/insertPointSystem", data);
    },

    // /targetCtl/selectPointSystem/{startDate}/{endDate}  查询评分区间
    selectPointSystem(data) {
      return axios.post(`/targetCtl/selectPointSystem/${data.startDate}/${data.endDate}`);
    },
  };
}

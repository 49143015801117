export default {
  parent: "srm",
  routers: [
    {
      // 客户
      path: "srmclient",
      name: "SrmClient",
      component: () => import("@/views/crm/client/clientView.vue"),
    },
    {
      // 订单
      path: "srmorder",
      name: "SrmOrder",
      component: () => import("@/views/crm/order/orderView.vue"),
    },
    {
      // 合同
      path: "srmcontract",
      name: "SrmContract",
      component: () => import("@/views/crm/contract/contractView.vue"),
    },
    {
      // 发票
      path: "srminvoice",
      name: "SrmInvoice",
      component: () => import("@/views/crm/invoice/indexView.vue"),
    },
    {
      // 回款
      path: "srmpayment",
      name: "SrmPayment",
      component: () => import("@/views/crm/payment/paymentView.vue"),
    },
    {
      // 商品
      path: "srmproduct",
      name: "SrmProduct",
      component: () => import("@/views/crm/product/indexView.vue"),
    },
  ],
};

/*
 * @Description: 招聘
 * @Date: 2023-09-14 17:07:17
 * @LastEditTime: 2024-01-10 14:19:56
 */
export default {
  parent: "Recruit",
  routers: [
    // 职位 Job
    {
      path: "job",
      name: "Job",
      component: () => import("@/views/recruit/job/IndexView.vue"),
    },
    // 候选人 Candidate
    {
      path: "candidate",
      name: "Candidate",
      component: () => import("@/views/recruit/candidate/IndexView.vue"),
    },
    // 消息
    {
      path: "messages",
      name: "Messages",
      component: () => import("@/views/recruit/message/IndexView.vue"),
    },
    // 人才库 Talent
    {
      path: "talent",
      name: "Talent",
      component: () => import("@/views/recruit/talent/IndexView.vue"),
    },
    // 猎头 Headhunters
    // {
    //   path: "headhunters",
    //   name: "Headhunters",
    //   component: () => import("@/views/recruit/headhunters/IndexView.vue"),
    // },
    {
      path: "headhunters",
      name: "Headhunters",
      component: () => import("@/views/recruit/headhunters/IndexViewNew.vue"),
      meta: {
        title: "猎头",
        prev: {
          name: "ChannelMenu",
          title: "渠道",
        },
      },
    },
    // 渠道 Channel
    // {
    //   path: "channel",
    //   name: "Channel",
    //   component: () => import("@/views/recruit/channel/IndexView.vue"),
    // },
    // 渠道菜单
    {
      path: "channelmenu",
      name: "ChannelMenu",
      component: () => import("@/views/recruit/channelMenu/channelMenu.vue"),
    },
    {
      //好招企业-渠道-子菜单
      path: "Channel",
      name: "Channel",
      component: () => import("@/views/recruit/channel/IndexView.vue"),
      meta: {
        title: "好招",
        prev: {
          name: "ChannelMenu",
          title: "渠道",
        },
      },
    },
    // 安排 arrange
    {
      path: "arrange",
      name: "Arrange",
      component: () => import("@/views/recruit/arrange/bm/indexView.vue"),
    },
    // 体检 physicalExamArrange
    {
      path: "physicalExamArrange",
      name: "PhysicalExamArrange",
      component: () =>
        import("@/views/recruit/arrange/tj/PhysicalExamArrange.vue"),
      meta: {
        title: "体检",
        prev: {
          name: "Toolbox",
          title: "工具",
        },
      },
    },
    // 通知 noticeArrange
    {
      path: "noticeArrange",
      name: "NoticeArrange",
      component: () => import("@/views/recruit/arrange/tz/NoticeArrange.vue"),
      meta: {
        title: "通知",
        prev: {
          name: "Toolbox",
          title: "工具",
        },
      },
    },
    // 招录 project
    {
      path: "hzproject",
      name: "HzProject",
      component: () => import("@/views/recruit/project/Project.vue"),
    },
    // 笔试
    {
      path: "writtenExamArrange",
      name: "WrittenExamArrange",
      component: () =>
        import("@/views/recruit/arrange/bs/writtenExamArrange.vue"),
      meta: {
        title: "笔试",
        prev: {
          name: "Toolbox",
          title: "工具",
        },
      },
    },
    // 面试
    {
      path: "InterviewArrange",
      name: "InterviewArrange",
      component: () =>
        import("@/views/recruit/arrange/ms/InterviewArrange.vue"),
      meta: {
        title: "面试",
        prev: {
          name: "Toolbox",
          title: "工具",
        },
      },
    },
    // 报考
    {
      //报名--考试设置页
      path: "application",
      name: "Application",
      component: () => import("@/views/recruit/arrange/bm/ProjectNew.vue"),
    },
    // 考试测评
    // {
    //   path: "exam",
    //   name: "Exam",
    //   component: () => import("@/views/staff/exam/IndexView.vue"),
    //   meta: {
    //     title: "测评",
    //     prev: {
    //       name: "Toolbox",
    //       title: "工具",
    //     },
    //   },
    // },
    {
      path: "MatcheasyExam",
      name: "MatcheasyExam",
      component: () => import("@/views/staff/exam/IndexViewMatcheasy.vue"),
      meta: {
        title: "测评",
        prev: {
          name: "Toolbox",
          title: "工具",
        },
      },
    },
    // 招聘会
    {
      path: "jobfair",
      name: "Jobfair",
      component: () => import("@/views/recruit/jobfair/IndexView.vue"),
    },
    // 工具
    {
      path: "toolbox",
      name: "Toolbox",
      component: () => import("@/views/recruit/toolbox/IndexView.vue"),
    },
    // 商城
    {
      path: "hzmall",
      name: "HzMall",
      component: () => import("@/views/recruit/mall/IndexView.vue"),
    },
  ],
};

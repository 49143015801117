export default {
  parent: "SrmPayment",
  routers: [
    {
      // 全部
      path: ":page",
      name: "SRMPaymentList",
      component: () => import("@/views/crm/payment/paymentList.vue"),
    },
    // {
    //   // 已确认
    //   path: "confrimList",
    //   name: "SrmConfrimList",
    //   component: () => import("@/views/crm/payment/paymentList.vue"),
    // },
    // {
    //   // 待确认
    //   path: "toList",
    //   name: "SrmToList",
    //   component: () => import("@/views/crm/payment/paymentList.vue"),
    // },
    // {
    //   // 已驳回
    //   path: "opposeList",
    //   name: "SrmOpposeList",
    //   component: () => import("@/views/crm/payment/paymentList.vue"),
    // },
    // {
    //   // 已废除
    //   path: "abrogateList",
    //   name: "SrmAbrogateList",
    //   component: () => import("@/views/crm/payment/paymentList.vue"),
    // },
  ],
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tempWrap no-scroll",style:(_vm.backgroundStyle)},[_c('div',{staticClass:"topBg"},[_c('div',{staticClass:"top"},[(_vm.transData.logo.show)?_c('div',{staticClass:"logo"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.transData.logo.show),expression:"transData.logo.show"}],attrs:{"src":_vm.transData.logo.img}})]):_vm._e(),_c('div',{staticClass:"title",style:(_vm.titleStyle)},[_vm._v(_vm._s(_vm.titleText))])])]),_c('div',{staticClass:"bodyBox"},[_c('div',{staticClass:"tempBody"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 1),expression:"step == 1"}],staticClass:"kqxz",style:('background-color:' +
          _vm.transData.loginbox.rgba +
          ';border-color:' +
          _vm.transData.loginbox.borderColor +
          ';border-width:' +
          (_vm.transData.loginbox.show ? 1 : 0) +
          'px;')},[_vm._m(0),_c('div',{staticClass:"content no-scroll",domProps:{"innerHTML":_vm._s(_vm.inform)}},[_vm._v(_vm._s(_vm.inform))]),_c('div',{staticClass:"flex-space mt15"},[_c('el-checkbox',{staticClass:"flex",model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('p',{staticClass:"f14"},[_vm._v("我已阅读并同意遵守规定")])]),(_vm.checked)?_c('p',{staticClass:"el-icon-right",staticStyle:{"line-height":"19px","color":"#bbb"},on:{"click":function($event){return _vm.prevStep(2)}}}):_vm._e()],1)]),(
          (_vm.examData.projectType == 2 || _vm.examData.projectType == 3) &&
          _vm.examData.real_name == 2
        )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}]},[_c('div',{staticClass:"formBox",style:('background-color:' +
            _vm.transData.loginbox.rgba +
            ';border-color:' +
            _vm.transData.loginbox.borderColor +
            ';border-width:' +
            (_vm.transData.loginbox.show ? 1 : 0) +
            'px;')},[_c('div',{staticClass:"mb20 f60"},[_vm._v(" 注：本项目采用匿名方式，但限制了项目参与范围，请输入限制范围内的手机或邮箱获取项目参与许可! ")]),_c('input',{staticClass:"text",style:('border-color:' +
              _vm.transData.loginbox.borderColor +
              '!important;color:' +
              _vm.transData.loginbox.borderColor +
              '!important;'),attrs:{"placeholder":_vm.examData.login_rule == '1' ? '输入手机号' : '输入电子邮箱'}}),_c('div',{staticClass:"login-input"},[_c('input',{staticClass:"text",style:('border-color:' +
                _vm.transData.loginbox.borderColor +
                '!important;color:' +
                _vm.transData.loginbox.borderColor +
                '!important;'),attrs:{"type":"text","placeholder":"输入验证码"}}),_c('p',{style:('color:' + _vm.transData.loginbox.borderColor),attrs:{"slot":"suffix"},slot:"suffix"},[_c('em',{style:('background-color:' + _vm.transData.loginbox.borderColor)}),_c('span',{staticClass:"f14"},[_vm._v("获取验证码")])])]),_c('el-button',{style:('border-color:' +
              _vm.transData.loginbox.borderColor +
              '!important;color:' +
              _vm.transData.loginbox.borderColor +
              '!important;')},[_vm._v("验证")])],1),(_vm.judgesList.length)?_c('div',{staticClass:"mt30"},[_c('p',[_vm._v("请点击下面的随机许可证参与项目")]),_c('ul',{staticClass:"mt5 flex c9"},_vm._l((_vm.judgesList),function(item,index){return _c('li',{key:index,staticClass:"mr10"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()]):(_vm.examData.login_rule == '3')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],staticClass:"formBox",style:('background-color:' +
          _vm.transData.loginbox.rgba +
          ';border-color:' +
          _vm.transData.loginbox.borderColor +
          ';border-width:' +
          (_vm.transData.loginbox.show ? 1 : 0) +
          'px;')},[_c('input',{staticClass:"text",style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;'),attrs:{"type":"text","placeholder":"输入通行证"}}),_c('input',{staticClass:"text",style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;'),attrs:{"type":"text","placeholder":"输入密码","show-password":""}}),_c('el-button',{style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;')},[_vm._v("登录")])],1):(_vm.examData.login_rule == '1' || _vm.examData.login_rule == '2')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],staticClass:"formBox",style:('background-color:' +
          _vm.transData.loginbox.rgba +
          ';border-color:' +
          _vm.transData.loginbox.borderColor +
          ';border-width:' +
          (_vm.transData.loginbox.show ? 1 : 0) +
          'px;')},[_c('input',{staticClass:"text",style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;'),attrs:{"type":"text","placeholder":_vm.examData.login_rule == '1' ? '输入手机号码' : '输入电子邮箱'}}),_c('div',{staticClass:"login-input"},[_c('input',{staticClass:"text",style:('border-color:' +
              _vm.transData.loginbox.borderColor +
              '!important;color:' +
              _vm.transData.loginbox.borderColor +
              '!important;'),attrs:{"type":"text","placeholder":"输入验证码"}}),_c('p',{style:('color:' + _vm.transData.loginbox.borderColor),attrs:{"slot":"suffix"},slot:"suffix"},[_c('em',{style:('background-color:' + _vm.transData.loginbox.borderColor)}),_c('span',[_vm._v("免费获取")])])]),_c('el-button',{style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;')},[_vm._v("登录")])],1):(_vm.examData.login_rule == '4')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],staticClass:"formBox",style:('background-color:' +
          _vm.transData.loginbox.rgba +
          ';border-color:' +
          _vm.transData.loginbox.borderColor +
          ';border-width:' +
          (_vm.transData.loginbox.show ? 1 : 0) +
          'px;')},[_c('input',{staticClass:"text",style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;'),attrs:{"type":"text","placeholder":"输入姓名"}}),_c('input',{staticClass:"text",style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;'),attrs:{"type":"text","placeholder":"输入身份证号"}}),_c('el-button',{style:('border-color:' +
            _vm.transData.loginbox.borderColor +
            '!important;color:' +
            _vm.transData.loginbox.borderColor +
            '!important;')},[_vm._v("登录")])],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('span',[_vm._v("须知")])])
}]

export { render, staticRenderFns }
/*
 * @Description: Asset
 * @Date: 2024-07-03 17:40:28
 * @LastEditTime: 2024-07-03 17:42:45
 */

import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// /asset/selectAccountAsset  查询登陆账户的账户余额等信息
export function selectAccountAsset(data) {
  return request({
    url: "/asset/selectAccountAsset",
    method: "post",
    data,
  });
}

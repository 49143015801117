export default {
  parent: "Activity",
  routers: [
    // 我参加的
    {
      path: "in",
      name: "Activity_in",
      component: () => import("@/views/staff/activity/InView.vue"),
    },
    // 我发布的
    {
      path: "publish",
      name: "Activity_publish",
      component: () => import("@/views/staff/activity/PublishView.vue"),
    },
    // 全部
    {
      path: "all",
      name: "Activity_all",
      component: () => import("@/views/staff/activity/IndexView.vue"),
    },
  ],
};

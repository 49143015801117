/*
 * @Description: 关联 阿仕图 用户相关
 * @Date: 2023-11-23 09:05:25
 * @LastEditTime: 2023-11-29 17:29:46
 */
export default function (axios) {
  return {
    // /asktrueUserLinkCtl/getAsktrueToken 获取阿仕图token
    getAsktrueToken() {
      return axios.get("/asktrueUserLinkCtl/getAsktrueToken");
    },

    // /asktrueUserLinkCtl/insertCompany 新增阿仕图企业
    insertAsktrueCompany(data) {
      return axios.post("/asktrueUserLinkCtl/insertCompany", data);
    },

    // /asktrueUserLinkCtl/insertCompStaffInfo  新增阿仕图员工
    insertAsktrueCompStaffInfo(data) {
      return axios.post("/asktrueUserLinkCtl/insertCompStaffInfo", data, {
        headers: {
          // noErrorAlert: true,
        },
      });
    },
  };
}

export default function (axios) {
  return {
    // 审批类型——增、改
    app_saveAuditType(params) {
      return axios.post("/cAuditType/saveAuditType", params);
    },
    // 审批类型——删
    app_deleteAuditType(params) {
      return axios.post("/cAuditType/deleteAuditType", params);
    },
    // 审批类型——查
    app_selectPage(params) {
      return axios.get("/cAuditType/selectPage", { params });
    },

    // ----------------------------------------------------------------------

    // 审批模板——增、改
    app_saveAudit(params) {
      return axios.post("/cAudit/saveAudit", params);
    },
    // 审批模板——删
    app_deleteAudit(params) {
      return axios.post("/cAudit/deleteAudit", params);
    },
    // 审批模板——查列表
    app_selectAudit(params, type) {
      if (type == "set") {
        return axios.get("/cAudit/selectAudit", { params }); //设置
      } else {
        return axios.get("/cAudit/selectAuditByUser", { params }); //选择
      }
    },
    // 审批模板——查详情
    app_selectAuditById(params, nose) {
      nose = nose ? nose : ""
      let url = "/cAudit/selectAuditById" + nose
      return axios.get(url, { params });
    },

    // ----------------------------------------------------------------------

    // 审批工作流——增、改
    app_appSaveAudit(params) {
      return axios.post("/approval/saveAudit", params);
    },
    // 审批工作流——删
    app_appDeleteAudit(params) {
      return axios.post("/approval/deleteAudit", params);
    },
    // 审批工作流——查列表
    app_appSelectAudit(params) {
      return axios.get("/approval/selectAudit", { params });
    },
    // 审批工作流——查详情
    app_appSelectAuditById(params) {
      return axios.get("/approval/selectAuditById", { params });
    },
    // ----------------------------------------------------------------------

    // 审批列表——增
    app_submitApproval(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/approval/submitApproval" + nose, params);
    },
    // 审批列表——增前获取审批编码
    app_getMatterCode(params, nose) {
      nose = nose ? nose : ""
      return axios.get("/approval/getMatterCode" + nose, { params });
    },

    // 审批列表——我的申请（分页）
    app_selectMyApplication(params) {
      return axios.get("/approval/selectMyApplication", { params });
    },
    // 审批列表——我的审批（分页）
    app_selectApplyAudit(params) {
      return axios.get("/approval/selectApplyAudit", { params });
    },
    // 审批列表——抄送我的（分页）
    app_selectReceiveAudit(params) {
      return axios.get("/approval/selectReceiveAudit", { params });
    },
    // 审批列表——查详情
    app_selectAuditMatterRecordById(params, nose) {
      nose = nose ? nose : ""
      return axios.get("/approval/selectAuditMatterRecordById" + nose, { params });
    },

    // 审批列表——审批（同意、不同意）
    app_audit(params) {
      return axios.post("/approval/audit", params);
    },
  };
}

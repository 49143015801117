import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// // 获取公司信息
// export const getCompanyInfo = (data) => getReq(apiUrl1+'/companyMemberCtl/selectCompMemberById',data);
export function getCompanyInfo(params) {
  return request({
    url: "/companyMemberCtl/selectCompMemberById",
    method: "get",
    params,
  });
}

// // 公司信息修改
// export const updateMemberComp = (data) => postReq(apiUrl1+'/companyMemberCtl/updateMemberComp',data,{ContentType:'application/json;charset=UTF-8'});

// // 获取公司安全信息
// export const getComSafetyInfo = () => getReq(apiUrl1+'/cUser/selectUserInfoById',{});
export function getComSafetyInfo(params) {
  return request({
    url: "/cUser/selectUserInfoById",
    method: "get",
    params,
  });
}

// // 判断账号绑定的手机号是否唯一
// export const checkMobilePhone = (data) => getReq(apiUrl1+'/cUser/checkMobilePhone',data);

// // 判断账号绑定的邮箱是否唯一
// export const checkEmail = (data) => getReq(apiUrl1+'/cUser/checkEmail',data);

// // 邮箱解绑验证
// export const checkVerifyEmail = (data) => getReq(apiUrl1+'/cUser/checkVerifyEmail',data);

// // 手机解绑验证
// export const checkVerifyPhone = (data) => getReq(apiUrl1+'/cUser/checkVerifyPhone',data);

// // 邮箱绑定
// export const bindEmail = (data) => postReq(apiUrl1+'/cUser/bindEmail',data);

// // 手机号绑定
// export const bindMobile = (data) => postReq(apiUrl1+'/cUser/confirmMobileCode',data);

// // 子账号邮箱绑定
// export const bindChildEmail = (data) => postReq(apiUrl1+'/cChildUser/bindEmail',data);

// // 手机号绑定
// export const bindChildMobile = (data) => postReq(apiUrl1+'/cChildUser/confirmMobileCode',data);

// //员工权限
// export const getPower = (data) => getReq(apiUrl1+'/cPowerSetCtl/selectPower',data);//查询
// export const getLeftPower = (data) => getReq(apiUrl1+'/cAccountAuthCtl/selectAuthByUserId',data);//查询
// export const updatePower = (data) => postReq(apiUrl1+'/cPowerSetCtl/updatePower',data,{ContentType:'application/json;charset=UTF-8'});//变更
// export const updateAuthPower = (data) => postReq(apiUrl1+'/cAccountAuthCtl/updatePAccountAuth',data,{ContentType:'application/json;charset=UTF-8'});//变更
// // 更新左侧树状结构
// export const updatePAccountAuth = data => postReq(apiUrl1+"/cAccountAuthCtl/updatePAccountAuth",data,{ContentType:'application/json;charset=UTF-8'})
// // 查询权限树状结构
// export const selectMenuTree = data => getReq(apiUrl1+"/cAccountAuthCtl/selectMenuTree",data)

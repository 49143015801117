<template>
  <div class="background-item-container" @click.stop="">
    <div class="flex-space background-item">
      <div>背景色</div>
      <div class="flex-center" style="margin-right: -4px">
        <el-color-picker
          :predefine="predefineColors"
          @change="change('pure')"
          v-model="pure"
          class="cube-color"
          size="mini"
        ></el-color-picker>
        <!-- <i class="el-icon-arrow-down"></i> -->
      </div>
    </div>
    <div
      :class="['background-item', { 'unset-height': unsetHeight.gradient }]"
      @click="changeType('gradient')"
    >
      <div class="flex-space">
        <div>渐变色</div>
        <div class="flex-center">
          <div
            class="cube-color-img"
            v-if="backgroundType == 'gradient'"
            :style="
              'background:linear-gradient(' +
              gradient[0] +
              ',' +
              gradient[1] +
              ');'
            "
          ></div>
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
      <div class="linner-gra flex-center" @click.stop="">
        <el-color-picker
          :predefine="predefineColors"
          class="no-border"
          @change="change('gradient')"
          v-model="gradient[0]"
          size="mini"
        ></el-color-picker>
        <div
          class="color-show"
          :style="
            'background:linear-gradient(to right,' +
            gradient[0] +
            ',' +
            gradient[1] +
            ');'
          "
        ></div>
        <el-color-picker
          :predefine="predefineColors"
          class="no-border"
          @change="change('gradient')"
          v-model="gradient[1]"
          size="mini"
        ></el-color-picker>
      </div>
    </div>
    <div
      :class="['background-item', { 'unset-height': unsetHeight.img }]"
      @click="changeType('img')"
    >
      <div class="flex-space">
        <div>背景图</div>
        <div><i class="el-icon-arrow-down"></i></div>
      </div>
      <div class="upload-img-outer">
        <el-upload
          class="img-container tac"
          :action="apiUrl + '/minIOApi/oss/putObjects?fileDirType=comp_img'"
          :headers="token"
          :on-success="handleSuccess"
          accept="image/png"
        >
          <img v-if="img" style="width: 100%; height: 100%" :src="img" />
          <div v-else class="el-icon-plus" style=""></div
        ></el-upload>
        <p class="notes">
          注：背景图片拉伸显示;若图片上传失败，请尝试把图片像素调小或者剪切范围缩小后再上传。
        </p>
      </div>
    </div>
    <p class="notes">注：背景色、渐变色、背景图三选一；</p>
    <div class="flex-space">
      <el-button
        size="mini"
        style="width: 62px"
        class="mb15"
        @click="resetData('', 'clear')"
        >清空</el-button
      >
      <el-button
        size="mini"
        style="width: 62px"
        class="mb15"
        @click="resetData('', 'reset')"
        >重置</el-button
      >
    </div>
  </div>
</template>

<script>
import { apiUrl1 } from "@/api/asktrue/config";
export default {
  props: ["backgroundStyle"],
  data() {
    return {
      backgroundType: "",
      pure: "",
      gradient: ["#fff", "#fff"],
      img: "",
      unsetHeight: {
        gradient: false,
        img: false,
      },
      type: ["gradient", "img", "pure"],
      token: {},
      apiUrl: apiUrl1,
    };
  },
  watch: {
    // 监听传值变化
    backgroundStyle() {
      this.resetData();
      this.getData();
      this.unsetHeight = { gradient: false, img: false };
      this.backgroundType !== "pure"
        ? (this.unsetHeight[this.backgroundType] = true)
        : "";
    },
  },
  created() {
    this.token = { access_token: this.$store.state.asktrue.access_token };
    this.getData();
  },
  methods: {
    handleSuccess(res) {
      if (res.code == "200") {
        this.img = res.data.result;
        this.change("img");
      }
    },
    // 还原设置样式
    getData() {
      this.backgroundType = this.backgroundStyle.backgroundType;
      this[this.backgroundType] = this.backgroundStyle.typeDetail;
    },
    // 展示收起背景类型设置
    changeType(type) {
      this.unsetHeight[type] = !this.unsetHeight[type];
    },
    // 重置除type外的其它类型，reset选填，值为reset重置，clear清空。
    resetData(type, reset) {
      this.type.forEach((ele) => {
        if (ele !== type) {
          this[ele] = ele == "gradient" ? ["#fff", "#fff"] : "";
        }
      });
      if (reset == "reset" || reset == "clear") {
        if (reset == "reset") {
          this.backgroundType = "pure";
          this.pure = "#ebedf1";
        }
        this.$emit(
          "dealChange",
          {
            backgroundType: this.backgroundType,
            typeDetail: this[this.backgroundType],
          },
          "backgroundStyle"
        );
      }
    },
    // 背景数据变化
    change(type) {
      this.backgroundType = type;
      this.resetData(type);
      // 传值父组件改变
      this.$emit(
        "dealChange",
        {
          backgroundType: this.backgroundType,
          typeDetail: this[this.backgroundType],
        },
        "backgroundStyle"
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list {
  display: none;
}
.background-item-container {
  background: #f2f2f2;
  padding: 0 15px;
  .notes {
    line-height: 20px;
    text-align: left;
    padding: 10px 0;
    color: #ccc;
  }
  .notes:hover {
    color: #f60;
  }
  .background-item {
    line-height: 50px;
    border-bottom: 1px dotted #e4e4e4;
    height: 51px;
    overflow: hidden;
    .upload-img-outer {
      background: #fff;
      padding: 10px 15px;
      /deep/ .img-container {
        border: 1px solid #e4e4e4;
        line-height: 108px;
        width: 100%;
        height: 108px;
        align-items: center;
        img {
          width: 106px;
          height: 106px !important;
        }
        .el-icon-plus {
          font-size: 24px;
          width: 100%;
          color: #ccc;
        }
      }
      &:hover .notes {
        color: #f60;
      }
    }
    &.unset-height {
      height: unset;
      border-bottom: 0px;
    }
    .cube-color-img {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      margin-right: 9px;
      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .linner-gra {
      margin-bottom: 10px;
      background: #ebedf1;
      .color-show {
        height: 18px;
        flex-grow: 1;
      }
    }
    /deep/ .cube-color .el-color-picker__trigger {
      padding: 3px;
      border-color: transparent;
      margin-right: 20px;
      .el-color-picker__color {
        border-radius: 2px;
        border: 1px solid #e4e4e4;
        overflow: hidden;
      }
      .el-color-picker__icon {
        left: unset;
        color: #333;
        right: -37px;
        font-size: 15px;
      }
    }
    /deep/ .no-border .el-color-picker__trigger {
      border-color: transparent;
      .el-color-picker__color {
        border-radius: 2px;
        border: 0px;
        overflow: hidden;
      }
    }
  }
}
</style>

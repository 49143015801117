import qs from "qs"; // 序列化
export default function (axios) {
  return {
    //简历初筛分页查询
    getFiltraResumeListReq(data) {
      return axios.post("/newPrimaryScreeningCtl/selectPage", data, {
        ContentType: "application/json",
      });
    },
    //全部简历统计
    getCandidateCount(data) {
      return axios.get(
        "/newResume/getCount",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //企业收藏简历
    addCompCollResumeReq(data) {
      return axios.get(
        "/matching/addCompCollResume",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    updateCompCollResumeReq(data) {
      return axios.get(
        "/matching/updateCompCollResume",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //人才库删除候选人(招聘系统)
    deleteResumeLibZpReq(data) {
      return axios.post("/resumepool/deleteResumeLibZp", data, {
        ContentType: "application/json",
      });
    },
    //企业推荐职位发送短信与邮件
    recommendedJobSendReq(data) {
      return axios.post("/approval/recommendedJobSend", data, {
        ContentType: "application/json",
      });
    },
    //分类查询(层级)
    getCompDirTreeReq(data) {
      return axios.get(
        "/resumepool/selectCompDirTree",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //简历入库
    addResumeToDirReq(data) {
      return axios.post("/resumepool/addResumeTODir", data, {
        ContentType: "application/json",
      });
    },

    // //简历附件编辑
    //简历信息编辑
    updateResumeReq(typeUrl, data) {
      let url = "/resume/update" + typeUrl;
      return axios.post(url, data, {
        ContentType: "application/json",
      });
    },
    //简历附件编辑
    updateResumeFileReq(data) {
      return axios.post("/resume/updateAttatchInfo", data, {
        ContentType: "application/json",
      });
    },
    //简历信息编辑
    delFindReq(data) {
      return axios.post(
        "/findCtl/deleteByPrimaryKey?findPeopleId=" + data.findPeopleId,
        data,
        {
          ContentType: "application/json",
        }
      );
    },
    //删除录用的报道地点
    addResumePostRelReq2(data) {
      return axios.post("/newResume/saveResumeChannel", data, {
        ContentType: "application/json",
      });
    },
    //查询当前简历的附件简历url
    getResumeAttachReq(data) {
      return axios({
        url: "/resume/queryAttachmentById",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },
    analyzeNoseReq(data) {
      return axios.post("/analyzeResumeCtl/analyzeNose", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //分类查询(层级)
    getResumeAllInfoReq(data) {
      return axios.get(
        "/resume/selectResumeAllInfo",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //简历信息查询
    // export function getResumeInfoReq(typeUrl,data) {
    //   let url='/resume/select'+typeUrl
    //   return getReq(apiUrl+url ,data)
    // }
    getResumeInfoReq(typeUrl, data) {
      let url = "/resume/select" + typeUrl;
      return axios.get(
        url,
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //简历信息删除

    delResumeInfoReq(typeUrl, data) {
      let url = "/resume/delete" + typeUrl;
      return axios.delete(
        url,
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //简历信息编辑
    updateResumePlain(data) {
      return axios.post("/resume/updateResumePlain", data, {
        ContentType: "application/json",
      });
    },
    // export const getProgessResumeListReq = data => postReq(apiUrl + "/resumeProcessCtl/selectPage", data, { ContentType: 'application/json;charset=UTF-8' })//简历进程分页查询

    //简历进程分页查询
    // getProgessResumeListReq(data) {
    //   return axios.post("/resumeProcessCtl/selectPage", data, {
    //     ContentType: "application/json",
    //   });
    // },
    // //简历进程-阶段数值查询
    getStageResumeNumReq(data) {
      return axios.get(
        "/newResume/selectStageCount",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },

    // 阶段筛选数据查询
    selectCompStagesReq(data) {
      return axios.get(
        "/approval/selectCompStages",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    //阶段新增/编辑
    updateStageReq(data) {
      return axios.get(
        "/operate/updateStage",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    // 阶段删除
    delStageReq(data) {
      return axios.delete(
        "/operate/deleteStage",
        { params: data },
        {
          ContentType: "application/json",
        }
      );
    },
    // 阶段上下移动
    moveStageReq(data) {
      return axios.post("/operate/editStage", data, {
        ContentType: "application/json",
      });
    },
    //简历录用分页查询
    getEmployResumeListReq(data) {
      return axios.post("/resumeProcessCtl/selectResumeEmployList", data, {
        ContentType: "application/json",
      });
    },
    //简历初筛分页查询
    getUnsuitableResumeListReq(data) {
      return axios.post("/newResume/selectInappropriatePage", data, {
        ContentType: "application/json",
      });
    },
    //更改录用简历状态(是否发送offer,是否入职)
    updateEmployResumeReq(data) {
      return axios.post("/newResume/updateEmployResume", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

    //获取offer模版
    getOfferTemplateListReq(data) {
      return axios.post("/newResume/getOfferTemplateList", data, {
        ContentType: "application/json",
      });
    },
    //维护offer模版(新增,修改,删除)
    updateOfferTemplateReq(data) {
      return axios.post("/newResume/updateOfferTemplate", data, {
        ContentType: "application/json",
      });
    },
    //获取录用的报道地点
    selectAddrListReq(data) {
      return axios.post("/newResume/selectList", data, {
        ContentType: "application/json",
      });
    },
    //新增录用的报道地点
    insertAddressReq(data) {
      return axios.post("/newResume/insertAddress", data, {
        ContentType: "application/json",
      });
    },
    // 修改录用的报道地点
    editAddressReq(data) {
      return axios.post("/newResume/editAddress", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // export const delAddressReq = id => postReq(apiUrl + `/newResume/delAddress/${id}`)//删除录用的报道地点
    delAddressReq(id) {
      return axios.post(`/newResume/delAddress/${id}`);
    },
    // export const selectLabelReq = data => getReq(apiUrl + "/operate/selectLabel", data)//查询标签
    selectLabelReq(data) {
      return axios.get("/operate/selectLabel", { params: data });
    },
    // export const updateLabelReq = data => getReq(apiUrl + "/operate/updateLabel", data)//简历标签新增/修改
    // export const updateResumeLabelReq = data => getReq(apiUrl + "/newResume/updateResumeLabel", data)//简历设置标签
    updateLabelReq(data) {
      return axios.get("/operate/updateLabel", { params: data });
    },
    updateResumeLabelReq(data) {
      return axios.get("/newResume/updateResumeLabel", { params: data });
    },
  };
}

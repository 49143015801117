export default {
  parent: "Approve",
  routers: [
    // 我的申请
    {
      path: "myapply",
      name: "MyApply",
      component: () => import("@/views/oa/approve/MyApply.vue"),
    },
    // 我的审批
    {
      path: "list",
      name: "ApproveList",
      component: () => import("@/views/oa/approve/ApproveList.vue"),
    },
    // 抄送我的
    {
      path: "mycc",
      name: "MyCc",
      component: () => import("@/views/oa/approve/MyCc.vue"),
    },
    // 模板设置
    {
      path: "temp",
      name: "ApproveTemp",
      component: () => import("@/views/oa/approve/ApproveTemp.vue"),
    },
  ],
};

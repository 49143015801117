<template>
  <div class="exam-background pr" :style="backgroundStyle">
    <!--头：logo与title-->
    <div class="top">
      <div class="logo" v-if="transData.logo.show">
        <img v-show="transData.logo.show" :src="transData.logo.img" />
      </div>
      <div class="title" :style="titleStyle">{{ titleText }}</div>
      <!--{{titleText||examData.exam_name}}-->
    </div>

    <div :class="['main-box', { mainMore: stepList.length > 1 }]">
      <div v-if="stepList.length > 1" class="step-show">
        <div
          :class="['back-border05 ' + (onG ? 'border-on-g' : 'border-on')]"
          :style="stepStyle.pass"
        ></div>
        <div
          :style="
            step == stepList[0].step
              ? stepStyle.checked
              : maxStep >= stepList[0].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[0].step && !onG },
            { 'on-g': maxStep >= stepList[0].step && onG },
            { 'active-g': step == stepList[0].step && onG },
            { active: step == stepList[0].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[0].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[0].step) + 1
            }}</i>
            <div class="name">{{ stepList[0].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[1].step ? stepStyle.pass : stepStyle.begin
          "
          v-if="stepList.length > 1"
          :class="[
            'back-border1',
            { 'border-on': maxStep >= stepList[1].step && !onG },
            { 'border-on-g': maxStep >= stepList[1].step && onG },
          ]"
        ></div>
        <div
          :style="
            step == stepList[1].step
              ? stepStyle.checked
              : maxStep >= stepList[1].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          v-if="stepList.length > 1"
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[1].step && !onG },
            ,
            { active: step == stepList[1].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[1].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[1].step) + 1
            }}</i>
            <div class="name">{{ stepList[1].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[2].step ? stepStyle.pass : stepStyle.begin
          "
          v-if="stepList.length > 2"
          :class="[
            'back-border1',
            { 'border-on': maxStep >= stepList[2].step && !onG },
            { 'border-on-g': maxStep >= stepList[2].step && onG },
          ]"
        ></div>
        <div
          :style="
            step == stepList[2].step
              ? stepStyle.checked
              : maxStep >= stepList[2].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          v-if="stepList.length > 2"
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[2].step && !onG },
            { active: step == stepList[2].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[2].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[2].step) + 1
            }}</i>
            <div class="name">{{ stepList[2].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[3].step ? stepStyle.pass : stepStyle.begin
          "
          v-if="stepList.length > 3"
          :class="[
            'back-border1',
            { 'border-on': maxStep >= stepList[3].step && !onG },
            { 'border-on-g': maxStep >= stepList[3].step && onG },
          ]"
        ></div>
        <div
          :style="
            step == stepList[3].step
              ? stepStyle.checked
              : maxStep >= stepList[3].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          v-if="stepList.length > 3"
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[3].step && !onG },
            { active: step == stepList[3].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[3].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[3].step) + 1
            }}</i>
            <div class="name">{{ stepList[3].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[4].step ? stepStyle.pass : stepStyle.begin
          "
          v-if="stepList.length > 4"
          :class="[
            'back-border1',
            { 'border-on': maxStep >= stepList[4].step && !onG },
            { 'border-on-g': maxStep >= stepList[4].step && onG },
          ]"
        ></div>
        <div
          :style="
            step == stepList[4].step
              ? stepStyle.checked
              : maxStep >= stepList[4].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          v-if="stepList.length > 4"
          :class="[
            'top-info-item',
            { on: maxStep >= stepList[4].step && !onG },
            { active: step == stepList[4].step },
          ]"
        >
          <div class="info" @click="changeStep(stepList[4].step)">
            <i>{{
              stepList.findIndex((ele) => ele.step == stepList[4].step) + 1
            }}</i>
            <div class="name">{{ stepList[4].title }}</div>
          </div>
        </div>
        <div
          :style="
            maxStep >= stepList[stepList.length - 1].step
              ? stepStyle.pass
              : stepStyle.begin
          "
          :class="[
            'back-border05',
            {
              'border-on':
                maxStep >= stepList[stepList.length - 1].step && !onG,
            },
            {
              'border-on-g':
                maxStep >= stepList[stepList.length - 1].step && onG,
            },
          ]"
        ></div>
      </div>

      <div :class="['main', 'main' + step]">
        <!--  :style="step!=1?outerStyle:({'min-height':outerStyle.minHeight})" -->
        <!--考前须知-->
        <div class="plate tal" v-if="inform && step == 0">
          <!-- <h2>考前须知</h2> -->
          <div class="kqxz border-box">
            <div class="inform tal" v-html="inform"></div>
            <el-checkbox
              class="mt20"
              v-model="isInform"
              @change="changeStep(0, 1)"
              >我已阅读并同意遵守规定</el-checkbox
            >
          </div>
        </div>

        <!--环境检测-->
        <div
          class="plate"
          v-if="stepList.length > 1"
          v-show="
            (step == 1 || step == 2 || step == 3) &&
            examData &&
            (examData.face_recognition == 2 ||
              examData.photograph == 2 ||
              examData.prevent_chead == 2)
          "
        >
          <!-- <h2>答题检测</h2> -->
          <div class="yhj2 border-box flex-center">
            <!-- <span class="title ">环境检测</span> -->
            <div class="hardwareCheck">
              <div
                v-show="step == 1"
                name="wl"
                v-if="
                  examData.face_recognition == 2 || examData.photograph == 2
                "
              >
                <div class="testing">
                  <div class="left-div">
                    <img src="@/assets/image/asktrue/cws.png" />
                    <p class="mt20 f12">
                      为保证答题顺利请确保您使用的网络稳定流畅。
                    </p>
                    <div>
                      <button class="start-test mt30" @click="goCheck(1)">
                        <span v-if="hardwareCheck.isNetwork == 0">开始检测</span
                        ><span v-else>重新检测</span>
                      </button>
                    </div>
                    <div class="common_status mt20">
                      <div
                        :class="'s' + hardwareCheck.isNetwork"
                        v-if="hardwareCheck.isNetwork == 1"
                      >
                        <em class="el-icon-success"></em><span>检测通过</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isNetwork"
                        v-if="hardwareCheck.isNetwork == 2"
                      >
                        <em class="el-icon-error"></em
                        ><span>网络环境不稳定</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isNetwork"
                        v-if="hardwareCheck.isNetwork == 3"
                      >
                        <em class="el-icon-loading"></em><span>正在检测</span>
                      </div>
                    </div>
                  </div>
                  <div class="right-div" v-if="hardwareCheck.isNetwork > 0">
                    <p class="mt30 mb10 f12">
                      为了保证答题顺利,建议活动开始前30min再次检测网速。
                    </p>
                    <el-table
                      :data="hardwareCheck.networkResult"
                      :show-header="false"
                      border
                      size="mini"
                      style="width: 100%"
                    >
                      <el-table-column
                        prop="label"
                        width="180"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="result"
                        align="center"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <div
                v-show="step == 2"
                name="sx"
                v-if="
                  examData &&
                  (examData.face_recognition == 2 || examData.photograph == 2)
                "
              >
                <div class="testing">
                  <div class="left-div">
                    <img src="@/assets/image/asktrue/camera.png" />
                    <p class="mt20 f12">
                      在线笔试会开启视频监控，请保证您的电脑有可以使用的摄像头。
                    </p>
                    <div>
                      <button class="start-test mt30" @click="goCheck(2)">
                        <span v-if="hardwareCheck.isWebcam == 0">开始检测</span
                        ><span v-else>重新检测</span>
                      </button>
                    </div>
                    <div class="common_status mt20">
                      <div
                        :class="'s' + hardwareCheck.isWebcam"
                        v-if="hardwareCheck.isWebcam == 1"
                      >
                        <em class="el-icon-success"></em><span>检测通过</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isWebcam"
                        v-if="hardwareCheck.isWebcam == 2"
                      >
                        <em class="el-icon-error"></em
                        ><span>未检测到摄像头</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isWebcam"
                        v-if="hardwareCheck.isWebcam == 3"
                      >
                        <em class="el-icon-loading"></em><span>正在检测</span>
                      </div>
                    </div>
                  </div>
                  <div class="right-div" v-show="hardwareCheck.isWebcam > 0">
                    <p class="mt30 mb10 f12">
                      请点击地址栏右侧的<span class="media-icon"></span
                      >图标，在弹出的对话框中选择"始终允许使用您的摄像头"，然后刷新页面。如果没有任何提示，请确认您的电脑有可用的摄像头。
                    </p>
                    <div class="mt10 tac">
                      <Video
                        ref="webcam"
                        :param="hardwareCheck.webcamParam"
                        v-on:back="videoBack"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="step == 3"
                name="zb"
                v-if="examData && examData.prevent_chead == 2"
              >
                <div class="testing">
                  <div class="left-div">
                    <img src="@/assets/image/asktrue/fzb.png" />
                    <p class="mt20 f12">
                      在线笔试会开启防作弊控制功能，请确保已下载并安装防作弊插件。
                    </p>
                    <div>
                      <button class="start-test mt30" @click="goCheck(3)">
                        <span v-if="hardwareCheck.isPOC == 0">开始检测</span
                        ><span v-else>重新检测</span>
                      </button>
                    </div>
                    <div class="common_status mt20">
                      <div
                        :class="'s' + hardwareCheck.isPOC"
                        v-if="hardwareCheck.isPOC == 1"
                      >
                        <em class="el-icon-success"></em><span>检测通过</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isPOC"
                        v-if="hardwareCheck.isPOC == 2"
                      >
                        <em class="el-icon-error"></em
                        ><span>未检测到考试插件</span>
                      </div>
                      <div
                        :class="'s' + hardwareCheck.isPOC"
                        v-if="hardwareCheck.isPOC == 3"
                      >
                        <em class="el-icon-loading"></em><span>正在检测</span>
                      </div>
                    </div>
                  </div>
                  <div class="right-div">
                    <div class="clearfix mt20 mb20">
                      <span class="fl f16 c6">请先安装插件</span
                      ><a class="fr c39c" @click="hardwareCheck.isPocDoc = true"
                        >使用帮助<i
                          class="iconfont iconbangzhuwendang1 f14 c39c"
                        ></i
                      ></a>
                    </div>
                    <div
                      class="tac"
                      style="height: 170px; border: 1px dotted #e4e4e4"
                    >
                      <div class="mb15" style="padding-top: 25px">
                        <img
                          class="note-img"
                          src="@/assets/image/asktrue/plugdown.png"
                          style="
                            width: 50px !important;
                            height: 50px !important;
                          "
                        />
                        <p class="ml5 mb10">考试插件.exe</p>
                      </div>
                      <a
                        href="https://www.asktrue.cn/asktrue/install/%E8%80%83%E8%AF%95%E6%8F%92%E4%BB%B6.exe"
                        class="f12 cff yhj2"
                        style="background: #ccc; padding: 5px 10px"
                        >点此下载</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--登录信息-->
        <div
          class="plate"
          v-show="
            step == 5 && examData.login_rule && examData.login_rule != '0'
          "
        >
          <div class="border-box">
            <div class="login clearfix" v-if="examData.login_rule == '3'">
              <!--通行证-->
              <el-input
                class="mt0"
                v-model="name"
                placeholder="输入考号"
                prefix-icon="el-icon-user"
              ></el-input>
              <el-input
                v-model="pwd"
                type="password"
                placeholder="输入密码"
                autocomplete="new-password"
                prefix-icon="el-icon-lock"
              ></el-input>
            </div>
            <div
              class="login clearfix"
              v-if="examData.login_rule == '1' || examData.login_rule == '2'"
            >
              <!--手机、邮箱-->
              <el-input
                class="mt10"
                v-model="name"
                :placeholder="
                  examData.login_rule == '1' ? '输入手机号码' : '输入电子邮箱'
                "
                :prefix-icon="
                  examData.login_rule == '1'
                    ? 'el-icon-mobile-phone'
                    : 'el-icon-message'
                "
              ></el-input>
              <div class="pr code-div" style="width: 360px; margin: 0 auto">
                <el-input
                  style="width: 100%"
                  class="psd-input"
                  placeholder="输入验证码"
                  v-model="pwd"
                  prefix-icon="el-icon-lock"
                ></el-input>
                <span class="getcodebtn pa cur" v-if="num < 1"
                  ><em></em><span class="cur">获取验证码</span></span
                >
              </div>
            </div>
            <div class="login clearfix" v-if="examData.login_rule == '4'">
              <!--人脸识别-->
              <el-input
                class="mt0"
                v-model="name"
                placeholder="输入姓名"
                prefix-icon="el-icon-user"
              ></el-input>
              <el-input
                v-model="pwd"
                placeholder="输入身份证号"
                prefix-icon="el-icon-postcard"
              ></el-input>
              <!-- <FaceRecognition @getImg="getImgFn"/> -->
            </div>
            <!--进入考试-->
            <div class="tac mt40">
              <el-button class="logBtn" :style="this.buttonStyle"
                >{{
                  examData.loginRule == "4" && faceFalg == 1 ? "核验" : "登录"
                }}
              </el-button>
            </div>
          </div>
        </div>
        <!--进入考试-->
        <!-- <div class="mt40 tal"><el-button type="primary" @click="$utils.voit('请前往预览页面测试！')" :style="buttonStyle" style="padding:0px;width:200px;height:40px;">现在去参加</el-button></div> -->
      </div>
    </div>
    <!--版权-->
    <div
      class="foot"
      v-if="this.transData.copyright && this.transData.copyright.show"
    >
      {{ this.transData.copyright.font }}
    </div>

    <!--必填项弹窗-->
    <el-drawer
      :visible.sync="isMustFill"
      size="40%"
      custom-class="asktrue-drawer"
      ><MustFill
        ref="mustFill"
        :id="Number($route.query.id)"
        :type="String($route.params.type)"
        :ownerId="vipId"
        :studentId="studentId"
        v-on:closeFill="isMustFill = false"
    /></el-drawer>

    <!--使用帮助弹窗-->
    <el-drawer
      :visible.sync="hardwareCheck.isPocDoc"
      size="30%"
      class="doc-pop"
      custom-class="asktrue-drawer"
    >
      <h3 class="tac">考试插件安装说明</h3>
      <div class="mt10">
        请在Windows7以上操作系统环境下运行防作弊插件；<br />请使用谷歌、360、QQ、搜狗等主流浏览器登录系统（不建议使用IE浏览器）。
      </div>
      <div
        class="mt20"
        v-for="(item, index) in hardwareCheck.docList"
        :key="index"
      >
        <el-image
          :src="item.img"
          title="点击查看大图"
          :preview-src-list="[item.img]"
          :z-index="2005"
        ></el-image>
        <div class="mt10">{{ item.text }}</div>
      </div>
    </el-drawer>
    <div class="pop-body" v-show="waitingTime >= 0">
      <div>环境监测通过，稍后为您切换登录页！{{ waitingTime }}</div>
    </div>
  </div>
</template>

<script>
// import {v4 as uuidv4} from 'uuid';
import MustFill from "../../ExamMustFill";
import Video from "../../Video";
export default {
  props: {
    transData: Object,
  },
  watch: {
    transData: {
      handler() {
        this.getStyle();
      },
      deep: true,
    },
    step(val) {
      this.maxStep = val < this.maxStep ? this.maxStep : val;
    },
  },
  // computed: {
  //   examData(){
  //     return this.$store.state.project.projectInfo;
  //   }
  // },
  components: { MustFill, Video },
  data() {
    return {
      stepStyle: {
        checked: "",
        begin: "",
        pass: "",
      },
      type: "", //id
      style: {
        //外观样式
        backgroundStyle: "", //设置的背景
      },
      title: "", //标题
      inform: "", //考前须知
      hardwareCheck: {
        //设备环境检测
        isNetwork: 0, //网络开关
        networkResult: [],
        isWebcam: 0, //摄像头状态
        isRunCam: false, //摄像头检测开关
        webcamParam: {
          width: "250",
          height: "158",
        },
        isPOC: 0, //防作弊开关
        isPocDoc: false, //使用帮助弹窗
        docList: [
          {
            img: require("@/assets/image/asktrue/fzb/usehelp2.png"),
            text: "在当前页面，点击切换至“防作弊”栏，然后点击“点此下载”按钮，下载“考试插件.exe”。注意，不要选择下载并运行，请下载保存到文件夹中。",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp3.png"),
            text: "下载完成后，鼠标右键单击“考试插件”，在弹出的菜单页，选择“以管理员身份运行”",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp4.png"),
            text: "在弹出windows保护弹窗中，点击“更多信息”",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp5.png"),
            text: "然后点击“仍要运行”按钮",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp6.png"),
            text: "弹出程序安装界面，点击“下一步”按钮",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp7.png"),
            text: "在许可协议弹窗，选择“我接受”按钮",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp8.png"),
            text: "点击“浏览”按钮，选择安装程序的文件夹，建议插件安装路径选择D盘的文件夹；然后点击“安装”按钮继续安装；",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp9.png"),
            text: "如果程序安装了电脑管家或360安全卫士等电脑防护工具，需要在提示弹窗中选择“允许程序所有操作”，确保程序能顺利安装；",
          },
          {
            img: require("@/assets/image/asktrue/fzb/usehelp10.png"),
            text: "程序安装完成后，点击“完成”按钮完成安装；",
          },
        ],
      },
      name: "", //登录账号
      pwd: "", //登录密码
      isMustFill: false, //必填项开关
      studentId: "", //考生id

      // examData:{},
      num: 0, //秒数
      myVar: "", //定时器

      vipId: "",
      backgroundStyle: "", //设置的背景
      titleStyle: "", //设置的标题样式
      titleText: "标题", //设置的标题
      buttonStyle: "",
      stepList: [],
      step: 0,
      maxStep: 0,
      isInform: false,
      outerStyle: this.client(),
      onG: false,
      activeTab: "wl",
      activelist: [],
      waitingTime: -1,
      // uuid: uuidv4().split('-').join(''),
    };
  },
  created() {
    this.getReset();
  },
  mounted() {
    window.jsonpcallback = (res) => {
      this.checkFzbFn(res);
    };
  },
  methods: {
    changeStepStyle() {
      for (let prop in this.stepStyle) {
        this.stepStyle[prop] = this.transData.step[prop];
      }
    },
    getReset() {
      this.getStyle();
      this.type = this.$store.state.asktrue.projectInfo
        ? this.$store.state.asktrue.projectInfo.projectType
        : "";
      this.vipId = this.$store.state.asktrue.projectInfo
        ? this.$store.state.asktrue.projectInfo.vip_id
        : "";
      this.examData = this.$store.state.asktrue.projectInfo
        ? this.$store.state.asktrue.projectInfo
        : "";
      if (this.vipId) {
        this.getExamInfoFn();
      }
      this.$watch(
        "$store.state.project.projectInfo",
        (val) => {
          if (val.vip_id) {
            this.examData = val;
            this.vipId = val.vip_id;
            this.getExamInfoFn();
            this.type = val.projectType;
          }
        },
        { deep: true }
      );
    },
    stopVideo() {
      if (this.$refs.webcam) this.$refs.webcam.stopNavigator();
    },
    client() {
      if (window.innerWidth) {
        // ie9+ 最新的浏览器
        return {
          minWidth: (window.innerHeight - 50) * 0.866 + "px",
          minHeight: (window.innerHeight - 50) * 0.8 + "px",
        };
      } else if (document.compatMode === "CSS1Compat") {
        // W3C
        return {
          minWidth: (document.documentElement.clientHeight - 50) * 0.867 + "px",
          minHeight: (document.documentElement.clientHeight - 50) * 0.8 + "px",
        };
      }
      return {
        minWidth: (document.body.clientHeight - 50) * 0.867 + "px",
        minHeight: (document.body.clientHeight - 50) * 0.8 + "px",
      };
    },
    //切换步骤
    changeStep(val, mark) {
      if (mark) {
        this.step = this.stepList[1].step;
      } else {
        if (val <= this.maxStep) {
          this.step = val;
        }
      }
    },
    getStyle() {
      // 背景样式
      let backgroundType = this.transData.backgroundStyle.backgroundType;
      let typeDetail = this.transData.backgroundStyle.typeDetail;
      switch (backgroundType) {
        case "pure":
          this.backgroundStyle = "background:" + typeDetail;
          break;
        case "gradient":
          this.backgroundStyle =
            "background:linear-gradient(" +
            typeDetail[0] +
            "," +
            typeDetail[1] +
            ");";
          break;
        case "img":
          this.backgroundStyle =
            "background:url(" + typeDetail + ");background-size:cover;";
          break;
      }
      if (
        this.transData.backgroundStyle.backgroundType == "pure" &&
        this.transData.backgroundStyle.typeDetail &&
        this.transData.backgroundStyle.typeDetail.split("#")[1].toLowerCase() ==
          "3399cc"
      ) {
        this.onG = true;
      } else {
        this.onG = false;
      }
      let title = this.transData.title;
      this.titleText = title.text;
      this.titleStyle =
        "font-size:" +
        title.fontSize +
        ";color:" +
        title.color +
        ";font-weight:" +
        (title.bold ? "bold;" : "normal;");
      this.buttonStyle =
        "background: " +
        this.transData.button.backgroundColor +
        "!important;color:" +
        this.transData.button.fontColor +
        "!important;border:0px;";
    },
    //获取考试信息
    getExamInfoFn() {
      this.stepList = [];
      this.title = this.examData.exam_name;
      this.inform = this.examData.exam_text.split("\n").join("<br>");
      if (!this.inform) {
        this.step = 0;
      }
      if (
        !this.inform &&
        (this.examData.face_recognition == 2 ||
          this.examData.photograph == 2 ||
          this.examData.prevent_chead == 2)
      ) {
        this.step = 1;
      }
      if (
        !this.inform &&
        this.examData.face_recognition == 1 &&
        this.examData.photograph == 1 &&
        this.examData.prevent_chead == 1
      ) {
        this.step = 2;
      }

      if (this.inform) {
        let str =
          this.examData.projectType == 1
            ? "考试"
            : this.examData.projectType == 2
            ? "调查"
            : this.examData.projectType == 3
            ? "评估"
            : this.examData.projectType == 4
            ? "报名"
            : "投票";
        this.stepList.push({ title: str + "须知", step: 0 });
      }
      if (
        this.examData.face_recognition == 2 ||
        this.examData.photograph == 2 ||
        this.examData.prevent_chead == 2
      ) {
        // this.stepList.push({title:'环境检测',step:1})
        if (
          this.examData.photograph == 2 ||
          this.examData.face_recognition == 2
        ) {
          this.activelist.wl = false;
          this.stepList.push({ title: "网络检测", step: 1 });
        }
        if (
          this.examData.photograph == 2 ||
          this.examData.face_recognition == 2
        ) {
          this.activelist.sx = false;
          this.stepList.push({ title: "摄像头检测", step: 2 });
        }
        if (this.examData.prevent_chead == 2) {
          this.activelist.zb = false;
          this.stepList.push({ title: "防作弊检测", step: 3 });
        }
      }
      // if(this.examData.face_recognition==2||this.examData.photograph==2||this.examData.prevent_chead==2){
      //   this.stepList.push({title:'环境检测',step:1})
      //   if(this.examData.face_recognition==2){this.activelist.wl=false}
      //   if(this.examData.photograph==2){this.activelist.sx=false}
      //   if(this.examData.prevent_chead==2){this.activelist.zb=false}
      // }
      if (this.examData.login_rule && this.examData.login_rule != "0") {
        this.stepList.push({ title: "欢迎登录", step: 5 });
      }
      if (this.stepList.length) {
        this.step = this.stepList[0].step;
      }
    },
    goCheck(i) {
      console.log(i);
      return false;
      // if(i==1){//检测网络
      //   this.hardwareCheck.isNetwork=3;
      //   let zdws=100;
      //   var csws=navigator.connection.downlink* 1024 /8;
      //   if(csws>=zdws){
      //     this.hardwareCheck.networkResult=[
      //       {label:'检测网速',result:csws+' kb/s'},
      //       {label:'推荐网速',result:'>'+zdws+' kb/s'},
      //       {label:'网络状态',result:'正常'},
      //       {label:'推荐方案',result:'无'}
      //     ];
      //     this.hardwareCheck.isNetwork=1;
      //     this.activelist.wl=true;
      //     this.getNextCheck()
      //     // this.checkAllYesFn()
      //   }else{
      //     this.hardwareCheck.networkResult=[
      //       {label:'检测网速',result:csws+' kb/s'},
      //       {label:'推荐网速',result:'>'+zdws+' kb/s'},
      //       {label:'网络状态',result:'网络环境不稳定'},
      //       {label:'推荐方案',result:'更换网络连接'}
      //     ];
      //     this.hardwareCheck.isNetwork=2;
      //   }
      // }else if(i==2){//检测摄像头
      //   this.$refs.webcam.getCompetence();
      // }else if(i==3){//检测防作弊插件
      //   let oJson = { "function": "GetSerial", "id": 0 };
      //   let data=JSON.stringify(oJson)
      //   let that = this;
      //   that.$http.jsonp('http://127.0.0.1:17681',{params : { json:data,callback:'jsonpcallback'},jsonp:'jsonpcallback'}).then(res=>{
      //     console.log(res)
      //   }).catch((err)=>{
      //     if(err.status!=0){that.hardwareCheck.isPOC=2}
      //   });
      // }
    },
    //检测防作弊
    checkFzbFn(res) {
      if (res[0].ret) {
        let flag = res[0].FindCard;
        if (flag == 1) {
          if (!this.$utils.asktrue.isSupportFullScreenFn()) {
            //是否支持全屏
            this.$message.error(
              "该浏览器版本不支持全屏考试，请换高版本的浏览器进行考试！"
            );
            this.hardwareCheck.isPOC = 2;
            return false; //检测失败;
          } else {
            this.hardwareCheck.isPOC = 1;
            this.getNextCheck();
            // this.checkAllYesFn()
          }
        } else {
          this.hardwareCheck.isPOC = 2;
        }
      } else {
        this.hardwareCheck.isPOC = 2;
      }
    },

    //视频监测返回数据
    videoBack(yn) {
      if (yn) {
        this.hardwareCheck.isWebcam = 1;
        this.getNextCheck();
        // this.checkAllYesFn()
      } else {
        this.hardwareCheck.isWebcam = 2;
      }
    },
    // getNextCheck(){
    //   for(var prop in this.activelist){
    //     if(this.activelist[prop]==false){
    //       this.activeTab=prop;
    //       return;
    //     }
    //   }
    // },
    getNextCheck() {
      let that = this;
      for (var prop in this.activelist) {
        if (this.activelist[prop] == false) {
          this.waitingTime = 3;
          var timer = setInterval(() => {
            if (that.waitingTime != 0) {
              that.waitingTime--;
            } else {
              prop == "wl"
                ? (this.step = 1)
                : prop == "sx"
                ? (this.step = 2)
                : (this.step = 3);
              console.log(this.step, prop);
              this.activeTab = prop;
              this.checkAllYesFn();
              that.waitingTime--;
              clearInterval(timer);
            }
          }, 1000);
          return;
        }
      }
      this.waitingTime = 3;
      var timer1 = setInterval(() => {
        if (that.waitingTime != 0) {
          that.waitingTime--;
        } else {
          this.checkAllYesFn();
          that.waitingTime--;
          clearInterval(timer1);
        }
      }, 1000);
    },
    //检测是否全部通过
    checkAllYesFn() {
      if (
        this.examData &&
        this.examData.face_recognition == 1 &&
        this.examData.photograph == 1
      ) {
        this.hardwareCheck.isWebcam = 1;
      }
      if (this.examData && this.examData.prevent_chead == 1) {
        this.hardwareCheck.isPOC = 1;
      }

      if (
        this.hardwareCheck.isNetwork == 1 &&
        this.hardwareCheck.isWebcam == 1 &&
        this.hardwareCheck.isPOC == 1
      ) {
        this.step = 4;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pop-body {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    padding: 20px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    width: 320px;
    margin: -70px auto 0;
    border-radius: 3px;
    color: #666;
  }
}
.exam-background {
  height: 100%;
  overflow: hidden;
  background: #fff;
  position: relative;
  .topBg {
    width: 100%;
    height: 45%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    img {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
  }
  .top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .logo {
      max-height: 60px;
      border-radius: 10px;
      overflow: hidden;
      width: fit-content;
      margin-right: 10px;
      flex-shrink: 0;
      img {
        max-height: 60px;
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #555;
      line-height: 36px;
      flex-shrink: 1;
    }
  }
  .main-box {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 640px;
    height: 435px;
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 2;

    .step-show {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      margin-top: 60px;
      height: 30px;
      padding: 0 70px;
      .back-border1 {
        flex-grow: 0;
        flex-shrink: 0;
        width: 140px;
        border-top: 1px dashed #aaa;
      }
      .back-border05 {
        flex-grow: 1;
        border-top: 1px dashed #aaa;
      }
      .border-on {
        border-color: #3399cc;
      }
      .border-on-g {
        border-color: rgb(5, 117, 173);
      }
      .el-icon-arrow-right {
        color: #39c;
        padding-top: 4px;
      }
      .sep {
        width: 14px;
      }
      .top-info-item {
        flex-grow: 0;
        position: relative;
        margin-top: -10px;
        color: #aaa;
        border-color: #aaa;
        .info {
          font-size: 12px;
          padding: 0 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          max-width: 40px;
          i {
            display: block;
            border: 1px solid;
            width: 22px;
            height: 22px;
            border-radius: 15px;
            line-height: 20px;
            margin-bottom: 6px;
          }
          .name {
            display: block;
            word-break: keep-all;
          }
        }
        &.on {
          position: relative;
          border-color: #3399cc;
          color: #39c;
        }
        &.active {
          font-size: 14px;
          color: #f60;
          border-color: #f60;
        }
      }
    }

    .main {
      flex: 1;
      margin: 0px auto;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 25px 70px 0px;
      background-color: #fff;
      &.main0 {
        .plate .border-box {
          min-height: 300px;
          padding: 40px 50px 50px;
        }
      }
      &.main1,
      &.main2,
      &.main3 {
        .plate {
          justify-content: space-between;
        }
      }
      &.main4 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /deep/ .el-input__inner {
          height: 45px;
        }
      }
      &.main5 {
        padding: 0px;
        .plate {
          flex-direction: column;
          justify-content: center;
          .border-box {
            flex: none;
          }
        }
        /deep/ .el-input {
          .el-input__inner {
            height: 45px;
            border-radius: 23px;
            background-color: rgba(255, 255, 255, 0);
            padding: 0 20px 0 50px;
          }
          .el-input__prefix {
            left: 15px;
            font-size: 18px;
            .el-input__icon {
              line-height: 45px;
            }
          }
        }
        .logBtn {
          padding: 0px;
          width: 360px;
          height: 45px;
          border-radius: 23px;
        }
      }
      .plate {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        h2 {
          font-size: 15px;
          margin-bottom: 5px;
          position: relative;
          padding-left: 10px;
          color: #444;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0px;
            margin-top: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #444;
          }
        }

        .border-box {
          flex: 1;
          position: relative;
          min-height: 120px;
          border-radius: 5px;
          span.title {
            display: inline-block;
            margin-bottom: 10px;
            width: 80px;
            margin-left: 10px;
            font-size: 16px;
            margin-left: 126px;
            color: #666;
            position: relative;
            &.hav-after::before {
              content: "";
              position: absolute;
              top: 3px;
              left: -10px;
              width: 4px;
              height: 16px;
              border-radius: 5px;
              background-color: #39c;
            }
          }
          &.kqxz {
            width: 100%;
            height: 100%;
            padding-bottom: 40px;
            position: relative;
            padding: 0px 0px 40px 0;
            .inform {
              line-height: 180%;
              width: 100%;
              height: 100%;
              overflow: hidden;
              overflow-y: auto;
              border: 1px solid #e4e4e4;
              padding: 20px;
              border-radius: 8px;
            }
          }
        }

        /deep/ .hardwareCheck {
          width: 100%;
          // .el-tabs__content{
          .testing {
            display: flex;
            justify-content: space-between;
            .left-div {
              width: 35%;
              text-align: center;
              margin-right: 5%;
              padding-top: 20px;
              img {
                width: 60px;
                height: 60px;
              }
              p {
                line-height: 180%;
                color: #888;
              }
              .start-test {
                width: 100px;
                text-align: center;
                line-height: 30px;
                border-radius: 2px;
                background-color: #f60;
                color: #fff;
                cursor: pointer;
                font-size: 12px;
              }
              .common_status {
                height: 26px;
                div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.s1 {
                    color: #06b300;
                  }
                  &.s2 {
                    color: #f60;
                  }
                  &.s3 {
                    color: #000;
                  }
                  em {
                    font-size: 26px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                  span {
                    font-size: 15px;
                    font-weight: 700;
                    vertical-align: middle;
                  }
                }
              }
            }
            .right-div {
              width: 60%;
              p {
                line-height: 180%;
              }
            }

            .test_speed {
              width: 100%;
              margin: 0px auto 20px;
              a i {
                display: inline-block;
                width: 16px;
                text-align: center;
                height: 16px;
                line-height: 15px;
                margin-left: 3px;
                font-size: 12px;
              }
            }
            .web_speed {
              width: 100%;
              border: 1px solid #e5ebf0;
              margin: 0 auto;
              border-collapse: collapse;
            }
            .web_speed td {
              width: 50%;
              height: 48px;
              text-align: center;
              border-bottom: 1px solid #e5ebf0;
            }
            .web_speed .reco {
              color: #5bbcee;
            }
          }
          // }
        }

        /deep/ .login {
          .el-input {
            width: 360px;
            display: flex;
            margin: 30px auto 0;
            // input{border:0;border-bottom:1px solid#e4e4e4;}
          }
          .code-div {
            .el-input__inner {
              padding-right: 100px;
            }
            .getcodebtn {
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              color: #999;
              position: relative;
              padding-left: 20px;
              em {
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 16px;
                background-color: #e4e4e4;
              }
            }
          }
        }
      }
      .check-two {
        padding: 30px 40px !important;
        .qr-code {
          width: 160px;
          height: 160px;
          padding: 5px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          img {
            width: 100%;
          }
          .checked {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            background: rgba(255, 255, 255, 0.9);
            color: #06b300;
            font-size: 18px;
            i {
              color: #06b300;
              margin-top: 44px;
              display: inline-block;
              font-size: 32px;
            }
          }
          &.yes .checked {
            display: block;
          }
        }
      }
    }
    &.mainMore {
      width: 850px;
      height: 500px;
      .main {
        height: 50%;
      }
    }
  }
  .foot {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #666;
    z-index: 1;
  }

  /deep/ .doc-pop {
    .el-drawer__body {
      padding: 30px 20px 20px;
      text-align: left;
      .el-image {
        height: unset;
      }
      img {
        width: 100%;
        cursor: pointer; //max-height: 190px;
        &.el-image-viewer__img {
          width: auto;
          max-height: auto;
        }
      }
    }
  }
}
</style>

export default function (axios) {
  return {
    selectDiary(params) {
      //查询日志列表
      return axios.get("/cDiary/selectPage", { params });
    },
    editDiary(params) {
      //编辑或新增日志
      return axios.post("/cDiary/editDiary", params);
    },
    delDiary(params) {
      //删除日志
      return axios.post("/cDiary/deleteDiary?diaryIds=" + params.diaryIds);
    },
    // 日志上一个/下一个
    nextDiary(params) {
      return axios.post("/cDiary/selectPreOrNextLog", params);
    },
    addReadRecord(params) {
      //新增访问记录 查询日志详情前需要调用此接口
      return axios.post(
        "/cDiary/addReadRecord?diaryId=" +
          params.diaryId +
          "&" +
          "memberId=" +
          params.memberId
      );
    },
    selectByPrimaryKey(params) {
      //根据主键 查询一条详细的日志记录 同时增加访问记录
      return axios.post("/cDiary/selectByPrimaryKey?diaryId=" + params.diaryId);
    },
    // uploadDiary(params) {
    //   //日志上传附件
    //   return axios.upload("/file/uploadCover?fileDirType=diary_file", params);
    // },

    addComment(menu, params) {
      //新增评论，此方法为公共评论方法，通过栏目menu判断读取接口
      if (menu == "diary") {
        // 日志
        return axios.post("/cDiary/addComment", params);
      } else if (menu == "notice") {
        // 公告
        return axios.post("/cNotice/addNoticeComment", params);
      }
    },
    addCommentReply(menu, params) {
      //从评论那里新增评论，此方法为公共评论方法，通过栏目menu判断读取接口
      if (menu == "diary") {
        // 日志
        return axios.post("/cDiary/addCommentReply", params);
      } else if (menu == "notice") {
        // 公告
        return axios.post("cNotice/addCommentReply", params);
      }
    },
  };
}

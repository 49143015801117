/*
 * @Description: 系统消息查询  C News Remind Ctl
 * @Date: 2024-03-18 16:11:14
 * @LastEditTime: 2024-03-18 19:05:55
 */

export default function (axios) {
  return {
    // /cNewsRemind/selectNewsRemind 查询消息列表
    selectNewsRemind() {
      return axios.get('/cNewsRemind/selectNewsRemind');
    },
    // /cNewsRemind/selectNewsRemindByType 查询指定系统消息列表
    selectNewsRemindByType(params) {
      return axios.get('/cNewsRemind/selectNewsRemindByType', {
        params,
      });
    },

    // /cNewsRemind/updateIsReadNews  对系统消息进行已读
    updateIsReadNews(params) {
      return axios.post('/cNewsRemind/updateIsReadNews', {},
      {
        params,
      });
    },
    // /cNewsRemind/deleteNewsRemindById 删除系统消息
    deleteNewsRemindById(params) {
      return axios.post('/cNewsRemind/deleteNewsRemindById', {},
      {
        params,
      });
    },

    // /cNewsRemind/selectNoReadNewsCount  查询所有的未读消息数量
    selectNoReadNewsCount(params) {
      return axios.get('/cNewsRemind/selectNoReadNewsCount', {
        params,
      });
    },
  };
}

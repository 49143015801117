<!-- 新增/编辑课件（章节） -->
<template>
  <div class="add-chapter">
    <span @click="clickFn">
      <slot>
        <el-button type="primary">
          <i class="iconfont iconjia"></i>
          <span>添加课件</span>
        </el-button>
      </slot>
    </span>

    <!-- 新增弹窗 -->
    <el-drawer :visible.sync="show" size="600px">
      <el-form ref="myform" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="课件名称：" prop="coursewareName">
          <el-input
            v-model="form.coursewareName"
            placeholder="输入课件名称"
            class="myText"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属章节：" prop="chapterId">
          <g-selectset
            v-model="form.chapterId"
            :options="other.chapterList"
            placeholder="选择课程章节"
            class="mySelect"
            @save="zhangSaveFn"
            @del="zhangDelFn"
          />
        </el-form-item>
        <el-form-item label="讲师：" prop="lecturerId">
          <el-select
            v-model="form.lecturerId"
            placeholder="选择讲师"
            @change="lecturerFn"
            class="myText wfull"
          >
            <el-option
              v-for="item in lecturerInfo ? JSON.parse(lecturerInfo) : []"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传课件：" prop="coursewareUrl">
          <div class="uploadBox">
            <g-uploadfile v-model="form.coursewareUrl" :prop="uploadProp" />
          </div>
        </el-form-item>
        <el-form-item label="课后练习：">
          <PracticePop v-model="form.paperId" className="mySelect" />
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" class="savebtn" @click="saveFn(1)"
            >保存并新增</el-button
          >
          <el-button type="primary" class="savebtn" @click="saveFn(2)"
            >保存并退出</el-button
          >
          <el-button class="savebtn" @click="closeFn">退出</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import PracticePop from "./PracticePop.vue";
const defaultForm = {
  coursewareId: null, //	integer	课件自增主键 新增不传/修改传
  coursewareName: null, //	string	课件名称
  chapterId: null, //	integer	章节id 新增传
  coursewareUrl: null, //	string	课件地址
  courseId: null, //	integer	课程id 新增传
  paperId: null, //练习id
  lecturerId: null, //讲师id
  lecturerInfo: "", //讲师json
};
export default {
  components: { PracticePop },
  props: {
    courseId: [Number, String], //课程id
    coursewareId: [Number, String], //课件id
    lecturerInfo: String, //讲师ids，JSON字符串
  },
  data() {
    return {
      show: false,

      form: JSON.parse(JSON.stringify(defaultForm)),
      rules: {
        coursewareName: [
          { required: true, message: "输入课件名称", trigger: "blur" },
        ],
        chapterId: [
          { required: true, message: "选择所属章节", trigger: "blur" },
        ],
        coursewareUrl: [
          { required: true, message: "上传课件视频", trigger: "blur" },
        ],
      },

      other: {
        chapterList: [], //章选项
        lecturerInfo: [], //讲师ids，数组
        lecturerList: [], //讲师选项
        practiceList: [], //练习选项
      },

      // 上传组件传参
      uploadProp: {
        fileDirType: "video_file", //图片上传路径参数
        limit: 1, //最大上传个数
        buttonName: "点击上传", //控件上传按钮/文本文字
        styleType: "single", //控件样式类型：button按钮样式（默认）；font文本样式；single单个文件模式（替换新增）
        style: "width:200px;height:110px;", //控件样式
        field: {
          // 附件返回字段
          id: "attachId",
          name: "attachName",
          url: "attachUrl",
        },
      },
    };
  },
  watch: {
    show: {
      handler(v) {
        if (!v) {
          this.form = JSON.parse(JSON.stringify(defaultForm));
        }
      },
    },
    "form.coursewareUrl": {
      handler(v) {
        if (v) {
          let i = v.indexOf("/video/study");
          let val = v.substring(i, v.length);
          this.form.strUrl = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    clickFn() {
      this.getChapterListFn();
      if (this.coursewareId) {
        this.getDataFn();
      }
      this.show = true;
    },
    // 获取章列表
    getChapterListFn() {
      let ps = {
        courseId: this.courseId, //	integer	课程Id
        orderBy: null, //	string	排序规则
        pageNum: 1, //	string	当前页码
        pageSize: 100, //	string	每页条数
      };
      this.$api.kc_chapterListApi(ps).then((res) => {
        if (res.code == "200") {
          this.other.chapterList = res.data.result.list.map((e) => {
            return { label: e.chapterName, value: e.chapterId };
          });
        }
      });
    },
    // 选择讲师
    lecturerFn() {
      let arr = JSON.parse(this.lecturerInfo);
      let item = arr.find((e) => e.userId == this.form.lecturerId);
      this.form.lecturerInfo = JSON.stringify(item);
    },

    // 获取章节数据
    getDataFn() {
      let ps = {
        coursewareId: this.coursewareId,
      };
      this.$api.kc_selectByIdApi(ps).then((res) => {
        if (res.code == "200") {
          this.$set(this, "form", res.data.result);
          console.log(this.form, "this.formmmm");
          // 讲师处理
          if (this.form.lecturerId) {
            this.other.lecturerId = JSON.parse(this.form.lecturerId).userId;
          }
        }
      });
    },
    // 保存章
    zhangSaveFn(e) {
      console.log(e, "=eeee");
      let ps = {
        chapterName: e.name, //	string	章节名称
        courseId: null, //	integer	课程id 新增传
        parentId: -1, //	integer	父级id，没有则为-1
      };
      if (e.id > 0) {
        ps.chapterId = e.id; //章节自增主键 新增不传/修改传
      } else {
        ps.courseId = this.courseId; //课程id 新增传
      }
      this.$api.kc_editChapterApi(ps).then((res) => {
        if (res.code == "200") {
          this.getChapterListFn();
        }
      });
    },
    // 删除章
    zhangDelFn(id) {
      let ps = { chapterId: id };
      this.$api.kc_deleteChapterApi(ps).then((res) => {
        if (res.code == "200") {
          this.$message.success("删除成功");
          this.getChapterListFn();
        }
      });
    },
    // 保存课件
    saveFn(v) {
      this.$refs.myform.validate((valid) => {
        if (valid) {
          let ps = JSON.parse(JSON.stringify(this.form));
          if (this.coursewareId) {
            ps.coursewareId = this.coursewareId;
          }
          ps.courseId = this.courseId;
          this.$api.kc_editCoursewareApi(ps).then((res) => {
            if (res.code == "200") {
              this.$message("保存成功");
              if (v == 2) {
                this.show = false;
                this.$emit("refresh");
              }
              this.form = JSON.parse(JSON.stringify(defaultForm));
            }
          });
        }
      });
    },

    closeFn() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  line-height: 36px;
}
/deep/.el-form-item__content {
  line-height: 36px;
  .el-input__inner {
    display: block;
  }
}
.myText {
  height: 36px;
  // width: 100%;
}
.mySelect {
  height: 36px;
  border: 1px solid #e4e4e4;
  /deep/.title {
    padding: 0 10px;
  }
}

.uploadBox {
  display: flex;
  align-items: flex-end;
  gap: 40px;
  .tips {
    font-size: 12px;
    color: #f60;
  }
}
.savebtn {
  width: 90px;
  height: 36px;
}
.wfull {
  width: 100%;
}
</style>

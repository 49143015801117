const utf8 = { ContentType: "application/json;charset=UTF-8" };
export default function (axios) {
  return {
    //新增招聘会
    addJobFairs(params1) {
      return axios.post("/jobFairs/addJobFairs", params1);
    },
    //  企业报名参加招聘会
    addJobFairsComp(params) {
      return axios.post("/jobFairs/addJobFairsComp", params, utf8);
    },
    // 个人报名参加招聘会
    addJobFairsUser(params) {
      return axios.post("/jobFairs/addJobFairsUser", params, utf8);
    },
    //  查询参会公司详情
    selectCompDetail(params) {
      return axios.get("/jobFairs/selectCompDetail", { params });
    },
    //  查询我创建的招聘会下面参加的公司
    selectFairsComp(params) {
      return axios.get("/jobFairs/selectFairsComp", { params });
    },
    //  查询所有的招聘职位
    selectFairsJob(params) {
      return axios.get("/jobFairs/selectFairsJob", { params });
    },
    //  分页查询我创建的招聘会
    selectJobFairs(params) {
      return axios.get("/jobFairs/selectJobFairs", { params });
    },
    //  对报名参加的公司进行审核
    updateAuditStatus(params) {
      return axios.post("/jobFairs/updateAuditStatus", params);
    },
    //  公司查询我报名的招聘会
    selectMyJobFairs(params) {
      return axios.get("/jobFairs/selectMyJobFairs", { params });
    },
    //  查询我创建的————报名个人列表
    selectJobFairsUserPage(params) {
      return axios.get("/jobFairs/selectJobFairsUserPage", { params });
    },
    //  前台页面展示所有招聘会
    selectAllFairsNose(params) {
      return axios.get("/jobFairs/selectAllFairsNose", { params });
    },
    //  个人对具体职位进行投递简历
    addJobFairsUserResume(params) {
      return axios.post("/jobFairs/addJobFairsUserResume", params);
    },
    // 查询我参加的招聘会里面的职位
    selectMyJobFairsJob(params) {
      return axios.get("/jobFairs/selectMyJobFairsJob", { params });
    },
    //  查询我参加的招聘会里面的我收到的简历或所有的报名者
    selectMyJobFairsResume(params) {
      return axios.get("/jobFairs/selectMyJobFairsResume", { params });
    },
    //  控制台查询招聘会详情
    selectJobFairsDetail(params) {
      return axios.get("/jobFairs/selectJobFairsDetail", { params });
    },
    //  前台展示招聘会详情
    selectFairsDetailNose(params) {
      return axios.get("/jobFairs/selectFairsDetailNose", { params });
    },
    // 我报名的 添加职位 到 报名职位
    addJobFairsJob(params) {
      return axios.post("/jobFairs/addJobFairsJob", params, utf8);
    },
    //  对我报名的职位进行上线下线操作
    editJobFairsJob(params) {
      return axios.post("/jobFairs/editJobFairsJob", params);
    },
    //  移除已经添加到报名的职位
    removeJobFairsJob(params) {
      return axios.post("/jobFairs/removeJobFairsJob", params);
    },
    //  前台查询该招聘会下面所有的职位
    selectJobFairsAllJobNose(params) {
      return axios.get("/jobFairs/selectJobFairsAllJobNose", { params });
    },

    //  前台查询该招聘会下面所有的职位
    selectJobFairsJobDetailNose(params) {
      return axios.get("/jobFairs/selectJobFairsJobDetailNose", { params });
    },

    //  前台查询公司审核状态
    selectCompAuditStatusNoseApi(params) {
      return axios.get("/jobFairs/selectCompAuditStatusNose", { params });
    },
    //  我创建的招聘会 招聘职位的筛选条件的查询
    selectScreenConditionNoseApi(params) {
      return axios.post(
        `/jobFairs/selectScreenConditionNose?type=${params.type}&fairsId=${params.fairsId}`,
        utf8
      );
    },

    //  个人登录之后 查看我报名的双选会
    selectUserFairsJobApi(params) {
      return axios.get(
        `/jobFairs/selectUserFairsJob?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
      );
    },

    //  前台判断个人是否已经参加招聘会
    selectUserIsAddFairsApi(params) {
      return axios.get("	/jobFairs/selectUserIsAddFairs", { params });
    },

    //  移除已经添加到报名的职位
    selectAllNoticeListNoseApi(params1, params) {
      return axios.post(
        "/notice/selectAllNoticeListNose" + params1,
        params,
        utf8
      );
    },

    //  查询个人参加过的报名项目
    selectMyAddNoticeApi(params) {
      return axios.get(
        `/notice/selectMyAddNotice?pageNum=${params.pageNum}&pageSize=${params.pageSize}&phone=${params.phone}`
      );
    },

    //  查询个人首页的各种数量
    selectCountForUserApi(params) {
      return axios.get(`/jobFairs/selectCountForUser?phone=${params.phone}`);
    },
  };
}

export default {
  parent: "CrmClient",
  routers: [
    {
      // 待跟进
      path: "wait",
      name: "CrmClientWait",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 跟进中
      path: "ing",
      name: "CrmClientIng",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 商谈中
      path: "deep",
      name: "CrmClientDeep",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 已签约
      path: "success",
      name: "CrmClientSuccess",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 不合作
      path: "loss",
      name: "CrmClientLoss",
      component: () => import("@/views/crm/client/clientList.vue"),
    },
    {
      // 邀请
      path: "invite",
      name: "CrmClientInvite",
      component: () => import("@/views/crm/client/clientInvite.vue"),
    },
    {
      // 搜索
      path: "sea",
      name: "CrmClientSea",
      component: () => import("@/views/crm/client/clientSea.vue"),
    },
  ],
};

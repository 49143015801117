// interviewRoom Store
export default {
  namespaced: true,
  local: true,
  state: {
    RoomState: {
      localUser: {
        userId: "",
        userName: "",
        userAvatar: "",
        userType: "", // interviewer 面试官  interviewee 面试者
        mainStreamInfo: {
          audioVolume: 0,
          isAudioStreamAvailable: false,
          isVideoStreamAvailable: false,
          type: "main",
        },
        screenStreamInfo: {},
        isAudioMutedByMaster: false,
        isVideoMutedByMaster: false,
      },
      remoteUserObj: {},
      isDefaultOpenCamera: false,
      isDefaultOpenMicrophone: false,
      isLocalAudioMuted: false,
      isLocalVideoMuted: false,
      hasStartedCamera: false,
      hasStartedMicrophone: false,
      currentCameraId: "",
      currentMicrophoneId: "",
      currentSpeakerId: "",
      cameraList: [],
      microphoneList: [],
      speakerList: [],
    },
  },
  mutations: {
    setLocalUser(state, payload) {
      Object.assign(state.RoomState.localUser, payload.Object);
    },
    updateLocalStream(state, payload) {
      Object.assign(state.RoomState.localUser.mainStreamInfo, payload.stream);
    },
    localStream(state) {
      const { userId, userName, mainStreamInfo } = state.RoomState.localUser;
      return { userId, userName, ...mainStreamInfo };
    },
    // 远端用户进房间
    addRemoteUser(state, userInfo) {
      const { userId, name, type } = userInfo;
      if (
        !userId ||
        userId === state.RoomState.localUser.userId ||
        userId === `share_${state.RoomState.localUser.userId}`
      ) {
        return;
      }
      const newUser = {
        userId,
        userName: name || "",
        // userAvatar: avatar || '',
        userType: type,
        mainStreamInfo: null,
        screenStreamInfo: null,
        // role: ETUIRoomRole.AUDIENCE,
      };
      // 本端为主持人，则记录用户禁言禁画, 申请发言等信息

      // Object.assign(newUser, {
      //   isAudioMutedByMaster: basicStore.isMuteAllAudio,
      //   isVideoMutedByMaster: basicStore.isMuteAllVideo,
      //   isChatMutedByMaster: false,
      //   isUserApplyingToAnchor: false,
      //   isInvitingUserToAnchor: false,
      // });
      if (this.$set) {
        // 兼容 Vue 2.7 给对象添加新的 property
        this.$set(state.RoomState.remoteUserObj, userId, newUser);
      } else {
        state.RoomState.remoteUserObj[userId] = newUser;
      }
      state.RoomState.remoteUserObj = Object.assign(
        {},
        state.RoomState.remoteUserObj
      );
    },
    updateRemoteAudioStream(state, eventInfo) {
      const { userId, available } = eventInfo;
      const remoteUserInfo = state.RoomState.remoteUserObj[userId];
      if (!remoteUserInfo) {
        return;
      }
      const streamInfo = remoteUserInfo.mainStreamInfo || { type: "main" };
      remoteUserInfo.mainStreamInfo = Object.assign(streamInfo, {
        isAudioStreamAvailable: !!available,
      });
      // state.RoomState.remoteUserObj = Object.assign({}, state.RoomState.remoteUserObj);
    },

    updateRemoteVideoStream(state, eventInfo) {
      const { userId, available } = eventInfo;
      const remoteUserInfo = state.RoomState.remoteUserObj[userId];
      if (!remoteUserInfo) {
        return;
      }
      const streamInfo = remoteUserInfo.mainStreamInfo || { type: "main" };
      remoteUserInfo.mainStreamInfo = Object.assign(streamInfo, {
        isVideoStreamAvailable: !!available,
      });
      // state.RoomState.remoteUserObj = Object.assign({}, state.RoomState.remoteUserObj);
    },

    setAudioVolume(state, audioVolumeArray) {
      audioVolumeArray.forEach((audioVolumeItem) => {
        const { userId, audioVolume } = audioVolumeItem;
        if (
          (userId === state.RoomState.localUser.userId || userId === "local") &&
          state.RoomState.localUser.mainStreamInfo
        ) {
          state.RoomState.localUser.mainStreamInfo.audioVolume = audioVolume;
        } else {
          const remoteUserInfo = state.RoomState.remoteUserObj[userId];
          if (remoteUserInfo && remoteUserInfo.mainStreamInfo) {
            remoteUserInfo.mainStreamInfo.audioVolume = audioVolume;
          }
        }
      });
    },

    removeRemoteUser(state, userId) {
      if (!userId || userId === state.RoomState.localUser.userId) {
        return;
      }
      delete state.RoomState.remoteUserObj[userId];
      state.RoomState.remoteUserObj = Object.assign(
        {},
        state.RoomState.remoteUserObj
      );
    },
    removeLocalUser(state) {
      state.RoomState.localUser = null;
    },
    setMuteAllAudio(state, muted) {
      Object.values(state.RoomState.remoteUserObj).forEach((remoteUserInfo) => {
        Object.assign(remoteUserInfo, { isAudioMutedByMaster: muted });
      });
      state.RoomState.remoteUserObj = Object.assign(
        {},
        state.RoomState.remoteUserObj
      );
    },
  },
  actions: {
    updateLocalStream(context, payload) {
      context.commit("updateLocalStream", payload);
    },
    localStream(context) {
      context.commit("localStream");
    },
    addRemoteUser(context, payload) {
      context.commit("addRemoteUser", payload.userInfo);
    },
    updateRemoteVideoStream(context, payload) {
      context.commit("updateRemoteVideoStream", payload.eventInfo);
    },
    updateRemoteAudioStream(context, payload) {
      context.commit("updateRemoteAudioStream", payload.eventInfo);
    },
    setAudioVolume(context, payload) {
      context.commit("setAudioVolume", payload.audioVolumeArray);
    },
    removeRemoteUser(context, payload) {
      context.commit("removeRemoteUser", payload.userId);
    },
    setMuteAllAudio(context, payload) {
      context.commit("setMuteAllAudio", payload.muted);
    },
  },
};
// const state = {
//   RoomState: {
//     localUser: {
//       userId: "",
//       userName: "",
//       userAvatar: "",
//       userType: "", // interviewer 面试官  interviewee 面试者
//       mainStreamInfo: {
//         audioVolume: 0,
//         isAudioStreamAvailable: false,
//         isVideoStreamAvailable: false,
//         type: "main",
//       },
//       screenStreamInfo: {},
//       isAudioMutedByMaster: false,
//       isVideoMutedByMaster: false,
//     },
//     remoteUserObj: {},
//     isDefaultOpenCamera: false,
//     isDefaultOpenMicrophone: false,
//     isLocalAudioMuted: false,
//     isLocalVideoMuted: false,
//     hasStartedCamera: false,
//     hasStartedMicrophone: false,
//     currentCameraId: "",
//     currentMicrophoneId: "",
//     currentSpeakerId: "",
//     cameraList: [],
//     microphoneList: [],
//     speakerList: [],
//   },
// };

// const mutations = {
//   setLocalUser(state, payload) {
//     console.log(
//       "触发触发触发触发触发触发触发触发触发触发触发触发触发触发触发触发触发触发"
//     );
//     Object.assign(state.RoomState.localUser, payload.Object);
//   },
//   updateLocalStream(state, payload) {
//     Object.assign(state.RoomState.localUser.mainStreamInfo, payload.stream);
//   },
//   localStream(state) {
//     const { userId, userName, mainStreamInfo } = state.RoomState.localUser;
//     return { userId, userName, ...mainStreamInfo };
//   },
//   // 远端用户进房间
//   addRemoteUser(state, userInfo) {
//     const { userId, name, type } = userInfo;
//     if (
//       !userId ||
//       userId === state.RoomState.localUser.userId ||
//       userId === `share_${state.RoomState.localUser.userId}`
//     ) {
//       return;
//     }
//     const newUser = {
//       userId,
//       userName: name || "",
//       // userAvatar: avatar || '',
//       userType: type,
//       mainStreamInfo: null,
//       screenStreamInfo: null,
//       // role: ETUIRoomRole.AUDIENCE,
//     };
//     // 本端为主持人，则记录用户禁言禁画, 申请发言等信息

//     // Object.assign(newUser, {
//     //   isAudioMutedByMaster: basicStore.isMuteAllAudio,
//     //   isVideoMutedByMaster: basicStore.isMuteAllVideo,
//     //   isChatMutedByMaster: false,
//     //   isUserApplyingToAnchor: false,
//     //   isInvitingUserToAnchor: false,
//     // });
//     if (this.$set) {
//       // 兼容 Vue 2.7 给对象添加新的 property
//       this.$set(state.RoomState.remoteUserObj, userId, newUser);
//     } else {
//       state.RoomState.remoteUserObj[userId] = newUser;
//     }
//     state.RoomState.remoteUserObj = Object.assign(
//       {},
//       state.RoomState.remoteUserObj
//     );
//   },
//   updateRemoteAudioStream(state, eventInfo) {
//     const { userId, available } = eventInfo;
//     const remoteUserInfo = state.RoomState.remoteUserObj[userId];
//     if (!remoteUserInfo) {
//       return;
//     }
//     const streamInfo = remoteUserInfo.mainStreamInfo || { type: "main" };
//     remoteUserInfo.mainStreamInfo = Object.assign(streamInfo, {
//       isAudioStreamAvailable: !!available,
//     });
//     // state.RoomState.remoteUserObj = Object.assign({}, state.RoomState.remoteUserObj);
//   },

//   updateRemoteVideoStream(state, eventInfo) {
//     const { userId, available } = eventInfo;
//     const remoteUserInfo = state.RoomState.remoteUserObj[userId];
//     if (!remoteUserInfo) {
//       return;
//     }
//     const streamInfo = remoteUserInfo.mainStreamInfo || { type: "main" };
//     remoteUserInfo.mainStreamInfo = Object.assign(streamInfo, {
//       isVideoStreamAvailable: !!available,
//     });
//     // state.RoomState.remoteUserObj = Object.assign({}, state.RoomState.remoteUserObj);
//   },

//   setAudioVolume(state, audioVolumeArray) {
//     audioVolumeArray.forEach((audioVolumeItem) => {
//       const { userId, audioVolume } = audioVolumeItem;
//       if (
//         (userId === state.RoomState.localUser.userId || userId === "local") &&
//         state.RoomState.localUser.mainStreamInfo
//       ) {
//         state.RoomState.localUser.mainStreamInfo.audioVolume = audioVolume;
//       } else {
//         const remoteUserInfo = state.RoomState.remoteUserObj[userId];
//         if (remoteUserInfo && remoteUserInfo.mainStreamInfo) {
//           remoteUserInfo.mainStreamInfo.audioVolume = audioVolume;
//         }
//       }
//     });
//   },

//   removeRemoteUser(state, userId) {
//     if (!userId || userId === state.RoomState.localUser.userId) {
//       return;
//     }
//     delete state.RoomState.remoteUserObj[userId];
//     state.RoomState.remoteUserObj = Object.assign(
//       {},
//       state.RoomState.remoteUserObj
//     );
//   },
//   removeLocalUser(state) {
//     state.RoomState.localUser = null;
//   },
//   setMuteAllAudio(state, muted) {
//     Object.values(state.RoomState.remoteUserObj).forEach((remoteUserInfo) => {
//       Object.assign(remoteUserInfo, { isAudioMutedByMaster: muted });
//     });
//     state.RoomState.remoteUserObj = Object.assign(
//       {},
//       state.RoomState.remoteUserObj
//     );
//   },
// };

// const actions = {
//   updateLocalStream(context, payload) {
//     context.commit("updateLocalStream", payload);
//   },
//   localStream(context) {
//     context.commit("localStream");
//   },
//   addRemoteUser(context, payload) {
//     context.commit("addRemoteUser", payload.userInfo);
//   },
//   updateRemoteVideoStream(context, payload) {
//     context.commit("updateRemoteVideoStream", payload.eventInfo);
//   },
//   updateRemoteAudioStream(context, payload) {
//     context.commit("updateRemoteAudioStream", payload.eventInfo);
//   },
//   setAudioVolume(context, payload) {
//     context.commit("setAudioVolume", payload.audioVolumeArray);
//   },
//   removeRemoteUser(context, payload) {
//     context.commit("removeRemoteUser", payload.userId);
//   },
//   setMuteAllAudio(context, payload) {
//     context.commit("setMuteAllAudio", payload.muted);
//   },
// };

// export default {
//   state,
//   mutations,
//   actions,
// };

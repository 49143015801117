/*
 * @Description: 问卷调查相关的页面 主要是详情页
 * @Date: 2023-11-17 16:22:39
 * @LastEditTime: 2023-11-17 17:53:11
 */

export default {
  parent: "investigationQuestionnaire",
  routers: [
    {
      //调查--问卷
      path: "Questionnaire",
      name: "ProDcQuestionnaire",
      component: () =>
        import("@/views/staff/questionnaire/detail/questionnaireView.vue"),
    },
    {
      //调查--样本（调查-不认证）
      path: "Sample",
      name: "ProDcSample",
      component: () =>
        import("@/views/staff/questionnaire/detail/sampleView.vue"),
    },
    {
      //调查--样本（调查-认证）
      path: "Sample2",
      name: "ProDcSample2",
      component: () =>
        import("@/views/staff/questionnaire/detail/sample2View.vue"),
    },
    {
      //调查--成绩
      path: "Score",
      name: "ProDcScore",
      component: () =>
        import("@/views/staff/questionnaire/detail/scoreView.vue"),
    },
    {
      //调查--数据
      path: "Data",
      name: "ProDcData",
      component: () =>
        import("@/views/staff/questionnaire/detail/dataView.vue"),
    },
    {
      //调查--统计
      path: "Statistics",
      name: "ProDcStatistics",
      component: () =>
        import("@/views/staff/questionnaire/detail/statisticsView.vue"),
    },
    {
      //调查--通知
      path: "Notice",
      name: "ProDcNotice",
      component: () =>
        import("@/views/staff/questionnaire/detail/noticeView.vue"),
    },
  ],
};

export default {
  routers: [
    // 课程-详情
    {
      path: "/train/course/detail",
      name: "courseDetail",
      component: () => import("@/views/train/course/detailView.vue"),
      meta: { title: "课程" },
    },
    // // 课程-学习
    {
      path: "/train/course/detail/play",
      name: "coursePlay",
      component: () => import("@/views/train/course/playView.vue"),
      meta: { title: "课程" },
    },
    // // 直播-播放
    // {
    //   path: "/train/live/livePlayer",
    //   name: "livePlayer",
    //   component: () => import("@/views/train/live/videoPlayer"),
    // },
    // // 直播-开播
    {
      path: "/train/live/live",
      name: "activeLive",
      component: () => import("@/views/train/live/ActiveLive"),
    },
    // // 培训班-详情
    {
      path: "/train/class/detail",
      name: "classDetail",
      component: () => import("@/views/train/class/detailView.vue"),
      // redirect: { name: "MyDiary" },
    },
    //学员-详情
    {
      path: "/train/student/detail",
      name: "studentDetail",
      component: () => import("@/views/train/student/detailView.vue"),
      meta: { title: "学员" },
    },

    //讲师-详情
    {
      path: "/train/lecturer/detail",
      name: "lecturerDetail",
      component: () => import("@/views/train/lecturer/detailView.vue"),
      // meta: { title: "课程" },
    },
    // *********************************************************************

    //测验页面
    // {
    //   path: "/train/class/components/quiz",
    //   name: "quiz",
    //   component: () => import("@/views/train/class/components/quizView.vue"),
    //   // redirect: { name: "MyDiary" },
    // },
    // //测验详情-考生
    // {
    //   path: "/train/exam/examinee",
    //   name: "ProKsExaminee",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/examinee.vue"),
    // },
    // //测验详情-问卷
    // {
    //   path: "/train/exam/questionnaire",
    //   name: "ProKsQuestionnaire",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/questionnaire.vue"),
    // },
    // //测验详情-数据
    // {
    //   path: "/train/exam/data",
    //   name: "ProKsData",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/data.vue"),
    // },
    // //测验详情-成绩
    // {
    //   path: "/train/exam/score",
    //   name: "ProKsScore",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/score.vue"),
    // },
    // //测验详情-统计
    // {
    //   path: "/train/exam/statistics",
    //   name: "ProKsStatistics",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/statistics.vue"),
    // },
    // //测验详情-通知
    // {
    //   path: "/train/exam/notice",
    //   name: "examNotice",
    //   component: () =>
    //     import("@/views/train/exam/components/detail-ks/notice.vue"),
    // },
    // //考试登录
    // {
    //   path: "/train/exam/login/:id?/:type?/:ownerId?/:userId?/:loginCode?/:idCard?/:code?/:staffcloud_compId?/:staffcloud_userId?/:staffcloud_Asktrue_userId?",
    //   name: "ExamLogin",
    //   component: () => import("@/views/train/exam/components/login.vue"),
    // },
    // //答题页
    // {
    //   path: "/Exam/Main/:id?/:type?/:ownerId?",
    //   name: "ExamMain",
    //   component: () =>
    //     import("@/views/train/exam/components/template/main.vue"),
    // },
  ],
};

export default function (axios) {
  return {
    // 社保核算
    // /internal/socialInsuranceHeSuan/addHeSuan
    calcSs(ids, time, sign) {
      // let url = "/socialInsuranceHeSuan/addHeSuan";
      // if (sign === "hro") url = axios.urlHro + url;
      let params = { time: time };
      if (ids) {
        params["employIds"] = ids;
      }
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceHeSuan/addHeSuan`,
        {},
        { params }
      );
    },
    // 获取档案列表
    getSsArchives(data, sign) {
      // let url = "/socialInsuranceRecord/selectPage";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, data);
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/selectPage`,
        data
      );
    },
    // 员工查看自己的档案
    getPersonalSsArchives(data, sign) {
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/selectSeInfoByUser`,
        data
      );
    },
    //新增或修改社保档案
    addSsArchives(params) {
      let url = params.sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.id) {
        url += "/socialInsuranceRecord/editRecord";
      } else {
        url += "/socialInsuranceRecord/addRecord";
      }
      return axios.post(url, params);
    },
    // 点击编辑， 编辑档案
    ssArchivesInfo(archiveId, sign) {
      // let url = "/socialInsuranceRecord/selectRecordById";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { archiveId } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/selectRecordById`,
        {},
        { params: { archiveId } }
      );
    },

    // 社保减员
    reduceSsRecord(endTime, ids, sign) {
      // let url = "/socialInsuranceRecord/delete";
      // if (sign === "hro") url = axios.urlHro + url;
      // endTime += "-01";
      // return axios.post(url, {}, { params: { endTime, ids } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/delete`,
        {},
        { params: { endTime, ids } }
      );
    },

    // 判断是否有社保记录
    hasSsRecord(ids, sign) {
      // let url = "/socialInsuranceRecord/selectShebaoNumById";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.get(url, { params: { ids } });
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/selectShebaoNumById`,
        { params: { ids } }
      );
    },

    // 删除社保档案
    removeSsArchives(headerIds, delRecord, sign) {
      // let url = "/socialInsuranceRecord/deleteArchives";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { headerIds, delRecord } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/deleteArchives`,
        {},
        { params: { headerIds, delRecord } }
      );
    },
    // 删除社保记录
    removeSsRecords(ids, sign) {
      // let url = "/socialInsuranceRecord/deleteShebaoJiluByids";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { ids } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/deleteShebaoJiluByids`,
        {},
        { params: { ids } }
      );
    },
    // 详情中单个或者批量删除档案
    removeSsArchivesInfo(delRecord, headerId, ids, sign) {
      // let url = "/socialInsuranceRecord/deleteArchivesById";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { delRecord, headerId, ids } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceRecord/deleteArchivesById`,
        {},
        { params: { delRecord, headerId, ids } }
      );
    },

    // 获取社保记录
    getSsRecord(params) {
      let url = params.sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.employId) {
        url += "/socialInsuranceHeSuan/selectEmpById";
      } else {
        url += "/socialInsuranceHeSuan/selectPage";
      }
      return axios.get(url, { params });
    },
    // 社保记录统计
    SsRecordCount(params, sign) {
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceHeSuan/selectSeRecordCount`,
        { params }
      );
    },
    // 保存社保记录
    saveSsRecord(info, sign) {
      // let url = "/socialInsuranceHeSuan/heSuanSave";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, info);
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceHeSuan/heSuanSave`,
        info
      );
    },
    // 导出社保记录   { responseType: "blob" }
    exportRecord(params, sign) {
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceHeSuan/exportSeRecord`,
        { params }
      );
    },

    // 添加或编辑社保设置
    addSsSet(params) {
      let url = params.sign === "hro" ? axios.urlHro : axios.urlHrm;
      if (params.setId) {
        // 编辑
        url += "/socialInsuranceSet/editsocialInsuranceSet";
      } else {
        // 新增
        url += "/socialInsuranceSet/addSocialInsuranceSet";
      }
      return axios.post(url, params);
    },
    // 获取社保设置
    getSsSet(sign) {
      // let url = "/socialInsuranceSet/selectPage";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.get(url, { params: { pageNum: 1, pageSize: 999999 } });
      return axios.get(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceSet/selectPage`,
        { params: { pageNum: 1, pageSize: 999999 } }
      );
    },
    // 删除社保设置
    removeSsSet(setIds, delRecord, sign) {
      // let url = "/socialInsuranceSet/delete";
      // if (sign === "hro") url = axios.urlHro + url;
      // return axios.post(url, {}, { params: { setIds, delRecord } });
      return axios.post(
        `/${
          sign === "hro" ? axios.urlHro : axios.urlHrm
        }/socialInsuranceSet/delete`,
        {},
        { params: { setIds, delRecord } }
      );
    },
  };
}

// import {postReq, getReq,uplodeReq} from '@/api/apiReq';
// import {apiUrl, apiUrl6,apiUrl7 } from "@/api/config"

export default function (axios) {
  return {
    // //用户登录
    // export const postLoginReq = data => postReq(apiUrl+"/anon/generateToken",data)
    // // /interviewPlan/getCount  面试统计
    // export const getCountOfinterviewPlan = () => getReq(apiUrl7+"/interviewPlan/getCount")
    // // 删除面试安排
    // export const delInterviewReq = data => postReq(apiUrl7+"/interviewPlan/deleteInterviewPlan", data, {ContentType: 'application/json'})
    // // 新增面试安排项目
    // export const editInterviewPlanReq = data => postReq(apiUrl7+"/interviewPlan/editInterviewPlan", data, {ContentType: 'application/json'})
    // // 单个、批量以及全部导出面试安排
    // export const exportInterviewReq = data => getReq(apiUrl7+"/interviewPlan/exportInterviewPlanExcel", data)
    // // 导出面试签到
    // export const exportInterviewSigneReq = data => getReq(apiUrl7+"/interviewPlan/exportInterviewSigneExcel", data)
    // // 导入面试安排
    // export const uploadInterviewReq = (data,params) => uplodeReq(apiUrl7+"/interviewPlan/importInterviewPlan?planId="+params.planId, data)
    // // 查询单个面试安排详情
    // export const getInterviewReq = data => postReq(apiUrl7+"/interviewPlan/selectByPrimaryKey", data, {ContentType: 'application/json'})
    // // 查询单个安排面试项目详情 无需token
    // export const getInterviewWithoutTokenReq = (data, params) => postReq(apiUrl7+"/interviewPlan/selectByPrimaryKeyNose?vipId=" + params.vipId, data, {ContentType: 'application/json'})
    // // 面试安排列表分页查询
    // export const interviewPlanList = data => getReq(apiUrl7+"/interviewPlan/selectPage", data)
    // // 面试评分
    // // 编辑面试评分表
    // export const editScorePaperReq = data => postReq(apiUrl7+"/planScore/editPlaceScore", data, {ContentType: 'application/json'})
    // // 查询面试评分表详情
    // export const searchScorePaperReq = data => postReq(apiUrl7+"/planScore/selectByPrimaryKey", data, {ContentType: 'application/json'})
    // // 获取面试评分表列表
    // export const getScorePaperListReq = data => getReq(apiUrl7+"/planScore/selectPage", data)
    // // 删除面试评分表
    // export const delScorePaperReq = data => postReq(apiUrl7+"/planScore/deletePlanScore?scoreIds=" + data.scoreIds + "&planId=" + data.planId, data, {ContentType: 'application/json'})
    // // 面试人员
    // // 编辑面试人员
    // export const editInterviewMemberReq = data => postReq(apiUrl7+"/interviewMember/editInterviewMember", data, {ContentType: 'application/json'})
    // // // 简历内新增/编辑面试人员
    // // export const editJianLiInterviewMemberReq = (data,params) => postReq(apiUrl+"/interviewMember/editJianLiInterviewMember?score_id="+params.score_id+"&channelId="+params.channelId+"&jobId="+params.jobId+"&resumeId="+params.resumeId+"&stageId="+params.stageId+"&needConfirm="+params.needConfirm, data, {ContentType: 'application/json'})
    // // 简历内新增/编辑面试人员
    // export function editJianLiInterviewMemberReq(data,params) {
    //   let url='/interviewMember/editJianLiInterviewMember'
    //   if(params){
    //     url=url+'?'
    //     for(let key in params){
    //       if(key){url+=key+'='+params[key]+'&'}
    //     }
    //   }
    //   return postReq(apiUrl7+url ,data, {ContentType: 'application/json'})
    // }
    // // 更新面试人员签到状态
    // export const editInterviewSignStatusReq = data => postReq(apiUrl7+"/interviewMember/editSigneStatus", data, {ContentType: 'application/json'})
    // // 分页查询面试人员
    // export const getInterviewMemberListReq = data => getReq(apiUrl7+"/interviewMember/selectPage", data)
    // // /interviewMember/selectPageNew  分页查询面试人员（新）
    // export const getInterviewMemberListNewReq = data => postReq(apiUrl7 + "/interviewMember/selectPageNew", data)
    // // /interviewMember/getFilterData   获取面试人员的筛选数据
    // export const getInterviewMemberFilterDataReq = data => getReq(apiUrl7+"/interviewMember/getFilterData", data)
    // // 删除面试人员
    // export const delInterviewMemberReq = data => postReq(apiUrl7+"/interviewMember/deleteInterviewMember", data, {ContentType: 'application/json'})
    // // 新增或者编辑外部评委
    // export const editOutInterviweReq = data => postReq(apiUrl7+"/outInterview/editOutInterviwe", data, {ContentType: 'application/json'})
    // // 查询评委（外部）
    // export const selectInterviewReq = data => postReq(apiUrl7+"/outInterview/selectPage", data)
    // // /outInterview/selectAllPage 查询评委 （内部加外部）
    // export const selectInterviewerAllReq = data => postReq(apiUrl7+"/outInterview/selectAllPage", data)
    // // 查询评委 无需 token
    // export const selectInterviewWithOutTokenReq = data => postReq(apiUrl7+"/outInterview/selectPageNose", data)
    // // 批量导入评价
    // export const importCommentReq = (data,params) => uplodeReq(apiUrl7+"/interviewPlan/importComment?planId="+params.planId, data)
    // // 导出面试评价
    // export const exportCommentExcelReq = (data) => postReq(apiUrl7+"/interviewPlan/exportCommentExcel", data)
    // // 查询详细的面试安排人员的信息
    // export const getExamArrangeInfoReq = data => postReq(apiUrl6+"/examPlan/selectInterviewArrangeInfo", data)
    // export const addInterviewTimeReq = data => postReq(apiUrl7+"/interviewPlan/addInterviewTime",data, {ContentType: 'application/json'})
    // //面试安排获取职位数据
    // export const getJobNameByPlanIdReq = data => postReq(apiUrl7+"/interviewMember/getJobNameByPlanId?planId="+data.planId)
    // export const editQueReq = data => postReq(apiUrl7+"/interviewPlan/editQuestion",data, {ContentType: 'application/json'})  //新增或者编辑面试试题
    // export const delQueReq = data => postReq(apiUrl7+"/interviewPlan/deleteQuestion",data)  //删除面试试题
    // export const getQueReq = data => getReq(apiUrl7+"/interviewPlan/selectQuestion", data)  //面试试题查询
    // export const getQueReqWithoutToken = data => getReq(apiUrl7+"/interviewPlan/selectQuestionNose", data)  //面试试题查询 无需token
    // export const editQueTypeReq = data => postReq(apiUrl7+"/interviewPlan/editQuestionType",data, {ContentType: 'application/json'})  //新增或者编辑面试试题分类
    // export const delQueTypeReq = data => postReq(apiUrl7+"/interviewPlan/deleteQuestionType",data)  //删除面试试题分类
    // export const getQueTypeReq = data => getReq(apiUrl7+"/interviewPlan/selectQuestionType", data)  //面试试题分类查询
    // // 面试试题分类查询 无需token
    // export const getQueTypeReqWithoutToken = data => getReq(apiUrl7+"/interviewPlan/selectQuestionTypeNose", data)
    // //面试登录接口
    // export const interviewLoginReq = data => postReq(apiUrl7+"/interviewPlan/interviewLoginNose",data)  //删除面试试题分类
    // export const selectIfExistTelReq = data => postReq(apiUrl7+"/interviewPlan/selectIfExistTelNose",data)  //判断面试人员或者评委的手机号是否存在
    // export const selectTimeJobPlaceReq = data => postReq(apiUrl7+"/interviewPlan/selectTimeJobPlace",data)  //查询面试的时间职位场地
    // export const selectIfExistByOutReq = data => postReq(apiUrl7+"/outInterview/selectIfExistByOut",data)  //查询该外部评委是否可以删除
    // export const deleteOutInterviewReq = data => postReq(apiUrl7+"/outInterview/deleteOutInterview",data)  //删除外部评委,删除前需要调用另一个（上面这个selectIfExistByOutReq）查询是否能删除外部评委的接口
    // export const selectTelReq = data => postReq(apiUrl7+"/outInterview/selectTel",data) //判断外部评委的手机号是否可以重复
    // export const selectMemberByMemberIdReq = data => postReq(apiUrl7+"/interviewMember/selectMemberByMemberId",data) //根据主键查询面试人员的详细信息
    // // 根据主键查询面试人员的详细信息 无需token
    // export const selectMemberByMemberIdWithOutTokenReq = data => postReq(apiUrl7+"/interviewMember/selectMemberByMemberIdNose",data)
    // export const editPlanScoreResultReq = data => postReq(apiUrl7+"/outInterview/editPlanScoreResult",data, {ContentType: 'application/json'}) //评委给面试人员评分
    // // export const selectScoreResultOneReq = data => postReq(apiUrl7+"/outInterview/selectScoreResultOne",data) //评委查询单个面试人员的评分
    // export const selectScoreResultOneReq = data => postReq(apiUrl7+"/outInterview/selectScoreResultOne?interviewerId="+data.interviewerId+"&judgeId="+data.judgeId+"&judgeType="+data.judgeType+"&scoreId="+data.scoreId+"") //评委查询单个面试人员的评分
    // export const selectQuestionForMemberReq = data => postReq(apiUrl7+"/interviewPlan/selectQuestionForMember?planId="+data.planId) //考生查询自己的试题(人机交互，随机试题)
    //  //考生查询自己的试题(人机交互，随机试题) 无需token
    // export const selectQuestionForMemberReqWithoutToken = data => postReq(apiUrl7+"/interviewPlan/selectQuestionForMemberNose?planId="+data.planId)
    // //保存人机面试的试题
    // export const saveQuestionRecordReq = data => postReq(apiUrl7+"/outInterview/saveQuestionRecord",data,{ContentType: 'application/json'})
    // // 保存人机面试的试题 无需token
    // export const saveQuestionRecordReqWithoutToken = (data, params) => postReq(apiUrl7+"/outInterview/saveQuestionRecordNose?vipId=" + params.vipId + "&userId=" + params.userId, data, {ContentType: 'application/json'})
    // export const getResumeAllInfoReq = data => getReq(apiUrl+"/resume/selectResumeAllInfo",data)
    // //修改状态操作
    // export const editExamMember = data => postReq(apiUrl7+"/outInterview/editMemberOrJudgeType",data)
    // // 改变当前评委或者是面试人员是否在房间的状态 无需token
    // export const editExamMemberWithOutToken = data => postReq(apiUrl7+"/outInterview/editMemberOrJudgeTypeNose",data)
    // // trtc ================= start =============================
    // // 开始录制
    // export const startRecording = data => getReq(apiUrl7+"/anon/trtc/createCloudRecording",data)
    // // 结束录制
    // export const stopRecording = data => getReq(apiUrl7+"/anon/trtc/deleteCloudRecording",data)
    // // 解散房间
    // export const stopMeeting = data => getReq(apiUrl7+"/anon/trtc/dismissRoom",data)
    // // 分页查询面试视频
    // export const getPlayerListPage = data => getReq(apiUrl7+"/interviewRTC/selectPlayerPageNose",data)
    // // 查询项目音视频数据
    // export const getPlanRTCListPage = data => getReq(apiUrl7+"/interviewRTC/selectPlanRTCPage",data)
    // // 查询面试者音视频数据
    // export const getPlanMemberRTCListPage = data => getReq(apiUrl7+"/interviewRTC/selectMemberPage",data)
    // // trtc ================= end =============================

    // export const getResumeAllInfoReqWithoutToken = (data) =>
    // getReq(apiUrl + "/resume/selectResumeAllInfoNose", data);
    getResumeAllInfoReqWithoutToken(params) {
      return axios.get("/resume/selectResumeAllInfoNose", { params });
    },
  };
}

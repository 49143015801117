<!--
课件/章节 列表
-->
<template>
  <!-- 章 -->
  <div class="chapter-list" :class="from">
    <div class="chapter-item" v-for="(item, index) in list" :key="index">
      <div class="title">{{ item.chapterName }}</div>
      <!-- 节 -->
      <div class="section-list">
        <div
          :class="[
            'section-item',
            { center: from != 'play' },
            { cur: from != 'list' },
            { on: e.coursewareId == value },
            { ed: e.ed },
          ]"
          v-for="(e, i) in item.coursewareList"
          :key="i"
          @click="clickFn(e)"
        >
          <div class="icon" v-if="from == 'play'">
            <g-icon name="iconbofang1" />
          </div>
          <div class="number" v-else>{{ e.index }}</div>
          <div class="namebox">
            <g-font :text="e.coursewareName" class="name" />
            <span class="timer" v-if="from == 'play'">36分钟</span>
          </div>
          <div class="right" v-if="from == 'list' && isCreate" @click.stop>
            <div class="func">
              <g-icon
                name="iconbofang1"
                title="播放"
                @click.native="palyFn(e)"
              />
              <g-icon
                name="iconshengxu"
                size="18px"
                title="上移"
                @click.native="moveFn(e, i, item.coursewareList, 'up')"
              />
              <g-icon
                name="iconjiangxu"
                size="18px"
                title="下移"
                @click.native="moveFn(e, i, item.coursewareList, 'down')"
              />
              <Edit
                :courseId="courseId"
                :coursewareId="e.coursewareId"
                :lecturerInfo="lecturerInfo"
                @refresh="getListFn()"
              >
                <g-icon name="iconbianji1" size="14px" title="编辑" />
              </Edit>
              <g-icon
                name="iconcuo"
                size="18px"
                title="删除"
                @click.native="delFn(e)"
              />
            </div>
          </div>
          <div class="right" v-if="from == 'list' && !isCreate">
            <div class="situation">
              <div class="e">
                <span class="l">学习</span>
                <g-icon
                  name="icondui"
                  size="13px"
                  color="#04cc00"
                  v-if="e.studyStatus == 1"
                />
                <g-icon name="iconcuo" size="13px" color="#f60" v-else />
              </div>
              <div class="e">
                <span class="l">练习</span>
                <g-icon
                  name="icondui"
                  size="13px"
                  color="#04cc00"
                  v-if="e.examStatus == 1"
                />
                <g-icon name="iconcuo" size="13px" color="#f60" v-else />
              </div>
              <div class="e">
                <span class="l">笔记</span>
                <g-icon
                  name="icondui"
                  size="13px"
                  color="#04cc00"
                  v-if="e.noteStatus == 1"
                />
                <g-icon name="iconcuo" size="13px" color="#f60" v-else />
              </div>
            </div>
            <div class="viewNum">{{ item.totalTime || 0 }}</div>
            <el-button @click.stop="toStudyFn(1)" class="toStudy"
              >学习</el-button
            >
          </div>

          <div class="state" v-if="from == 'play' || from == 'practice'">
            <!-- 正在观看 -->
            <!-- <g-icon name="iconbofang" v-if="e.id == pid" /> -->
            <!-- 已看完 -->
            <!-- <g-icon
              name="icondui"
              :color="from == 'play' ? '#038600' : '#04cc00'"
              v-if="e.ed && e.id != pid"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Edit from "@/views/train/course/components/AddChapter.vue";

export default {
  name: "g-chapter",
  components: { Edit },
  props: {
    value: [Number, String], //双向绑定课件id：播放详情中有用到
    courseId: [Number, String], //课程id
    from: String,
    isCreate: { type: Boolean, default: false }, //是否创建者
    lecturerInfo: String, //讲师ids，JSON字符串
  },
  data() {
    return {
      list: [],
      studyList: [1, 2, 3],
    };
  },
  created() {
    this.getListFn();
  },
  methods: {
    // 获取课件列表
    getListFn() {
      let ps = {
        courseId: this.courseId, //
        pageNum: 1, //	string	当前页数
        pageSize: 1000, //	string	每页条数
      };
      console.log(this.courseId, ps, "ps==this.courseId");
      this.$api.kc_chapterListApi(ps).then((res) => {
        if (res.code == "200") {
          this.list = res.data.result.list;
          let index = 0;
          this.list.forEach((e) => {
            e.coursewareList?.forEach((el) => {
              index++;
              this.$set(el, "index", index);
            });
          });
          if (index > 0) {
            this.$emit("exist", true); //告知父元素是否存在课件
          }
          console.log(
            this.value,
            this.from,
            this.list[0]?.coursewareList[0],
            "==3ziduan"
          );
          if (
            ((!this.value && this.from == "play") ||
              (this.value && this.from == "practice")) &&
            this.list[0]?.coursewareList[0]
          ) {
            let item = this.list[0].coursewareList[0];
            this.clickFn(item);
          }
        }
      });
    },
    toStudyFn(id) {
      // this.$router.push({
      //   name: "coursePlay",
      //   query: { id: this.courseId, pid: id },
      // });
      const params = { id: this.courseId, pid: id };
      let routeData = this.$router.resolve({
        name: "tocPlay",
        query: params,
      });
      window.open(routeData.href, "_blank");
    },
    clickFn(e) {
      let obj = JSON.parse(JSON.stringify(e));
      obj.prevId = JSON.parse(JSON.stringify(this.value));
      console.log(e, "=e");

      this.$emit("input", obj.coursewareId);
      this.$emit("click", obj);
    },

    // 播放
    palyFn(e) {
      console.log(e, "=e");
      this.$emit("input", e.coursewareId);
      this.$emit("play");
    },
    // 上移、下移
    moveFn(item, index, list, direction) {
      let length = list.length,
        newId = null,
        newSort = null;
      if (direction == "up") {
        if (index == 0) {
          this.$message.warning("已经是第一个了！");
          return false;
        } else {
          newId = list[index - 1].coursewareId;
          newSort = list[index - 1].coursewareSort;
        }
      }
      if (direction == "down") {
        if (index == length - 1) {
          this.$message.warning("已经是最后一个了！");
          return false;
        } else {
          newId = list[index + 1].coursewareId;
          newSort = list[index + 1].coursewareSort;
        }
      }
      console.log(item, direction);
      let ps = {
        oldId: item.coursewareId,
        oldSort: item.coursewareSort,
        newId: newId,
        newSort: newSort,
      };
      this.$api.kc_moveCoursewareApi(ps).then((res) => {
        if (res.code == "200") {
          this.getListFn();
        }
      });
    },
    // 删除
    delFn(item) {
      let ps = { coursewareId: item.coursewareId };
      this.$api.kc_deleteChapterApi(ps).then((res) => {
        if (res.code == "200") {
          this.$message.success("删除成功");
          this.getListFn();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.chapter-list {
  .chapter-item {
    &:not(:first-child) {
      margin-top: 20px;
    }
    .title {
      font-size: 15px;
      // font-weight: bold;
      margin: 0px 0 10px 0;
    }
    .section-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .section-item {
        display: flex;
        justify-content: space-between;
        &.center {
          align-items: center;
        }
        //
        gap: 15px;
        height: 40px;
        padding: 0 10px;
        position: relative;
        background: rgba(228, 238, 245, 0.5);

        &.cur {
          cursor: pointer;
        }
        .namebox {
          flex: 1;
          width: 0px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
          .timer {
            font-size: 12px;
            color: #667280;
          }
        }
        .state {
        }
        &.ed {
          // color: #999;
        }
        &.on {
          background-color: #ecf5ff;
          .number,
          .icon {
            color: #39c;
          }
          .namebox {
            color: #39c;
            .timer {
              color: #39c;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 50px;
          .func {
            display: flex;
            align-items: center;
            gap: 20px;
            .g-icon {
              cursor: pointer;
              color: #999;
              &:hover {
                color: #39c;
              }
            }
          }
          .situation {
            display: flex;
            align-items: center;
            gap: 20px;
            .e {
              display: flex;
              align-items: center;
              gap: 5px;
              .v {
                color: #999;
              }
            }
          }
          .viewNum {
            width: 50px;
            text-align: center;
          }
        }
        .toStudy {
          position: absolute;
          right: 15px;
          z-index: 99;
          display: none;
          padding: 0px;
          width: 50px;
          height: 24px;
          font-size: 12px;
          border-width: 0px;
        }

        &:hover {
          // background: #f5f8fa;
          background: rgba(228, 238, 245, 0.8);
          .toStudy {
            display: block;
          }
        }
      }
    }
  }
  &.play {
    .chapter-item {
      color: #a1a9b2;
      .section-list {
        gap: 0px;
        .section-item {
          background: rgba(235, 237, 241, 0);
          padding: 10px;
          height: auto;
          font-size: 13px;
          margin: 0 -10px;
          &.ed {
            // color: #333;
          }
          &.on {
            background-color: #0f1419 !important;
            border-radius: 5px;
          }
          &:hover {
            background: rgba(249, 199, 199, 0);
            color: #999;
          }
        }
      }
    }
  }
}
</style>

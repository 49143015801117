var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"selectSet",class:['g-selectset', { on: _vm.isOn }, _vm.placement]},[_c('div',{class:['selectTitle', { isDisabled: _vm.disabled }],on:{"click":function($event){_vm.disabled ? '' : _vm.clickFn()}}},[_vm._t("default",function(){return [_c('span',{staticClass:"value slh",class:{ no: _vm.label == '' || _vm.label == null },attrs:{"title":_vm.label}},[_vm._v(_vm._s(_vm.label || _vm.placeholder))]),_c('i',{staticClass:"el-icon-arrow-down"})]})],2),_c('div',{staticClass:"select-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.options.length == 0),expression:"options.length == 0"}],staticClass:"noData"},[_vm._v("暂无数据，请先新增")]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.options.length > 0),expression:"options.length > 0"}],staticClass:"option scroll"},_vm._l((_vm.options),function(SelItem,SelIndex){return _c('li',{key:'selectSet_' + SelIndex,class:{ on: _vm.value == SelItem.value },on:{"click":function($event){return _vm.selectFn(SelItem)}}},[_c('span',[_vm._v(_vm._s(SelItem.label))]),(!SelItem.const && _vm.isEdit)?_c('div',{staticClass:"select-func",on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"iconfont iconbianji",attrs:{"title":"编辑"},on:{"click":function($event){_vm.eid = SelItem.value;
              _vm.name = SelItem.label;
              _vm.isSet = true;}}}),_c('i',{staticClass:"iconfont iconshanchu",attrs:{"title":"删除"},on:{"click":function($event){return _vm.delFn(SelItem.value)}}})]):_vm._e()])}),0),(_vm.isEdit)?_c('div',{staticClass:"g-selectset-box"},[(!_vm.isSet)?_c('div',{staticClass:"add",on:{"click":function($event){_vm.eid = 0;
          _vm.name = '';
          _vm.isSet = true;}}},[_vm._v(" +新增 ")]):_c('div',{staticClass:"edit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.saveFn.apply(null, arguments)}}},[_vm._v("保存")]),_c('button',{on:{"click":function($event){$event.stopPropagation();_vm.eid = 0;
            _vm.name = '';
            _vm.isSet = false;}}},[_vm._v(" 取消 ")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
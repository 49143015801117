//安排笔试路由
export default {
  routers: [
    // const arrangeBs = [
    {
      //登录页
      path: "/bsLogin",
      name: "BsLogin",
      component: (resolve) =>
        require(["@/views/recruit/arrange/bs/loginView"], resolve),
      meta: { pageType: "bs" },
    },
    {
      //首页
      path: "/bsIndex",
      name: "BsIndex",
      component: (resolve) =>
        require(["@/views/recruit/arrange/bs/indexView"], resolve),
      meta: { pageType: "bs", auth: true },
    },
    {
      //详情页
      path: "/bsDetail",
      name: "BsDetail",
      component: (resolve) =>
        require(["@/views/recruit/arrange/bs/detail"], resolve),
      meta: { pageType: "bs", auth: true },
    },
  ],
};

/*
 * @Description:
 * @Date: 2023-11-17 09:38:44
 * @LastEditTime: 2024-01-12 16:26:31
 */
import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// //查询短信 邮件 数量
// export const getCountReq = data => getReq(apiUrl1+"/sendCtl/selectMsgAndMailCount",data)
export function getCountReq(params) {
  return request({
    url: "/sendCtl/selectMsgAndMailCount",
    method: "get",
    params,
  });
}

// //
// export const getVipCountReq = data => getReq(apiUrl1+"/sendCtl/selectMsgAndMailCountNose",data)

// //查询短信 邮件订单分页
// export const getSmsListReq = data => getReq(apiUrl1+"/sendCtl/selectSMSOrMailPage",data)

// //查询订单分页
// export const getOrderListReq = data => getReq(apiUrl1+"/orderCtl/selectOrderPageByParam",data)

// //查询账户分页
// export const selectAccountPage = data => getReq(apiUrl1+"/accountCtl/selectAccountPage",data)
// //查询账户信息
// export const selectCompOrStaffAcconutInfo = data => getReq(apiUrl1+"/accountCtl/selectCompOrStaffAcconutInfo",data)
// // 查询产品分类字段
// export const accountGetGoodName = data => getReq(apiUrl1+"/accountCtl/getGoodName",data)

// /*员工相关*/
// //查询企业员工分页
export function getStaffListPageReq(params) {
  return request({
    url: "/companyStaffMemberCtl/selectCompStaffMemberPage",
    method: "get",
    params,
  });
}

// /companyStaffMemberCtl/selectCompStaffMemberPageNose  查询企业员工分页(免token)
export function getStaffListPageReqNose(params) {
  return request({
    url: "/companyStaffMemberCtl/selectCompStaffMemberPageNose",
    method: "get",
    params,
  });
}

// //查询企业员工
// export const getStaffListReq = data => getReq(apiUrl1+"/companyStaffMemberCtl/selectCompStffMember",data)
// //企业员工会员添加
// export const addStaffReq = data => postReq(apiUrl1+"/companyStaffMemberCtl/insertCompStaffInfo",data,{ContentType:'application/json;charset=UTF-8'})
// //企业员工会员修改
// export const updateStaffReq = data => postReq(apiUrl1+"/companyStaffMemberCtl/updateCompStaff",data,{ContentType:'application/json;charset=UTF-8'})
// //企业员工会员状态修改
// export const updateCompStaffMemberStatus = data => postReq(apiUrl1+"/companyStaffMemberCtl/updateCompStaffMemberStatus",data)
// /*员工相关*/

// // 企业查询部门
// export const selectDeptByCompId = data => getReq(apiUrl1+"/cCompDepartment/selectDeptByCompId",data)
export function selectDeptByCompId(params) {
  return request({
    url: "/cCompDepartment/selectDeptByCompId",
    method: "get",
    params,
  });
}
// // 企业新增部门
// export const addDeptReq = data => postReq(apiUrl1+"/cCompDepartment/addDept",data,{ContentType:'application/json;charset=UTF-8'})
// // 企业更新或删除部门
// export const updateDeptReq = data => postReq(apiUrl1+"/cCompDepartment/updateDept",data,{ContentType:'application/json;charset=UTF-8'})
// /*员工相关*/

// // 查看用户权限
// export const selectUserAuth = data => getReq(apiUrl1+"/selectAuthByToken",data)

// /*发票相关*/
// // 公司/员工
// // 查询发票列表/查询单个发票信息
// export const selectInvoiceList = data => getReq(apiUrl1+"/cReceiptInfoCtl/selectReceiptInfo",data)
// // 新增发票信息
// export const insertInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/insertReceiptInfo",data)
// // 修改
// export const updateInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/updateReceiptInfo",data)
// // 删除
// export const deleteInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/updateReceiptStatus",data)
// // 个人
// // 查询发票列表/查询单个发票信息
// export const selectPersonInvoiceList = data => getReq(apiUrl1+"/cReceiptInfoCtl/selectReceiptInfoPerson",data)
// // 新增发票信息
// export const insertPersonInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/insertReceiptInfoPerson",data)
// // 修改
// export const updatePersonInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/updateReceiptInfoPerson",data)
// // 删除
// export const deletePersonInvoice = data => getReq(apiUrl1+"/cReceiptInfoCtl/updateReceiptStatusPerson",data)

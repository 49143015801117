<!-- 暂无数据公共样式
  可直接引用，无需设置任何参数  
  1、<NoData v-if="totalSize == 0" />
  2、import NoData from "@/components/NoData.vue";
  3、components: { NoData },

  亦可根据不同的引用环境设置class和tips，如：
  <NoData class="nullData" tips="暂无数据，请更换筛选条件再试下" v-if="totalSize == 0" />

  nullData从父组件中自行调整即可，如：
  /deep/.no_data{
    padding:50px 0;
    .icon{
      width: 80px;
      height: 60px;
    }
    p{font-size:12px;}
  }
 -->
<template>
  <div class="no_data no-select">
    <svg class="icon" aria-hidden="true">
      <use xlink:href="#iconmeiyoushuju"></use>
    </svg>
    <p>
      <slot>
        <span>{{ tips }}</span>
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "no-data",
  props: {
    tips: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
// 暂无数据样式1
.no_data {
  padding: 100px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 160px;
    height: 110px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }

  p {
    font-size: 14px;
    color: #ccc;
    margin-top: 20px;
  }
}
</style>

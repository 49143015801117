export default {
  parent: "System",
  routers: [
    {
      path: "message",
      name: "Message",
      component: () => import("@/views/system/message"),
      meta: {
        title: "消息",
      },
    },
  ],
};

<!--注释：
职位弹窗
-->
<template>
  <div class="form-warpper no-select">
    <div class="monitor-box">
      <div class="share-box pr" style="display: none">
        <h2 class="mt30">第二视角_登录</h2>
        <!--分享链接-->
        <div class="url">
          <div class="clearfix">
            <input type="text" readonly v-model="loginUrl2" class="text" />
            <button
              class="btnnull"
              v-clipboard:copy="loginUrl2"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              复制
            </button>
            <!-- <a @click="$utils.voit('请使用移动端浏览器打开')"><button class="btnnull">打开</button></a> -->
          </div>
        </div>
        <!--二维码-->
        <div class="ewm flex">
          <div class="tac">
            <p style="margin-top: -8px">扫码登录</p>
            <div class="qr-code" id="qr-code1" ref="qrcode1">
              <img class="code" :src="loginCode" />
            </div>
          </div>
        </div>
      </div>

      <div class="share-box pr" style="display: none">
        <h2 class="mt30">第二视角_登录</h2>
        <!--分享链接-->
        <div class="url">
          <div class="clearfix">
            <!-- <input type="text" readonly v-model="jkUrl" class="text no-select" disabled  /> -->
            <div class="text no-select">{{ loginUrl2 }}</div>
            <button
              class="btnnull"
              v-clipboard:copy="loginUrl2"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              复制
            </button>
            <!-- <a  @click="$utils.voit('请使用移动端浏览器打开')"><button class="btnnull">打开</button></a> -->
          </div>
        </div>
        <!--二维码-->
        <div class="ewm flex">
          <div class="tac">
            <p style="margin-top: -8px">扫码登录</p>
            <div class="qr-code" id="qr-code2" ref="qrcode2">
              <img class="code" :src="loginCode" />
            </div>
          </div>
        </div>
      </div>

      <!--监考分享链接-->
      <div class="second monitor">
        <template
          v-if="this.monitor.v1 + this.monitor.v2 + this.monitor.v3 > 1"
        >
          <div class="share-box pr">
            <h2 class="mt30">全屏_监考</h2>
            <div class="url">
              <div class="clearfix">
                <input type="text" readonly v-model="jkUrlAll" class="text" />
                <button
                  class="btnnull"
                  v-clipboard:copy="jkUrlAll"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  复制
                </button>
                <a :href="jkUrlAll" :titleInfo="this.proData" target="_blank"
                  ><button class="btnnull">打开</button></a
                >
              </div>
            </div>
          </div>
        </template>
        <template v-if="monitor.v1">
          <div style="border-top: 1px dotted #e4e4e4; margin: 0 0px"></div>
          <div class="share-box pr">
            <h2 class="mt30">第一视角_监考</h2>
            <div class="url">
              <div class="clearfix">
                <input type="text" readonly v-model="jkUrl" class="text" />
                <button
                  class="btnnull"
                  v-clipboard:copy="jkUrl"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  复制
                </button>
                <a :href="jkUrl" target="_blank"
                  ><button class="btnnull">打开</button></a
                >
              </div>
            </div>
          </div>
        </template>

        <template v-if="monitor.v2">
          <div style="border-top: 1px dotted #e4e4e4; margin: 0 0px"></div>
          <!--第二视角-认证-->
          <div
            class="share-box pr"
            style="border-radius: 0px"
            v-show="proData.specify_student == 1"
          >
            <h2 class="mt30">第二视角_监考</h2>
            <!--分享链接-->
            <div class="url">
              <div class="clearfix">
                <input type="text" readonly v-model="jkUrl2" class="text" />
                <button
                  class="btnnull"
                  v-clipboard:copy="jkUrl2"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  复制
                </button>
                <a :href="jkUrl2" target="_blank"
                  ><button class="btnnull">打开</button></a
                >
              </div>
            </div>
          </div>

          <!--第二视角-不认证-->
          <div
            class="share-box pr"
            style="border-radius: 0px"
            v-show="proData.specify_student != 1"
          >
            <h2 class="mt30">第二视角_监考</h2>
            <!--分享链接-->
            <div class="url">
              <div class="clearfix">
                <div class="text no-select">{{ jkUrl2 }}</div>
                <button class="btnnull" @click="disableFn">复制</button>
                <a @click="disableFn"><button class="btnnull">打开</button></a>
              </div>
            </div>
          </div>
        </template>

        <template v-if="monitor.v3">
          <!--屏幕监测-->
          <div style="border-top: 1px dotted #e4e4e4; margin: 0 0px"></div>
          <div class="share-box pr" style="border-radius: 0px">
            <h2 class="mt30">屏幕监测_监考</h2>
            <!--分享链接-->
            <div class="url">
              <div class="clearfix">
                <input type="text" readonly v-model="jkUrl3" class="text" />
                <button
                  class="btnnull"
                  v-clipboard:copy="jkUrl3"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  复制
                </button>
                <a :href="jkUrl3" target="_blank"
                  ><button class="btnnull">打开</button></a
                >
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="second assign-div mt30">
        <h2 class="mb30">监考_设置</h2>
        <div class="flex-space">
          <el-radio-group v-model="assignForm.type" @change="getTabDataFn">
            <el-radio :label="1">随机分配</el-radio>
            <el-radio :label="2">按考场分配</el-radio>
            <el-radio :label="3" v-if="proData.abc === 1">按问卷分配</el-radio>
          </el-radio-group>
        </div>
        <div class="assign-box flex">
          <!-- 考场列表 -->
          <div class="assign-left" v-show="assignForm.type != 1">
            <ul class="" v-show="assignForm.type == 2">
              <li
                class="ellipsis"
                v-for="(item, index) in assignForm.placeList"
                :key="index"
              >
                <span
                  :class="{
                    on: assignForm.placeId == (item.placeId || item.kdId),
                  }"
                  @click="
                    assignForm.placeId = item.placeId || item.kdId;
                    changeAssign();
                  "
                  >{{
                    item.kdName + (item.placeName ? "_" + item.placeName : "")
                  }}</span
                >
              </li>
            </ul>
            <!--问卷列表-->
            <div
              v-for="(item, index) in assignForm.testAbList"
              :key="index"
              v-show="assignForm.type == 3"
            >
              <el-divider v-if="item.abLabel && item.list.length">{{
                item.abLabel
              }}</el-divider>
              <ul v-if="assignForm.paperList.length" class="paper-ul">
                <li v-for="(el, ei) in item.list" :key="ei" class="ellipsis">
                  <span
                    class="paperName"
                    @click="
                      assignForm.paperIndex = ei;
                      assignForm.paperTypeId = el.paperTypeId;
                      changeAssign();
                    "
                    :class="{
                      on:
                        assignForm.paperIndex == ei &&
                        assignForm.paperTypeId == el.paperTypeId,
                    }"
                    :title="el.paperName"
                    >{{ el.paperName || "--" }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="assign-right">
            <div class="assign-top flex column-center mt15">
              <!-- <span class="flex one">考生人数：<el-input v-model="assignForm.stuNum" placeholder="输入考生人数" type="number" @blur="changeAssignFn()" :disabled="assignForm.isAverage"></el-input></span> -->
              <span class="flex column-center one assign-input">
                <span style="width: 80px">监考人数：</span>
                <el-input
                  v-model="assignForm.invigNum"
                  placeholder="输入数量"
                  type="number"
                  @blur="changeAssignFn()"
                ></el-input
              ></span>
              <!-- <el-checkbox v-model="assignForm.isAverage" @change="changeAssignFn()">平均分配</el-checkbox> -->
              <div class="">
                <span class="one">总人数：{{ assignForm.stuTotalNum }}</span
                ><span class="one">已分配：{{ assignForm.assignNum }}</span
                ><span class="one"
                  >未分配：{{
                    assignForm.stuTotalNum - assignForm.assignNum
                  }}</span
                >
              </div>
            </div>
            <div class="assign-table mt15">
              <el-table
                :data="assignForm.dataList"
                border
                style="width: 100%"
                height="300"
              >
                <el-table-column label="账号" width="220">
                  <template slot-scope="scope">
                    <span class="ml5" v-if="scope.row.admin == 1">{{
                      scope.row.invigilatorNo
                    }}</span>
                    <el-input
                      v-else
                      v-model="scope.row.invigilatorNo"
                      placeholder="输入账号"
                      @change="updateInvigilatorFn(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="密码" width="220">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.password"
                      placeholder="输入密码"
                      @change="updateInvigilatorFn(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="分配考生">
                  <template slot-scope="scope">
                    <span class="ml5" v-if="scope.row.admin == 1">全部</span>
                    <el-input
                      v-else
                      v-model="scope.row.stuNum"
                      placeholder="输入分配考生数量"
                      @change="updateInvigilatorFn(scope.row, 2)"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getShareInfoReq,
  saveShareInfoReq,
  postXcxEwmReq,
  addInvigilatorReq,
  selectInvigilatorReq,
  getStudentCountReq,
  getProPaperReq,
  updateInvigilatorReq,
  postSelectPlaceListReq,
} from "@/api/asktrue/project/project"; //引入接口
import { apiUrl1 } from "@/api/asktrue/config";
import { getShorlUrlReq } from "@/api/asktrue/common";

export default {
  name: "PopMonitor",
  props: {
    source: String, //分享来源
    examData: Object, //项目信息
    proData: Object,
  },
  data() {
    return {
      selectIndex: 0,
      ruleForm: {},
      rules: {},
      jkUrl: "",
      jkCode1: "",
      loginUrl2: "", //第二视角登录
      loginCode: "", //登录二维码
      jkUrl2: "", //第二视角监考
      jkCode2: "",
      jkUrl3: "", //屏幕监测监考
      jkUrlAll: "", //全部视角监考
      //项目
      url: "https://dwz.cn/9oI5mguL", //分享路径
      imageUrl: require("@/assets/image/asktrue/error/asktrue.png"), //分享图片
      title: "阿仕图，一站式考评SaaS平台！", //分享标题
      content: "考试？测评？调查？评估？Asktrue.cn帮您一站解决！", //分享内容
      //商品
      url2: "https://dwz.cn/9oI5mguL", //分享路径
      imageUrl2: require("@/assets/image/asktrue/error/asktrue.png"), //分享图片
      title2: "阿仕图，一站式考评SaaS平台！", //分享标题
      content2: "考试？测评？调查？评估？Asktrue.cn帮您一站解决！", //分享内容

      token: {},
      apiUrl1: apiUrl1,

      assignForm: {
        type: 1, //分配类型
        stuTotalNum: 0, //总人数
        assignNum: 0, //已分配
        noAssignNum: 0, //未分配
        invigNum: "", //考官人数
        stuNum: "",
        isAverage: false, //平均分配
        dataList: [], //账号列表
        placeList: [], // 考点列表
        roomList: [], //考场列表
        placeId: 0, //考场id
        paperList: [], //问卷列表
        paperIndex: 0,
        paperTypeId: 0, //AB卷
      },
      paperId: "",
      monitor: {
        v1: 0,
        v2: 0,
        v3: 0,
      },
    };
  },
  mounted() {
    this.changeTabFn();
  },
  //页面初始加载
  activated() {
    this.jkUrl = "";
    this.jkUrl2 = "";
    this.jkUrlAll = "";
    setTimeout(() => {
      this.changeTabFn();
    }, 500);
    this.token = { access_token: this.$storage.get("token") };
    this.getShareInfoFn();
    // this.assignForm.paperList.forEach(el => {
    //   this.assignForm.paperId = el.paperId
    // })
  },
  methods: {
    changeTabFn() {
      this.$nextTick(() => {
        let examData = this.$store.getters.projectInfo;
        this.assignForm.stuTotalNum = examData.student_amount || 0;
        let examDataTemp = null;
        let examDataSlot = JSON.parse(sessionStorage.getItem("examDataSlot"));
        if (examDataSlot) {
          examDataTemp = examDataSlot;
        } else {
          examDataTemp = examData;
        }
        this.monitor.v1 =
          Number(examDataTemp.player_set.substring(0, 1)) == 0 ? 0 : 1;
        this.monitor.v2 =
          Number(examDataTemp.player_set.substring(1, 2)) == 0 ? 0 : 1;
        this.monitor.v3 =
          Number(examDataTemp.player_set.substring(2, 3)) == 0 ? 0 : 1;

        let id = Number(this.$route.query.id);

        this.jkUrlAll =
          "https://" +
          location.host +
          "/#/Project/Monitoring/login?examId=" +
          id +
          "&type=" +
          this.examData.type +
          "&vipId=" +
          this.examData.vipId +
          "&name=" +
          this.$route.query.title +
          "&playerSet=" +
          examData.player_set +
          "&playerType=all";
        //监考页1
        this.jkUrl =
          "https://" +
          location.host +
          "/#/Project/Monitoring/login?examId=" +
          id +
          "&type=" +
          this.examData.type +
          "&vipId=" +
          this.examData.vipId +
          "&name=" +
          this.$route.query.title +
          "&playerSet=" +
          examData.player_set +
          "&playerType=one";
        //this.jkUrl="https://"+location.host+'/#/Project/Monitoring/one?examId='+id+'&type='+this.examData.type+'&vipId='+this.examData.vipId+'&name='+this.$route.query.title+'&playerSet='+examData.player_set//+'&playerType=one'
        //监考页2
        this.jkUrl2 =
          "https://" +
          location.host +
          "/#/Project/Monitoring/login?examId=" +
          id +
          "&type=" +
          this.examData.type +
          "&vipId=" +
          this.examData.vipId +
          "&name=" +
          this.$route.query.title +
          "&playerSet=" +
          examData.player_set +
          "&playerType=two";
        //this.jkUrl2="https://"+location.host+'/#/Project/Monitoring/two?examId='+id+'&type='+this.examData.type+'&vipId='+this.examData.vipId+'&name='+this.$route.query.title+'&playerSet='+examData.player_set//+'&playerType=two'
        //监考页3
        this.jkUrl3 =
          "https://" +
          location.host +
          "/#/Project/Monitoring/login?examId=" +
          id +
          "&type=" +
          this.examData.type +
          "&vipId=" +
          this.examData.vipId +
          "&name=" +
          this.$route.query.title +
          "&playerSet=" +
          examData.player_set +
          "&playerType=three";
        //this.jkUrl3="https://"+location.host+'/#/Project/Monitoring/three?examId='+id+'&type='+this.examData.type+'&vipId='+this.examData.vipId+'&name='+this.$route.query.title+'&playerSet='+examData.player_set//+'&playerType=three'
        if (this.monitor.v1 + this.monitor.v2 + this.monitor.v3 > 1) {
          getShorlUrlReq({ longUrl: this.jkUrlAll }).then((res) => {
            if (res.code == "200") {
              this.jkUrlAll = res.data.dwz;
            }
          });
        }
        if (this.monitor.v1) {
          getShorlUrlReq({ longUrl: this.jkUrl }).then((res) => {
            if (res.code == "200") {
              this.jkUrl = res.data.dwz;
            }
          });
        }
        if (this.monitor.v2) {
          getShorlUrlReq({ longUrl: this.jkUrl2 }).then((res) => {
            if (res.code == "200") {
              this.jkUrl2 = res.data.dwz;
            }
          });
        }
        if (this.monitor.v3) {
          getShorlUrlReq({ longUrl: this.jkUrl3 }).then((res) => {
            if (res.code == "200") {
              this.jkUrl3 = res.data.dwz;
            }
          });
        }

        let jumpWxa = {
          path: "pages/monitor/login/login",
          query:
            id + "@" + this.$route.query.vipId + "@" + this.$route.query.type,
        };
        let formData = new FormData();
        formData.append("jump_wxa", JSON.stringify(jumpWxa));
        // postXcxLinkReq(formData).then(res=>{
        //   if(res.code=='200'){
        //     let urlLink=res.data.result
        //     //登录页
        //     this.loginUrl2=window.location.href.split('Project')[0]+'page/xcx?urlLink='+urlLink
        //     getShorlUrlReq({longUrl:this.loginUrl2}).then(res=>{
        //       if(res.code=='200'){this.loginUrl2=res.data.dwz;}
        //     })
        //   }
        // })

        this.loginUrl2 =
          "https://" +
          location.host +
          `/#/exam/secondcamera/login/${id}/${this.examData.type}/${this.examData.vipId}`;
        // getShorlUrlReq({longUrl:this.loginUrl2}).then(res=>{
        //   if(res.code=='200'){this.loginUrl2=res.data.dwz;}
        // })

        this.$nextTick(() => {
          // 用之前通用的 creatQrFn 生成 qrCode
          if (this.$refs.qrcode1) {
            this.$refs.qrcode1.innerHTML = "";
            this.$commons.creatQrFn(this.loginUrl2, "qr-code1", 70);
          }
          if (this.$refs.qrcode2) {
            this.$refs.qrcode2.innerHTML = "";
            this.$commons.creatQrFn(this.loginUrl2, "qr-code2", 70);
          }
        });

        //生成小程序码
        for (let i = 0; i < 3; i++) {
          //0第一视角监考，1第二视角登录，3第二视角监考
          let params = {
            page:
              i == 1
                ? "pages/monitor/login/login"
                : "pages/monitor/login/login",
            sceneStr:
              id +
              "@" +
              this.$route.query.vipId +
              "@" +
              this.$route.query.type +
              "@" +
              i,
          };
          postXcxEwmReq(params).then((res) => {
            if (res.code == "200") {
              if (i == 0) {
                this.jkCode1 = res.data.result;
              } else if (i == 1) {
                console.log(res.data.result);
                // this.loginCode = res.data.result 第二视角登录改为普通二维码了，所以这里不需要了
              } else {
                this.jkCode2 = res.data.result;
              }
            }
          });
        }

        // console.log(this.assignForm.invigNum)
        // this.assignForm.invigNum='';
        this.assignForm.stuNum = "";
        this.getTabDataFn();
      });
    },

    //获取监考账号密码列表
    selectInvigilatorFn() {
      let params = {
        examId: this.examData.examId, //项目ID
        projectType: this.examData.type, //项目类型
        vipId: this.examData.vipId, //当前监考者vipId
      };

      if (this.assignForm.type === 2) {
        // 按考场分配
        params.placeId = this.assignForm.placeId;
      }

      if (this.assignForm.type === 3) {
        // 按问卷分配
        params.paperId = this.paperId;
        params.paperTypeId = this.assignForm.paperTypeId;
      }

      selectInvigilatorReq(params).then((res) => {
        if (res.code == "200") {
          this.assignForm.dataList = [];
          if (res.data.result && res.data.result.length) {
            this.assignForm.dataList = res.data.result.filter(
              (e) => e.stuNum || e.admin == 1
            );
            this.assignForm.invigNum =
              res.data.result.filter((e) => e.stuNum).length || "";
            this.getStudentCountFn(); //获取分配数据
          }
        }
      });
    },

    //更改分配方式时获取考场或问卷数据
    getTabDataFn() {
      let params = {
        examId: this.examData.examId,
        projectType: this.examData.type,
      };
      if (this.assignForm.type == 1) {
        // 随机分配
        this.selectInvigilatorFn(); //获取监考账号密码列表
      } else if (this.assignForm.type == 2) {
        // 按考场分配：获取考场数据
        postSelectPlaceListReq(params).then((res) => {
          if (res.code == 200) {
            this.assignForm.placeList = [];
            let $ = res.data.result;
            $.forEach((ele) => {
              if (ele.roomList || ele.roomList.length) {
                //有考场
                ele.roomList.forEach((el) => {
                  this.assignForm.placeList.push({
                    kdId: ele.placeId, //考点id
                    kdName: ele.placeName, //考点名称
                    placeId: el.placeId, //考场id
                    placeName: el.placeName, //考场名称
                    isShow: true,
                  });
                });
              } else {
                //无考场
                this.assignForm.placeList.push({
                  kdId: ele.placeId, //考点id
                  kdName: ele.placeName, //考点名称
                  placeId: null, //考场id
                  placeName: "", //考场名称
                  isShow: true,
                });
              }
            });
            if (this.assignForm.placeList.length > 0) {
              this.assignForm.placeId =
                this.assignForm.placeList[0].placeId ||
                this.assignForm.placeList[0].kdId; //设置默认初始场地id
            }
            this.selectInvigilatorFn(); // 获取监考账号密码列表
            console.log(this.assignForm.placeList, "kcres");
          }
        });
      } else if (this.assignForm.type == 3) {
        // 按问卷分配：获取问卷数据
        let proData = this.$store.getters.projectInfo;
        getProPaperReq(params).then((res) => {
          if (res.code == "200") {
            this.assignForm.paperList = [];
            this.assignForm.testAbList = [];
            this.isFinished = true;
            if (res.data.result.length > 0) {
              if (proData.abc == 1) {
                //开启AB卷
                let abList = proData.paperTypeList.split(",");
                let l1 = [];
                let l2 = [];
                abList.forEach((el) => {
                  el.length == 1 ? l1.push(el) : l2.push(el);
                });
                abList = l1.sort().concat(l2.sort());

                abList.map((el) => {
                  let lis = res.data.result.filter((e) => e.paperTypeId == el);
                  if (lis.length > 0) {
                    this.assignForm.paperList =
                      this.assignForm.paperList.concat(lis);
                    console.log(this.assignForm.paperList, "list");
                    this.assignForm.paperList.forEach((el) => {
                      this.paperId = el.paperId;
                      console.log(this.paperId, el, "ididid");
                    });
                  }
                  this.assignForm.testAbList.push({
                    abLabel: el + "卷", //AB卷名称
                    list: lis,
                  });
                });
                console.log(
                  this.assignForm.testAbList,
                  "=this.assignForm.testAbList"
                );
                this.assignForm.paperTypeId =
                  this.assignForm.testAbList[0][0].paperTypeId; //设置默认初始场地id
                this.assignForm.paperId =
                  this.assignForm.testAbList[0][0].paperId; //设置默认初始场地id
              } else {
                //未开启AB卷
                this.assignForm.paperList = this.assignForm.paperList.concat(
                  res.data.result
                );
                this.assignForm.testAbList = [
                  {
                    abLabel: "", //空名称
                    list: res.data.result,
                  },
                ];
                console.log(
                  this.assignForm.testAbList,
                  "=this.assignForm.testAbList"
                );
                this.assignForm.paperTypeId = 0; //设置默认初始场地id
                this.assignForm.paperId =
                  this.assignForm.testAbList[0][0].paperId; //设置默认初始场地id
              }
            }

            // this.$forceUpdate();
            this.selectInvigilatorFn(); // 获取监考账号密码列表
          }
        });
      }
    },
    // 考场列表
    // getRoomListFn() {

    // },

    // 分配考场，问卷
    changeAssign() {
      console.log(this.assignForm.invigNum, "invigNum");
      var params = {
        examId: this.examData.examId,
        projectType: this.examData.type,
        vipId: this.examData.vipId,
        invigilatorNum: this.assignForm.invigNum,
      };
      // 按考场分配
      if (this.assignForm.type === 2) {
        params.placeId = this.assignForm.placeId;
      }
      // 按问卷分配
      if (this.assignForm.type === 3) {
        params.paperId = this.paperId;
        params.paperTypeId = this.assignForm.paperTypeId;
      }
      addInvigilatorReq(params).then((res) => {
        if (res.code == "200") {
          console.log(res, "ress");
          this.selectInvigilatorFn();
          this.getStudentCountFn();
        }
      });
    },

    //分配监考账号密码
    changeAssignFn() {
      if (!this.assignForm.invigNum) {
        this.$message.warning("监考人数不正确");
        return false;
      }
      if (this.assignForm.assignNum > this.assignForm.stuTotalNum) {
        this.assignForm.assignNum = this.assignForm.stuTotalNum;
      }

      //监考人员账号、密码生成

      var params = {};
      // 按考场分配
      if (this.assignForm.type === 2) {
        params = {
          examId: this.examData.examId,
          invigilatorNum: this.assignForm.invigNum,
          // 考场参数
          // examPlaceName :
          // examRoomName :
          projectType: this.examData.type,
          vipId: this.examData.vipId,
        };
      }
      // 按问卷分配
      if (this.assignForm.type === 3) {
        params = {
          examId: this.examData.examId,
          invigilatorNum: this.assignForm.invigNum,
          paperId: this.paperId,
          paperTypeId: this.assignForm.paperTypeId,
          projectType: this.examData.type,
          vipId: this.examData.vipId,
        };
      }
      // 随机分配
      if (this.assignForm.type === 1) {
        params = {
          examId: this.examData.examId,
          invigilatorNum: this.assignForm.invigNum,
          projectType: this.examData.type,
          vipId: this.examData.vipId,
        };
      }
      addInvigilatorReq(params).then((res) => {
        if (res.code == "200") {
          this.selectInvigilatorFn();
        }
      });
    },

    //修改账号密码、分配考生
    updateInvigilatorFn(row, type) {
      console.log(row, "row=");
      if (type == 2) {
        let inTotalNum = this.assignForm.dataList.reduce((prev, next) => {
          return Number(prev) + Number(next.stuNum);
        }, 0);
        if (inTotalNum > this.assignForm.stuTotalNum) {
          this.$message.warning("考生人数分配不正确");
          row.stuNum = 0;
          return false;
        }
      }
      if (this.assignForm.type == 2) {
        row.placeId = this.assignForm.placeId;
      }
      updateInvigilatorReq(row).then((res) => {
        if (res.code == "200") {
          this.$message.success("修改成功", "success");
          this.getStudentCountFn();
        }
      });
    },
    //获取考生总数量及已分配数量
    async getStudentCountFn() {
      let params = {
        examId: this.examData.examId,
        projectType: this.examData.type,
        vipId: this.examData.vipId,
      };
      // 按考场分配
      if (this.assignForm.type === 2) {
        params.placeId = this.assignForm.placeId;
      }
      // 按问卷分配
      if (this.assignForm.type === 3) {
        params.paperId = this.paperId;
        params.paperTypeId = this.assignForm.paperTypeId;
      }
      await getStudentCountReq(params).then((res) => {
        if (res.code == "200") {
          this.assignForm.stuTotalNum = res.data.result.studentCount || 0;
          console.log(this.assignForm, "form");
          this.assignForm.assignNum = res.data.result.assignedCount || 0;
        }
      });
    },
    //获取分享数据
    getShareInfoFn() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.$route.query.type,
      };
      getShareInfoReq(params).then((res) => {
        if (res.code == "200") {
          if (res.data.result.shareTitle) {
            this.title = res.data.result.shareTitle;
          }
          if (res.data.result.shareDesc) {
            this.content = res.data.result.shareDesc;
          }
          if (res.data.result.shareLogo) {
            this.imageUrl = res.data.result.shareLogo;
          }

          if (res.data.result.shareTitle1) {
            this.title2 = res.data.result.shareTitle1;
          }
          if (res.data.result.shareDesc1) {
            this.content2 = res.data.result.shareDesc1;
          }
          if (res.data.result.shareLogo1) {
            this.imageUrl2 = res.data.result.shareLogo1;
          }
        }
      });
    },
    closePop() {
      //关闭弹窗的传参
      this.$emit("closePop");
    },
    //获取分享内容
    getHref(type) {
      const share = {
        title: "",
        desc: "",
        share_url: this.jkUrl,
        image_url: "",
      };
      if (type == "wb") {
        return (
          "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pic=" +
          share.image_url +
          "&searchPic=true"
        );
      } else if (type == "qq") {
        return (
          "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&desc=" +
          share.desc
        );
      } else if (type == "qzone") {
        return (
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
          encodeURIComponent(share.share_url) +
          "&sharesource=qzone&title=" +
          share.title +
          "&pics=" +
          share.image_url +
          "&desc=" +
          share.desc
        );
      } else if (type == "wx") {
        return (
          "https://qr.liantu.com/api.php?text=" +
          encodeURIComponent(share.share_url)
        );
      }
    },
    //打开窗口
    postShareFn(type) {
      let url = this.getHref(type);
      window.open(url);
    },
    //复制链接
    onCopy() {
      this.$message.success("内容已复制到剪切板！");
    },
    onError() {
      this.$message.error("抱歉，复制失败！");
    },
    //保存
    submitForm() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.$route.query.type,
        shareDec: this.content,
        shareLogo: this.imageUrl,
        shareTitle: this.title,

        shareTitle1: this.title2,
        shareDec1: this.content2,
        shareLogo1: this.imageUrl2,
      };
      saveShareInfoReq(params).then((res) => {
        if (res.code == "200") {
          this.$message.success("保存成功", "success");
        }
      });
    },

    //禁用方法
    disableFn() {
      this.$message.warning("本项目暂无法使用第二视角");
    },
  },
};
</script>

<style lang="less" scoped>
.assign-input {
  // margin-right:25px;
  /deep/ .el-input {
    width: 80px;
    height: 30px;
  }
  /deep/ .el-input__inner {
    height: 30px !important;
  }
}
.form-warpper {
  /deep/ .el-tabs {
    .el-tabs__header {
      .el-tabs__item {
        font-size: 18px;
      }
    }
  }

  h2 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    line-height: 24px;
  }
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }
  /deep/ .assign-div {
    border-radius: 3px;
    padding: 0px;
    color: #666;
    .assign-box {
      border: 1px solid#e4e4e4;
      margin-top: 15px;
      .assign-left {
        width: 30%;
        border-right: 1px solid#efefef;
        padding: 10px 20px;
        ul {
          li {
            color: #606266;
            border-bottom: 0px dashed #e7e7e7;
            line-height: 38px;
            margin-right: 15px;
            text-align: left;
            span {
              cursor: pointer;
              &.on {
                color: #39c;
              }
            }
          }
          .el-divider__text {
            color: #666;
          }
          .paper-ul {
            max-height: 260px;
            overflow-y: auto;
            li {
              border: 0;
              width: 100%;
              border-bottom: 1px solid#e7e7e7;
              text-align: left;
              height: 38px;
              line-height: 38px;
              margin-right: 0;
              margin-top: 0;
            }
            li.on {
              color: #39c;
            }
            li:last-child {
              border-bottom: 0;
            }
          }
        }
      }
      .assign-right {
        flex: 1;
        width: 300px;
        padding: 0 20px 20px;
      }
    }
    .assign-top {
      padding: 10px 0;
      // border-bottom:1px solid#e4e4e4;

      .one {
        margin-right: 25px;
      }
    }
    // .assign-bottom .one{margin-right:25px;align-items: center;
    //   .el-input{width:calc(100% - 100px;);height:30px;.el-input__inner{height:30px;}}
    // }
    .assign-table {
      .el-input__inner {
        border: 0;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .share-box {
    border-radius: 3px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    //.share{display:flex;justify-content:flex-start;
    .url {
      width: 62%;
      .text {
        float: left;
        width: 65%;
        height: 34px;
        line-height: 34px;
      }
      .btnnull {
        float: left;
        max-width: 70px;
        width: 15%;
        height: 34px;
        border: 1px solid #39c;
        color: #39c;
        margin-left: 2%;
      }
      .btnout {
        float: left;
        width: auto;
        height: 34px;
        margin-right: 2%;
        padding: 0 15px;
        background-color: #fff;
      }
    }
    .orient {
      width: 22%;
      text-align: left;
      padding-left: 10px;
      em {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        margin-right: 12px;
        cursor: pointer;
      }
      em.iconweixin {
        background-color: #00d700;
      }
      em.iconxinlang {
        background-color: #e6162d;
      }
      em.iconqq {
        background-color: #0075c2;
      }
    }
    //}
    .ewm {
      text-align: right;
      border-left: 1px dashed #e4e4e4;
      padding: 0 30px;
      margin-top: -30px;
      div {
        width: 76px;
        p {
          display: block;
          margin: 0px 0px 5px 0;
        }
        .qr-code {
          width: 76px;
          height: 76px;
          padding: 3px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
          img.code {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
      }
    }

    .set {
      width: 60%;
      padding-right: 30px;
      .title {
        margin-bottom: 8px;
      }
      .i-cm {
        float: left;
        width: 80px;
        height: 80px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .upload-demo {
        float: left;
      }
    }
    .view {
      width: 40%;
      padding: 25px 30px 30px;
      background-color: #ebedf1;
      h3 {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
      }
      .share {
        background: #fff;
        padding: 15px;
        color: #666;
        border-radius: 2px;
        .i-cm {
          float: left;
          width: 50px;
          height: 50px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .font {
          width: calc(100% - 65px);
        }
        p {
          line-height: 160%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }
  .monitor .share-box .url {
    width: 95%;
  }

  .share-box {
    padding: 75px 0px 30px;
    h2 {
      position: absolute;
      top: 0px;
    }
  }
  .second {
    border-radius: 3px;
    background-color: #fff;
    &.no {
      div.text {
        border: 1px solid #e4e4e4;
        background-color: #f2f2f2;
        color: #6b6b6b;
        overflow: hidden;
        font-size: 13.33333px;
        padding: 0 5px;
        border-radius: 2px;
      }
      .btnnull {
        border: 1px solid #e4e4e4;
        color: #ccc;
      }
      .qr-code {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.qr-code {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
.set .el-form-item__content {
  line-height: 34px;
}
.set .share-pic .avatar-uploader .el-upload {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.set .share-pic .el-button--primary {
  height: 34px;
  width: 90px;
  border-color: #e4e4e4;
  background-color: #fff;
  color: #666;
  border-radius: 2px;
  font-size: 14px;
}
.set .el-upload__tip {
  margin-top: 30px;
  line-height: 16px;
}
.maxlength .el-input__inner {
  padding-right: 50px;
}
</style>

export default function (axios) {
  return {
    // 发布职位时删除企业
    hjobDeleteVirtualCompReq(data) {
      return axios.post(
        "/headhunter/job/cn/deleteVirtualComp?id=" + data.id,
        data,
        {
          ContentType: "application/json",
        }
      );
    },
    // 发布职位时新增 / 编辑企业
    hjobSaveVirtualCompReq(data) {
      return axios.post("/headhunter/job/cn/saveVirtualComp", data, {
        ContentType: "application/json",
      });
    },
    //注册企业查询{
    hjobSelectCompReq(data) {
      return axios.post("/headhunter/job/cn/selectComp", data, {
        ContentType: "application/json",
      });
    },
    //发布职位招聘企业查询
    hjobSelectZpCompReq(data) {
      return axios.post("/headhunter/job/cn/selectZpComp", data, {
        ContentType: "application/json",
      });
    },
  };
}

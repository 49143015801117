export default {
  parent: "CrmInvoice",
  routers: [
    {
      // 全部
      path: "all",
      name: "CrmInvoiceAll",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 待确认
      path: "wait",
      name: "CrmInvoiceWait",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 已确认
      path: "cfmd",
      name: "CrmInvoiceCfmd",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 已驳回
      path: "reject",
      name: "CrmInvoiceReject",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
    {
      // 已作废
      path: "nullify",
      name: "CrmInvoiceNullify",
      component: () => import("@/views/crm/invoice/invoiceList.vue"),
    },
  ],
};

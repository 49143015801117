<template>
  <div class="index-warpper pr">
    <div class="top">
      <div
        class="vant-img"
        :style="{
          backgroundImage:
            'url(' + headImg ||
            require('@/assets/image/asktrue/topBg/ast1.jpg') + ')',
          backgroundSize: 'cover',
        }"
      >
        <!-- <img style="width: 100%;height: 100%;" :src="headImg"/> -->
      </div>
      <!-- <img class="vant-img" :src="require(transData.headerImg)"/> -->

      <div class="title">
        <p class="text04">{{ detail.papertName || detail.projectName }}</p>
      </div>
      <div class="time">
        <p class="text03">
          {{
            (type != "wj" && type != "wjS" && $route.query.type != "wj"
              ? detail.insertTime
              : detail.createTime) || "--"
          }}
          发布
        </p>
      </div>
      <div class="top-footer">
        <div class="grid">
          <div class="dot"></div>
          <p class="text03">
            样本<span class="number-color">{{
              detail.studentAmount ? detail.studentAmount : 0
            }}</span>
          </p>
        </div>
        <div class="grid">
          <div class="dot"></div>
          <p class="text03">
            评论<span class="number-color">{{
              detail.commentCount ? detail.commentCount : 0
            }}</span>
          </p>
        </div>
        <div class="grid">
          <div class="dot"></div>
          <div class="price">
            <p class="text03">
              {{
                activeAmount.examAmount ? "¥" + activeAmount.examAmount : "免费"
              }}
            </p>
          </div>
          <p class="text03"><i class="el-icon-arrow-down"></i></p>
        </div>
      </div>
    </div>
    <div class="middle" :style="transData.commons ? '' : 'border:0px;'">
      <div class="title"><p class="text04">问卷说明</p></div>
      <div class="title-border"></div>
      <div
        class="content text03"
        v-html="transData.introHtml || detail.paperNote"
      ></div>
    </div>
    <div
      class="middle"
      style="border: none; padding-bottom: "
      v-if="transData.commons"
    >
      <div class="title"><p class="text04">用户评论</p></div>
      <div class="title-border"></div>
      <div class="noComment mt10" v-if="conmments.length == 0">
        <div><p class="text03 c9">暂无评论，快去抢沙发！</p></div>
        <div class="more"><p class="text03">发布评论</p></div>
      </div>
      <div v-if="conmments.length > 0">
        <div v-for="(item, index) in conmments" :key="index" class="comment">
          <img
            class="user"
            src="@/assets/image/asktrue/icon/user.svg"
            width="30"
          />
          <div class="comment-info">
            <div class="time">
              <p class="text03">{{ item.commentTime }} 发布</p>
            </div>
            <div class="evaluate">
              <p class="text03">{{ item.replyComment }}</p>
            </div>
          </div>
        </div>
        <div class="more">
          <p class="text03">
            更多评论<img
              :src="require('@/assets/image/asktrue/icon/icongengduo.svg')"
              style="margin-left: 5px"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="page-bottom">
      <div class="bottom-left">
        <i class="iconxing2 iconfont"></i>
        <p class="text04">收藏</p>
      </div>
      <div class="bottom-left">
        <i class="iconfont iconfenxiang1"></i>
        <p class="text04">分享</p>
      </div>
      <div class="purchase"><p class="text04">立即购买</p></div>
    </div>
  </div>
</template>

<script>
import {
  selectAnonPaperById,
  selectCommentByPaperId,
} from "@/api/asktrue/paper/paper";
import { getProDetailReq, getProEvaReq } from "@/api/asktrue/project/commodity";
import Bus from "@/views/staff/js/bus";
export default {
  props: ["transData", "paperId", "projectId", "type"],
  watch: {
    transData: {
      handler() {
        this.getStyle();
      },
      deep: true,
    },
    paperId() {
      this.createdGetData();
    },
    projectId() {
      this.createdGetData();
    },
  },
  data() {
    return {
      id: this.paperId || this.projectId,
      detail: {},
      activeAmount: [],
      headImg: "",
      //评论列表
      conmments: [],
      showShare: false, //显示分享
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "qq", icon: "qq" },
        { name: "复制链接", icon: "link" },
      ],
      examAmounts: [],
      htmlInfo: "",
    };
  },
  created() {
    this.createdGetData();
    Bus.$on(
      "getDetail",
      function () {
        this.createdGetData();
      }.bind(this)
    );
    Bus.$on(
      "explainChange",
      function (val, type) {
        this.htmlInfo = val;
        if (type) {
          this.detail.paperNote = val;
        }
      }.bind(this)
    );
  },
  deactivated() {
    //离开页面取消监听
    Bus.$off("getDetail");
    Bus.$off("explainChange");
  },
  methods: {
    createdGetData() {
      console.log(444);
      //获取从上一个页面传递的数据
      this.getStyle();
      this.getDetailFn();
      this.getDetailCommentListFn();
    },
    //获取套餐费用
    getPrice() {
      // 价格升序
      this.examAmounts.sort((a, b) => {
        return a.examAmount - b.examAmount;
      });
      if (this.examAmounts.length) {
        this.activeAmount = this.examAmounts[this.examAmounts.length - 1] || {};
      } else {
        this.activeAmount = { paperAmount: 0, examAmount: 0 };
      }
    },
    // 获取样式
    getStyle() {
      let headerImg = this.transData.headerImg;
      this.headImg =
        headerImg.indexOf("@/") > -1
          ? require("@/assets/image/asktrue/topBg/ast" +
              headerImg.match(/[0-9]{1,2}/) +
              ".jpg")
          : headerImg;
    },
    //获取详情信息
    getDetailFn() {
      if (this.type) {
        let params = {
          projectId: this.id,
          projectType: this.type,
        };
        getProDetailReq(params).then((res) => {
          if (res.code == "200") {
            this.detail = res.data.result;
            this.examAmounts = res.data.result.examAmounts || [];
            if (this.htmlInfo) {
              this.detail.goodsText = this.htmlInfo;
            }
            this.getPrice();
          }
        });
      } else {
        selectAnonPaperById({ paperId: this.id }).then((res) => {
          if (res.code == "200") {
            this.detail = res.data.result;
            this.examAmounts = res.data.result.examAmounts || [];
            if (this.htmlInfo) {
              this.detail.paperNote = this.htmlInfo;
            }
            this.getPrice();
          }
        });
      }
    },

    //获取评论列表数据
    getDetailCommentListFn() {
      if (this.type) {
        //项目详情页面
        let params = {
          pageNum: 1,
          pageSize: 5,
          paperId: this.id,
          projectType: this.type,
        };
        getProEvaReq(params).then((res) => {
          if (res.code == "200") {
            this.conmments = res.data.result.list;
          }
        });
      } else {
        let params = { pageNum: 1, pageSize: 5, paperId: this.id };
        selectCommentByPaperId(params).then((res) => {
          if (res.code == "200") {
            this.conmments = res.data.result.list;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.vant-img {
  width: 100%;
  background-size: 100% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 117px;
}
.index-warpper {
  min-height: 620px;
  background-color: #fff;
  font-size: 37.5px;

  .top {
    border-bottom: 0.26em solid #f4f4f4;

    .popover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #fff;
        height: 14%;
      }
    }

    .title {
      padding: 0.3em 0;
      font-weight: bold;
      color: #626164;
    }

    .time {
      color: #999;
    }

    .top-footer {
      width: 70%;
      display: flex;
      justify-content: space-around;
      margin: 0.4em auto 0.7em auto;
      color: #999;

      .grid {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .dot {
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background-color: #ccc;
          margin-right: 5px;
        }

        .number-color {
          color: #3398cc;
          padding-left: 5px;
        }

        .price {
          color: orangered;
          margin-right: 5px;
          font-weight: 550;
        }
      }
    }

    .set-meal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5em 0.5em 0.2em 0.5em;

      .price {
        color: orangered;
      }
    }

    .set-bottom {
      text-align: left;
      color: #999;
      padding-left: 0.5em;
    }
  }

  .middle {
    border-bottom: 0.26em solid #f4f4f4;
    padding-top: 0.3em;

    .title {
      font-weight: bold;
      color: #626164;
      padding: 0.3em 0;
    }

    .title-border {
      width: 1em;
      height: 3px;
      background-color: #e4e4e4;
      margin: 0 auto;
    }

    .comment {
      width: 93%;
      padding: 0.5em 0;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      border-bottom: 1px solid #f4f4f4;

      .comment-info {
        width: 100%;
        padding-left: 0.3em;

        .time {
          color: #999;
        }

        .evaluate {
          width: 90%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-top: 6px;
        }
      }
    }

    .content {
      width: 100%;
      height: auto;
      text-align: left;
      .text {
        font-size: 0.35em;
      }
      padding: 0.7em 1em;
    }

    .more {
      width: 2.9em;
      height: 0.8em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5em auto;
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      color: #999;
    }
  }

  .page-bottom {
    width: 100%;
    height: 1.4em;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #f4f4f4;

    .bottom-left {
      width: 26%;
      height: 1.4em;
      border-right: 1px solid #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .purchase {
      width: 48%;
      height: 1.4em;
      line-height: 1.43em;
      background-color: #3a92d9;
      color: #fff;
    }
  }
}
.text03 {
  font-size: 0.3em;
}
.text04 {
  font-size: 0.4em;
}
.text038 {
  font-size: 0.38em;
}
</style>

export default function (axios) {
  return {
    // //获取登录用户信息
    // export const getUserInfoReq = data => getReq(apiUrl+"/compMemberCtl/selectUserInfoByToken",data)
    getUserInfoReq(params) {
      return axios.get("/compMemberCtl/selectUserInfoByToken", { params });
    },

    // // //新增、编辑认证信息
    // export const postRzInfoReq = data => postReq(apiUrl+"/attestation/save",data,{ContentType:'application/json;charset=UTF-8'})
    // //查询认证信息
    // export const getRzInfoReq = data => getReq(apiUrl+"/attestation/select/"+data)
    // //更改申请状态
    // export const editRzStatusReq = data => postReq(apiUrl+"/attestation/updateApplyStatus",data)
    // // //查询认证信息列表，目前只有个人用户才会出现多条数据
    // export const getRzListReq = data => getReq(apiUrl+"/attestation/selectList",data)

    // 获取阿仕图token
    getAsktrueTokenFromMatcheasy(params) {
      return axios.get("/asktrueUserLinkCtl/getAsktrueToken", { params });
    },

    // 新增阿仕图企业
    insertAsktrueCompanyFromMatcheasy(data) {
      return axios.post("/asktrueUserLinkCtl/insertCompany", data);
    },

    insertCompStaffInfoFromMatcheasy(data) {
      return axios.post("/asktrueUserLinkCtl/insertCompStaffInfo", data);
    },
  };
}

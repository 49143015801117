/*
 * @Description: exam.js
 * @Date: 2023-11-20 10:59:37
 * @LastEditTime: 2024-01-09 09:47:10
 */
import request from "@/assets/plugins/asktrue/request-answer"; // answer 实例

// //获取登录信息
// export function getLoginNoseReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/loginNose" //调查
//       : type == 3
//       ? "/appraise/loginNose" //评估
//       : type == 4
//       ? "/enroll/loginNose" //报名
//       : type == 5
//       ? "/vote/loginNose" //投票
//       : "/exam/loginNose"; //考试
//   return postReq(apiUrl4 + url, data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// }
export function getLoginNoseReq(type, data) {
  let url =
    type == 2
      ? "/investigation/loginNose" //调查
      : type == 3
      ? "/appraise/loginNose" //评估
      : type == 4
      ? "/enroll/loginNose" //报名
      : type == 5
      ? "/vote/loginNose" //投票
      : "/exam/loginNose"; //考试
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// //检测防作弊插件
// export const checkFzbReq = (data) =>
//   getReq("http://127.0.0.1:17681?callback=jsonpcallback&json=" + data);

// //获取考试信息
// export function getExamNoseReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectByIdNose" //调查
//       : type == 3
//       ? "/appraise/selectByIdNose" //评估
//       : type == 4
//       ? "/enroll/selectByIdNose" //报名
//       : type == 5
//       ? "/vote/selectByIdNose" //投票
//       : "/exam/selectByIdNose"; //考试
//   return getReq(apiUrl4 + url, data);
// }
export function getExamNoseReq(type, params) {
  let url =
    type == 2
      ? "/investigation/selectByIdNose" //调查
      : type == 3
      ? "/appraise/selectByIdNose" //评估
      : type == 4
      ? "/enroll/selectByIdNose" //报名
      : type == 5
      ? "/vote/selectByIdNose" //投票
      : "/exam/selectByIdNose"; //考试
  return request({
    url: url,
    method: "get",
    params,
  });
}

// //查询考生必填项信息-旧
// export function getRequiredReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectRequired" //调查
//       : type == 4
//       ? "/enroll/selectRequired" //报名
//       : type == 5
//       ? "/vote/selectRequired" //投票
//       : "/exam/selectRequired"; //考试
//   return getReq(apiUrl4 + url, data);
// }

export function newGetRequiredReq(type) {
  let url =
    type == 2
      ? "/investigation/selectProjRequired" //调查
      : type == 4
      ? "/enroll/selectProjRequired" //报名
      : type == 5
      ? "/vote/selectProjRequired" //投票
      : "/exam/selectProjRequired"; //考试
  return request({
    url: url,
    method: "get",
  });
}

// //提交考生必填项信息-旧
// export function postRequiredReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/saveRequired" //调查
//       : type == 4
//       ? "/enroll/saveRequired" //报名
//       : type == 5
//       ? "/vote/saveRequired" //投票
//       : "/exam/saveRequired"; //考试
//   return postReq(apiUrl4 + url, data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// }

// //提交考生必填项信息-新
// export function newPostRequiredReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/saveProjRequired" //调查
//       : type == 4
//       ? "/enroll/saveProjRequired" //报名
//       : type == 5
//       ? "/vote/saveProjRequired" //投票
//       : "/exam/saveProjRequired"; //考试
//   return postReq(apiUrl4 + url, data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// }
export function newPostRequiredReq(type, data) {
  let url =
    type == 2
      ? "/investigation/saveProjRequired" //调查
      : type == 4
      ? "/enroll/saveProjRequired" //报名
      : type == 5
      ? "/vote/saveProjRequired" //投票
      : "/exam/saveProjRequired"; //考试
  return request({
    url: url,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// //发送验证码(通用)
// export const getExamCodeReq = (data) =>
//   getReq(apiUrl4 + "/exam/verifyCodeNose", data);

// //查询评估对象
// export const getPgListReq = (data) =>
//   getReq(apiUrl4 + "/appraise/selectObjList", data);

// //查询问卷列表
// export function getQuesListReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectPaperList" //调查
//       : type == 3
//       ? "/appraise/selectPaperList" //评估
//       : type == 4
//       ? "/enroll/selectPaperList" //报名
//       : type == 5
//       ? "/vote/selectPaperList" //投票
//       : "/exam/selectPaperList"; //考试
//   return getReq(apiUrl4 + url, data);
// }
export function getQuesListReq(type, params) {
  let url =
    type == 2
      ? "/investigation/selectPaperList" //调查
      : type == 3
      ? "/appraise/selectPaperList" //评估
      : type == 4
      ? "/enroll/selectPaperList" //报名
      : type == 5
      ? "/vote/selectPaperList" //投票
      : "/exam/selectPaperList"; //考试
  return request({
    url: url,
    method: "get",
    params,
  });
}

// //查询当前在线考生基本信息
// export function getStuInfoReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectStudentInfo" //调查
//       : type == 3
//       ? "/appraise/selectStudentInfo" //评估
//       : type == 4
//       ? "/enroll/selectStudentInfo" //报名
//       : type == 5
//       ? "/vote/selectStudentInfo" //投票
//       : "/exam/selectStudentInfo"; //考试
//   return getReq(apiUrl4 + url, data);
// }

export function getStuInfoReq(type, params) {
  let url =
    type == 2
      ? "/investigation/selectStudentInfo" //调查
      : type == 3
      ? "/appraise/selectStudentInfo" //评估
      : type == 4
      ? "/enroll/selectStudentInfo" //报名
      : type == 5
      ? "/vote/selectStudentInfo" //投票
      : "/exam/selectStudentInfo"; //考试
  return request({
    url: url,
    method: "get",
    params,
  });
}

// //开始答题，查询问卷试题
// export function getPaperQuesReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectQuestionList" //调查
//       : type == 3
//       ? "/appraise/selectQuestionList" //评估
//       : type == 4
//       ? "/enroll/selectQuestionList" //报名
//       : type == 5
//       ? "/vote/selectQuestionList" //投票
//       : "/exam/selectQuestionList"; //考试
//   return getReq(apiUrl4 + url, data, {
//     showLoading: true,
//     loadingTarget: ".paper-wrap",
//   });
// }

// //（评估矩阵）开始答题，查询问卷试题
// export function getPaperQuesNewReq(data) {
//   let url = "/appraise/selectQuestionListNew"; //评估
//   return getReq(apiUrl4 + url, data, {
//     showLoading: true,
//     loadingTarget: ".paper-wrap",
//   });
// }

// //答题界面初始是否加载密封条，及密封条显示内容
// export function getPaperQuesPageReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectPaperQuestionPage" //调查
//       : type == 3
//       ? "/appraise/selectPaperQuestionPage" //评估
//       : type == 4
//       ? "/enroll/selectPaperQuestionPage" //报名
//       : type == 5
//       ? "/vote/selectPaperQuestionPage" //投票
//       : "/exam/selectPaperQuestionPage"; //考试
//   return getReq(apiUrl4 + url, data);
// }

// //(评估矩阵样式)答题界面初始是否加载密封条，及密封条显示内容
// export function getPgPaperPageReq(data) {
//   let url = "/appraise/selectPaperQuestionPageNew"; //评估
//   return getReq(apiUrl4 + url, data);
// }

// //提交答案、交卷
// export function saveAnswersReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/saveAnswers" //调查
//       : type == 3
//       ? "/appraise/saveAnswers" //评估
//       : type == 4
//       ? "/enroll/saveAnswers" //报名
//       : type == 5
//       ? "/vote/saveAnswers" //投票
//       : "/exam/saveAnswers"; //考试
//   return postReq(apiUrl4 + url, data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// }

// //（评估矩阵）提交答案、交卷
// export function saveAnswersNewReq(data) {
//   let url = "/appraise/saveAnswersNew"; //评估
//   return postReq(apiUrl4 + url, data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// }

// //查询考生问卷答案
// export function getAnswersReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectAnswers" //调查
//       : type == 3
//       ? "/appraise/selectAnswers" //评估
//       : type == 4
//       ? "/enroll/selectAnswers" //报名
//       : type == 5
//       ? "/vote/selectAnswers" //投票
//       : "/exam/selectAnswers"; //考试
//   return getReq(apiUrl4 + url, data);
// }

// export function selectProjectReq(data) {
//   return getReq(apiUrl4 + "/exam/selectProjectPaperInfo", data);
// }

// //查询答题记录id
// export function getStudentExamIdReq(type, data) {
//   let url =
//     type == 2
//       ? "/investigation/selectProjectStudentDo" //调查
//       : // type==3?'/appraise/selectAnswers'://评估
//       type == 4
//       ? "/enroll/selectProjectStudentDo" //报名
//       : type == 5
//       ? "/vote/selectProjectStudentDo" //投票
//       : "/exam/selectProjectStudentDo"; //考试
//   return getReq(apiUrl4 + url, data);
// }
export function getStudentExamIdReq(type, params) {
  let url =
    type == 2
      ? "/investigation/selectProjectStudentDo" //调查
      : // type==3?'/appraise/selectAnswers'://评估
      type == 4
      ? "/enroll/selectProjectStudentDo" //报名
      : type == 5
      ? "/vote/selectProjectStudentDo" //投票
      : "/exam/selectProjectStudentDo"; //考试
  return request({
    url: url,
    method: "get",
    params,
  });
}

// //（评估矩阵）查询问卷答案
// export function getAnswersNewReq(data) {
//   let url = "/appraise/selectAnswersNew"; //评估
//   return getReq(apiUrl4 + url, data);
// }

// //考生开启视频监控获取sig，各种形式项目登录通用
// export const getGenSigReq = (data) => postReq(apiUrl4 + "/player/genSig", data);
// //监考员开启视频监控获取sig
// export const getSigNoseReq = (data) =>
//   postReq(apiUrl4 + "/player/genSigNose", data);
// //考生成功加入房间开启视频通话时调用
// export const joinRoomReq = (data) =>
//   postReq(apiUrl4 + "/player/joinRoom", data);

// //文件上传
// export const postUplodeReq = (data) =>
//   uplodeReq(
//     apiUrl4 +
//       "/minIOApi/putObjects?fileDirType=" +
//       data.fileDirType +
//       "&paperId=" +
//       data.paperId +
//       "&studentExamId=" +
//       data.studentExamId,
//     data.fileData
//   );

// // 评估类项目自评检测发送验证码
// export function checkVerifyCodeFn(data) {
//   let url = "/appraise/selfRoleCheckVerifyCode";
//   return getReq(apiUrl4 + url, data);
// }
// // 评估类项目自评检测
// export function getSelfRoleCheckCodeFn(data) {
//   let url = "/appraise/selfRoleCheck";
//   return getReq(apiUrl4 + url, data);
// }

// // 执行代码
// export function getRunOnlineNoseFn(data) {
//   let url = "/exam/runOnlineNose";
//   return getReq(apiUrl4 + url, data);
// }
export function getRunOnlineNoseFn(params) {
  return request({
    url: "/exam/runOnlineNose",
    method: "get",
    params,
  });
}

// // 获取 核身跳转地址
// export function getFaceParamsFn(data) {
//   let url = "/anon/faceRecognitionCtl/startCheckFace";
//   return getReq(apiUrl4 + url, data);
// }

// // 获取 验证结果
// export const getFaceResultReq = (data) =>
//   postReq(apiUrl4 + "/anon/faceRecognitionCtl/serverCheck", data, {
//     ContentType: "application/json;charset=UTF-8",
//   });
// //获取token
// export const getStuTokenReq = (data) =>
//   getReq(apiUrl4 + "/anon/faceRecognitionCtl/getToken", data);
// // 分配考生
// export const addInvigilatorReq = (data) =>
//   getReq(apiUrl4 + "/anon/projectInvigilate/addInvigilator", data);
// // 获取考生考场
// export const aselectExamRoomReq = (data) =>
//   getReq(apiUrl4 + "/anon/projectInvigilate/selectExamRoom", data);

// //获取系统参数通用方法
// export const selectParams = (data) =>
//   getReq(apiUrl4 + "/exam/getDictNose", data);
export function selectParams(params) {
  return request({
    url: "/exam/getDictNose",
    method: "get",
    params,
  });
}

// //根据域名获取参数(wx_appid_kf为微信分享所需参数，缺省时，使用前端现用值为默认值)
// export const getParamNoseReq = (data) =>
//   postReq(apiUrl4 + "/player/getParamNose", data);

// //获取该考生的房间号
// export const getStudentRoomReq = (data) =>
//   getReq(apiUrl4 + "/exam/getStudent", data);
// //获取考场以及考场里面的考生
// export const getRoomStudentReq = (data) =>
//   getReq(apiUrl4 + "/exam/getRoomStudentNose", data);
// //小程序码
// export const postXcxEwmReq = (data) =>
//   postReq(apiUrl4 + "/anon/smallRoutine/code", data);

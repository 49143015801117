/*
 * @Description: 沟通相关的api
 * @Date: 2024-04-09 16:19:24
 * @LastEditTime: 2024-05-06 17:46:43
 */


export default function (axios) {
  return {
// /communication/selectAll  全部沟通列表
matcheasyCommunicationSelectAll(data){
  return axios.post(
    "/communication/selectAll?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectNewGreetings  新招呼列表
matcheasyCommunicationSelectNewGreetings(data){
  return axios.post(
    "/communication/selectNewGreetings?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectCommunications  沟通中列表
matcheasyCommunicationSelectCommunications(data){
  return axios.post(
    "/communication/selectCommunications?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectCollectComp  关注我列表（招聘者）
matcheasyCommunicationSelectCollectComp(data){
  return axios.post(
    "/communication/selectCollectComp?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectCollectHh  关注我列表（猎头）
matcheasyCommunicationSelectCollectHh(data){
  return axios.post(
    "/communication/selectCollectHh?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectCompCollection 我关注列表（招聘者），关注我列表（求职者）
matcheasyCommunicationSelectCompCollection(data){
  return axios.post(
    "/communication/selectCompCollection?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/selectUserCollection  我关注列表（求职者）
matcheasyCommunicationSelectUserCollection(data){
  return axios.post(
    "/communication/selectUserCollection?jobId=" + data.jobId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

//  /communication/selectDelivered  已投递列表
matcheasyCommunicationSelectDelivered(data){
  return axios.post(
    "/communication/selectDelivered?jobId=" + data.jobId + "&processStatus=" + data.processStatus,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /resume/updateChannelAttachment 更新投递职位的附件简历
// 沟通中可能多次发送附件简历，更新投递职位的附件简历
matcheasyCommunicationUpdateChannelAttachment(data){
  return axios.post(
    "/resume/updateChannelAttachment?jobId=" + data.jobId + "&resumeId=" + data.resumeId + "&attachmentUrl=" + data.attachmentUrl,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},

// /communication/getInfoByUserId  根据id查询hr或企业基本信息
matcheasyCommunicationGetInfoByUserId(data){
  return axios.post(
    "/communication/getInfoByUserId?userId=" + data.userId,
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},


// /communication/selectAllChat  聊天列表
matcheasyCommunicationSelectAllChat(data){
  return axios.post(
    "/communication/selectAllChat",
    data,
    {
      ContentType: "application/json;charset=UTF-8",
    }
  );
},
  }
}
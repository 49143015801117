import { Message } from "element-ui";

let storage = {};

//封装过期控制代码

//设置缓存数据
/*
 * set 存储方法
 * @ param {String}  key 键
 * @ param {String}  value 值，
 * @ param {String}  expired 过期时间，以分钟为单位，非必须
 */
storage.set = function (key, value, expired) {
  //获取时间戳
  let time = Date.now() + 1000 * 60 * 60 * 10; //默认保存10小时
  if (expired) {
    time = Date.now() + 1000 * 60 * expired;
  }

  //定义一个前缀，表示只删除自己定义的缓存
  //const cachePrefix = 'SERVICE_QR_';

  try {
    localStorage.setItem(key, value);
  } catch (e) {
    //localStorage.clear()
    // localStorage容量不够，根据保存的时间删除已缓存到 localStorage
    if (e.name.toUpperCase().indexOf("QUOTA") >= 0) {
      let item;
      const tempArr = [];
      // 先把所有的缓存对象来出来，放到 tempArr 里

      for (item in localStorage) {
        if (item.indexOf("token") == -1) {
          tempArr.push(item);
        }
      }
      // 如果有缓存对象
      if (tempArr.length) {
        // 按缓存时间升序排列数组
        tempArr.sort((a, b) => a.stamp - b.stamp);
        // 删除缓存时间最早的
        localStorage.removeItem(tempArr[0]);
        // 删除后在再添加，利用递归完成
        storage.set(key, value);
      }
    }
  }

  return localStorage.setItem(
    key,
    JSON.stringify({
      data: value,
      expired: time,
    })
  );
};
//获取缓存数据
storage.get = function (key, lIndex) {
  let data = localStorage.getItem(key);
  if (!data) {
    return false;
  }
  //转为对象
  var dataObj = JSON.parse(data);
  let expired = dataObj.expired || Date.now() + 1;
  const now = Date.now();
  //console.log(key,now,expired)
  if (now >= expired) {
    localStorage.removeItem(key);
    if (key == "token" && !lIndex) {
      Message("登录信息已失效，请重新登录");
      //localStorage.clear();
    }
    return;
  } else {
    return dataObj.data;
  }
};

//移除缓存数据
storage.remove = function (key) {
  localStorage.removeItem(key);
};

export default storage;

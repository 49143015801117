const Evaluate = {
  "请对本次服务进行评价": "请对本次服务进行评价",
  "提交评价": "提交评价",
  "服务评价工具": "服务评价工具",
  "使用": "使用",
  "自定义消息": "自定义消息",
  "搭建": "搭建",
  "星": "星"
}

export default Evaluate;

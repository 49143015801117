// import { postReq, getReq, delReq, uplodeReq } from "@/api/apiReq";
// import {
//   apiUrl6,
//   apiUrl7,
//   apiUrl8,
//   apiUrl10,
//   apiUrl11,
//   apiUrl12,
// } from "@/api/config";
export default function (axios) {
  return {
    // //删除安排
    // export function delExamReq(type, data) {
    //   let url =
    //     type == 1
    //       ? apiUrl6 + "/examPlan/deleteExamPlan"
    //       : type == 2
    //       ? apiUrl7 + "/interviewPlan/deleteInterviewPlan"
    //       : type == 3
    //       ? apiUrl8 + "/physicalPlan/deletePhysicalPlan"
    //       : "";
    //   return postReq(url, data, { ContentType: "application/json" });
    // }
    // // 新增/编辑笔试项目
    // export const editExamReq = (data) =>
    //   postReq(apiUrl6 + "/examPlan/editExamPlan", data, {
    //     ContentType: "application/json",
    //   });
    // //更新签到设置
    // export function examSigneSetReq(type, data) {
    //   let url =
    //     type == "1"
    //       ? apiUrl6 + "/examPlan/editSigneSet"
    //       : type == "2"
    //       ? apiUrl7 + "/interviewPlan/editSigneSet"
    //       : type == "3"
    //       ? apiUrl8 + "/physicalPlan/editSigneSet"
    //       : "";
    //   return postReq(url, data);
    // }
    // // 单个、批量以及全部导出笔试安排
    // export const exportExamPlanReq = (data) =>
    //   getReq(apiUrl6 + "/examPlan/exportExamPlanExcel", data);
    // // 导出笔试签到
    // export const exportExamSigneReq = (data) =>
    //   getReq(apiUrl6 + "/examPlan/exportExamSigneExcel", data);
    // // 导入笔试安排
    // export const uploadExamPlanReq = (data, params) =>
    //   uplodeReq(apiUrl6 + "/examPlan/importExamPlan?planId=" + params.planId, data);
    // // 查询单个笔试安排详情
    // export const selectByPrimaryKeyReq = (data) =>
    //   postReq(apiUrl6 + "/examPlan/selectByPrimaryKey", data, {
    //     ContentType: "application/json",
    //   });
    // // 笔试安排列表分页查询
    // export const examPlanList = (data) =>
    //   getReq(apiUrl6 + "/examPlan/selectPage", data);
    // // 考号相关
    // // 新增考号规则
    // export const editPlanStnoReq = (data) =>
    //   postReq(
    //     apiUrl6 +
    //       "/stnoRlue/editPlanStno?planId=" +
    //       (data.planId || "") +
    //       "&isPsd=" +
    //       data.isPsd,
    //     data,
    //     { ContentType: "application/json" }
    //   );
    // // 新增笔试人员的时候 根据规则生成考号
    // export const createExamNumReq = (data) =>
    //   postReq(apiUrl6 + "/stnoRlue/createExamNum", data);
    // // 删除考号规则
    // export const delStnoReq = (data) =>
    //   delReq(apiUrl6 + "/stnoRlue/deleteByPrimaryKey", data);
    // // 查询考号规则列表
    // export const stnoListReq = (data) =>
    //   getReq(apiUrl6 + "/stnoRlue/selectPage", data);
    // // 通过id查询详情
    // export const selectByStnoIdReq = (data) =>
    //   postReq(apiUrl6 + "/stnoRlue/selectByStnoId", data);
    // // 考生相关
    // // 分页查询笔试人员
    // export const examMemberListReq = (data) =>
    //   getReq(apiUrl6 + "/examMember/selectPage", data);
    // // 分页查询笔试人员  新
    // export const examMemberListReqNew = (data) =>
    //   postReq(apiUrl6 + "/examMember/selectPageNew", data);
    // // 笔试统计
    // export const examMemberCount = (data) =>
    //   getReq(apiUrl6 + "/examMember/deCount", data);
    // // 获取笔试的时间场地筛选
    // export const examMemberPlaceAndDate = (data) =>
    //   getReq(apiUrl6 + "/examMember/getFilterData", data);
    // // 新增/编辑笔试/面试/体检人员
    // export function editExamMemberReq(type, data, params) {
    //   let url =
    //     type == "written"
    //       ? apiUrl6 + "/examMember/editExamMember"
    //       : type == "interview"
    //       ? apiUrl7 + "/interviewMember/editInterviewMember"
    //       : type == "physical"
    //       ? apiUrl8 + "/physicalMember/editPhysicalMember"
    //       : "";
    //   if (params) {
    //     url = url + "?";
    //     for (let key in params) {
    //       url += key + "=" + params[key] + "&";
    //     }
    //   }
    //   return postReq(url, data, { ContentType: "application/json" });
    // }
    // // 简历内新增/编辑笔试人员
    // export function editResumeExamMemberReq(data, params) {
    //   let url = "/examMember/editResumeExamMember";
    //   if (params) {
    //     url = url + "?";
    //     for (let key in params) {
    //       if (key) {
    //         url += key + "=" + params[key] + "&";
    //       }
    //     }
    //   }
    //   return postReq(apiUrl6 + url, data, { ContentType: "application/json" });
    // }
    // // 删除笔试人员
    // export const delExamMemberReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/delete", data);
    // //关闭笔试or面试or体检项目
    // export const editPlanStatusReq = (data) =>
    //   postReq(apiUrl6 + "/examPlan/editPlanStatus", data);
    // // 查询详细的笔试安排人员的信息
    // export const getExamArrangeInfoReq = (data) =>
    //   postReq(apiUrl6 + "/examPlan/selectExamArrangeInfo", data);
    // //签到相关
    // //登录
    // export const confirmSignReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/confirmTelNose", data);
    // //查询人员签到信息
    // export const confirmSignNoseReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/confirmSignNose", data);
    // // 更新人员签到状态
    // export const editExamSignStatusReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/confirmSigneStatusNose", data);
    // //自动生成一个考号和密码
    // export const createStnoReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/createStno", data);
    // //笔试安排获取职位数据--停用
    // export const getJobNameByPlanIdReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/getJobNameByPlanId?planId=" + data.planId);
    // export const addExamTimeReq = (data) =>
    //   postReq(apiUrl6 + "/examPlan/addExamTime", data, {
    //     ContentType: "application/json",
    //   });
    // //笔试安排获取阿仕图同步考试数据
    // export const getSyncExamReq = (data) =>
    //   getReq(apiUrl10 + "/projectBase/getExamNamNose", data);
    // //笔试安排-长链接转短网址(好招用)
    // export const getShorlUrlReq = (data) =>
    //   postReq(apiUrl10 + "/dwz/getShorlUrlNose", data);
    // //笔试安排-查询同步考试下-考生成绩
    // //export const getSyncScoreDetailNoseReq = data => getReq(apiUrl10+"/project/score/selectScoreDetailNose",data)
    // export const getSyncPaperListNoseReq = (data) =>
    //   getReq(apiUrl10 + "/project/score/selectPaperListNose", data);
    // //笔试安排-时间场地职位查询
    // export const getJobTimePlaceByPlanIdReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/selectTimeJobPlace", data);
    // //新增考试以及分数
    // export const getInserList = (data) =>
    //   postReq(apiUrl6 + "/examCtl/inserList", data, {
    //     ContentType: "application/json",
    //   });
    // //查询考试以及分数
    // export const getStudentInfo = (data) =>
    //   postReq(apiUrl6 + "/examCtl/selecList", data);
    // //删除考试
    // export const delExamScore = (data) =>
    //   postReq(apiUrl6 + "/examCtl/delExam", data);
    // //根据计划id查询考试名称以及id
    // export const getExamMap = (data) =>
    //   postReq(apiUrl6 + "/examCtl/selectExamMap", data);
    // //查询单个考试以及维度
    // export const getExam = (data) => postReq(apiUrl6 + "/examCtl/selectExam", data);
    // //新增分数
    // export const addScore = (data) =>
    //   postReq(apiUrl6 + "/examCtl/insertScore", data, {
    //     ContentType: "application/json",
    //   });
    // //导出成绩
    // export const ExportExam = (data) =>
    //   postReq(apiUrl6 + "/examMember/exportRecordNose", data);
    // //导入成绩
    // export const uploadExamReq = (data, params) =>
    //   uplodeReq(
    //     apiUrl6 +
    //       "/examCtl/importExamScore?access_token=" +
    //       params.token +
    //       "&planId=" +
    //       params.planId,
    //     data
    //   ); //批量导入通知内容 其实导入的就是信息项的值
    // //考生开启视频监控获取sig，各种形式项目登录通用
    // export const getGenSigReq = (data) =>
    //   postReq(apiUrl11 + "/player/genSig", data);
    // //监考员开启视频监控获取sig
    // export const getSigNoseReq = (data) =>
    //   postReq(apiUrl11 + "/player/genSigNose", data);
    // //根据域名获取参数(wx_appid_kf为微信分享所需参数，缺省时，使用前端现用值为默认值)
    // export const getParamNoseReq = (data) =>
    //   postReq(apiUrl11 + "/player/getParamNose", data);
    // //导出报告
    // export const exportProjectReportReq = (data) =>
    //   getReq(apiUrl12 + "/fileMake/exportProjectReportNose", data); //导出报告--获取code代码,不需要token
    // //获取报告
    // export const getProjectReportReq = (data) =>
    //   getReq(apiUrl12 + "/fileMake/getExportUrlNose", data); //导出报告--获取code代码--获取code代码,不需要token
    // // 文件操作
    // export const perviewFileReq = (data) =>
    //   postReq(apiUrl12 + "/minIOApi/previewNose", data); //预览
    // export const getMemberOneNoseReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/getMemberOneNose", data, {
    //     ContentType: "application/json",
    //   }); //获取录用的报道地点
    // //判断这个人是否已经安排到该笔试安排下面
    // export const selectMemberNumReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/selectMemberNum", data);
    // // 判断这个人是否已经安排到该笔试安排下面（报考相关）
    // export const selectMemberNumEnrollReq = (data) =>
    //   postReq(apiUrl6 + "/examMember/selectMemberNumEnroll", data);
    // // 审批前查询下一流程阶段
    // export const getNextStageReq = (data) =>
    //   getReq(apiUrl6 + "/examMember/getNextStage", data);
    // // 笔试审批
    // export const updatePlanApprovalBsReq = (data, channelId) =>
    //   postReq(
    //     apiUrl6 + `/examMember/updatePlanApproval?channelId=${channelId}`,
    //     data,
    //     {
    //       ContentType: "application/json",
    //     }
    //   );
  };
}

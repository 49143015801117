export default function (axios) {
  return {
    // 文档——增
    doc_add(params) {
      return axios.post("/cDocumentRecord/addDocument", params);
    },
    // 文档——查(分页)
    doc_select(params) {
      return axios.get("/cDocumentRecord/selectPage", { params });
    },

    // //用户登录
    // export const postLoginReq = data => postReq(apiUrl+"/anon/generateToken",data)

    // // /interviewPlan/getCount  面试统计
    // export const getCountOfinterviewPlan = () => getReq(apiUrl7+"/interviewPlan/getCount")
    getCountOfinterviewPlan(params) {
      return axios.get("/interviewPlan/getCount", { params });
    },
    // 获取考试地点列表
    // export const getInterviewPlaceListReq = (params, data) =>
    //   postReq(apiUrl7 + "/interviewPlace/selectPage?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize, data);
    getInterviewPlaceListReq(params, data) {
      return axios.post(
        "/interviewPlace/selectPage?pageNum=" +
          params?.pageNum +
          "&pageSize=" +
          params?.pageSize,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    },
    // 根据报考者报名id查询面试人员的详细信息 无需token
    selectMemberByChannelIdNoseReq(data) {
      return axios.post(
        "/interviewMember/selectMemberByChannelIdNose?planId=" +
          data.planId +
          "&channelId=" +
          data.channelId,
        data,
        {
          ContentType: "application/json",
        }
      );
    },
    // // 删除面试安排
    // export const delInterviewReq = data => postReq(apiUrl7+"/interviewPlan/deleteInterviewPlan", data, {ContentType: 'application/json'})
    delInterviewReq(params) {
      return axios.post("/interviewPlan/deleteInterviewPlan", params);
    },
    //更新签到设置
    examSigneSetReqMs(params) {
      // let url = type == '1' ? '/examPlan/editSigneSet' : type == '2' ? apiUrl7 + '/interviewPlan/editSigneSet' : type == '3' ? apiUrl8 + '/physicalPlan/editSigneSet' : ''
      return axios.post("interviewPlan/editSigneSet", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //删除安排
    // delExamReqMs(params) {
    //   return axios.post("/interviewPlan/deleteInterviewPlan", params);
    // },
    // // 新增面试安排项目
    // export const editInterviewPlanReq = data => postReq(apiUrl7+"/interviewPlan/editInterviewPlan", data, {ContentType: 'application/json'})
    editInterviewPlanReq(params) {
      return axios.post("/interviewPlan/editInterviewPlan", params);
    },
    // // 单个、批量以及全部导出面试安排
    // export const exportInterviewReq = data => getReq(apiUrl7+"/interviewPlan/exportInterviewPlanExcel", data)
    exportInterviewReq(params) {
      return axios.get("/interviewPlan/exportInterviewPlanExcel", { params });
    },
    // // 导出面试签到
    // export const exportInterviewSigneReq = data => getReq(apiUrl7+"/interviewPlan/exportInterviewSigneExcel", data)
    exportInterviewSigneReq(params) {
      return axios.get("/interviewPlan/exportInterviewSigneExcel", { params });
    },
    // // 导入面试安排
    // export const uploadInterviewReq = (data,params) => uplodeReq(apiUrl7+"/interviewPlan/importInterviewPlan?planId="+params.planId, data)
    // // 查询单个面试安排详情
    // export const getInterviewReq = data => postReq(apiUrl7+"/interviewPlan/selectByPrimaryKey", data, {ContentType: 'application/json'})
    getInterviewReq(params) {
      return axios.post("/interviewPlan/selectByPrimaryKey", params);
    },
    // // 查询单个安排面试项目详情 无需token
    // export const getInterviewWithoutTokenReq = (data, params) => postReq(apiUrl7+"/interviewPlan/selectByPrimaryKeyNose?vipId=" + params.vipId, data, {ContentType: 'application/json'})
    getInterviewWithoutTokenReq(data, params) {
      return axios.post(
        "/interviewPlan/selectByPrimaryKeyNose?vipId=" + params.vipId,
        data
      );
    },
    // // 面试安排列表分页查询
    // export const interviewPlanList = data => getReq(apiUrl7+"/interviewPlan/selectPage", data)
    interviewPlanList(params) {
      return axios.get("/interviewPlan/selectPage", { params });
    },
    // // 面试评分
    // // 编辑面试评分表
    // export const editScorePaperReq = data => postReq(apiUrl7+"/planScore/editPlaceScore", data, {ContentType: 'application/json'})
    editScorePaperReq(params) {
      return axios.post("/planScore/editPlaceScore", params);
    },
    // // 查询面试评分表详情
    // export const searchScorePaperReq = data => postReq(apiUrl7+"/planScore/selectByPrimaryKey", data, {ContentType: 'application/json'})
    searchScorePaperReq(params) {
      return axios.post("/planScore/selectByPrimaryKey", params);
    },
    // // 获取面试评分表列表
    // export const getScorePaperListReq = data => getReq(apiUrl7+"/planScore/selectPage", data)
    getScorePaperListReq(params) {
      return axios.get("/planScore/selectPage", { params });
    },
    // // 删除面试评分表
    // export const delScorePaperReq = data => postReq(apiUrl7+"/planScore/deletePlanScore?scoreIds=" + data.scoreIds + "&planId=" + data.planId, data, {ContentType: 'application/json'})
    delScorePaperReq(data) {
      return axios.post(
        "/planScore/deletePlanScore?scoreIds=" +
          data.scoreIds +
          "&planId=" +
          data.planId,
        data
      );
    },
    // // 面试人员
    // // 编辑面试人员
    // export const editInterviewMemberReq = data => postReq(apiUrl7+"/interviewMember/editInterviewMember", data, {ContentType: 'application/json'})
    editInterviewMemberReq(params) {
      return axios.post("/interviewMember/editInterviewMember", params);
    },
    editExamMemberReqMs(type, data, params) {
      let url = "/interviewMember/editInterviewMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          url += key + "=" + params[key] + "&";
        }
      }
      return axios.post(url, data);
    },
    // // // 简历内新增/编辑面试人员
    // // export const editJianLiInterviewMemberReq = (data,params) => postReq(apiUrl+"/interviewMember/editJianLiInterviewMember?score_id="+params.score_id+"&channelId="+params.channelId+"&jobId="+params.jobId+"&resumeId="+params.resumeId+"&stageId="+params.stageId+"&needConfirm="+params.needConfirm, data, {ContentType: 'application/json'})
    // editJianLiInterviewMemberReq(data, params) {
    //   return axios.post(
    //     "/interviewMember/editJianLiInterviewMember?score_id=" +
    //     params.score_id +
    //     "&channelId=" +
    //     params.channelId +
    //     "&jobId=" +
    //     params.jobId +
    //     "&resumeId=" +
    //     params.resumeId +
    //     "&stageId=" +
    //     params.stageId +
    //     "&needConfirm=" +
    //     params.needConfirm,
    //     data
    //   );
    // },
    // // 简历内新增/编辑面试人员
    // export function editJianLiInterviewMemberReq(data,params) {
    //   let url='/interviewMember/editJianLiInterviewMember'
    //   if(params){
    //     url=url+'?'
    //     for(let key in params){
    //       if(key){url+=key+'='+params[key]+'&'}
    //     }
    //   }
    //   return postReq(apiUrl7+url ,data, {ContentType: 'application/json'})
    // }
    editJianLiInterviewMemberReq(data, params) {
      let url = "/interviewMember/editJianLiInterviewMember";
      if (params) {
        url = url + "?";
        for (let key in params) {
          if (key) {
            url += key + "=" + params[key] + "&";
          }
        }
      }
      return axios.post(url, data);
    },
    // // 更新面试人员签到状态
    // export const editInterviewSignStatusReq = data => postReq(apiUrl7+"/interviewMember/editSigneStatus", data, {ContentType: 'application/json'})
    editInterviewSignStatusReq(params) {
      return axios.post("/interviewMember/editSigneStatus", params);
    },
    // // 分页查询面试人员
    // export const getInterviewMemberListReq = data => getReq(apiUrl7+"/interviewMember/selectPage", data)
    getInterviewMemberListReq(params) {
      return axios.get("/interviewMember/selectPage", { params });
    },

    // 分页查询面试人员 无 token  用在面试项目 评委后台
    // export const getInterviewMemberListWithoutTokenReq = (data) =>
    // getReq(apiUrl7 + "/interviewMember/selectPageNose", data);
    getInterviewMemberListWithoutTokenReq(params) {
      return axios.get("/interviewMember/selectPageNose", { params });
    },

    // // /interviewMember/selectPageNew  分页查询面试人员（新）
    // export const getInterviewMemberListNewReq = data => postReq(apiUrl7 + "/interviewMember/selectPageNew", data)
    getInterviewMemberListNewReq(params) {
      return axios.post("/interviewMember/selectPageNew", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // /interviewMember/getFilterData   获取面试人员的筛选数据
    // export const getInterviewMemberFilterDataReq = data => getReq(apiUrl7+"/interviewMember/getFilterData", data)
    getInterviewMemberFilterDataReq(params) {
      return axios.get("/interviewMember/getFilterData", { params });
    },
    // // 删除面试人员
    // export const delInterviewMemberReq = data => postReq(apiUrl7+"/interviewMember/deleteInterviewMember", data, {ContentType: 'application/json'})
    delInterviewMemberReq(params) {
      return axios.post("/interviewMember/deleteInterviewMember", params);
    },
    // // 新增或者编辑外部评委
    // export const editOutInterviweReq = data => postReq(apiUrl7+"/outInterview/editOutInterviwe", data, {ContentType: 'application/json'})
    editOutInterviweReq(params) {
      return axios.post("/outInterview/editOutInterviwe", params);
    },
    // // 查询评委（外部）
    // export const selectInterviewReq = data => postReq(apiUrl7+"/outInterview/selectPage", data)
    selectInterviewReq(params) {
      return axios.post("/outInterview/selectPage", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // /outInterview/selectAllPage 查询评委 （内部加外部）
    // export const selectInterviewerAllReq = data => postReq(apiUrl7+"/outInterview/selectAllPage", data)
    selectInterviewerAllReq(params) {
      return axios.post("/outInterview/selectAllPage", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // 查询评委 无需 token
    // export const selectInterviewWithOutTokenReq = data => postReq(apiUrl7+"/outInterview/selectPageNose", data)
    selectInterviewWithOutTokenReq(params) {
      return axios.post("/outInterview/selectPageNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // 批量导入评价
    // export const importCommentReq = (data,params) => uplodeReq(apiUrl7+"/interviewPlan/importComment?planId="+params.planId, data)

    // // 导出面试评价
    // export const exportCommentExcelReq = (data) => postReq(apiUrl7+"/interviewPlan/exportCommentExcel", data)
    exportCommentExcelReq(params) {
      return axios.post("/interviewPlan/exportCommentExcel", params);
    },
    // // 查询详细的面试安排人员的信息
    // export const getExamArrangeInfoReq = data => postReq(apiUrl6+"/examPlan/selectInterviewArrangeInfo", data)
    getExamArrangeInfoReq(params) {
      return axios.post("/examPlan/selectInterviewArrangeInfo", params);
    },
    // export const addInterviewTimeReq = data => postReq(apiUrl7+"/interviewPlan/addInterviewTime",data, {ContentType: 'application/json'})
    addInterviewTimeReq(params) {
      return axios.post("/interviewPlan/addInterviewTime", params);
    },
    // //面试安排获取职位数据
    // export const getJobNameByPlanIdReq = data => postReq(apiUrl7+"/interviewMember/getJobNameByPlanId?planId="+data.planId)
    getJobNameByPlanIdReq(data) {
      return axios.post(
        "/interviewMember/getJobNameByPlanId?planId=" + data.planId
      );
    },
    // export const editQueReq = data => postReq(apiUrl7+"/interviewPlan/editQuestion",data, {ContentType: 'application/json'})  //新增或者编辑面试试题
    editQueReq(params) {
      return axios.post("/interviewPlan/editQuestion", params);
    },
    // export const delQueReq = data => postReq(apiUrl7+"/interviewPlan/deleteQuestion",data)  //删除面试试题
    delQueReq(params) {
      return axios.post("/interviewPlan/deleteQuestion", params);
    },
    // export const getQueReq = data => getReq(apiUrl7+"/interviewPlan/selectQuestion", data)  //面试试题查询
    getQueReq(params) {
      return axios.get("/interviewPlan/selectQuestion", { params });
    },
    // export const getQueReqWithoutToken = data => getReq(apiUrl7+"/interviewPlan/selectQuestionNose", data)  //面试试题查询 无需token
    getQueReqWithoutToken(params) {
      return axios.get("/interviewPlan/selectQuestionNose", { params });
    },
    // export const editQueTypeReq = data => postReq(apiUrl7+"/interviewPlan/editQuestionType",data, {ContentType: 'application/json'})  //新增或者编辑面试试题分类
    editQueTypeReq(params) {
      return axios.post("/interviewPlan/editQuestionType", params);
    },
    // export const delQueTypeReq = data => postReq(apiUrl7+"/interviewPlan/deleteQuestionType",data)  //删除面试试题分类
    delQueTypeReq(params) {
      return axios.post("/interviewPlan/deleteQuestionType", params);
    },
    // export const getQueTypeReq = data => getReq(apiUrl7+"/interviewPlan/selectQuestionType", data)  //面试试题分类查询
    getQueTypeReq(params) {
      return axios.get("/interviewPlan/selectQuestionType", { params });
    },
    // // 面试试题分类查询 无需token
    // export const getQueTypeReqWithoutToken = data => getReq(apiUrl7+"/interviewPlan/selectQuestionTypeNose", data)
    getQueTypeReqWithoutToken(params) {
      return axios.get("/interviewPlan/selectQuestionTypeNose", { params });
    },
    // //面试登录接口
    // export const interviewLoginReq = data => postReq(apiUrl7+"/interviewPlan/interviewLoginNose",data)  //删除面试试题分类
    interviewLoginReq(params) {
      return axios.post("/interviewPlan/interviewLoginNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // export const selectIfExistTelReq = data => postReq(apiUrl7+"/interviewPlan/selectIfExistTelNose",data)  //判断面试人员或者评委的手机号是否存在
    selectIfExistTelReq(params) {
      return axios.post("/interviewPlan/selectIfExistTelNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // export const selectTimeJobPlaceReq = data => postReq(apiUrl7+"/interviewPlan/selectTimeJobPlace",data)  //查询面试的时间职位场地
    selectTimeJobPlaceReq(params) {
      return axios.post("/interviewPlan/selectTimeJobPlace", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // export const selectIfExistByOutReq = data => postReq(apiUrl7+"/outInterview/selectIfExistByOut",data)  //查询该外部评委是否可以删除
    selectIfExistByOutReq(params) {
      return axios.post("/outInterview/selectIfExistByOut", params);
    },
    // export const deleteOutInterviewReq = data => postReq(apiUrl7+"/outInterview/deleteOutInterview",data)  //删除外部评委,删除前需要调用另一个（上面这个selectIfExistByOutReq）查询是否能删除外部评委的接口
    deleteOutInterviewReq(params) {
      return axios.post("/outInterview/deleteOutInterview", params);
    },
    // export const selectTelReq = data => postReq(apiUrl7+"/outInterview/selectTel",data) //判断外部评委的手机号是否可以重复
    selectTelReq(params) {
      return axios.post("/outInterview/selectTel", params);
    },
    // export const selectMemberByMemberIdReq = data => postReq(apiUrl7+"/interviewMember/selectMemberByMemberId",data) //根据主键查询面试人员的详细信息
    selectMemberByMemberIdReq(params) {
      return axios.post("/interviewMember/selectMemberByMemberId", params);
    },
    // // 根据主键查询面试人员的详细信息 无需token
    // export const selectMemberByMemberIdWithOutTokenReq = data => postReq(apiUrl7+"/interviewMember/selectMemberByMemberIdNose",data)
    selectMemberByMemberIdWithOutTokenReq(params) {
      return axios.post("/interviewMember/selectMemberByMemberIdNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // export const editPlanScoreResultReq = data => postReq(apiUrl7+"/outInterview/editPlanScoreResult",data, {ContentType: 'application/json'}) //评委给面试人员评分
    editPlanScoreResultReq(params) {
      return axios.post("/outInterview/editPlanScoreResult", params);
    },
    // export const selectScoreResultOneReq = data => postReq(apiUrl7+"/outInterview/selectScoreResultOne?interviewerId="+data.interviewerId+"&judgeId="+data.judgeId+"&judgeType="+data.judgeType+"&scoreId="+data.scoreId+"") //评委查询单个面试人员的评分
    selectScoreResultOneReq(data) {
      return axios.post(
        "/outInterview/selectScoreResultOne?interviewerId=" +
          data.interviewerId +
          "&judgeId=" +
          data.judgeId +
          "&judgeType=" +
          data.judgeType +
          "&scoreId=" +
          data.scoreId +
          ""
      );
    },
    // export const selectQuestionForMemberReq = data => postReq(apiUrl7+"/interviewPlan/selectQuestionForMember?planId="+data.planId) //考生查询自己的试题(人机交互，随机试题)
    selectQuestionForMemberReq(data) {
      return axios.post(
        "/interviewPlan/selectQuestionForMember?planId=" + data.planId
      );
    },
    //  //考生查询自己的试题(人机交互，随机试题) 无需token
    // export const selectQuestionForMemberReqWithoutToken = data => postReq(apiUrl7+"/interviewPlan/selectQuestionForMemberNose?planId="+data.planId)
    selectQuestionForMemberReqWithoutToken(data) {
      return axios.post(
        "/interviewPlan/selectQuestionForMemberNose?planId=" + data.planId
      );
    },
    // //保存人机面试的试题
    // export const saveQuestionRecordReq = data => postReq(apiUrl7+"/outInterview/saveQuestionRecord",data,{ContentType: 'application/json'})
    saveQuestionRecordReq(params) {
      return axios.post("/outInterview/saveQuestionRecord", params);
    },
    // // 保存人机面试的试题 无需token
    // export const saveQuestionRecordReqWithoutToken = (data, params) => postReq(apiUrl7+"/outInterview/saveQuestionRecordNose?vipId=" + params.vipId + "&userId=" + params.userId, data, {ContentType: 'application/json'})
    saveQuestionRecordReqWithoutToken(data, params) {
      return axios.post(
        "/outInterview/saveQuestionRecordNose?vipId=" +
          params.vipId +
          "&userId=" +
          params.userId,
        data
      );
    },
    // export const getResumeAllInfoReq = data => getReq(apiUrl+"/resume/selectResumeAllInfo",data)
    // getResumeAllInfoReq(params) {
    //   return axios.get("/resume/selectResumeAllInfo", { params });
    // },
    // 删除考试地点
    delInterviewPlaceReq(data) {
      return axios.delete("/interviewPlace/delete", data);
    },
    // 编辑考试地点
    // export const changeInterviewPlaceReq = (data) =>
    // postReq(apiUrl7 + "/interviewPlace/editInterviewPlace", data, {
    //   ContentType: "application/json",
    // });
    changeInterviewPlaceReq(params) {
      return axios.post("/interviewPlace/editInterviewPlace", params);
    },

    // //修改状态操作
    // export const editExamMember = data => postReq(apiUrl7+"/outInterview/editMemberOrJudgeType",data)
    editExamMember(params) {
      return axios.post("/outInterview/editMemberOrJudgeType", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // 改变当前评委或者是面试人员是否在房间的状态 无需token
    // export const editExamMemberWithOutToken = data => postReq(apiUrl7+"/outInterview/editMemberOrJudgeTypeNose",data)
    editExamMemberWithOutToken(params) {
      return axios.post("/outInterview/editMemberOrJudgeTypeNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // // trtc ================= start =============================
    // // 开始录制
    // export const startRecording = data => getReq(apiUrl7+"/anon/trtc/createCloudRecording",data)
    startRecording(params) {
      return axios.get("/anon/trtc/createCloudRecording", { params });
    },
    // // 结束录制
    // export const stopRecording = data => getReq(apiUrl7+"/anon/trtc/deleteCloudRecording",data)
    stopRecording(params) {
      return axios.get("/anon/trtc/deleteCloudRecording", { params });
    },
    // // 解散房间
    // export const stopMeeting = data => getReq(apiUrl7+"/anon/trtc/dismissRoom",data)
    stopMeeting(params) {
      return axios.get("/anon/trtc/dismissRoom", { params });
    },
    // // 分页查询面试视频
    // export const getPlayerListPage = data => getReq(apiUrl7+"/interviewRTC/selectPlayerPageNose",data)
    getPlayerListPage(params) {
      return axios.get("/interviewRTC/selectPlayerPageNose", { params });
    },
    // // 查询项目音视频数据
    // export const getPlanRTCListPage = data => getReq(apiUrl7+"/interviewRTC/selectPlanRTCPage",data)
    getPlanRTCListPage(params) {
      return axios.get("/interviewRTC/selectPlanRTCPage", { params });
    },
    // // 查询面试者音视频数据
    // export const getPlanMemberRTCListPage = data => getReq(apiUrl7+"/interviewRTC/selectMemberPage",data)
    getPlanMemberRTCListPage(params) {
      return axios.get("/interviewRTC/selectMemberPage", { params });
    },
    // // trtc ================= end =============================

    // 审批前查询下一流程阶段
    // export const getNextStageReq = (data) =>
    // getReq(apiUrl7 + "/interviewMember/getNextStage", data);
    getNextStageReq(params) {
      return axios.get("/interviewMember/getNextStage", { params });
    },

    // 面试审批
    // export const updatePlanApprovalMsReq = (data, channelId) =>
    // postReq(
    //   apiUrl7 + `/interviewMember/updatePlanApproval?channelId=${channelId}`,
    //   data,
    //   {
    //     ContentType: "application/json",
    //   }
    // );
    updatePlanApprovalMsReq(data, channelId) {
      return axios({
        url: `/interviewMember/updatePlanApproval?channelId=${channelId}`,
        method: "post",
        data,
      });
    },

    // 获取考场城市列表
    getInterviewPlaceCityListReq(data) {
      return axios.post("/interviewPlace/selectCity", data);
    },

    // export const selectScoreResultOneReqWithOutToken = (data) =>
    // postReq(
    //   apiUrl7 +
    //     "/outInterview/selectScoreResultOneNose?interviewerId=" +
    //     data.interviewerId +
    //     "&judgeId=" +
    //     data.judgeId +
    //     "&judgeType=" +
    //     data.judgeType +
    //     "&scoreId=" +
    //     data.scoreId +
    //     ""
    // ); //评委查询单个面试人员的评分
    selectScoreResultOneReqWithOutToken(data) {
      return axios({
        url: `/outInterview/selectScoreResultOneNose?interviewerId=${data.interviewerId}&judgeId=${data.judgeId}&judgeType=${data.judgeType}&scoreId=${data.scoreId}`,
        method: "post",
      });
    },
  };
}

import requestPaper from "@/assets/plugins/asktrue/request-paper"; // paper 实例
import qs from "qs"; // 序列化

// //类别设置-----------------------------↓
// export const postTypeReq = data => postReq(apiUrl2+"/remarkSubject/selectAll",data);
export function postTypeReq(data) {
  return requestPaper({
    url: "/remarkSubject/selectAll",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const postTypeAddReq = data => postReq(apiUrl2+"/remarkSubject/editRemarkLabel",data,{ContentType:'application/json;charset=UTF-8'});//新增/编辑
// export const postTypeDelReq = data => postReq(apiUrl2+"/remarkSubject/deleteByRemarkId",data);//删除
// export const postTypeMoveReq = data => postReq(apiUrl2+"/remarkSubject/movelSubjects",data);//移动

// //标签设置=============================↑

// //标签设置-----------------------------↓
// export const postTagsReq = data => postReq(apiUrl2+"/remarkLabel/selectAll",data);//列表
export function postTagsReq(data) {
  return requestPaper({
    url: "/remarkLabel/selectAll",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const postTagsAddReq = data => postReq(apiUrl2+"/remarkLabel/editRemarkLabel",data,{ContentType:'application/json;charset=UTF-8'});//新增/编辑
// export const postTagsDelReq = data => postReq(apiUrl2+"/remarkLabel/deleteByRemarkId",data);//删除
// export const postTagsMoveReq = data => postReq(apiUrl2+"/remarkLabel/movelLabels",data);//移动
// //标签设置=============================↑

// //评语列表
// export const getRemarkListReq = data => postReq(apiUrl2+"/remarkInfo/selectCount",data,{showLoading:true,loadingTarget:'.console-main'})
export function getRemarkListReq(data) {
  return requestPaper({
    url: "/remarkInfo/selectCount",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //新增/编辑评语
// export const editRemarkReq = data => postReq(apiUrl2+"/remarkInfo/editRemarkInfo",data,{ContentType:'application/json;charset=UTF-8'})
export function editRemarkReq(data) {
  return requestPaper({
    url: "/remarkInfo/editRemarkInfo",
    method: "post",
    data,
  });
}

// //通过主键查询一条详细的评语内容
// export const getRemarkEditReq = data => getReq(apiUrl2+"/remarkInfo/selectByRemarkId",data)

// //删除评语
// export const delRemarkReq = data => postReq(apiUrl2+"/remarkInfo/deleteByRemarkId",data)
export function delRemarkReq(data) {
  return requestPaper({
    url: "/remarkInfo/deleteByRemarkId",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

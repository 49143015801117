<!--注释： 
职位弹窗
-->
<template>
  <div class="form-warpper no-select no-scroll">
    <div>
      <div class="share-box pr" style="padding: 75px 50px 40px 50px">
        <h2 class="mt30">分享链接</h2>
        <!--分享链接-->
        <div class="url">
          <div class="clearfix">
            <input type="text" readonly v-model="url2" class="text" />
            <button
              class="btnnull"
              v-clipboard:copy="url2"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              复制
            </button>
            <a :href="url2" target="_blank"
              ><button class="btnnull">打开</button></a
            >
          </div>
          <div class="clearfix mt25">
            <button
              class="btnout"
              @click="$utils.asktrue.downLoadQrFn('qrCode2')"
            >
              下载二维码
            </button>
            <button class="btnout" @click="$utils.voit()">二维码海报</button>
          </div>
        </div>
        <!--分享渠道-->
        <div class="orient">
          <em class="iconfont iconweixin" @click="$utils.voit()"></em>
          <em
            class="iconfont iconxinlang"
            v-if="source == 'xm'"
            @click="postShareFn('wb')"
          ></em>
          <em
            class="iconfont iconqq"
            title="分享到QQ好友"
            v-if="source == 'xm'"
            @click="postShareFn('qq')"
          ></em>
        </div>
        <!--二维码-->
        <div class="ewm">
          <div class="tac">
            <p style="margin-top: -8px">微信扫码</p>
            <div ref="qrCode2" id="qrCode2" class="qr-code"></div>
          </div>
        </div>
      </div>

      <div class="share-box pr mt30" style="padding: 75px 50px 40px 50px">
        <h2 class="mt30">分享设置</h2>
        <div class="set">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="demo-ruleForm"
          >
            <div class="title">分享图片</div>
            <el-form-item class="share-pic clearfix">
              <div class="i-cm">
                <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
              </div>
              <el-upload
                class="upload-demo"
                accept="image/*"
                :action="
                  apiUrl1 + '/minIOApi/oss/putObjects?fileDirType=comp_img'
                "
                :headers="token"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="uploadfile"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只支持JPG和PNG格式，建议尺寸80*80px，不超过4M
                </div>
              </el-upload>
            </el-form-item>
            <div class="title mt30">分享标题</div>
            <el-form-item prop="title">
              <el-input
                type="text"
                placeholder="输入分享标题"
                v-model="title2"
                maxlength="30"
                show-word-limit
                class="text maxlength"
              ></el-input>
            </el-form-item>
            <div class="title mt30">分享说明</div>
            <el-form-item prop="title">
              <el-input
                type="text"
                placeholder="输入分享说明"
                v-model="content2"
                maxlength="50"
                show-word-limit
                class="text maxlength"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                class="btnsave mr15"
                style="width: 90px; height: 34px"
                >保存</el-button
              >
              <el-button
                @click="resetForm('ruleForm')"
                class="btnnull"
                style="width: 90px; height: 34px"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="view">
          <h3>分享到朋友圈</h3>
          <div class="share flex">
            <div class="i-cm">
              <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
            </div>
            <div class="font">
              <div class="title">{{ title2 }}</div>
            </div>
          </div>
          <h3 class="mt15">发送微信好友</h3>
          <div class="share">
            <div class="title">{{ title2 }}</div>
            <div class="flex mt10">
              <p class="f12 c8 font">{{ content2 }}</p>
              <div class="i-cm">
                <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
              </div>
            </div>
          </div>
          <h3 class="mt15">分享到QQ好友</h3>
          <div class="share">
            <div class="title">{{ title2 }}</div>
            <div class="flex mt10">
              <p class="f12 c8 font">{{ content2 }}</p>
              <div class="i-cm">
                <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getShareInfoReq,
  saveShareInfoReq,
} from "@/api/asktrue/project/project"; //引入接口
import { apiUrl1 } from "@/api/asktrue/config";
import { getShorlUrlReq } from "@/api/asktrue/common";

export default {
  props: {
    source: String, //分享来源
    examData: Object, //项目信息
  },
  data() {
    return {
      activeName: "first",
      ruleForm: {},
      rules: {},
      jkUrl: "",
      //项目
      url: "https://dwz.cn/9oI5mguL", //分享路径
      imageUrl: require("@/assets/image/asktrue/error/asktrue.png"), //分享图片
      title: "阿仕图，一站式考评SaaS平台！", //分享标题
      content: "考试？测评？调查？评估？Asktrue.cn帮您一站解决！", //分享内容
      //商品
      url2: "https://dwz.cn/9oI5mguL", //分享路径
      imageUrl2: require("@/assets/image/asktrue/error/asktrue.png"), //分享图片
      title2: "阿仕图，一站式考评SaaS平台！", //分享标题
      content2: "考试？测评？调查？评估？Asktrue.cn帮您一站解决！", //分享内容

      token: {},
      apiUrl1: apiUrl1,
    };
  },
  //页面初始加载
  created() {
    this.changeTabFn();
    this.token = { access_token: this.$store.state.asktrue.access_token };
    this.getShareInfoFn();
  },
  methods: {
    changeTabFn() {
      this.$nextTick(() => {
        if (this.activeName == "first") {
          //项目
          this.url =
            window.location.href.split("Project")[0] +
            "Exam/Login/" +
            this.$route.query.id +
            "/" +
            this.examData.type +
            "/" +
            this.examData.vipId +
            `/${this.$store.state.asktrue.userInfo.userId}`;
          this.$refs.qrCode.innerHTML = "";
          this.$utils.creatQrFn(this.url, "qrCode", 66);
        }
        if (this.activeName == "second") {
          //商品
          this.url2 =
            window.location.href.split("Project")[0] +
            "GoodsDetail?pId=" +
            this.$route.query.id +
            "&type=" +
            this.examData.type;
          this.$refs.qrCode2.innerHTML = "";
          this.$utils.creatQrFn(this.url2, "qrCode2", 66);

          getShorlUrlReq({ longUrl: this.url2 }).then((res) => {
            if (res.code == "200") {
              this.url2 = res.data.dwz;
            }
          });
        }
      });
    },
    //获取分享数据
    getShareInfoFn() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.$route.query.type,
      };
      getShareInfoReq(params).then((res) => {
        if (res.code == "200") {
          if (res.data.result.shareTitle) {
            this.title = res.data.result.shareTitle;
          }
          if (res.data.result.shareDesc) {
            this.content = res.data.result.shareDesc;
          }
          if (res.data.result.shareLogo) {
            this.imageUrl = res.data.result.shareLogo;
          }

          if (res.data.result.shareTitle1) {
            this.title2 = res.data.result.shareTitle1;
          }
          if (res.data.result.shareDesc1) {
            this.content2 = res.data.result.shareDesc1;
          }
          if (res.data.result.shareLogo1) {
            this.imageUrl2 = res.data.result.shareLogo1;
          }
        }
      });
    },
    closePop() {
      //关闭弹窗的传参
      this.$emit("closePop");
    },
    //上传图片
    uploadfile(res) {
      if (res.code == "200") {
        if (this.activeName == "first") {
          //项目
          this.imageUrl = res.data.result;
        } else {
          this.imageUrl2 = res.data.result;
        }
      }
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
      }
      return isJPG || isPNG;
    },
    //获取分享内容
    getHref(type) {
      const share = {
        title:
          this.activeName == "first"
            ? this.title
            : this.activeName == "second"
            ? this.title2
            : "",
        desc:
          this.activeName == "first"
            ? this.content
            : this.activeName == "second"
            ? this.content2
            : "",
        share_url:
          this.activeName == "first"
            ? this.url
            : this.activeName == "second"
            ? this.url2
            : this.jkUrl,
        image_url:
          this.activeName == "first"
            ? this.imageUrl
            : this.activeName == "second"
            ? this.imageUrl2
            : "",
      };
      if (type == "wb") {
        return (
          "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pic=" +
          share.image_url +
          "&searchPic=true"
        );
      } else if (type == "qq") {
        return (
          "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&desc=" +
          share.desc
        );
      } else if (type == "qzone") {
        return (
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
          encodeURIComponent(share.share_url) +
          "&sharesource=qzone&title=" +
          share.title +
          "&pics=" +
          share.image_url +
          "&desc=" +
          share.desc
        );
      } else if (type == "wx") {
        return (
          "https://qr.liantu.com/api.php?text=" +
          encodeURIComponent(share.share_url)
        );
      }
    },
    //打开窗口
    postShareFn(type) {
      let url = this.getHref(type);
      window.open(url);
    },
    //复制链接
    onCopy() {
      this.$message.success("内容已复制到剪切板！");
    },
    onError() {
      this.$message.error("抱歉，复制失败！");
    },
    //保存
    submitForm() {
      let params = {
        examId: this.$route.query.id,
        projectType: this.$route.query.type,
        shareDec: this.content,
        shareLogo: this.imageUrl,
        shareTitle: this.title,

        shareTitle1: this.title2,
        shareDec1: this.content2,
        shareLogo1: this.imageUrl2,
      };
      saveShareInfoReq(params).then((res) => {
        if (res.code == "200") {
          this.$utils.voit("保存成功", "success");
        }
      });
    },
    //重置
    resetForm() {
      if (this.activeName == "first") {
        //项目
        this.title = "阿仕图，一站式考评SaaS平台！";
        this.content = "考试？测评？调查？评估？Asktrue.cn帮您一站解决！";
        this.imageUrl = require("@/assets/image/asktrue/error/asktrue.png");
      } else {
        this.title2 = "阿仕图，一站式考评SaaS平台！";
        this.content2 = "考试？测评？调查？评估？Asktrue.cn帮您一站解决！";
        this.imageUrl2 = require("@/assets/image/asktrue/error/asktrue.png");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form-warpper {
  h2 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    line-height: 24px;
  }
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }
  .share-box {
    border-radius: 3px;
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    .url {
      width: 62%;
      .text {
        float: left;
        width: 65%;
        height: 34px;
        line-height: 34px;
      }
      .btnnull {
        float: left;
        max-width: 70px;
        width: 15%;
        height: 34px;
        border: 1px solid #39c;
        color: #39c;
        margin-left: 2%;
      }
      .btnout {
        float: left;
        width: auto;
        height: 34px;
        margin-right: 2%;
        padding: 0 15px;
        background-color: #fff;
      }
    }
    .orient {
      width: 22%;
      text-align: left;
      padding-left: 10px;
      em {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        margin-right: 12px;
        cursor: pointer;
      }
      em.iconweixin {
        background-color: #00d700;
      }
      em.iconxinlang {
        background-color: #e6162d;
      }
      em.iconqq {
        background-color: #0075c2;
      }
    }
    .ewm {
      width: 16%;
      max-width: 100px;
      text-align: right;
      border-left: 1px dashed #e4e4e4;
      div {
        width: 76px;
        float: right;
        p {
          display: block;
          margin: 0px 0px 5px 0;
        }
        .qr-code {
          width: 76px;
          height: 76px;
          padding: 5px;
          border: 1px solid #e4e4e4;
          border-radius: 3px;
          overflow: hidden;
        }
      }
    }

    .set {
      width: 60%;
      padding-right: 30px;
      .title {
        margin-bottom: 8px;
      }
      .i-cm {
        float: left;
        width: 80px;
        height: 80px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .upload-demo {
        float: left;
      }
    }
    .view {
      width: 40%;
      padding: 25px 30px 30px;
      background-color: #ebedf1;
      h3 {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
      }
      .share {
        background: #fff;
        padding: 15px;
        color: #666;
        border-radius: 2px;
        .i-cm {
          float: left;
          width: 50px;
          height: 50px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .font {
          width: calc(100% - 65px);
        }
        p {
          line-height: 160%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .share-box {
    h2 {
      position: absolute;
      top: 0px;
    }
  }
}
</style>

<style>
.set .el-form-item__content {
  line-height: 34px;
}
.set .share-pic .avatar-uploader .el-upload {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.set .share-pic .el-button--primary {
  height: 34px;
  width: 90px;
  border-color: #e4e4e4;
  background-color: #fff;
  color: #666;
  border-radius: 2px;
  font-size: 14px;
}
.set .el-upload__tip {
  margin-top: 30px;
  line-height: 16px;
}
.maxlength .el-input__inner {
  padding-right: 50px;
}
</style>

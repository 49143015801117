export default function (axios) {
  return {
    // ************************************************************************************************指标
    //指标——查：列表
    indexListApi(params) {
      return axios.post("/index/selectPage", params);
    },
    //指标——增改：新增、编辑
    indexSaveApi(params) {
      return axios.post("/index/saveIndex", params);
    },
    //指标——删
    indexDelApi(params) {
      return axios.post("/index/deleteByIds", {}, { params });
    },
    //指标——查：详情
    indexDetailApi(params) {
      return axios.get("/index/getIndexById", params);
    },

    // ************************************************************************************************目标

    // ************************************************************************************************进程

    // ************************************************************************************************考核
  };
}

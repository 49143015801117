export default {
  routers: [
    {
      path: "/company/:companyId",
      component: () => import("@/views/company/Layout.vue"),
      children: [
        {
          path: "",
          name: "CompanyHome",
          component: () => import("@/views/company/HomeView.vue"),
        },
        {
          path: "about",
          name: "CompanyAbout",
          component: () => import("@/views/company/AboutView.vue"),
        },
        {
          path: "notice",
          // name: "CompanyNotice",
          component: () => import("@/views/company/NoticeView.vue"),
          children: [
            {
              path: "",
              name: "CompanyNotice",
              component: () =>
                import("@/views/company/notice/NoticeListView.vue"),
            },
            {
              path: ":noticeId",
              name: "CompanyNoticeDetail",
              component: () =>
                import("@/views/company/notice/NoticeDetailView.vue"),
            },
          ],
        },
        {
          path: "business",
          name: "CompanyBusiness",
          component: () => import("@/views/company/BusinessView.vue"),
        }, // 业务办理 放置员工经常需要办理的业务
      ],
    },
  ],
};

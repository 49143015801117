export default {
  parent: "OA",
  routers: [
    // 日志
    {
      path: "diary",
      name: "Diary",
      component: () => import("@/views/oa/diary/DiaryView.vue"),
      // redirect: { name: "MyDiary" },
    },
    // 文档1
    {
      path: "doc",
      name: "Doc",
      component: () => import("@/views/oa/doc/DocView.vue"),
      // redirect: { name: "DocList" },
    },
    // 公告
    {
      path: "notice",
      name: "Notice",
      component: () => import("@/views/oa/notice/NoticeView.vue"),
      // redirect: { name: "NoticePage" },
    },
    // 审批
    {
      path: "approve",
      name: "Approve",
      component: () => import("@/views/oa/approve/ApproveView.vue"),
      // redirect: { name: "MyApply" },
    },
    // 任务
    {
      path: "task",
      name: "Task",
      component: () => import("@/views/oa/task/TaskView.vue"),
    },
    // 打卡
    {
      path: "attendance",
      name: "Attendance",
      component: () => import("@/views/oa/attendance/AttendanceView.vue"),
      // redirect: { name: "AttendanceList" },
    },
  ],
};

export function getPcStyle() {
  return {
    // 模板一默认样式-------------------------------------------------------------------
    login1: {
      //登录页样式控制对象
      exist: [
        "template",
        "backgroundStyle",
        "step",
        "title",
        "button",
        "copyright",
      ], //当前模板存在哪些控制项
      template: {
        template: 1,
      },
      backgroundStyle: {
        //背景样式控制
        backgroundType: "pure", //背景样式类型，pure：纯色；gradient：渐变色；img：图片
        typeDetail: "#00b38a", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
      step: {
        begin: { borderColor: "#aaa", color: "#aaa" },
        pass: { borderColor: "#00b38a", color: "#00b38a" },
        checked: { borderColor: "#f60", color: "#f60" },
      },
      title: {
        //标题
        text: "",
        fontSize: "26px",
        color: "#555",
        bold: false,
      },
      logo: {
        show: false, //是否展示logo
        img: "", //图片路径
      },
      sidePicture: {
        //插画
        img: "",
      },
      button: {
        //按钮样式
        backgroundColor: "#00b38a", //背景色
        fontColor: "#fff", //字体颜色
      },
      loginbox: {
        show: true,
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        opacity: 50,
      },
      copyright: {
        //版权信息
        show: true,
        font: "Asktrue 提供技术支持",
      },
    },
    // 模板二默认样式-------------------------------------------------------------------
    login2: {
      //登录页样式控制对象
      exist: [
        "template",
        "backgroundStyle",
        "step",
        "title",
        "button",
        "copyright",
      ], //当前模板存在哪些控制项
      template: {
        template: 2,
      },
      backgroundStyle: {
        //背景样式控制
        backgroundType: "pure", //背景样式类型，pure：纯色；gradient：渐变色；img：图片
        typeDetail: "#00B38A", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
      step: {
        begin: { borderColor: "#aaa", color: "#aaa" },
        pass: { borderColor: "#00b38a", color: "#00b38a" },
        checked: { borderColor: "#f60", color: "#f60" },
      },
      title: {
        //标题
        text: "",
        fontSize: "26px",
        color: "#555",
        bold: false,
      },
      logo: {
        show: false, //是否展示logo
        img: "", //图片路径
      },
      sidePicture: {
        //插画
        img: "",
      },
      button: {
        //按钮样式
        backgroundColor: "#00b38a", //背景色
        fontColor: "#fff", //字体颜色
      },
      loginbox: {
        show: true,
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        opacity: 50,
      },
      copyright: {
        //版权信息
        show: true,
        font: "Asktrue 提供技术支持",
      },
    },
    // 模板三默认样式-------------------------------------------------------------------
    login3: {
      //登录页样式控制对象
      exist: [
        "template",
        "backgroundStyle",
        "step",
        "title",
        "loginbox",
        "copyright",
      ], //当前模板存在哪些控制项
      template: {
        template: 3,
      },
      backgroundStyle: {
        //背景样式控制
        backgroundType: "img", //背景样式类型，pure：纯色；gradient：渐变色；img：图片
        typeDetail: require("@/assets/image/asktrue/style/styleBg.jpg"), //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
      step: {
        begin: { borderColor: "#aaa", color: "#aaa" },
        pass: { borderColor: "#00b38a", color: "#00b38a" },
        checked: { borderColor: "#f60", color: "#f60" },
      },
      title: {
        //标题
        text: "",
        fontSize: "26px",
        color: "#555",
        bold: false,
      },
      logo: {
        show: false, //是否展示logo
        img: "", //图片路径
      },
      sidePicture: {
        //插画
        img: "",
      },
      button: {
        //按钮样式
        backgroundColor: "#00b38a", //背景色
        fontColor: "#fff", //字体颜色
      },
      loginbox: {
        show: true,
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        opacity: 50,
        rgba: "rgba(255,255,255,0.5)",
      },
      copyright: {
        //版权信息
        show: true,
        font: "Asktrue 提供技术支持",
      },
    },

    // 以下商品页、答题页暂时不再受控制-------------------------------------------------------↓
    commodity: {
      //商品页样式控制对象
      backgroundStyle: {
        //背景样式控制
        backgroundType: "pure", //背景样式类型
        typeDetail: "#ebedf1", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
      headerImg: "@/assets/image/asktrue/topBg/ast1.jpg", //页眉
      commons: true, //是否允许评论
    },

    examStyle: {
      //答题页样式控制
      backgroundStyle: {
        //背景样式控制
        backgroundType: "pure", //背景样式类型
        typeDetail: "#ebedf1", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
    },
    // 以上商品页、答题页暂时不再受控制-------------------------------------------------------↑
  };
}
export function getMobileStyle() {
  return {
    commodity: {
      //商品页样式控制对象
      headerImg: "@/assets/image/asktrue/topBg/ast1.jpg", //页眉
      commons: true, //是否允许评论
    },
    login: {
      //登录页样式控制对象
      title: {
        //标题
        text: "",
        fontSize: "19px",
        color: "#555",
        bold: true,
        show: false,
      },
      logo: {
        show: false, //是否展示logo
        img: "", //图片路径
      },
      button: {
        //按钮样式
        backgroundColor: "#00b38a", //背景色
        fontColor: "#fff", //字体颜色
      },
    },
    examStyle: {
      //答题页样式控制
      backgroundStyle: {
        //背景样式控制
        backgroundType: "pure", //背景样式类型
        typeDetail: "#ebedf1", //类型为图片则为url链接，类型为纯色则为颜色字符串，类型为渐变色则为数组
      },
    },
  };
}

// 公积金
export default {
  parent: "Salary",
  routers: [
    // 社保
    {
      path: "social",
      name: "Social",
      component: () => import("@/views/salary/ss/SocialView"),
    },
    // 公积金
    {
      path: "housingfund",
      name: "HousingFund",
      component: () => import("@/views/salary/pf/ProvidentView"),
    },
    // 个税
    {
      path: "tax/:sign?",
      name: "Tax",
      component: () => import("@/views/salary/iit/ListShunt.vue"),
    },
    // 薪酬--工资↓
    {
      path: "wage",
      name: "Wage",
      component: () => import("@/views/salary/wage/WageView"),
      redirect: { name: "WageFile" },
    },
  ],
};

// 绩效
export default {
  parent: "Performance",
  routers: [
    // 统计分析
    {
      path: "resultanalysis",
      name: "ResultAnalysis",
      component: () =>
        import("@/views/performance/resultanalysis/indexView.vue"),
    },
    // 绩效评估
    {
      path: "performanceappraisal",
      name: "PerformanceAppraisal",
      component: () =>
        import("@/views/performance/performance-appraisal/IndexView.vue"),
    },
    // 指标库
    {
      path: "targetlibrary",
      name: "TargetLibrary",
      component: () => import("@/views/performance/indexList.vue"),
    },
    // 目标设定
    {
      path: "targetsetting",
      name: "TargetSetting",
      component: () =>
        import("@/views/performance/target-setting/IndexView.vue"),
    },
    // 进程管理
    {
      path: "processmanagement",
      name: "ProcessManagement",
      component: () =>
        import("@/views/performance/ProcessManagement/IndexView.vue"),
    },

    {
      path: "360",
      name: "360",
      component: () => import("@/views/staff/360/IndexView.vue"),
    },
  ],
};

import Vue from "vue";
import store from "@/store";
import router from "@/router";

const Axios = require("axios");
/* 设置接口路径
  注意事项：
  1、不用再像云掌门一样，设置路径后，再从后面逐个创建Axios和配置拦截器
  2、★★★★★   格式必须是如：{ name: "user", api: "https://user.staffcloud.cn", axios: null }
  3、★★★★★   name值在api文件夹下必须有对应的同名文件夹，所有属于该api下的接口必须存放在这个同名文件夹下的js文件内
  4、★★★★★   user接口必须是第一个
*/
const Apis_baseURL = [
  { name: "user", api: "https://staffmarket.cn/user/user", axios: null }, // 用户,必须是第一个对象★★★★★
  { name: "oa", api: "https://staffmarket.cn/oa", axios: null },//oa办公
  { name: "crm", api: "https://staffmarket.cn/crm", axios: null },//客户crm
  { name: "perf", api: "https://staffmarket.cn/perf", axios: null },//绩效
  { name: "staff", api: "https://staffmarket.cn/staff", axios: null },//员工关系
  { name: "salary", api: "https://staffmarket.cn/salary", axios: null },// 薪酬
  { name: "train", api: "https://staffmarket.cn/study", axios: null }, //培训接口

  { name: "matcheasy", api: "https://api.matcheasy.cn", axios: null }, //培训接口
  { name: "bs", api: "https://enroll.matcheasy.cn/", axios: null }, //培训接口
  { name: "ms", api: "https://enroll.matcheasy.cn/", axios: null }, //培训接口
  { name: "tj", api: "https://enroll.matcheasy.cn/", axios: null }, //培训接口
  { name: "tz", api: "https://enroll.matcheasy.cn/", axios: null }, //培训接口

];
for (let e of Apis_baseURL) {
  // 创建 oa staff salary user crm
  e.axios = Axios.create({ baseURL: e.api });
  e.axios.urlHro = "outsourced";
  e.axios.urlHrm = "internal";

  // 添加请求拦截器
  e.axios.interceptors.request.use(
    function (config) {
      // config.headers["X-Access-Token"] = store.state.user.access_token; // 添加请求头
      config.headers.access_token = store.state.user.access_token; // 添加请求头      
      console.log(JSON.stringify(config.headers, null, 2), '请求头')
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // 添加响应拦截器
  e.axios.interceptors.response.use(
    function (res) {
      let data = res.data;
      if (data.code != 200 && data.code != 0) {
        // 没有token或者token过期自动跳转到登录界面
        if (data.code == 401 || data.code == 406) {
          store.dispatch("user/setAccess_token", "");
          return router.replace({ name: "login" });
        }
        Vue.prototype.$message.error(data.msg || "接口报错" + data.code);
        if (!data.msg && !data.code) {
          throw data.msg || "接口报错" + data.code;
        }
      }
      return data;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  if (e.name == "user") {
    // 上传文件的方法
    e.axios.upload = function (url, data, options = {}) {
      let formData = new FormData();
      for (let k of Object.keys(data)) {
        let val = data[k];
        if (val instanceof Array) {
          for (let item of val) {
            formData.append(k, item);
          }
        } else {
          formData.append(k, val);
        }
      }
      return e.axios.post(url, formData, {
        headers: {
          ...(options.header || {}),
          "Content-Type": "multipart/form-data",
        },
        params: options.params,
      });
    };
  }
}

// ****************************************************************

export default {
  install(Vue) {
    // 引入全局api
    const api = {};
    const req = require.context("@/api", true, /\.js$/);
    req.keys().forEach((path) => {
      let fn = req(path)?.default;
      let type = path.split("/")[1];
      if (typeof fn === "function") {
        for (let e of Apis_baseURL) {
          if (type == e.name) {
            Object.assign(api, fn(e.axios));
          }
        }
      }
    });
    Vue.prototype.$api = api;
    Vue.prototype.$axios = Apis_baseURL[0].axios;
  },
};

export { };

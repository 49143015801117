<template>
  <div
    class="camera_outer"
    :style="{ width: param.width + 'px', height: param.height + 'px' }"
  >
    <video
      id="videoCamera"
      :width="param.width"
      :height="param.height"
      autoplay
    ></video>
    <canvas
      style="display: none"
      id="canvasCamera"
      :width="param.width"
      :height="param.height"
    ></canvas>
    <div class="btn_camera" v-if="param.type != 'exam'">
      请调整摄像头，将面部置于视频中央
    </div>
  </div>
</template>
<script>
export default {
  props: {
    param: {
      type: Object,
      default: () => {
        return { width: "250", height: "158" };
      },
    },
    isRun: Boolean,
  },
  data() {
    return {
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      isYN: false,
    };
  },
  computed: {},
  methods: {
    /*
     *@function  调用权限
     *****************************************/
    getCompetence() {
      // Camera(document.getElementById('videoCamera'))
      // this.isYN=true;
      // this.backData();
      var _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      var constraints = {
        audio: false,
        video: {
          width: this.param.width,
          height: this.param.height,
          transform: "scaleX(-1)",
        },
      };
      _this.init(constraints);

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          stream.onended = _this.noStream();
          _this.thisVideo.onloadedmetadata = function () {
            if (stream.active) {
              //在这里需要做判断
              _this.isYN = true;
              _this.backData();
              _this.thisVideo.play();
            } else {
              _this.init(constraints);
            }
          };
        })
        .catch((err) => {
          console.log(err, "err");
          _this.isYN = false;
          this.backData();
        });
    },
    /*
     *@function  绘制图片
     *****************************************/
    setImage() {
      var _this = this;
      // 点击，canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL("image/png");
      _this.imgSrc = image;
      this.$emit("refreshDataList", this.imgSrc);
    },
    /*
     *@function  base64转文件
     *****************************************/
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    /*
     *@function  关闭摄像头
     *****************************************/
    stopNavigator() {
      if (this.thisVideo) {
        console.log("video stop");
        this.thisVideo.srcObject.getTracks()[0].stop();
        // const stream = this.thisVideo.srcObject;
        // const tracks = stream.getTracks();

        // tracks.forEach((track) => {
        //   track.stop();
        // });

        // this.thisVideo.srcObject = null;
      }
    },
    noStream(err) {
      //失败回调
      console.log(err, "err");
      this.init(); // 重新调用
    },
    //初始化
    init() {
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
    },
    //关闭
    backData() {
      this.$emit("back", this.isYN);
    },
  },
  mounted() {
    if (this.isRun) {
      this.getCompetence();
    }
  },
  deactivated() {
    // called when removed from the DOM into the cache
    // and also when unmounted
    this.stopNavigator();
  }, // 考生登录用到了这个页面，而考生登录页面 是 keepalive的 😫，所以 需要这个，不然video 摄像头并不关闭
  beforeDestroy() {
    console.log("video beforeDestroy");
    this.stopNavigator();
  },
};
</script>
<style lang="less" scoped>
.camera_outer {
  position: relative;
  overflow: hidden;
  background-color: #000;
  border-radius: 5px;
  margin: 0 auto;
  video,
  canvas {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .btn_camera {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    line-height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #ffffff;
    font-size: 12px;
  }
}
</style>

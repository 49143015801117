var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staffSelector"},[_c('div',{ref:"xuanzekuang",class:['ss-select-box', { isDisabled: _vm.disabled }, { test: _vm.a }],on:{"!click":function($event){_vm.disabled ? '' : _vm.showFn()}}},[_vm._t("default",function(){return [_c('div',{staticClass:"ss-text-box"},[(_vm.prefixIcon)?_c('em',{class:['prefixIcon', _vm.prefixIcon]}):_vm._e(),_c('div',{class:['text slh', { c: !_vm.text }]},[_vm._v(" "+_vm._s(_vm.isShowCheckedLabel ? _vm.text || _vm.placeholder : _vm.placeholder)+" ")]),_c('em',{staticClass:"el-icon-arrow-down"})])]})],2),_c('el-dialog',{attrs:{"visible":_vm.show,"title":_vm.title,"append-to-body":"","custom-class":"tree-dialog"},on:{"update:visible":function($event){_vm.show=$event},"close":function($event){_vm.show = false}}},[_c('div',{staticClass:"content-box"},[_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.newTabs),function(item,i){return _c('el-tab-pane',{key:i,attrs:{"name":item.name,"label":item.label}})}),1),_c('g-searchinput',{attrs:{"placeholder":"关键词搜索"},on:{"confirm":_vm.searchFn},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),(_vm.treeData[_vm.currentTab] && _vm.treeData[_vm.currentTab].length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"loading-box"},[_c('el-tree',{ref:"tree",attrs:{"filter-node-method":_vm.filterTree,"default-expanded-keys":_vm.defaultExpandedKeys,"default-checked-keys":_vm.defaultCheckedKeys,"data":_vm.treeData[_vm.currentTab],"node-key":"urlId","show-checkbox":"","props":{ label: 'name', id: 'urlId' }},on:{"check":_vm.checkFn},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node"},[_c('em',{class:[
                'iconfont',
                _vm.treeType.find((e) => {
                  return e.treeType == data.treeType;
                }).iconfont,
              ]}),_c('span',[_vm._v(_vm._s(node.label))])])}}],null,false,2046018052)})],1):_c('g-null',{staticClass:"no",attrs:{"tips":_vm.currentTab == 'c'
            ? '暂无合作' + (_vm.systemSign == 1 ? '供应商' : '客户')
            : '暂无数据'}})],1),_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-title-box"},[_c('div',{staticClass:"selected-title"},[_vm._v("已选（"+_vm._s(_vm.selected.length)+"）")]),_c('div',{staticClass:"btn-clear",on:{"click":_vm.clearFn}},[_vm._v("清空")])]),_c('div',{staticClass:"selected-content-box"},[_vm._l((_vm.selected),function(item,i){return _c('div',{key:i,staticClass:"selected-item"},[_c('div',{staticClass:"item-content"},[_c('em',{class:[
                'iconfont',
                _vm.treeType.find((e) => {
                  return e.treeType == item.treeType;
                }).iconfont,
              ]}),_c('span',[_vm._v(_vm._s(item.urlName || item.name))])]),_c('div',{staticClass:"btn-cancel",on:{"click":function($event){return _vm.cancelFn(item.urlId, item.tabName)}}},[_vm._v(" × ")])])}),(_vm.selected.length)?_c('div',{staticClass:"selectedTips"},_vm._l((_vm.treeType),function(tItem,tIndex){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.selected.filter((e) => e.treeType == tItem.treeType).length
            ),expression:"\n              selected.filter((e) => e.treeType == tItem.treeType).length\n            "}],key:'tt_' + tIndex},[_vm._v(_vm._s(_vm.selected.filter((e) => e.treeType == tItem.treeType).length)+"个"+_vm._s(tItem.label))])}),0):_c('div',{staticClass:"selectedTips"},[_c('span',[_vm._v("还未做出选择")])])],2)]),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.ensureFn}},[_vm._v("确定")]),_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
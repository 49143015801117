export default function (axios) {
  return {
    //  新增/修改评分
    updateEditScore(params) {
      return axios.post("/performance/evaluation/editScore", params);
    },
    //   进程/考核列表查询
    getProcessSelectPage(params) {
      return axios.post("/performance/evaluation/selectPage", params);
    },
    //  查询评分列表
    getProcessSelectScoreList(params) {
      return axios.post(
        "/performance/evaluation/selectScoreList?targetId=" + params.targetId
      );
    },
    //  考核退回修改
    getProcessSendBack(params) {
      return axios.post("/performance/evaluation/sendBack", params);
    },
    // 评论列表展示
    getRemarkSelectPayBackList(params) {
      return axios.post("/remark/selectRemarkList", params);
    },
    // 评论新增/修改信息
    addRemarkEditRemark(params) {
      return axios.post("/remark/editRemark", params);
    },
    // 从评论那里新增回复,
    AddRemarkReply(params) {
      return axios.post("/remark/addRemarkReply", params);
    },
    // 删除计划信息
    delPlanDelDocument(params) {
      return axios.get("/plan/delDocument?planId=" + params.planId);
    },
    // 计划新增/修改
    addPlanEditPlan(params) {
      return axios.post("/plan/editPlan", params);
    },
    // 计划列表展示
    getPlanSelectPlanList(params) {
      return axios.post("/plan/selectPlanList", params);
    },
    // 查询绩效纪录列表
    getProcessSelectRecordList(params) {
      return axios.post(
        "/performance/record/selectRecordList?targetId=" + params.targetId,
        params
      );
    },
    // 新增文档
    AddDocumentProcess(params) {
      return axios.post("/document/addDocument", params);
    },
    //删除文档信息
    DelDocumentProcess(params) {
      return axios.get("/document/delDocument?attachId=" + params.attachId);
    },
    //文档列表展示
    getProcessSelectDocumentList(params) {
      return axios.post("/document/selectDocumentList", params);
    },
    //  修改KR进度
    getEditSchedule(params) {
      return axios.post("/course/editSchedule", params);
    },
    // 提交考核
    submitExamine(params) {
      return axios.post("/course/submitExamine?targetId=" + params.targetId);
    },
    //  申请修改
    courseSendBack(params) {
      return axios.post("/course/sendBack", params);
    },
    //  废除目标
    repealTarget(params) {
      return axios.post("/course/repealTarget?targetId=" + params.targetId);
    },
  };
}

export default function (axios) {
  return {
    // 文档——增
    doc_add(params) {
      return axios.post("/cDocumentRecord/addDocument", params);
    },
    // 文档——删（单个、批量）
    doc_del(params) {
      return axios.post("/cDocumentRecord/deleteDocument", params);
    },
    // 文档——查(分页)
    doc_select(params) {
      return axios.get("/cDocumentRecord/selectPage", { params });
    },
    // 文档——查(详情)
    doc_queryDocById(params) {
      return axios.get("/cDocumentRecord/queryDocById", { params });
    },

    //********************************************************************* */

    // 文档类型——增、改
    doc_saveType(params) {
      return axios.post("/cDocumentType/editCDocumentTypeCus", params);
    },
    doc_selectIfUse(params) {
      return axios.post("/cDocumentType/selectIfUse", {}, { params });
    },
    // 文档类型——删（单个、批量）
    doc_delType(params) {
      return axios.post(
        "/cDocumentType/deleteCDocumentTypeCus",
        {},
        { params }
      );
    },
    // 文档类型——查(分页)
    doc_selectType(params) {
      return axios.get("/cDocumentType/selectPage", { params });
    },
  };
}

/*
 * @Description: 绩效 目标设定
 * @Date: 2024-01-25 14:26:02
 * @LastEditTime: 2024-01-27 13:44:29
 */

export default {
  parent: "TargetSetting",
  routers: [
    {
      path: ":type",
      name: "PerformanceTargetList",
      component: () =>
        import("@/views/performance/target-setting/TargetListView.vue"),
    },
  ],
};

// 使用方法：this.$utils.date(时间戳).format(格式)
// 格式（注意大小写），如：YYYY-MM-DD HH:mm:ss
// YYYY：年，返回值如：2023
// YY：月，返回值如：23
// MM：月，返回值如：01
// DD：日，返回值如：02
// HH：时，返回值如：09
// mm：分，返回值如：17
// ss：秒，返回值如：56

// 详细参考文档：http://momentjs.cn/docs/

import moment from "moment";

export default {
  date: moment,
  getDay(val) {
    let timestr = val;
    let month = timestr.getMonth() + 1;
    let date = timestr.getDate();
    let timeString = [
      timestr.getFullYear(),
      month,
      date,
      timestr.toString().split(" ")[0],
    ];
    return timeString;
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tinymce-editor mt5"},[_c('editor',{attrs:{"id":_vm.tinymceId,"init":_vm.init,"placeholder":_vm.dialogType == 1
        ? '输入试题内容'
        : _vm.dialogType == 2
        ? '输入选项内容'
        : _vm.dialogType == 3
        ? '输入答案解析'
        : _vm.dialogType == 4
        ? '输入产品说明'
        : '输入说明'},model:{value:(_vm.descValue),callback:function ($$v) {_vm.descValue=$$v},expression:"descValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  parent: "Headhunters",
  routers: [
    {
      path: ":page",
      name: "HeadhuntersList",
      component: () => import("@/views/recruit/headhunters/ListView.vue"),
    },
  ],
};

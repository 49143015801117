// import { getReq, postReq, delReq } from "@/api/apiReq";
// import { apiUrl } from "@/api/config";

export default function (axios) {
  return {
    // //----简历审批相关-----
    // export const getFiltraResumeListReq = (data) =>
    //   postReq(apiUrl + "/newPrimaryScreeningCtl/selectPage", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历初筛分页查询

    selectResumeForCompReq(data) {
      return axios.post("/newPrimaryScreeningCtl/selectResumeForComp", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //简历初筛分页查询
    // export const getUnsuitableResumeListReq = (data) =>
    //   postReq(apiUrl + "/newResume/selectInappropriatePage ", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历初筛分页查询
    deleteResumeReq(params) {
      return axios.post("/newResume/deleteResume", params, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // export const deleteResumeReq = (data) =>
    //   postReq(apiUrl + "/newResume/deleteResume", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //删除简历
    // export const getApproResumeListReq = (data) =>
    //   postReq(apiUrl + "/resumeProcessCtl/selectResumeApproveList", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历审批分页查询
    // //export const getApproResumeDetailReq = data => getReq(apiUrl+"/approval/selectResumeDetail",data)//简历详情-简历下审批和面试中的简历详情查询
    // export const getApproResumeDetailReq = (data) =>
    //   getReq(apiUrl + "/newResume/selectResumeDetail", data); //简历详情-简历下审批和面试中的简历详情查询
    // export const acceptPushJobReq = (data) =>
    //   getReq(apiUrl + "/approval/acceptPushJob", data); //接受推荐职位
    addResumeRelJobReq(data) {
      return axios.post(apiUrl + "/newResume/deliveryResume", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //	简历关联到职位
    // export const addResumeRelJob2Req = (data) =>
    //   getReq(apiUrl + "/approval/addResumeRelJob", data); //	简历推荐到职位
    // export const selectCompStagesReq = (data) =>
    //   getReq(apiUrl + "/approval/selectCompStages", data); //	阶段筛选数据查询
    selectCompStagesReq(params) {
      return axios.get("/approval/selectCompStages", { params });
    },

    // export const selectResumeRecordReq = (data) =>
    //   getReq(apiUrl + "/newResume/selectResumeRecord", data); //简历操作历史记录查询

    // export const selectResumeStageDataReq = (data) =>
    //   getReq(apiUrl + "/approval/selectResumeStageData", data); //阶段筛选数据查询
    selectResumeStageDataReq(params) {
      return axios.get("/approval/selectResumeStageData", { params });
    },
    updateInsideReq(data) {
      return axios.post("/newResume/updateInside", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //内审审批
    // export const updateProcStageApprovalReq = (data) =>
    //   postReq(apiUrl + "/newResume/updateProcStageApproval", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //阶段审批
    updateProcStageNewReq(data) {
      return axios.post("/newResume/updateProcStageNew", data, {
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
    }, //阶段审批（新）
    // export const selectProcStageListReq = (data) =>
    //   getReq(apiUrl + "/approval/selectProcStageList", data); //流程阶段回显
    // export const recommendedJobSendReq = (data) =>
    //   postReq(apiUrl + "/approval/recommendedJobSend", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //企业推荐职位发送短信与邮件
    // //----简历审批相关end-----
    // //----简历进程/录用相关-----
    // export const getProgessResumeListReq = (data) =>
    //   postReq(apiUrl + "/resumeProcessCtl/selectPage", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历进程分页查询
    // export const getStageResumeNumReq = (data) =>
    //   getReq(apiUrl + "/newResume/selectStageCount", data); //简历进程-阶段数值查询
    // export const getEmployResumeListReq = (data) =>
    //   postReq(apiUrl + "/resumeProcessCtl/selectResumeEmployList", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历录用分页查询
    // export const updateEmployResumeReq = (data) =>
    //   postReq(apiUrl + "/newResume/updateEmployResume", data); //更改录用简历状态(是否发送offer,是否入职)
    // export const updateOfferTemplateReq = (data) =>
    //   postReq(apiUrl + "/newResume/updateOfferTemplate", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //维护offer模版(新增,修改,删除)
    // export const getOfferTemplateListReq = (data) =>
    //   postReq(apiUrl + "/newResume/getOfferTemplateList", data); //获取offer模版
    // export const selectAddrListReq = (data) =>
    //   postReq(apiUrl + "/newResume/selectList", data); //获取录用的报道地点
    // export const insertAddressReq = (data) =>
    //   postReq(apiUrl + "/newResume/insertAddress", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //新增录用的报道地点
    // export const editAddressReq = (data) =>
    //   postReq(apiUrl + "/newResume/editAddress", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //修改录用的报道地点
    // export const delAddressReq = (id) =>
    //   postReq(apiUrl + `/newResume/delAddress/${id}`); //删除录用的报道地点
    // export const addResumePostRelReq2 = (data) =>
    //   postReq(apiUrl + "/newResume/saveResumeChannel", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //删除录用的报道地点
    getCount(params) {
      return axios.get("/newResume/getCount", { params });
    }, //全部简历统计
    // //----简历进程相关end-----
    // //----简历面试相关-----
    // export const getInterviewListReq = (data) =>
    //   postReq(apiUrl + "/resumeProcessCtl/queryInterviewList", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历面试分页查询
    // export const getInterviewInfoReq = (data) =>
    //   getReq(apiUrl + "/interview/selectInterviewInfo", data); //面试评价查询
    // export const getInterviewScoreReq = (data) =>
    //   postReq(apiUrl + "/interviewPlan/selectScoreByScoreId", data); //通过评价id查询详细的评价维度
    // export function updateInterviewInfoReq(data, params) {
    //   //进行面试评价
    //   let url = "/interviewPlan/insertResumeInterview";
    //   if (params) {
    //     url = url + "?";
    //     for (let key in params) {
    //       url += key + "=" + params[key] + "&";
    //     }
    //   }
    //   return postReq(apiUrl + url, data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // }
    // //----简历面试相关end-----
    // //----简历匹配相关-----
    // export const addCompCollResumeReq = (data) =>
    //   getReq(apiUrl + "/matching/addCompCollResume", data); //企业收藏简历
    // export const updateCompCollResumeReq = (data) =>
    //   getReq(apiUrl + "/matching/updateCompCollResume", data); //企业取消收藏简历
    // export const getMatchResumeDetailReq = (data) =>
    //   getReq(apiUrl + "/matching/selectResumeDetail", data); //简历详情
    getMatchResumeListReq(params) {
      return axios.get("/matching/selectMatchingResumePage", { params });
    }, //推荐列表
    // export const getMatchVisitJobReq = (data) =>
    //   getReq(apiUrl + "/matching/selectVisitJobResumePage", data); //访问职位简历分页查询
    // export const getCollVisitJobReq = (data) =>
    //   getReq(apiUrl + "/matching/selectCollJobResumePage", data); //收藏我
    // export const getMyCollResumeReq = (data) =>
    //   getReq(apiUrl + "/matching/selectCompCollResumePage", data); //我收藏
    getMateResumeListReq(type, params) {
      let url =
        "/matching/" +
        (type == 1
          ? "selectMatchingResumePage"
          : type == 2
          ? "selectCompCollResumePage"
          : type == 3
          ? "selectVisitJobResumePage"
          : type == 4
          ? "selectCollJobResumePage"
          : "");
      if (!type) {
        url = "/searching/selectPage";
      }
      return axios.get(url, { params });
    },
    // export const selectCompCollJobResumePageReq = (data) =>
    //   getReq(apiUrl + "/resumePostRelCtl/selectCompCollJobResumePage", data); //收藏我(个人)；收藏职位简历分页查询
    // export const selectPersonColJobResumePageReq = (data) =>
    //   getReq(apiUrl + "/resumePostRelCtl/selectPersonColJobResumePage", data); //我收藏(个人)；我收藏的职位分页
    // export const selectPersonVisitJobResumePageReq = (data) =>
    //   getReq(apiUrl + "/resumePostRelCtl/selectPersonVisitJobResumePage", data); //看过我(个人)；访问职位简历分页查询
    // //----简历匹配相关end-----
    // //----简历搜索相关-----
    // export const getSearchResumeListReq = (data) =>
    //   getReq(apiUrl + "/searching/selectPage", data); //	简历搜索分页查询
    // export const getSearchResumeDetailReq = (data) =>
    //   getReq(apiUrl + "/searching/selectResumeDetail", data); //简历详情
    // //----简历搜索相关end-----
    // //----简历人才库相关-----
    // export const getPoolResumeListReq = (data) =>
    //   getReq(apiUrl + "/resumepool/selectResumePoolPage", data); //人才库分页查询
    // export const getPoolResumeListNewReq = (data) =>
    //   getReq(apiUrl + "/resumepool/selectResumePoolPageNew", data); //人才库分页查询
    // export const getPoolResumeDetailReq = (data) =>
    //   getReq(apiUrl + "/resumepool/selectResumeDetail", data); //简历详情
    addResumeToDirReq(data) {
      return axios.post("/resumepool/addResumeTODir", data);
    },
    // export const addResumeToDirReq = (data) =>
    //   postReq(apiUrl + "/resumepool/addResumeTODir", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历入库
    // export const deleteCompDirReq = (data) =>
    //   delReq(apiUrl + "/resumepool/deleteCompDir", data); //分类删除
    // export const getCompDirReq = (data) =>
    //   getReq(apiUrl + "/resumepool/selectCompDir", data); //分类查询
    getCompDirTreeReq(params) {
      return axios.get("/resumepool/selectCompDirTree", { params });
    },
    // export const getCompDirTreeReq = (data) =>
    //   getReq(apiUrl + "/resumepool/selectCompDirTree", data); //分类查询(层级)
    // export const updateCompDirReq = (data) =>
    //   getReq(apiUrl + "/resumepool/updateCompDir", data); //分类新增修改
    // export const moveDirReq = (data) =>
    //   getReq(apiUrl + "/resumepool/moveDir", data); //简历移动
    // export const getIdByNameAndNoReq = (data) =>
    //   postReq(apiUrl + "/resumepool/getIdByNameAndNo", data); //人才库简历id获取（根据手机号或邮箱）
    // export const getIdByPIdReq = (data) =>
    //   postReq(apiUrl + "/resumepool/getIdByPId", data); //人才库简历id获取(根据人才id)
    // export const deleteResumeLibReq = (data) =>
    //   postReq(apiUrl + "/resumepool/deleteResumeLib", data); //人才库删除单个简历(猎头系统)
    deleteResumeLibAllReq(data) {
      return axios.post("/resumepool/deleteResumeLibAll", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // export const deleteResumeLibAllReq = (data) =>
    //   postReq(apiUrl + "/resumepool/deleteResumeLibAll", data); //人才库删除候选人(猎头系统)
    // export const deleteResumeLibZpReq = (data) =>
    //   postReq(apiUrl + "/resumepool/deleteResumeLibZp", data); //人才库删除候选人(招聘系统)
    // export const saveResumeChannelReq = (data) =>
    //   postReq(apiUrl + "/resumepool/saveResumeChannel", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //人才库简历推荐到职位（招聘系统）
    // export const checkResumeCountReq = (data) =>
    //   postReq(apiUrl + "/resumepool/checkResumeCount", data); //简历库查看限制
    // //----简历人才库相关end-----
    // //----简历操作相关-----
    // export const addResumeCommentReq = (data) =>
    //   getReq(apiUrl + "/newResume/addResumeComment", data); //简历点评
    // export const addResumeCommentPoolReq = (data) =>
    //   getReq(apiUrl + "/newResume/addResumeCommentPool", data); //简历库简历点评
    addResumeExamReq(params) {
      return axios.get("/newResume/addResumeExam", { params });
    }, //候选人添加阿仕图测评
    checkProcStageApprovalReq(data) {
      return axios.post("/newResume/checkProcStageApproval", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }, //验证审批权限
    selectResumeNotesReq(params) {
      return axios.get("/newResume/selectResumeNotes", { params });
    }, //简历综合点评查询

    delStageReq(params) {
      return axios.delete("/operate/deleteStage", { params });
    },

    // export const selectLabelReq = (data) =>
    //   getReq(apiUrl + "/operate/selectLabel", data); //查询标签
    // export const updateLabelReq = (data) =>
    //   getReq(apiUrl + "/operate/updateLabel", data); //简历标签新增/修改
    // export const updateResumeLabelReq = (data) =>
    //   getReq(apiUrl + "/newResume/updateResumeLabel", data); //简历设置标签

    // export const updateStageReq = (data) =>
    //   getReq(apiUrl + "/operate/updateStage", data); //阶段新增/编辑
    updateStageReq(params) {
      return axios.get("/operate/updateStage", { params });
    },

    // export const moveStageReq = (data) =>
    //   postReq(apiUrl + "/operate/editStage", data); // 阶段上下移动
    // //----简历操作相关end-----
    // //--------------------猎头简历管理--------------------

    addResumePostRelReq(data) {
      //基本信息新增/编辑
      return axios.post("/headhunterResumeCtl/saveResumeChannel", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    getJobNameAndCompReq(params) {
      return axios.get("/headerResumeCtl/getJobNameAndComp", { params });
    }, //获取职位数据
    getHuntResumeListReq(type, params) {
      //简历列表
      if (type == "rck") {
        //人才库
        return axios.get("/resumepool/selectResumePoolPage", { params });
      } else if (type == "wt") {
        //委托
        return axios.post("/entrustCtl/headerEntrustResumePage", params, {
          ContentType: "application/json;charset=UTF-8",
        });
      } else if (type == "td") {
        //投递
        return axios.post("/newPrimaryScreeningCtl/selectPage", params, {
          ContentType: "application/json;charset=UTF-8",
        });
      } else if (type == "pp") {
        //搜索
        return axios.post("/resume/selectAllPage", params, {
          ContentType: "application/json;charset=UTF-8",
        });
      } else if (type == "sx") {
        //推荐
        return axios.post(
          "/newPrimaryScreeningCtl/headerRecommendResumePage",
          params,
          { ContentType: "application/json;charset=UTF-8" }
        );
      } else if (type == "inAudit") {
        //内审
        return axios.post(
          "/newPrimaryScreeningCtl/headerInsiResumePage",
          params,
          { ContentType: "application/json;charset=UTF-8" }
        );
      } else if (type == "ms") {
        //面试
        return axios.post("/resumeProcessCtl/queryInterviewList", params, {
          ContentType: "application/json;charset=UTF-8",
        });
      } else if (type == "bhs") {
        //不合适
        return axios.post(
          "/headhunterResumeCtl/selectInappropriatePage",
          params,
          { ContentType: "application/json;charset=UTF-8" }
        );
      } else {
        let url =
          "/resumeProcessCtl/" +
          (type == "jd"
            ? "headerProcessResumePage"
            : type == "offer"
            ? "headerEmployResumePage"
            : "queryInterviewList");
        return axios.post(url, params, {
          ContentType: "application/json;charset=UTF-8",
        });
      }
    },
    // export function getHuntResumeListReq(type, data) {
    //   //简历列表
    //   if (type == "rck") {
    //     //人才库
    //     return getReq(apiUrl + "/resumepool/selectResumePoolPage", data);
    //   } else if (type == "wt") {
    //     //委托
    //     return postReq(apiUrl + "/entrustCtl/headerEntrustResumePage", data, {
    //       ContentType: "application/json;charset=UTF-8",
    //     });
    //   } else if (type == "td") {
    //     //投递
    //     return postReq(apiUrl + "/newPrimaryScreeningCtl/selectPage", data, {
    //       ContentType: "application/json;charset=UTF-8",
    //     });
    //   } else if (type == "pp") {
    //     //搜索
    //     return postReq(apiUrl + "/resume/selectAllPage", data, {
    //       ContentType: "application/json;charset=UTF-8",
    //     });
    //   } else if (type == "sx") {
    //     //推荐
    //     return postReq(
    //       apiUrl + "/newPrimaryScreeningCtl/headerRecommendResumePage",
    //       data,
    //       { ContentType: "application/json;charset=UTF-8" }
    //     );
    //   } else if (type == "inAudit") {
    //     //内审
    //     return postReq(
    //       apiUrl + "/newPrimaryScreeningCtl/headerInsiResumePage",
    //       data,
    //       { ContentType: "application/json;charset=UTF-8" }
    //     );
    //   } else if (type == "ms") {
    //     //面试
    //     return postReq(apiUrl + "/resumeProcessCtl/queryInterviewList", data, {
    //       ContentType: "application/json;charset=UTF-8",
    //     });
    //   } else if (type == "bhs") {
    //     //不合适
    //     return postReq(
    //       apiUrl + "/headhunterResumeCtl/selectInappropriatePage",
    //       data,
    //       { ContentType: "application/json;charset=UTF-8" }
    //     );
    //   } else {
    //     let url =
    //       "/resumeProcessCtl/" +
    //       (type == "jd"
    //         ? "headerProcessResumePage"
    //         : type == "offer"
    //         ? "headerEmployResumePage"
    //         : "queryInterviewList");
    //     return postReq(apiUrl + url, data, {
    //       ContentType: "application/json;charset=UTF-8",
    //     });
    //   }
    // }
    //查询学校性质
    selectSchoolLevelReq(params) {
      return axios.get("/newPrimaryScreeningCtl/selectSchoolLevel", { params });
    },

    getHeaderStageNumReq(data) {
      return axios.post("/headerResumeCtl/getStage", data);
    }, //简历进程-阶段查询
    getHeaderPlanNumReq(data) {
      return axios.post("/headerResumeCtl/getPlan", data);
    }, //简历进程-按安排项目统计
    headerApproveReq(data) {
      return axios.post("/headerResumeCtl/headerApprove", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //阶段审批
    getHeadersResumeCount(params) {
      return axios.get("/headhunterResumeCtl/getCount", { params });
    },
    //附件简历
    getResumeAttachReq(params) {
      return axios.post("/resume/queryAttachmentById", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // getResumeAttachReq(data) {
    //   return axios.post("/resume/queryAttachmentById", data);
    // },
    //查询当前简历的附件简历url
    selectStageCountByTypeReq(params) {
      return axios.get("/newResume/selectStageCountByType", params);
    }, //类型详情中的简历阶段数值统计
    selectStageCountByJobIdReq(params) {
      return axios.get("/newResume/selectStageCountByJobId", { params });
    },
    selectStageCountByJobIdReq(params) {
      return axios.get("/newResume/selectStageCountByJobId", { params });
    }, //职位详情简历阶段统计
    selectStageCountReq(data) {
      return axios.post("/resumeProcessCtl/selectStageCount", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //类型详情中的简历阶段数值统计
    //获取简历创建者列表
    getCreatorReq(data) {
      return axios.post("/newResume/getCreator", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // //简历解析
    analyzeNoseReq(data) {
      return axios.post("/analyzeResumeCtl/analyzeNose", data);
    },
    // export const analyzeNoseReq = (data) =>
    //   postReq(apiUrl + "/analyzeResumeCtl/analyzeNose", data);
    // // 简历模板
    // export const selectResumeTemplatePageReq = (data) =>
    //   getReq(apiUrl + "/resumeTemplateCtl/selectResumeTemplatePage", data); //简历模板查询
    selectResumeTemplatePageReq(params) {
      return axios.get("/resumeTemplateCtl/selectResumeTemplatePage", {
        params,
      });
    },

    // export const saveResumeTemplateReq = (data) =>
    //   postReq(apiUrl + "/resumeTemplateCtl/saveResumeTemplate", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历模板新增、编辑
    // export const getResumeTemplateReq = (data) =>
    //   getReq(
    //     apiUrl +
    //       "/resumeTemplateCtl/getResumeTemplate?templateId=" +
    //       data.templateId
    //   ); //简历模板详情
    // export const deleteResumeTemplateReq = (data) =>
    //   postReq(
    //     apiUrl +
    //       "/resumeTemplateCtl/deleteResumeTemplate?templateId=" +
    //       data.templateId
    //   ); //简历模板删除
    // export const saveResumeFieldReq = (data) =>
    //   postReq(apiUrl + "/resumeTemplateCtl/saveResumeField", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历自定义字段新增、编辑
    saveResumeFieldReq(data) {
      return axios.post("/resumeTemplateCtl/saveResumeField", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // export const selectResumeAllFieldsReq = (data) =>
    //   getReq(apiUrl + "/resumeTemplateCtl/selectResumeAllFields", data); //简历全部字段查询
    selectResumeAllFieldsReq(params) {
      return axios.get("/resumeTemplateCtl/selectResumeAllFields", { params });
    },
    // export const deleteResumeFieldReq = (data) =>
    //   postReq(
    //     apiUrl + "/resumeTemplateCtl/deleteResumeField?fieldId=" + data.fieldId
    //   ); //简历自定义字段删除
    deleteResumeFieldReq(data) {
      return axios.post(
        "/resumeTemplateCtl/deleteResumeField?fieldId=" + data.fieldId
      );
    },
    // export const selectTemplatePageCreatorReq = (data) =>
    //   getReq(apiUrl + "/resumeTemplateCtl/selectTemplatePageCreator", data); //简历模板创建者查询
    // // /resumeTemplateCtl/restoreDefaultTemplate 简历模板恢复默认
    // export const restoreDefaultTemplate = (data) =>
    //   getReq(
    //     apiUrl +
    //       "/resumeTemplateCtl/restoreDefaultTemplate?projectId=" +
    //       data.projectId
    //   ); //简历模板详情
    restoreDefaultTemplate(data) {
      return axios.get(
        "/resumeTemplateCtl/restoreDefaultTemplate?projectId=" + data.projectId
      );
    },
    // // 项目简历模板
    // export const saveProjectResumeTemplateReq = (data) =>
    //   postReq(apiUrl + "/resumeTemplateCtl/saveProjectResumeTemplate", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //简历模板新增、编辑
    saveProjectResumeTemplateReq(data) {
      return axios.post("/resumeTemplateCtl/saveProjectResumeTemplate", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // export const getProjectResumeTemplateReq = (data) =>
    //   getReq(
    //     apiUrl +
    //       "/resumeTemplateCtl/getProjectResumeTemplate?projectId=" +
    //       data.projectId
    //   ); //简历模板详情
    getProjectResumeTemplateReq(data) {
      return axios.get(
        "/resumeTemplateCtl/getProjectResumeTemplate?projectId=" +
          data.projectId
      );
    },
    // export const checkResumeReq = (data) =>
    //   getReq(apiUrl + "/newResume/checkResume?jobId=" + data.jobId); //企业官网简历投递前校验简历自定义字段
    // export const getResumeFieldContentReq = (data) =>
    //   getReq(
    //     apiUrl +
    //       "/newResume/getResumeFieldContent?jobId=" +
    //       data.jobId +
    //       "&resumeId=" +
    //       data.resumeId
    //   ); //企业官网简历自定义字段及数据
    // export const getTemplateIdByJobIdReq = (data) =>
    //   getReq(apiUrl + "/newResume/getTemplateIdByJobId?jobId=" + data.jobId); //企业官网简历投递前校验简历自定义字段
    // export const updateByPrimaryKeySelectiveReq = (data) =>
    //   postReq(apiUrl + "/newResume/updateByPrimaryKeySelective", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   }); //修改推荐详情表被推荐人信息

    // 访问记录以及 公司关注个人相关接口
    addEditSeeRecordReq(data) {
      return axios.post("/seeRecord/addEditSeeRecord", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //hr或者企业查看个人建立时候进行新增查看记录;
    eaddUserForCompReq(data) {
      return axios.post("/seeRecord/addUserForComp", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //公司收藏个人
    selectCollectUserReq(params) {
      return axios.get("/seeRecord/selectCollectUser", { params });
    }, //查看哪个公司关注了我
    selectSeeMeRecordReq(params) {
      return axios.get("/seeRecord/selectSeeMeRecord", { params });
    }, //查询谁看过我 我是个人
    addUserForCompReq(data) {
      return axios.post("/seeRecord/addUserForComp", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    }, //公司收藏个人
    // 跟上面的接口放一块了 所以在这个文件夹里了
    // /seeRecord/addEditSeeRecordForComp  个人查看招聘者职位时候进行新增查看记录
    addEditSeeRecordForComp(data) {
      return axios.post("/seeRecord/addEditSeeRecordForComp", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    // /seeRecord/selectCollectUserForComp  我是招聘者 查看我关注了哪些牛人
    selectCollectUserForComp(params) {
      return axios.get("/seeRecord/selectCollectUserForComp", { params });
    },
    // /seeRecord/selectCollectCompForUser   我是招聘者 查看哪些求职者收藏了我
    selectCollectCompForUser(params) {
      return axios.get("/seeRecord/selectCollectCompForUser", { params });
    },
    // /seeRecord/selectSeeUserRecordForComp  查询我看过谁 我是招聘者
    selectSeeUserRecordForComp(params) {
      return axios.get("/seeRecord/selectSeeUserRecordForComp", { params });
    },
    // /seeRecord/selectSeeMeRecordForComp  查询谁看过我 我是招聘者
    selectSeeMeRecordForComp(params) {
      return axios.get("/seeRecord/selectSeeMeRecordForComp", { params });
    },
    // /seeRecord/selectCommunicationsAll   互动-沟通过 包含新招呼 以及沟通中的人员
    selectCommunicationsAll(params) {
      {
        return axios.post("/seeRecord/selectCommunicationsAll", params);
      }
    },

    // /resume/selectResumeByUserId   根据求职者查询简历（无联系方式）
    selectResumeByUserId(params) {
      return axios.get("/resume/selectResumeByUserId", { params });
    }
  };
}

let utf8 = { ContentType: "application/json;charset=UTF-8" };
//----简历管理相关-----
export default function (axios) {
  return {
    //简历分页查询
    getResumePageReq(data) {
      return axios.get("/resume/selectPage", { data });
    },
    //简历全部信息查询
    getResumeAllInfoReq(params) {
      return axios.get("/resume/selectResumeAllInfo", { params });
    },
    //简历删除
    deleteResumeReq(data) {
      return axios.delete("/resume/deleteResume", { data });
    },
    //简历附件编辑
    updateResumeFileReq(data) {
      return axios.post("/resume/updateAttatchInfo", data, utf8);
    },
    //简历设置
    updateResumeSetReq(data) {
      return axios.post("/resume/updateResumeSetUp", data, utf8);
    },
    //简历设置回显信息
    getResumeSetReq(data) {
      return axios.get("/resume/selectResumeForSet/" + data.resumeId, [data]);
    },
    //设置默认简历
    setDefaulResumeReq(data) {
      return axios.post("/resume/setDefaultResume", data);
    },
    //修改在职情况接口
    updateWorkStatusReq(data) {
      return axios.get("/resume/updateWorkStatus", { data });
    },
    //简历信息编辑
    updateResumeReq(typeUrl, data) {
      let url = "/resume/update" + typeUrl;
      return axios.post(url, data, utf8);
    },

    //简历信息查询
    getResumeInfoReq(typeUrl, data) {
      let url = "/resume/select" + typeUrl;
      return axios.get(url, { data });
    },

    //简历信息删除
    delResumeInfoReq(typeUrl, data) {
      let url = "/resume/delete" + typeUrl;
      return axios.delete(url, data);
    },

    //简历信息编辑
    updateResumePlain(data) {
      let url = "/resume/updateResumePlain";
      return axios.post(url, data, utf8);
    },
    //简历全部信息查询
    getResumeAllInfoForAbReq(params) {
      return axios.get("/resume/selectResumeAllInfoForAb", { params });
    },
  };
}
